import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Chevron from '../../../assets/chevron.inline.svg';

const Select = forwardRef(
	(
		{
			options,
			labelOption,
			labelText,
			id,
			hintText,
			name,
			validationMessage,
			showValidation,
			className,
			selectClassName,
			labelClassName,
			labelWrapperClassName,
			...selectAttributes
		},
		ref
	) => {
		const errorId = `${id}-error`;
		const hintTextId = `${id}-hint-text`;

		const renderOption = (option) => (
			<option
				value={option.value}
				key={`${option.value}-${id}-${Math.random()}`}
				disabled={option.disabled}
			>
				{option.label}
			</option>
		);
		return (
			<div
				className={clsx(
					'relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
					showValidation ? 'before:bg-alert-400' : '',
					className
				)}
			>
				<div className={clsx('mb-4', labelWrapperClassName)}>
					<label
						htmlFor={id}
						className={clsx(
							'text-brand-blue-400 text-base-f font-centra-medium',
							labelClassName
						)}
					>
						{labelText}
					</label>
					{hintText ? <p id={hintTextId}>{hintText}</p> : null}
				</div>

				<div className="relative">
					<select
						className={clsx(
							'block px-4 py-3 mb-3 rounded-xs shadow-answer hover:bg-brand-pale-400 outline-none w-full appearance-none placeholder:text-grey-400 focus:placeholder:text-brand-blue-400 transition-colors duration-200 motion-reduce:transition-none bg-brand-pale-300 border-1 border-solid border-blue-500',
							selectClassName,
							showValidation
								? 'border-alert-400 placeholder:text-alert-500'
								: ''
						)}
						name={name}
						id={id}
						aria-invalid={!!showValidation}
						aria-describedby={
							hintText ? `${hintTextId} ${errorId}` : errorId
						}
						ref={ref}
						{...selectAttributes}
					>
						{labelOption && (
							<option value="">
								{selectAttributes?.placeholder ||
									'Please select'}
							</option>
						)}
						{options.map(renderOption)}
					</select>

					<Chevron
						className="absolute rotate-180 -translate-y-1/2 pointer-events-none top-1/2 right-4"
						width="14"
						height="14"
						aria-hidden="true"
					/>
				</div>

				{showValidation ? (
					<p className="mt-3 text-alert-500" id={errorId}>
						{validationMessage}
					</p>
				) : null}
			</div>
		);
	}
);

Select.defaultProps = {
	hintText: undefined,
	validationMessage: '',
	showValidation: false,
	className: '',
	labelClassName: '',
	selectClassName: '',
	labelWrapperClassName: '',
	labelOption: true,
};

Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.string,
			disabled: PropTypes.string,
		})
	).isRequired,
	labelText: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hintText: PropTypes.string,
	validationMessage: PropTypes.string,
	showValidation: PropTypes.bool,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
	selectClassName: PropTypes.string,
	labelWrapperClassName: PropTypes.string,
	labelOption: PropTypes.bool,
};

export { Select };
