// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const ResourceHeader = ({
	content,
	variant,
	className,
	description,
	heading,
}) => {
	const { id, title, image, topLogo, audio } = content;

	let alt = '';
	let hasImage;
	let imageData = {};
	if (image && Object.keys(image).length !== 0) {
		alt = image.alt || '';
		imageData = getImage(image);
		hasImage = true;
	} else {
		hasImage = false;
	}

	return (
		<section
			aria-labelledby={id}
			className={clsx(
				'sm:gap-10 max-w-content mx-auto px-3 md:px-8 xl:px-0',
				variant === 'Download'
					? 'sm:grid sm:grid-cols-[1fr_50%] md:grid-cols-[1fr_43%] lg:grid-cols-[1fr_38%] lg:gap-14'
					: null,
				variant === 'Event' ? 'mb-9 md:mb-xl-f' : 'mb-14 md:mb-20',
				variant !== 'Download' && variant !== 'Event'
					? 'lg:gap-16'
					: null,
				className
			)}
		>
			<div className="text-center mb-8 flex flex-row justify-center items-center">
				{Object.keys(topLogo).length !== 0 ? (
					<div className="">
						{topLogo.responsiveImage ? (
							<img
								src={topLogo.responsiveImage.src}
								width={topLogo.responsiveImage.width}
								height={topLogo.responsiveImage.height}
								alt={topLogo.responsiveImage.alt || ''}
								// className="w-full"
								loading="lazy"
							/>
						) : (
							<GatsbyImage
								image={topLogo.gatsbyImageData}
								alt={topLogo.alt || ''}
							/>
						)}
					</div>
				) : null}
			</div>

			<div className="">
				<h1
					id={id}
					className="mb-2 md:leading-tight text-2xl-f md:text-5xl md:mb-4 text-center pb-4 lg:pb-6 xl:pb-8"
				>
					{heading ||
						description?.value?.document?.children[0]?.children[0]
							?.value ||
						title}
				</h1>
			</div>

			<div className="text-center">
				{hasImage ? (
					<GatsbyImage
						className={clsx(
							'border-rounded',
							// 'drop-shadow-image max-h-[230px] max-w-full xs:max-h-[320px] md:max-h-[280px]'
							'drop-shadow-image'
						)}
						image={imageData}
						quality={100}
						alt={alt}
						height="500"
					/>
				) : null}
			</div>

			<div>
				{audio ? (
					<AudioPlayer
						// autoPlay
						src={audio}
						// onPlay={e => console.log("onPlay")}
						// layout="horizontal"
					/>
				) : null}
			</div>
		</section>
	);
};

export { ResourceHeader };
