export const data = {
	1: {
		combined: 267.52,
		hr: 140.8,
		hs: 139.76,
	},
	2: {
		combined: 133.76,
		hr: 70.4,
		hs: 69.88,
	},
	3: {
		combined: 89.17,
		hr: 46.93,
		hs: 46.59,
	},
	4: {
		combined: 66.88,
		hr: 35.2,
		hs: 34.94,
	},
	5: {
		combined: 53.5,
		hr: 28.16,
		hs: 27.95,
	},
	6: {
		combined: 55.39,
		hr: 30.98,
		hs: 28.68,
	},
	7: {
		combined: 47.47,
		hr: 26.56,
		hs: 24.59,
	},
	8: {
		combined: 41.54,
		hr: 23.24,
		hs: 21.51,
	},
	9: {
		combined: 36.92,
		hr: 20.66,
		hs: 19.12,
	},
	10: {
		combined: 33.23,
		hr: 18.59,
		hs: 17.21,
	},
	11: {
		combined: 34.09,
		hr: 20.2,
		hs: 16.47,
	},
	12: {
		combined: 33.14,
		hr: 19.89,
		hs: 15.74,
	},
	13: {
		combined: 32.22,
		hr: 19.63,
		hs: 15,
	},
	14: {
		combined: 31.55,
		hr: 19.41,
		hs: 14.49,
	},
	15: {
		combined: 30.86,
		hr: 19.21,
		hs: 13.93,
	},
	16: {
		combined: 29.94,
		hr: 18.98,
		hs: 13.16,
	},
	17: {
		combined: 29.32,
		hr: 18.83,
		hs: 12.64,
	},
	18: {
		combined: 28.61,
		hr: 18.7,
		hs: 11.98,
	},
	19: {
		combined: 28,
		hr: 18.58,
		hs: 11.44,
	},
	20: {
		combined: 27.13,
		hr: 18.14,
		hs: 10.9,
	},
	21: {
		combined: 26.41,
		hr: 17.61,
		hs: 10.43,
	},
	22: {
		combined: 25.21,
		hr: 16.81,
		hs: 9.95,
	},
	23: {
		combined: 24.11,
		hr: 16.08,
		hs: 9.52,
	},
	24: {
		combined: 23.11,
		hr: 15.41,
		hs: 9.13,
	},
	25: {
		combined: 23.42,
		hr: 15.8,
		hs: 8.8,
	},
	26: {
		combined: 22.52,
		hr: 15.19,
		hs: 8.46,
	},
	27: {
		combined: 21.69,
		hr: 14.63,
		hs: 8.15,
	},
	28: {
		combined: 20.91,
		hr: 14.1,
		hs: 7.86,
	},
	29: {
		combined: 20.19,
		hr: 13.62,
		hs: 7.59,
	},
	30: {
		combined: 19.77,
		hr: 13.36,
		hs: 7.38,
	},
	31: {
		combined: 19.14,
		hr: 12.93,
		hs: 7.14,
	},
	32: {
		combined: 18.54,
		hr: 12.52,
		hs: 6.92,
	},
	33: {
		combined: 17.98,
		hr: 12.14,
		hs: 6.71,
	},
	34: {
		combined: 17.45,
		hr: 11.79,
		hs: 6.51,
	},
	35: {
		combined: 17.48,
		hr: 12.03,
		hs: 6.33,
	},
	36: {
		combined: 16.99,
		hr: 11.7,
		hs: 6.16,
	},
	37: {
		combined: 16.53,
		hr: 11.38,
		hs: 5.99,
	},
	38: {
		combined: 16.1,
		hr: 11.08,
		hs: 5.83,
	},
	39: {
		combined: 15.69,
		hr: 10.8,
		hs: 5.68,
	},
	40: {
		combined: 16.36,
		hr: 11.46,
		hs: 5.6,
	},
	41: {
		combined: 15.96,
		hr: 11.18,
		hs: 5.47,
	},
	42: {
		combined: 15.58,
		hr: 10.91,
		hs: 5.34,
	},
	43: {
		combined: 15.22,
		hr: 10.66,
		hs: 5.21,
	},
	44: {
		combined: 14.87,
		hr: 10.42,
		hs: 5.09,
	},
	45: {
		combined: 14.98,
		hr: 10.64,
		hs: 5.11,
	},
	46: {
		combined: 14.65,
		hr: 10.41,
		hs: 5,
	},
	47: {
		combined: 14.34,
		hr: 10.19,
		hs: 4.89,
	},
	48: {
		combined: 14.04,
		hr: 9.98,
		hs: 4.79,
	},
	49: {
		combined: 13.76,
		hr: 9.78,
		hs: 4.69,
	},
	50: {
		combined: 14.13,
		hr: 10.13,
		hs: 4.74,
	},
	51: {
		combined: 13.86,
		hr: 9.93,
		hs: 4.65,
	},
	52: {
		combined: 13.59,
		hr: 9.74,
		hs: 4.56,
	},
	53: {
		combined: 13.33,
		hr: 9.56,
		hs: 4.47,
	},
	54: {
		combined: 13.09,
		hr: 9.38,
		hs: 4.39,
	},
	55: {
		combined: 12.85,
		hr: 9.21,
		hs: 4.31,
	},
	56: {
		combined: 12.62,
		hr: 9.04,
		hs: 4.23,
	},
	57: {
		combined: 12.4,
		hr: 8.89,
		hs: 4.16,
	},
	58: {
		combined: 12.18,
		hr: 8.73,
		hs: 4.09,
	},
	59: {
		combined: 11.98,
		hr: 8.58,
		hs: 4.02,
	},
	60: {
		combined: 11.9,
		hr: 8.51,
		hs: 3.99,
	},
	61: {
		combined: 11.7,
		hr: 8.37,
		hs: 3.92,
	},
	62: {
		combined: 11.52,
		hr: 8.24,
		hs: 3.86,
	},
	63: {
		combined: 11.33,
		hr: 8.11,
		hs: 3.8,
	},
	64: {
		combined: 11.16,
		hr: 7.98,
		hs: 3.74,
	},
	65: {
		combined: 10.98,
		hr: 7.86,
		hs: 3.68,
	},
	66: {
		combined: 10.82,
		hr: 7.74,
		hs: 3.62,
	},
	67: {
		combined: 10.66,
		hr: 7.62,
		hs: 3.57,
	},
	68: {
		combined: 10.5,
		hr: 7.51,
		hs: 3.52,
	},
	69: {
		combined: 10.35,
		hr: 7.4,
		hs: 3.47,
	},
	70: {
		combined: 10.79,
		hr: 7.88,
		hs: 3.47,
	},
	71: {
		combined: 10.63,
		hr: 7.77,
		hs: 3.42,
	},
	72: {
		combined: 10.49,
		hr: 7.66,
		hs: 3.37,
	},
	73: {
		combined: 10.34,
		hr: 7.55,
		hs: 3.33,
	},
	74: {
		combined: 10.2,
		hr: 7.45,
		hs: 3.28,
	},
	75: {
		combined: 10.07,
		hr: 7.35,
		hs: 3.24,
	},
	76: {
		combined: 9.93,
		hr: 7.26,
		hs: 3.2,
	},
	77: {
		combined: 9.81,
		hr: 7.16,
		hs: 3.16,
	},
	78: {
		combined: 9.68,
		hr: 7.07,
		hs: 3.11,
	},
	79: {
		combined: 9.56,
		hr: 6.98,
		hs: 3.08,
	},
	80: {
		combined: 10.04,
		hr: 7.4,
		hs: 3.18,
	},
	81: {
		combined: 9.92,
		hr: 7.31,
		hs: 3.14,
	},
	82: {
		combined: 9.8,
		hr: 7.22,
		hs: 3.1,
	},
	83: {
		combined: 9.68,
		hr: 7.14,
		hs: 3.06,
	},
	84: {
		combined: 9.56,
		hr: 7.05,
		hs: 3.03,
	},
	85: {
		combined: 9.45,
		hr: 6.97,
		hs: 2.99,
	},
	86: {
		combined: 9.34,
		hr: 6.89,
		hs: 2.96,
	},
	87: {
		combined: 9.23,
		hr: 6.81,
		hs: 2.92,
	},
	88: {
		combined: 9.13,
		hr: 6.73,
		hs: 2.89,
	},
	89: {
		combined: 9.03,
		hr: 6.66,
		hs: 2.86,
	},
	90: {
		combined: 9.46,
		hr: 7.04,
		hs: 2.95,
	},
	91: {
		combined: 9.36,
		hr: 6.96,
		hs: 2.92,
	},
	92: {
		combined: 9.26,
		hr: 6.88,
		hs: 2.89,
	},
	93: {
		combined: 9.16,
		hr: 6.81,
		hs: 2.86,
	},
	94: {
		combined: 9.06,
		hr: 6.74,
		hs: 2.83,
	},
	95: {
		combined: 8.96,
		hr: 6.67,
		hs: 2.8,
	},
	96: {
		combined: 8.87,
		hr: 6.6,
		hs: 2.77,
	},
	97: {
		combined: 8.78,
		hr: 6.53,
		hs: 2.74,
	},
	98: {
		combined: 8.69,
		hr: 6.46,
		hs: 2.71,
	},
	99: {
		combined: 8.6,
		hr: 6.4,
		hs: 2.68,
	},
	100: {
		combined: 9.26,
		hr: 7,
		hs: 2.81,
	},
};

export const dataCA = {
	1: {
		combined: 489.0,
		hs: 279.0,
		hr: 279.0,
	},
	2: {
		combined: 244.5,
		hs: 139.5,
		hr: 139.5,
	},
	3: {
		combined: 173.0,
		hs: 96.33,
		hr: 109.67,
	},
	4: {
		combined: 129.75,
		hs: 72.25,
		hr: 82.25,
	},
	5: {
		combined: 103.8,
		hs: 57.8,
		hr: 65.8,
	},
	6: {
		combined: 108.17,
		hs: 58.17,
		hr: 69.83,
	},
	7: {
		combined: 92.71,
		hs: 49.86,
		hr: 59.86,
	},
	8: {
		combined: 81.13,
		hs: 43.63,
		hr: 52.38,
	},
	9: {
		combined: 72.11,
		hs: 38.78,
		hr: 46.56,
	},
	10: {
		combined: 64.9,
		hs: 34.9,
		hr: 41.9,
	},
	11: {
		combined: 72.64,
		hs: 37.18,
		hr: 49.0,
	},
	12: {
		combined: 66.58,
		hs: 34.08,
		hr: 44.92,
	},
	13: {
		combined: 61.46,
		hs: 31.46,
		hr: 41.46,
	},
	14: {
		combined: 57.07,
		hs: 29.21,
		hr: 38.5,
	},
	15: {
		combined: 53.27,
		hs: 27.27,
		hr: 35.93,
	},
	16: {
		combined: 59.31,
		hs: 28.06,
		hr: 41.81,
	},
	17: {
		combined: 55.82,
		hs: 26.41,
		hr: 39.35,
	},
	18: {
		combined: 52.72,
		hs: 24.94,
		hr: 37.17,
	},
	19: {
		combined: 49.95,
		hs: 22.68,
		hr: 36.32,
	},
	20: {
		combined: 47.45,
		hs: 22.45,
		hr: 33.45,
	},
	21: {
		combined: 52.33,
		hs: 23.76,
		hr: 38.05,
	},
	22: {
		combined: 49.95,
		hs: 22.68,
		hr: 36.32,
	},
	23: {
		combined: 47.78,
		hs: 21.7,
		hr: 34.74,
	},
	24: {
		combined: 45.79,
		hs: 20.79,
		hr: 33.29,
	},
	25: {
		combined: 43.96,
		hs: 19.96,
		hr: 31.96,
	},
	26: {
		combined: 46.12,
		hs: 19.96,
		hr: 34.58,
	},
	27: {
		combined: 44.41,
		hs: 19.22,
		hr: 33.3,
	},
	28: {
		combined: 42.82,
		hs: 18.54,
		hr: 32.11,
	},
	29: {
		combined: 41.34,
		hs: 17.9,
		hr: 31.0,
	},
	30: {
		combined: 39.97,
		hs: 17.3,
		hr: 29.97,
	},
	31: {
		combined: 41.9,
		hs: 17.39,
		hr: 31.9,
	},
	32: {
		combined: 40.59,
		hs: 16.84,
		hr: 30.91,
	},
	33: {
		combined: 39.36,
		hs: 16.33,
		hr: 29.97,
	},
	34: {
		combined: 38.21,
		hs: 15.85,
		hr: 29.09,
	},
	35: {
		combined: 37.11,
		hs: 15.4,
		hr: 28.26,
	},
	36: {
		combined: 38.86,
		hs: 15.53,
		hr: 30.25,
	},
	37: {
		combined: 37.81,
		hs: 15.11,
		hr: 29.43,
	},
	38: {
		combined: 36.82,
		hs: 14.71,
		hr: 28.66,
	},
	39: {
		combined: 35.87,
		hs: 14.33,
		hr: 27.92,
	},
	40: {
		combined: 34.98,
		hs: 13.98,
		hr: 27.23,
	},
	41: {
		combined: 35.34,
		hs: 14.12,
		hr: 27.54,
	},
	42: {
		combined: 34.5,
		hs: 13.79,
		hr: 26.88,
	},
	43: {
		combined: 33.7,
		hs: 13.47,
		hr: 26.26,
	},
	44: {
		combined: 32.93,
		hs: 13.16,
		hr: 25.66,
	},
	45: {
		combined: 32.2,
		hs: 12.87,
		hr: 25.09,
	},
	46: {
		combined: 32.59,
		hs: 13.02,
		hr: 25.41,
	},
	47: {
		combined: 31.89,
		hs: 12.74,
		hr: 24.87,
	},
	48: {
		combined: 31.23,
		hs: 12.48,
		hr: 24.35,
	},
	49: {
		combined: 30.59,
		hs: 12.22,
		hr: 23.86,
	},
	50: {
		combined: 29.98,
		hs: 11.98,
		hr: 23.38,
	},
	51: {
		combined: 30.37,
		hs: 12.14,
		hr: 23.71,
	},
	52: {
		combined: 29.79,
		hs: 11.9,
		hr: 23.25,
	},
	53: {
		combined: 29.23,
		hs: 11.68,
		hr: 22.81,
	},
	54: {
		combined: 28.69,
		hs: 11.46,
		hr: 22.39,
	},
	55: {
		combined: 28.16,
		hs: 11.25,
		hr: 21.98,
	},
	56: {
		combined: 27.66,
		hs: 11.05,
		hr: 21.95,
	},
	57: {
		combined: 27.18,
		hs: 10.86,
		hr: 21.21,
	},
	58: {
		combined: 26.71,
		hs: 10.67,
		hr: 20.84,
	},
	59: {
		combined: 26.25,
		hs: 10.49,
		hr: 20.49,
	},
	60: {
		combined: 25.82,
		hs: 10.32,
		hr: 20.15,
	},
	61: {
		combined: 26.21,
		hs: 10.31,
		hr: 20.64,
	},
	62: {
		combined: 25.79,
		hs: 10.15,
		hr: 20.31,
	},
	63: {
		combined: 25.38,
		hs: 9.98,
		hr: 19.98,
	},
	64: {
		combined: 24.98,
		hs: 9.83,
		hr: 19.67,
	},
	65: {
		combined: 24.6,
		hs: 9.68,
		hr: 19.37,
	},
	66: {
		combined: 24.23,
		hs: 9.53,
		hr: 19.08,
	},
	67: {
		combined: 23.87,
		hs: 9.39,
		hr: 18.79,
	},
	68: {
		combined: 23.51,
		hs: 9.25,
		hr: 18.51,
	},
	69: {
		combined: 23.17,
		hs: 9.12,
		hr: 18.25,
	},
	70: {
		combined: 22.84,
		hs: 8.99,
		hr: 17.99,
	},
	71: {
		combined: 23.23,
		hs: 9.0,
		hr: 18.44,
	},
	72: {
		combined: 22.9,
		hs: 8.88,
		hr: 18.18,
	},
	73: {
		combined: 22.59,
		hs: 8.75,
		hr: 17.93,
	},
	74: {
		combined: 22.28,
		hs: 8.64,
		hr: 17.69,
	},
	75: {
		combined: 21.99,
		hs: 8.52,
		hr: 17.45,
	},
	76: {
		combined: 21.7,
		hs: 8.41,
		hr: 17.22,
	},
	77: {
		combined: 21.42,
		hs: 8.3,
		hr: 17.0,
	},
	78: {
		combined: 21.14,
		hs: 8.19,
		hr: 16.78,
	},
	79: {
		combined: 20.87,
		hs: 8.09,
		hr: 16.57,
	},
	80: {
		combined: 20.61,
		hs: 7.99,
		hr: 16.36,
	},
	81: {
		combined: 20.98,
		hs: 8.01,
		hr: 16.65,
	},
	82: {
		combined: 20.72,
		hs: 7.91,
		hr: 16.45,
	},
	83: {
		combined: 20.47,
		hs: 7.82,
		hr: 16.25,
	},
	84: {
		combined: 20.23,
		hs: 7.73,
		hr: 16.06,
	},
	85: {
		combined: 19.99,
		hs: 7.64,
		hr: 15.87,
	},
	86: {
		combined: 19.76,
		hs: 7.55,
		hr: 15.69,
	},
	87: {
		combined: 19.53,
		hs: 7.46,
		hr: 15.51,
	},
	88: {
		combined: 19.31,
		hs: 7.38,
		hr: 15.33,
	},
	89: {
		combined: 19.09,
		hs: 7.29,
		hr: 15.16,
	},
	90: {
		combined: 18.88,
		hs: 7.21,
		hr: 14.99,
	},
	91: {
		combined: 19.22,
		hs: 7.24,
		hr: 15.37,
	},
	92: {
		combined: 19.01,
		hs: 7.16,
		hr: 15.21,
	},
	93: {
		combined: 18.81,
		hs: 7.09,
		hr: 15.04,
	},
	94: {
		combined: 18.61,
		hs: 7.01,
		hr: 14.88,
	},
	95: {
		combined: 18.41,
		hs: 6.94,
		hr: 14.73,
	},
	96: {
		combined: 18.22,
		hs: 6.86,
		hr: 14.42,
	},
	97: {
		combined: 18.03,
		hs: 6.79,
		hr: 14.42,
	},
	98: {
		combined: 17.85,
		hs: 6.72,
		hr: 14.28,
	},
	99: {
		combined: 17.67,
		hs: 6.66,
		hr: 14.13,
	},
	100: {
		combined: 17.49,
		hs: 6.59,
		hr: 13.99,
	},
};

export const dataIE = {
	1: {
		combined: 366,
		hr: 219,
		hs: 188,
	},
	2: {
		combined: 183,
		hr: 109.5,
		hs: 94,
	},
	3: {
		combined: 122,
		hr: 73,
		hs: 62.67,
	},
	4: {
		combined: 91.5,
		hr: 54.75,
		hs: 47,
	},
	5: {
		combined: 73.2,
		hr: 43.8,
		hs: 37.6,
	},
	6: {
		combined: 76.83,
		hr: 47,
		hs: 38.33,
	},
	7: {
		combined: 65.86,
		hr: 40.29,
		hs: 32.86,
	},
	8: {
		combined: 57.63,
		hr: 35.25,
		hs: 28.75,
	},
	9: {
		combined: 51.22,
		hr: 31.33,
		hs: 25.56,
	},
	10: {
		combined: 46.1,
		hr: 28.2,
		hs: 23,
	},
	11: {
		combined: 43.55,
		hr: 27.18,
		hs: 21.27,
	},
	12: {
		combined: 41.58,
		hr: 26.58,
		hs: 19.92,
	},
	13: {
		combined: 41.46,
		hr: 26.85,
		hs: 18.77,
	},
	14: {
		combined: 40.64,
		hr: 27.07,
		hs: 17.79,
	},
	15: {
		combined: 40.6,
		hr: 27.27,
		hs: 16.93,
	},
	16: {
		combined: 39.94,
		hr: 27.13,
		hs: 16.19,
	},
	17: {
		combined: 38.76,
		hr: 27,
		hs: 15.53,
	},
	18: {
		combined: 38.28,
		hr: 26.89,
		hs: 14.94,
	},
	19: {
		combined: 37.84,
		hr: 26.79,
		hs: 14.42,
	},
	20: {
		combined: 37.45,
		hr: 26.7,
		hs: 13.95,
	},
	21: {
		combined: 36.14,
		hr: 27.33,
		hs: 13.76,
	},
	22: {
		combined: 34.5,
		hr: 26.09,
		hs: 13.14,
	},
	23: {
		combined: 33,
		hr: 24.96,
		hs: 12.57,
	},
	24: {
		combined: 31.63,
		hr: 23.92,
		hs: 12.04,
	},
	25: {
		combined: 31.96,
		hr: 24.56,
		hs: 11.96,
	},
	26: {
		combined: 30.73,
		hr: 23.62,
		hs: 11.5,
	},
	27: {
		combined: 29.59,
		hr: 22.74,
		hs: 11.07,
	},
	28: {
		combined: 28.54,
		hr: 21.93,
		hs: 10.68,
	},
	29: {
		combined: 27.55,
		hr: 21.17,
		hs: 10.31,
	},
	30: {
		combined: 27.97,
		hr: 21.63,
		hs: 10.3,
	},
	31: {
		combined: 27.06,
		hr: 20.94,
		hs: 9.97,
	},
	32: {
		combined: 26.22,
		hr: 20.28,
		hs: 9.66,
	},
	33: {
		combined: 25.42,
		hr: 19.67,
		hs: 9.36,
	},
	34: {
		combined: 24.68,
		hr: 19.09,
		hs: 9.09,
	},
	35: {
		combined: 24.83,
		hr: 19.54,
		hs: 9.11,
	},
	36: {
		combined: 24.14,
		hr: 19,
		hs: 8.86,
	},
	37: {
		combined: 23.49,
		hr: 18.49,
		hs: 8.62,
	},
	38: {
		combined: 22.87,
		hr: 18,
		hs: 8.39,
	},
	39: {
		combined: 22.28,
		hr: 17.54,
		hs: 8.18,
	},
	40: {
		combined: 22.48,
		hr: 17.85,
		hs: 8.23,
	},
	41: {
		combined: 21.93,
		hr: 17.41,
		hs: 8.02,
	},
	42: {
		combined: 21.4,
		hr: 17,
		hs: 7.83,
	},
	43: {
		combined: 20.91,
		hr: 16.6,
		hs: 7.65,
	},
	44: {
		combined: 20.43,
		hr: 16.23,
		hs: 7.48,
	},
	45: {
		combined: 20.64,
		hr: 16.53,
		hs: 7.53,
	},
	46: {
		combined: 20.2,
		hr: 16.17,
		hs: 7.37,
	},
	47: {
		combined: 19.77,
		hr: 15.83,
		hs: 7.21,
	},
	48: {
		combined: 19.35,
		hr: 15.5,
		hs: 7.06,
	},
	49: {
		combined: 18.96,
		hr: 15.18,
		hs: 6.92,
	},
	50: {
		combined: 19.58,
		hr: 15.88,
		hs: 6.98,
	},
	51: {
		combined: 19.2,
		hr: 15.57,
		hs: 6.84,
	},
	52: {
		combined: 18.83,
		hr: 15.27,
		hs: 6.71,
	},
	53: {
		combined: 18.47,
		hr: 14.98,
		hs: 6.58,
	},
	54: {
		combined: 18.13,
		hr: 14.7,
		hs: 6.46,
	},
	55: {
		combined: 17.8,
		hr: 14.44,
		hs: 6.35,
	},
	56: {
		combined: 17.48,
		hr: 14.18,
		hs: 6.23,
	},
	57: {
		combined: 17.18,
		hr: 13.93,
		hs: 6.12,
	},
	58: {
		combined: 16.88,
		hr: 13.69,
		hs: 6.02,
	},
	59: {
		combined: 16.59,
		hr: 13.46,
		hs: 5.92,
	},
	60: {
		combined: 17.15,
		hr: 14.07,
		hs: 5.98,
	},
	61: {
		combined: 16.87,
		hr: 13.84,
		hs: 5.89,
	},
	62: {
		combined: 16.6,
		hr: 13.61,
		hs: 5.79,
	},
	63: {
		combined: 16.33,
		hr: 13.4,
		hs: 5.7,
	},
	64: {
		combined: 16.08,
		hr: 13.19,
		hs: 5.61,
	},
	65: {
		combined: 15.83,
		hr: 12.98,
		hs: 5.52,
	},
	66: {
		combined: 15.59,
		hr: 12.79,
		hs: 5.44,
	},
	67: {
		combined: 15.36,
		hr: 12.6,
		hs: 5.36,
	},
	68: {
		combined: 15.13,
		hr: 12.41,
		hs: 5.28,
	},
	69: {
		combined: 14.91,
		hr: 12.23,
		hs: 5.2,
	},
	70: {
		combined: 15.41,
		hr: 12.7,
		hs: 5.27,
	},
	71: {
		combined: 15.2,
		hr: 12.52,
		hs: 5.2,
	},
	72: {
		combined: 14.99,
		hr: 12.35,
		hs: 5.13,
	},
	73: {
		combined: 14.78,
		hr: 12.18,
		hs: 5.05,
	},
	74: {
		combined: 14.58,
		hr: 12.01,
		hs: 4.99,
	},
	75: {
		combined: 14.39,
		hr: 11.85,
		hs: 4.92,
	},
	76: {
		combined: 14.2,
		hr: 11.7,
		hs: 4.86,
	},
	77: {
		combined: 14.01,
		hr: 11.55,
		hs: 4.79,
	},
	78: {
		combined: 13.83,
		hr: 11.4,
		hs: 4.73,
	},
	79: {
		combined: 13.66,
		hr: 11.25,
		hs: 4.67,
	},
	80: {
		combined: 14.11,
		hr: 11.68,
		hs: 4.74,
	},
	81: {
		combined: 13.94,
		hr: 11.53,
		hs: 4.68,
	},
	82: {
		combined: 13.77,
		hr: 11.39,
		hs: 4.62,
	},
	83: {
		combined: 13.6,
		hr: 11.25,
		hs: 4.57,
	},
	84: {
		combined: 13.44,
		hr: 11.12,
		hs: 4.51,
	},
	85: {
		combined: 13.28,
		hr: 10.99,
		hs: 4.46,
	},
	86: {
		combined: 13.13,
		hr: 10.86,
		hs: 4.41,
	},
	87: {
		combined: 12.98,
		hr: 10.74,
		hs: 4.36,
	},
	88: {
		combined: 12.83,
		hr: 10.61,
		hs: 4.31,
	},
	89: {
		combined: 12.69,
		hr: 10.49,
		hs: 4.26,
	},
	90: {
		combined: 12.99,
		hr: 10.82,
		hs: 4.32,
	},
	91: {
		combined: 12.85,
		hr: 10.7,
		hs: 4.27,
	},
	92: {
		combined: 12.71,
		hr: 10.59,
		hs: 4.23,
	},
	93: {
		combined: 12.57,
		hr: 10.47,
		hs: 4.18,
	},
	94: {
		combined: 12.44,
		hr: 10.36,
		hs: 4.14,
	},
	95: {
		combined: 12.31,
		hr: 10.25,
		hs: 4.09,
	},
	96: {
		combined: 12.18,
		hr: 10.15,
		hs: 4.05,
	},
	97: {
		combined: 12.05,
		hr: 10.04,
		hs: 4.01,
	},
	98: {
		combined: 11.93,
		hr: 9.94,
		hs: 3.97,
	},
	99: {
		combined: 11.81,
		hr: 9.84,
		hs: 3.93,
	},
};
