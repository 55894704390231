import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from '../../atoms/nav-link';
import { sendTrackingData } from '../../../utils';

const MobileMegaMenu = forwardRef(
	(
		{ links, className, listClassName, parentTrackingId, ...navAttributes },
		ref
	) => {
		const handleClickTracking = (clickTitle) => {
			const gtmStartedEvent = window.dataLayer?.find(
				(element) => element['gtm.start']
			);
			if (!gtmStartedEvent) return;

			sendTrackingData('click', {
				event_name: 'navigation',
				click_action: 'navigate',
				click_type: 'link',
				click_text: parentTrackingId,
				click_title: clickTitle,
			});
		};

		return (
			<nav
				ref={ref}
				className="pb-3 bg-brand-pale-400"
				{...navAttributes}
			>
				<ul className={clsx('w-full px-3', listClassName)}>
					{links.map((link) => (
						<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
							<NavLink
								className="w-full text-lg font-centra-book hover:!bg-transparent focus:rounded-none"
								to={link.link}
								icon={link.icon}
								onClick={() => handleClickTracking(link.title)}
							>
								{link.title}
							</NavLink>
						</li>
					))}
				</ul>
			</nav>
		);
	}
);

export { MobileMegaMenu };

MobileMegaMenu.defaultProps = {
	className: '',
	listClassName: '',
	parentTrackingId: '',
};

MobileMegaMenu.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subText: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
	listClassName: PropTypes.string,
	parentTrackingId: PropTypes.string,
};
