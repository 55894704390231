/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { useStaticQuery, graphql } from 'gatsby';
import { Button } from '../../atoms/button';
import { CallUs } from '../../atoms/call-us';

import { useDrawer } from '../../../contexts/drawer-context';

import { refProp } from '../../../types';

const CallCtas = ({ drawerRef, onClickProp, siteSettings }) => {
	const { setDrawerRef } = useDrawer();

	const data = useStaticQuery(graphql`
		query {
			datoCmsSiteSpecificSetting {
				callbackButtonCopy
			}
		}
	`);

	return (
		<div
			className={clsx(
				'flex',
				'flex-wrap',
				'flex-col',
				'items-center',
				'justify-center',
				'md:justify-start',
				'md:items-start',
				'space-y-4',
				'lg:space-y-0',
				'lg:space-x-8',
				'lg:flex-row',
				'lg:items-center'
			)}
		>
			<CallUs altMobile />
			<span className="max-lg:hidden">OR</span>
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					onClickProp({
						click_text:
							data.datoCmsSiteSpecificSetting.callbackButtonCopy,
					});
				}}
				variant="White"
			>
				{siteSettings.callbackButtonCopyCa ||
					siteSettings.callbackButtonCopy ||
					data.datoCmsSiteSpecificSetting.callbackButtonCopy}
			</Button>
		</div>
	);
};

CallCtas.defaultProps = {
	onClickProp: () => {},
};

CallCtas.propTypes = {
	drawerRef: refProp.isRequired,
	onClickProp: PropTypes.func,
};

export { CallCtas };
