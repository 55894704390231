// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/faq';
import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { FeefoTestimonials } from '../components/molecules/feefo';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { TestimonialsFAQ } from '../components/organisms/testimonials-faq';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { Head } from '../components/templates/head';
import { pageTypes, getBreadcrumbs } from '../utils/url-helpers';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { FAQ } from '../components/organisms/faq-new';
import { ClientLogos } from '../components/atoms/client-logos';

const FaqPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsFaqPage.noIndex,
		noFollow: data.datoCmsFaqPage.noFollow,
		metaInformation: data.datoCmsFaqPage.metaInformation,
		fallbackTitle: data.datoCmsFaqPage.title,
		fallbackDescription: data.datoCmsFaqPage.longIntroduction,
		canonicalUrl: data.datoCmsFaqPage?.canonicalUrl || location.href,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.faq,
			location,
		}),
	};

	const faqContent = {
		title: data.datoCmsFaqPage.faqContainer.title,
		subText: data.datoCmsFaqPage.faqContainer.subText,
		questions: data.datoCmsFaqPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const testimonialsContent = {
		...data.datoCmsFaqPage.testimonials,
		title: data.datoCmsFaqPage.testimonials.title.value.document.children,
		slides: data.datoCmsFaqPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const adviceBannerContent = {
		...data.datoCmsFaqPage.adviceBanner,
		title: data.datoCmsFaqPage.adviceBanner.title.value.document.children,
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsFaqPage.internal.type,
		data.datoCmsFaqPage
	);
	const { locale } = data.datoCmsFaqPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<FAQ faqContent={faqContent} />
			<ClientLogos pathname={location.pathname} />
			<TestimonialsFAQ
				drawerRef={adviceFormRef}
				testimonialsContent={testimonialsContent}
			/>
			{locale !== 'en-CA' ? <FeefoTestimonials locale={locale} /> : null}
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>
			<TrustpilotReviews
				locale={locale}
				className="pt-12 !pb-0"
				wrapperClassName="pb-12 border-b-1 border-b-blue-200"
			/>

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedAboutUs = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<FaqPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedAboutUs;

export const query = graphql`
	query aboutUs($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsFaqPage(locale: { eq: $locale }) {
			locale
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			originalId
			internal {
				type
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
		}
	}
`;
