import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tag } from '../../atoms/tag';

const TagsList = ({ tags, category, className }) => {
	if (tags) {
		return (
			<ul className={clsx('flex', className)}>
				{tags.map((tag) => (
					<Tag key={tag} tag={tag} />
				))}
			</ul>
		);
	}
	return (
		<ul className={clsx('flex', className)}>
			<Tag tag={category} />
		</ul>
	);
};

export { TagsList };

TagsList.defaultProps = {
	className: '',
	category: '',
	tags: [],
};

TagsList.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string),
	category: PropTypes.string,
	className: PropTypes.string,
};
