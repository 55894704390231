/**
 * To use this somewhere and get the current value of the Marketo context:
 * import { useMarketo } from '../../../contexts/Marketo-context';
 * const { marketoRef, setMarketoRef } = useDrawer();
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MktoForms2PerInstanceManager } from '../utils/marketo';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const MarketoContext = React.createContext(undefined);

const MarketoProvider = ({ children }) => {
	const instanceURLs = [
		'https://pages.peninsulagrouplimited.com',
		'https://app-lon04.marketo.com',
		'https://pages.peninsula-ca.com',
		'https://app-ab31.marketo.com',
	];
	const MktoForms2Instances = new MktoForms2PerInstanceManager(instanceURLs);

	return (
		<MarketoContext.Provider value={MktoForms2Instances}>
			{children}
		</MarketoContext.Provider>
	);
};

MarketoProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

const useMarketo = () => {
	const context = useContext(MarketoContext);
	if (context === undefined) {
		throw new Error('useMarketo must be used within a MarketoProvider');
	}
	return context;
};

export { MarketoProvider, useMarketo };

export default MarketoContext;
