import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from 'gatsby-link';

const RelatedCard = ({ title, intro, to, className, headerLevel }) => {
	const Heading = headerLevel;
	let Component = Link;

	// If someone puts in an external link switch to using <a></a>
	if (to.indexOf('http') === 0) {
		Component = 'a';
	} else if (!to.endsWith('/')) {
		// eslint-disable-next-line no-param-reassign
		to = `${to}/`;
	}

	return (
		<article
			className={clsx(
				'flex flex-col',
				'relative h-full overflow-hidden group',
				'bg-white rounded-sm shadow-card',
				className
			)}
		>
			<header className="p-5 text-white bg-blue-500 text-lg-f hover:bg-blue-400 grow">
				<Component
					to={typeof Component !== 'string' ? to : undefined}
					href={typeof Component === 'string' ? to : undefined}
					className="leading-snug before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:w-full before:h-full hover:underline hover:decoration-1"
				>
					<Heading>{title}</Heading>
				</Component>
			</header>
			<div className="p-5">
				<p className="overflow-hidden text-base-f line-clamp-3 font-centra-light">
					{intro}
				</p>
			</div>
		</article>
	);
};

RelatedCard.defaultProps = {
	headerLevel: 'h3',
	className: '',
};

RelatedCard.propTypes = {
	headerLevel: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']),
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	intro: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

export { RelatedCard };
