// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../../atoms/button';
import rightArrow from './icons/arrow_pointing_right.webp';
import leftArrow from './icons/arrow_pointing_left.webp';

const CardsWithImageVideoCampaignCA = (
	{ cardsWithImageVideoCampaignCAContent, modalRef },
	className
) => {
	const { title, textWithImageSections } =
		cardsWithImageVideoCampaignCAContent;
	const { setModalRef } = useModal();

	return (
		<div
			className={`w-full xs:pb-5 ${className}`}
			style={{
				background:
					'linear-gradient(145deg, rgba(238,245,255,1) 0%, rgba(238,245,255,1) 50%, rgba(215,221,249,1) 100%)',
			}}
		>
			<div className="flex flex-col gap-10 px-6 pt-7 pb-6 lg:px-12 lg:pb-32 lg:pt-24 xl:pt-20 xl:pb-24 mx-auto lg:max-w-m-screen">
				<div className="-mb-3 lg:pb-5">
					{createHeadingHighlightCA({
						headings: title.value.document.children[0].children,
						variant: 'red',
						className: 'text-center',
					})}
				</div>

				{textWithImageSections.map((_, idx) => (
					<div className="grid grid-flow-row lg:grid-flow-col grid-cols-1 lg:grid-cols-3 auto-rows-max rounded-[.75rem] px-2 lg:gap-5 bg-white shadow-sm">
						<div
							className={`col-span-1 flex flex-col justify-end gap-5 p-5 h-50 bg-no-repeat -mx-2 ${
								idx % 2 === 0
									? 'order-first lg:order-first rounded-t-[.75rem] lg:rounded-l-[.75rem] lg:rounded-r-none'
									: 'order-first lg:order-last rounded-t-[.75rem] rounded-b-none lg:rounded-r-[.75rem] lg:rounded-l-none '
							}`}
							style={{
								backgroundSize: 'cover',
								backgroundImage: `url(${textWithImageSections[idx].backgroundImage.url})`,
								backgroundColor: 'rgba(0,0,0,.5)',
								backgroundBlendMode: 'multiply',
							}}
						>
							<div className="text-2xl font-bold text-white max-w-xs">
								{createHeadingHighlightCA({
									headings:
										textWithImageSections[idx].title.value
											.document.children[0].children,
									variant: 'white',
									className: `break-words max-w-[300px] drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.4)]`,
								})}
								{/* right arrow desktop */}
								<img
									src={rightArrow}
									alt=""
									className={`ml-auto mt-3 -mb-3 ${
										idx % 2 === 0
											? 'hidden lg:block'
											: 'hidden'
									}`}
								/>
								{/* right arrow mobile */}
								<img
									src={leftArrow}
									alt=""
									className={`ml-auto -mt-3 -mb-10 ${
										idx % 2 === 0
											? 'block scale-50 rotate-[235deg] lg:hidden'
											: 'hidden'
									}`}
								/>
								{/* left arrow desktop */}
								<img
									src={leftArrow}
									alt=""
									className={`mr-auto -mt-3 -mb-7 ${
										idx % 2 !== 0
											? 'hidden  lg:block'
											: 'hidden'
									}`}
								/>
								{/* left arrow mobile */}
								<img
									src={leftArrow}
									alt=""
									className={`ml-auto -mt-3 -mb-10 ${
										idx % 2 !== 0
											? 'block scale-50 rotate-[235deg] lg:hidden'
											: 'hidden'
									}`}
								/>
							</div>
							<div>
								<Button
									onClick={() => setModalRef(modalRef)}
									className="hidden md:block transition delay-150 duration-1000 inline-flex items-center px-6 py-4 mt-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
								>
									{textWithImageSections[idx].primaryLinkCopy}
								</Button>
								{/* for mobile */}
								<Button
									onClick={() => {
										window.location.href = '#adviceform';
									}}
									className="block md:hidden transition delay-150 duration-1000 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 max-w-fit break-words"
								>
									{textWithImageSections[idx].primaryLinkCopy}
								</Button>
							</div>
						</div>
						{/* next section */}
						<div className="col-span-2 lg:rounded-r-[.75rem] py-5 grid grid-flow-row grid-cols-1 lg:grid-cols-2 auto-rows-max content-center items-stretch">
							{textWithImageSections[idx].textPerImageBlock.map(
								(item) => (
									<div className="col-span-1 rounded-[.75rem] p-3 m-3 border-2 border-[#E2E9F6] font-bold flex flex-rows items-center gap-5 hover:shadow-sm ease-out duration-75 delay-75 transform transition shadow-sm">
										<div className="min-w-[40px] min-h-[40px] flex items-center justify-center">
											<img src={item.image.url} alt="" />
										</div>
										<div className="font-castledown-regular text-[16px] leading-[1.35rem]">
											{item.text}
										</div>
									</div>
								)
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CardsWithImageVideoCampaignCA;

