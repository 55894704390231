import React from 'react';

const CheckIcon = () => (
	<svg
		className="shrink-0"
		width="23"
		height="24"
		viewBox="0 0 22 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="11" cy="11.2032" r="10" fill="#72D7AE" />
		<path
			d="M6 12.7032L9 15.7032L15.5 8.20325"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export { CheckIcon };
