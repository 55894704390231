// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import {
	isHeading,
	isLink,
	isList,
	isParagraph,
	isRoot,
} from 'datocms-structured-text-utils';
import { graphql } from 'gatsby';
import React, { useRef } from 'react';
import { renderMarkRule, renderNodeRule, StructuredText } from 'react-datocms';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PBSLink } from '../components/atoms/link';
import { TickList } from '../components/atoms/tick-list';
import * as rteContentStyles from '../components/organisms/rte-content/index.module.css';
import { InlineRecord } from '../components/organisms/rte-content/inline-record';
import { Head } from '../components/templates/head';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import { DefaultLayout } from '../layouts/default';
import '../styles/blog.css';
import { parseMarkdown } from '../utils/parse-md';
import { parseMarkdownUK } from '../utils/parse-md-uk';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';
// markup
const LegalDocumentPage = ({ location, data }) => {
	const containerStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '30vh',
		width: '100vw',
		backgroundImage: `url(https://www.datocms-assets.com/64336/1712342621-hero_privacy.jpg)`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	};

	const contentMD = data.datoCmsLegalDocument.content;
	const {
		locale,
		showMarkdownContent,
		showStructureContent,
		structuredContent,
	} = data.datoCmsLegalDocument;
	const parsedMD =
		locale === 'en' ? parseMarkdownUK(contentMD) : parseMarkdown(contentMD);
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.legalDocuments,
			location,
			data: data.datoCmsLegalDocument,
		}),
	};
	const metaContent = {
		noIndex: data.datoCmsLegalDocument.noIndex,
		noFollow: data.datoCmsLegalDocument.noFollow,
		metaInformation: data.datoCmsLegalDocument.metaInformation,
		fallbackTitle: data.datoCmsLegalDocument.title,
		fallbackDescription: data.datoCmsLegalDocument.longIntroduction || '',
		canonicalUrl: data.datoCmsLegalDocument?.canonicalUrl || location?.href,
	};
	let headingId = 0;
	const headingStyles = {
		h2: 'my-4 md:my-8 first:mt-0 text-2xl md:text-3xl font-castledown-regular',
		h3: 'my-4 md:my-5 text-xl md:text-2xl',
		h4: 'my-4 md:my-5 text-lg md:text-xl',
		h5: 'my-4 md:my-5 text-lg md:text-xl',
		h6: 'my-4 md:my-5 text-lg md:text-xl',
	};
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const isMarkdown = showMarkdownContent && !!contentMD;
	const isStructuredContent = showStructureContent && !!structuredContent;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsLegalDocument.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<div style={containerStyle}>
				<div className="text-center text-white lg:text-4xl text-3xl m-2">
					<h1>{data.datoCmsLegalDocument.title}</h1>
				</div>
			</div>
			<div className="max-w-7xl mx-auto text-lg lg:text-justify md:p-8 p-3 leading-normal flex flex-wrap overflow-auto !sm:text-sm">
				{isStructuredContent && !isMarkdown ? (
					<StructuredText
						data={structuredContent}
						renderInlineRecord={({ record }) => (
							<InlineRecord
								record={record}
								buttonFormRef={adviceFormRef || ''}
								drawerRef={drawerRef || ''}
							/>
						)}
						customNodeRules={[
							renderNodeRule(
								isHeading,
								({ node, children, key }) => {
									const HeadingTag = `h${node.level}`;
									const headingClassNames =
										headingStyles[HeadingTag];

									let id = null;
									if (node.level === 2) {
										id = `t-${headingId}`;
										headingId += 1;
									}

									return (
										<HeadingTag
											className={clsx(headingClassNames)}
											key={key}
											id={id}
										>
											{children}
										</HeadingTag>
									);
								}
							),
							renderNodeRule(
								isLink,
								({ node, children, key }) => (
									<PBSLink
										to={node.url}
										variant="Link"
										key={key}
									>
										{children}
									</PBSLink>
								)
							),
							renderNodeRule(
								isList,
								({ node, children, key }) => {
									let ListTag;
									let listClassName;

									if (node.style === 'bulleted') {
										ListTag = 'ul';
										listClassName = 'list-disc';
									}
									if (node.style === 'numbered') {
										ListTag = 'ol';
										listClassName = 'list-decimal';
									}

									return (
										<ListTag
											className={clsx(
												'mb-4 md:mb-5 text-lg font-centra-light md:text-xl list-inside',
												listClassName,
												rteContentStyles.list
											)}
											key={
												key +
												node.style +
												rteContentStyles.list
											}
										>
											{children}
										</ListTag>
									);
								}
							),
							renderNodeRule(
								isParagraph,
								({
									adapter: { renderNode },
									node,
									children,
									key,
									ancestors,
								}) => {
									if (
										node.children[0].type === 'inlineItem'
									) {
										return (
											<React.Fragment key={key}>
												{children}
											</React.Fragment>
										);
									}
									if (isRoot(ancestors[0])) {
										return renderNode(
											'p',
											{
												key,
												className:
													'mb-4 text-lg md:mb-5 font-centra-light md:text-xl last-of-type:mb-0',
											},
											children
										);
									}
									return (
										<React.Fragment key={key}>
											{children}
										</React.Fragment>
									);
								}
							),
						]}
						renderBlock={({ record }) => {
							// eslint-disable-next-line no-underscore-dangle
							switch (record.__typename) {
								case 'DatoCmsTickListContainer':
									return (
										<TickList
											className="grid text-lg gap-y-5 gap-x-8 md:grid-cols-2 mt-lg-f md:text-xl"
											listItemClassName="flex items-start !mb-0"
											textClassName="mb-[6px]"
											points={record.listItems}
										/>
									);
								case 'DatoCmsBlogTable':
									return (
										<>
											<div
												dangerouslySetInnerHTML={{
													__html: record.tableContent,
												}}
												className="my-8 md:mb-5 text-lg font-centra-light md:text-xl blog-custom "
											/>
										</>
									);
								case 'DatoCmsImageBlock':
									return (
										<div className="grid grid-cols-1 gap-5">
											<div className="bg-white flex flex-col items-center my-2">
												<img
													src={record.image.url}
													alt={record?.image.alt}
													// width={record.image.width}
													// height={record.image.height}
													className="object-cover bg-white w-full md:w-[90%] h-auto"
												/>
												{record.hasImageCredit && (
													<div className=" bg-brand-pale-400 flex items-start justify-start border-blue-200 border-2 w-full md:w-[90%] h-auto">
														<span className="text-lg text-black py-2 px-3 font-centra-medium">
															{record.imageCredit}
														</span>
													</div>
												)}
											</div>
										</div>
									);
								case 'DatoCmsQuoteBlock':
									return (
										<blockquote className=" relative p-5 my-5 text-lg font-centra-light bg-brand-pale-400 rounded-sm border-blue-200 border-2">
											<div className="flex flex-col border-l-[6px] border-blue-400 pl-5">
												<cite className="mb-4  text-xl md:text-2xl font-centra-light">
													{record.quote}
												</cite>
												<span className="text-sm font-bold text-brand-blue-200">
													{record.quoteCredit}
												</span>
											</div>
										</blockquote>
									);
								default:
									return null;
							}
						}}
						customMarkRules={[
							renderMarkRule('strong', ({ children, key }) => (
								<span className="font-centra-book" key={key}>
									{children}
								</span>
							)),
						]}
					/>
				) : (
					<>
						<div
							dangerouslySetInnerHTML={{ __html: parsedMD }}
							className="break-words table td legal"
						/>
					</>
				)}
			</div>
		</GoogleReCaptchaProvider>
	);
};

const WrappedLegalDocument = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<LegalDocumentPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedLegalDocument;

export const query = graphql`
	query LegalDocumentQuery($locale: String!, $slug: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsLegalDocument(locale: { eq: $locale }, slug: { eq: $slug }) {
			id
			noFollow
			noIndex
			originalId
			slug
			title
			content
			locale
			canonicalUrl
			showMarkdownContent
			showStructureContent
			structuredContent {
				value
				blocks {
					... on DatoCmsBlogTable {
						__typename
						id: originalId
						tableContent
					}
					... on DatoCmsQuoteBlock {
						__typename
						id: originalId
						quote
						quoteCredit
						model {
							apiKey
						}
					}

					... on DatoCmsImageBlock {
						__typename
						id: originalId
						hasImageCredit
						imageCredit
						image {
							alt
							url
							width
							height
						}
						model {
							apiKey
						}
					}
					... on DatoCmsMediaTable {
						__typename
						id: originalId
						tableContent
					}
				}
				links {
					... on DatoCmsAdviceBanner {
						__typename
						id: originalId
						title {
							value
						}
						content
						ctaLabel
						ctaLink
						ctaOpenForm
					}
					... on DatoCmsNewsletterSignUp {
						__typename
						id: originalId
						subText
						newsletterSignUpTitle
					}
					... on DatoCmsCtaBanner {
						__typename
						id: originalId
						content
						linkCopy
						link
						ctaBannerTitle
						ctaOpenForm
						image {
							alt
							url
							gatsbyImageData(
								width: 415
								height: 315
								layout: CONSTRAINED
							)
						}
					}
				}
			}
			metaInformation {
				description
				title
			}
			allSeoLocales: _allSlugLocales {
				locale
			}
		}
	}
`;
