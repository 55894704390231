import React from 'react';
import PropTypes from 'prop-types';
import { TabsProvider } from '../../../contexts/tabs-context';
import { TabList } from './tab-list';
import { TabPanel } from './tab-panel';

const Tabs = ({ children, className, tabsTitle }) => (
	<TabsProvider>
		<section aria-label={tabsTitle} className={className}>
			{children}
		</section>
	</TabsProvider>
);

export { Tabs, TabList, TabPanel };

Tabs.defaultProps = {
	className: '',
};

Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	tabsTitle: PropTypes.string.isRequired,
};
