// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import Redswipe from '../../../assets/red-swipe.png';

const ParagraphContent = ({ paragraphContent }) => {
	const { title, content, content2, as } = paragraphContent;

	const Heading = as || 'h2';

	return (
		<section className="mx-auto mt-10 text-center md:mb-10 lg:container">
			<div className={clsx('py-10', 'md:p-10', 'md:pr-0')}>
				<div className="max-w-5.5xl gap-4 md:gap-8 md:flex-row ">
					<div className="p-3">
						<Heading className="mt-2 text-3xl lg:text-5xl font-castledown-regular">
							{title}
						</Heading>
						<img
							className="object-fill h-[15px] w-[156px] mx-auto my-10"
							src={Redswipe}
							alt="Red Swipe"
						/>
						<p className="mt-6 text-2xl leading-10 lg:mt-8 lg:text-2xl font-centra-book">
							{content}
						</p>
						<p className="mt-6 text-2xl leading-10 lg:mt-8 lg:text-2xl font-centra-book">
							{content2}
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

ParagraphContent.defaultProps = {
	paragraphContent: {
		title: '',
		content: '',
		content2: '',
	},
};

ParagraphContent.propTypes = {
	paragraphContent: PropTypes.shape({
		title: PropTypes.string,
		content: PropTypes.string,
		content2: PropTypes.string,
	}),
};

export { ParagraphContent };
