import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconCardList } from '../../molecules/icon-card-list-ppc';

const IconCards = ({
	uspTitle,
	id,
	variant,
	showContentLinks,
	iconCardsContent,
	containerClassName,
	wrapperClassName,
	borderBottom,
	borderClassNames,
	isTimeline,
}) => {
	const { uspCards } = iconCardsContent;

	return (
		<section
			className={clsx(
				variant === 'Dark'
					? 'bg-blue-500 py-lg-f md:py-lg-f'
					: 'mt-6 lg:mt-12',
				containerClassName
			)}
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'pl-4 mx-auto md:px-8 max-w-m-screen',
					wrapperClassName
				)}
			>
				{/* <h2 className='mb-4 text-3xl text-center lg:text-4xl'>{uspTitle}</h2> */}
				<h2 className="mb-5 mr-3 text-4xl text-center lg:mr-0 lg:text-4xl font-castledown-regular">
					{uspTitle}
				</h2>
				<div className="-ml-[8px] -mt-[8px]">
					<IconCardList
						cards={uspCards}
						variant={variant}
						showContentLinks={showContentLinks}
						isTimeline={isTimeline}
					/>
				</div>
			</div>
			{borderBottom ? <hr className={clsx(borderClassNames)} /> : null}
		</section>
	);
};

IconCards.defaultProps = {
	id: 'hr-services',
	uspTitle: '',
	showContentLinks: true,
	variant: 'Default',
	containerClassName: '',
	wrapperClassName: '',
	borderBottom: false,
	borderClassNames: '',
	isTimeline: false,
};

IconCards.propTypes = {
	id: PropTypes.string,
	uspTitle: PropTypes.string,
	variant: PropTypes.string,
	isTimeline: PropTypes.bool,
	showContentLinks: PropTypes.bool,
	iconCardsContent: PropTypes.shape({
		uspTitle: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	containerClassName: PropTypes.string,
	wrapperClassName: PropTypes.string,
	borderBottom: PropTypes.bool,
	borderClassNames: PropTypes.string,
};

export { IconCards };
