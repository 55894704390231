// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/partner';

import { Head } from '../components/templates/head';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import { Drawer } from '../components/atoms/drawer';
import { useDrawer } from '../contexts/drawer-context';

import { IconCards } from '../components/organisms/icon-cards-partner';
import { AdviceForm } from '../components/organisms/advice-form-partner';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice-partner';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';

import { MainHeader } from '../components/molecules/main-header-partner';
import { ClientLogosPartner } from '../components/atoms/client-logos-partner';
import { ParallaxSlider } from '../components/molecules/parallax-slider-partner';
import { PartnerInfoSection } from '../components/molecules/partner-info-section';
import { ParagraphContent } from '../components/molecules/paragraph-content';

import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { HrefLang } from '../components/templates/hrefLang';

const IndexPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const { locale } = data.datoCmsPartnerPage;
	const metaContent = {
		noIndex: data.datoCmsPartnerPage.noIndex,
		noFollow: data.datoCmsPartnerPage.noFollow,
		metaInformation: data.datoCmsPartnerPage.metaInformation,
		fallbackTitle: data.datoCmsPartnerPage.title,
		fallbackDescription: data.datoCmsPartnerPage.longIntroduction,
		canonicalUrl: data.datoCmsPartnerPage?.canonicalUrl || location.href,
	};

	const headerCTAContent = {
		...data.datoCmsPartnerPage.partnerHeaderCta,
		header: data.datoCmsPartnerPage.partnerHeaderCta.header.value.document
			.children,
	};

	const logoContent = data.datoCmsPartnerPage.partnerLogos;

	const partnerPageSlider = {
		...data.datoCmsPartnerPage.partnerPageSlider,
		title: data.datoCmsPartnerPage.partnerPageSlider.title.value.document
			.children,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsPartnerPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsPartnerPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsPartnerPage?.customFormField[0]?.customFormButton,
	};

	const slide = {
		...data.datoCmsPartnerPage.partnerInfoSection,
		title: data.datoCmsPartnerPage.partnerInfoSection.title,
	};

	const { partnerLogoTopBottom } = data.datoCmsPartnerPage;

	const { displayPolicyNumber } = data.datoCmsPartnerPage;

	const paragraphContent = {
		title: data.datoCmsPartnerPage.paragraphTitle,
		content: data.datoCmsPartnerPage.paragraphText,
		content2: data.datoCmsPartnerPage.paragraphText2,
	};

	const index = 1;

	const partnerName = data.datoCmsPartnerPage.title;

	const uspBanner = data.datoCmsPartnerPage.uspCtaCardsPartner;
	const { cardTitle1 } = data.datoCmsPartnerPage;

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(headerCTAContent?.primaryImage),
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPartnerPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<MainHeader
				urlLocale={locale}
				drawerRef={adviceFormRef}
				{...headerCTAContent}
			/>
			{partnerLogoTopBottom ? (
				<>
					<ClientLogosPartner
						pathname={location.pathname}
						logoContent={logoContent}
					/>
					<ClientLogosPartner
						pathname={location.pathname}
						logoContent={logoContent}
						isMobile
					/>
				</>
			) : null}
			<ParallaxSlider parallaxSliderContent={partnerPageSlider} />
			<PartnerInfoSection
				urlLocale={locale}
				drawerRef={adviceFormRef}
				partnerInfoSection={slide}
				index={index}
			/>
			{data.datoCmsPartnerPage.uspCtaCardsBannerDisplayOnPage1 && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					cardtitle={cardTitle1}
					drawerRef={adviceFormRef}
				/>
			)}

			<ParagraphContent paragraphContent={paragraphContent} />
			{!partnerLogoTopBottom ? (
				<>
					<ClientLogosPartner
						pathname={location.pathname}
						logoContent={logoContent}
						className2="-mt-16"
					/>
					<ClientLogosPartner
						pathname={location.pathname}
						logoContent={logoContent}
						isMobile
					/>
				</>
			) : null}
			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
							partnerName={partnerName}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
							partnerName={partnerName}
							customFormField={customFormField}
							displayPolicyNumber={displayPolicyNumber}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedHome1 = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		partnerNumber={data.datoCmsPartnerPage.partnerNumber}
	>
		<IndexPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedHome1;

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPartnerPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			uspCtaCardsBannerDisplayOnPage1
			cardTitle1
			title
			slug
			partnerNumber
			paragraphTitle
			paragraphText
			paragraphText2
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			partnerHeaderCta {
				header {
					value
				}
				logo {
					alt
					url
					gatsbyImageData(width: 128, height: 75, layout: CONSTRAINED)
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				showGoogleReview
				primaryLinkOpenForm
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
			}
			partnerLogoTopBottom
			displayPolicyNumber
			partnerLogos {
				title
				logoOne {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoTwo {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoThree {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoFour {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoFive {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoSix {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoNumber
			}
			imageContent {
				id
				title
				content
				linkCopy
				link
				image {
					url
					gatsbyImageData(
						width: 600
						height: 500
						layout: CONSTRAINED
					)
					alt
				}
				showCtas
				imageLeftAligned
			}
			partnerPageSlider {
				title {
					value
				}
				slides {
					title
					subText
					image {
						alt
						url
						gatsbyImageData(
							width: 600
							height: 460
							layout: CONSTRAINED
						)
					}
				}
			}
			partnerInfoSection {
				image {
					title
					alt
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				primaryLinkCopy
				primaryLink
				subText
				title
				tickList {
					text
				}
			}
			uspCtaCardsPartner {
				content {
					value
				}
				icon
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			ourSoftware {
				title
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				mobileImage {
					alt
					url
					gatsbyImageData(
						width: 230
						height: 277
						layout: CONSTRAINED
					)
				}
				desktopImage {
					alt
					url
					gatsbyImageData(
						width: 280
						height: 568
						layout: CONSTRAINED
					)
				}
				tickList {
					id
					text
					subText
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			blogContainer {
				id
				title
				subText
				buttonCopy
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}

		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
