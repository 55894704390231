import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { pricingCardProps } from '../../../types';

import { TitlePricingCA } from '../../atoms/title-pricing-ca';
import { TitlePricingCA2 } from '../../atoms/title-pricing-ca-2';
import { PricingCardsCA } from '../../molecules/pricing-cards-ca';
import { PricingCalculatorCA } from '../../molecules/pricing-calculator-ca';
import { PricingTable } from '../pricing-table';
import { GoogleBusinessReviewPricing } from '../../atoms/google-business-review-pricing';
import { Modal } from '../../atoms/modal-v2-ca';
import { useModal } from '../../../contexts/modal-context';
import { PricingFormCA } from '../ca/forms/salesforce/pricing-modal';
import { SuccessFormMessage } from '../ca/forms/salesforce/success-pricing-modal';

const PricingSectionCA = ({ pricingContent, customFormField }) => {
	const priceQuoteFormRef = useRef(null);

	const [priceCalculated, setPriceCalculated] = useState({
		combined: '-',
		hr: '-',
		hs: '-',
	});
	const [employeeCount, setEmployeeCount] = useState(0);
	const [reference, setReference] = useState('');
	const [formTitle, setFormTitle] = useState('');

	const { modalRef } = useModal();

	const {
		title,
		subtitle,
		cards,
		title2,
		subtitle2,
		quoteButtonCopy,
		table,
	} = pricingContent;

	return (
		<section className="relative w-full">
			<Modal
				label="Get a custom price for your business"
				ref={priceQuoteFormRef}
				open={modalRef === priceQuoteFormRef}
				hasClose
				className="!p-0 z-50"
				elevation={50}
			>
				{reference === '' ? (
					<PricingFormCA
						setReference={setReference}
						customFormField={customFormField}
						employeeCount={employeeCount}
						formTitle={formTitle}
					/>
				) : (
					<SuccessFormMessage className="my-[15%]" />
				)}
			</Modal>
			<div className="px-3 pt-0 pb-16 mx-auto max-w-m-screen">
				<TitlePricingCA title={title} />
				<PricingCalculatorCA
					title={title}
					subtitle={subtitle}
					setPriceCalculated={setPriceCalculated}
					setEmployeeCount={setEmployeeCount}
					customFormField={customFormField}
					modalRef={priceQuoteFormRef}
				/>
				<PricingCardsCA
					priceCalculated={priceCalculated}
					content={cards}
					employeeCount={employeeCount}
					modalRef={priceQuoteFormRef}
					setFormTitle={setFormTitle}
					quoteButtonCopy={quoteButtonCopy}
				/>
				<div className="mt-20">
					<TitlePricingCA2 title={title2} subtitle={subtitle2} />
				</div>
				<div className="mt-9">
					<PricingTable
						modalRef={priceQuoteFormRef}
						setFormTitle={setFormTitle}
						content={cards}
						table={table}
						employeeCount={employeeCount}
					/>
				</div>
				<div className="mt-20">
					<GoogleBusinessReviewPricing />
				</div>
				<div className="max-w-md px-3 mx-auto mt-12 md:mt-20">
					<p className="mt-16 text-xl text-center font-centra-light">
						* Pricing is dependent on your number of employees.
					</p>
					<p className="mt-8 text-xl text-center font-centra-light">
						The above service pricing is based on our mid-level
						product over a five-year term. One to five-year terms
						are available. Depending on your industry or business
						circumstances and the service term you require, the fee
						may vary above or below the example fee.
					</p>
				</div>
			</div>
		</section>
	);
};

PricingSectionCA.propTypes = {
	pricingContent: PropTypes.shape({
		title: PropTypes.string,
		title2: PropTypes.string,
		subtitle: PropTypes.string,
		subtitle2: PropTypes.string,
		quoteButtonCopy: PropTypes.string,
		table: PropTypes.instanceOf(Array),
		cards: PropTypes.arrayOf(pricingCardProps),
	}).isRequired,
	customFormField: PropTypes.node.isRequired,
};

export { PricingSectionCA };
