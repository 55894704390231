import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from 'gatsby-link';

import { cardContents } from '../../../types';

import * as styles from './index.module.css';
import { buildLink } from '../../../utils/locale';

const JobCard = ({ cardContent, onClickProp, index, loading, locale }) => {
	const { location, jobTitle, salary, slug } = cardContent;

	const Element = 'article';

	const linkStyles = `inline bg-gradient-to-r [background-size:0_2px] [background-position:0_100%] bg-no-repeat transition-all duration-300 motion-reduce:transition-none before:absolute before:top-0 before:left-0 before:w-full before:h-full focus:[background-size:100%_2px] group-hover:[background-size:100%_2px] focus:outline-none`;

	if (loading) {
		return (
			<Element
				className={clsx(
					'group relative bg-white h-full w-full',
					'border-2 border-solid rounded-sm shadow-card',
					styles.borderLoading
				)}
				aria-labelledby={`${jobTitle}-card`}
			>
				<div
					className={clsx('w-full h-[244px] block', styles.bgLoading)}
				/>

				<div className="p-4 pt-8">
					<div
						className={clsx(
							'flex h-[22px] w-[50%]',
							styles.bgLoading
						)}
					/>
					<div className="mb-4">
						<div
							className={clsx(
								'mt-4 flex h-[28px]',
								styles.bgLoading
							)}
						/>

						<div
							className={clsx(
								'mt-4 flex h-[100px]',
								styles.bgLoading
							)}
						/>
					</div>

					<div
						className={clsx(
							'flex py-4 border-t',
							styles.breakBorderLoading
						)}
					>
						<div
							className={clsx(
								'flex w-12 h-12 rounded-[100%] mr-4',
								styles.bgLoading
							)}
						/>
						<div className="flex flex-col w-[50%]">
							<div
								className={clsx(
									'flex h-[20px] w-[50%]',
									styles.bgLoading
								)}
							/>
							<div
								className={clsx(
									'mt-2 flex h-[20px]',
									styles.bgLoading
								)}
							/>
						</div>
					</div>

					<div className="flex flex-row">
						<div
							className={clsx(
								'flex h-[30px] w-[35%]',
								styles.bgLoading
							)}
						/>
						<div
							className={clsx(
								'ml-3 flex h-[30px] w-[9%]',
								styles.bgLoading
							)}
						/>
					</div>
				</div>
			</Element>
		);
	}

	return (
		<Element
			className={clsx(
				'flex flex-col',
				'group relative bg-white h-full w-full',
				'border-2 border-blue-200 border-solid rounded-sm shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'hover:shadow-default-hover focus-within:shadow-default-hover',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2'
			)}
			aria-labelledby={`${jobTitle}-card`}
		>
			<div className="p-4 grow">
				<h3
					className="text-2xl font-centra-light mb-4"
					id={`${jobTitle}-card`}
				>
					<Link
						to={buildLink(locale, `about/careers/${slug}`)}
						className={clsx(linkStyles)}
						onClick={() =>
							onClickProp({
								click_text: jobTitle,
								click_index: index + 1,
							})
						}
					>
						{jobTitle}
					</Link>
				</h3>
				{location ? (
					<p className="mb-4 text-xl font-centra-light">{location}</p>
				) : null}
				{salary ? (
					<p className="text-xl font-centra-light">{salary}</p>
				) : null}
			</div>
		</Element>
	);
};

export { JobCard };

JobCard.defaultProps = {
	cardContent: {
		as: 'article',
	},
	index: 1,
	onClickProp: () => {},
	location: '',
	loading: false,
	locale: 'en',
};

JobCard.propTypes = {
	cardContent: cardContents,
	onClickProp: PropTypes.func,
	index: PropTypes.number,
	location: PropTypes.string,
	loading: PropTypes.bool,
	locale: PropTypes.string,
};
