import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { PBSLink } from '../../atoms/link';

import FacebookIcon from '../../../assets/facebook.inline.svg';
import LinkedInIcon from '../../../assets/linkedin.inline.svg';
import YouTubeIcon from '../../../assets/youtube.inline.svg';
import InstagramIcon from '../../../assets/instagram.inline.svg';
import TikTokIcon from '../../../assets/tiktok.inline.svg';
import TwitterIcon from '../../../assets/twitter.x.inline.svg';

import { getLocale } from '../../../utils/locale';

const SocialLinks = ({ heading, headingClasses, as, direction, className }) => {
	const [locale, setLocale] = useState();
	const Component = as;

	const listClasses = clsx(direction === 'vertical' ? '' : 'flex');
	const linkClasses = clsx(
		'inline-flex',
		'items-center',
		'justify-center',
		direction === 'vertical' ? 'px-4' : 'px-2',
		'py-2',
		direction === 'vertical' ? 'w-[54px]' : '',
		'hover:text-blue-400'
	);

	const onClick = (e) => {
		if (typeof window !== 'undefined') {
			e.preventDefault();
			window.open(e.currentTarget.href);
		}
	};

	useEffect(() => {
		setLocale(
			getLocale(
				typeof window !== 'undefined' ? window.location.pathname : ''
			)
		);
	}, []);

	const socialLinks = {
		facebook: {
			en: 'https://www.facebook.com/PeninsulaUK/?fref=ts',
			ca: 'https://www.facebook.com/PeninsulaCa/',
			ie: 'https://www.facebook.com/PeninsulaIE/',
		},
		linkedIn: {
			en: 'https://www.linkedin.com/company/peninsula-uk/',
			ca: 'https://www.linkedin.com/company/peninsula-ca/',
			ie: 'https://www.linkedin.com/company/peninsula-ireland/',
		},
		youTube: {
			en: 'https://www.youtube.com/channel/UCWQ5bV3Xyvk2ka75LrDVXXg',
			ca: 'https://www.youtube.com/c/PeninsulaCanada',
			ie: 'https://www.youtube.com/channel/UCRs4y__BqPRS2g3XiZ_uh6g',
		},
		instagram: {
			en: 'https://www.instagram.com/peninsula_uk/',
			ca: 'https://www.instagram.com/peninsula_ca/?hl=en',
			ie: 'https://www.instagram.com/peninsula_ireland/',
		},
	};

	const { facebook, linkedIn, youTube, instagram } = socialLinks;

	const getSocialLink = (social) => {
		const objectLocale = Object.keys(social)
			.filter((link) => link === locale)
			.toString();

		return social[objectLocale || 'en'];
	};

	return (
		<div className={clsx('social-links', direction)}>
			{heading ? (
				<Component
					className={clsx(
						headingClasses || 'text-xl font-centra-medium'
					)}
				>
					{heading}
				</Component>
			) : null}
			<ul
				className={clsx(
					'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-center',
					direction === 'vertical' ? 'flex-col' : 'flex-row',
					direction === 'vertical' ? '' : 'gap-4',
					className
				)}
			>
				<li className={listClasses}>
					<PBSLink
						to={getSocialLink(facebook)}
						variant="Unset"
						title="Follow us on Facebook"
						className={linkClasses}
						onClick={onClick}
					>
						<FacebookIcon />
					</PBSLink>
				</li>
				<li className={listClasses}>
					<PBSLink
						to={getSocialLink(linkedIn)}
						variant="Unset"
						title="Follow us on LinkedIn"
						className={linkClasses}
						onClick={onClick}
					>
						<LinkedInIcon />
					</PBSLink>
				</li>
				<li className={listClasses}>
					<PBSLink
						to={getSocialLink(youTube)}
						variant="Unset"
						title="Watch us on YouTube"
						className={linkClasses}
						onClick={onClick}
					>
						<YouTubeIcon />
					</PBSLink>
				</li>
				<li className={listClasses}>
					<PBSLink
						to={getSocialLink(instagram)}
						variant="Unset"
						title="Follow us on Instagram"
						className={linkClasses}
						onClick={onClick}
					>
						<InstagramIcon />
					</PBSLink>
				</li>
				{locale === 'ie' && (
					<>
						<li className={listClasses}>
							<PBSLink
								to="https://twitter.com/peninsula_ie"
								variant="Unset"
								title="Follow us on Twitter"
								className={linkClasses}
								onClick={onClick}
							>
								<TwitterIcon />
							</PBSLink>
						</li>
						<li className={listClasses}>
							<PBSLink
								to="https://www.tiktok.com/@peninsula_ireland"
								variant="Unset"
								title="Follow us on TikTok"
								className={linkClasses}
								onClick={onClick}
							>
								<TikTokIcon />
							</PBSLink>
						</li>
					</>
				)}
			</ul>
		</div>
	);
};

SocialLinks.defaultProps = {
	heading: 'Social',
	headingClasses: '',
	as: 'h2',
	direction: 'vertical',
	className: '',
};

SocialLinks.propTypes = {
	heading: PropTypes.string,
	headingClasses: PropTypes.string,
	as: PropTypes.string,
	direction: PropTypes.string,
	className: PropTypes.string,
};

export { SocialLinks };
