import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropsTypes from 'prop-types';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { toPng } from 'html-to-image';
import certificateTemplete from '../../../../images/GLU_Certificate.png';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { CallUs } from '../../../atoms/call-us';
import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { Button } from '../../../atoms/button';

function Certificate({ title, response }) {
	const ref = useRef(null);
	const [technicalError, setTechnicalError] = useState(false);
	const [loading, setLoading] = useState(false);
	const btnText = 'Click To Download Certificate';

	const name = `${response.payloadData.FirstName}  ${response.payloadData.LastName} - ${response.payloadData.Company}`;
	const isBrowser = typeof window !== 'undefined';
	let localName = '';
	if (isBrowser) {
		localName = window.localStorage.getItem('c_name');
	}

	const [imageLoaded, setImageLoaded] = useState(false);
	const handleImageLoad = () => {
		setImageLoaded(true);
	};

	const saveName = useCallback(
		(value) => {
			if (isBrowser && name) {
				window.localStorage.setItem('c_name', value);
			}
		},
		[isBrowser, name]
	);
	useEffect(() => {
		saveName(name);
	}, [name, saveName]);
	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		Title: '',
		PersonSource: 'PBS GLU E-Learning',
		referalSource: response.payloadData?.referralCookie,
		keyword: response.payloadData?.keywordCookie,
		utm_medium: response.payloadData?.mediumCookie,
		utm_campaign: response.payloadData?.campaignCookie,
		MSCLKID__c: response.payloadData?.msclkidCookie,
		MSCLKID_last: response.payloadData?.msclkidLastCookie,
		GCLID__c: response.payloadData?.gclidCookie,
		gCLID_last: response.payloadData?.gclidLastCookie,
		utm_campaign_last__c: response.payloadData?.campaignLastCookie,
		utm_content_last__c: response.payloadData?.contentLastCookie,
		utm_medium_last__c: response.payloadData?.mediumLastCookie,
		utm_source_last__c: response.payloadData?.sourceLastCookie,
		utm_term_last__c: response.payloadData?.termLastCookie,
		Google_Cookie_ID__c: response.payloadData?.googleCookie,
		socialCampaignID: response.payloadData?.socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
	});
	const onButtonClick = () => {
		if (ref.current === null) {
			return;
		}

		toPng(ref.current, { cacheBust: true })
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = 'certificate.png';
				link.href = dataUrl;
				link.click();
				const payloadData = {
					...response.payloadData,
					gLUPageURL: isBrowser ? window.location.href : null,
				};

				useMarketoFormSubmit(
					payloadData,
					response.formId,
					response.MktoForms2Instances
				)
					.then(() => {
						formik.setStatus('submitted');

						setLoading(false);
					})
					.catch(() => {
						formik.setStatus('error');
						setTechnicalError(true);
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log(err);
			});
	};
	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<div className="bg-white overflow-auto scrollbar-hidden">
			{imageLoaded && (
				<div className="items-center px-40 mt-2 flex ">
					<Button
						type="submit"
						onClick={onButtonClick}
						disabled={formik.isSubmitting}
						className={clsx(
							formik.isSubmitting
								? 'justify-center loading'
								: null
						)}
					>
						<span
							className={clsx(
								'text-sm',
								formik.isSubmitting ? 'invisible' : null
							)}
						>
							{loading ? 'please wait...' : btnText}
						</span>
						{formik.isSubmitting ? (
							<span className="sr-only" aria-live="assertive">
								Submitting your details, please wait...
							</span>
						) : null}
					</Button>
				</div>
			)}
			<div className="relative w-full h-auto" ref={ref}>
				<img
					src={certificateTemplete}
					alt="Certificate Not Found Try again"
					onLoad={handleImageLoad}
					style={{ display: imageLoaded ? 'block' : 'none' }}
				/>
				<div
					className={clsx(
						'text-brand-red-500 absolute md:[top:43.9%] max-mdx:[top:42.9%] max-mdx:[font-size:1.1rem] lg:[top:43.7%] [top:43%] md:[text-center] lg:[left:5%] [left:6%] font-bold [transform: translate(-50%, -50%)] [font-size:1rem] md:[font-size:1.2rem] lg:[font-size:1.2rem] w-[85%]',
						name.length < 40 ? 'mt-2' : 'mt-3'
					)}
				>
					<h1 className="text-center line-clamp-2 font-bold">
						{name || localName}
					</h1>
				</div>
				<div
					className={clsx(
						'text-brand-red-500 absolute md:[top:57.5%] lg:[top:57%] [top:57%] md:[left:16%] lg:[left:16%] [left:15%] font-bold [transform: translate(-50%, -50%)] [font-size:0.9rem] md:[font-size:1.2rem] lg:[font-size:1.2rem] w-[70%]',
						title.length < 40 ? 'mt-2' : 'mt-3'
					)}
				>
					<h1 className="max-mdx:[font-size:1.1rem] text-center line-clamp-2 font-bold">
						{title}
					</h1>
				</div>
				<div className="max-mdx:[font-size:1.1rem] text-brand-red-500 top-[71%] md:top-[71%] lg:top-[71%] left-[40%] md:left-[42%] lg:left-[42%] font-bold absolute [font-size:0.9rem] md:[font-size:1.1rem] lg:[font-size:1.2rem]">
					{new Date().toLocaleDateString()}
				</div>
			</div>
		</div>
	);
}

Certificate.defaultProps = {
	title: '',
	response: {},
};

Certificate.propTypes = {
	title: PropsTypes.string,
	response: PropsTypes.objectOf,
};

export default Certificate;
