import React from 'react';
import PropTypes from 'prop-types';
import { SidebarHeader } from '../../../../../atoms/sidebar-header-pricing';
import { Button } from '../../../../../atoms/button';
import { sendTrackingData } from '../../../../../../utils';

const SuccessFormMessage = ({ response }) => {
	const returnButtonText = 'Return home';

	const handleClickTracking = (clickText) => {
		window.location.href = '/ca';
		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-form',
			click_type: 'primary',
			click_title: response,
			click_text: clickText,
		});
	};

	return (
		<>
			<div className="flex flex-col w-full justify-between mx-auto grow max-w-sm px-4 pt-6 pb-10 bg-brand-pale-300">
				<SidebarHeader
					heading="Thank you for contacting Peninsula. We will get back to you shortly."
					ariaLive="assertive"
				/>
			</div>
			<div className="flex flex-col justify-between h-full px-4 pt-8 pb-14">
				<Button
					variant="White"
					className="w-max"
					onClick={() => {
						handleClickTracking(returnButtonText);
					}}
				>
					{returnButtonText}
				</Button>
			</div>
		</>
	);
};

SuccessFormMessage.propTypes = {
	response: PropTypes.string.isRequired,
};

export { SuccessFormMessage };
