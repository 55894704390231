import React from 'react';
import PropTypes from 'prop-types';

import { IconCardHrSoftware } from '../../atoms/icon-card-hr-software';

const IconCardListHrSoftware = ({
	cards,
	variant,
	showContentLinks,
	isTimeline,
}) => (
	<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6 mb-14 lg:mb-28">
		{cards.map((item, index) => (
			<IconCardHrSoftware
				{...item}
				variant={variant === 'Dark' ? 'Light' : 'Default'}
				hasLinks={showContentLinks}
				index={index}
				svgStyles={
					isTimeline
						? 'w-[40px] h-[40px] md:w-[54px] md:h-[54px]'
						: null
				}
			/>
		))}
	</div>
);

IconCardListHrSoftware.defaultProps = {
	variant: 'Default',
	showContentLinks: true,
	isTimeline: false,
};

IconCardListHrSoftware.propTypes = {
	showContentLinks: PropTypes.bool,
	variant: PropTypes.string,
	isTimeline: PropTypes.bool,
	cards: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.string.isRequired,
			linkCopy: PropTypes.string,
			link: PropTypes.string,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
};

export { IconCardListHrSoftware };
