import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import { breadCrumbItems as breadCrumbItemsType } from '../../../types';
import { PBSLink } from '../link';
import { getLocale, buildLink } from '../../../utils/locale';

const BreadCrumbs = ({ breadCrumbItems, className, titleOverride, glu }) => {
	const breadCrumbItemsLength = breadCrumbItems.length - 1;
	const location = useLocation();
	const locale = getLocale(location.pathname);
	const showHomeIcon = (i) => {
		if (i === 0) {
			return (
				glu && (
					<HomeIcon className="h-6 mt-2 flex  items-center  justify-centers" />
				)
			);
		}
	};

	return (
		<section
			className={clsx(
				'px-4 pt-4 pb-8 mx-auto md:pt-4 lg:pb-12 max-w-m-screen lg:px-4 ',
				className
			)}
		>
			<ul>
				{breadCrumbItems
					.map(({ title, url }, index) => {
						if (index !== breadCrumbItemsLength) {
							return (
								<PBSLink
									variant="Unset"
									to={buildLink(locale, url) || ''}
									className="underline hover:decoration-blue-400 items-center inline-flex justify-centers gap-1"
								>
									{showHomeIcon(index)}
									<p className="mt-5 flex justify-centers items-center hover:decoration-blue-400 hover:text-blue-400">
										{' '}
										{title}
									</p>
								</PBSLink>
							);
						}
						return title;
					})
					.map((item, index) => (
						<li
							key={item?.props ? item.props.children : item}
							className="text-s-f pr-2 md:pr-xxs-f after:content-['/'] md:after:pl-xxs-f after:pl-2 last:after:content-none after:font-centra-light last:pr-0 inline"
						>
							{index === breadCrumbItemsLength && titleOverride
								? titleOverride
								: item}
						</li>
					))}
			</ul>
		</section>
	);
};

BreadCrumbs.defaultProps = {
	className: '',
	titleOverride: '',
	glu: false,
};

BreadCrumbs.propTypes = {
	className: PropTypes.string,
	breadCrumbItems: breadCrumbItemsType.isRequired,
	titleOverride: PropTypes.string,
	glu: PropTypes.bool,
};

export { BreadCrumbs };

const HomeIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" {...props}>
		<g
			fill="#002c50"
			fillRule="nonzero"
			stroke="none"
			strokeWidth="1"
			strokeLinecap="butt"
			strokeLinejoin="miter"
			strokeMiterlimit="10"
			strokeDasharray=""
			strokeDashoffset="0"
			fontFamily="none"
			fontWeight="none"
			fontSize="none"
			textAnchor="none"
		>
			<g transform="scale(5.12,5.12)">
				<path d="M25,1.05078c-0.2175,0 -0.43414,0.06898 -0.61914,0.20898l-23,17.95117c-0.43,0.34 -0.50992,0.9682 -0.16992,1.4082c0.34,0.43 0.9682,0.50992 1.4082,0.16992l1.38086,-1.07812v26.28906c0,0.55 0.45,1 1,1h14v-18h12v18h14c0.55,0 1,-0.45 1,-1v-26.28906l1.38086,1.07812c0.19,0.14 0.39914,0.21094 0.61914,0.21094c0.3,0 0.58906,-0.13086 0.78906,-0.38086c0.34,-0.44 0.26008,-1.0682 -0.16992,-1.4082l-23,-17.95117c-0.185,-0.14 -0.40164,-0.20898 -0.61914,-0.20898zM35,5v1.05078l6,4.67969v-5.73047z" />
			</g>
		</g>
	</svg>
);
