/* eslint-disable react/prop-types */
import React from 'react';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
	isListItem,
} from 'datocms-structured-text-utils';
import { clsx } from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as textWithImageStyles from './index.module.css';
import { getLocale, buildLink } from '../../../utils/locale';
import { PBSLink } from '../../atoms/link';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import VideoPlayer from '../../glu/molecules/video-player';
import { CallUs } from '../../atoms/call-us';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';
import { TrustpilotBusinessReviewHorizontal } from '../../atoms/trustpilot-business-review';
import TickIcon from '../../../assets/tick.inline.svg';

const FlexibleContentRow = ({ data, drawerRef, urlLocale }) => {
	const {
		title,
		fullWidthTitle,
		copy,
		image,
		blueBackground,
		fullWidthBackgroundColour,
		alignImageLeft,
		video,
		showTelephoneNumber,
		ctaText,
		showTrustpilot,
	} = data;
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);
	const { setDrawerRef } = useDrawer();
	return (
		<div
			className={clsx('w-full mt-8 md:mt-20', {
				'bg-brand-pale-400 py-10 md:py-14':
					blueBackground && fullWidthBackgroundColour,
				'bg-white': !blueBackground && !fullWidthBackgroundColour,
			})}
		>
			<div
				className={clsx(
					'px-5 md:px-10 flex flex-col max-w-m-screen mx-auto',
					{
						'bg-brand-pale-400 py-10 md:py-14':
							blueBackground && !fullWidthBackgroundColour,
						'bg-white':
							!blueBackground && !fullWidthBackgroundColour,
					}
				)}
			>
				{title && fullWidthTitle && (
					<HeadingTagLine
						className="mb-8 md:mb-12"
						headingClassNames="text-left md:!text-4xl"
						title={title.value.document.children}
					/>
				)}

				<div
					className={clsx('flex flex-col', {
						'md:flex-row md:flex-nowrap': alignImageLeft,
						'md:flex-row-reverse md:flex-nowrap': !alignImageLeft,
					})}
				>
					{image?.url && (
						<div
							className={clsx(
								'mb-8 md:mb-0 md:w-1/2 md:text-right',
								{
									'md:text-right': !alignImageLeft,
									'md:text-left md:flex 2xl:-translate-x-20':
										alignImageLeft,
								}
							)}
						>
							<GatsbyImage
								image={image.gatsbyImageData}
								alt={image.alt || ''}
								className="w-auto border-4 border-white shadow-[4px_4px_8px_1px_rgba(0,0,0,0.3)] box-border"
							/>
						</div>
					)}

					{video?.providerUid && (
						<div
							className={clsx(
								'mb-2 lg:mb-0 aspect-video md:w-1/2',
								{
									'md:text-right': !alignImageLeft,
									'md:text-left 2xl:-translate-x-20':
										alignImageLeft,
								}
							)}
						>
							<VideoPlayer videoEmbed={video} />
						</div>
					)}

					<div
						className={clsx('md:w-1/2', {
							'md:pr-10': !alignImageLeft,
							'md:pl-10 2xl:w-3/5': alignImageLeft,
						})}
					>
						{title && !fullWidthTitle && (
							<HeadingTagLine
								className="mb-4 md:mb-8"
								headingClassNames="text-left md:!text-4xl"
								title={title.value.document.children}
							/>
						)}
						<StructuredText
							data={copy.value}
							customNodeRules={[
								renderNodeRule(
									isHeading,
									({ node, children, key }) => {
										const HeadingTag = `h${node.level}`;
										return (
											<HeadingTag
												className="mb-5 text-2xl lg:text-3xl font-castledown-regular"
												key={key}
											>
												{children}
											</HeadingTag>
										);
									}
								),
								renderNodeRule(
									isLink,
									({ node, children, key }) => (
										<PBSLink
											to={buildLink(locale, node.url)}
											variant="Link"
											key={key}
										>
											{children}
										</PBSLink>
									)
								),
								renderNodeRule(
									isList,
									({ node, children, key }) => {
										let ListTag;
										let listClassName;

										if (node.style === 'bulleted') {
											ListTag = 'ul';
											listClassName = 'list-disc';
										}
										if (node.style === 'numbered') {
											ListTag = 'ol';
											listClassName = 'list-decimal';
										}

										return (
											<ListTag
												className={clsx(
													'mb-4 text-lg font-centra-light md:text-xl list-inside',
													listClassName,
													textWithImageStyles.list
												)}
												key={
													key +
													node.style +
													textWithImageStyles.list
												}
											>
												{children}
											</ListTag>
										);
									}
								),
								renderNodeRule(
									isListItem,
									({ children, key }) => (
										<li
											className="flex items-baseline mt-5 leading-tight mb-4 last-of-type:mb-0"
											key={key}
										>
											<TickIcon
												className={clsx(
													'w-3 h-auto shrink-0 mr-4 text-blue-400'
												)}
												aria-hidden="true"
											/>

											{children}
										</li>
									)
								),
								renderNodeRule(
									isParagraph,
									({ children, key, ancestors }) => {
										if (isRoot(ancestors[0])) {
											return (
												<p
													className="mb-4 text-lg font-centra-light md:text-xl last-of-type:mb-0"
													key={key}
												>
													{children}
												</p>
											);
										}
										return children;
									}
								),
							]}
							customMarkRules={[
								renderMarkRule(
									'strong',
									({ children, key }) => (
										<span
											className="font-centra-book"
											key={key}
										>
											{children}
										</span>
									)
								),
							]}
						/>
						<div
							className={clsx('flex flex-col xl:flex-row', {
								'my-5 xl:my-10 w-fit': ctaText,
								'xl:w-full': ctaText && showTelephoneNumber,
							})}
						>
							{showTelephoneNumber && (
								<div className="flex flex-col xl:flex-row xl:mb-0">
									<CallUs className="xl:self-center" />
									<p className="mt-5 mb-5 xl:mb-0 xl:mt-0 xl:mx-8 xl:self-center">
										OR
									</p>
								</div>
							)}
							{ctaText && (
								<Button
									onClick={() => {
										setDrawerRef(drawerRef);
									}}
									variant="White"
									className="h-14"
								>
									{ctaText}
								</Button>
							)}
						</div>
					</div>
				</div>
				{showTrustpilot && (
					<div className="my-10">
						<TrustpilotBusinessReviewHorizontal
							className="justify-center !mt-0 px-3"
							textClassName="text-center"
							locale={urlLocale}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default FlexibleContentRow;
/* eslint-enable react/prop-types */
