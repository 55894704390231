import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '../../atoms/button';

import { InputVariants } from '../../atoms/text-input';

import { data, dataCA, dataIE } from './data';
import { sendTrackingData } from '../../../utils';
import { getLocale } from '../../../utils/locale';
import { useModal } from '../../../contexts/modal-context';
import { refProp } from '../../../types';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const getMultiplier = () => {
	const multiplier = 1;
	let currency = '£';

	switch (locale) {
		case 'ie':
			currency = '€';
			break;
		case 'ca':
			currency = '$';
			break;
		default:
			currency = '£';
			break;
	}

	return { multiplier, currency };
};

const PricingCalculatorCA = ({
	setPriceCalculated,
	setEmployeeCount,
	title,
	subtitle,
	modalRef,
}) => {
	const elName = 'employeeCount';
	const buttonText = 'See price';
	const [inputValue, setInputValue] = useState('');

	const handleOnChange = (e) => {
		setInputValue(e.target.value);
	};

	const { setModalRef } = useModal();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-calculator',
			click_type: 'primary',
			click_action: 'change state',
			click_title: title,
			click_text: buttonText,
		});
	};

	const submitHandler = async (event) => {
		event.preventDefault();

		const formData = new FormData(event.target);
		const employeeCount = formData.get('employeeCount');
		if (!employeeCount) {
			return;
		}

		setEmployeeCount(parseInt(employeeCount, 10));
		const { multiplier, currency } = getMultiplier();

		let dataKey;

		if (locale === 'ca') {
			// dataKey = employeeCount > 100 ? 100 : employeeCount;
			dataKey = employeeCount;
			if (employeeCount > 100) {
				// Use Modal.
				dataKey = 0;
				setModalRef(modalRef);
			}
		} else if (locale === 'ie') {
			dataKey = employeeCount > 99 ? 99 : employeeCount;
		} else {
			dataKey = employeeCount > 100 ? 100 : employeeCount;
		}

		let localeData;

		if (locale === 'ca') {
			localeData = dataCA[dataKey];
		} else if (locale === 'ie') {
			localeData = dataIE[dataKey];
		} else {
			localeData = data[dataKey];
		}

		if (locale === 'ca' && employeeCount > 100) {
			setPriceCalculated('');
			handleClickTracking();
		} else {
			const toFixed = Object.entries(localeData).map(([key, value]) => {
				const fixedValue = (parseFloat(value) * multiplier).toFixed(2);
				return {
					[key]: `${currency}${fixedValue}`,
				};
			});
			setPriceCalculated(Object.assign({}, ...toFixed));
			handleClickTracking();
		}
	};

	return (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col items-center pb-2"
			>
				<label
					htmlFor={elName}
					className="block mb-5 text-xl md:text-2xl font-centra-light"
				>
					{subtitle}
				</label>

				<div className="flex flex-col items-center xs:items-stretch xs:flex-row">
					<input
						className={clsx(
							InputVariants.Default,
							'h-auto mb-0 appearance-none transition-colors duration-200',
							'xs:mr-5',
							'hover:bg-brand-pale-400',
							'focus:border-blue-400 focus:placeholder:text-brand-blue-400',
							'motion-reduce:transition-none',
							inputValue.length > 0
								? 'border-brand-blue-400 bg-brand-pale-300'
								: ''
						)}
						min={1}
						type="number"
						id={elName}
						name={elName}
						required
						aria-required="true"
						onChange={handleOnChange}
						value={inputValue}
						placeholder="e.g. 22"
					/>
					<Button
						type="submit"
						className="!font-centra-book mt-5 xs:mt-0"
					>
						{buttonText}
					</Button>
				</div>
			</form>
		</>
	);
};

export { PricingCalculatorCA };

PricingCalculatorCA.defaultProps = {
	setEmployeeCount: () => {},
	setPriceCalculated: () => {},
	title: '',
	subtitle: '',
};

PricingCalculatorCA.propTypes = {
	setPriceCalculated: PropTypes.func,
	setEmployeeCount: PropTypes.func,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	// eslint-disable-next-line react/require-default-props
	modalRef: refProp,
};
