import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import * as modalStyles from './index.module.css';
import * as modalStyles from './index-middle.module.css';

import { useLocation } from '@reach/router';

const Modal = () => {
	const [modal, setModal] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setModal(!modal);
		}, 10000);
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleModal = () => {
		setModal(!modal);
		window.sessionStorage.setItem('marketoCampaignPopupShown', true);
	};
	const isWindows = typeof window !== 'undefined';

	if (modal) {
		if (isWindows)
			document.body.classList.add(`${modalStyles.activemodal}`);
	} else if (isWindows)
		document.body.classList.remove(`${modalStyles.activemodal}`);

	const data = useStaticQuery(graphql`
		query {
			datoCmsMarketoCampaignPopup {
				id
				name
				popUpBanner {
					url
					alt
				}
				ctaLink
				excludeUrl
				marketoCampaignPopupDisplayOnPage
			}
		}
	`);

	const popUpBannerURL = data.datoCmsMarketoCampaignPopup.popUpBanner.url;
	const popUpBannerAlt = data.datoCmsMarketoCampaignPopup.popUpBanner.alt;
	const { ctaLink } = data.datoCmsMarketoCampaignPopup;
	const { excludeUrl } = data.datoCmsMarketoCampaignPopup;
	const excludeUrls = excludeUrl.split(',').map(url => url.trim());
	const { marketoCampaignPopupDisplayOnPage } =
		data.datoCmsMarketoCampaignPopup;

	const location = useLocation();
	const { href } = location;

	return (
		<>
			{modal &&
				marketoCampaignPopupDisplayOnPage && (!excludeUrls.includes(href)) &&
				!window.sessionStorage.getItem('marketoCampaignPopupShown') && (
					<div className="modal" role="presentation">
						<div
							className={`${modalStyles.overlay}`}
							onClick={() => {
								toggleModal();
							}}
							onKeyDown={() => {
								toggleModal();
							}}
							role="presentation"
						/>

						<div
							className={`${modalStyles.modalcontent}`}
							role="banner"
						>
							<a
								className=""
								href={ctaLink}
								target="_blank"
								onClick={() => {
									toggleModal();
								}}
								onKeyDown={() => {
									toggleModal();
								}}
								rel="noreferrer"
							>
								<img
									src={popUpBannerURL}
									alt={popUpBannerAlt}
									className={`${modalStyles.campaignimage}`} 
									id={`${modalStyles.popup}`}
								/>
							</a>

							<button
								className={`${modalStyles.closemodal} text-black`}
								type="button"
								onClick={() => {
									toggleModal();
								}}
								onKeyDown={() => {
									toggleModal();
								}}
							>
								X
							</button>
						</div>
					</div>
				)}
		</>
	);
};

export { Modal };
