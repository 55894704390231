import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { refProp, priceCalculatedProp, pricingCardProps } from '../../../types';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../button';
import { sendTrackingData } from '../../../utils';
import * as pricingCardStyles from './index.module.css';

const getCardPrice = ({ cardType, priceCalculated }) => {
	let price = '-';

	if (cardType === 'human resources' && priceCalculated?.hr) {
		price = priceCalculated.hr;
	}

	if (cardType === 'health and safety' && priceCalculated?.hs) {
		price = priceCalculated.hs;
	}

	if (cardType === 'combined' && priceCalculated?.combined) {
		price = priceCalculated.combined;
	}

	return price;
};

// Function that calculates what the price of the combined service would be before discount.
const getCombinedPrice = ({ cardType, priceCalculated }) => {
	let price = '';

	const hrPrice = parseFloat(priceCalculated.hr?.replace('$', ''));
	const healthAndSafetyPrice = parseFloat(
		priceCalculated.hs?.replace('$', '')
	);

	if (cardType === 'combined') {
		const sum = hrPrice + healthAndSafetyPrice;
		if (Number.isNaN(sum)) {
			price = '';
		} else {
			price = `$${sum.toFixed(2)}`;
		}
	}

	return price;
};

const PricingCard = ({
	content,
	employeeCount,
	modalRef,
	setFormTitle,
	priceCalculated,
	index,
	quoteButtonCopy,
}) => {
	const buttonText = quoteButtonCopy;
	const { title, cardType, description } = content;

	const { setModalRef } = useModal();

	const handleClickTracking = (clickType) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			component_name: 'pricing-card',
			click_type: clickType,
			click_action: 'card click',
			click_state: `${employeeCount}_no_employees`,
			click_title: title,
			click_text: buttonText,
			click_index: index + 1,
		});
	};

	const handleClick = () => {
		setModalRef(modalRef);
		setFormTitle(title);
	};

	const priceBlock = () => (
		<>
			<div className="flex flex-col items-start justify-between xs:items-center xs:flex-row text-center mx-auto">
				<div className="text-sm xl:max-w-none md:max-w-[100px] text-center">
					<strong
						className={clsx(
							'block text-4xl md:text-4xl',
							'flex flex-col text-blue-400 font-centra-book mt-8 text-center'
						)}
					>
						{cardType === 'combined' &&
						priceCalculated.combined !== '-' ? (
							<span className="line-through lg:mt-2 xl:-mt-5 text-[#B3B3B3] decoration-[#E30138]">
								{getCombinedPrice({
									cardType,
									priceCalculated,
								})}
							</span>
						) : null}
						<span className="text-center mx-auto">
							{getCardPrice({ cardType, priceCalculated })}
						</span>
					</strong>
					<p
						className={clsx(
							'inline',
							'text-blue-400 text-center mx-auto'
						)}
					>
						per employee per month
					</p>
				</div>
			</div>
		</>
	);

	// Creating border styles for the pricing cards that renders the style based on card type

	const borderStyles = {
		'combined': {
			className: 'bg-[#E30138] h-14 rounded-t-[20px] py-4',
			content: (
				<h1 className="mx-auto text-center text-white text-xl font-medium">
					Most Popular
				</h1>
			),
		},
		'health and safety': {
			className: 'bg-[#1F2E7A] h-3',
			content: null,
		},
		'human resources': {
			className: 'bg-[#9CAAF0] h-3',
			content: null,
		},
	};

	const borderStyle = borderStyles[cardType];

	return (
		<div
			className="flex flex-col col-span-12 md:col-span6 lg:col-span-4 mt-auto transform transition duration-200 
		hover:scale-105"
		>
			{borderStyle ? (
				<div className={borderStyle.className}>
					{borderStyle.content}
				</div>
			) : null}
			<article
				className={clsx(
					'flex',
					'flex-col',
					'col-span-12',
					'md:col-span-6',
					'lg:col-span-4',
					'bg-white',
					'border-2',
					'border-blue-200',
					'lg:max-h-[451px]',
					'xl:max-h-[360px]',
					'grow',
					'sm:h-[451px]',
					'h-[375px]',
					'shadow-[10px_2px_15px_0px_rgba(29,41,81,0.1)]',
					pricingCardStyles.pricingCard
				)}
			>
				<header className="flex flex-col p-s-f md:px-8 mx-auto">
					{cardType === 'human resources' ? (
						<h2 className="lg:mb-12 mx-auto text-center text-lg mb-s-f xl:mb-s-f md:text-2xl font-centra-medium text-[#1F2E7A]">
							{title}
						</h2>
					) : (
						<h2 className="mx-auto text-center text-lg mb-s-f md:text-2xl font-centra-medium text-[#1F2E7A]">
							{title}
						</h2>
					)}
					<p className="mx-auto text-center h-2 mt-2 mb-4">
						{description}
					</p>
					{priceBlock(true)}
				</header>
				{cardType === 'combined' && priceCalculated.combined !== '-' ? (
					<div className="flex flex-col items-start justify-between xs:items-center mx-auto xs:flex-row">
						<Button
							onClick={() => {
								handleClick();
								handleClickTracking('primary');
							}}
							className="!font-centra-book mx-auto xs:mx-0"
						>
							{buttonText}
						</Button>
					</div>
				) : (
					<div className="flex flex-col p-s-f items-start justify-between xs:items-center mx-auto xs:flex-row">
						<Button
							onClick={() => {
								handleClick();
								handleClickTracking('primary');
							}}
							className="!font-centra-book mx-auto xs:mx-0"
						>
							{buttonText}
						</Button>
					</div>
				)}
			</article>
		</div>
	);
};

PricingCard.defaultProps = {
	setFormTitle: () => null,
	index: 0,
	employeeCount: 0,
	priceCalculated: {
		hr: '-',
		hs: '-',
		combined: '-',
	},
};

PricingCard.propTypes = {
	setFormTitle: PropTypes.func,
	modalRef: refProp.isRequired,
	priceCalculated: priceCalculatedProp,
	content: pricingCardProps.isRequired,
	index: PropTypes.number,
	employeeCount: PropTypes.number,
	quoteButtonCopy: PropTypes.string.isRequired,
};

export { PricingCard };
