/**
 * To use this somewhere and get the current value of the ScreenResizer context:
 * import { useScreenResizer } from '../../../contexts/screen-resize-context';
 * const { screenHeight, screenWidth } = useScreenResizer();
 */

import React, { useContext, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from '@react-hook/resize-observer';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const ScreenResizeContext = React.createContext({
	height: 0,
	width: 0,
});

const useSize = () => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const isBrowser = typeof window !== 'undefined';

	if (isBrowser) {
		useLayoutEffect(() => {
			setWidth(document.body.clientWidth);
			setHeight(document.body.clientHeight);
		}, []);

		useResizeObserver(document.body, (entry) => {
			setWidth(entry.target.clientWidth);
			setHeight(entry.target.clientHeight);
		});
	}

	return { width, height };
};

const ScreenResizerProvider = ({ children }) => {
	const size = useSize();
	const { width, height } = size;

	return (
		<ScreenResizeContext.Provider value={{ width, height }}>
			{children}
		</ScreenResizeContext.Provider>
	);
};

ScreenResizerProvider.defaultProps = {};

ScreenResizerProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

const useScreenResizer = () => {
	const context = useContext(ScreenResizeContext);
	if (context === undefined) {
		throw new Error(
			'useScreenResizer must be used within a ScreenResizerProvider'
		);
	}
	return context;
};

export { ScreenResizerProvider, useScreenResizer };

export default ScreenResizeContext;
