import { useState, useEffect } from 'react';

const useModal = (ref) => {
	const [isModalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		const outsideClick = (e) => {
			if (isModalOpen && ref.current && !ref.current.contains(e.target)) {
				setModalOpen(false);
			}
		};

		document.addEventListener('mousedown', outsideClick);

		return () => {
			document.removeEventListener('mousedown', outsideClick);
		};
	}, [isModalOpen, ref]);

	return { isModalOpen, setModalOpen };
};
export default useModal;
