// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PBSLink } from '../../atoms/link';

const PressBanner = ({ pressBannerContent }) => {
	const { heading, title, intro, slug, thumbnail } = pressBannerContent;
	const imageData = getImage(thumbnail);
	const Alt =
		thumbnail.alt || title || 'Peninsula HR & Health and Safty Support';

	return (
		<section aria-label="pressBanner">
			<div className="md:w-[768px] lg:w-auto md:mx-auto max-w-m-screen lg:px-8 pb-4">
				<div>
					<h1 className="pb-6 lg:pb-8 text-2xl-f px-4 lg:px-0">
						{heading}
					</h1>
				</div>
				<div className="lg:grid lg:grid-cols-2">
					<div className="">
						<GatsbyImage
							className="w-full"
							image={imageData}
							alt={Alt}
						/>
					</div>

					<div className="bg-brand-pale-400 py-6 px-4 lg:px-20 lg:flex">
						<div className="m-auto ">
							<div>
								<p className="pb-4 font-semibold text-[24px] lg:text-[32px] leading-[35px]">
									{title}
								</p>
								<p
									className={clsx(
										'overflow-hidden text-base-f font-centra-light line-clamp-4',
										'font-normal text-[20px] leading-[28px]'
									)}
								>
									{intro}
								</p>
								<p className="pt-6">
									<PBSLink to={slug}>Read more</PBSLink>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export { PressBanner };
