// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BreadCrumbs } from '../../components/atoms/breadcrumbs';
import { BannerNav } from '../../components/glu/organisms/banner-nav';
import PageSection from '../../components/glu/organisms/page-section';
import SessionCardContainer from '../../components/glu/organisms/session-card-container';
import { Head } from '../../components/templates/head';
import { GLULayout } from '../../layouts/glu';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../../utils/seo-schema';
import {
	getBreadcrumbs,
	getBreadcrumLocale,
	pageTypes,
} from '../../utils/url-helpers';

import Spinner from '../../assets/spinner.inline.svg';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import useFindCookie from '../../hooks/useCookie';

import ArticleNav from '../../components/glu/organisms/article-nav';
import { buildLink } from '../../utils/locale';

const WebinarLibrary = ({ data, location }) => {
	const { liveWebinarSessions, locale, title } = data.datoCmsGluWebinarsPage;
	const metaContent = {
		noIndex: data.datoCmsGluWebinarsPage.noIndex,
		noFollow: data.datoCmsGluWebinarsPage.noFollow,
		metaInformation: data.datoCmsGluWebinarsPage.seoMeta,
		fallbackTitle: data.datoCmsGluWebinarsPage.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl:
			data.datoCmsGluWebinarsPage?.canonicalUrl || location?.href,
	};
	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { nodes: gluBanner } = data.allDatoCmsGluBanner;
	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.gluWebinarsPage,
			location,
		}),
		image: getImageSeoSchema(data?.headingImage?.url),
	};
	const bannerTitle = 'Love it, Share it';

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsGluWebinarsPage.internal.type,
		data.datoCmsGluWebinarsPage
	);

	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);

	const { locale: urlLocale } = data.datoCmsGluWebinarsPage;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{bannerTitle && urlLocale === 'en-IE' && (
				<BannerNav title={bannerTitle} />
			)}

			{locale === 'en' && articles.length > 0 && (
				<ArticleNav
					articleData={articles}
					locale={locale}
					bannerData={gluBanner}
				/>
			)}
			<div className="bg-brand-pale-400">
				<BreadCrumbs breadCrumbItems={breadCrumbItems} glu />
				<div className="text-4xl text-center py-6 -mt-20">
					<p>
						<span
							className={clsx(
								locale === 'en'
									? 'max-smd:text-xl max-lg:mt-10  max-smd:mt-10 flash-effect text-brand-blue-400 rounded inline-block transform transition-transform duration-300 hover:scale-105 active:scale-95'
									: 'text-brand-blue-400 rounded inline-block transform transition-transform duration-300 hover:scale-105 active:scale-95'
							)}
						>
							For Webinar Catch-Ups visit:
						</span>
						<br />
						<a
							className="transform transition-transform duration-300 hover:scale-105 active:scale-95 m-2 px-5 py-2 text-sm md:px-8 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white"
							// href="/elearning/hr-and-employment-law/#webcast"
							href={buildLink(
								locale,
								'/elearning/hr-and-employment-law/#webcast'
							)}
						>
							HR & Employment Law
						</a>
						<a
							className="transform transition-transform duration-300 hover:scale-105 active:scale-95 m-2 px-5 py-2 text-sm md:px-8 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white"
							// href="/elearning/health-and-safety/#webcast"
							href={buildLink(
								locale,
								'/elearning/health-and-safety/#webcast'
							)}
						>
							Health & Safety
						</a>
					</p>
				</div>
			</div>
			{findingCookie ? (
				<div className="flex flex-row justify-center">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							<PageSection theme="White">
								<h1 className=" max-smd:font-bold max-smd:-mb-4 max-lg:font-bold max-lg:text-2xl lg:text-5xl -mt-10 mb-4">
									{title}
								</h1>
								<SessionCardContainer
									liveWebinarSessions={liveWebinarSessions}
									locale={locale}
								/>
								<br />
								<br />
							</PageSection>
						</>
					) : (
						<div className="flex flex-row justify-center py-10  items-center w-full">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
								/>
							</div>
						</div>
					)}
				</>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedWebinarLibrary = ({ data, location }) => (
	<GLULayout locale={data.datoCmsGluWebinarsPage.locale}>
		<WebinarLibrary data={data} location={location} />
	</GLULayout>
);

export default WrappedWebinarLibrary;

export const query = graphql`
	query gluWebinarLibrary($locale: String!) {
		datoCmsGluWebinarsPage(locale: { eq: $locale }) {
			noIndex
			noFollow

			title
			internal {
				type
			}

			seoMeta {
				title
				description
				image {
					url
				}
				twitterCard
			}
			liveWebinarSessions {
				highlightedLiveWebinar {
					title
					dateAndTime
					slug
					shortenedContent {
						value
					}
				}
				image {
					gatsbyImageData(placeholder: DOMINANT_COLOR)
					alt
				}
				upcomingLiveWebinars {
					title
					dateAndTime
					slug
				}
			}
			locale
		}
		allDatoCmsGluBanner(filter: { locale: { eq: $locale } }) {
			nodes {
				link
				id
				title
				viewTitle
				enableFlashing
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
		allDatoCmsGluCourse(
			limit: 1
			filter: {
				isWebinarCatchUp: { eq: true }
				hideInOnDemandLibrary: { ne: true }
				locale: { eq: $locale }
			}
		) {
			totalCount
		}
	}
`;
