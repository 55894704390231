import { useEffect, useState } from 'react';

const prettifyNumber = (num) => num.toString().padStart(2, '0');

const getDigits = (countDown) => {
	const days = parseInt(
		prettifyNumber(Math.floor(countDown / (1000 * 60 * 60 * 24))),
		10
	);
	const hours = parseInt(
		prettifyNumber(
			Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
		),
		10
	);
	const minutes = parseInt(
		prettifyNumber(
			Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
		),
		10
	);
	const seconds = parseInt(
		prettifyNumber(Math.floor((countDown % (1000 * 60)) / 1000)),
		10
	);

	const hasExpired = days + hours + minutes + seconds <= 0;

	return { days, hours, minutes, seconds, hasExpired };
};

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(
		countDownDate - new Date().getTime()
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getDigits(countDown);
};

export default useCountdown;
