/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import {
	StructuredText,
	renderNodeRule,
	renderMarkRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading } from 'datocms-structured-text-utils';
import PropTypes from 'prop-types';

const PageSection = ({
	theme = 'Default',
	titleBlock,
	// eslint-disable-next-line no-unused-vars
	logo,
	children: mainBody,
	className,
	size = 'Regular',
}) => {
	const themeMap = {
		White: {
			color: 'inherit',
			bgColor: 'white',
		},
		Dark: {
			color: 'white',
			bgColor: 'brand-blue-400',
		},
		Default: {
			color: 'inherit',
			bgColor: 'brand-pale-400',
		},
		Inherit: {
			color: 'inherit',
			bgColor: 'inherit',
		},
		Light: {
			color: 'inherit',
			bgColor: 'brand-pale-300',
		},
	}[theme];

	return (
		<section className={`bg-${themeMap.bgColor} text-${themeMap.color}`}>
			<div className={clsx('px-4 mx-auto max-w-m-screen', className)}>
				<div className="flex flex-col items-center justify-between md:flex-row">
					<div className="order-last w-full lg:w-1/2 lg:order-1 mt-12">
						{typeof titleBlock === 'string' ? (
							<h2
								className={clsx(
									'mb-5 font-bold font-castledown-regular',
									size === 'Large'
										? 'text-2xl lg:text-5xl'
										: 'text-2xl lg:text-3xl'
								)}
							>
								{titleBlock}
							</h2>
						) : (
							<StructuredText
								data={titleBlock}
								customNodeRules={[
									renderNodeRule(
										isHeading,
										({ node, children, key }) => {
											const HeadingTag = `h${node.level}`;
											return (
												<HeadingTag
													className={clsx(
														'mb-5  font-bold  font-castledown-regular',
														size === 'Large'
															? 'text-2xl lg:text-5xl'
															: 'text-2xl lg:text-3xl'
													)}
													key={key}
												>
													{children}
												</HeadingTag>
											);
										}
									),
								]}
								customMarkRules={[
									renderMarkRule(
										'highlight',
										({ children, key }) => (
											<span
												className="font-bold text-brand-red-400"
												key={key}
											>
												{children}
											</span>
										)
									),
								]}
							/>
						)}
					</div>
				</div>
				{mainBody}
			</div>
		</section>
	);
};

export default PageSection;

PageSection.defaultProps = {
	theme: 'Default',
};

PageSection.propTypes = {
	children: PropTypes.node.isRequired,
	theme: PropTypes.oneOf(['Default', 'White', 'Dark', 'Light', 'Inherit']),
};
