import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider, Field } from 'formik';
import loadScript from 'load-script2';
import clsx from 'clsx';
import { TextInput } from '../../../atoms/text-input';
import { Button } from '../../../atoms/button';
import { sleep, sendTrackingData, getCookie } from '../../../../utils';
import { validateString, validateEmail } from '../../../../utils/marketo';
import { useFormValidation } from '../../../../hooks/form-validation';
import { useMarketo } from '../../../../contexts/marketo-context';
import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { CallUs } from '../../../atoms/call-us';
import { Textarea } from '../../../atoms/textarea';
import useModal from '../../../../hooks/modal';
import ModalWrapper from '../../molecules/modal-wrapper';

const FeedbackGlu = ({
	pageUrl,
	setIsGlu,
	locale,
	setShowFeedbackBubble,
	setShowCommentForm,
	showCommentForm,
	showFeedbackBubble,
	setResponse,
}) => {
	const ref = useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [closeForm, setCloseForm] = useState(false);
	const [technicalError, setTechnicalError] = useState(false);
	const MktoForms2Instances = useMarketo();
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');
	const [loading, setLoading] = useState(false);
	const title = 'Access your content';
	const formId = 7393;

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const initialValidations = {
		FullName: { isValid: true },
		Email: { isValid: true },
		Comment: { isValid: true },
	};
	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const commentRef = useRef(null);
	const { validations, validateForm } = useFormValidation(initialValidations);

	useEffect(() => {
		if (!validations?.FullName?.isValid && fullNameRef?.current) {
			return fullNameRef.current.focus();
		}

		if (!validations?.Email?.isValid && emailRef?.current) {
			return emailRef.current.focus();
		}
		if (!validations?.Comment?.isValid && commentRef?.current) {
			return commentRef.current.focus();
		}
	}, [validations]);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Comment: '',
		PersonSource: 'PBS GLU E-Learning',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
	});
	const handleFeedback = () => {
		setModalOpen(true);
		setShowCommentForm(true);
		setShowFeedbackBubble(true);
	};
	const handleSubmitComment = () => {
		setShowCommentForm(true);
		setShowFeedbackBubble(false);
		handleFeedback();
	};
	const handleSuccessMessage = () => {
		setShowSuccessMessage(true);
		setTimeout(() => {
			setShowSuccessMessage(false);
		}, 15000);
	};
	const submitHandler = async (event) => {
		setLoading(true);
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) {
			setLoading(false);
			return;
		}

		const names = formEntries.FullName.split(' ');
		setFirstName(names[0]);
		setLastName(names[1]);
		const payloadData = {
			...initialValues,
			FirstName: names[0],
			LastName: names[1] || names[0],
			Email: formEntries.Email,
			MktoCompanyNotes: formEntries.Comment,
			PersonSource: 'Certificate download',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
		};
		await sleep(500);
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setResponse(true);
				// setConsentResponse(true);
				handleSubmitComment();
				setCloseForm(true);
				setLoading(false);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
				setLoading(false);
			});
	};

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);

	const validateEmailPage = (value) => {
		let error;
		const emailAddressRegex =
			/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/i;

		if (!value) {
			error = false;
		} else if (!emailAddressRegex.test(value)) {
			error = false;
		} else {
			error = true;
		}
		return error;
	};
	const isEmailValid = validateEmailPage(emailRef?.current?.value);
	const btnText = 'Submit';
	const shareLink = typeof window !== 'undefined' ? window.location.href : '';
	const content = {
		title,
		text: `Peninsula GLU E-learning UK.`,
		msg: `Please Do Not Remove The Link.`,
		url: shareLink,
	};

	return (
		<>
			{locale === 'en' &&
				setIsGlu &&
				showFeedbackBubble &&
				!showCommentForm && (
					<div className="absolute text-lg left-14 bottom-7 bg-black-800 text-xl text-red p-4 flex items-center space-x-4">
						<div className="animate wave-effect single-words">
							<span>W</span>
							<span>a</span>
							<span>n</span>
							<span>t</span>
							&nbsp;<span>M</span>
							<span>o</span>
							<span>r</span>
							<span>e</span>
							<span>?</span>
						</div>
						<button
							type="submit"
							onClick={handleSubmitComment}
							className="text-xl mt-20 mr-10 "
						>
							<span>C</span>
							<span>l</span>
							<span>i</span>
							<span>c</span>
							<span>k</span>&nbsp;<span>M</span>
							<span>e</span>
							<span>-</span>
							<span>👍</span>
						</button>
					</div>
				)}
			{locale === 'en' &&
				setIsGlu &&
				showCommentForm &&
				isModalOpen &&
				!closeForm && (
					<ModalWrapper>
						<div ref={ref} className="py-20">
							{technicalError ? (
								<div className="px-4 pb-20 bg-brand-pale-300">
									<SidebarHeader
										heading="Oops! Looks like your submission did not reach us 😕"
										text=" We would love for you to try again, using the email below instead. Thank You."
										ariaLive="assertive"
									/>
									<a
										href={`mailto:GLU@peninsula-uk.com?&subject=${content.title}&subject=${content.text}&body=${shareLink} - ${content.msg}`}
										className=" px-4 -py-14 underline bg-brand-pale-300 text-2xl text-blue-400 font-castledown-regular"
									>
										GLU@peninsula-uk.com
									</a>
									<CallUs className="px-4 pt-4 -py-14" />
								</div>
							) : (
								<FormikProvider value={formik}>
									<form
										onSubmit={submitHandler}
										data-formid={formId}
										data-forminstance="one"
									>
										<div className="flex flex-col grow bg-white">
											<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-10 lg:px-8">
												<Field
													name="FullName"
													validate={validateString}
												>
													{({ field }) => (
														<div>
															<label
																htmlFor="FullName"
																className="text-brand-blue-400 font-centra-medium"
															>
																Full Name&#42;
															</label>
															<TextInput
																id="FullName"
																name="FullName"
																aria-required="true"
																className="w-full mb-3 wrapper-small"
																ref={
																	fullNameRef
																}
																showValidation={
																	!validations
																		?.FullName
																		?.isValid &&
																	!fullNameRef
																		?.current
																		?.value
																}
																validationMessage={
																	!validations
																		?.FullName
																		?.isValid
																		? 'Please enter your full name'
																		: null
																}
																{...field}
																space={false}
																placeholder="Enter Your Full Name..."
															/>
														</div>
													)}
												</Field>
												<Field
													name="Email"
													validate={validateEmail}
												>
													{({ field }) => (
														<div>
															<label
																htmlFor="Email"
																className="text-brand-blue-400 font-centra-medium"
															>
																Email&#42;
															</label>
															<TextInput
																id="Email"
																name="Email"
																type="email"
																aria-required="true"
																className="w-full mb-3 wrapper-small"
																ref={emailRef}
																showValidation={
																	!isEmailValid &&
																	!validations
																		?.Email
																		?.isValid
																}
																validationMessage={
																	!validations
																		?.Email
																		?.isValid
																		? 'Please enter your email address'
																		: null
																}
																{...field}
																space={false}
																placeholder="xxxxxxx@gmail.com"
															/>
														</div>
													)}
												</Field>
												<Field
													name="Comment"
													validate={validateString}
												>
													{({ field }) => (
														<div>
															<label
																htmlFor="Comment"
																className="text-brand-blue-400 font-centra-medium"
															>
																Comment&#42;
															</label>
															<Textarea
																id="Comment"
																name="Comment"
																aria-required="true"
																className="w-full mb-3 wrapper-small"
																ref={commentRef}
																showValidation={
																	!validations
																		?.Comment
																		?.isValid &&
																	!commentRef
																		?.current
																		?.value
																}
																validationMessage={
																	!validations
																		?.Comment
																		?.isValid
																		? 'Please enter your comments here'
																		: null
																}
																{...field}
																space={false}
																placeholder="Comments here"
															/>
														</div>
													)}
												</Field>
												<div className="flex flex-col lg:items-center item-start lg:px-8 px-4 pt-3 pb-6 bg-white lg:flex-row lg:justify-between">
													<div className="wrapper-small">
														<Button
															type="submit"
															onClick={
																handleSuccessMessage
															}
															disabled={
																formik.isSubmitting
															}
															className={clsx(
																formik.isSubmitting
																	? 'justify-center loading'
																	: null
															)}
														>
															<span
																className={clsx(
																	'text-sm',
																	formik.isSubmitting
																		? 'invisible'
																		: null
																)}
															>
																{loading
																	? 'please wait...'
																	: btnText}
															</span>
															{formik.isSubmitting ? (
																<span
																	className="sr-only"
																	aria-live="assertive"
																>
																	Submitting
																	your
																	details,
																	please
																	wait...
																</span>
															) : null}
														</Button>
													</div>
												</div>
											</fieldset>
										</div>
									</form>
								</FormikProvider>
							)}
						</div>
					</ModalWrapper>
				)}
			{/* Success message */}
			{locale === 'en' && setIsGlu && closeForm && showSuccessMessage && (
				<div className="absolute bottom-7 bg-black-800 text-red p-4 flex items-center space-x-4">
					<div className="animate  [font-size:20px] wave-effect single-words">
						<span>T</span>
						<span>h</span>
						<span>a</span>
						<span>n</span>
						<span>k</span>&nbsp;
						<span>y</span>
						<span>o</span>
						<span>u</span>&nbsp;
						<span>f</span>
						<span>o</span>
						<span>r</span>&nbsp;<span>y</span>
						<span>o</span>
						<span>u</span>
						<span>r</span>&nbsp;
						<span>f</span>
						<span>e</span>
						<span>e</span>
						<span>d</span>
						<span>b</span>
						<span>a</span>
						<span>c</span>
						<span>k</span> {firstName} {lastName}💖✨
					</div>
				</div>
			)}
		</>
	);
};
FeedbackGlu.defaultProps = {
	setIsGlu: '',
	locale: '',
	setShowFeedbackBubble: '',
	setShowCommentForm: '',
	showCommentForm: '',
	showFeedbackBubble: '',
};

FeedbackGlu.propTypes = {
	pageUrl: PropTypes.string.isRequired,
	setResponse: PropTypes.func.isRequired,
	setIsGlu: PropTypes.string,
	locale: PropTypes.string,
	setShowFeedbackBubble: PropTypes.string,
	setShowCommentForm: PropTypes.string,
	showCommentForm: PropTypes.string,
	showFeedbackBubble: PropTypes.string,
	setModalOpen: PropTypes.func.isRequired,
};
export default FeedbackGlu;
