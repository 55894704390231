import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { getLocale } from '../../../utils/locale';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

export const CallUs = ({ className, onClickProp, number, primaryLinkCopy }) => {
	const [fallBackNumber, setFallBackNumber] = useState('');

	useEffect(() => {
		if (!number) {
			if (locale === 'ca') {
				setFallBackNumber('1 (833) 247-3652');
			} else {
				setFallBackNumber('0800 158 2313');
			}
		}
	}, [number]);

	return (
		<a
			href={`tel:${
				number?.replaceAll(' ', '') ||
				fallBackNumber?.replaceAll(' ', '')
			}`}
			onClick={() =>
				onClickProp({
					click_text: number,
				})
			}
			className={clsx(className)}
		>
			<span className={locale !== 'ca' ? 'rulerclick' : ''}>
				{primaryLinkCopy}
			</span>
		</a>
	);
};

CallUs.defaultProps = {
	className: '',
	onClickProp: () => {},
};

CallUs.propTypes = {
	className: PropTypes.string,
	onClickProp: PropTypes.func,
	number: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
};
