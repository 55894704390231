import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { datoGatsbyImageProp, refProp, titleProp } from '../../../types';
import { useDrawer } from '../../../contexts/drawer-context';
import { Button } from '../../atoms/button';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import * as headingStyles from '../safecheck-text-image-container/index.module.css';
import { sendTrackingData } from '../../../utils';

function SafeCheckTextWithDarkBgContent({
	textWithDarkBgContent,
	donwloadDrawerRef,
	header,
	ctaPrimaryText,
	id,
	imageBlock,
	subTitle,
}) {
	const { as, headingClassNames } = textWithDarkBgContent;
	const { setDrawerRef } = useDrawer();
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});
	const Component = as || 'h1';
	const imageData = getImage(imageBlock);
	const Alt =
		imageData.alt || titleText || 'Peninsula HR & Health and Safty Support';
	const handleClickTracking = (text = null) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: text || ctaPrimaryText,
		});
	};
	return (
		<section
			className="max-w-m-screen mx-auto lg:container bg-black h-auto border-b-[7px] border-[#3954E0] p-3 mt-10"
			aria-labelledby={id}
		>
			<div className="grid md:grid-cols-2  grid-cols-1 text-white ">
				<div className="flex px-1 md:px-5 xl:px-10 xl:py-7 py-4 flex-col gap-1 md:gap-5">
					<div
						className={clsx(
							'flex flex-col shrink mb-5',
							headingStyles.headingContainer
						)}
					>
						<Component
							id={id}
							className={clsx(
								'!leading-[1.3] font-castledown-regular',
								'text-4xl md:text-4xl xl:text-5xl ',
								headingClassNames
							)}
						>
							{titleText}
						</Component>
					</div>
					<p className="mb-0 text-lg font-centra-light  md:text-xl last-of-type:mb-0">
						{subTitle}
					</p>
					{ctaPrimaryText ? (
						<Button
							onClick={() => {
								setDrawerRef(donwloadDrawerRef);
								handleClickTracking(ctaPrimaryText);
							}}
							className="justify-start mt-4 w-max sm:mt-0 hover:bg-white hover:text-black"
						>
							{ctaPrimaryText}
						</Button>
					) : null}
				</div>
				<div className="flex flex-1 w-full flex-col md:px-10 md:pt-3 lg:px-16 xl:px-10 2xl:px-28 xl:pt-10 overflow-hidden 2xl:mb-[-11.9px] xl:mb-[-8.9px]">
					<div
						className={clsx(
							'text-center mt-8 lg:mt-0 lg:text-text pr-[-26px] w-full '
						)}
					>
						<GatsbyImage
							image={imageData}
							alt={Alt}
							className={clsx(
								'drop-shadow-image xl:rotate-[-6deg] xl:bottom-[-32px] lg:rotate-[-6deg] lg:bottom-0]',
								'self-end',
								'justify-self-end',
								'md:justify-self-end'
							)}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

SafeCheckTextWithDarkBgContent.defaultProps = {
	textWithDarkBgContent: {
		as: 'h1',
		headingClassNames: '',
	},
};

SafeCheckTextWithDarkBgContent.propTypes = {
	textWithDarkBgContent: PropTypes.shape({
		as: PropTypes.string,
		headingClassNames: PropTypes.string,
	}),
	id: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired,
	ctaPrimaryText: PropTypes.string.isRequired,
	imageBlock: datoGatsbyImageProp.isRequired,
	header: titleProp.isRequired,
	donwloadDrawerRef: refProp.isRequired,
};
export { SafeCheckTextWithDarkBgContent };
