// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { parseMarkdown } from '../../../utils/parse-md';
import { CallUs } from '../call-us-ppc-v2';

const defaultBannerTitle = `Ask us how we can save your time and money.`; // fallback banner title
const defaultBannerContent = `We’ve helped over **6,500 Canadian** businesses grow by taking the load of HR and health & safety compliance off their backs. Call our experienced advisors today and get answers to all your HR and health & safety queries.`; // fallback banner content

const CtaBannerPPCV2 = ({ ctaBannerContent }) => {
	const { bannerTitle, bannerContent, ctaLabel } = ctaBannerContent;
	const bannerContentParsed = parseMarkdown(bannerContent); // DatoCMS banner content
	const defaultBannerContentParsed = parseMarkdown(defaultBannerContent); // fallback banner content
	return (
		<section
			id="cta-banner-ppc-v2"
			className="pt-20 mt-14 xl:mt-20 pb-24 bg-brand-pale-400"
		>
			<div className="flex flex-col justify-center items-center max-w-sm sm:max-w-xl md:max-w-3xl lg:max-w-6xl xl:max-w-7xl mx-auto text-center px-10">
				<div>
					<h2 className="  text-3xl lg:text-4xl xl:text-5xl font-castledown-regular leading-[1.2] md:leading-[1.3]">
						<span>{bannerTitle || defaultBannerTitle}</span>
					</h2>
				</div>
				<div
					className="mt-6 text-xl md:text-2xl font-centra-light "
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html:
							bannerContentParsed || defaultBannerContentParsed,
					}}
				/>
				<span className="pt-10">
					<CallUs ctaMode ctaLabel={ctaLabel} />
				</span>
			</div>
		</section>
	);
};

export default CtaBannerPPCV2;
