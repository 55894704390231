import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const TabsContext = React.createContext(undefined);

const TabsProvider = ({ children }) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [activeTabPanel, setActiveTabPanel] = useState(null);
	const [numberOfTabs, setNumberOfTabs] = useState(0);

	return (
		<TabsContext.Provider
			value={{
				activeIndex,
				activeTabPanel,
				numberOfTabs,
				setActiveIndex,
				setActiveTabPanel,
				setNumberOfTabs,
			}}
		>
			{children}
		</TabsContext.Provider>
	);
};

TabsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

const useTabs = () => {
	const context = useContext(TabsContext);

	if (context === undefined) {
		throw new Error('useTabs must be used within a TabsProvider');
	}
	return context;
};

export { TabsProvider, useTabs };

export default TabsContext;
