import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';

const IconBanner = ({ data }) => (
	<div className="my-lg-f lg:my-2xl-f">
		<div className="px-3 mx-auto max-w-m-screen lg:px-8">
			<ul
				className={clsx(
					'group',
					'relative',
					'px-s-f pt-xs-f pb-0 rounded-sm sm:px-xs-f sm:pt-s-f',
					'transition-colors duration-200 motion-reduce:transition-none',
					'border-1 border-blue-300',
					'focus-within:outline focus-within:outline-8',
					'grid grid-cols-[1fr_50%] text-left gap-2 bg-brand-pale-300',
					data.length === 3 ? 'sm:grid-cols-3' : 'sm:grid-cols-4'
				)}
			>
				{data.map((item) => {
					let iconSrc;
					const { icon, subHeading, heading } = item;
					if (icon) {
						iconSrc =
							// eslint-disable-next-line import/no-dynamic-require, global-require
							require(`!!file-loader!../../../assets/${icon}.inline.svg`).default;
					}
					return (
						<li
							key={`${heading}-${subHeading}`}
							className={clsx('flex mb-xs-f sm:mb-s-f')}
						>
							{iconSrc && (
								<span className="inline-block mr-2 md:mr-3">
									<SVG
										src={iconSrc}
										className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px] text-blue-400"
									/>
								</span>
							)}
							<span>
								<span className="block mb-2 text-xs-f text-grey-500 lg:text-s-f">
									{heading}
								</span>
								<h3 className="text-s-f font-centra-medium lg:text-base-f">
									{subHeading}
								</h3>
							</span>
						</li>
					);
				})}
			</ul>
		</div>
	</div>
);

IconBanner.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			heading: PropTypes.string.isRequired,
			subHeading: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
};

export { IconBanner };
