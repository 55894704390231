export const costOfLatnessAbsence = (count) =>
	(count * 1038).toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
export const staffTurnover = (9000).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const fineContract = (3750).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const hsFines = (22545).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const draftingPolicy = (1500).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const draftingContract = (1500).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const hsDocs = (1000).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const generalHr = (38868).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
// export const costAccidents = (2089).toLocaleString(undefined, {
// 	minimumFractionDigits: 0,
// 	maximumFractionDigits: 0,
// });
export const averageTribunal = (5117).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

// export const costHrManager = (50600).toLocaleString(undefined, {
// 	minimumFractionDigits: 0,
// 	maximumFractionDigits: 0,
// });
// export const costHsManager = (46000).toLocaleString(undefined, {
// 	minimumFractionDigits: 0,
// 	maximumFractionDigits: 0,
// });
// export const averageBackPain = (3165).toLocaleString(undefined, {
// 	minimumFractionDigits: 0,
// 	maximumFractionDigits: 0,
// });
export const totalCost = (count) =>
	(
		Number(draftingContract.replace(/,/g, '')) +
		Number(draftingPolicy.replace(/,/g, '')) +
		Number(hsDocs.replace(/,/g, '')) +
		Number(fineContract.replace(/,/g, '')) +
		Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
		Number(staffTurnover.replace(/,/g, '')) +
		Number(generalHr.replace(/,/g, '')) +
		Number(hsFines.replace(/,/g, '')) +
		Number(averageTribunal.replace(/,/g, ''))
	).toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});

// export const totalCostHS = (count) => {
// 	if (count > 49) {
// 		return (
// 			Number(costHsManager.replace(/,/g, '')) +
// 			Number(hsDocs.replace(/,/g, '')) +
// 			Number(staffTurnover.replace(/,/g, '')) +
// 			Number(averageBackPain.replace(/,/g, ''))
// 		).toLocaleString(undefined, {
// 			minimumFractionDigits: 0,
// 			maximumFractionDigits: 0,
// 		});
// 	}
// 	return (
// 		Number(draftingContract.replace(/,/g, '')) +
// 		Number(draftingPolicy.replace(/,/g, '')) +
// 		Number(hsDocs.replace(/,/g, '')) +
// 		Number(staffTurnover.replace(/,/g, '')) +
// 		Number(generalHr.replace(/,/g, '')) +
// 		Number(costAccidents.replace(/,/g, ''))
// 	).toLocaleString(undefined, {
// 		minimumFractionDigits: 0,
// 		maximumFractionDigits: 0,
// 	});
// };

// export const totalCostES = (count) => {
// 	if (count > 49) {
// 		return (
// 			Number(costHrManager.replace(/,/g, '')) +
// 			Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
// 			Number(staffTurnover.replace(/,/g, '')) +
// 			Number(averageTribunal.replace(/,/g, '')) +
// 			Number(hsDocs.replace(/,/g, '')) +
// 			Number(averageBackPain.replace(/,/g, ''))
// 		).toLocaleString(undefined, {
// 			minimumFractionDigits: 0,
// 			maximumFractionDigits: 0,
// 		});
// 	}
// 	return (
// 		Number(draftingContract.replace(/,/g, '')) +
// 		Number(draftingPolicy.replace(/,/g, '')) +
// 		Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
// 		Number(staffTurnover.replace(/,/g, '')) +
// 		Number(generalHr.replace(/,/g, '')) +
// 		Number(averageTribunal.replace(/,/g, ''))
// 	).toLocaleString(undefined, {
// 		minimumFractionDigits: 0,
// 		maximumFractionDigits: 0,
// 	});
// };

export const annualCost = (count) => {
	let amount = 0;
	switch (true) {
		case count <= 5:
			amount = 366 * 12;
			break;
		case count <= 10:
			amount = 461 * 12;
			break;
		case count === '11':
			amount = 479 * 12;
			break;
		case count === '12':
			amount = 499 * 12;
			break;
		case count === '13':
			amount = 539 * 12;
			break;
		case count === '14':
			amount = 569 * 12;
			break;
		case count === '15':
			amount = 609 * 12;
			break;
		case count === '16':
			amount = 639 * 12;
			break;
		case count === '17':
			amount = 659 * 12;
			break;
		case count === '18':
			amount = 689 * 12;
			break;
		case count === '19':
			amount = 719 * 12;
			break;
		case count === '20':
			amount = 749 * 12;
			break;
		case count <= 24:
			amount = 759 * 12;
			break;
		case count <= 29:
			amount = 799 * 12;
			break;
		case count <= 34:
			amount = 839 * 12;
			break;
		case count <= 39:
			amount = 869 * 12;
			break;
		case count <= 44:
			amount = 899 * 12;
			break;
		case count <= 49:
			amount = 929 * 12;
			break;
		case count <= 59:
			amount = 979 * 12;
			break;
		case count <= 69:
			amount = 1029 * 12;
			break;
		case count <= 79:
			amount = 1079 * 12;
			break;
		case count <= 89:
			amount = 1129 * 12;
			break;
		case count <= 99:
			amount = 1169 * 12;
			break;
		default:
			amount = 'CAT';
			break;
	}
	if (count > 99) {
		return 'CAT';
	}
	return amount.toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

// export const annualCostHS = (count) => {
// 	let amount = 0;
// 	switch (true) {
// 		case count <= 5:
// 			amount = 150 * 12;
// 			break;
// 		case count <= 10:
// 			amount = 191 * 12;
// 			break;
// 		case count === '11':
// 			amount = 199 * 12;
// 			break;
// 		case count === '12':
// 			amount = 208 * 12;
// 			break;
// 		case count === '13':
// 			amount = 215 * 12;
// 			break;
// 		case count === '14':
// 			amount = 225 * 12;
// 			break;
// 		case count === '15':
// 			amount = 232 * 12;
// 			break;
// 		case count === '16':
// 			amount = 234 * 12;
// 			break;
// 		case count === '17':
// 			amount = 236 * 12;
// 			break;
// 		case count === '18':
// 			amount = 238 * 12;
// 			break;
// 		case count === '19':
// 			amount = 241 * 12;
// 			break;
// 		case count === '20':
// 			amount = 243 * 12;
// 			break;
// 		case count <= 24:
// 			amount = 244 * 12;
// 			break;
// 		case count <= 29:
// 			amount = 250 * 12;
// 			break;
// 		case count <= 34:
// 			amount = 261 * 12;
// 			break;
// 		case count <= 39:
// 			amount = 271 * 12;
// 			break;
// 		case count <= 44:
// 			amount = 276 * 12;
// 			break;
// 		case count <= 49:
// 			amount = 283 * 12;
// 			break;
// 		case count <= 59:
// 			amount = 299 * 12;
// 			break;
// 		case count <= 69:
// 			amount = 312 * 12;
// 			break;
// 		case count <= 79:
// 			amount = 327 * 12;
// 			break;
// 		case count <= 89:
// 			amount = 350 * 12;
// 			break;
// 		case count <= 99:
// 			amount = 373 * 12;
// 			break;
// 		case count <= 119:
// 			amount = 405 * 12;
// 			break;
// 		case count <= 139:
// 			amount = 450 * 12;
// 			break;
// 		case count <= 159:
// 			amount = 496 * 12;
// 			break;
// 		case count <= 179:
// 			amount = 540 * 12;
// 			break;
// 		case count <= 199:
// 			amount = 586 * 12;
// 			break;
// 		case count <= 224:
// 			amount = 636 * 12;
// 			break;
// 		case count <= 249:
// 			amount = 692 * 12;
// 			break;

// 		default:
// 			amount = 'POA';
// 			break;
// 	}
// 	if (count > 249) {
// 		return 'POA';
// 	}
// 	return amount.toLocaleString(undefined, {
// 		minimumFractionDigits: 0,
// 		maximumFractionDigits: 0,
// 	});
// };

// export const annualCostES = (count) => {
// 	let amount = 0;
// 	switch (true) {
// 		case count <= 5:
// 			amount = 150 * 12;
// 			break;
// 		case count <= 10:
// 			amount = 197 * 12;
// 			break;
// 		case count === '11':
// 			amount = 229 * 12;
// 			break;
// 		case count === '12':
// 			amount = 246 * 12;
// 			break;
// 		case count === '13':
// 			amount = 263 * 12;
// 			break;
// 		case count === '14':
// 			amount = 280 * 12;
// 			break;
// 		case count === '15':
// 			amount = 297 * 12;
// 			break;
// 		case count === '16':
// 			amount = 310 * 12;
// 			break;
// 		case count === '17':
// 			amount = 326 * 12;
// 			break;
// 		case count === '18':
// 			amount = 342 * 12;
// 			break;
// 		case count === '19':
// 			amount = 360 * 12;
// 			break;
// 		case count === '20':
// 			amount = 374 * 12;
// 			break;
// 		case count <= 24:
// 			amount = 381 * 12;
// 			break;
// 		case count <= 29:
// 			amount = 395 * 12;
// 			break;
// 		case count <= 34:
// 			amount = 401 * 12;
// 			break;
// 		case count <= 39:
// 			amount = 421 * 12;
// 			break;
// 		case count <= 44:
// 			amount = 458 * 12;
// 			break;
// 		case count <= 49:
// 			amount = 479 * 12;
// 			break;
// 		case count <= 59:
// 			amount = 506 * 12;
// 			break;
// 		case count <= 69:
// 			amount = 531 * 12;
// 			break;
// 		case count <= 79:
// 			amount = 563 * 12;
// 			break;
// 		case count <= 89:
// 			amount = 592 * 12;
// 			break;
// 		case count <= 99:
// 			amount = 639 * 12;
// 			break;
// 		case count <= 119:
// 			amount = 700 * 12;
// 			break;
// 		case count <= 139:
// 			amount = 782 * 12;
// 			break;
// 		case count <= 159:
// 			amount = 864 * 12;
// 			break;
// 		case count <= 179:
// 			amount = 964 * 12;
// 			break;
// 		case count <= 199:
// 			amount = 1027 * 12;
// 			break;
// 		case count <= 224:
// 			amount = 1122 * 12;
// 			break;
// 		case count <= 249:
// 			amount = 1224 * 12;
// 			break;

// 		default:
// 			amount = 'POA';
// 			break;
// 	}
// 	if (count > 249) {
// 		return 'POA';
// 	}
// 	return amount.toLocaleString(undefined, {
// 		minimumFractionDigits: 0,
// 		maximumFractionDigits: 0,
// 	});
// };

export const costPerEmployee = (count) => {
	if (annualCost(count) === 'CAT') {
		return 'CAT Quote';
	}
	const ac = Number(annualCost(count).replace(/,/g, ''));
	const c = Number(count);
	if (c === 0) {
		return 0;
	}
	const total = ac / c / 12;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

// export const costPerEmployeeHS = (count) => {
// 	if (annualCostHS(count) === 'POA') {
// 		return 'POA';
// 	}
// 	const ac = Number(annualCostHS(count).replace(/,/g, ''));
// 	const c = Number(count);
// 	if (c === 0) {
// 		return 0;
// 	}
// 	const total = ac / c / 12;
// 	return total.toLocaleString(undefined, {
// 		minimumFractionDigits: 2,
// 		maximumFractionDigits: 2,
// 	});
// };

// export const costPerEmployeeES = (count) => {
// 	if (annualCostES(count) === 'POA') {
// 		return 'POA';
// 	}
// 	const ac = Number(annualCostES(count).replace(/,/g, ''));
// 	const c = Number(count);
// 	if (c === 0) {
// 		return 0;
// 	}
// 	const total = ac / c / 12;
// 	return total.toLocaleString(undefined, {
// 		minimumFractionDigits: 2,
// 		maximumFractionDigits: 2,
// 	});
// };

// export const annualRoiES = (count) => {
// 	if (annualCostES(count) === 'POA') {
// 		return 'POA';
// 	}
// 	const tc = Number(totalCostES(count).replace(/,/g, ''));
// 	const ac = Number(annualCostES(count).replace(/,/g, ''));
// 	if (ac === 0) {
// 		return 0;
// 	}
// 	const total = tc / ac;
// 	return total.toLocaleString(undefined, {
// 		minimumFractionDigits: 2,
// 		maximumFractionDigits: 2,
// 	});
// };

// export const annualRoiHS = (count) => {
// 	if (annualCostHS(count) === 'POA') {
// 		return 'POA';
// 	}
// 	const tc = Number(totalCostHS(count).replace(/,/g, ''));
// 	const ac = Number(annualCostHS(count).replace(/,/g, ''));
// 	if (ac === 0) {
// 		return 0;
// 	}
// 	const total = tc / ac;
// 	return total.toLocaleString(undefined, {
// 		minimumFractionDigits: 2,
// 		maximumFractionDigits: 2,
// 	});
// };

export const annualRoi = (count) => {
	if (annualCost(count) === 'CAT') {
		return 'CAT';
	}
	const tc = Number(totalCost(count).replace(/,/g, ''));
	const ac = Number(annualCost(count).replace(/,/g, ''));
	if (ac === 0) {
		return 0;
	}
	const total = tc / ac;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};
