export const industryData = {
	hospitality: {
		lateness_absence: 1.33,
		staff_turnover: 1.4,
		hr_docs: 1.05,
		hs_docs: 2.41,
		disciplinary: 2.11,
		ohs_fines: 1,
		general_hr: 1.69,
		cost_accidents: 1.97,
		average_wrongful: 1.34,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.01,
	},
	education: {
		lateness_absence: 1.36,
		staff_turnover: 1.28,
		hr_docs: 1.62,
		hs_docs: 1,
		disciplinary: 1.43,
		ohs_fines: 1,
		general_hr: 1.41,
		cost_accidents: 0.81,
		average_wrongful: 1.01,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.87,
	},
	agriculture: {
		lateness_absence: 0.81,
		staff_turnover: 0.84,
		hr_docs: 0.81,
		hs_docs: 1,
		disciplinary: 1.21,
		ohs_fines: 1,
		general_hr: 0.61,
		cost_accidents: 1.31,
		average_wrongful: 1.44,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 0.75,
	},
	vets_pets: {
		lateness_absence: 1.26,
		staff_turnover: 1.17,
		hr_docs: 1,
		hs_docs: 1,
		disciplinary: 0.73,
		ohs_fines: 1,
		general_hr: 1.34,
		cost_accidents: 0.8,
		average_wrongful: 1.26,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.06,
	},
	childcare: {
		lateness_absence: 1.8,
		staff_turnover: 1.07,
		hr_docs: 1.1,
		hs_docs: 1,
		disciplinary: 1.23,
		ohs_fines: 1,
		general_hr: 1.33,
		cost_accidents: 1.22,
		average_wrongful: 0.81,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.25,
	},
	automotive: {
		lateness_absence: 0.79,
		staff_turnover: 0.88,
		hr_docs: 0.75,
		hs_docs: 4.02,
		disciplinary: 0.98,
		ohs_fines: 1,
		general_hr: 0.74,
		cost_accidents: 1.01,
		average_wrongful: 1.11,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 0.74,
	},
	beauty_hair: {
		lateness_absence: 1.29,
		staff_turnover: 0.91,
		hr_docs: 0.87,
		hs_docs: 1,
		disciplinary: 0.56,
		ohs_fines: 1,
		general_hr: 1.14,
		cost_accidents: 0.46,
		average_wrongful: 1.53,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 0.95,
	},
	health_medical: {
		lateness_absence: 1.25,
		staff_turnover: 1.1,
		hr_docs: 1.26,
		hs_docs: 1.12,
		disciplinary: 0.93,
		ohs_fines: 1,
		general_hr: 1.16,
		cost_accidents: 0.89,
		average_wrongful: 1.18,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.26,
	},
	retail_wholesale: {
		lateness_absence: 1.1,
		staff_turnover: 1.54,
		hr_docs: 1.19,
		hs_docs: 1,
		disciplinary: 1.35,
		ohs_fines: 1,
		general_hr: 1.35,
		cost_accidents: 1.03,
		average_wrongful: 1.12,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.39,
	},
	finance_insurance: {
		lateness_absence: 1.16,
		staff_turnover: 1.05,
		hr_docs: 1.43,
		hs_docs: 1,
		disciplinary: 0.59,
		ohs_fines: 1,
		general_hr: 0.67,
		cost_accidents: 0.3,
		average_wrongful: 1.09,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.15,
	},
	sports_recreation: {
		lateness_absence: 0.65,
		staff_turnover: 0.7,
		hr_docs: 1.1,
		hs_docs: 1,
		disciplinary: 0.81,
		ohs_fines: 1,
		general_hr: 1.09,
		cost_accidents: 0.91,
		average_wrongful: 0.57,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.11,
	},
	manufacturing: {
		lateness_absence: 1.85,
		staff_turnover: 1.57,
		hr_docs: 1.7,
		hs_docs: 1,
		disciplinary: 1.66,
		ohs_fines: 1,
		general_hr: 1.53,
		cost_accidents: 2.72,
		average_wrongful: 1.8,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.63,
	},
	professional_services: {
		lateness_absence: 1.03,
		staff_turnover: 1.09,
		hr_docs: 1.14,
		hs_docs: 2.34,
		disciplinary: 1.01,
		ohs_fines: 1,
		general_hr: 0.98,
		cost_accidents: 0.83,
		average_wrongful: 1.15,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.06,
	},
	real_estate: {
		lateness_absence: 0.83,
		staff_turnover: 1.29,
		hr_docs: 1.06,
		hs_docs: 1,
		disciplinary: 0.85,
		ohs_fines: 1,
		general_hr: 0.78,
		cost_accidents: 0.73,
		average_wrongful: 0.69,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.12,
	},
	household_services: {
		lateness_absence: 0.8,
		staff_turnover: 0.78,
		hr_docs: 0.93,
		hs_docs: 1,
		disciplinary: 0.57,
		ohs_fines: 1,
		general_hr: 0.92,
		cost_accidents: 1.05,
		average_wrongful: 0.75,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 0.71,
	},
	transportation_storage: {
		lateness_absence: 0.81,
		staff_turnover: 1.07,
		hr_docs: 1.02,
		hs_docs: 1,
		disciplinary: 1.2,
		ohs_fines: 1,
		general_hr: 0.94,
		cost_accidents: 1.02,
		average_wrongful: 1.28,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 0.91,
	},
	construction: {
		lateness_absence: 1.03,
		staff_turnover: 1.16,
		hr_docs: 1.26,
		hs_docs: 2.46,
		disciplinary: 1.07,
		ohs_fines: 1,
		general_hr: 0.87,
		cost_accidents: 1.4,
		average_wrongful: 1.02,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1.26,
	},
	dentists: {
		lateness_absence: 0.82,
		staff_turnover: 1.34,
		hr_docs: 0.96,
		hs_docs: 1,
		disciplinary: 0.65,
		ohs_fines: 1,
		general_hr: 1.15,
		cost_accidents: 0.57,
		average_wrongful: 1.13,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 0.22,
	},
	other: {
		lateness_absence: 1,
		staff_turnover: 1,
		hr_docs: 1,
		hs_docs: 1,
		disciplinary: 1,
		ohs_fines: 1,
		general_hr: 1,
		cost_accidents: 1,
		average_wrongful: 1,
		hr_manager_cost: 1,
		hs_manager_cost: 1,
		single_policy: 1,
	},
};
