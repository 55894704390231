export const industries = [
	{
		value: 'accountancy',
		label: 'Accountancy',
	},
	{
		value: 'advertising',
		label: 'Advertising',
	},
	{
		value: 'aerospace',
		label: 'Aerospace',
	},
	{
		value: 'agriculture',
		label: 'Agriculture',
	},
	{
		value: 'apparel',
		label: 'Apparel',
	},
	{
		value: 'automotive',
		label: 'Automotive',
	},
	{
		value: 'banking',
		label: 'Banking',
	},
	{
		value: 'beauty-cosmetics',
		label: 'Beauty and Cosmetics',
	},
	{
		value: 'biotechnology',
		label: 'Biotechnology',
	},
	{
		value: 'chemicals',
		label: 'Chemicals',
	},
	{
		value: 'communications',
		label: 'Communications',
	},
	{
		value: 'construction',
		label: 'Construction',
	},
	{
		value: 'consulting',
		label: 'Consulting',
	},
	{
		value: 'digital Services',
		label: 'Digital Services',
	},
	{
		value: 'education',
		label: 'Education',
	},
	{
		value: 'electronics',
		label: 'Electronics',
	},
	{
		value: 'employment',
		label: 'Employment',
	},
	{
		value: 'energy',
		label: 'Energy',
	},
	{
		value: 'engineering',
		label: 'Engineering',
	},
	{
		value: 'entertainment',
		label: 'Entertainment',
	},
	{
		value: 'environmental',
		label: 'Environmental',
	},
	{
		value: 'fashion',
		label: 'Fashion',
	},
	{
		value: 'finance',
		label: 'Finance',
	},
	{
		value: 'food-beverage',
		label: 'Food and Beverage',
	},
	{
		value: 'government',
		label: 'Government',
	},
	{
		value: 'healthcare',
		label: 'Healthcare',
	},
	{
		value: 'hospitality',
		label: 'Hospitality',
	},
	{
		value: 'hr-professional',
		label: 'HR Professional',
	},
	{
		value: 'information-technology',
		label: 'Information Technology',
	},
	{
		value: 'insurance',
		label: 'Insurance',
	},
	{
		value: 'journalism-news',
		label: 'Journalism and News',
	},
	{
		value: 'legal-services',
		label: 'Legal Services',
	},
	{
		value: 'machinery',
		label: 'Machinery',
	},
	{
		value: 'management-consultancy',
		label: 'Management Consultancy',
	},
	{
		value: 'manufacturing',
		label: 'Manufacturing',
	},
	{
		value: 'media',
		label: 'Media',
	},
	{
		value: 'not-for-profit',
		label: 'Not For Profit',
	},
	{
		value: 'other',
		label: 'Other',
	},
	{
		value: 'pharmaceutical',
		label: 'Pharmaceutical',
	},
	{
		value: 'property',
		label: 'Property',
	},
	{
		value: 'publishing',
		label: 'Publishing',
	},
	{
		value: 'recreation',
		label: 'Recreation',
	},
	{
		value: 'recruitment',
		label: 'Recruitment',
	},
	{
		value: 'retail',
		label: 'Retail',
	},
	{
		value: 'service',
		label: 'Service',
	},
	{
		value: 'shipping',
		label: 'Shipping',
	},
	{
		value: 'sports',
		label: 'Sports',
	},
	{
		value: 'technology',
		label: 'Technology',
	},
	{
		value: 'telecommunications',
		label: 'Telecommunications',
	},
	{
		value: 'transportation',
		label: 'Transportation',
	},
	{
		value: 'travel',
		label: 'Travel',
	},
	{
		value: 'utilities',
		label: 'Utilities',
	},
];

export const services = [
	{
		value: 'hr',
		label: 'HR',
	},
	{
		value: 'employment-law',
		label: 'Employment law',
	},
	{
		value: 'health-safety',
		label: 'Health and safety',
	},
	{
		value: 'all',
		label: 'All of the above',
	},
];
