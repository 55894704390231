import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const InputVariants = {
	Default:
		'block px-4 py-3 mb-3 border-1 rounded-xs border-blue-500 border-solid bg-brand-pale-300 placeholder:text-grey-400',
	Rounded:
		'rounded-lg border-1 border-white ring ring-1 ring-black bg-white px-6 py-4 font-centra-light text-base-f leading-none placeholder:text-grey-500 focus:ring-blue-400',
	RoundedThick:
		'rounded-lg border-2 border-blue-400 bg-white px-6 py-4 font-centra-light text-base-f leading-none placeholder:text-grey-500 focus:!border-black outline-none focus:ring-0',
};

const TextInput = forwardRef(
	(
		{
			type,
			labelText,
			id,
			hintText,
			name,
			validationMessage,
			showValidation,
			showLabel,
			space,
			className,
			variant,
			inputOnChange,
			inputValue,
			...inputAttributes
		},
		ref
	) => {
		const [textValue, setTextValue] = useState(inputValue || '');
		const errorId = `${id}-error`;
		const hintTextId = `${id}-hint-text`;

		const handleOnChange = (e) => {
			if (inputOnChange) {
				inputOnChange(e.target.value);
				setTextValue(e.target.value);
			} else {
				setTextValue(e.target.value);
			}
		};

		return (
			<div
				className={clsx(
					'relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none',
					space ? 'mb-6' : null,
					showValidation ? 'before:bg-alert-400' : '',
					className
				)}
			>
				<div className={clsx(showLabel ? 'mb-4' : null)}>
					<label
						htmlFor={id}
						className={clsx(
							'text-brand-blue-400 text-base-f font-centra-medium',
							showLabel === false ? 'sr-only' : null
						)}
					>
						{labelText}
					</label>
					{hintText ? <p id={hintTextId}>{hintText}</p> : null}
				</div>

				<input
					className={clsx(
						InputVariants[variant],
						'w-full appearance-none hover:bg-brand-pale-400 focus:border-blue-400 focus:placeholder:text-brand-blue-400 transition-colors duration-200 motion-reduce:transition-none',
						textValue.length > 0
							? 'border-brand-blue-400 bg-brand-pale-300'
							: '',
						showValidation
							? 'border-alert-400 placeholder:text-alert-500'
							: ''
					)}
					type={type}
					name={name}
					id={id}
					onChange={handleOnChange}
					aria-invalid={!!showValidation}
					aria-describedby={
						hintText ? `${hintTextId} ${errorId}` : errorId
					}
					value={textValue}
					ref={ref}
					{...inputAttributes}
				/>

				{showValidation ? (
					<p
						className="mt-4 text-left text-alert-500 status"
						id={errorId}
					>
						{validationMessage}
					</p>
				) : null}
			</div>
		);
	}
);

TextInput.defaultProps = {
	className: '',
	type: 'text',
	hintText: undefined,
	validationMessage: '',
	showValidation: false,
	showLabel: true,
	variant: 'Default',
	space: true,
	inputOnChange: undefined,
	inputValue: '',
	labelText: '',
};

TextInput.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	labelText: PropTypes.string,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hintText: PropTypes.string,
	validationMessage: PropTypes.string,
	showValidation: PropTypes.bool,
	showLabel: PropTypes.bool,
	variant: PropTypes.string,
	space: PropTypes.bool,
	inputOnChange: PropTypes.func,
	inputValue: PropTypes.string,
};

export { TextInput };
