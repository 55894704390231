export const checkMediaTypes = (urls) => {
	const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
	const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm'];

	const imageArr = [];
	const videoArr = [];

	if (urls && urls?.length > 0) {
		urls?.forEach((elm) => {
			const extension = elm.media?.url
				.split('.')
				.pop()
				.split('?')[0]
				.toLowerCase();

			if (imageExtensions?.includes(`.${extension}`)) {
				if (!videoArr?.length > 0) imageArr.push(elm?.media?.url);
			} else if (videoExtensions?.includes(`.${extension}`)) {
				if (!videoArr?.length > 0 && !imageArr?.length > 0)
					videoArr.push(elm?.media?.url);
			}
		});

		// Check conditions
		if (imageArr?.length === 1 && videoArr?.length === 0) {
			// console.log('Valid: 1 image.');
			return {
				isSoloImg: true,
				isTwinImg: false,
				isVideo: false,
			};
		}
		if (imageArr?.length === 2 && videoArr?.length === 0) {
			// console.log('Valid: 2 images.');
			return {
				isSoloImg: false,
				isTwinImg: true,
				isVideo: false,
			};
		}
		if (imageArr?.length === 0 && videoArr?.length === 1) {
			// console.log('Valid: 1 video.');
			return {
				isSoloImg: false,
				isTwinImg: false,
				isVideo: true,
			};
		}
	} else {
		return {
			isSoloImg: false,
			isTwinImg: false,
			isVideo: false,
		};
	}
};
