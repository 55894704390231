import React from 'react';
import PropTypes from 'prop-types';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';
import { clsx } from 'clsx';
import { useLocation } from '@reach/router';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';

import { titleProp, refProp, rteContent } from '../../../types';
import { useDrawer } from '../../../contexts/drawer-context';
import { getRteContent } from '../../../utils';
import { getLocale, buildLink } from '../../../utils/locale';

const TwoColText = ({
	textColTextContent,
	drawerRef,
	markdownSupported = false,
	openModal,
	peninsulaNow,
	pageName,
}) => {
	const {
		title,
		content,
		id,
		primaryLink,
		primaryLinkCopy,
		primaryButtonOpenForm,
		secondaryLink,
		secondaryLinkCopy,
	} = textColTextContent;
	const { setDrawerRef } = useDrawer();
	const location = useLocation();
	const locale = getLocale(location.pathname);
	let primaryCta;
	if (primaryButtonOpenForm) {
		primaryCta = (
			<Button
				onClick={() => {
					if (
						locale === '' &&
						peninsulaNow &&
						pageName === 'childService'
					) {
						openModal();
					} else {
						setDrawerRef(drawerRef);
					}
				}}
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink) {
		primaryCta = (
			<PBSLink to={buildLink(locale, primaryLink)}>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	return (
		<section
			className="px-4 mx-auto md:px-8 max-w-m-screen py-xl-f xl:py-2xl-f"
			aria-labelledby={id}
		>
			<HeadingTagLine
				className="mb-4 md:mb-8"
				headingClassNames="text-left md:!text-4xl"
				id={id}
				title={title}
			/>

			<div className="md:gap-12 md:columns-2 lg:gap-[60px]">
				{markdownSupported ? (
					<StructuredText
						data={content}
						customNodeRules={[
							renderNodeRule(
								isHeading,
								({ node, children, key }) => {
									const HeadingTag = `h${node.level}`;
									return (
										<HeadingTag
											className="mb-5 text-lg text-brand-red-400 font-castledown-regular"
											key={key}
										>
											{children}
										</HeadingTag>
									);
								}
							),
							renderNodeRule(
								isLink,
								({ node, children, key }) => (
									<PBSLink
										to={buildLink(locale, node.url)}
										variant="Link"
										key={key}
									>
										{children}
									</PBSLink>
								)
							),
							renderNodeRule(
								isList,
								({ node, children, key }) => {
									let ListTag;
									let listClassName;

									if (node.style === 'bulleted') {
										ListTag = 'ul';
										listClassName = 'list-disc';
									}
									if (node.style === 'numbered') {
										ListTag = 'ol';
										listClassName = 'list-decimal';
									}

									return (
										<ListTag
											className={clsx(
												'mb-4 font-centra-light list-inside',
												listClassName
											)}
											key={key + node.style}
										>
											{children}
										</ListTag>
									);
								}
							),
							renderNodeRule(
								isParagraph,
								({ children, key, ancestors }) => {
									if (isRoot(ancestors[0])) {
										return (
											<p
												className="mb-4 font-centra-light last-of-type:mb-0"
												key={key}
											>
												{children}
											</p>
										);
									}
									return children;
								}
							),
						]}
						customMarkRules={[
							renderMarkRule('strong', ({ children, key }) => (
								<span className="font-centra-book" key={key}>
									{children}
								</span>
							)),
						]}
					/>
				) : (
					getRteContent(content, id)
				)}
				<div className="flex flex-col items-center justify-center mt-8 md:items-start xl:flex-row xl:justify-start">
					{primaryCta}
					{secondaryLink && secondaryLinkCopy ? (
						<PBSLink
							to={secondaryLink}
							variant="White"
							className="mt-5 xl:ml-5 xl:mt-0"
						>
							{secondaryLinkCopy}
						</PBSLink>
					) : null}
				</div>
			</div>
		</section>
	);
};

TwoColText.defaultProps = {
	markdownSupported: false,
	openModal: false,
	peninsulaNow: false,
	pageName: '',
};

TwoColText.propTypes = {
	textColTextContent: PropTypes.shape({
		title: titleProp.isRequired,
		id: PropTypes.string.isRequired,
		content: rteContent.isRequired,
		primaryLink: PropTypes.string,
		primaryLinkCopy: PropTypes.string.isRequired,
		primaryButtonOpenForm: PropTypes.bool,
		secondaryLink: PropTypes.string.isRequired,
		secondaryLinkCopy: PropTypes.string.isRequired,
	}).isRequired,
	drawerRef: refProp.isRequired,
	markdownSupported: PropTypes.bool,
	openModal: PropTypes.bool,
	peninsulaNow: PropTypes.bool,
	pageName: PropTypes.string,
};

export { TwoColText };
