import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { IconCard } from '../../atoms/icon-card-bdm';
import { refProp } from '../../../types';

const IconCardList = ({
	cards,
	variant,
	showContentLinks,
	isTimeline,
	bdmNumber,
	drawerRef,
}) => (
	<ul
		className={clsx(
			'w-full grid gap-xs-f md:gap-s-f grid-flow-col',
			'auto-cols-[minmax(299px,_1fr)]',
			'overflow-x-auto overflow-y-hidden',
			'list-scrollbar',
			'pb-xs-f md:pb-base-f',
			'px-[8px] pt-[8px]',
			variant === 'Dark' && 'list-scrollbar-light',
			cards.length > 4 ? 'lg:auto-cols-[minmax(340px,_1fr)]' : ''
		)}
	>
		{cards.map((item, index) => (
			<li
				key={`${item.title}${Math.random()}`}
				className={clsx(
					isTimeline
						? 'relative before:w-5 before:h-2 before:bg-blue-400 before:absolute before:top-1/2 before:-right-5 last:before:content-none'
						: null
				)}
			>
				<IconCard
					{...item}
					variant={variant === 'Dark' ? 'Light' : 'Default'}
					hasLinks={showContentLinks}
					index={index}
					svgStyles={
						isTimeline
							? 'w-[40px] h-[40px] md:w-[54px] md:h-[54px]'
							: null
					}
					bdmNumber={bdmNumber}
					drawerRef={drawerRef}
				/>
			</li>
		))}
	</ul>
);

IconCardList.defaultProps = {
	variant: 'Default',
	showContentLinks: true,
	isTimeline: false,
	bdmNumber: '',
	drawerRef: '',
};

IconCardList.propTypes = {
	showContentLinks: PropTypes.bool,
	variant: PropTypes.string,
	isTimeline: PropTypes.bool,
	cards: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.string.isRequired,
			linkCopy: PropTypes.string,
			link: PropTypes.string,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
	bdmNumber: PropTypes.string,
	drawerRef: refProp,
};

export { IconCardList };
