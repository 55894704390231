export const isVideo = (url) => {
	const videoExtensions = [
		'mp4',
		'mkv',
		'webm',
		'avi',
		'mov',
		'flv',
		'wmv',
		'mpeg',
		'mpg',
		'3gp',
		'3g2',
		'h264',
		'm4v',
		'ts',
		'rm',
		'rmvb',
		'f4v',
		'vob',
		'divx',
		'dv',
		'mxg',
		'mxf',
	];

	// Extract the file extension from the URL
	const extension = url.split('.').pop().split('?')[0].toLowerCase();

	// Check if the extracted extension is in the list of video extensions
	return videoExtensions.includes(extension);
};
