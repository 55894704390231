import React from 'react';
import clsx from 'clsx';
import Link from 'gatsby-link';

import { useLocation } from '@reach/router';
import { eventsCardProp } from '../../../types';

import MapPin from '../../../assets/map-pin.inline.svg';
import MapPinOutline from '../../../assets/map-pin-outline.inline.svg';
import Calendar from '../../../assets/calendar.inline.svg';

import * as styles from '../card/index.module.css';
import { buildLink, getLocale } from '../../../utils/locale';

const EventsCard = ({
	as,
	onClickProp,
	cardContent,
	to,
	loading,
	className,
	grow,
}) => {
	const {
		id,
		index,
		slug,
		eventTime,
		eventDate,
		eventDateTime,
		eventLocation,
		shortDescription,
	} = cardContent;

	const linkText = `View event at ${eventLocation.venueName} on ${eventDate}`;
	const url = useLocation();
	const locale = getLocale(url.pathname);
	const Element = as || 'article';
	const linkStyles = `inline bg-gradient-to-r [background-size:0_2px] [background-position:0_100%] bg-no-repeat transition-all duration-300 motion-reduce:transition-none before:absolute before:top-0 before:left-0 before:w-full before:h-full focus:[background-size:100%_2px] group-hover:[background-size:100%_2px] focus:outline-none`;

	if (loading) {
		return (
			<Element
				className={clsx(
					'group relative bg-white h-full w-full',
					'border-2 border-solid rounded-sm shadow-card',
					styles.borderLoading,
					className
				)}
				aria-labelledby={`${id}-card`}
			>
				<div
					className={clsx(
						'w-full h-[220px] md:h-[260px] block',
						styles.bgLoading
					)}
				/>

				<div className="p-4 pt-base-f">
					<div
						className={clsx(
							'flex h-[30px] w-[50%]',
							styles.bgLoading
						)}
					/>

					<div className="mb-base-f">
						<div
							className={clsx(
								'mt-4 flex h-[40px]',
								styles.bgLoading
							)}
						/>

						<div
							className={clsx(
								'mt-4 flex h-[100px]',
								styles.bgLoading
							)}
						/>
					</div>

					<div
						className={clsx(
							'flex pt-4 border-t',
							styles.breakBorderLoading
						)}
					>
						<div
							className={clsx(
								'flex h-[30px] w-[35%] mr-3',
								styles.bgLoading
							)}
						/>
						<div
							className={clsx(
								'flex h-[30px] w-[35%]',
								styles.bgLoading
							)}
						/>
					</div>
				</div>
			</Element>
		);
	}

	return (
		<article
			className={clsx(
				'flex flex-col group relative bg-white w-full',
				'border-2 border-blue-200 border-solid rounded-sm shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'focus-within:shadow-default-hover focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2',
				to ? 'hover:shadow-default-hover' : null,
				grow ? 'h-full' : null,
				className
			)}
		>
			<div className={clsx(grow ? 'grow' : null)}>
				<div className="bg-brand-pale-400 pt-[4rem] pb-5 px-s-f mb-6">
					<p
						className={clsx(
							'absolute px-2 py-1 text-sm text-blue-400 bg-white rounded-md top-4 left-4 font-centra-medium'
						)}
					>
						Human Resources
					</p>
					<p
						className={clsx(
							'absolute px-2 py-1 text-sm top-4 right-4'
						)}
					>
						<MapPin
							aria-hidden="true"
							className="text-blue-400 inline mr-2 w-[13px] items-middle"
						/>
						<span className="inline align-middle">
							<span className="inline align-middle">
								In-person
							</span>{' '}
							<span className="hidden align-middle md:inline">
								event
							</span>
						</span>
					</p>
					<h3 className="pt-2 leading-tight pb-s-f md:pb-5 md:pt-0 text-lg-f font-centra-medium">
						{to ? (
							<Link
								to={buildLink(locale, `${to}/${slug}`)}
								className={clsx(
									linkStyles,
									'from-blue-400 to-blue-400 hover:text-blue-400'
								)}
								onClick={() =>
									onClickProp({
										click_text: eventLocation.venueName,
										click_index: index + 1,
									})
								}
							>
								{eventLocation.venueName}{' '}
								<span className="sr-only">{linkText}</span>
							</Link>
						) : (
							eventLocation.venueName
						)}
					</h3>
					<time
						dateTime={eventDateTime}
						className="flex items-center text-sm text-blue-400 md:text-base-f font-centra-medium"
					>
						<div className="w-[21px] mr-3">
							<Calendar
								aria-hidden="true"
								className="mt-[-3px]"
							/>
						</div>
						<span>
							{eventDate} | {eventTime}
						</span>
					</time>
				</div>

				<div className="px-s-f mb-8">
					{eventLocation.address ? (
						<div className="flex pb-5 mb-5 border-b border-brand-pale-500 items-center">
							<div className="text-blue-400 w-[14px] flex-shrink-0 mr-3">
								<MapPinOutline />
							</div>
							<p className="text-sm md:text-base-f">
								{
									eventLocation.address.value.document
										.children[0].children[0].value
								}
							</p>
						</div>
					) : null}
					{shortDescription ? (
						<p className="text-sm md:text-base-f">
							{shortDescription}
						</p>
					) : null}
				</div>
			</div>
		</article>
	);
};
EventsCard.defaultProps = {
	onClickProp: () => {},
	index: 1,
	grow: true,
};

EventsCard.propTypes = eventsCardProp;

export { EventsCard };
