import React, { useEffect } from 'react';

const JobCardContainerCA = () => {
	// load breeze-hr
	useEffect(() => {
		// Create a script element
		const script = document.createElement('script');
		script.src =
			'https://peninsula-canada.breezy.hr/embed/js?bzsrc=jswidget&include_filters=true&link_external=true&no_pos_msg=true&popup=false';
		script.async = true;

		// Append the script to the document body
		document.body.appendChild(script);

		// Clean up: remove the script from the DOM when component unmounts
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<div
			id="BrzyHr_app"
			className="px-3 mx-auto max-w-m-screen lg:px-8 mt-14 mb-2xl-f md:mb-20"
		/>
	);
};

export { JobCardContainerCA };
