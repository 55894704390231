import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { refProp, titleProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import * as headingStyles from './index.module.css';
import { TrustpilotBusinessReviewHorizontal } from '../../atoms/trustpilot-business-review';
import { PBSLink } from '../../atoms/link';
import { buildLink } from '../../../utils/locale';
import { Button } from '../../atoms/button';
import VideoPlaceholder from '../../../assets/safecheck-placeholder.png';
import ModalWrapper from '../../glu/molecules/modal-wrapper';
import VideoPlayer from '../../glu/molecules/video-player';
import useModal from '../../../hooks/modal';
import { useDrawer } from '../../../contexts/drawer-context';
import { sendTrackingData } from '../../../utils';

function MainHeader({
	id,
	locale,
	drawerRef,
	donwloadDrawerRef,
	header,
	subHeader,
	showTrustpilot,
	glu,
	as,
	safecheckVideo,
	headingClassNames,
	primaryButtonText,
	primaryButtonOpenForm,
	primaryButtonLink,
	secondaryButtonText,
	secondaryButtonOpenForm,
}) {
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const { setDrawerRef } = useDrawer();
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});

	const handleClickTracking = (text = null) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: text || primaryButtonText,
		});
	};

	let cta;
	if (primaryButtonOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					handleClickTracking(primaryButtonText);
				}}
				className="w-max"
			>
				{primaryButtonText}
			</Button>
		);
	} else if (primaryButtonLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryButtonLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryButtonText}
			</PBSLink>
		);
	} else if (primaryButtonLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryButtonLink)}
				onClick={handleClickTracking}
			>
				{primaryButtonText}
			</PBSLink>
		);
	}

	return (
		<section
			role="banner"
			className="px-4 pt-10 pb-6 lg:py-6 mx-auto lg:pr-0 lg:max-w-m-screen"
			aria-labelledby={id}
		>
			<div className="grid md:grid-cols-3 grid-cols-1 ">
				<div
					className={clsx(
						'flex flex-1 col-span-2 flex-col gap-2 justify-center shrink lg:mr-8 lg:pl-8 xl:mr-12',
						headingStyles.headingContainer
					)}
				>
					<Component
						id={id}
						className={clsx(
							'!leading-[1.3] font-castledown-regular',
							headingStyles.heading,
							'text-4xl md:text-4xl xl:text-5xl md:w-8/12 xl:w-10/12',
							headingClassNames
						)}
					>
						{titleText}
					</Component>

					<span
						className={clsx(
							'mt-2 leading-[1.2] block',
							'md:mt-2',
							' xl:leading-[1.3]',
							`text-xl md:text-3xl font-castledown-regular mr-20 `
						)}
					>
						{subHeader}
					</span>
					<div className=" mr-auto mt-6 max-w-m-screen">
						{showTrustpilot ? (
							<TrustpilotBusinessReviewHorizontal />
						) : null}
					</div>
					{primaryButtonText || secondaryButtonText ? (
						<p className="flex flex-col items-center justify-center mt-8 sm:flex-row sm:justify-start">
							{cta || null}
							{secondaryButtonOpenForm && secondaryButtonText ? (
								<Button
									onClick={() => {
										setDrawerRef(donwloadDrawerRef);
										handleClickTracking(
											secondaryButtonText
										);
									}}
									variant="White"
									className="justify-center w-full mt-4 sm:w-max sm:mt-0 sm:ml-4"
								>
									{secondaryButtonText}
								</Button>
							) : null}
						</p>
					) : null}
				</div>
				<div className="hidden md:flex flex-1 flex-col col-span-1  mt-3 w-[97%] font-castledown-regular bg-grey-50">
					<p className="md:text-2xl text-xl text-jsutify md:pt-0 pt-5 md:text-left md:w-[80%] w-full ">
						{safecheckVideo[0].title}
					</p>
					<div className="w-full">
						<div className="pt-5 mt-2 text-center lg:text-left lg:pt-2">
							<button
								type="button"
								onClick={() => setModalOpen(true)}
								tabIndex={0}
								className="relative"
							>
								<img
									src={
										safecheckVideo[0].thumbnail.url ||
										VideoPlaceholder
									}
									className="shadow-2xl"
									alt="Video placeholder"
								/>
							</button>
						</div>
					</div>
					{isModalOpen && (
						<ModalWrapper>
							<div
								className="relative w-7/12 m-auto opacity-100"
								ref={ref}
							>
								{safecheckVideo[0].vimeoLink?.providerUid && (
									<VideoPlayer
										videoEmbed={safecheckVideo[0].vimeoLink}
										autoPlay
										locale={locale}
									/>
								)}
							</div>
						</ModalWrapper>
					)}
				</div>
			</div>
		</section>
	);
}

MainHeader.defaultProps = {
	id: 'main-site-header',
	subHeader: '',
	locale: '',
	showTrustpilot: true,
	headingClassNames: '',
	glu: false,
	as: 'h1',
	safecheckVideo: '',
	primaryButtonText: '',
	primaryButtonOpenForm: '',
	primaryButtonLink: '',
	secondaryButtonText: '',
	secondaryButtonOpenForm: '',
};

MainHeader.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	locale: PropTypes.string,
	subHeader: PropTypes.string,
	showTrustpilot: PropTypes.bool,
	glu: PropTypes.bool,
	headingClassNames: PropTypes.string,
	as: PropTypes.string,
	safecheckVideo: PropTypes.string,
	primaryButtonText: PropTypes.string,
	primaryButtonOpenForm: PropTypes.string,
	primaryButtonLink: PropTypes.string,
	secondaryButtonText: PropTypes.string,
	secondaryButtonOpenForm: PropTypes.string,
	// eslint-disable-next-line react/require-default-props
	drawerRef: refProp,
	// eslint-disable-next-line react/require-default-props
	donwloadDrawerRef: refProp,
};
export { MainHeader };
