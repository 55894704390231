import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const ModalContext = React.createContext(undefined);

const ModalProvider = ({ children, ref }) => {
	const [modalRef, setModalRef] = useState(ref);

	return (
		<ModalContext.Provider
			value={{
				modalRef,
				setModalRef,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

ModalProvider.defaultProps = {
	ref: '',
};

ModalProvider.propTypes = {
	children: PropTypes.node.isRequired,
	ref: PropTypes.string,
};

const useModal = () => {
	const context = useContext(ModalContext);
	if (context === undefined) {
		throw new Error('useModal must be used within a ModalProvider');
	}
	return context;
};

export { ModalProvider, useModal };

export default ModalContext;
