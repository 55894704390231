// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '../../../atoms/button';
import { PBSLink } from '../../../atoms/link';

const CourseTile = ({
	ctaText,
	iconSrc,
	onClick,
	title,
	date,
	introText,
	externalLink,
}) => (
	<div>
		<div className="flex flex-col justify-between py-8 border-b min-h-[120px] lg:py-3 lg:flex-row border-brand-300">
			<div className="flex flex-row items-center">
				<div className="shrink-0">
					<img src={iconSrc} alt="Watch webinar" className="mr-4" />
				</div>
				<div className="mr-3">
					<h3 className="text-xl font-bold">{title}</h3>
					<p className="pt-2 uppercase">{date || introText || ''}</p>
				</div>
			</div>
			{onClick ? (
				<div className="flex items-center pt-5 lg:pt-0 shrink-0">
					<Button
						onClick={onClick}
						className="text-center !text-sm !block w-full"
						size="Small"
					>
						{ctaText}
					</Button>
				</div>
			) : (
				<div className="flex items-center pt-5 lg:pt-0 shrink-0">
					<PBSLink
						to={externalLink}
						className="text-center !text-sm !block w-full"
						size="Small"
						variant="White"
						target="_blank"
					>
						{ctaText}
					</PBSLink>
				</div>
			)}
		</div>
	</div>
);

export default CourseTile;
