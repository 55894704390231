import React from 'react';
import PropTypes from 'prop-types';

const LongIntro = ({ content }) => (
	<div className="px-3 mx-auto max-w-content mb-14 md:px-8 xl:px-0">
		<p className="text-lg md:text-xl">{content}</p>
	</div>
);

export { LongIntro };

LongIntro.propTypes = {
	content: PropTypes.string.isRequired,
};
