import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';

import { CallUs } from '../call-us-cta-bdm';
import { refProp } from '../../../types';

import { useDrawer } from '../../../contexts/drawer-context';
import { Button } from '../button';

const variantsWrapper = {
	Default: 'bg-brand-pale-400 focus-within:outline-blue-400/[0.20]',
	Light: 'bg-white hover:bg-brand-pale-500 focus-within:outline-white/[0.60]',
};

const variantsRingOutter = {
	Default: 'ring-blue-500/[0.08] group-hover:ring-white/[0.20]',
	Light: 'ring-blue-500/[0.08]  group-hover:ring-blue-500/[0.08]',
};

const variantsRingInner = {
	Default: 'ring-blue-500/[0.15] ',
	Light: 'ring-blue-500/[0.15] group-hover:ring-blue-500/[0.15]',
};

const IconCard = ({
	title,
	content,
	linkCopy,
	link,
	icon,
	variant,
	className,
	svgStyles,
	bdmNumber,
	drawerRef,
}) => {
	const { setDrawerRef } = useDrawer();
	let iconSrc;

	if (icon) {
		iconSrc =
			// eslint-disable-next-line import/no-dynamic-require, global-require
			require(`!!file-loader!../../../assets/${icon}.inline.svg`).default;
	}

	const currVariant = variant || 'Default';

	return (
		<article
			className={clsx(
				variantsWrapper[currVariant],
				'group',
				'relative text-center',
				'w-full h-full',
				'px-s-f py-base-f md:px-xs-f rounded-sm',
				'transition-colors duration-200 motion-reduce:transition-none',
				'border-2 border-blue-300',
				'focus-within:outline focus-within:outline-8',
				className
			)}
		>
			{iconSrc ? (
				<div
					aria-hidden
					className={clsx(
						variantsRingOutter[currVariant],
						`inline-flex bg-white md:ring-[16px] ring-[10px] mt-xxs-f md:mt-xs-f rounded-full`
					)}
				>
					<div
						className={clsx(
							variantsRingInner[currVariant],
							`inline-flex bg-white md:ring-[8px] ring-[5px] rounded-full md:p-s-f p-xs-f text-blue-400`
						)}
					>
						<SVG
							src={iconSrc}
							className={clsx(
								'bg-white w-[30px] h-[30px] md:w-[40px] md:h-[40px]',
								svgStyles
							)}
						/>
					</div>
				</div>
			) : null}
			<h3 className="mt-6 text-lg-f md:text-lg-f mb-xs-f md:mt-9">
				{title}
			</h3>
			<p className="text-base-f md:text-base-f font-centra-light">
				{content}
			</p>

			{link ? (
				<div className="border-blue-300 md:pt-s-f md:mt-base-f pt-s-f mt-s-f">
					<Button onClick={() => setDrawerRef(drawerRef)}>
						{linkCopy}
					</Button>
				</div>
			) : (
				<div className="border-blue-300 md:pt-s-f md:mt-base-f pt-s-f mt-s-f">
					<CallUs
						className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
						number={bdmNumber}
						primaryLinkCopy={linkCopy}
					/>
				</div>
			)}
		</article>
	);
};

IconCard.defaultProps = {
	variant: 'Default',
	className: '',
	svgStyles: '',
	linkCopy: '',
	link: '',
	bdmNumber: '',
	drawerRef: '',
};

IconCard.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	linkCopy: PropTypes.string,
	link: PropTypes.string,
	icon: PropTypes.string.isRequired,
	variant: PropTypes.string,
	className: PropTypes.string,
	svgStyles: PropTypes.string,
	bdmNumber: PropTypes.string,
	drawerRef: refProp,
};

export { IconCard };
