import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import { isParagraph, isLink, isRoot } from 'datocms-structured-text-utils';

import { PBSLink } from '../link';
import { Vimeo } from '../vimeo';
import { HeadingTagLine } from '../heading-tag-line';
import { TickList } from '../tick-list';

import { structuredTextContent, titleProp } from '../../../types';

const TextWithVideo = ({ textWithVideoContent, className }) => {
	const { id, title, content, tickList, vimeoVideo } = textWithVideoContent;

	return (
		<div
			className={clsx(
				'md:grid md:grid-cols-[1fr_45%] xl:grid-cols-[1fr_41%] md:gap-10 xl:gap-[100px]',
				className
			)}
		>
			<div className="px-3 md:px-0">
				<HeadingTagLine
					className="!text-left mb-5"
					headingClassNames="!text-3xl lg:!text-4xl"
					id={id}
					title={title}
				/>
				<StructuredText
					data={content}
					customNodeRules={[
						renderNodeRule(isLink, ({ node, children, key }) => (
							<PBSLink to={node.url} variant="Link" key={key}>
								{children}
							</PBSLink>
						)),
						renderNodeRule(
							isParagraph,
							({ children, key, ancestors }) => {
								if (isRoot(ancestors[0])) {
									return (
										<p
											className="mb-4 text-lg font-centra-light md:text-xl last-of-type:mb-0"
											key={key}
										>
											{children}
										</p>
									);
								}
								return children;
							}
						),
					]}
					customMarkRules={[
						renderMarkRule('strong', ({ children, key }) => (
							<span className="font-centra-book" key={key}>
								{children}
							</span>
						)),
					]}
				/>
				{tickList && tickList.length > 0 ? (
					<TickList
						points={tickList}
						className="mt-7"
						listItemClassName="text-lg font-centra-medium md:text-xl"
					/>
				) : null}
			</div>
			<div className="mt-8 md:mt-0">
				<Vimeo responsive video={vimeoVideo.url} />
			</div>
		</div>
	);
};

TextWithVideo.defaultProps = {
	textWithVideoContent: {
		tickList: [],
	},
	className: '',
};

TextWithVideo.propTypes = {
	textWithVideoContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: titleProp.isRequired,
		content: structuredTextContent.isRequired,
		tickList: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				text: PropTypes.string,
				subText: PropTypes.string,
			})
		),
		vimeoVideo: PropTypes.shape({
			url: PropTypes.string,
		}).isRequired,
	}),
	className: PropTypes.string,
};

export { TextWithVideo };
