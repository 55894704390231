import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MegaMenuLink } from '../../atoms/mega-menu-link';

const MegaMenu = forwardRef(
	(
		{ links, className, listClassName, hidden, backdrop, ...navAttributes },
		ref
	) => (
		<div
			className={clsx(
				'absolute left-0 right-0 z-10 top-full w-screen h-[calc(100vh-136px)]',
				className
			)}
			hidden={hidden}
		>
			{backdrop || null}
			<nav
				ref={ref}
				className={clsx(
					'w-screen bg-white block absolute left-0 pb-10 z-10'
				)}
				{...navAttributes}
			>
				<ul
					className={clsx(
						'grid grid-cols-3 gap-[15px] mx-auto max-w-m-screen',
						listClassName
					)}
				>
					{links.map((link) => (
						<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
							<MegaMenuLink
								to={link.link}
								title={link.title}
								text={link.subText}
								icon={link.icon}
								parentTrackingId={navAttributes[
									'aria-label'
								]?.replace(' menu', '')}
							/>
						</li>
					))}
				</ul>
			</nav>
		</div>
	)
);

export { MegaMenu };

MegaMenu.defaultProps = {
	className: '',
	listClassName: '',
	hidden: true,
	backdrop: '',
};

MegaMenu.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subText: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
	listClassName: PropTypes.string,
	hidden: PropTypes.bool,
	backdrop: PropTypes.node,
};
