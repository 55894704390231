import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Logo from '../../../assets/trustpilot-logo.inline.svg';
import Star from '../../../assets/trustpilot-star.inline.svg';

import { PBSLink } from '../link';

import { getTrustpilotStarLabel } from '../../../utils';

const TrustpilotBusinessReview = () => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsTrustpilotRating {
				id
				trustscore {
					id
					originalId
					stars
					trustscore
				}
				numberOfReview {
					usedForTrustScoreCalculation
					total
					fourStars
					fiveStars
				}
			}
		}
	`);
	return (
		<div className="flex flex-col justify-center max-w-[235px] text-center shrink-0 w-full">
			<h2 className="mb-2 text-lg font-centra-medium">
				{getTrustpilotStarLabel(
					data.datoCmsTrustpilotRating.trustscore[0].stars
				)}
			</h2>
			<div
				className="grid grid-cols-[repeat(auto-fit,_40px)] gap-2 justify-center mb-3"
				aria-label={`${data.datoCmsTrustpilotRating.trustscore[0].stars} Trustpilot stars`}
				role="img"
			>
				{[
					...Array(data.datoCmsTrustpilotRating.trustscore[0].stars),
				].map((_, i) => (
					<Star
						// eslint-disable-next-line react/no-array-index-key
						key={`business-unit-star-${i}`}
						aria-hidden="true"
						width={40}
						height={40}
					/>
				))}
			</div>
			<p className="mb-2 font-centra-book">
				Based on{' '}
				<PBSLink
					variant="Link"
					className="md:text-base text-brand-blue-400"
					to="https://uk.trustpilot.com/review/peninsulagrouplimited.com"
				>
					{
						data.datoCmsTrustpilotRating.numberOfReview[0]
							.usedForTrustScoreCalculation
					}{' '}
					reviews
				</PBSLink>
			</p>
			<a href="https://uk.trustpilot.com/" aria-label="Trustpilot UK">
				<Logo width={100} className="mx-auto" />
			</a>
		</div>
	);
};

const TrustpilotBusinessReviewHorizontalPPCUK = ({
	className,
	textClassName,
}) => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsTrustpilotRating {
				id
				trustscore {
					id
					originalId
					stars
					trustscore
				}
				numberOfReview {
					usedForTrustScoreCalculation
					total
					fourStars
					fiveStars
				}
			}
		}
	`);
	// Get the percentage of good and excellent reviews
	const goodExcellent =
		data.datoCmsTrustpilotRating.numberOfReview[0].fiveStars +
		data.datoCmsTrustpilotRating.numberOfReview[0].fourStars;
	const excellentPercentage =
		(goodExcellent / data.datoCmsTrustpilotRating.numberOfReview[0].total) *
		100;

	return (
		<div
			className={clsx(
				'flex',
				'flex-wrap',
				'items-center',
				'w-full',
				'mt-8',
				className
			)}
		>
			<div aria-label="Trustpilot UK." className="mr-3">
				<Logo width={100} className="mx-auto" />
			</div>
			<p
				className={clsx(
					'w-full mb-2 text-sm md:mb-0 md:mr-3 md:text-base font-centra-book max-md:-order-1 md:w-auto',
					textClassName
				)}
			>
				Over {excellentPercentage.toFixed(0)}% of people rated us{' '}
				<span className="font-centra-medium">Excellent</span> or{' '}
				<span className="font-centra-medium">Good</span>
			</p>
			<div
				className="flex items-center space-x-1"
				aria-label={`${data.datoCmsTrustpilotRating.trustscore[0].stars} Trustpilot stars`}
				role="img"
			>
				{[
					...Array(data.datoCmsTrustpilotRating.trustscore[0].stars),
				].map((_, i) => (
					<Star
						// eslint-disable-next-line react/no-array-index-key
						key={`business-unit-star-${i}`}
						aria-hidden="true"
						width={20}
						height={20}
					/>
				))}
			</div>
		</div>
	);
};

TrustpilotBusinessReviewHorizontalPPCUK.defaultProps = {
	className: '',
	textClassName: '',
};

TrustpilotBusinessReviewHorizontalPPCUK.propTypes = {
	className: PropTypes.string,
	textClassName: PropTypes.string,
};

export { TrustpilotBusinessReview, TrustpilotBusinessReviewHorizontalPPCUK };
