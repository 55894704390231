// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const GreetingHeader = ({ headingImg }) => (
	<div className="px-4 mx-auto lg:-mt-32 max-w-m-screen">
		<div className="text-center lg:text-right">
			<GatsbyImage
				image={headingImg.gatsbyImageData}
				alt={headingImg.alt || ''}
			/>
		</div>
	</div>
);

export default GreetingHeader;
