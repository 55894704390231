import { useLocation } from '@reach/router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { sendTrackingData } from '../../../utils';
import { getLocale } from '../../../utils/locale';
import { annualRoi as annualRoiIE } from '../../molecules/vap-calculator-ie/dataIE';
import { annualRoi } from '../../molecules/vap-calculator-uk/dataUK';
import { Button } from '../button';
import { PBSLink } from '../link';
import { InputVariants } from '../text-input';

function VapCalculatorBlock({
	headerTitle,
	subHeader,
	ctaText,
	// ctaSuccessText,
	ctaSuccessLink,
}) {
	const elName = 'employeeCount';
	const [submitted, setSubmitted] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const location = useLocation();
	const locale = getLocale(location.pathname);

	const getCurrency = (count = null) => {
		let currency = '£';

		switch (locale) {
			case 'ie':
				currency = '€';
				break;
			case 'ca':
				currency = '$';
				break;
			default:
				currency = '£';
				break;
		}

		if (count > 249) {
			return '';
		}
		return currency;
	};
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-calculator',
			click_type: 'primary',
			click_action: 'change state',
			click_title: headerTitle,
			click_text: ctaText,
		});
	};
	const handleOnChange = (e) => {
		setInputValue(e.target.value);
	};
	const [priceCalculated, setPriceCalculated] = React.useState({
		annual_roi: '-',
	});
	const submitHandler = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const employeeCount = formData.get('employeeCount');
		if (!employeeCount) {
			return;
		}

		setPriceCalculated({
			annual_roi: `${getCurrency(employeeCount)}${
				locale === 'ie'
					? annualRoiIE(employeeCount)
					: annualRoi(employeeCount)
			}`,
		});
		handleClickTracking();
		setSubmitted(true);
	};
	return (
		<div
			className={clsx(
				'!font-centra-book !bg-brand-pale-400 border-1 border-blue-200 !text-blue-500 !leading-snug rounded-sm lg:p-10 p-5 flex ',
				submitted
					? 'items-start justify-start translate-x-0 transition-all duration-700 animate-shake'
					: 'items-center justify-center'
			)}
		>
			{!submitted ? (
				<div className="flex flex-col items-center justify-center lg:gap-6 gap-4">
					<p className="lg:text-5xl text-3xl text-center font-castledown-regular">
						{headerTitle}
					</p>
					<p className="lg:text-3xl text-xl text-center font-centra-light text-grey-400">
						{subHeader}
					</p>
					<form onSubmit={submitHandler} className="flex">
						<div className="flex lg:flex-row flex-col items-center xs:items-stretch justify-center">
							<input
								className={clsx(
									InputVariants.Default,
									'h-auto mb-0 appearance-none transition-colors duration-200',
									'xs:mr-5',
									'hover:bg-brand-pale-400',
									'focus:border-blue-400 focus:placeholder:text-brand-blue-400',
									'motion-reduce:transition-none',
									inputValue.length > 0
										? 'border-brand-blue-400 bg-brand-pale-300'
										: ''
								)}
								min={1}
								type="number"
								id={elName}
								name={elName}
								required
								aria-required="true"
								onChange={handleOnChange}
								value={inputValue}
								placeholder="e.g. 22"
							/>
							<div className="flex items-center justify-center">
								<Button
									type="submit"
									className="!font-centra-book -mt-[5px] md:-mt-[7px] h-fit text-xs !px-16"
								>
									<p className="text-lg">{ctaText} </p>
								</Button>
							</div>
						</div>
					</form>
				</div>
			) : (
				<div className="flex flex-col items-start justify-start gap-6 w-full">
					<div className="flex items-center justify-between w-full">
						<p className="lg:text-5xl text-2xl font-castledown-regular text-black">
							Your Return on Investment with Peninsula
						</p>
						<p className="lg:text-5xl text-3xl text-blue-400 font-castledown-regular">
							{priceCalculated.annual_roi}
						</p>
					</div>
					<div className="flex items-center justify-between w-full">
						<p className="lg:text-2xl text-base font-centra-light text-grey-400">
							Explore the value you’d enjoy for every £1 spent
							with Peninsula:
						</p>
						<p className="lg:text-3xl text-xl text-blue-400 font-castledown-regular">
							for every £1 spent
						</p>
					</div>
					<div className="flex w-full items-center justify-center gap-6">
						<PBSLink
							to={ctaSuccessLink}
							variant="Default"
							className="!font-centra-book -mt-[5px] md:-mt-[7px] h-fit text-xs lg:!px-20 "
						>
							<p className="text-lg-f"> Show me</p>
						</PBSLink>
					</div>
				</div>
			)}
		</div>
	);
}

VapCalculatorBlock.defaultProps = {
	headerTitle: '',
	subHeader: '',
	ctaText: '',
	// ctaSuccessText: '',
	ctaSuccessLink: '',
};

VapCalculatorBlock.propTypes = {
	headerTitle: PropTypes.string,
	subHeader: PropTypes.string,
	ctaText: PropTypes.string,
	// ctaSuccessText: PropTypes.string,
	ctaSuccessLink: PropTypes.string,
};
export default VapCalculatorBlock;
