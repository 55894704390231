import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { getLocale } from '../../../utils/locale';
import { useFormValidation } from '../../../hooks/form-validation';
import { useSalesforceApi } from '../../../hooks/salesforce';
import { useSalesforceAzureApi } from '../../../hooks/salesforce-azure-ca';
import {
	getCookie,
	pageUrlFomater,
	sendTrackingData,
	sleep,
} from '../../../utils';
import { TextInput } from '../../atoms/text-input';
import { RadioButton } from '../../atoms/radio-button';
import { TimesRange } from '../../molecules/times-range';
import { Button } from '../../atoms/button';
import { SidebarHeader } from '../../atoms/sidebar-header';
import { CallUs } from '../../atoms/call-us-ppc';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	company: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};
function AdviceFormPPC({ setReference, setResponse, customFormField }) {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [chooseTime, setChooseTime] = useState(false);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [handshake, setHandshake] = useState(false);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();

	let isSelectedTimes = false;
	const sideBarHeaderText =
		customFormField?.customFormTitle || 'Schedule a FREE advice call';
	const submitButtonText =
		customFormField?.customFormButton || sideBarHeaderText;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		const todaysDate = new Date().toISOString().split('T')[0];

		if (!isValid) {
			setHandshake(true);
			return;
		}

		setSubmitting(true);

		isSelectedTimes = formEntries.callFrom && formEntries.callTo;
		const selectedDate = isSelectedTimes ? formEntries.callDay : todaysDate;
		const selectedTime = isSelectedTimes
			? `${formEntries.callFrom}-${formEntries.callTo}`
			: 'asap';

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.company,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			selectedDate,
			selectedTime,
			leadSource: 'Website - Become a Client',
			locale,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
		};

		if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ca') {
			payloadData.leadSource = 'Website';
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (locale === 'ca' && response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse(
					`Brilliant, one of our advisors will call you ${
						isSelectedTimes
							? `between the hours of ${
									formEntries.callFrom
							  } and ${formEntries.callTo} ${
									todaysDate === formEntries.callDay
										? 'today'
										: 'tomorrow'
							  }.`
							: 'as soon as possible.'
					}`
				);
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}
		if (!validations.company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}
		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	React.useEffect(() => {
		let timer;
		if (handshake) {
			timer = setTimeout(() => {
				setHandshake(false);
			}, 500);
		}
		return () => clearTimeout(timer);
	}, [handshake]);
	return isError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<form
			id="inline-form-ppc"
			onSubmit={submitHandler}
			className={clsx(
				'flex flex-col justify-between w-full mx-auto overflow-hidden border rounded-sm shadow-lg lg:-mt-10 grow border-brand-pale-500',
				handshake && 'animate-shake'
			)}
		>
			<div className="flex flex-col grow bg-white">
				<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-6 lg:px-8 grow">
					<legend className="sr-only">
						Enter your personal details
					</legend>
					<div className="flex flex-col lg:flex-row lg:gap-6">
						<TextInput
							labelText="First name"
							id="callbackFirstName"
							name="firstName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your first name"
							showValidation={!validations.firstName.isValid}
							ref={firstNameRef}
						/>

						<TextInput
							labelText="Last name"
							id="callbackLastName"
							name="lastName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your last name"
							showValidation={!validations.lastName.isValid}
							ref={lastNameRef}
						/>
					</div>
					<div className="flex flex-col lg:flex-row lg:gap-6">
						<TextInput
							labelText="Company"
							id="callbackCompany"
							name="company"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your company name"
							showValidation={!validations.company.isValid}
							ref={companyRef}
						/>

						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>
					</div>
					<div className="flex flex-col lg:flex-row lg:gap-6">
						<TextInput
							labelText="Best number to call you on"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>
					</div>
					<div className="flex flex-col lg:flex-row lg:gap-6">
						<div className="w-full wrapper-small">
							<fieldset
								className={clsx(chooseTime ? 'mb-4' : null)}
								disabled={submitting}
							>
								<legend className="mb-1 text-brand-blue-400 text-base-f font-centra-medium">
									When shall we call you?
								</legend>

								<p className="mb-4 text-s-f">
									Callbacks can be as quick as within 8
									minutes
								</p>

								<div className="flex flex-wrap gap-2">
									<RadioButton
										defaultChecked
										labelText="As soon as possible"
										id="callAsap"
										value="callAsap"
										name="callWhen"
										onChange={() => setChooseTime(false)}
									/>
									<RadioButton
										labelText="Choose a time"
										id="chooseTime"
										value="chooseTime"
										name="callWhen"
										onChange={() => setChooseTime(true)}
									/>
								</div>
							</fieldset>
						</div>
					</div>
					<div className="flex flex-col lg:flex-row lg:gap-6 w-full">
						<div
							className="px-4 py-10 bg-brand-pale-300 w-full"
							hidden={!chooseTime}
						>
							<TimesRange
								callFromIsValid={
									chooseTime &&
									validations.callFrom &&
									validations.callFrom.isValid
								}
								callToIsValid={
									chooseTime &&
									validations.callTo &&
									validations.callTo.isValid
								}
								isDisabled={!chooseTime}
							/>
						</div>
					</div>
				</fieldset>
			</div>

			<div className="flex flex-col items-center px-8 pt-3 pb-4 bg-white lg:pb-2 lg:flex-row lg:justify-between">
				<div className="px-0 py-5 bg-white">
					<div className="wrapper-small">
						<Button
							id="advice-form-ppc"
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</div>
		</form>
	);
}
AdviceFormPPC.defaultProps = {
	customFormField: '',
};

AdviceFormPPC.propTypes = {
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
	customFormField: PropTypes.node,
};
export default AdviceFormPPC;
