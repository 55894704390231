import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import QuotationMark from '../../../assets/quotation-mark.inline.svg';
import * as reviewStyles from './index.module.css';

const ReviewBanner = ({
	reviewBannerContent,
	className,
	quoteClassName,
	titleClassName,
	inContent,
}) => {
	const { title, subText, reviewer } = reviewBannerContent;

	return (
		<figure
			className={clsx(
				'px-4 py-12',
				'text-center text-white font-castledown-regular',
				'bg-blue-500 rounded-sm max-w-m-screen',
				'md:px-8',
				inContent
					? `${reviewStyles.reviewInContent} mt-8`
					: `${reviewStyles.review} mx-3 my-xl-f lg:my-2xl-f`,
				className
			)}
		>
			<div className="max-w-[920px] mx-auto">
				<QuotationMark
					className={clsx(
						'w-8 h-auto pb-4 mx-auto md:pb-5',
						quoteClassName
					)}
					aria-hidden="true"
				/>
				<blockquote>
					<p
						className={clsx(
							'mb-5 text-3xl lg:text-4xl',
							titleClassName
						)}
					>
						{title}
					</p>
					<p
						className={clsx(
							'text-lg-f mb-7',
							reviewStyles.quotationEnd
						)}
					>
						{subText}
					</p>
				</blockquote>
				<figcaption className="text-base-f">- {reviewer}</figcaption>
			</div>
		</figure>
	);
};

ReviewBanner.defaultProps = {
	className: '',
	quoteClassName: '',
	titleClassName: '',
	inContent: false,
};

ReviewBanner.propTypes = {
	className: PropTypes.string,
	titleClassName: PropTypes.string,
	quoteClassName: PropTypes.string,
	reviewBannerContent: PropTypes.shape({
		title: PropTypes.string.isRequired,
		subText: PropTypes.string.isRequired,
		reviewer: PropTypes.string.isRequired,
	}).isRequired,
	inContent: PropTypes.bool,
};

export { ReviewBanner };
