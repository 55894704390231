import { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { mapToGatsbyFormat } from '../../utils';

const token = process.env.GATSBY_DATOAPIKEY;
const useDraft = process.env.GATSBY_DATO_DRAFT;

const fetcher = (query) =>
	// eslint-disable-next-line compat/compat
	fetch(`https://graphql.datocms.com/${useDraft ? 'preview/' : ''}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify({
			query,
		}),
	}).then((res) => res.json());

export const useFilters = ({
	queryName,
	secondQueryName,
	search,
	query,
	data: initialData,
	itemLimit,
	customFilter,
	searchRef,
}) => {
	const params =
		typeof window !== `undefined`
			? // eslint-disable-next-line compat/compat
			  new URLSearchParams(window.location.search)
			: ``;
	const pageParam = params.get ? params.get('p') : 0;

	const [items, setItems] = useState(initialData?.nodes || []);
	const [filter, setFilter] = useState(customFilter || 'created_DESC');
	const [searchQuery, setSearchQuery] = useState(searchRef || '');
	const [pagination, setPagination] = useState({
		currentPage: pageParam <= 0 ? 0 : Number(pageParam),
		totalPages: Math.ceil(initialData?.totalCount / itemLimit),
	});

	const currentQuery = query({
		queryName,
		secondQueryName,
		filter,
		skip: Number(pageParam) > 0 ? pagination.currentPage * itemLimit : 0,
		search,
	});
	const key = JSON.stringify(currentQuery);
	const {
		data: resData,
		error,
		isLoading,
	} = useSWR(key, () => fetcher(currentQuery), {
		shouldRetryOnError: true,
		errorRetryInterval: 1000,
		errorRetryCount: 2,
		revalidateOnMount: true,
		revalidateOnFocus: false,
		revalidateIfStale: false,
	});
	useEffect(() => {
		if (!resData?.data) return;
		const newDataArray = secondQueryName
			? [...resData.data[queryName], ...resData.data[secondQueryName]]
			: resData.data[queryName] || [];
		if (newDataArray.length) {
			const sortNodes = newDataArray.map(mapToGatsbyFormat);
			setItems(sortNodes.slice(0, itemLimit));
		} else {
			setItems([]);
		}
	}, [itemLimit, queryName, resData?.data, secondQueryName]);
	const totalSearchCount = useMemo(
		() => resData?.data?.[`_${queryName}Meta`]?.count || 0,
		[resData, queryName]
	);

	useEffect(() => {
		if (searchRef && searchQuery !== searchRef?.current?.value) {
			setSearchQuery(searchRef?.current?.value);
			setPagination({
				currentPage: 0,
				totalPages: Math.ceil(totalSearchCount / itemLimit),
			});
		} else {
			setPagination({
				currentPage: pageParam <= 0 ? 0 : Number(pageParam - 1),
				totalPages: Math.ceil(totalSearchCount / itemLimit),
			});
		}
	}, [searchRef, searchQuery, totalSearchCount, itemLimit, pageParam]);

	useEffect(() => {
		setPagination({
			...pagination,
			currentPage: pageParam <= 0 ? 0 : Number(pageParam - 1),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	return {
		loading: isLoading,
		error,
		pagination,
		filter,
		items,
		setPagination,
		setFilter,
		totalSearchCount,
	};
};
