import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { sendTrackingData } from '../../../utils/index';

import { PBSLink } from '../link';
import { buildLink, getLocale } from '../../../utils/locale';

const IconCardHrSoftware = ({
	hasLinks,
	title,
	content,
	linkCopy,
	link,
	icon,
	index,
	svgStyles,
}) => {
	let iconSrc;

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	if (icon) {
		iconSrc =
			// eslint-disable-next-line import/no-dynamic-require, global-require
			require(`!!file-loader!../../../assets/${icon}.inline.svg`).default;
	}

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('component_interaction', {
			component_name: 'icon-card',
			click_type: 'link',
			click_title: title,
			click_text: linkCopy,
			click_index: index + 1,
		});
	};

	return (
		<article className="p-6 bg-white rounded-[10px] shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col">
			<div aria-hidden className="flex items-center mb-4">
				<div className="text-blue-400 text-2xl mr-4">
					<SVG
						src={iconSrc}
						className={clsx(
							'bg-white w-[30px] h-[30px] md:w-[40px] md:h-[40px]',
							svgStyles
						)}
					/>
				</div>
				<h3 className="text-2xl font-semibold text-blue-600">
					{title}
				</h3>
			</div>

			<p className="text-gray-700 text-xl mb-4 flex-grow">{content}</p>

			{hasLinks && link && linkCopy ? (
				<div className="border-blue-200 pt-4 mt-auto">
					<PBSLink
						to={buildLink(locale, link)}
						variant="Link"
						onClick={handleClickTracking}
						className="text-blue-200 hover:underline"
					>
						{linkCopy}
					</PBSLink>
				</div>
			) : null}
		</article>
	);
};

IconCardHrSoftware.defaultProps = {
	hasLinks: true,
	svgStyles: '',
	linkCopy: '',
	link: '',
	index: 1,
};

IconCardHrSoftware.propTypes = {
	hasLinks: PropTypes.bool,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	linkCopy: PropTypes.string,
	link: PropTypes.string,
	icon: PropTypes.string.isRequired,
	svgStyles: PropTypes.string,
	index: PropTypes.number,
};

export { IconCardHrSoftware };
