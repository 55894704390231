// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { PBSLink } from '../../../atoms/link';
import ClockIcon from '../../../../assets/clock.inline.svg';
import CalendarIcon from '../../../../assets/calendar.inline.svg';
import { buildLink } from '../../../../utils/locale';

const SessionCard = ({ card, pbsWebinar, locale }) => {
	const date = format(new Date(card.dateAndTime), 'MMM d, yyyy');
	const time = format(new Date(card.dateAndTime), 'h:mm');

	return (
		<div
			className="flex flex-col justify-between w-full p-5 font-bold rounded-sm lg:w-1/3 bg-brand-pale-400"
			key={card.title}
		>
			<div>
				<h4 className="mb-4 text-2xl min-h-[75px]">{card.title}</h4>
				<p className="mb-4 text-lg">
					<CalendarIcon
						className="w-[22px] text-blue-400 inline-block mr-4"
						aria-hidden
					/>
					{date}
				</p>
				<p className="mb-4 text-xl">
					<ClockIcon
						className="w-[22px] text-blue-400 inline-block mr-4"
						aria-hidden
					/>
					{time}
				</p>
			</div>
			<div>
				<PBSLink
					to={buildLink(
						locale,
						`${pbsWebinar ? '/webinars/' : '/elearning/webinar/'}${
							card.slug
						}`
					)}
					className="text-center !text-sm"
					size="Small"
					target="_blank"
				>
					Register your place
				</PBSLink>
			</div>
		</div>
	);
};

export default SessionCard;
