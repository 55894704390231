import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Title = ({
	title,
	subtitle,
	children,
	className,
	id = title.replace(/[^A-Z0-9]+/gi, '-'),
}) => (
	<section
		className={clsx('md:text-center text-left px-3', className)}
		aria-labelledby={id}
	>
		<div className="flex flex-col justify-center lg:mx-auto">
			<h1 className="pb-6 text-2xl-f" id={id}>
				{title}
			</h1>
			{subtitle ? (
				<p className="self-center max-w-5xl mb-10 text-xl leading-snug md:text-lg-f font-centra-light lg:mb-16 ">
					{subtitle}
				</p>
			) : null}
			{children}
		</div>
	</section>
);

export { Title };

Title.defaultProps = {
	id: 'title-component',
	className: '',
	subtitle: '',
	children: '',
};

Title.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
};
