import React, { useState, useEffect } from 'react';
import { Button } from '../../../atoms/button';

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	const toggleVisibility = () => {
		if (window.scrollY > 1500) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className="fixed mt-10 bottom-4 right-4 max-mdx:px-[2%] lg:px-[1.7%] 2xl:px-[1.2%] max-mdx:py-[15%]  2xl:py-[10%] lg:py-[14%] px-[2%] ">
			{isVisible && (
				<Button
					onClick={scrollToTop}
					className="!bottom-5 !w-14 !h-14 !flex  !items-center !justify-center !right-5 !text-white  hover:!bg-brand-blue-400 !border-none !bg-blue-400 !p-2 !rounded-full !shadow-lg "
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-20 w-20"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						{' '}
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M5 15l7-7 7 7"
						/>{' '}
					</svg>
				</Button>
			)}
		</div>
	);
};

export default ScrollToTop;
