import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from 'gatsby-link';
import { useTabs } from '../../../contexts/tabs-context';

const getPosition = (eventCode, index, total) => {
	if (eventCode === 'ArrowRight') {
		if (index === total - 1) return index;

		return index + 1;
	}

	if (eventCode === 'ArrowLeft') {
		if (index === 0) return index;

		return index - 1;
	}

	return index;
};

const TabItem = ({ item, itemIndex, tabId, tabPanelId, onClickProp }) => {
	const tabItemRef = useRef(null);
	const { activeIndex, activeTabPanel, numberOfTabs, setActiveIndex } =
		useTabs();
	const isActive = activeIndex === itemIndex;

	const handleClick = (event) => {
		event.preventDefault();

		setActiveIndex(itemIndex);
	};

	const handlekeyDown = (event) => {
		if (event.code === 'ArrowDown') return activeTabPanel.focus();

		const nextItemIndex = getPosition(event.code, itemIndex, numberOfTabs);

		setActiveIndex(nextItemIndex);
	};

	useEffect(() => {
		if (tabItemRef.current && isActive) {
			tabItemRef.current.focus();
		}
	}, [isActive]);

	return (
		<li className="flex-grow" role="presentation">
			<Link
				ref={tabItemRef}
				to={`#${tabPanelId}`}
				id={tabId}
				role="tab"
				tabIndex={!isActive ? '-1' : null}
				aria-selected={isActive}
				onClick={(event) => {
					handleClick(event);
					onClickProp({
						click_title: item.tabTitle,
						click_text: item.tabSubTitle,
						click_index: itemIndex + 1,
					});
				}}
				onKeyDown={handlekeyDown}
				className={clsx(
					'w-full flex items-center justify-center md:flex-col',
					'relative py-2 text-sm border-1 border-blue-300',
					'transition-colors motion-reduce:transition-none',
					'outline-none focus:shadow-focus',
					itemIndex > 0
						? 'border-t-0 md:border-l-0 md:border-t-1'
						: '',
					itemIndex === 0
						? 'rounded-t-sm md:rounded-l-lg md:rounded-tr-none'
						: '',
					itemIndex === numberOfTabs - 1
						? 'rounded-b-sm md:rounded-r-lg md:rounded-bl-none'
						: '',
					isActive
						? 'bg-brand-blue-400 text-white z-[5]'
						: 'bg-brand-pale-300 text-brand-blue-400 hover:bg-brand-pale-500'
				)}
			>
				<span className="flex flex-row items-center text-xl font-centra-medium">
					{item.tabIcon && item.tabIcon}
					{item.tabTitle}
				</span>
				<small className="block ml-4 text-sm md:ml-0">
					{item.tabSubTitle}
				</small>
			</Link>
		</li>
	);
};

export { TabItem };

TabItem.defaultProps = {
	onClickProp: () => {},
};

TabItem.propTypes = {
	item: PropTypes.shape({
		tabTitle: PropTypes.string.isRequired,
		tabSubTitle: PropTypes.string,
		tabIcon: PropTypes.node,
	}).isRequired,
	itemIndex: PropTypes.number.isRequired,
	tabId: PropTypes.string.isRequired,
	tabPanelId: PropTypes.string.isRequired,
	onClickProp: PropTypes.func,
};
