import React from 'react';
import PropTypes from 'prop-types';

const Backdrop = ({ onClick, children }) => (
	<>
		<div
			aria-hidden="true"
			className="absolute top-0 bottom-0 left-0 right-0 z-0 backdrop-blur"
			onClick={onClick}
		>
			{children}
		</div>
	</>
);

Backdrop.defaultProps = {
	children: '',
	onClick: () => {},
};

Backdrop.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
};

export { Backdrop };
