import React from 'react';
import PropTypes from 'prop-types';

import { AccordionCA } from '../../atoms/accordion-ca';
import { AccordionGroup } from '../../molecules/accordion-group';
import { getRteContent, sendTrackingData } from '../../../utils';

import { rteContent } from '../../../types';

const FaqCA = ({ id, faqContent }) => {
	const { title, subText, questions } = faqContent;

	const handleClickTracking = ({ question, index }) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'faq',
			click_type: 'link',
			click_title: question,
			click_index: index + 1,
		});
	};

	const accordions = () =>
		questions.map(({ question, answer }, index) => {
			const qId = question.replace(/[^A-Z0-9]/gi, '');
			return (
				<AccordionCA
					key={question}
					title={question}
					buttonId={`accordionHead-${qId}`}
					contentId={`accordionContent-${qId}`}
					variant="Slim"
					onClickProp={() =>
						handleClickTracking({
							question,
							index,
						})
					}
				>
					{getRteContent(answer, qId)}
				</AccordionCA>
			);
		});

	return (
		<section aria-labelledby={id}>
			<div className="max-w-[850px] mx-auto my-xl-f px-xs-f md:my-3xl-f-">
				<h2 className="text-center pb-xs-f text-2xl-f" id={id}>
					{title}
				</h2>
				<p className="text-xl text-center md:text-2xl font-centra-light pb-lg-f">
					{subText}
				</p>
				<AccordionGroup accordions={accordions()} />
			</div>
		</section>
	);
};

FaqCA.defaultProps = {
	id: 'faq',
};

FaqCA.propTypes = {
	id: PropTypes.string,
	faqContent: PropTypes.shape({
		title: PropTypes.string,
		subText: PropTypes.string,
		questions: PropTypes.arrayOf(
			PropTypes.shape({
				question: PropTypes.string.isRequired,
				answer: rteContent.isRequired,
			})
		).isRequired,
	}).isRequired,
};

export { FaqCA };
