/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { datoGatsbyImageProp, refProp } from '../../../types';

import { PBSLink } from '../../atoms/link';
import { CallCtas } from '../contact-ctas';

import { sendTrackingData } from '../../../utils';

const ImageContent = ({ imageContent, drawerRef, siteSettings }) => {
	const {
		id,
		title,
		content,
		as,
		image,
		link,
		linkCopy,
		showCtas,
		imageLeftAligned,
	} = imageContent;
	const Heading = as || 'h2';
	const Alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(image);

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'image-content',
			click_type: 'secondary',
			click_title: title,
			...trackData,
		});
	};

	return (
		<section className="mx-auto lg:container" aria-labelledby={id}>
			<div
				className={clsx(
					'py-10',
					'md:p-10',
					imageLeftAligned ? 'md:pl-0' : 'md:pr-0',
					'bg-brand-pale-400'
				)}
			>
				<div className="flex flex-col gap-4 md:gap-8 md:flex-row">
					<div
						className={clsx(
							imageLeftAligned ? '' : 'order-1',
							'border-8',
							'border-white',
							imageLeftAligned
								? 'md:border-l-0'
								: 'md:border-r-0',
							imageLeftAligned
								? 'lg:border-l-8'
								: 'lg:border-r-8',
							imageLeftAligned ? 'lg:-ml-8' : 'lg:-mr-8',
							'shrink-0',
							'drop-shadow-image',
							'md:max-w-[40%]',
							'self-start',
							'justify-self-center',
							'md:justify-self-start',
							'max-md:mx-3'
						)}
					>
						<GatsbyImage image={imageData} alt={Alt} />
					</div>
					<div className="p-3">
						<Heading
							id={id}
							className="mt-2 text-3xl lg:text-5xl font-castledown-regular"
						>
							{title}
						</Heading>
						{content ? (
							<p className="mt-6 text-xl leading-9 lg:mt-8 lg:text-2xl">
								{content}
							</p>
						) : null}
						{link && linkCopy ? (
							<p className="mt-10 text-2xl leading-9 text-center md:text-left">
								<PBSLink to={link}>{linkCopy}</PBSLink>
							</p>
						) : null}
						{showCtas ? (
							<>
								<hr className="mt-10 border-blue-200" />
								<div className="mt-10">
									<CallCtas
										drawerRef={drawerRef}
										onClickProp={handleClickTracking}
										siteSettings={siteSettings}
									/>
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</section>
	);
};

ImageContent.defaultProps = {
	imageContent: {
		as: 'h2',
	},
};

ImageContent.propTypes = {
	imageContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		content: PropTypes.string.isRequired,
		image: datoGatsbyImageProp.isRequired,
		link: PropTypes.string.isRequired,
		linkCopy: PropTypes.string.isRequired,
		showCtas: PropTypes.bool.isRequired,
		imageLeftAligned: PropTypes.bool.isRequired,
	}),
	drawerRef: refProp.isRequired,
};

export { ImageContent };
