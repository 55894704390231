// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/bdm';
import { Head } from '../components/templates/head';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice-bdm';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { IconCardsBDM } from '../components/organisms/icon-cards-bdm';
import { MainHeader } from '../components/molecules/main-header-bdm';
import { Testimonials } from '../components/organisms/testimonials-bdm';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-bdm';
import { IconCards } from '../components/organisms/icon-cards';
import { BdmBio } from '../components/molecules/bdm-bio';
import { pageTypes } from '../utils/url-helpers';
import { Drawer } from '../components/atoms/drawer';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';

// markup
const BdmPage = ({ data, location }) => {
	// console.log(data);
	// return false;
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const bdmName = data.datoCmsBdmPage.title;
	const { bdmNumber } = data.datoCmsBdmPage;

	const metaContent = {
		noIndex: data.datoCmsBdmPage.noIndex,
		noFollow: data.datoCmsBdmPage.noFollow,
		metaInformation: data.datoCmsBdmPage.metaInformation,
		fallbackTitle: data.datoCmsBdmPage.title,
		fallbackDescription: data.datoCmsBdmPage.longIntroduction,
		canonicalUrl: data.datoCmsBdmPage?.canonicalUrl || location.href,
	};

	const textWithImageContainer = {
		...data.datoCmsBdmPage.textWithImageContainer,
		header: data.datoCmsBdmPage.textWithImageContainer.header.value.document
			.children,
		firsttitle:
			data.datoCmsBdmPage.textWithImageContainer?.firstTitle.value
				.document.children,
		secondtitle:
			data.datoCmsBdmPage.textWithImageContainer?.secondTitle.value
				.document.children,
		thirdtitle:
			data.datoCmsBdmPage.textWithImageContainer?.thirdTitle.value
				.document.children,
		firstContent: data.datoCmsBdmPage.textWithImageContainer.firstContent,
		secondContent: data.datoCmsBdmPage.textWithImageContainer.secondContent,
		thirdContent: data.datoCmsBdmPage.textWithImageContainer.thirdContent,
	};

	const headerCTAContent = {
		...data.datoCmsBdmPage.mainHeader,
		header: data.datoCmsBdmPage.mainHeader.header.value.document.children,
		googleReview: data.siteSettings.googleReview,
	};

	const bdmBio = {
		bioTitle: data.datoCmsBdmPage.bioTitle,
		bioContent: data.datoCmsBdmPage.bioContent,
		bdmImage: data.datoCmsBdmPage.bdmImage,
	};

	const { uspTitle } = data.datoCmsBdmPage;
	const uspBanner = data.datoCmsBdmPage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsBdmPage.uspCtaCardsBannerDisplayLinks;

	const testimonialsContent = {
		...data.datoCmsBdmPage.testimonials,
		title: data.datoCmsBdmPage.testimonials.title.value.document.children,
		slides: data.datoCmsBdmPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsBdmPage,
		}),
		image: getImageSeoSchema(data.datoCmsBdmPage?.mainHeader?.primaryImage),
		// faq: getFAQSeoSchema(faqContent?.questions),
	};

	const uspContainer = {
		...data.datoCmsBdmPage.uspCardsContainer,
		title: data.datoCmsBdmPage.uspCardsContainer.title.value.document
			.children,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsBdmPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsBdmPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsBdmPage?.customFormField[0]?.customFormButton,
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsBdmPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{data.datoCmsBdmPage.mainHeaderDisplayOnPage && (
				<MainHeader
					drawerRef={adviceFormRef}
					{...headerCTAContent}
					bdmName={bdmName}
					bdmNumber={bdmNumber}
				/>
			)}
			{data.datoCmsBdmPage.uspCtaCardsBannerDisplayOnPage && (
				<IconCardsBDM
					uspTitle={uspTitle}
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					showContentLinks={uspBannerDisplayLinks}
					bdmNumber={bdmNumber}
					drawerRef={adviceFormRef}
				/>
			)}
			<BdmBio bdmBio={bdmBio} />
			<Testimonials
				drawerRef={adviceFormRef}
				testimonialsContent={testimonialsContent}
			/>
			{data.datoCmsBdmPage.uspCardsContainerDisplayOnPage && (
				<IconCards variant="Dark" iconCardsContent={uspContainer} />
			)}
			{data.datoCmsBdmPage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
					drawerRef={adviceFormRef}
				/>
			)}
			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
						customFormField={customFormField}
						bdmName={bdmName}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				url
				alt
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsBdmPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			bioTitle
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			bioContent {
				value
			}
			bdmImage {
				alt
				url
				gatsbyImageData(width: 500, height: 500, layout: CONSTRAINED)
			}
			bdmNumber
			bdmEmail
			originalId
			slug
			title
			uspTitle
			mainHeaderDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			textWithImageContainerDisplayOnPage
			uspCardsContainerDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}

			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}

			textWithImageContainer {
				id
				header {
					value
				}
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstButtonLink
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondButtonLink
				secondDisplayCtaOnPage
				thirdTitle {
					value
				}
				thirdContent {
					value
				}
				thirdImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				thirdButtonCopy
				thirdButtonLink
				thirdDisplayCtaOnPage
			}
			uspCardsContainer {
				title {
					value
				}
				subText
				uspCards {
					title
					content
					linkCopy
					link
					icon
				}
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedPpc = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		bdmNumber={data.datoCmsBdmPage.bdmNumber}
		bdmEmail={data.datoCmsBdmPage.bdmEmail}
	>
		<BdmPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedPpc;
