// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/vap-bdm';
import { Head } from '../components/templates/head';
import { VapSection } from '../components/organisms/vap-section-bdm';
import { pageTypes } from '../utils/url-helpers';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { HrefLang } from '../components/templates/hrefLang';

// markup
const VapPageBDM = ({ location, data }) => {
	const adviceFormRef = useRef(null);
	const metaContent = {
		noIndex: data.datoCmsVapPage.noIndex,
		noFollow: data.datoCmsVapPage.noFollow,
		metaInformation: data.datoCmsVapPage.metaInformation,
		fallbackTitle: data.datoCmsVapPage.title,
		fallbackDescription: data.datoCmsVapPage.longIntroduction,
		canonicalUrl: data.datoCmsVapPage?.canonicalUrl || location.href,
	};

	const pricingContent = {
		title: data.datoCmsVapPage.heading,
		subtitle: data.datoCmsVapPage.subheading,
		cards: data.datoCmsVapPage.cards,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsVapPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<VapSection
				pricingContent={pricingContent}
				drawerRef={adviceFormRef}
			/>
		</GoogleReCaptchaProvider>
	);
};

const WrappedVapPageBDM = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<VapPageBDM location={location} data={data} />
	</DefaultLayout>
);

export default WrappedVapPageBDM;

export const query = graphql`
	query vap($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsVapPage(locale: { eq: $locale }) {
			originalId
			cardTitle1
			cardTitle2 {
				value
			}
			cardSubtitle2
			cardContent2
			uspCtaCardsBannerDisplayOnPage1
			uspCtaCardsBannerDisplayOnPage2
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			textWithImageDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			uspCtaCardsBanner1 {
				content {
					value
				}
				icon
			}
			uspCtaCardsBanner2 {
				title
				content {
					value
				}
				icon
			}
			id
			heading
			subheading
			textWithImage {
				id
				title
				tickList {
					id
					text
				}
				buttonCopy
				buttonLink
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
