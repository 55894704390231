import { getGAClientId } from '../../utils';

const headers = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
};

export const useSalesforceApi = () => {
	const fetchSalesforce = async (executeRecaptcha, payloadData, method) => {
		const token = await executeRecaptcha('submit');
		const gaClientId = await getGAClientId();

		const payload = {
			...payloadData,
			gaClientId,
			token,
		};

		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.log('submission object', payload);
			return { status: 200, body: { identifier: 'develop-ref' } };
		}

		// eslint-disable-next-line compat/compat
		const response = await fetch('/api/salesforce', {
			method,
			headers,
			body: JSON.stringify(payload),
		});

		if (response.status === 200 && method === 'POST') {
			return { status: response.status, body: await response.json() };
		}
		return { status: response.status };
	};

	return { fetchSalesforce };
};
