import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// eslint-disable-next-line arrow-body-style
const TabPanel = ({ children, tabId, tabPanelId }) => {
	return (
		<section
			id={`panelset-ca-${tabPanelId}`}
			role="tabpanel"
			aria-labelledby={tabId}
			className={clsx(
				'grid-cols-12 gap-5 outline-none',
				'lg:container lg:mx-auto focus:shadow-focus grid'
			)}
		>
			{children}
		</section>
	);
};

export { TabPanel };

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	tabId: PropTypes.string.isRequired,
	tabPanelId: PropTypes.string.isRequired,
};
