/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { DrawerProvider } from '../../../contexts/drawer-context';
import { ScreenResizerProvider } from '../../../contexts/screen-resize-context';
import { MarketoProvider } from '../../../contexts/marketo-context';
import { MasterLayout } from '../../master';

const DefaultLayout = ({ children, className }) => (
	<ScreenResizerProvider>
		<DrawerProvider>
			<MarketoProvider>
				<MasterLayout>
					<main id="main" className={className}>
						{children}
					</main>
				</MasterLayout>
			</MarketoProvider>
		</DrawerProvider>
	</ScreenResizerProvider>
);

export const query = graphql`
	fragment siteSettings on DatoCmsSiteSpecificSetting {
		phoneNumber
	}
	fragment navItems on DatoCmsSiteSpecificSetting {
		phoneNumber
		primaryNavigation {
			title
			link
			treeChildren {
				title
				subText
				link
				icon
				position
				locale
			}
		}
		sideNavigation {
			title
			link
		}
	}
	fragment footerItems on DatoCmsSiteSpecificSetting {
		footerAddress
		footerNavigation {
			treeChildren {
				title
				link
				position
			}
			title
		}

		footerInternationalSites {
			title
			link
		}
		footerCopyright
	}
`;

DefaultLayout.defaultProps = {
	className: '',
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export { DefaultLayout };
