import { useState } from 'react';

export const useFormValidation = (initialValidations) => {
	const [validations, setValidations] = useState(initialValidations);

	// Regular expressions for validation
	const phoneRegex = /^(?:[+\d].*\d|\d)$/;
	const emailAddressRegex =
		/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;
	const jobTitleRegex = /^[a-z\d]{2,}/i;
	const noEmployeesRegex = /^\d+$/;
	const nameRegex = /^[a-z ,.'-]+$/i;
	const fullNameRegex = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
	const companyNameRegex = /^[A-Za-z0-9\s\-']+$/;

	const formatFormEntry = (entry) => {
		const key = entry[0];
		const value = entry[1];

		// Title radio button validation
		if (key === 'Title') {
			return {
				key: 'title', // lowercase to match validation key
				value,
				isValid: value !== '', // valid if any option is selected
			};
		}

		if (key === 'phoneNumber' || key === 'Phone') {
			return {
				key,
				value,
				isValid: phoneRegex.test(value),
			};
		}

		if (key === 'noEmployees') {
			return {
				key,
				value,
				isValid: noEmployeesRegex.test(value),
			};
		}

		if (key === 'emailAddress' || key === 'email') {
			return {
				key,
				value,
				isValid: emailAddressRegex.test(value),
			};
		}

		if (
			key === 'addressLine2' ||
			key === 'company' ||
			key === 'isEmployer'
		) {
			return {
				key,
				value,
				isValid: true,
			};
		}

		if (key === 'companyName') {
			return {
				key,
				value,
				isValid: companyNameRegex.test(value),
			};
		}

		if (key === 'firstName') {
			return {
				key,
				value,
				isValid: nameRegex.test(value),
			};
		}

		if (key === 'lastName') {
			return {
				key,
				value,
				isValid: nameRegex.test(value),
			};
		}

		if (key === 'fullName') {
			return {
				key,
				value,
				isValid: fullNameRegex.test(value),
			};
		}

		if (key === 'JobTitle') {
			return {
				key,
				value,
				isValid: jobTitleRegex.test(value),
			};
		}

		if (key === 'company') {
			return {
				key,
				value,
				isValid: companyNameRegex.test(value),
			};
		}

		// Fallback for any other field
		return {
			key,
			value,
			isValid: value !== '',
		};
	};

	const formatFormValidation = (entry) => ({
		[entry.key]: { value: entry.value, isValid: entry.isValid },
	});

	const formatSalesforceData = (entry) => ({
		[entry.key]: entry.value,
	});

	const validateForm = (form) => {
		const formData = new FormData(form);
		const formEntries = Array.from(formData.entries()).map(formatFormEntry);

		// Check for Title field and add validation if missing
		const hasTitle = formEntries.some((entry) => entry.key === 'title');

		if (!hasTitle) {
			formEntries.push({
				key: 'title',
				value: '',
				isValid: false,
			});
		}

		const formValidations = formEntries.map(formatFormValidation);
		const formEntriesObject = Object.assign({}, ...formValidations);
		const salesforceData = formEntries.map(formatSalesforceData);

		setValidations(formEntriesObject);

		// Check if any validation failed
		if (formEntries.some((entry) => entry.isValid === false)) {
			return {
				isValid: false,
				formEntries: Object.assign({}, ...salesforceData),
			};
		}

		// All validations passed
		return {
			formEntries: Object.assign({}, ...salesforceData),
			isValid: true,
		};
	};

	return {
		validations,
		validateForm,
	};
};
