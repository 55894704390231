import React, {
	useRef,
	useLayoutEffect,
	useState,
	useCallback,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cardContents, eventsCardProp } from '../../../types';

import { EventsCard } from '../../molecules/events-card';
import { Select } from '../../atoms/select';
import { CardFilters } from '../../molecules/card-filters';

const isClient = typeof window !== `undefined`;

const EventCardContainer = ({
	cardContainerContent,
	location,
	setMaxDistance,
	setOrderBy,
	loading,
	error,
	filters,
	hasLocationSet,
}) => {
	const { id, to, cards, totalCount } = cardContainerContent;
	const params = isClient
		? // eslint-disable-next-line compat/compat
		  new URLSearchParams(window.location.search)
		: ``;
	const pageParam = params.get ? params.get('p') ?? 0 : 0;
	const itemsPerPage = 12;
	const pages = [];
	const cardsCopy = [...cards];
	while (cardsCopy.length > 0) pages.push(cardsCopy.splice(0, itemsPerPage));
	const [pagination, setPagination] = useState({
		currentPage: pageParam > pages.length ? 0 : Math.max(0, pageParam - 1),
		totalPages: pages.length,
	});
	const scrollRef = useRef();
	const [currentPageRef, setCurrentPageRef] = useState(
		pagination.currentPage
	);

	const scroll = () =>
		setTimeout(() => {
			window.scrollTo({
				top: scrollRef?.current?.offsetTop,
				left: 0,
				behavior: 'smooth',
			});
		}, 500);

	const doScroll = useCallback(async () => {
		if (pagination.currentPage !== currentPageRef) {
			await scroll();
			setCurrentPageRef(pagination.currentPage);
		}
	}, [pagination.currentPage, currentPageRef]);

	useLayoutEffect(() => {
		doScroll();
	}, [cards, doScroll]);

	useEffect(() => {
		if (hasLocationSet) {
			scroll();
			setCurrentPageRef(0);
			setPagination({
				totalPages: pages.length,
				currentPage: 0,
			});
			if (isClient) {
				const url = new URL(window.location);
				url.searchParams.delete('p');
				window.history.pushState({}, '', url);
			}
		}
	}, [hasLocationSet, pages.length]);
	const Wrapper = ({ className, children }) => {
		Wrapper.defaultProps = {
			className: '',
		};

		Wrapper.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<section
				className={clsx(
					'px-3 mx-auto max-w-m-screen lg:px-8',
					className
				)}
				aria-labelledby={id}
			>
				{children}
			</section>
		);
	};

	const WrapperNonScrollable = ({ className, children }) => {
		WrapperNonScrollable.defaultProps = {
			className: '',
		};

		WrapperNonScrollable.propTypes = {
			className: PropTypes.string,
			children: PropTypes.node.isRequired,
		};

		return (
			<ul
				className={clsx(
					'w-full grid gap-x-5 gap-y-2 sm:gap-y-s-f md:gap-y-base-f grid-flow-row',
					'md:grid-cols-2 xl:grid-cols-3 pb-4 sm:pb-5',
					className
				)}
			>
				{children}
			</ul>
		);
	};

	const renderCard = (card, index) => (
		<li key={`${card.id}-${card.title}${Math.random()}`}>
			<EventsCard
				index={index}
				cardContent={card}
				to={to}
				location={location}
				loading={loading}
			/>
		</li>
	);

	const [filterVals, setFilterVals] = useState({
		distance: '',
		orderBy: '',
	});

	const distanceVal = filterVals.distance || filters.distances.at(-1).value;
	const handleFilters = (type, val) => {
		setFilterVals({
			...filterVals,
			[type]: val,
		});
	};

	const renderNoEvents = (title, subTitle) => (
		<section
			className="px-3 mx-auto max-w-m-screen lg:px-8 mt-14 md:mt-20 mb-2xl-f md:mb-20"
			ref={scrollRef}
		>
			<div className="text-center lg:mx-auto">
				<h2 className="mb-2 text-2xl text-center md:text-3xl !text-3xl md:!text-4xl md:!leading-snug block">
					{title}
				</h2>
				{subTitle ? (
					<p className="text-lg md:text-xl mb-3 last-of-type:mb-0 break-words font-centra-light block">
						{subTitle}
					</p>
				) : null}
			</div>
		</section>
	);

	return (
		<>
			<Wrapper className="mt-14 md:mt-20 mb-2xl-f md:mb-20">
				<div
					className="flex flex-col justify-center lg:mx-auto"
					ref={scrollRef}
				>
					<div
						className={clsx(
							'flex flex-row items-center flex-wrap sm:flex-nowrap',
							hasLocationSet && 'sm:flex-row-reverse'
						)}
					>
						{hasLocationSet ? (
							<div className="w-full sm:w-auto sm:items-end sm:flex sm:ml-auto">
								<div className="flex flex-col sm:mb-0 sm:items-end">
									<Select
										id="distance"
										name="distance"
										aria-required="true"
										labelOption={false}
										labelText="Sort by:"
										labelWrapperClassName="sm:!mb-0 sm:self-center sm:mr-3 sr-only"
										labelClassName="!text-sm"
										selectClassName="sm:w-44 !text-sm"
										className="w-full sm:flex sm:justify-end mb-2 sm:mb-0 sm:mr-2"
										value={filterVals.distance}
										options={filters.distances}
										onChange={(e) => {
											handleFilters(
												'distance',
												e.target.value
											);
											setMaxDistance(
												e.target.value === ''
													? filters.distances.at(-1)
															.value
													: e.target.value
											);
										}}
									/>
									{error && (
										<div className="mt-2 text-brand-red-400">
											{error}
										</div>
									)}
								</div>
								<div className="flex flex-col mb-s-f sm:mb-0 sm:items-end">
									<Select
										id="sortDate"
										name="sortDate"
										aria-required="true"
										labelOption={false}
										labelText="Sort by:"
										labelWrapperClassName="sm:!mb-0 sm:self-center sm:mr-3 sr-only"
										labelClassName="!text-sm"
										selectClassName="!text-sm sm:min-w-[20rem]"
										className="w-full sm:flex sm:justify-end"
										value={filterVals.orderBy}
										options={filters.orderBy}
										onChange={(e) => {
											handleFilters(
												'orderBy',
												e.target.value
											);
											setOrderBy(e.target.value);
										}}
									/>
									{error && (
										<div className="mt-2 text-brand-red-400">
											{error}
										</div>
									)}
								</div>
							</div>
						) : null}
						<span className="flex flex-row justify-start text-sm md:w-1/4 lg:w-1/3">
							{totalCount} results
						</span>
					</div>
					{cards?.length > 0 ? (
						<>
							<WrapperNonScrollable className="pb-4 sm:pb-5 mt-s-f md:mt-base-f">
								{pages.length > 0
									? pages[pagination.currentPage].map(
											renderCard
									  )
									: null}
							</WrapperNonScrollable>
							<CardFilters
								filterVariant="Bottom"
								totalCount={totalCount}
								setPagination={setPagination}
								pagination={pagination}
								error={error}
							/>
						</>
					) : (
						renderNoEvents(
							`Sorry, no events found within ${distanceVal} mile${
								distanceVal !== 1 ? 's' : ''
							} of your location`,
							'Please try searching a different location'
						)
					)}
					{error
						? renderNoEvents(
								'Sorry, we seem to be having technical difficulties',
								'Please try refreshing your browser'
						  )
						: null}
				</div>
			</Wrapper>
		</>
	);
};

EventCardContainer.defaultProps = {
	location: '',
	loading: false,
	error: null,
	setMaxDistance: () => {},
	setOrderBy: () => {},
	hasLocationSet: false,
};

EventCardContainer.propTypes = {
	setMaxDistance: PropTypes.func,
	setOrderBy: PropTypes.func,
	cardContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		to: PropTypes.string.isRequired,
		subText: PropTypes.string,
		totalCount: PropTypes.number,
		cards: PropTypes.oneOfType([
			PropTypes.arrayOf(cardContents),
			PropTypes.arrayOf(eventsCardProp.cardContent),
		]),
	}).isRequired,
	location: PropTypes.string,
	loading: PropTypes.bool,
	hasLocationSet: PropTypes.bool,
	error: PropTypes.string,
	filters: PropTypes.shape({
		distances: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string,
				value: PropTypes.string,
			})
		),
		orderBy: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string,
				value: PropTypes.string,
			})
		),
	}).isRequired,
};

export { EventCardContainer };
