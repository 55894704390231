// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { pricingCardProps, refProp } from '../../../types';
import { Title } from '../../atoms/title';
import { VapCalculatorIE } from '../../molecules/vap-calculator-ie';
import { VapCardIE } from '../../molecules/vap-card-ie';

const VapSectionIE = ({ pricingContent, drawerRef, locale }) => {
	const [priceCalculated, setPriceCalculated] = useState({
		drafting_contract: '-',
		drafting_policy: '-',
		hs_docs: '-',
		fine_contract: '-',
		lateness_absence: '-',
		staff_turnover: '-',
		general_hr: '-',
		hs_fines: '-',
		average_tribunal: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
		// cost_hr_manager: '-',
		// average_back_pain: '-',
		// total_cost_hs: '-',
		// total_cost_es: '-',
		// annual_cost_hs: '-',
		// annual_cost_es: '-',
		// cost_per_employee_hs: '-',
		// cost_per_employee_es: '-',
		// annual_roi_hs: '-',
		// annual_roi_es: '-',
	});
	const [employeeCount, setEmployeeCount] = useState(0);
	const [showLargeBusiness, setShowLargeBusiness] = useState(false);
	const { title, subtitle } = pricingContent;
	const [show, setShow] = useState(false);
	const [parent] = useAutoAnimate({
		animate: true,
		duration: 300,
		easing: 'ease-in-out',
	});

	return (
		<section className="relative w-full">
			<div ref={parent} className="px-3 py-16 mx-auto max-w-m-screen">
				<Title title={title} id="vap-calc" />
				<VapCalculatorIE
					title={title}
					subtitle={subtitle}
					setPriceCalculated={setPriceCalculated}
					setEmployeeCount={setEmployeeCount}
					setShowLargeBusiness={setShowLargeBusiness}
					setShow={setShow}
				/>

				{show && (
					<section className="col-span-12 lg:col-span-12">
						<VapCardIE
							priceCalculated={priceCalculated}
							employeeCount={employeeCount}
							drawerRef={drawerRef}
							showLargeBusiness={showLargeBusiness}
							locale={locale}
						/>
					</section>
				)}
			</div>
		</section>
	);
};

VapSectionIE.propTypes = {
	pricingContent: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		cards: PropTypes.arrayOf(pricingCardProps),
	}).isRequired,
	drawerRef: refProp.isRequired,
	locale: PropTypes.string.isRequired,
};

export { VapSectionIE };
