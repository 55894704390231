import React from 'react';
import PropTypes from 'prop-types';
import LogoIcon from '../../../assets/logo.inline.svg';

const Logo = ({ url, title, ...logoAttributes }) => (
	<LogoIcon {...logoAttributes} className="max-w-full" />
);

Logo.defaultProps = {
	url: '/',
};

Logo.propTypes = {
	url: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export { Logo };
