// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import SessionCard from '../../molecules/session-card';
import FeaturedSessionCard from '../../molecules/featured-session-card-ca-new';
import LiveSessionCard from '../../molecules/live-session-card-ca-new';
import PageSection from '../page-section';

const SessionCardContainerCA = ({
	liveWebinarSessions,
	pbsWebinar,
	locale,
}) => {
	const sortedWebinars = liveWebinarSessions.upcomingLiveWebinars.sort(
		(a, b) => new Date(a.dateAndTime) - new Date(b.dateAndTime)
	);

	return (
		<div>
			<div className="flex flex-col gap-x-10 md:flex-row">
				<div className="w-full pt-8 md:w-1/2 md:pt-0">
					<GatsbyImage
						image={liveWebinarSessions.image.gatsbyImageData}
						alt={liveWebinarSessions.image.alt || ''}
						className="w-full h-full"
					/>
				</div>

				<FeaturedSessionCard
					featuredCard={liveWebinarSessions.highlightedLiveWebinar}
					pbsWebinar={pbsWebinar}
					locale={locale}
				/>
			</div>

			{pbsWebinar ? (
				<PageSection
					theme="White"
					titleBlock={liveWebinarSessions.upcomingWebinarsCopy}
				>
					<div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-3">
						{sortedWebinars.map((card) => (
							<LiveSessionCard
								session={card}
								pbsWebinar
								locale={locale}
							/>
						))}
					</div>
				</PageSection>
			) : (
				<>
					<h3 className="mt-10 mb-6 text-2xl font-bold">
						Upcoming sessions
					</h3>
					<div className="flex flex-col gap-10 lg:flex-row">
						{liveWebinarSessions.upcomingLiveWebinars.map(
							(card) => (
								<SessionCard
									card={card}
									key={card.title}
									pbsWebinar={pbsWebinar}
									locale={locale}
								/>
							)
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default SessionCardContainerCA;
