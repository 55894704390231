import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

import { titleProp, datoGatsbyImageProp, refProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { sendTrackingData } from '../../../utils';
import { buildLink, getLocale } from '../../../utils/locale';

import { useDrawer } from '../../../contexts/drawer-context';
import { CallUs } from '../../atoms/call-us-child-service-ca';

import * as headingStyles from './index.module.css';
import tick from './blue_tick.svg';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const MainHeaderCorCA = ({
	id,
	header,
	secondaryHeaderRows,
	// eslint-disable-next-line react/prop-types
	secondaryHeader,
	as,
	primaryLink,
	primaryLinkCopy,
	primaryLinkOpenForm,
	primaryImage,
	googleReview,
	secondaryImage,
	// secondaryLink,
	headingClassNames,
	drawerRef,
	glu,
	logo,
	// eslint-disable-next-line react/prop-types
	ctaLabel,
	// eslint-disable-next-line react/prop-types
	showTrustPilot: showBusinessReview,
}) => {
	const Component = as;

	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});

	const { setDrawerRef } = useDrawer();

	const primaryImageData = getImage(primaryImage);
	const secondaryImageData = getImage(secondaryImage);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;

	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					handleClickTracking();
				}}
				className=" inline-flex items-center justify-center px-6 py-4 mt-4 text-lg leading-6 !text-blue-400 transition-colors bg-white border-2 border-blue-400 rounded-lg hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus hover:!text-white focus:text-white focus:outline-focus sm:w-max sm:mt-0 sm:ml-4"
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}
	return (
		<>
			<div className="block mb-5 md:hidden">
				<CallUs ctaMode={false} ctaLabel={ctaLabel} />
			</div>
			<div
				role="banner"
				className={clsx(
					'px-4 mx-auto lg:max-w-m-screen md:py-10 lg:py-0 lg:px-10 sm:mt-0 mb-12 md:mb-0 xl:px-12 2xl:pt-10 2xl:px-0',
					{
						'bg-brand-pale-400': glu,
						'lg:max-w-m-screen': !glu,
					}
				)}
				aria-labelledby={id}
			>
				<div className="flex flex-row items-center ">
					<div className="lg:w-3/5 xl:w-3/5 2xl:w-[54%]">
						<div
							className={clsx('flex mx-auto', {
								'lg:max-w-m-screen': glu,
							})}
						>
							<span
								className={clsx(
									'flex flex-col',
									headingStyles.headingContainer
								)}
							>
								{logo && (
									<img
										src={logo.url}
										alt={logo.alt || ''}
										className="max-w-[275px]"
									/>
								)}
								<div
									className={clsx(
										'inline !leading-[1.3] font-castledown-regular mx-5 xl:mx-0',
										headingStyles.heading,
										glu
											? 'inline text-2xl lg:text-4xl font-bold lg:min-h-[160px]'
											: 'inline text-4xl md:text-4xl xl:text-5xl ',
										headingClassNames
									)}
								>
									<Component
										id={id}
										className="!leading-[1.3] font-castledown-regular index-module--heading--48393 text-4xl md:text-5xl xl:text-5xl"
									>
										{titleText}
									</Component>
									{secondaryHeaderRows?.length > 0 ? (
										<ul
											className={clsx(
												'mx-2',
												'list-none',
												'mt-2 leading-[1.2] block',
												'xl:leading-[1.3]',
												'pt-3',
												glu
													? 'text-xl lg:text-2xl font-castledown-regular'
													: `text-2xl md:text-3xl ${headingStyles.heading} `
											)}
										>
											{secondaryHeaderRows.map(
												({ secondaryHeaderRow }) => (
													<div className="flex flex-row gap-4 py-1 my-1 xl:py-1 2xl:py-2">
														<div className="mt-2 min-w-[20px]">
															<img
																src={tick}
																alt=""
															/>
														</div>
														<div>
															<li className="max-w-full text-[21px] sm:text-[24px]">
																{
																	secondaryHeaderRow
																}
															</li>
														</div>
													</div>
												)
											)}
										</ul>
									) : (
										<span
											className={clsx(
												'mt-4 leading-[1.2] block',
												'md:mt-8',
												' xl:leading-[1.3]',
												glu
													? 'text-xl lg:text-2xl font-castledown-regular'
													: `text-2xl md:text-4xl xl:text-[24px] ${headingStyles.heading}`
											)}
										>
											{secondaryHeader}
										</span>
									)}
								</div>
								<span className="md:ml-5 xl:ml-0 xl:px-2">
									<p className="flex flex-col items-center justify-center mt-8 sm:flex-row sm:justify-start">
										{/* 1st CTA */}
										<CallUs ctaMode ctaLabel={ctaLabel} />

										{/* 2nd CTA */}
										{cta || null}
									</p>
									{showBusinessReview ? (
										<BusinessReviewHorizontal
											googleReview={googleReview}
										/>
									) : null}
								</span>
							</span>
						</div>
					</div>
					{/* right div for img */}
					<div
						className={`hidden lg:block grid-cols-12 lg:grid grow shrink-0 ${headingStyles.imageContainer} lg:w-2/5 lg:pl-12 xl:pt-5 2xl:w-[46%] 2xl:pl-10`}
					>
						<GatsbyImage
							image={primaryImageData}
							alt={primaryImage.alt || ''}
							key={primaryImage.url}
							className={clsx(
								'border-8',
								'border-white',
								'drop-shadow-image',
								'lg:-ml-8',
								'lg:h-[250px]',
								'lg:w-[250px]',
								'xl:ml-8',
								'xl:h-[280px]',
								'xl:w-[280px]',
								'2xl:-mb-10',
								'2xl:ml-20',
								'2xl:h-[334px]',
								'2xl:w-[334px]',
								headingStyles.image1
							)}
						/>
						<GatsbyImage
							image={secondaryImageData}
							alt={secondaryImage.alt || ''}
							key={secondaryImage.url}
							className={clsx(
								'border-8',
								'border-white',
								'drop-shadow-image',
								'lg:h-[250px]',
								'lg:w-[250px]',
								'xl:-mt-14',
								'xl:h-[280px]',
								'xl:w-[280px]',
								'2xl:-mt-24',
								'2xl:h-[334px]',
								'2xl:w-[334px]',
								headingStyles.image2
							)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

MainHeaderCorCA.defaultProps = {
	id: 'main-site-header-v2-ca',
	as: 'h1',
	headingClassNames: '',
	glu: false,
	logo: {},
	secondaryHeaderRows: [],
};

MainHeaderCorCA.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	as: PropTypes.string,
	primaryImage: datoGatsbyImageProp.isRequired,
	secondaryImage: datoGatsbyImageProp.isRequired,
	headingClassNames: PropTypes.string,
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	// secondaryLink: PropTypes.string.isRequired,
	secondaryHeaderRows: PropTypes.arrayOf(PropTypes.string),
	// eslint-disable-next-line react/require-default-props
	drawerRef: refProp,
	glu: PropTypes.bool,
	logo: datoGatsbyImageProp,
	googleReview: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
};

export { MainHeaderCorCA };
