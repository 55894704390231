import React from 'react';
import PropTypes from 'prop-types';
import { TextWithImage } from '../../atoms/text-with-image-hr-software';
import { HeadingTagLine } from '../../atoms/heading-tag-line-ppc';
import { HeadingTagLine2 } from '../../atoms/heading-tag-line-ppc2';

import {
	datoGatsbyImageProp,
	titleProp,
	structuredTextContent,
	refProp,
} from '../../../types';

const TextWithImageContainer = ({
	textWithImageContainerContent,
	modalRef,
	className,
}) => {
	const { id } = textWithImageContainerContent;
	let textWithImages = [textWithImageContainerContent];

	if (
		!Object.prototype.hasOwnProperty.call(
			textWithImageContainerContent,
			'content'
		)
	) {
		const {
			firsttitle,
			firstContent,
			firstImage,
			firstButtonCopy,
			firstDisplayCtaOnPage,
			secondtitle,
			secondContent,
			secondImage,
			secondButtonCopy,
			secondDisplayCtaOnPage,
		} = textWithImageContainerContent;

		const elementOne = {
			titleProps: {
				id,
				firsttitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine,
			content: firstContent,
			image: firstImage,
			firstButtonCopy,
			firstDisplayCtaOnPage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		const elementTwo = {
			titleProps2: {
				id,
				secondtitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			as: HeadingTagLine2,
			content: secondContent,
			image: secondImage,
			secondButtonCopy,
			secondDisplayCtaOnPage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		textWithImages = secondImage ? [elementOne, elementTwo] : [elementOne];
	}

	// console.log('This is the className', className);

	return (
		<section className={`py-10 md:py-16 ${className}`}>
			<div
				className="px-4 mx-auto md:px-8 max-w-m-screen "
				aria-labelledby={id}
			>
				{textWithImages.map((elContent, index) => {
					let flipSecond = false;
					if (textWithImages.length > 0) {
						flipSecond = true;
					}

					return (
						<TextWithImage
							// eslint-disable-next-line react/no-array-index-key
							key={`${id}${index}`}
							textWithImageContent={elContent}
							modalRef={modalRef}
							{...((flipSecond && index === 0) ||
							(flipSecond && index === 2)
								? { flip: true }
								: null)}
						/>
					);
				})}
			</div>
		</section>
	);
};

TextWithImageContainer.defaultProps = {
	className: '',
};

TextWithImageContainer.propTypes = {
	textWithImageContainerContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firsttitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		secondtitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		thirdtitle: PropTypes.oneOfType([titleProp, PropTypes.string])
			.isRequired,
		firstButtonCopy: PropTypes.string,
		secondButtonCopy: PropTypes.string,
		thirdButtonCopy: PropTypes.string,
		buttonLink: PropTypes.string,
		content: structuredTextContent,
		firstContent: structuredTextContent,
		secondContent: structuredTextContent,
		thirdContent: structuredTextContent,
		image: datoGatsbyImageProp,
		firstImage: datoGatsbyImageProp,
		secondImage: datoGatsbyImageProp,
		thirdImage: datoGatsbyImageProp,
		firstDisplayCtaOnPage: PropTypes.bool,
		secondDisplayCtaOnPage: PropTypes.bool,
		thirdDisplayCtaOnPage: PropTypes.bool,
	}).isRequired,
	modalRef: refProp.isRequired,
	className: PropTypes.string,
};

export { TextWithImageContainer };
