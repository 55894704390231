export const contactUs = [
	{
		value: 'Payments Team',
		label: 'Payments Team',
	},
	{
		value: 'Client Experience',
		label: 'Client Experience',
	},
	{
		value: 'HR Advisory Department',
		label: 'HR Advisory Department',
	},
	{
		value: 'HR Policy and Documentation',
		label: 'HR Policy and Documentation',
	},
	{
		value: 'Peninsula Face2Face On-site Services',
		label: 'Peninsula Face2Face On-site Services',
	},
	{
		value: 'Health and Safety',
		label: 'Health and Safety',
	},
	{
		value: 'Software (BrightHR)',
		label: 'Software (BrightHR)',
	},
	{
		value: 'EAP & Occupational Health (Health Assured)',
		label: 'EAP & Occupational Health (Health Assured)',
	},
	{
		value: 'Legal Services',
		label: 'Legal Services',
	},
	{ value: 'Irwell Law', label: 'Irwell Law' },
	{ value: 'Other', label: 'Other' },
];

export const contactUsIe = [
	{
		value: 'Payments Team',
		label: 'Payments Team',
	},
	{
		value: 'Client Experience',
		label: 'Client Experience',
	},
	{
		value: 'HR Advisory Department',
		label: 'HR Advisory Department',
	},
	{
		value: 'HR Policy and Documentation',
		label: 'HR Policy and Documentation',
	},
	{
		value: 'Graphite Face2Face Services',
		label: 'Graphite Face2Face Services',
	},
	{
		value: 'Health and Safety',
		label: 'Health and Safety',
	},
	{
		value: 'Software (BrightHR)',
		label: 'Software (BrightHR)',
	},
	{
		value: 'EAP & Occupational Health (Health Assured)',
		label: 'EAP & Occupational Health (Health Assured)',
	},
	{
		value: 'Litigation',
		label: 'Litigation',
	},
	{ value: 'Other', label: 'Other' },
];
