import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { TagsList } from '../tags-list';

import { Author } from '../../atoms/author';
import { Speaker } from '../../atoms/speaker';
import { HeaderDownloadPromo } from '../../atoms/header-download-promo';

import {
	authorProp,
	datoGatsbyImageProp,
	refProp,
	speakerProp,
} from '../../../types';

import LogoIcon from '../../../assets/logo.inline.svg';

const ResourceHeader = ({
	content,
	variant,
	drawerRef,
	className,
	description,
	heading,
}) => {
	const {
		id,
		title,
		categories,
		author,
		speaker,
		image,
		tickList,
		createdDate,
		createdDateOrdinal,
		lastUpdated,
		lastUpdatedOrdinal,
		pageUrl,
	} = content;

	let alt = '';
	let hasImage;
	let imageData = {};
	if (image && Object.keys(image).length !== 0) {
		alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
		imageData = getImage(image);
		hasImage = true;
	} else {
		hasImage = false;
	}

	const tags = categories?.map((category) => category.name);

	const authorContent = {
		author,
		createdDate,
		createdDateOrdinal,
		lastUpdated,
		lastUpdatedOrdinal,
		pageUrl,
		title,
	};
	return (
		<section
			aria-labelledby={id}
			className={clsx(
				'sm:gap-10 max-w-content mx-auto px-3 md:px-8 xl:px-0',
				variant === 'Download'
					? 'sm:grid sm:grid-cols-[1fr_50%] md:grid-cols-[1fr_43%] lg:grid-cols-[1fr_38%] lg:gap-14'
					: null,
				variant === 'Event' ? 'mb-9 md:mb-xl-f' : 'mb-14 md:mb-20',
				variant !== 'Download' && variant !== 'Event'
					? 'sm:grid sm:grid-cols-[1fr_34%] lg:gap-16'
					: null,
				className
			)}
		>
			<div>
				<h1
					id={id}
					className="mb-2 md:leading-tight text-2xl-f md:text-5xl md:mb-4"
				>
					{heading ||
						description?.value?.document?.children[0]?.children[0]
							?.value ||
						title}
				</h1>
				<h2
					className={`${
						variant !== 'Event'
							? 'text-2xl-f md:text-5xl'
							: 'text-lg'
					}`}
				>
					{description?.value?.document?.children[1]?.children[0]
						? title
						: description?.value?.document?.children[1]?.children[0]
								?.value || heading}
				</h2>
				{tags?.length > 0 ? (
					<TagsList
						className="flex-wrap mb-7 [&>li]:mt-2"
						tags={tags}
					/>
				) : null}

				{author ? (
					<Author
						displayClassNames="hidden sm:block"
						layoutClassNames="lg:flex"
						content={authorContent}
					/>
				) : null}
				{speaker ? (
					<Speaker
						displayClassNames="hidden sm:block"
						layoutClassNames="lg:flex"
						bioRef="md"
						content={{
							speaker,
							id,
						}}
					/>
				) : null}
			</div>

			<div>
				{variant === 'Download' ? (
					<HeaderDownloadPromo
						promoTickList={tickList}
						drawerRef={drawerRef}
					/>
				) : null}
				{hasImage ? (
					<GatsbyImage
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image max-h-[230px] max-w-full xs:max-h-[320px] md:max-h-[280px]'
						)}
						image={imageData}
						quality={80}
						alt={alt}
					/>
				) : null}
				{!hasImage && variant !== 'Download' && variant !== 'Event' ? (
					<LogoIcon
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image aspect-[408/245] p-12'
						)}
						alt="Peninsula Group Limited Logo Placeholder"
					/>
				) : null}

				{author ? (
					<Author
						displayClassNames="block sm:hidden"
						content={authorContent}
					/>
				) : null}
				{speaker ? (
					<Speaker
						displayClassNames="block sm:hidden"
						bioRef="sm"
						content={{
							speaker,
							id,
						}}
					/>
				) : null}
			</div>
		</section>
	);
};

export { ResourceHeader };

ResourceHeader.defaultProps = {
	variant: '',
	drawerRef: () => {},
	className: '',
	description: '',
	heading: '',
};

ResourceHeader.propTypes = {
	content: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		categories: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			})
		),
		author: authorProp,
		speaker: speakerProp,
		createdDate: PropTypes.string,
		createdDateOrdinal: PropTypes.string,
		lastUpdated: PropTypes.string,
		lastUpdatedOrdinal: PropTypes.string,
		image: datoGatsbyImageProp,
		pageUrl: PropTypes.string,
		tickList: PropTypes.arrayOf(
			PropTypes.shape({ text: PropTypes.string })
		),
		downloadId: PropTypes.string,
	}).isRequired,
	variant: PropTypes.string,
	drawerRef: refProp,
	className: PropTypes.string,
	description: PropTypes.string,
	heading: PropTypes.string,
};
