// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { vapCalculatedProp, refProp } from '../../../types';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';

export default function FullCoverIE({ priceCalculated, drawerRef }) {
	const { setDrawerRef } = useDrawer();
	const [parent] = useAutoAnimate({
		animate: true,
		duration: 300,
		easing: 'ease-in-out',
	});
	return (
		<div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-24">
			<div ref={parent} className="col-1 ">
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of drafting a contract
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.drafting_contract}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of drafting a single policy
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.drafting_policy}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							<p className="hidden lg:block">
								Cost to conduct a risk assessment
							</p>
							<p className="lg:hidden">
								Health & safety documents
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.hs_docs}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of fine for no employment <br /> contracts (for
							1 employee)
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.fine_contract}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of staff lateness and absence
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.lateness_absence}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of staff churn & <br /> recruitment (for 1
							employee)
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.staff_turnover}
					</div>
				</div>

				{/* <div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of Health & Safety manager
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.cost_hs_manager}
					</div>
				</div> */}

				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							<p className="hidden lg:block">
								Cost of general HR, <br />
								admin and paperwork
							</p>
							<p className="lg:hidden">
								Cost of general HR, admin and paperwork
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.general_hr}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							Cost of H&S fines (average employer liability)
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.hs_fines}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b-2 lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px]">
							<p className="">Average Tribunal claim</p>
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-black font-normal text-[16px] lg:text-[20px] xl:text-[22px]">
						{priceCalculated.average_tribunal}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b md:border-b-0 lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px]">
							<p className="hidden lg:block text-brand-red-500">
								Annual cost <br />
								to your business
							</p>
							<p className="lg:hidden text-brand-red-500">
								Annual cost to your business
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-brand-red-500 font-semibold text-[24px] md:text-[30px]">
						{priceCalculated.total_cost}
					</div>
				</div>
			</div>

			<div className="col-2 bg-brand-pale-400 focus-within:outline-blue-400/[0.20] border-2 border-blue-300 px-s-f py-base-f rounded-sm lg:h-[36rem] lg:mt-4">
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px]">
							<p className="hidden lg:block">
								Annual cost of <br />
								Peninsula service
							</p>
							<p className="lg:hidden">
								Annual cost of Peninsula service
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-semibold text-[16px] md:text-[20px] lg:text-[24px] leading-[32px]">
						{priceCalculated.annual_cost}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8 border-b lg:py-3 lg:flex-row border-[#1F2E7A]">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px]">
							<p className="">
								Cost of Peninsula per
								<br /> employee per month
							</p>
						</div>
					</div>
					<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-semibold text-[16px] md:text-[20px] lg:text-[24px] leading-[32px]">
						{priceCalculated.cost_per_employee}
					</div>
				</div>
				<div className="flex flex-col justify-between py-8  lg:py-3 lg:flex-row ">
					<div className="flex flex-row items-center">
						<div className="shrink-0 text-[#1D2951] text-[24px] md:text-[26px] font-semibold leading-[32px]">
							Return On Investment
						</div>
					</div>
					<div className="lg:text-right text-[#3954E0] ">
						<h3 className="lg:pl-28 pt-4 lg:pt-0 text-[24px] md:text-[30px] font-semibold lg:text-[42px]">
							{priceCalculated.annual_roi}
						</h3>
						<p className="text-[20px] lg:text-[15px] xl:text-[20px]">
							for every €1 spent
						</p>
					</div>
				</div>

				<div className="text-center mt-6">
					<p className="">
						<Button
							onClick={() => setDrawerRef(drawerRef)}
							className="whitespace-nowrap"
						>
							Save my time & money
						</Button>
					</p>
				</div>
			</div>
		</div>
	);
}
FullCoverIE.defaultProps = {
	priceCalculated: {
		drafting_contract: '-',
		drafting_policy: '-',
		hs_docs: '-',
		fine_contract: '-',
		lateness_absence: '-',
		staff_turnover: '-',
		general_hr: '-',
		hs_fines: '-',
		average_tribunal: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
	},
};
FullCoverIE.propTypes = {
	priceCalculated: vapCalculatedProp,
	drawerRef: refProp.isRequired,
};
