import React, { useState, useEffect } from 'react';
import { Button } from '../../../atoms/button';
import WrapperImage from '../../../../images/glu-home-modal-wrapper/GLU-wrapper-image-two.png';

const WebinarModal = () => {
	const [isVisible, setIsVisible] = useState(false);

	// locale storage open only once
	useEffect(() => {
		const hasSeenModal = localStorage.getItem('hasSeenModal');
		if (!hasSeenModal) {
			const timer = setTimeout(() => {
				setIsVisible(true);
			}, 15000);
			// Clean up the timer if the component unmounts
			return () => clearTimeout(timer);
		}
	}, []);
	const closeModal = () => {
		setIsVisible(false);
		localStorage.setItem('hasSeenModal', 'true');
	};

	const closeButton = () => {
		setIsVisible(false);
		localStorage.setItem('hasSeenModal', 'true');
	};

	return (
		isVisible && (
			<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
				<div
					className="absolute text-white max-smd:text-xl p-20  max-w-m-screen mx-auto shadow-lg"
					style={{
						backgroundImage: `url(${WrapperImage})`,
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}}
				>
					<Button
						className="absolute  top-2 max-smd:mt-[5%] mt-8 bg-none hover:bg-brand-red-500 right-4 md:right-8 2xl:right-8 text-white text-4xl rounded justify-center items-center bg-brand-blue-400 border-none font-bold"
						onClick={closeModal}
					>
						&times;
					</Button>

					<div className="text-center ">
						<div className="text-lg max-smd:text-xs -mt-10  font-bold mb-2 flex items-center justify-center">
							<span className="bg-brand-blue-400 text-white px-2 py-1 mt-2 mr-2 rounded">
								Don’t Forget that GLU elearning also run:
							</span>
						</div>
						<h1 className="text-5xl mb-6 max-smd:text-xl max-smd:font-[10%] font-semibold mb-1">
							<span className="text-brand-red-500">W</span>eekly{' '}
							<span className="text-brand-red-500">W</span>
							ednesday{' '}
							<span className="text-brand-red-500">W</span>ebinars
						</h1>
						<h3 className="text-xl max-smd:text-sm max-smd:mt-2 mb-10 underline underline-w-[30%] decoration-brand-red-500 decoration-8 underline-offset-[40%] underline">
							On the hottest of HR, Employment Law, and Health &
							Safety topics!
						</h3>

						<a href="https://www.peninsulagrouplimited.com/elearning/webinars/">
							<Button
								onClick={closeButton}
								className="hover:bg-brand-red-500 max-xs:text-[10%] max-smd:text-xs text-white  [border-bottom: 1px solid red] px-4 rounded-lg hover:bg-brand-blue-400 transition-colors"
							>
								Click Here to Register your place,
								<br /> or review our Catch-Up Sessions!
							</Button>
						</a>
					</div>
				</div>
			</div>
		)
	);
};

export default WebinarModal;
