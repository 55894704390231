import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ArrowIcon from '../../../assets/arrow.inline.svg';
import { useRefProp } from '../../../types';

export const CarouselNavigation = ({
	className,
	hasMoreThanOneSlide,
	nextRef,
	prevRef,
	paginationRef,
	onClickNextProp,
}) => (
	<div
		className={clsx(
			'items-center justify-center xl:justify-end',
			!hasMoreThanOneSlide ? 'hidden' : 'flex',
			className
		)}
		aria-hidden="true"
	>
		<span
			className="mr-7 text-base-f font-centra-light leading-7 !w-auto"
			ref={paginationRef}
		/>

		<div className="flex items-center justify-center">
			<button
				type="button"
				ref={prevRef}
				className="mr-5"
				aria-label="Previous slide"
			>
				<ArrowIcon viewBox="0 0 33 34" className="w-[30px] h-auto" />
			</button>

			<button
				type="button"
				ref={nextRef}
				onClick={onClickNextProp}
				aria-label="Next slide"
			>
				<ArrowIcon
					viewBox="0 0 33 34"
					className="w-[30px] h-auto rotate-180"
				/>
			</button>
		</div>
	</div>
);

CarouselNavigation.defaultProps = {
	className: '',
	onClickNextProp: () => {},
};

CarouselNavigation.propTypes = {
	className: PropTypes.string,
	nextRef: useRefProp.isRequired,
	prevRef: useRefProp.isRequired,
	paginationRef: useRefProp.isRequired,
	hasMoreThanOneSlide: PropTypes.bool.isRequired,
	onClickNextProp: PropTypes.func,
};
