import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { getLocale } from '../../../utils/locale';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

export const CallUs = ({ className, onClickProp, number, type }) => {
	const [fallBackNumber, setFallBackNumber] = useState('');

	useEffect(() => {
		if (!number) {
			if (locale === 'ca') {
				setFallBackNumber('1 (833) 247-3652');
			} else {
				setFallBackNumber('0800 158 2313');
			}
		}
	}, [number]);

	return type === 'button' ? (
		<button
			className={clsx(className)}
			type="submit"
			onClick={() => {
				if (type === 'button') {
					if (typeof window !== 'undefined') {
						document?.getElementById('advice-form-ppc')?.click();
						// document
						// 	?.getElementById('inline-form-ppc')
						// 	?.scrollIntoView({
						// 		behavior: 'smooth',
						// 		block: 'center',
						// 	});
					}
				}
			}}
		>
			Speak to an expert
		</button>
	) : (
		<a
			href={`tel:${
				number?.replaceAll(' ', '') ||
				fallBackNumber?.replaceAll(' ', '')
			}`}
			onClick={() =>
				onClickProp({
					click_text: number,
				})
			}
			className={clsx(className)}
		>
			<span className={locale !== 'ca' ? 'rulerclick' : ''}>
				Speak to an expert
			</span>
		</a>
	);
};

CallUs.defaultProps = {
	className: '',
	type: 'cta',
	onClickProp: () => {},
};

CallUs.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onClickProp: PropTypes.func,
	number: PropTypes.string.isRequired,
};
