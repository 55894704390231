import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { Hamburger } from '../hamburger';

import * as drawerStyles from './index.module.css';

import { useDrawer } from '../../../contexts/drawer-context';

const Drawer = forwardRef(
	(
		{
			label,
			anchor = 'right',
			children,
			className,
			elevation = 20,
			hideBackdrop = false,
			onClose,
			open = false,
			hasClose = false,
			...other
		},
		ref
	) => {
		const drawer = useRef(null);
		const { setDrawerRef } = useDrawer();

		// Closes the drawer when the user clicks outside of it
		const handleClose = (e) => {
			if (e.target === ref.current) {
				setDrawerRef('');
				if (open && onClose) {
					onClose();
				}
			}
		};

		// Closes the drawer when the user presses the escape key
		const handleEscape = (e) => {
			if (e.key === 'Escape' && open) {
				setDrawerRef('');
				if (onClose) {
					onClose();
				}
			}
		};

		const getKeyboardFocusableElements = (element) =>
			[
				...element.querySelectorAll(
					'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
				),
			].filter(
				(el) =>
					!el.hasAttribute('disabled') &&
					!el.getAttribute('aria-hidden')
			);

		useEffect(() => {
			if (open) {
				drawer.current.focus();

				const focusableElements = getKeyboardFocusableElements(
					drawer.current
				);
				const firstFocusableElement = focusableElements[0];
				const focusableContent = focusableElements;
				const lastFocusableElement =
					focusableContent[focusableContent.length - 1];

				document.addEventListener('keydown', (e) => {
					if (e.key === 'Escape') {
						setDrawerRef('');
						if (open && onClose) {
							onClose();
						}
					}

					if (e.shiftKey) {
						if (document.activeElement === firstFocusableElement) {
							if (e.key !== 'Enter') {
								lastFocusableElement.focus();
								e.preventDefault();
							}
						}
					} else if (
						document.activeElement === lastFocusableElement
					) {
						if (e.key !== 'Enter') {
							firstFocusableElement.focus();
							e.preventDefault();
						}
					}
				});
			}

			document.addEventListener('keyup', handleEscape);
			document.body.classList[open ? 'add' : 'remove']('overflow-hidden');
			document.documentElement.classList[open ? 'add' : 'remove'](
				'viewport-contain'
			);

			return () => {
				document.removeEventListener('keyup', handleEscape);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [open]);

		return (
			<div
				role="presentation"
				ref={ref}
				className={clsx(
					drawerStyles.drawer,
					open ? 'open' : 'closed',
					open ? drawerStyles.open : '',
					hideBackdrop ? '' : 'bg-black/20',
					'z-50 !important',
					'fixed',
					'left-0',
					'top-0',
					'min-h-screen',
					'w-full',
					'transition-opacity',
					`z-${elevation}`,
					open ? '' : 'delay-100',
					open ? 'pointer-events-auto' : 'pointer-events-none'
				)}
				onClick={handleClose}
				onKeyUp={handleEscape}
				data-testid="drawer"
				{...other}
			>
				<section
					role="dialog"
					aria-modal="true"
					aria-label={label}
					aria-hidden={!open}
					tabIndex="-1"
					ref={drawer}
					className={clsx(
						'bg-white',
						'shadow-drawer',
						'fixed',
						anchor === 'left' ? 'left-0' : 'right-0',
						'top-0',
						'pt-20',
						'h-full',
						'w-full',
						'overflow-y-auto',
						'overscroll-contain',
						'max-w-[460px]',
						'transition-transform',
						'ease-in-out',
						'duration-300',
						`z-${elevation}`,
						open // eslint-disable-line no-nested-ternary
							? 'translate-x-0'
							: anchor === 'left'
							? '-translate-x-full'
							: 'translate-x-full',
						className
					)}
				>
					{hasClose ? (
						<div className="flex justify-end absolute right-4 top-[18px]">
							<Hamburger
								isOpen={open}
								onClick={() => {
									setDrawerRef('');
									if (open && onClose) {
										onClose();
									}
								}}
							/>
						</div>
					) : null}
					{children}
				</section>
			</div>
		);
	}
);

Drawer.defaultProps = {
	anchor: 'right',
	children: <></>,
	className: '',
	elevation: 20,
	hideBackdrop: false,
	open: false,
	hasClose: false,
	onClose: () => {},
};

Drawer.propTypes = {
	label: PropTypes.string.isRequired,
	anchor: PropTypes.oneOf(['left', 'right']),
	children: PropTypes.node,
	className: PropTypes.string,
	elevation: PropTypes.number,
	hideBackdrop: PropTypes.bool,
	open: PropTypes.bool,
	hasClose: PropTypes.bool,
	onClose: PropTypes.func,
};

export { Drawer };
