import React from 'react';
import PropTypes from 'prop-types';
import { IconCard } from '../../atoms/icon-card-business-size';

const IconCardListBusinessSize = ({
	cards,
	variant,
	showContentLinks,
	isTimeline,
}) => (
	<div className="flex flex-wrap justify-center gap-6 max-w-[1300px] p-6 mb-8">
		{cards.map((item, index) => (
			<div
				key={`${item.title}${Math.random()}`}
				className={`w-full md:w-1/3 md:min-w-[315px] ${
					cards.length !== 4 ? 'lg:w-1/4' : ''
				} lg:min-w-[310px] xl:min-w-[370px]`}
			>
				<IconCard
					{...item}
					variant={variant === 'Dark' ? 'Light' : 'Default'}
					hasLinks={showContentLinks}
					index={index}
					svgStyles={
						isTimeline
							? 'w-[40px] h-[40px] md:w-[54px] md:h-[54px]'
							: null
					}
				/>
			</div>
		))}
	</div>
);
IconCardListBusinessSize.defaultProps = {
	variant: 'Default',
	showContentLinks: true,
	isTimeline: false,
};

IconCardListBusinessSize.propTypes = {
	showContentLinks: PropTypes.bool,
	variant: PropTypes.string,
	isTimeline: PropTypes.bool,
	cards: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.string.isRequired,
			linkCopy: PropTypes.string,
			link: PropTypes.string,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
};

export { IconCardListBusinessSize };
