// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import {
	renderMarkRule,
	renderNodeRule,
	StructuredText,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import * as compassStyles from './index.module.css';

const HeroCompassCA = ({ heroContent, className }) => {
	const { mainHeaderTitle, mainHeaderContent, mainHeaderImage } = heroContent;

	const customHighlightMarkRule = renderMarkRule(
		'highlight',
		({ children, key }) => (
			<span
				key={key}
				style={{ color: '#E30138', backgroundColor: 'transparent' }}
			>
				{children}
			</span>
		)
	);

	const customNodeRules = [
		renderNodeRule(
			isParagraph,
			({ adapter: { renderNode }, node, children, key, ancestors }) => {
				if (node.children[0].type === 'inlineItem') {
					return (
						<React.Fragment key={key}>{children}</React.Fragment>
					);
				}
				if (isRoot(ancestors[0])) {
					return renderNode('p', { key, className: '' }, children);
				}
				return <React.Fragment key={key}>{children}</React.Fragment>;
			}
		),
	];

	return (
		<div className={`text-[#1F2E7A] ${className}`}>
			<div
				className={`flex flex-col sm:flex-row gap-7 mt-8 sm:mt-12 text-left font-castledown-regular text-[#1F2E7A] sm:items-center xl:items-center xl:ml-5 xl:mt-0 2xl:px-[13.5rem]  2xl:items-center ${compassStyles.macHeroCompass}`}
			>
				<div
					className={`order-last flex flex-col gap-5 font-medium sm:order-first sm:w-fit lg:gap-8 ${compassStyles.mainHeroContent}`}
				>
					{/* Left Panel */}
					<div
						className={`text-[#E30138] text-[30px] lg:text-5xl xl:text-[48px] ${compassStyles.macHeroH1}`}
					>
						<StructuredText
							data={mainHeaderTitle}
							customNodeRules={customNodeRules}
							customMarkRules={[customHighlightMarkRule]}
						/>
					</div>
					<div
						className={`text-[18px] leading-6 lg:text-2xl xl:text-[28px] xl:leading-10 ${compassStyles.macHeroH2}`}
					>
						<StructuredText
							data={mainHeaderContent}
							customNodeRules={customNodeRules}
							customMarkRules={[customHighlightMarkRule]}
						/>
					</div>
				</div>
				<div
					className={`-mr-5 xl:m-0 lg:w-[90%] xl:w-full 2xl:w-full 2xl:-mr-7 ${compassStyles.macHeroImg}`}
				>
					<img
						// src={heroImg}
						src={mainHeaderImage.url}
						alt=""
						className="w-full h-auto max-h-[25rem] object-contain"
					/>
				</div>
			</div>
		</div>
	);
};

export default HeroCompassCA;
 