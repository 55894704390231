// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GLULayout } from '../../layouts/glu';
import TiltedCardContainer from '../../components/glu/organisms/tilted-card';
import PageSection from '../../components/glu/organisms/page-section';
import { Head } from '../../components/templates/head';
import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../../utils/url-helpers';
import { MainHeader } from '../../components/molecules/main-header';
import GluDownloadContainer from '../../components/glu/organisms/download-container';
import { BannerNav } from '../../components/glu/organisms/banner-nav';
import useFindCookie from '../../hooks/useCookie';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import Spinner from '../../assets/spinner.inline.svg';

const Gossip = ({ data, location }) => {
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];
	const { locale, eLearningSessionCards, eLearningTitle, downloadHeader } =
		data.datoCmsGluGossip;
	const metaContent = {
		noIndex: data.datoCmsGluGossip.noIndex,
		noFollow: data.datoCmsGluGossip.noFollow,
		metaInformation: data.datoCmsGluGossip.seoMeta,
		fallbackTitle: data.datoCmsGluGossip.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsGluGossip?.canonicalUrl || location?.href,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.gluGossip,
			location,
		}),
		image: getImageSeoSchema(mainLogo?.url),
	};

	const headerCTAContent = {
		...data.datoCmsGluGossip.hero,
		header: data.datoCmsGluGossip.hero.header.value.document.children,
	};
	const bannerTitle = 'Love it, Share it';
	const { locale: urlLocale } = data.datoCmsGluGossip;
	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{bannerTitle && <BannerNav title={bannerTitle} />}
			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							<MainHeader
								urlLocale={urlLocale}
								{...headerCTAContent}
								glu
								logo={mainLogo}
							/>
							<PageSection
								theme="White"
								logo={mainLogo}
								titleBlock={downloadHeader}
							>
								<GluDownloadContainer data={data} />
							</PageSection>
							<PageSection
								titleBlock={eLearningTitle.value}
								logo={mainLogo}
								className="pb-4"
							>
								<TiltedCardContainer
									cards={eLearningSessionCards}
									className="pb-0"
									locale={locale}
								/>
							</PageSection>
						</>
					) : (
						<div className="flex flex-row justify-center py-10  items-center w-full">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
								/>
							</div>
						</div>
					)}
				</>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedGossip = ({ data, location }) => (
	<GLULayout locale={data.datoCmsGluGossip.locale}>
		<Gossip data={data} location={location} />
	</GLULayout>
);
export default WrappedGossip;

export const query = graphql`
	query gluGossip($locale: String! = "en") {
		datoCmsGluGossip(locale: { eq: $locale }) {
			locale
			noIndex
			noFollow
			seoMeta {
				title
				description
				image {
					url
				}
				twitterCard
			}
			downloadHeader {
				value
			}
			hero {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			eLearningTitle {
				value
			}
			eLearningSessionCards {
				title
				image {
					gatsbyImageData
					alt
				}
				description {
					value
				}
				ctaLink
				ctaText
			}
		}
		allDatoCmsGluLogo {
			nodes {
				mainLogo {
					url
					alt
				}
			}
		}
		allDatoCmsGluDownload(limit: 1, filter: { locale: { eq: $locale } }) {
			totalCount
		}
	}
`;
