import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { IconCardList } from '../../molecules/icon-card-list-vap1';
import * as headingStyles from '../../molecules/safecheck-text-image-container/index.module.css';
import { titleProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';

const IconCards = ({
	id,
	variant,
	showContentLinks,
	iconCardsContent,
	containerClassName,
	wrapperClassName,
	borderBottom,
	borderClassNames,
	isTimeline,
	cardtitle,
	as,
	headerType,
}) => {
	const Component = as || 'h1';
	const { uspCards } = iconCardsContent;
	const titleText =
		headerType === 'simple'
			? cardtitle
			: createHeadingHighlight({
					headings: cardtitle[0]?.children,
					headingStyles,
			  });

	return (
		<section
			className={clsx(
				variant === 'Dark'
					? 'bg-blue-500 py-lg-f md:py-2xl-f'
					: 'mt-lg-f lg:mt-2xl-f',
				containerClassName
			)}
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'pl-4 mx-auto md:px-8 max-w-m-screen flex flex-col gap-5 ',
					wrapperClassName
				)}
			>
				{headerType === 'simple' ? (
					<div className="px-4 mx-auto max-w-m-screen">
						<Component
							id={id}
							className={clsx(
								'!leading-[1.3] font-castledown-regular text-3xl md:text-5xl',
								headingStyles.headingContainer
							)}
						>
							{titleText}
						</Component>
					</div>
				) : (
					<div
						className={clsx(
							'flex flex-col shrink !pl-[-40px] mb-5 w-full md:w-auto md:text-center pb-10',
							headingStyles.headingContainer
						)}
					>
						<Component
							id={id}
							className={clsx(
								'!leading-[1.3] font-castledown-regular md:!ml-[-16px]',
								'text-3xl md:text-5xl'
							)}
						>
							{titleText}
						</Component>
					</div>
				)}
				<div className="-ml-[8px] -mt-[8px]">
					<IconCardList
						cards={uspCards}
						variant={variant}
						showContentLinks={showContentLinks}
						isTimeline={isTimeline}
					/>
				</div>
			</div>

			{borderBottom ? <hr className={clsx(borderClassNames)} /> : null}
		</section>
	);
};

IconCards.defaultProps = {
	id: 'hr-services',
	showContentLinks: true,
	variant: 'Default',
	as: 'h1',
	containerClassName: '',
	wrapperClassName: '',
	borderBottom: false,
	borderClassNames: '',
	isTimeline: false,
	headerType: 'complex',
};

IconCards.propTypes = {
	id: PropTypes.string,
	variant: PropTypes.string,
	as: PropTypes.string,
	isTimeline: PropTypes.bool,
	showContentLinks: PropTypes.bool,
	iconCardsContent: PropTypes.shape({
		title: PropTypes.oneOfType([titleProp, PropTypes.string]),
		subText: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	containerClassName: PropTypes.string,
	wrapperClassName: PropTypes.string,
	borderBottom: PropTypes.bool,
	borderClassNames: PropTypes.string,
	cardtitle: titleProp.isRequired,
	headerType: PropTypes.string,
};

export { IconCards };
