import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { sendTrackingData } from '../../../utils/index';

import { PBSLink } from '../link';
import { buildLink, getLocale } from '../../../utils/locale';

const variantsWrapper = {
	Default:
		'bg-brand-pale-400 hover:bg-blue-500 hover:text-white hover:border-blue-500 focus-within:outline-blue-400/[0.20]',
	Light: 'bg-white hover:bg-brand-pale-500 focus-within:outline-white/[0.60]',
};

const variantsCTA = {
	Default: 'group-hover:text-white',
	Light: 'text-blue-400 group-hover:text-blue-500',
};

const variantsRingOutter = {
	Default: 'ring-blue-500/[0.08] group-hover:ring-white/[0.20]',
	Light: 'ring-blue-500/[0.08]  group-hover:ring-blue-500/[0.08]',
};

const variantsRingInner = {
	Default: 'ring-blue-500/[0.15] group-hover:ring-white/[0.60]',
	Light: 'ring-blue-500/[0.15] group-hover:ring-blue-500/[0.15]',
};

const IconCard = ({
	hasLinks,
	title,
	content,
	linkCopy,
	link,
	icon,
	variant,
	className,
	index,
	svgStyles,
}) => {
	let iconSrc;

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	if (icon) {
		iconSrc =
			// eslint-disable-next-line import/no-dynamic-require, global-require
			require(`!!file-loader!../../../assets/${icon}.inline.svg`).default;
	}

	const currVariant = variant || 'Default';

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('component_interaction', {
			component_name: 'icon-card',
			click_type: 'link',
			click_title: title,
			click_text: linkCopy,
			click_index: index + 1,
		});
	};

	return (
		<article
			className={clsx(
				variantsWrapper[currVariant],
				'group',
				'relative text-center',
				'w-full h-full',
				'px-s-f py-base-f md:px-xs-f rounded-sm',
				'transition-colors duration-200 motion-reduce:transition-none',
				'border-2 border-blue-300',
				'focus-within:outline focus-within:outline-8',
				!hasLinks && 'pointer-events-none',
				className
			)}
		>
			{iconSrc ? (
				<div
					aria-hidden
					className={clsx(
						variantsRingOutter[currVariant],
						`inline-flex bg-white md:ring-[16px] ring-[10px] mt-xxs-f md:mt-xs-f rounded-full`
					)}
				>
					<div
						className={clsx(
							variantsRingInner[currVariant],
							`inline-flex bg-white md:ring-[8px] ring-[5px] rounded-full md:p-s-f p-xs-f text-blue-400`
						)}
					>
						<SVG
							src={iconSrc}
							className={clsx(
								'bg-white w-[30px] h-[30px] md:w-[40px] md:h-[40px]',
								svgStyles
							)}
						/>
					</div>
				</div>
			) : null}
			<h3 className="mt-6 text-l md:text-lg-f mb-xs-f md:mt-9">
				{title}
			</h3>
			<p className="text-base-f md:text-base-f font-centra-light">
				{content}
			</p>

			{hasLinks && link && linkCopy ? (
				<div className="border-blue-300 md:pt-s-f md:mt-base-f pt-s-f mt-s-f border-t-1">
					<PBSLink
						to={buildLink(locale, link)}
						variant="Link"
						onClick={handleClickTracking}
						className={clsx(
							variantsCTA[currVariant],
							'!font-centra-book text-base-f md:text-base-f break-words',
							'before:absolute before:top-0 before:left-0 before:w-full before:h-full !px-2 !focus:outline-none focus:shadow-none'
						)}
					>
						{linkCopy}
					</PBSLink>
				</div>
			) : null}
		</article>
	);
};

IconCard.defaultProps = {
	variant: 'Default',
	hasLinks: true,
	className: '',
	svgStyles: '',
	linkCopy: '',
	link: '',
	index: 1,
};

IconCard.propTypes = {
	hasLinks: PropTypes.bool,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	linkCopy: PropTypes.string,
	link: PropTypes.string,
	icon: PropTypes.string.isRequired,
	variant: PropTypes.string,
	className: PropTypes.string,
	svgStyles: PropTypes.string,
	index: PropTypes.number,
};

export { IconCard };
