import React from 'react';
import PropTypes from 'prop-types';
import { vapCalculatedProp, refProp } from '../../../types';

import FullCover from './FullCover';
import HealthSafety from './HealthSafety';
import EmploymentServices from './EmploymentServices';

const VapCardUK = ({
	priceCalculated,
	drawerRef,
	showLargeBusiness,
	panel,
}) => (
	<section className="lg:px-4 lg:pr-7">
		{panel === 'fully-covered' && (
			<FullCover
				priceCalculated={priceCalculated}
				drawerRef={drawerRef}
				showLargeBusiness={showLargeBusiness}
			/>
		)}
		{panel === 'health-safety' && (
			<HealthSafety
				priceCalculated={priceCalculated}
				drawerRef={drawerRef}
				showLargeBusiness={showLargeBusiness}
			/>
		)}
		{panel === 'employment-services' && (
			<EmploymentServices
				priceCalculated={priceCalculated}
				drawerRef={drawerRef}
				showLargeBusiness={showLargeBusiness}
			/>
		)}
	</section>
);

VapCardUK.defaultProps = {
	priceCalculated: {
		lateness_absence: '-',
		staff_turnover: '-',
		hr_docs: '-',
		hs_docs: '-',
		disciplinary: '-',
		ohs_fines: '-',
		general_hr: '-',
		cost_accidents: '-',
		average_wrongful: '-',
		cost_hr_manager: '-',
		cost_hs_manager: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
		time_saved: '-',
	},
};

VapCardUK.propTypes = {
	priceCalculated: vapCalculatedProp,
	drawerRef: refProp.isRequired,
	showLargeBusiness: PropTypes.bool.isRequired,
	panel: PropTypes.string.isRequired,
};

export { VapCardUK };
