import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { refProp } from '../../../types';

import UnitedKingdom from '../../../assets/flags/united-kingdom.inline.svg';
import Ireland from '../../../assets/flags/ireland.inline.svg';
import Canada from '../../../assets/flags/canada.inline.svg';
import Australia from '../../../assets/flags/australia.inline.svg';
import NewZealand from '../../../assets/flags/new-zealand.inline.svg';

import { Title } from '../../atoms/title';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';

import { Tabs, TabList, TabPanel } from '../tabs';
import { AddressList } from '../../molecules/address-list';
import { EnquireForm } from '../enquire-form';

const getIcon = (icon) => {
	let iconComponent = null;
	switch (icon) {
		case 'united-kingdom':
			iconComponent = <UnitedKingdom className="mr-3" />;
			break;
		case 'ireland':
			iconComponent = <Ireland className="mr-3" />;
			break;
		case 'canada':
			iconComponent = <Canada className="mr-3" />;
			break;
		case 'australia':
			iconComponent = <Australia className="mr-3" />;
			break;
		case 'new-zealand':
			iconComponent = <NewZealand className="mr-3" />;
			break;
		default:
			iconComponent = null;
			break;
	}
	return iconComponent;
};

const ContactUs = ({ drawerRef, contactusContent, enquireOnlineContent }) => {
	const { setDrawerRef } = useDrawer();
	const { heading, subHeading, countryTabsTitle, countryTabs } =
		contactusContent;

	const refinedCountryTabs = countryTabs.map((tab) => ({
		...tab,
		tabTitle: tab.name,
		tabIcon: getIcon(tab.icon),
	}));

	const ContactFormCta = (className) => (
		<div
			className={clsx('flex flex-col items-center lg:hidden', className)}
		>
			<Button onClick={() => setDrawerRef(drawerRef)}>
				Contact Peninsula online
			</Button>
		</div>
	);

	return (
		<section className="relative w-full">
			<div className="max-w-screen-xl px-3 py-16 mx-auto">
				<Title title={heading} subtitle={subHeading}>
					{ContactFormCta('mb-10')}
				</Title>

				<Tabs tabsTitle={countryTabsTitle} className="mb-10 lg:mb-0">
					<div className="mb-16 lg:container lg:mx-auto">
						<TabList
							title={countryTabsTitle}
							options={refinedCountryTabs}
							className="rounded-t-sm rounded-b-sm shadow-slight md:rounded-l-lg md:rounded-r-lg"
						/>
					</div>
					{refinedCountryTabs.map((panel, index) => {
						const tabId = panel.tabTitle
							.replaceAll(' ', '-')
							.toLowerCase();

						return (
							<TabPanel
								key={tabId}
								tabId={`${tabId}-tab`}
								tabPanelId={`${tabId}-panel`}
								tabPanelIndex={index}
							>
								<section className="col-span-12 lg:col-span-7">
									<AddressList addressListContent={panel} />
								</section>
								<section className="hidden col-span-12 lg:block lg:col-span-5 lg:ml-6">
									<EnquireForm
										id={tabId}
										enquireOnlineContent={
											enquireOnlineContent
										}
									/>
								</section>
							</TabPanel>
						);
					})}
				</Tabs>

				{ContactFormCta()}
			</div>
		</section>
	);
};

export { ContactUs };

ContactUs.propTypes = {
	drawerRef: refProp.isRequired,
	contactusContent: PropTypes.shape({
		heading: PropTypes.string.isRequired,
		subHeading: PropTypes.string,
		countryTabsTitle: PropTypes.string.isRequired,
		countryTabs: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				icon: PropTypes.string,
			})
		).isRequired,
	}).isRequired,
	enquireOnlineContent: PropTypes.shape({
		locationFormTitle: PropTypes.string.isRequired,
		locationFormSubText: PropTypes.string,
		locationFormSuccessTitle: PropTypes.string.isRequired,
		locationFormSuccessSubText: PropTypes.string,
	}).isRequired,
};
