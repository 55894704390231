// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';
import { Head } from '../components/templates/head';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { CardContainer } from '../components/organisms/card-container-press';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Title } from '../components/atoms/title';
import { Drawer } from '../components/atoms/drawer';

import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { useFilters } from '../hooks/filters';

import { parseDatoLocale } from '../utils/locale';

import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { HrefLang } from '../components/templates/hrefLang';

const ViewAllBlogsPage = ({
	data,
	categoryOriginalIds,
	location,
	parentPageData,
	locale,
}) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const pressArticleQuery = ({
		queryName,
		filter,
		skip,
	}) => `query allBlogs($locale: SiteLocale = ${
		parseDatoLocale[locale] || 'en_CA'
	}) {
		${queryName}(filter: {categories: {anyIn: [${categoryOriginalIds}]}, slug: {neq: null}}, first: 12, orderBy: ${filter}, skip: ${skip}, locale: $locale, fallbackLocales: ${
		parseDatoLocale[locale] || 'en'
	})  {
				id
				title
				slug
				intro
				publishedDate: created
				publishedDateOrdinal: created
				thumbnail {
					responsiveImage(imgixParams: {fm: jpg, fit: crop, w: 500, h: 300, ar: "43:21"}, sizes:"(max-width: 640px) 400px, 800px") {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						alt
					}
				}	
				logo{
					responsiveImage(imgixParams: {fm: jpg,  w: 372, h: 45, ar: "43:21"}, sizes:"(max-width: 372px) 372px, 45px") {
						srcSet
						webpSrcSet
						sizes
						src
						width
						height
						alt
					}
				}	
		}
	}`;

	const {
		loading,
		error,
		pagination,
		setPagination,
		filter,
		setFilter,
		items,
	} = useFilters({
		queryName: 'allPressRadioPages',
		query: pressArticleQuery,
		data: data?.allDatoCmsPressRadioPage,
		itemLimit: 12,
	});

	const metaContent = {
		noIndex: data.datoCmsAllPressradiosPage.noIndex,
		noFollow: data.datoCmsAllPressradiosPage.noFollow,
		metaInformation: data.datoCmsAllPressradiosPage.metaInformation,
		fallbackTitle: data.datoCmsAllPressradiosPage.title,
		fallbackDescription: data.datoCmsAllPressradiosPage.longIntroduction,
		canonicalUrl:
			data.datoCmsAllPressradiosPage?.canonicalUrl || location.href,
	};

	const pressRadioContainerContent = {
		totalCount: data.allDatoCmsPressRadioPage.totalCount,
		cards: items,
	};

	const pressArticleContainerContent = {
		totalCount: data.allDatoCmsPressArticlePage.totalCount,
		cards: data.allDatoCmsPressArticlePage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.allBlogs,
			location,
			data: data.datoCmsAllPressradiosPage,
			parentPageData,
		}),
	};

	const breadCrumbItems = getBreadcrumbs(
		'datoCmsAllPressradiosPage',
		data.datoCmsAllPressradiosPage,
		parentPageData
	);

	const lowerCaseParentTitle = parentPageData?.title.toLowerCase();
	const parentPageDataExists =
		parentPageData?.title !== null && parentPageData?.title !== undefined;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsAllPressradiosPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title="Radio"
				className="!text-left px-3 mx-auto max-w-m-screen lg:px-8"
			/>
			<CardContainer
				variant="ViewAllFilters"
				cardContainerContent={{
					...pressRadioContainerContent,
					id: 'blog-container',
					title: 'Blog articles',
					buttonCopy: `View all ${lowerCaseParentTitle} blogs`,
					viewAllTextOverride: parentPageDataExists,
				}}
				cardVariant="PressArticle"
				loading={loading}
				error={error}
				filter={filter}
				setFilter={setFilter}
				setPagination={setPagination}
				pagination={pagination}
			/>

			{pressArticleContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollableArticle"
					cardContainerContent={{
						...pressArticleContainerContent,
						id: 'pressarticle-container',
						title: 'Latest articles',
						toAll: `../articles`,
					}}
					cardVariant="PressArticle"
				/>
			)}
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query ($categoryIds: [String!]!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsAllPressradiosPage(locale: { eq: $locale }) {
			originalId
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			id
			internal {
				type
			}
			noIndex
			noFollow
			canonicalUrl
			heading
			categoryGroupTitle
			categoryGroups {
				originalId
				id
				internal {
					type
				}
				slug
				title
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				categories {
					originalId
					id
					internal {
						type
					}
					slug
					name
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
		allDatoCmsPressRadioPage(
			sort: { order: DESC, fields: created }
			limit: 12
			filter: {
				categories: { elemMatch: { id: { in: $categoryIds } } }
				slug: { ne: null }
				locale: { eq: $locale }
			}
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				logo {
					alt
					gatsbyImageData(width: 372, height: 45)
				}
				sectors {
					id
					name
				}
			}
		}

		allDatoCmsPressArticlePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: { slug: { ne: null }, locale: { eq: $locale } }
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				logo {
					alt
					gatsbyImageData(width: 372, height: 45)
				}
				sectors {
					id
					name
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedHome = ({ data, pageContext, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<ViewAllBlogsPage
			data={data}
			location={location}
			categoryOriginalIds={pageContext.categoryOriginalIds}
			parentPageData={pageContext.parentPageData}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedHome;
