import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { CallUs } from '../../atoms/call-us-bdm';
import { Logo } from '../../atoms/logo-ppc';
import { SideNav } from '../../molecules/side-nav-v2';

import { sendTrackingData } from '../../../utils';

const Header = ({
	siteTitle,
	isStorybook,
	headerContent,
	mobileOnlyHamburger,
	homepageUrl,
	bdmNumber,
	bdmEmail,
}) => {
	const navRef = useRef(null);
	const { width } = useScreenResizer();
	const [sideLinks, setSideLinks] = useState([]);

	if (!isStorybook) {
		useEffect(() => {
			if (width > 1024) {
				setSideLinks(headerContent.sideNavigation);
			} else {
				setSideLinks([
					headerContent.primaryNavigation,
					headerContent.sideNavigation,
				]);
			}
		}, [width, headerContent]);
	}

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', trackData);
	};

	return (
		<header className="bg-white">
			<section className="relative flex items-center justify-between w-full gap-2 p-4 mx-auto max-w-m-screen">
				<div className="flex items-center header-left">
					<Logo
						width={126}
						height={36}
						title={siteTitle}
						url={homepageUrl}
					/>
				</div>
				<div className="flex items-center header-right">
					<CallUs
						number={bdmNumber}
						email={bdmEmail}
						className={`text-right ${
							!mobileOnlyHamburger ? '' : 'lg:border-none'
						}  border-brand-pale-500 focus:outline-0 focus:shadow-focus focus:rounded-xs`}
						onClickProp={() =>
							handleClickTracking({
								event_name: 'navigation',
								click_type: 'tel',
							})
						}
					/>
					<div
						className={`${
							!mobileOnlyHamburger ? 'block' : 'lg:hidden'
						}`}
					/>
				</div>
			</section>
			<SideNav
				navRef={navRef}
				links={isStorybook ? headerContent.sideNavigation : sideLinks}
				signInCopy={headerContent.signInCopy}
			/>
		</header>
	);
};

Header.defaultProps = {
	siteTitle: 'Peninsula Homepage',
	isStorybook: false,
	mobileOnlyHamburger: false,
	homepageUrl: '/',
	bdmNumber: '',
	bdmEmail: '',
};

Header.propTypes = {
	siteTitle: PropTypes.string,
	isStorybook: PropTypes.bool,
	headerContent: PropTypes.shape({
		primaryNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						subText: PropTypes.string.isRequired,
						link: PropTypes.string.isRequired,
						icon: PropTypes.string.isRequired,
					})
				),
			})
		).isRequired,
		sideNavigation: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			),
			PropTypes.arrayOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						link: PropTypes.string,
						treeChildren: PropTypes.arrayOf(
							PropTypes.shape({
								title: PropTypes.string.isRequired,
								subText: PropTypes.string.isRequired,
								link: PropTypes.string.isRequired,
								icon: PropTypes.string.isRequired,
							})
						),
					})
				),
				PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				)
			),
		]),
		sideNavigationOpenCopy: PropTypes.string.isRequired,
		sideNavigationCloseCopy: PropTypes.string.isRequired,
		signInCopy: PropTypes.string,
	}).isRequired,
	mobileOnlyHamburger: PropTypes.bool,
	homepageUrl: PropTypes.string,
	bdmNumber: PropTypes.number,
	bdmEmail: PropTypes.string,
};

export { Header };
