import React from 'react';
import PropTypes from 'prop-types';
import { TextWithImage } from '../../atoms/text-with-image-vap';

import { titleProp } from '../../../types';

const TextWithImageContainer = ({ textWithImageContainerContent }) => {
	const { id } = textWithImageContainerContent;
	const textWithImages = [textWithImageContainerContent];

	return (
		<section
			className="px-4 mx-auto md:px-8 max-w-m-screen my-xl-f xl:my-2xl-f"
			aria-labelledby={id}
		>
			{textWithImages.map((elContent, index) => {
				let flipSecond = false;
				if (textWithImages.length > 1) {
					flipSecond = true;
				}
				return (
					<TextWithImage
						// eslint-disable-next-line react/no-array-index-key
						key={`${id}${index}`}
						textWithImageContent={elContent}
						{...(flipSecond && index === 1 ? { flip: true } : null)}
					/>
				);
			})}
		</section>
	);
};

TextWithImageContainer.propTypes = {
	textWithImageContainerContent: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.oneOfType([titleProp, PropTypes.string]),
	}).isRequired,
};

export { TextWithImageContainer };
