const convertDate = (date) => {
	const newDate = date.replace(/([:,\-,+])/gm, '');
	return newDate.substring(0, newDate.length - 4);
};

export const makeIcsFile = ({
	location,
	startDateTime,
	endDateTime,
	description,
	title,
	shortDescription,
}) => {
	const isBrowser = typeof window !== 'undefined';
	let icsFile = null;
	// Dont try to format this ics template. it needs to be butt up to the left otherwise Outlook doesn't read it.
	const ics = `BEGIN:VCALENDAR
CALSCALE:GREGORIAN
VERSION:2.0
BEGIN:VEVENT
LOCATION:${location}
DTSTART;TZID=Europe/London:${convertDate(startDateTime)}
DTEND;TZID=Europe/London:${convertDate(endDateTime)}
DESCRIPTION:${description}
${
	shortDescription &&
	`
X-ALT-DESC;FMTTYPE=text/html:<!doctype html><html><body>${shortDescription}</body></html>`
}
SUMMARY:${title}
STATUS:CONFIRMED
END:VEVENT
END:VCALENDAR`;

	if (isBrowser) {
		const data = new File([ics], { type: 'text/plain' });
		if (icsFile !== null) {
			window.URL.revokeObjectURL(icsFile);
		}

		icsFile = window.URL.createObjectURL(data);

		return icsFile;
	}
};
