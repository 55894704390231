import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import loadScript from 'load-script2';

import { useLocation } from '@reach/router';
import { Button } from '../../../atoms/button';
import { TextInput } from '../../../atoms/text-input';
import { CallUs } from '../../../atoms/call-us';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { PBSLink } from '../../../atoms/link';

import { sleep, sendTrackingData, getCookie } from '../../../../utils';
import {
	validateString,
	validateJobTitle,
	validateEmail,
	validatePhoneNumber,
} from '../../../../utils/marketo';

import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { useMarketo } from '../../../../contexts/marketo-context';
import CountdownTimer from '../../molecules/countdown-timer';
import useCountdown from '../../../../hooks/useCountdown';
import { useFormValidation } from '../../../../hooks/form-validation';
import usePrivacyLink from '../../../../hooks/usePrivacyLink';
import { getLocale } from '../../../../utils/locale';
import { RadioButton } from '../../../atoms/radio-button';
import { Textarea } from '../../../atoms/textarea';

const WebinarSignupForm = ({
	pageUrl,
	dateAndTime,
	setFormResponse,
	formId,
	pbsWebinar,
	pageType,
	richTextField,
	showQuestionOnTopic,
}) => {
	const MktoForms2Instances = useMarketo();
	const initialValidations = {
		FirstName: { isValid: true },
		isClient: { isValid: true },
		CanNumber: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
		JobTitle: { isValid: true },
		discuss: { isValid: true },
	};

	const [technicalError, setTechnicalError] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);
	const url = useLocation();
	const urlLocale = getLocale(url.pathname);
	const { privacyLink } = usePrivacyLink(urlLocale);
	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');
	const [isClient, setIsClient] = useState('no');

	const title = 'Reserve your spot';

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const jobTitleRef = useRef(null);
	const canNumberRef = useRef(null);
	const isClientRef = useRef(null);
	const discussRef = useRef(null);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		Title: '',
		areyouaPeninsulaclient: isClient,
		CanNumber: '',
		discuss: '',
		PersonSource:
			urlLocale === 'ie'
				? 'Website - ROI Confirm Webinar Web Lead'
				: 'Website - Confirm Webinar Web Lead',
		LeadSource:
			urlLocale === 'ie'
				? 'Website - ROI Confirm Webinar Web Lead'
				: 'Website - Confirm Webinar Web Lead',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const submitHandler = async (event) => {
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Phone: formEntries.Phone,
			Email: formEntries.Email,
			Company: formEntries.Company,
			Title: formEntries.JobTitle,
			PersonSource:
				urlLocale === 'ie'
					? 'Website - ROI Confirm Webinar Web Lead'
					: 'Website - Confirm Webinar Web Lead',
		};

		if (showQuestionOnTopic && urlLocale === '') {
			payloadData.Questionsontopic = formEntries.discuss;
			payloadData.questionsontopic = formEntries.discuss;
		}

		if (pageType === 'webinar') {
			payloadData.Client_Account_Number__c =
				isClient === 'yes' ? formEntries.CanNumber : '';
			payloadData.areyouaPeninsulaclient =
				isClient === 'yes' ? 'Yes' : 'No';
		}

		await sleep(500);
		handleClickTracking();
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setFormResponse(true);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
			});
	};

	const { hasExpired } = useCountdown(dateAndTime);

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);

	useEffect(() => {
		if (!validations.FirstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.LastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.Email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (validations.Phone.isValid === false && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.Company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.JobTitle.isValid && jobTitleRef.current) {
			return jobTitleRef.current.focus();
		}

		if (showQuestionOnTopic && urlLocale === '') {
			if (!validations.discuss.isValid && discussRef.current) {
				return discussRef.current.focus();
			}
		}

		if (isClient === 'yes') {
			if (!validations?.CanNumber?.isValid && canNumberRef?.current) {
				return canNumberRef.current.focus();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isClient, validations]);

	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto overflow-hidden border rounded-sm shadow-lg lg:-mt-10 grow border-brand-pale-500"
			>
				{!pbsWebinar ? (
					<div className="flex flex-col p-4 lg:flex-row bg-brand-blue-400">
						<div className="w-full text-white">
							<CountdownTimer targetDate={dateAndTime} />
						</div>
					</div>
				) : (
					<h3 className="w-full p-4 text-2xl text-white bg-brand-blue-400 lg:p-8">
						Secure your spot
					</h3>
				)}
				<div className="flex flex-col grow bg-white">
					{urlLocale === '' && richTextField && (
						<div className="px-4 py-2 font-castledown-bold m-1">
							{' '}
							{richTextField}
						</div>
					)}
					{urlLocale !== 'ca' && pageType === 'webinar' ? (
						<>
							<fieldset className=" mt-5 flex flex-col px-4 pt-5 bg-white lg:pt-6 lg:px-8 grow">
								<legend className=" text-brand-blue-400 text-base-f font-centra-medium">
									Are you a Peninsula client?
								</legend>

								<div className="flex flex-wrap gap-2 ">
									<RadioButton
										defaultChecked={isClient === 'no'}
										labelText="No"
										id="enquireNo"
										name="isClient"
										value={isClient}
										ref={isClientRef}
										onChange={() => setIsClient('no')}
									/>

									<RadioButton
										defaultChecked={isClient === 'yes'}
										labelText="Yes"
										id="enquireYes"
										name="isClient"
										ref={isClientRef}
										value={isClient}
										onChange={() => setIsClient('yes')}
									/>
								</div>
							</fieldset>
							{isClient === 'yes' && urlLocale === 'ie' ? (
								<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-6 lg:px-8 grow">
									<Field
										name="CanNumber"
										validate={validateString}
									>
										{({ field }) => (
											<div className="w-full ">
												<label
													htmlFor="CanNumber"
													className="text-brand-blue-400 lg:text-base-f font-centra-medium"
												>
													Your CAN
												</label>
												<TextInput
													id="CanNumber"
													name="CanNumber"
													aria-required="true"
													className="w-full mb-3 wrapper-small"
													ref={canNumberRef}
													showValidation={
														!validations?.CanNumber
															?.isValid &&
														!canNumberRef?.current
															?.value
													}
													validationMessage={
														!validations?.CanNumber
															?.isValid
															? 'Please enter your CAN'
															: null
													}
													{...field}
													space={false}
													placeholder="CAN123"
													validateOnBlur
												/>
											</div>
										)}
									</Field>
								</fieldset>
							) : null}
						</>
					) : null}
					<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-6 lg:px-8 grow">
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="FirstName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="FirstName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											First name&#42;
										</label>
										<TextInput
											id="FirstName"
											name="FirstName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={firstNameRef}
											showValidation={
												!validations.FirstName
													.isValid &&
												!firstNameRef.current.value
											}
											validationMessage={
												!validations.FirstName.isValid
													? 'Please enter your first name'
													: null
											}
											{...field}
											space={false}
											placeholder={`${
												urlLocale === 'ie'
													? 'Sean'
													: 'Jane'
											}`}
											validateOnBlur
										/>
									</div>
								)}
							</Field>

							<Field name="LastName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="LastName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Last name&#42;
										</label>
										<TextInput
											id="LastName"
											name="LastName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={lastNameRef}
											showValidation={
												!validations.LastName.isValid &&
												!lastNameRef.current.value
											}
											validationMessage={
												!validations.LastName.isValid
													? 'Please enter your last name'
													: null
											}
											{...field}
											space={false}
											placeholder={`${
												urlLocale === 'ie'
													? 'Murphy'
													: 'Smith'
											}`}
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Company" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Company"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Company&#42;
										</label>
										<TextInput
											id="Company"
											name="Company"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={companyRef}
											showValidation={
												!validations.Company.isValid &&
												!companyRef.current.value
											}
											validationMessage={
												!validations.Company.isValid
													? 'Please enter your company'
													: null
											}
											{...field}
											space={false}
											placeholder="Company LTD"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
							<Field name="JobTitle" validate={validateJobTitle}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="JobTitle"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Job title&#42;
										</label>
										<TextInput
											id="JobTitle"
											name="JobTitle"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={jobTitleRef}
											showValidation={
												!validations.JobTitle.isValid
											}
											validationMessage={
												!validations.JobTitle.isValid
													? 'Please enter at least 2 characters'
													: null
											}
											{...field}
											space={false}
											placeholder="HR Manager"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="Email" validate={validateEmail}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Email"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Email&#42;
										</label>
										<TextInput
											id="Email"
											type="email"
											name="Email"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={emailRef}
											showValidation={
												!validations.Email.isValid &&
												!emailRef.current.value
											}
											validationMessage="Please enter your email address"
											{...field}
											space={false}
											placeholder={`${
												urlLocale === 'ie'
													? 'sean.murphy@gmail.com'
													: 'jane.smith@gmail.com'
											}`}
											validateOnBlur
										/>
									</div>
								)}
							</Field>
							<Field name="Phone" validate={validatePhoneNumber}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="Phone"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Phone&#42;
										</label>
										<TextInput
											id="Phone"
											name="Phone"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={phoneNumberRef}
											showValidation={
												!validations.Phone.isValid ===
													true &&
												phoneNumberRef.current
											}
											validationMessage="Please enter a phone number"
											{...field}
											space={false}
											placeholder={
												urlLocale === 'ie'
													? '087 1234 567'
													: '07997 210 007'
											}
											type="tel"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						{urlLocale === '' && showQuestionOnTopic && (
							<Textarea
								labelText="Submit your question for the Q&A below:"
								id="enquiredDiscuss"
								name="discuss"
								rows={6}
								validationMessage="Please enter your message"
								showValidation={!validations.discuss.isValid}
								ref={discussRef}
								maxLength={500}
							/>
						)}
					</fieldset>
				</div>

				<div className="flex flex-col items-center px-8 pt-3 pb-4 bg-white lg:pb-8 lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
							disabled={hasExpired}
						>
							<span
								className={clsx(
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{title}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
					<p className="px-4 pt-4 text-center bg-white lg:text-base-f lg:pt-0">
						View our{' '}
						<PBSLink to={privacyLink} variant="Link" size="Small">
							privacy notice
						</PBSLink>
						.
					</p>
				</div>
			</form>
		</FormikProvider>
	);
};

WebinarSignupForm.defaultProps = {
	setFormResponse: undefined,
	setConsentResponse: undefined,
	formId: 0,
	locale: 'en',
	pbsWebinar: false,
	pageType: 'webinar',
	richTextField: null,
	showQuestionOnTopic: false,
};

WebinarSignupForm.propTypes = {
	setConsentResponse: PropTypes.func,
	pageUrl: PropTypes.string.isRequired,
	dateAndTime: PropTypes.string.isRequired,
	setFormResponse: PropTypes.func,
	formId: PropTypes.number,
	locale: PropTypes.string,
	pbsWebinar: PropTypes.bool,
	pageType: PropTypes.string,
	richTextField: PropTypes.string,
	showQuestionOnTopic: PropTypes.bool,
};

export { WebinarSignupForm };
