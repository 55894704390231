import React from 'react';
import PropTypes from 'prop-types';

const SpeakerLinks = ({ links, className }) => {
	const socialLinks = links.map((link) => (
		<li key={`${link.outlet}SocialLink`}>
			<a
				href={link.href}
				target="_blank"
				aria-label={link.label}
				role="button"
				rel="noreferrer"
			>
				{link.icon}
			</a>
		</li>
	));

	return <ul className={className}>{socialLinks}</ul>;
};

SpeakerLinks.defaultProps = {
	className: '',
};

SpeakerLinks.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			outlet: PropTypes.string,
			href: PropTypes.string,
			label: PropTypes.string,
			icon: PropTypes.element,
		})
	).isRequired,
	className: PropTypes.string,
};

export { SpeakerLinks };
