import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SidebarHeader = ({ heading, text, className, ariaLive }) => (
	<header
		className={clsx(`px-4 pt-10 pb-5 bg-brand-pale-300 h-min`, className)}
		aria-live={ariaLive || 'off'}
	>
		<h2 className="text-xl font-black md:text-xl font-centra-light">
			<div dangerouslySetInnerHTML={{ __html: heading }} />
		</h2>
		{text && text !== '' ? (
			<p className="text-md md:text-md leading-[1.4]">{text}</p>
		) : null}
	</header>
);

SidebarHeader.defaultProps = {
	text: '',
	className: '',
	ariaLive: '',
};

SidebarHeader.propTypes = {
	heading: PropTypes.string.isRequired,
	text: PropTypes.string,
	className: PropTypes.string,
	ariaLive: PropTypes.string,
};

export { SidebarHeader };
