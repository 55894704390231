export const shareContent = async (hasShareContentSetup, content) => {
	if (hasShareContentSetup) {
		try {
			await navigator.share(content);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Error sharing:', err);
		}
	} else {
		// eslint-disable-next-line no-console
		console.error('Web Share API not supported');
	}
};
