import React from 'react';

function usePrivacyLink(locale) {
	const [privacyLink, setPrivacyUrl] = React.useState('');
	React.useEffect(() => {
		const caUrl =
			'https://www.peninsulagrouplimited.com/ca/privacy-policy/';
		const ieUrl =
			'https://www.peninsulagrouplimited.com/ie/privacy-policy/';
		const enUrl =
			'https://www.peninsulagrouplimited.com/legal/privacy-policy/';
		switch (locale) {
			case 'ca':
				setPrivacyUrl(caUrl);
				break;
			case 'ie':
				setPrivacyUrl(ieUrl);
				break;

			default:
				setPrivacyUrl(enUrl);
				break;
		}
	}, [locale]);
	return { privacyLink };
}

export default usePrivacyLink;
