import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function CopyButton({ text, url }) {
	const copyLinkRef = useRef(null);

	const copyLink = () => {
		if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
			const originalValue = copyLinkRef.current.textContent;

			navigator.clipboard.writeText(url);

			copyLinkRef.current.textContent = 'Link copied to clipboard';

			setTimeout(() => {
				copyLinkRef.current.textContent = originalValue;
			}, 1800);
		}
	};
	return (
		<button
			className="flex flex-row space-x-3 mt-5"
			type="button"
			onClick={() => copyLink()}
		>
			<div className="flex items-center gap-5 cursor-pointer">
				<div className="shadow-xl w-[48px] h-[48px] bg-[#3954E0] rounded-full items-center justify-center flex text-white">
					<svg
						width="24"
						height="33"
						viewBox="0 0 24 33"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.75 19.8064C12.3375 19.8064 12 19.4689 12 19.0564C12 18.6439 12.3375 18.3064 12.75 18.3064C13.9875 18.3064 15 17.2939 15 16.0564V13.0564C15 11.8189 13.9875 10.8064 12.75 10.8064H5.25C4.0125 10.8064 3 11.8189 3 13.0564V16.0564C3 17.2939 4.0125 18.3064 5.25 18.3064C5.6625 18.3064 6 18.6439 6 19.0564C6 19.4689 5.6625 19.8064 5.25 19.8064C3.17775 19.8064 1.5 18.1279 1.5 16.0564V13.0564C1.5 10.9849 3.17775 9.3064 5.25 9.3064H12.75C14.8215 9.3064 16.5 10.9849 16.5 13.0564V16.0564C16.5 18.1279 14.8215 19.8064 12.75 19.8064Z"
							fill="white"
						/>
						<path
							d="M18.75 24.3064H11.25C9.1785 24.3064 7.5 22.6279 7.5 20.5564V17.5564C7.5 15.4849 9.1785 13.8064 11.25 13.8064C11.6625 13.8064 12 14.1439 12 14.5564C12 14.9689 11.6625 15.3064 11.25 15.3064C10.0125 15.3064 9 16.3189 9 17.5564V20.5564C9 21.7939 10.0125 22.8064 11.25 22.8064H18.75C19.9875 22.8064 21 21.7939 21 20.5564V17.5564C21 16.3189 19.9875 15.3064 18.75 15.3064C18.3375 15.3064 18 14.9689 18 14.5564C18 14.1439 18.3375 13.8064 18.75 13.8064C20.8215 13.8064 22.5 15.4849 22.5 17.5564V20.5564C22.5 22.6279 20.8215 24.3064 18.75 24.3064Z"
							fill="white"
						/>
					</svg>
				</div>
				<p className="inline-flex items-center text-[15px] font-extrabold gap-5">
					{text}{' '}
					<span
						ref={copyLinkRef}
						className="font-light text-gray-100"
					/>
				</p>
			</div>
		</button>
	);
}

export { CopyButton };

CopyButton.defaultProps = {
	text: '',
	url: '',
};

CopyButton.propTypes = {
	text: PropTypes.string,
	url: PropTypes.string,
};
