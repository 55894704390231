import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { renderNodeRule, StructuredText } from 'react-datocms';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';

import { PBSLink } from '../../atoms/link';

import { eventLocationProp } from '../../../types';

import PinIcon from '../../../assets/map-pin-outline.inline.svg';

const EventMap = ({ location, showHeader }) => {
	const { address, venueLocation } = location;

	const [isGoogleMapsAPILoaded, setIsGoogleMapsAPILoaded] = useState(false);

	const mapConfig = {
		center: {
			lat: venueLocation.latitude,
			lng: venueLocation.longitude,
		},
	};

	const mapUrl = address?.value.document.children[0].children[0].value;

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API,
	});

	const [, setMap] = useState(null);

	const onLoad = useCallback((mapCallback) => {
		setIsGoogleMapsAPILoaded(true);
		setMap(mapCallback);
	}, []);

	const onUnmount = useCallback(() => {
		setMap(null);
	}, []);

	return isLoaded ? (
		<div>
			{address && showHeader ? (
				<h2 className="w-full h-full mb-5 text-2xl md:text-3xl font-castledown-regular">
					Where is it?
				</h2>
			) : null}
			<div
				className="border-2 border-blue-200 rounded-sm aspect-video bg-brand-pale-400"
				aria-hidden="true"
			>
				<GoogleMap
					mapContainerClassName="w-full h-full"
					center={mapConfig.center}
					zoom={16}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{isGoogleMapsAPILoaded ? (
						<Marker
							position={mapConfig.center}
							icon={{
								url: '/images/event-marker.svg',
								scaledSize: new window.google.maps.Size(50, 64),
							}}
						/>
					) : null}
				</GoogleMap>
				{address ? (
					<div className="px-5 border-t-2 border-blue-200 py-7 lg:p-8 lg:flex lg:items-center">
						<div className="mb-7 lg:mb-0">
							<p className="mb-2 text-lg md:text-xl font-centra-medium">
								Address:
							</p>
							<StructuredText
								data={address}
								customNodeRules={[
									renderNodeRule(
										isParagraph,
										({
											adapter: { renderNode },
											children,
											key,
											ancestors,
										}) => {
											if (isRoot(ancestors[0])) {
												return renderNode(
													'p',
													{
														key,
														className:
															'text-lg md:text-xl mb-1 last-of-type:mb-0',
													},
													children
												);
											}
											return (
												<React.Fragment key={key}>
													{children}
												</React.Fragment>
											);
										}
									),
								]}
							/>
						</div>
						<div className="ml-auto lg:pl-5 shrink-0">
							<PBSLink
								to={`https://maps.google.com/?q=${mapUrl}`}
								variant="White"
							>
								<PinIcon
									className="w-[18px] mr-3"
									aria-hidden="true"
								/>
								Get Directions
							</PBSLink>
						</div>
					</div>
				) : null}
			</div>
		</div>
	) : (
		<></>
	);
};

EventMap.defaultProps = {
	showHeader: true,
};

EventMap.propTypes = {
	location: eventLocationProp.isRequired,
	showHeader: PropTypes.bool,
};

export { EventMap };
