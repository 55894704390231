import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as reviewStyles from './index.module.css';

import QuotationMark from '../../../assets/quotation-mark.inline.svg';

import { gatsbyImageProp } from '../../../types';
import { splitParagraphs } from '../../../utils';

const ReviewImageBanner = ({ reviewImageBannerContent }) => {
	const { subText, reviewer, job, profile } = reviewImageBannerContent;
	const profileImageData = getImage(profile);
	const profileImageAlt = profile?.alt || '';

	return (
		<figure
			className={clsx(
				'px-4 py-12',
				'text-white font-centra-book',
				'bg-blue-500 rounded-sm max-w-m-screen',
				'md:px-8'
			)}
		>
			<div className="flex flex-col-reverse md:flex-row">
				<figure className="flex flex-col mx-auto flex-shrink-0 max-w-[250px] h-[320px] border-8 border-white mt-9 md:mr-9 md:mt-0 lg:mr-16">
					<GatsbyImage
						className={clsx('w-full', 'h-full')}
						image={profileImageData}
						quality={80}
						alt={profileImageAlt}
					/>
					<figcaption className="px-2 pt-2 text-black bg-white">
						{reviewer}
					</figcaption>
				</figure>

				<div>
					<QuotationMark
						className="w-8 h-auto pb-4 my-auto md:pb-5"
						aria-hidden="true"
					/>
					<blockquote>
						{splitParagraphs(
							subText,
							clsx('text-lg-f mb-5', reviewStyles.quotationEnd)
						)}
					</blockquote>
					{reviewer ? (
						<figcaption className="mt-7 text-base-f">
							- {reviewer} / {job}
						</figcaption>
					) : null}
				</div>
			</div>
		</figure>
	);
};

ReviewImageBanner.propTypes = {
	reviewImageBannerContent: PropTypes.shape({
		reviewer: PropTypes.string,
		job: PropTypes.string,
		title: PropTypes.string,
		subText: PropTypes.string,
		profile: PropTypes.shape({
			gatsbyImageData: gatsbyImageProp,
			alt: PropTypes.string,
		}),
	}).isRequired,
};

export { ReviewImageBanner };
