import PropTypes from 'prop-types';
import React from 'react';
import Cards from '../custom-trustpilot-card-reviews';
import RatedReview from '../custom-trustpilot-rated-reviews';

const TrustpilotReviews = ({ showRatings, reviews, pageName }) => {
	const ratingReviews = reviews
		.filter((review) => review.pageTag.includes(pageName))
		.map((review) => ({
			id: review.id,
			name: review.personName,
			time: review.date,
			title: review.title,
			review: review.content,
			rating: Number(review.rating),
			source: review.source,
			isVerified: review.isVerified,
		}));
	const ratings = ratingReviews.length;
	return ratingReviews.length > 0 ? (
		<div className="max-w-m-screen mx-auto  p-6">
			<Cards reviews={ratingReviews} />
			{showRatings && <RatedReview reviews={ratings} />}
		</div>
	) : null;
};

TrustpilotReviews.defaultProps = {
	showRatings: false,
	pageName: '',
	reviews: [],
};

TrustpilotReviews.propTypes = {
	showRatings: PropTypes.bool,
	pageName: PropTypes.string,
	reviews: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			time: PropTypes.string,
			title: PropTypes.string,
			review: PropTypes.string,
			rating: PropTypes.number,
			isVerified: PropTypes.bool,
		})
	),
};
export { TrustpilotReviews };
