import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { clsx } from 'clsx';

import { PBSLink } from '../../atoms/link';

import * as feefoStyles from './index.module.css';
// import { getLocale } from '../../../utils/locale';

const merchantIdentifier = (locale) => {
	if (locale === 'en-CA') {
		return 'peninsula-canada';
	}

	if (locale === 'en-IE') {
		return 'peninsula-business-services-ie';
	}

	return 'peninsula-business-services-uk';
};
// console.log('merchant: ', merchantIdentifier());
const getStarRating = ({ rating, className }) => (
	<span
		className={clsx(
			'relative',
			'overflow-hidden',
			'leading-[1]',
			feefoStyles.starRatings,
			className
		)}
	>
		<span
			style={{
				width: `${(rating / 5) * 100}%`,
			}}
			className={clsx(
				'block',
				'absolute',
				'top-0',
				'left-0',
				'leading-[1]',
				feefoStyles.starRatingsRating
			)}
		/>
	</span>
);

const FeefoTestimonials = ({ id, locale }) => {
	if (locale === 'en-IE') {
		return null;
	}
	// Set to the default object from when this was created to avoid CLS issues
	const [finalReviews, setReviews] = useState({
		merchant: {
			review_url: `https://www.feefo.com/en-GB/reviews/${merchantIdentifier(
				locale
			)}`,
		},
		meta: {
			verified_count: 1444,
		},
		rating: {
			rating: 4.8,
		},
	});

	const getData = React.useCallback(async () => {
		try {
			// eslint-disable-next-line compat/compat
			const reviewsApi = await fetch(
				`https://api.feefo.com/api/20/reviews/summary/all?merchant_identifier=${merchantIdentifier(
					locale
				)}`
			);
			const reviews = await reviewsApi.json();
			setReviews(reviews);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	}, [locale]);

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<section
			className="px-6 py-10 text-center bg-white feefo-widget md:bg-transparent md:my-20 md:py-0"
			aria-labelledby={id}
		>
			<div className="container mx-auto">
				<h2 className="sr-only" id={id}>
					Feefo Customer Rating {finalReviews.rating.rating} out of 5
				</h2>
				<div className="md:hidden">
					{getStarRating({
						rating: finalReviews.rating.rating,
						className: 'block mx-auto',
					})}
					<p className="mt-5 text-3xl font-centra-medium">
						<strong>{finalReviews.rating.rating}</strong>/5
					</p>
					<p className="px-10 mt-4 text-xs text-brand-blue-400">
						<strong>Independent Service Rating</strong> based on{' '}
						<strong>{finalReviews.meta.verified_count}</strong>{' '}
						verified reviews.
					</p>
					<div className="flex justify-between pt-4 mt-4 border-t border-grey-500">
						<span>
							<StaticImage
								src="../../../assets/feefo.png"
								alt="FeeFo Logo"
								width={90}
								height={31}
							/>
						</span>
						<PBSLink
							to={finalReviews.merchant.review_url}
							variant="Link"
							className="!text-[13px] font-centra-medium no-underline"
						>
							Read all reviews
						</PBSLink>
					</div>
				</div>
				<div className="hidden md:block">
					<div className="flex items-center justify-center space-x-2">
						<p className="text-2xl">Average Customer Rating:</p>
						{getStarRating({
							rating: finalReviews.rating.rating,
							className: 'inline-flex -mt-1',
						})}
						<p className="pr-4 text-4xl border-r border-grey-400">
							<strong className="inline-block ml-2">
								{finalReviews.rating.rating}
							</strong>
							/5
						</p>
						<StaticImage
							src="../../../assets/feefo.png"
							alt="FeeFo Logo"
							width={120}
							height={42}
						/>
					</div>
					<p className="mt-2 text-xs md:px-0 text-brand-blue-400">
						<strong>Independent Service Rating</strong> based on{' '}
						<strong>{finalReviews.meta.verified_count}</strong>{' '}
						verified reviews.{' '}
						<PBSLink
							to={finalReviews.merchant.review_url}
							variant="Link"
							className="!text-xs"
						>
							Read all reviews
						</PBSLink>
					</p>
				</div>
			</div>
		</section>
	);
};

FeefoTestimonials.defaultProps = {
	id: 'feefo-section-header',
	locale: '',
};

FeefoTestimonials.propTypes = {
	id: PropTypes.string,
	locale: PropTypes.string,
};

export { FeefoTestimonials };
