const replaceRegex = function (regex, replacement) {
	return function (str) {
		return str.replace(regex, replacement);
	};
};

// Regular expressions for Markdown
const codeBlockRegex = /((\n\t)(.*))+/g;
const inlineCodeRegex = /(`)(.*?)\1/g;
const imageRegex = /!\[(.*?)\]\((.*?)\)/g;
const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
const headingRegex = /\n(#+\s*)(.*)/g;
const boldItalicsRegex = /(\*{1,2})(.*?)\1/g;
const strikethroughRegex = /(~{2})(.*?)\1/g;
const blockquoteRegex = /\n(>{1}\s*.+)/g;
const horizontalRuleRegex = /\n(-{3,}|={3,})/g;
const unorderedListRegex = /(?:^|\n)(\s*[-+*]\s+.+)/g;
const orderedListRegex = /(?:^|\n)(\s*[0-9]+\.\s+.+)/g;
const paragraphRegex =
	/\n+(?!<pre>)(?!<h)(?!<ul>)(?!<ol>)(?!<blockquote)(?!<hr)(?!\t)([^\n]+)\n/g;
// Replacer functions for Markdown
const codeBlockReplacer = function (fullMatch) {
	return `\n<pre>${fullMatch}</pre>`;
};
const inlineCodeReplacer = function (fullMatch, tagStart, tagContents) {
	return `<code>${tagContents}</code>`;
};
const imageReplacer = function (fullMatch, tagTitle, tagURL) {
	return `<img src="${tagURL}" alt="${tagTitle}" />`; // Return img tag for image markdown syntax
};
const linkReplacer = function (fullMatch, tagTitle, tagURL) {
	return `<a href="${tagURL}">${tagTitle}</a>`;
};
const headingReplacer = function (fullMatch, tagStart, tagContents) {
	const headingLevel = tagStart.trim().length;
	const fontSize = 1.4 + (headingLevel - 1) * 0.5; // Calculate font size based on heading level
	const style = `font-size: ${fontSize}em; margin-bottom: .4em; color: #e40038;`; // Create style attribute with font size
	return `\n<h${headingLevel} style="${style}">${tagContents}</h${headingLevel}>`; // Return the modified <h> tag
};
const boldItalicsReplacer = function (fullMatch, tagStart, tagContents) {
	return `<${
		tagStart.trim().length === 1 ? 'em' : 'strong'
	}>${tagContents}</${tagStart.trim().length === 1 ? 'em' : 'strong'}>`;
};
const strikethroughReplacer = function (fullMatch, tagStart, tagContents) {
	return `<del>${tagContents}</del>`;
};
const blockquoteReplacer = function (fullMatch) {
	return `\n<blockquote>${fullMatch.trim()}</blockquote>`;
};
const horizontalRuleReplacer = function () {
	return '\n<hr />';
};
const unorderedListReplacer = function (fullMatch) {
	let items = '';
	fullMatch
		.trim()
		.split('\n')
		.forEach((item) => {
			items += `<li>${item.substring(2)}</li>`;
		});
	return `<ul style="list-style-type: disc; padding-left: 2em;">${items}</ul>`;
};
export { unorderedListReplacer };

const orderedListReplacer = function (fullMatch) {
	let items = '';
	fullMatch
		.trim()
		.split('\n')
		.forEach((item) => {
			items += `<li>${item.substring(2)}</li>`;
		});
	return `<ol style="list-style-type: disc; padding-left: 2em; padding-bottom: 0.5rem; margin-bottom: 0.5rem;">${items}</ol>`;
};
const paragraphReplacer = function (fullMatch, tagContents) {
	return `<p>${tagContents}</p>`;
};

// Rules for Markdown parsing (use in order of appearance for best results)
const replaceCodeBlocks = replaceRegex(codeBlockRegex, codeBlockReplacer);
const replaceInlineCodes = replaceRegex(inlineCodeRegex, inlineCodeReplacer);
const replaceImages = replaceRegex(imageRegex, imageReplacer);
const replaceLinks = replaceRegex(linkRegex, linkReplacer);
const replaceHeadings = replaceRegex(headingRegex, headingReplacer);
const replaceBoldItalics = replaceRegex(boldItalicsRegex, boldItalicsReplacer);
const replaceStrikethrough = replaceRegex(
	strikethroughRegex,
	strikethroughReplacer
);
const replaceBlockquotes = replaceRegex(blockquoteRegex, blockquoteReplacer);
const replaceHorizontalRules = replaceRegex(
	horizontalRuleRegex,
	horizontalRuleReplacer
);
const replaceUnorderedLists = replaceRegex(
	unorderedListRegex,
	unorderedListReplacer
);
const replaceOrderedLists = replaceRegex(orderedListRegex, orderedListReplacer);
const replaceParagraphs = replaceRegex(paragraphRegex, paragraphReplacer);

// Fix for tab-indexed code blocks
const codeBlockFixRegex = /\n(<pre>)((\n|.)*)(<\/pre>)/g;
const codeBlockFixer = function (tagContents) {
	// Reduced the number of parameters
	let lines = '';
	tagContents.split('\n').forEach((line) => {
		lines += `${line.substring(1)}\n`;
	});
	return `<pre>${lines}</pre>`;
};
const fixCodeBlocks = replaceRegex(codeBlockFixRegex, codeBlockFixer);

// Replacement rule order function for Markdown
const replaceMarkdown = function (str) {
	return replaceParagraphs(
		replaceOrderedLists(
			replaceUnorderedLists(
				replaceHorizontalRules(
					replaceBlockquotes(
						replaceStrikethrough(
							replaceBoldItalics(
								replaceHeadings(
									replaceLinks(
										replaceImages(
											replaceInlineCodes(
												replaceCodeBlocks(str)
											)
										)
									)
								)
							)
						)
					)
				)
			)
		)
	);
};

// Parser for Markdown (fixes code, adds empty lines around for parsing)
export const parseMarkdownUK = function (str) {
	return fixCodeBlocks(replaceMarkdown(`\n${str}\n`)).trim();
};
