import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { StructuredText, renderNodeRule } from 'react-datocms';
// import { StaticImage } from 'gatsby-plugin-image';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';

const MainHeader = ({ heroHeader, headingStyles, titleText }) => {
	const Component = 'h1';
	return (
		<div className="max-w-m-screen mx-auto grid grid-cols-2 mb-5 md:mb-0">
			<div className="col-span-2 flex-col flex gap-5 pl-2 xl:pb-10 mx-2">
				<Component
					id={heroHeader.id}
					className={clsx(
						'!leading-[1.3] font-castledown-regular',
						'text-2xl md:text-5xl xl:text-6xl',
						headingStyles.heading
					)}
				>
					{titleText}
				</Component>
				<StructuredText
					data={heroHeader.subTitle}
					customNodeRules={[
						renderNodeRule(
							isParagraph,
							({ children, key, ancestors }) => {
								if (isRoot(ancestors[0])) {
									return (
										<p
											className="2xl:text-2xl xl:text-lg text-sm fontcastledown-regular leading-6"
											key={key}
										>
											{children}
										</p>
									);
								}
								return children;
							}
						),
					]}
				/>
				<div className="2xl:text-[16.69px] text-xs  fontcastledown-regular leading-6">
					{heroHeader.heroFooterText}
				</div>
				{/* {heroHeader.termLink && (
					<a
						target="_blank"
						className="2xl:text-[16.66px] text-xs  fontcastledown-bold leading-6 underline"
						href={heroHeader.termLink}
						rel="noreferrer"
					>
						Terms apply*
					</a>
				)} */}
			</div>

			{/* <div className="hidden md:flex col-span-1 mt-16">
				<StaticImage
					alt="referal"
					src="../../../assets/refer-a-business.png"
					placeholder="none"
					objectFit="contain"
					style={{
						width: '100%',
						height: '100%',
					}}
				/>
			</div> */}
		</div>
	);
};

MainHeader.propTypes = {
	heroHeader: PropTypes.string.isRequired,
	headingStyles: PropTypes.isRequired,
	titleText: PropTypes.string.isRequired,
};
export { MainHeader };
