/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import { CallUs } from '../../../atoms/call-us';
import { Logo } from '../../../atoms/logo';

import { sendTrackingData } from '../../../../utils';

const LogoHeader = ({ siteTitle, logoContent, homepageUrl }) => {
	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', trackData);
	};

	return (
		<header className="bg-white">
			<section className="relative flex items-center justify-between w-full gap-2 p-4 mx-auto max-w-m-screen">
				<div className="flex items-center header-left">
					<Logo
						width={126}
						height={36}
						title={siteTitle}
						url={homepageUrl}
					/>
					<div className="max-w-[130px] ml-10">
						<img src={logoContent.url} alt="GLU logo" />
					</div>
				</div>
				<div className="flex items-center header-right">
					<CallUs
						className="pr-2 mr-2 text-right md:mr-6 md:pr-6 focus:outline-0 focus:shadow-focus focus:rounded-xs"
						onClickProp={() =>
							handleClickTracking({
								event_name: 'navigation',
								click_type: 'tel',
							})
						}
					/>
				</div>
			</section>
		</header>
	);
};

export { LogoHeader };
