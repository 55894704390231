import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MegaMenuLink } from '../../atoms/mega-menu-link-ca';

const MegaMenuOneGroup = forwardRef(
	(
		{ links, className, listClassName, hidden, backdrop, ...navAttributes },
		ref
	) => {
		// Sort links based on the predefined order
		const order = [
			'Start-up business',
			'Small business',
			'Medium business',
			'Large business',
		];

		// Sort function to reorder the links array based on the titles in the 'order' array
		const sortedLinks = links.sort(
			(a, b) =>
				order.indexOf(a.title.trim()) - order.indexOf(b.title.trim())
		);

		return (
			<div
				className={clsx(
					'absolute left-0 right-0 z-10 top-full w-screen h-[calc(100vh-136px)]',
					className
				)}
				hidden={hidden}
			>
				{backdrop || null}
				<nav
					ref={ref}
					className={clsx(
						'w-screen bg-white block absolute left-0 pb-10 z-10'
					)}
					{...navAttributes}
				>
					<div className="flex-grow mb-12 border-b-2 border-blue-200" />
					<div
						className={clsx(
							'grid grid-cols-3 gap-[15px] mx-auto max-w-m-screen',
							listClassName
						)}
					>
						{/* Core services */}
						<div className="col-span-3">
							<div className="flex items-center mb-4">
								<p className="ml-4 text-sm text-blue-300">
									For your business
								</p>
								<div className="flex-grow ml-4 mr-16 border-blue-300 border-b-1" />
							</div>
							<div className="grid grid-cols-3">
								{sortedLinks.map((link) => (
									<div
										key={link.title.replace(
											/[^A-Z0-9]/gi,
											''
										)}
									>
										<MegaMenuLink
											to={link.link}
											title={link.title}
											text={link.subText}
											icon={link.icon}
											parentTrackingId={navAttributes[
												'aria-label'
											]?.replace(' menu', '')}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</nav>
			</div>
		);
	}
);

export { MegaMenuOneGroup };

MegaMenuOneGroup.defaultProps = {
	className: '',
	listClassName: '',
	hidden: true,
	backdrop: '',
};

MegaMenuOneGroup.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subText: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
	listClassName: PropTypes.string,
	hidden: PropTypes.bool,
	backdrop: PropTypes.node,
};
