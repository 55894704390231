import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useTabs } from '../../../contexts/tabs-context';
import { TabItem } from './tab-item';

const TabList = ({ title, options, className, onClickProp }) => {
	const { setNumberOfTabs } = useTabs();

	const createTab = (option, index) => {
		const id = option.tabTitle.replaceAll(' ', '-').toLowerCase();

		return (
			<TabItem
				key={id}
				item={option}
				itemIndex={index}
				tabId={`${id}-tab`}
				tabPanelId={`${id}-panel`}
				onClickProp={onClickProp}
			/>
		);
	};

	useEffect(() => {
		setNumberOfTabs(options.length ?? 0);
	}, [setNumberOfTabs, options.length]);

	return (
		<>
			{title ? (
				<h2 className="mb-5 text-xl text-center font-centra-book">
					{title}
				</h2>
			) : null}

			<ul
				role="tablist"
				className={clsx(
					'flex flex-col justify-center md:flex-row',
					className
				)}
			>
				{options.map(createTab)}
			</ul>
		</>
	);
};

TabList.defaultProps = {
	title: null,
	className: '',
	onClickProp: () => {},
};

TabList.propTypes = {
	title: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			tabTitle: PropTypes.string.isRequired,
			tabSubTitle: PropTypes.string,
			tabIcon: PropTypes.node,
		})
	).isRequired,
	className: PropTypes.string,
	onClickProp: PropTypes.func,
};

export { TabList };
