import React from 'react';
import PropTypes from 'prop-types';

import { PBSLink } from '../../atoms/link';
import { Accordion } from '../../atoms/accordion';
import { AccordionGroup } from '../accordion-group';
import ArrowIcon from '../../../assets/arrow.inline.svg';

import { getLocale } from '../../../utils/locale';

const AddressList = ({ addressListContent }) => {
	const { locations } = addressListContent;

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	const accordions = () =>
		locations.map(
			({
				name: city,
				address,
				addressLink,
				addressLinkCopy,
				addressTitle,
				contactDetails,
				image,
			}) => (
				<Accordion
					key={city}
					title={city}
					buttonId={`accordionHead-${city}`}
					contentId={`accordionContent-${city}`}
					titleClassNames="text-2xl md:text-3xl font-castledown-regular"
				>
					<div className="mb-4 text-lg md:grid md:text-xl md:grid-cols-[1fr,fit-content(60%)] md:gap-4 lg:gap-8">
						<div className="mb-10 md:mb-0">
							<p className="mb-4 font-centra-medium">
								{addressTitle}
							</p>
							<p className="mb-4 whitespace-pre-wrap">
								{address}
							</p>

							<PBSLink
								to={addressLink}
								target="_blank"
								variant="LinkWithSVG"
								className="items-baseline"
							>
								{addressLinkCopy}
								<ArrowIcon
									viewBox="0 0 33 34"
									className="w-3 h-auto ml-2 -scale-x-100"
								/>
							</PBSLink>
						</div>

						<div>
							{contactDetails && contactDetails.length > 0
								? contactDetails.map(
										({
											name,
											phoneNumber,
											emailAddress,
										}) => (
											<div key={name} className="mb-5">
												<p>{name}</p>
												<p className="py-2 text-2xl font-centra-medium text-brand-red-400">
													<PBSLink
														as="a"
														to={`tel:${phoneNumber}`}
														className={
															phoneNumber ===
																' 1800 719 247' &&
															locale === 'ie'
																? 'rulertel'
																: 'ruler-tel'
														}
														variant="Unset"
													>
														{phoneNumber}
													</PBSLink>
												</p>
												{emailAddress && (
													<PBSLink
														as="a"
														to={`mailto:${emailAddress}`}
														variant="Link"
													>
														{emailAddress}
													</PBSLink>
												)}
											</div>
										)
								  )
								: null}
						</div>
					</div>
					{image ? <img src={image.url} alt={image.alt} /> : null}
				</Accordion>
			)
		);

	return (
		<section>
			<AccordionGroup accordions={accordions()} />
		</section>
	);
};

AddressList.defaultProps = {};

AddressList.propTypes = {
	addressListContent: PropTypes.shape({
		locations: PropTypes.arrayOf(
			PropTypes.shape({
				locations: PropTypes.arrayOf(
					PropTypes.shape({
						name: PropTypes.string,
						address: PropTypes.string,
						addressLink: PropTypes.string,
						addressLinkCopy: PropTypes.string,
						addressTitle: PropTypes.string,
						contactDetails: PropTypes.arrayOf(
							PropTypes.shape({
								name: PropTypes.string,
								phoneNumber: PropTypes.string,
								emailAddress: PropTypes.string,
							})
						),
						image: PropTypes.shape({
							url: PropTypes.string,
							alt: PropTypes.string,
						}),
					})
				),
			})
		),
	}).isRequired,
};

export { AddressList };
