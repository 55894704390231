import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../atoms/button';
import { CallUs } from '../../atoms/call-us';
import { SidebarHeader } from '../../atoms/sidebar-header';
import { TextInput } from '../../atoms/text-input';

import { useFormValidation } from '../../../hooks/form-validation';
import { useSalesforceApi } from '../../../hooks/salesforce';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../utils';
import { PBSLink } from '../../atoms/link';
import usePrivacyLink from '../../../hooks/usePrivacyLink';

import { useSalesforceAzureApi } from '../../../hooks/salesforce-azure-ca';

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};

const AdviceForm = ({ setReference, setResponse, locale }) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { privacyLink } = usePrivacyLink(locale);
	const sideBarHeaderText =
		'Take the first step towards a <span style="color: #E30138; font-weight: bold;">safer</span> workplace';
	const submitButtonText = 'Call me back';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form-safecheck',
			click_type: 'primary',
			click_title: 'SafeCheck - Book my free advice call',
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: 'SafeCheck - Book my free advice call',
			form_submit_text: submitButtonText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		setSubmitting(true);

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.company,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			leadSource: 'Website – SafeCheck',
			locale,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
		};

		if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		if (locale === 'ca') {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}

		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (locale === 'ca' && response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse(
					`Brilliant, one of our advisors will call you as soon as possible.`
				);
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto grow"
			>
				<SidebarHeader
					heading={sideBarHeaderText}
					text="Book your Health & Safety audit with SafeCheck today."
				/>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-10 bg-white pb-7 grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<TextInput
							labelText="First name"
							id="callbackFirstName"
							name="firstName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your first name"
							showValidation={!validations.firstName.isValid}
							ref={firstNameRef}
						/>

						<TextInput
							labelText="Last name"
							id="callbackLastName"
							name="lastName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your last name"
							showValidation={!validations.lastName.isValid}
							ref={lastNameRef}
						/>

						<TextInput
							labelText={
								locale === 'ca'
									? 'Company'
									: 'Company - optional'
							}
							id="callbackCompany"
							name="company"
							aria-required="false"
							className="w-full wrapper-small"
							validationMessage="Please enter your company name"
							ref={companyRef}
						/>

						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>

						<TextInput
							labelText="Telephone number"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>
					</fieldset>
				</div>

				<p className="mx-6 text-base-f">
					View our{' '}
					<PBSLink to={privacyLink} variant="Link">
						{' '}
						privacy notice{' '}
					</PBSLink>
				</p>

				<div className="px-4 py-12 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

AdviceForm.propTypes = {
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
};

export { AdviceForm };
