// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { DefaultLayout } from '../layouts/default';
import { HrefLang } from '../components/templates/hrefLang';
import { Head } from '../components/templates/head';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';
import { createHeadingHighlight } from '../utils/heading-highlight';
import { FAQ } from '../components/organisms/faq';
import * as headingStyles from '../components/molecules/main-header/index.module.css';
import { MainHeader } from '../components/molecules/main-header-referral';
import { HoverImageCardReferral } from '../components/molecules/hover-image-card-referral';
import Spinner from '../assets/spinner.inline.svg';

const RecommendBusiness = ({ location, data }) => {
	const [iframeQuery, setIframeQuery] = React.useState('');
	const [frameHeight, setFrameHeigth] = React.useState('300px');
	const onMessage = React.useCallback((event) => {
		if (event.origin !== 'https://peninsula.my.salesforce-sites.com')
			return;
		const { data: webdata } = event;
		setFrameHeigth(webdata.message);
		if (typeof window[webdata.func] === 'function') {
			window[webdata.func].call(null, webdata.message);
		}
	}, []);
	if (typeof window !== 'undefined') {
		if (window.addEventListener) {
			window.addEventListener('message', onMessage, false);
		} else if (window.attachEvent) {
			window.attachEvent('onmessage', onMessage, false);
		}
	}
	const metaContent = {
		noIndex: data.datoCmsReferABusiness.noIndex,
		noFollow: data.datoCmsReferABusiness.noFollow,
		metaInformation: data.datoCmsReferABusiness.metaInformation,
		fallbackTitle: data.datoCmsReferABusiness.title,
		fallbackDescription: data.datoCmsReferABusiness.longIntroduction,
		canonicalUrl: data.datoCmsReferABusiness?.canonicalUrl || location.href,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.pricing,
			location,
		}),
	};
	const titleText = createHeadingHighlight({
		headings:
			data.datoCmsReferABusiness.heroHeader[0].title.value.document
				.children[0].children,
		headingStyles,
	});
	const faqContent = {
		title: data.datoCmsReferABusiness.faqContainer.title,
		subText: data.datoCmsReferABusiness.faqContainer.subText,
		questions: data.datoCmsReferABusiness.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};
	const urlLocation = useLocation();
	React.useEffect(() => {
		setIframeQuery(urlLocation.search || '');
	}, [urlLocation.search]);
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsReferABusiness.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<MainHeader
				heroHeader={data.datoCmsReferABusiness.heroHeader[0]}
				headingStyles={headingStyles}
				titleText={titleText}
			/>
			{data.datoCmsReferABusiness.hasIframe && (
				<div className="h-auto max-w-m-screen mx-auto rounded-[24px] md:pr-6 md:pl-3">
					<IframeLoader
						src={`https://peninsula.my.salesforce-sites.com/recommendabusiness${iframeQuery}`}
						width="100%"
						height={frameHeight}
					/>
				</div>
			)}
			{data.datoCmsReferABusiness.referralCard.map((card) => (
				<HoverImageCardReferral card={card} key={card.id} />
			))}

			{data.datoCmsReferABusiness.faqDisplayOnPage && (
				<div className="bg-brand-pale-300 w-full py-1">
					<FAQ faqContent={faqContent} />
				</div>
			)}
		</GoogleReCaptchaProvider>
	);
};

function WrappedRecommendBusiness({ location, data }) {
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			className="mx-auto h-auto bg-white"
			pageName="PeninsulaNow"
		>
			<RecommendBusiness location={location} data={data} />
		</DefaultLayout>
	);
}

export default WrappedRecommendBusiness;

export const query = graphql`
	query recommendBusiness($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsReferABusiness(locale: { eq: $locale }) {
			title
			intro
			slug
			noIndex
			noFollow
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			metaInformation {
				title
				twitterCard
				description
				image {
					url
				}
			}
			canonicalUrl
			heroHeader {
				id
				locale
				title {
					value
				}
				subTitle {
					value
				}
				heroFooterText
				termLink
			}
			hasIframe
			referralCard {
				id
				heading
				locale
				referralCards {
					title
					content
				}
			}
			faqDisplayOnPage
			faqContainer {
				title
				subText
				locale
				id
				internalTitle
				questions {
					id
					question
					locale
					answer {
						value
					}
				}
			}
		}
	}
`;

const IframeLoader = ({ width, height, src }) => {
	const [loading, setLoading] = React.useState(true);
	React.useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1700);
	}, []);
	return (
		<div
			className={clsx(
				'flex items-center justify-center rounded-sm  ',
				loading
					? 'bg-brand-pale-300 border-2 border-brand-pale-400'
					: 'bg-transparent border-0'
			)}
			style={{
				width,
				height,
			}}
		>
			{loading && (
				<Spinner
					width="24"
					height="24"
					fill="currentColor"
					className="ml-2 animate-spin"
				/>
			)}
			<iframe
				id="iframeHandle"
				title="referral"
				src={src}
				scrolling="no"
				// onLoad={() => setTimeout(() => setLoading(false), 2400)}
				className="md:shadow-2xl md:rounded-[24px] bg-transparent"
				style={{
					display: loading ? 'none' : 'block',
					width: '100%',
					height,
				}}
			/>
		</div>
	);
};
