import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useLocation } from '@reach/router';
import { PBSLink } from '../link';
import { Button } from '../button';
import { CallUs } from '../call-us';

import { datoGatsbyImageProp, refProp } from '../../../types';
import { getMultiParagraphText } from '../../../utils';

import { useDrawer } from '../../../contexts/drawer-context';
import { buildLink, getLocale } from '../../../utils/locale';

const CtaBanner = ({
	id,
	title,
	content,
	image,
	link,
	linkCopy,
	ctaOpenForm,
	className,
	headingClassNames,
	drawerRef,
	onClickProp,
}) => {
	const { setDrawerRef } = useDrawer();
	const [hasImage, setHasImage] = useState(false);
	const location = useLocation();
	const locale = getLocale(location?.pathname);
	let alt = '';
	let imageData = {};
	if (image && Object.keys(image).length !== 0) {
		alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
		imageData = getImage(image);
	}

	const contentArr = content.split('\n');
	const paragraphClassNames =
		'text-lg md:text-xl mb-3 last-of-type:mb-0 text-center md:text-left break-words';

	let cta;
	if (link) {
		cta = (
			<PBSLink
				to={buildLink(locale, link)}
				className="text-center"
				onClick={() => {
					onClickProp({
						click_text: linkCopy,
						component_name: 'cta-banner',
					});
				}}
			>
				{linkCopy}
			</PBSLink>
		);
	} else if (ctaOpenForm) {
		cta = (
			<Button
				className="text-center"
				onClick={() => {
					setDrawerRef(drawerRef);
					onClickProp({
						click_text: linkCopy,
						component_name: 'cta-banner',
					});
				}}
			>
				{linkCopy}
			</Button>
		);
	}

	useEffect(() => {
		if (image && Object.keys(image).length !== 0) {
			setHasImage(true);
		}
	}, [image]);

	return (
		<article
			className={clsx(
				'bg-brand-pale-400 p-4 pt-7 lg:p-8 rounded-sm border-blue-200 border-2 md:grid my-14',
				className,
				hasImage ? 'md:grid-cols-[1fr_40%] md:gap-8 lg:gap-16' : null
			)}
		>
			<div
				className={clsx(
					'flex flex-col justify-center',
					hasImage ? null : 'lg:max-w-[80%]'
				)}
			>
				<h2
					id={id}
					className={clsx(
						'mb-5 text-2xl text-center md:text-3xl md:text-left',
						headingClassNames
					)}
				>
					{title}
				</h2>

				{getMultiParagraphText(contentArr, paragraphClassNames, id)}

				<div className="flex flex-col items-center lg:flex-row mt-7">
					{cta ? (
						<>
							{cta}
							<hr
								aria-hidden="true"
								className="hidden lg:block lg:h-full lg:min-h-full lg:mx-7 lg:border-l-1 lg:border-l-blue-300"
							/>
						</>
					) : null}
					<CallUs
						className={clsx('mb-7 lg:m-0', cta ? 'mt-5' : null)}
						altMobile
					/>
				</div>
			</div>
			{hasImage ? (
				<div className="flex flex-col items-center md:items-start">
					<GatsbyImage
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image'
						)}
						image={imageData}
						alt={alt}
					/>
				</div>
			) : null}
		</article>
	);
};

CtaBanner.defaultProps = {
	className: '',
	image: {},
	ctaOpenForm: true,
	onClickProp: () => {},
	headingClassNames: '',
};

CtaBanner.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	image: datoGatsbyImageProp,
	link: PropTypes.string.isRequired,
	linkCopy: PropTypes.string.isRequired,
	className: PropTypes.string,
	headingClassNames: PropTypes.string,
	ctaOpenForm: PropTypes.bool,
	drawerRef: refProp.isRequired,
	onClickProp: PropTypes.func,
};

export { CtaBanner };
