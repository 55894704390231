// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PressInquiry = ({ pressInquiryContent }) => {
	const {
		heading,
		subHeading,
		address1,
		address2,
		address3,
		phone,
		email,
		image,
	} = pressInquiryContent;

	const phoneSrc = `/images/logos/press/press_phone.png`;
	const emailSrc = `/images/logos/press/press_email.png`;

	const imageData = getImage(image);
	const Alt = 'Office pic';

	return (
		<section aria-label="pressInquiry">
			<div className="md:w-[768px] lg:w-auto md:mx-auto max-w-m-screen lg:px-8 pb-4">
				<div>
					<h1 className="pb-1 lg:pb-2 text-2xl-f px-4 lg:px-0">
						{heading}
					</h1>
					<p className="pb-2 lg:pb-4 px-4 lg:px-0 text-base-f lg:text-[20px] leading-[28px]">
						{subHeading}
					</p>
				</div>
				<div className="lg:grid lg:grid-cols-2 border-2 border-blue-200 border-solid rounded-sm">
					<div className="py-6 lg:flex ">
						<div className="m-auto ml-8">
							<div className="pb-8">
								<p className="font-medium text-[20px] lg:text-[30px] leading-[44px] text-[#1F2E7A]">
									{address1}
								</p>
								<p className="font-medium text-[16px] lg:text-[24px] leading-[44px] text-[#1F2E7A]">
									{address2}
								</p>
								<p className="font-medium text-[16px] lg:text-[24px] leading-[44px] text-[#1F2E7A]">
									{address3}
								</p>
							</div>
							<div className="w-4/6 sm:w-3/6 lg:w-full text-blue-200 border-b-2 border-blue-200 border-solid" />
							<div className="mt-6">
								<div className="flex">
									<div>
										<a href={`tel:${phone}`}>
											<img
												src={phoneSrc}
												alt="phone"
												width="18"
												height="20"
											/>
										</a>
									</div>
									<div className="pl-2 -mt-1 text-[16px] lg:text-[20px] leading-[34px] text-[#3954E0] font-semibold">
										<a href={`tel:${phone}`}>{phone}</a>
									</div>
								</div>

								<div className="flex">
									<div className="pt-2">
										<a href={`mailto:${email}`}>
											<img
												src={emailSrc}
												alt="phone"
												width="18"
												height="20"
											/>
										</a>
									</div>
									<div className="pl-2 pb-2 text-[16px] lg:text-[20px] leading-[34px] text-[#3954E0] font-semibold">
										<a href={`mailto:${email}`}>{email}</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="lg:py-8 lg:pr-4">
						<GatsbyImage
							className="w-full"
							image={imageData}
							alt={Alt}
						/>
					</div>
					{/* </div> */}
				</div>
			</div>
		</section>
	);
};

export { PressInquiry };
