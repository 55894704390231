// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { getLocale } from '../../utils/locale';

const getRobotMetaTag = ({ noIndex, noFollow }) => {
	let metaTags = [];

	if (noIndex) {
		metaTags = ['noindex'];
	}

	if (noFollow) {
		metaTags = [...metaTags, 'nofollow'];
	}

	if (noIndex || noFollow) {
		return <meta name="robots" content={metaTags.join(',')} />;
	}

	return null;
};

export const Head = ({ metaContent, seoSchemas }) => {
	const { pathname } = useLocation();
	const locale = getLocale(pathname);
	const [gtmID, setGtmID] = React.useState();
	React.useLayoutEffect(() => {
		if (locale === 'ie') {
			setGtmID(process.env.GATSBY_GTM_IE_TRACKING_ID);
		} else if (locale === 'ca') {
			setGtmID(process.env.GATSBY_GTM_CA_TRACKING_ID);
		} else {
			setGtmID(process.env.GATSBY_GTM_TRACKING_ID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const data = useStaticQuery(
		graphql`
			query {
				datoCmsSiteSpecificSetting {
					defaultSeoInformation {
						title
						description
						image {
							url
						}
						twitterCard
					}
				}
				site {
					siteMetadata {
						hrefLang
					}
				}
			}
		`
	);
	const {
		title: defaultTitle,
		description: defaultDescription,
		image: defaultImage,
		twitterCard: defaultTwitterCard,
	} = data.datoCmsSiteSpecificSetting?.defaultSeoInformation;
	const defaultLang = data.site?.siteMetadata?.hrefLang;

	const { noIndex, noFollow, metaInformation, canonicalUrl } = metaContent;

	const metaLang = 'en' || defaultLang;

	let metaTitle = '';
	if (locale === 'ca') {
		metaTitle =
			metaInformation?.title ||
			(metaContent.fallbackTitle
				? `${metaContent.fallbackTitle} | Peninsula Canada`
				: defaultTitle);
	} else {
		metaTitle =
			metaInformation?.title ||
			(metaContent.fallbackTitle
				? `${metaContent.fallbackTitle} | Peninsula UK`
				: defaultTitle);
	}

	const metaDescription =
		metaInformation?.description ||
		metaContent?.fallbackDescription ||
		defaultDescription;
	const metaTwitterCard = metaInformation?.twitterCard || defaultTwitterCard;
	const metaImage = metaInformation?.image?.url || defaultImage?.url;

	return (
		<Helmet
			htmlAttributes={{
				lang: metaLang,
			}}
			title={metaTitle}
		>
			<script type="application/ld+json">
				{JSON.stringify(seoSchemas.organization)}
			</script>
			<script type="application/ld+json">
				{JSON.stringify(seoSchemas.breadcrumbs)}
			</script>
			<script type="application/ld+json">
				{JSON.stringify(seoSchemas.image)}
			</script>
			<script type="application/ld+json">
				{JSON.stringify(seoSchemas.faq)}
			</script>
			<script type="application/ld+json">
				{JSON.stringify(seoSchemas.localBusiness)}
			</script>
			{locale === 'ca' ? (
				<script type="application/ld+json">
					{JSON.stringify(seoSchemas.blog)}
				</script>
			) : null}
			{locale === 'ca' ? (
				<script type="text/javascript">
					{`
						(function() {
						var didInit = false;
						function initMunchkin() {
						if(didInit === false) {
						didInit = true;
						Munchkin.init('544-LUT-177');
						}
						}
						var s = document.createElement('script');
						s.type = 'text/javascript';
						s.async = true;
						s.src = '//munchkin.marketo.net/munchkin.js';
						s.onreadystatechange = function() {
						if (this.readyState == 'complete' || this.readyState == 'loaded') {
						initMunchkin();
						}
						};
						s.onload = initMunchkin;
						document.getElementsByTagName('head')[0].appendChild(s);
						})();
					`}
				</script>
			) : null}
			{locale === 'ca' ? (
				<meta
					name="google-site-verification"
					content="HrTGgUGon1yJmx9vjdDZ7Ho9G6TNZUW1GEBgRRyTMrc"
				/>
			) : null}
			<meta name="description" content={metaDescription} />
			{getRobotMetaTag({ noIndex, noFollow })}
			<link rel="canonical" href={canonicalUrl} />
			<meta name="twitter:card" content={metaTwitterCard} />
			<meta property="og:title" content={metaTitle} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:image" content={metaImage} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={canonicalUrl} />
			{/* <script>
				{`window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('consent', 'default', {
							'ad_storage': 'denied',
							'ad_user_data': 'denied',
							'ad_personalization': 'denied',
							'analytics_storage': 'denied'
						})`}
	      </script> */}
			{gtmID && (
				<script id="gtag-config">
					{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${gtmID}');
				`}
				</script>
			)}
		</Helmet>
	);
};

Head.defaultProps = {
	metaContent: {
		noIndex: false,
		noFollow: false,
		metaInformation: {
			title: '',
			description: '',
			image: {
				url: '',
			},
			twitterCard: '',
		},
		canonicalUrl: '',
	},
};

Head.propTypes = {
	metaContent: PropTypes.shape({
		noIndex: PropTypes.bool,
		noFollow: PropTypes.bool,
		metaInformation: PropTypes.shape({
			title: PropTypes.string,
			description: PropTypes.string,
			image: PropTypes.shape({ url: PropTypes.string }),
			twitterCard: PropTypes.string,
		}),
		canonicalUrl: PropTypes.string,
	}),
};
