import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import GoogleSvg from '../../../assets/google-rating.inline.svg';

const RatedReview = ({ reviews }) => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsTrustpilotRating {
				id

				trustscore {
					id
					originalId
					stars
					trustscore
				}
			}
		}
	`);
	return (
		<div
			className={clsx(
				'flex flex-col  justify-center -mt-2',
				reviews > 4 ? 'items-center' : 'items-start'
			)}
		>
			<p className="text-gray-700 text-xs inline-flex gap-1">
				Rated{' '}
				<span className="font-bold">
					{data.datoCmsTrustpilotRating.trustscore[0].trustscore}
				</span>
				/ 5 based on <GoogleSvg className="w-4 h-4" /> Google Reviews
			</p>
		</div>
	);
};

RatedReview.defaultProps = {
	reviews: [],
};
RatedReview.propTypes = {
	reviews: PropTypes.arrayOf,
};

export default RatedReview;
