import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import SVG from 'react-inlinesvg';

import * as logoClasses from './index.module.css';
import { getLocale } from '../../../utils/locale';

const Logos = [
	{
		src: 'client-logos/bmi-healthcare',
		alt: 'BMI Healthcare',
		width: 178,
		height: 50,
	},
	{
		src: 'client-logos/cake-box',

		alt: 'Cake Box',
		width: 212,
		height: 60,
	},
	{
		src: 'client-logos/sgn-retail',
		alt: 'SGN Retail',
		width: 83,
		height: 60,
	},
	{
		src: 'client-logos/cash-converters',

		alt: 'Cash Converters',
		width: 201,
		height: 38,
	},
	{
		src: 'client-logos/barker-stonehouse',

		alt: 'Barker and Stonehouse',
		width: 70,
		height: 70,
	},
	{
		src: 'client-logos/ann-summers',
		alt: 'Ann Summers',
		width: 309,
		height: 60,
	},
];

const caLogos = [
	{
		src: 'byPNP-logo',
		alt: '',
		width: 200,
		height: 54,
	},
	{
		src: 'dq',

		alt: '',
		width: 120,
		height: 84,
	},
	{
		src: 'nf-public-library',
		alt: '',
		width: 220,
		height: 49,
	},
	{
		src: 'dominos',

		alt: '',
		width: 140,
		height: 96,
	},
	{
		src: 'tim-hortons',

		alt: '',
		width: 200,
		height: 81,
	},
];

const ieLogos = [
	{
		src: '3dental',
		alt: '',
		width: 245,
		height: 80,
	},
	{
		src: 'automotive-team',

		alt: '',
		width: 160,
		height: 80,
	},
	{
		src: 'clarenbridge-garden-centre',
		alt: '',
		width: 109,
		height: 80,
	},
	{
		src: 'killarney-mechanical-services',

		alt: '',
		width: 305,
		height: 80,
	},
	{
		src: 'medfit-proactive-healthcare',

		alt: '',
		width: 80,
		height: 80,
	},
	{
		src: 'turin-components',

		alt: '',
		width: 195,
		height: 80,
	},
];

const ClientLogos = ({ pathname }) => {
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);
	const getLogos = (logos, filetype) =>
		logos.map(({ src, alt, width, height }) => {
			const iconSrc =
				filetype === 'svg'
					? // eslint-disable-next-line import/no-dynamic-require, global-require
					  require(`!!file-loader!../../../assets/${src}.svg`)
							.default
					: `/images/logos/${src}.${filetype}`;

			return (
				<li
					className={clsx(
						'flex',
						'justify-center',
						'ml-4 mt-4',
						'md:ml-8 md:mt-8',
						'lg:ml-0 lg:mt-0',
						logoClasses.clientLogo
					)}
					key={alt}
				>
					{filetype === 'svg' ? (
						<SVG
							src={iconSrc}
							role="img"
							aria-label={alt}
							width={width}
							height={height}
						/>
					) : (
						<img
							src={iconSrc}
							alt={alt}
							width={width}
							height={height}
						/>
					)}
				</li>
			);
		});

	return (
		<section
			className={clsx(
				'px-4 py-10 md:py-20',
				pathname !== '/' ? 'hidden md:block' : null
			)}
			aria-label="Logos of clients that we have worked with"
		>
			<div className="md:w-[768px] lg:w-auto md:mx-auto max-w-m-screen lg:px-8">
				<ul
					className={clsx(
						'flex',
						'flex-wrap',
						'items-center',
						'justify-between',
						'-ml-4 -mt-4 mx-auto',
						'md:-ml-8 md:-mt-8',
						'lg:ml-0 lg:mt-0 lg:justify-between'
					)}
				>
					{locale === 'ie' ? getLogos(ieLogos, 'png') : null}
					{locale === 'ca' ? getLogos(caLogos, 'png') : null}
					{locale === '' ? getLogos(Logos, 'svg') : null}
				</ul>
			</div>
		</section>
	);
};

ClientLogos.propTypes = {
	pathname: PropTypes.string.isRequired,
};

export { ClientLogos };
