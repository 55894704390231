import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VapHeadingTagLine } from '../../molecules/vap-heading-tag-line';
import { pricingCardProps, refProp } from '../../../types';
import { VapCalculator } from '../../molecules/vap-calculator';
import { VapCard } from '../../molecules/vap-card-bdm';

const unSelectedVapItemsInitialState = [
	{ name: 'lateness_absence', label: 'Cost of lateness & absence' },
	{ name: 'staff_turnover', label: 'Staff turnover' },
	{ name: 'hr_docs', label: 'HR documents' },
	{ name: 'hs_docs', label: 'Health & safety documents' },
	{ name: 'disciplinary', label: 'Disciplinary administrative work' },
	{ name: 'ohs_fines', label: 'OHS fines for incomplete training' },
	{
		name: 'general_hr',
		label: 'General HR, paperwork ‘keeping on top of things’',
	},
	{ name: 'cost_accidents', label: 'Cost of accidents in your business' },
	{
		name: 'average_wrongful',
		label: 'Average wrongful dismissal claim (per year)',
	},
];

const VapSection = ({ drawerRef }) => {
	const [priceCalculated, setPriceCalculated] = useState({
		lateness_absence: '-',
		staff_turnover: '-',
		hr_docs: '-',
		hs_docs: '-',
		disciplinary: '-',
		ohs_fines: '-',
		general_hr: '-',
		cost_accidents: '-',
		average_wrongful: '-',
		cost_hr_manager: '-',
		cost_hs_manager: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
		time_saved: '-',
	});
	const [employeeCount, setEmployeeCount] = useState(0);
	const [selectedVapItems, setSelectedVapItems] = useState([]);

	const [unSelectedVapItems, setUnSelectedVapItems] = useState(
		unSelectedVapItemsInitialState
	);

	const [checkboxItems, setCheckboxItems] = useState([
		{
			name: 'lateness_absence',
			label: 'Persistent lateness & absence',
			isChecked: false,
		},
		{
			name: 'average_wrongful',
			label: 'Making a termination, the right way',
			isChecked: false,
		},
		{
			name: 'staff_turnover',
			label: 'Hiring and retaining the best staff',
			isChecked: false,
		},
		{
			name: 'hr_docs',
			label: 'Creating and updating employment contracts',
			isChecked: false,
		},
		{
			name: 'hr_docs',
			label: 'Creating and writing HR policies',
			isChecked: false,
		},
		{
			name: 'hs_docs',
			label: 'Implementing health and safety policies',
			isChecked: false,
		},
		{
			name: 'disciplinary',
			label: 'Performance management and employee discipline',
			isChecked: false,
		},
		{
			name: 'general_hr',
			label: 'Compliance with local laws and legislations',
			isChecked: false,
		},
		{
			name: 'general_hr',
			label: 'Avoiding fines and penalties from the government',
			isChecked: false,
		},
		{
			name: 'disciplinary',
			label: 'Conducting workplace investigations',
			isChecked: false,
		},
		{
			name: 'cost_accidents',
			label: 'What to do if there is an accident at work',
			isChecked: false,
		},
		{
			name: 'ohs_fines',
			label: 'Ensuring employees have completed mandated trainings',
			isChecked: false,
		},
	]);

	const title = [
		{
			type: 'paragraph',
			children: [
				{
					type: 'span',
					value: 'What are the ',
				},
				{
					type: 'span',
					marks: ['highlight'],
					value: 'hidden costs',
				},
				{
					type: 'span',
					value: '\n to your business?',
				},
				{
					type: 'span',
					value: '\n',
				},
			],
		},
	];

	const subText = `Find out the cost of HR and health and safety to your business, and the value Peninsula can bring.`;

	return (
		<section className="relative w-full">
			<div className="px-3 mx-auto max-w-7xl">
				<div className="px-3 pt-8 md:container md:mx-auto my-8">
					<VapHeadingTagLine id="vap-calc" title={title} />
				</div>
				<VapCalculator
					title={title}
					subtitle={subText}
					checkboxItems={checkboxItems}
					setPriceCalculated={setPriceCalculated}
					setEmployeeCount={setEmployeeCount}
					setUnSelectedVapItems={setUnSelectedVapItems}
					selectedVapItems={selectedVapItems}
					setSelectedVapItems={setSelectedVapItems}
					setCheckboxItems={setCheckboxItems}
					unSelectedVapItemsInitialState={
						unSelectedVapItemsInitialState
					}
				/>
				<VapCard
					priceCalculated={priceCalculated}
					employeeCount={employeeCount}
					drawerRef={drawerRef}
					unSelectedVapItems={unSelectedVapItems}
					selectedVapItems={selectedVapItems}
					unSelectedVapItemsInitialState={
						unSelectedVapItemsInitialState
					}
				/>
			</div>
		</section>
	);
};

VapSection.propTypes = {
	pricingContent: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		cards: PropTypes.arrayOf(pricingCardProps),
	}).isRequired,
	drawerRef: refProp.isRequired,
};

export { VapSection };
