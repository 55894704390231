// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { Title } from '../../atoms/title';

function StatementCompass({ statementContent }) {
	return (
		<section className="px-3 mx-auto max-w-m-screen lg:px-8 mt-5">
			<Title
				title={statementContent?.statementTitle}
				className="!text-center "
				subtitle={statementContent?.statementSubHeader}
				id={statementContent?.id}
			/>
			<div className=" grid grid-cols-1 lg:gap-10 md:gap-2 gap-5 md:grid-cols-3">
				{statementContent?.statement.map((item) => (
					<div
						key={item.id}
						className="flex flex-col items-center justify-center w-full mt-10 col-span-3 md:col-span-1 bg-brand-pale-500 rounded-md"
					>
						<img
							src={item.image.url}
							alt={item.image.alt}
							className="w-96 h-96 -mt-20 object-fill"
						/>
						<p className="max-w-5xl mt-5 text-center px-2 pb-10 font-centra-light text-xl">
							{item.content}
						</p>
					</div>
				))}
			</div>
		</section>
	);
}

export default StatementCompass;
