// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import clsx from 'clsx';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq';
import { CardContainer } from '../components/organisms/card-container-press';
import { RteContent } from '../components/organisms/rte-content';

import { ResourceHeader } from '../components/molecules/resource-header';
import { JumpTo } from '../components/molecules/jump-to';

import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { LongIntro } from '../components/atoms/long-intro';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';

// markup
const BlogPage = ({ data, location, parentPageData }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsPressArticlePage.noIndex,
		noFollow: data.datoCmsPressArticlePage.noFollow,
		metaInformation: data.datoCmsPressArticlePage.metaInformation,
		fallbackTitle: data.datoCmsPressArticlePage.title,
		fallbackDescription: data.datoCmsPressArticlePage.longIntroduction,
		canonicalUrl:
			data.datoCmsPressArticlePage?.canonicalUrl || location.href,
		hreflangs: {
			uk: data.datoCmsPressArticlePage.hreflangEnGb,
			ie: data.datoCmsPressArticlePage.hreflangEnIe,
		},
	};

	const resourceHeaderContent = {
		id: data.datoCmsPressArticlePage.originalId,
		title: data.datoCmsPressArticlePage.title,
		categories: data.datoCmsPressArticlePage.categories,
		author: data.datoCmsPressArticlePage.author,
		createdDate: data.datoCmsPressArticlePage.created,
		createdDateOrdinal: data.datoCmsPressArticlePage.createdOrdinal,
		lastUpdated: data.datoCmsPressArticlePage.meta.updatedAt,
		lastUpdatedOrdinal: data.datoCmsPressArticlePage.meta.updatedAtOrdinal,
		image: data.datoCmsPressArticlePage.thumbnail,
		pageUrl: location.href,
	};

	const rteContent = {
		...data.datoCmsPressArticlePage.content,
	};

	const faqContent = {
		title: data.datoCmsPressArticlePage.faqContainer?.title,
		subText: data.datoCmsPressArticlePage.faqContainer?.subText,
		questions: data.datoCmsPressArticlePage.faqContainer?.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const pressArticleContainerContent = {
		totalCount: data.allDatoCmsPressArticlePage.totalCount,
		cards: data.allDatoCmsPressArticlePage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.blog,
			location,
			data: data.datoCmsPressArticlePage,
			parentPageData,
		}),
		image: getImageSeoSchema(
			data.datoCmsPressArticlePage?.mainHeader?.primaryImage
		),
	};

	let pagePath = location.pathname;
	if (pagePath.endsWith('/')) {
		pagePath = location.pathname.slice(0, -1);
	}
	const displayFaq =
		data.datoCmsPressArticlePage.faqContainer &&
		data.datoCmsPressArticlePage.faqContainerDisplayOnPage;

	const lastHubPage = location.pathname.substring(
		0,
		location.pathname.lastIndexOf('/')
	);

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsPressArticlePage.internal.type,
		data.datoCmsPressArticlePage,
		parentPageData
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPressArticlePage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<ResourceHeader content={resourceHeaderContent} />
			{data.datoCmsPressArticlePage.intro ? (
				<LongIntro content={data.datoCmsPressArticlePage.intro} />
			) : null}
			{data.datoCmsPressArticlePage.showJumpSection ? (
				<JumpTo content={rteContent} />
			) : null}
			<RteContent
				containerClassName="max-w-content mx-auto mb-14 md:mb-20 px-3 md:px-8 xl:px-0"
				content={rteContent}
				drawerRef={adviceFormRef}
			/>
			<BreadCrumbs
				breadCrumbItems={breadCrumbItems}
				className={clsx(
					displayFaq && '!pb-0',
					!displayFaq && 'pb-14  mb-xl-f md:mb-3xl-f'
				)}
			/>
			{displayFaq ? <FAQ faqContent={faqContent} /> : null}
			{pressArticleContainerContent.totalCount > 0 && (
				<CardContainer
					cardContainerContent={{
						...pressArticleContainerContent,
						id: 'blog-container',
						title: 'More articles',
						to: `${lastHubPage}`,
						toAll: '/press',
						buttonCopy: 'Back to Peninsula in the Press',
					}}
					variant="Center"
					cardVariant="PressArticle"
				/>
			)}

			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query blogPage($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPressArticlePage(slug: { eq: $slug }, locale: { eq: $locale }) {
			title
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			originalId
			internal {
				type
			}
			slug
			categories {
				name
				slug
			}
			sectors {
				name
			}
			author {
				name
				image {
					alt
					url(imgixParams: { w: "44", h: "44" })
				}
				jobTitle
			}
			thumbnail {
				alt
				url
				gatsbyImageData(
					layout: CONSTRAINED
					aspectRatio: 1.3
					placeholder: DOMINANT_COLOR
					sizes: "(max-width: 640px) 640px, 800px"
				)
			}
			created
			createdOrdinal: created(formatString: "MMM Do YYYY")
			meta {
				updatedAt
				updatedAtOrdinal: updatedAt(formatString: "MMM Do YYYY")
			}
			longIntroduction
			intro
			faqContainerDisplayOnPage
			adviceBannerDisplayOnPage
			showJumpSection
			content {
				value
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			hreflangEnGb
			hreflangEnIe
		}
		allDatoCmsPressArticlePage(
			filter: { slug: { ne: $slug }, locale: { eq: $locale } }
			sort: { order: DESC, fields: created }
			limit: 3
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url(imgixParams: { w: "44", h: "44" })
					}
				}
				logo {
					alt
					gatsbyImageData(width: 372, height: 45)
				}
				sectors {
					id
					name
				}
			}
		}
	}
`;

const WrappedBlog = ({ data, pageContext, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<BlogPage
			data={data}
			location={location}
			slug={pageContext.slug}
			locale={pageContext.locale}
			parentPageData={pageContext.parentPageData}
		/>
	</DefaultLayout>
);

export default WrappedBlog;
