// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/button-has-type */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { sendTrackingData } from '../../../utils';
import './style.css';

import { titleProp } from '../../../types';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';
import { useModal } from '../../../contexts/modal-context';

const CorCA = ({ id, containerClassName, modalRef, drawerRef, corContent }) => {
	const { setDrawerRef } = useDrawer();
	const { setModalRef } = useModal();
	const [elementIndex, setElementIndex] = useState();

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'cor-advice-banner',
			click_type: 'primary',
			...trackData,
		});
	};

	const colors = ['#9CAAF0', '#1F2E7A', '#E30138', '#9CAAF0'];

	return (
		<div
			className={clsx(
				' mt-5 pt-5 scroll-mt-[79x] md:scroll-mt-[160px] scroll-mt-[140px]',
				containerClassName
			)}
			aria-labelledby={id}
		>
			<div className="px-10 md:container md:mx-auto">
				<HeadingTagLine
					id={id}
					title={corContent.title.value.document.children}
					subText={corContent.subText}
					headingClassNames="lg:text-4xl xl:text-5xl lg:-mx-10"
					subTextClassNames="mx-auto xl:px-24"
				/>
			</div>
			{/* desktop view */}
			<div className="hidden lg:block flex flex-col items-stretch justify-center text-center mac-container lg:px-10 xl:px-0 2xl:px-64 pt-12 pb-10 bg-[#F8FBFF] ">
				<div className="grid auto-rows-min grid-cols-1 text-[#1F2E7A] font-centra-no1 text-2xl font-black text-center px-16 lg:px-0 xl:px-16">
					<div className="flex flex-row items-stretch gap-3">
						{corContent.corCards.map((elm, idx) => (
							<div
								key={elm}
								onMouseOver={() => setElementIndex(idx)}
								onFocus={() => setElementIndex(idx)}
								onMouseOut={() => setElementIndex(null)}
								onBlur={() => setElementIndex(null)}
								className={`bg-white red text-[#1F2E7A] w-1/4 pt-7 lg:px-5 border-t-8 border-[${
									colors[idx]
								}] border-x-1 border-x-[#9CAAF0] shadow-xl ${
									idx === elementIndex &&
									'scale-105 ease-out duration-75 delay-75 transform transition '
								}`}
							>
								<span className="text-[#1F2E7A]">
									{elm.title}
								</span>
							</div>
						))}
					</div>
					<div className="flex flex-row items-stretch gap-3 text-sm font-light text-[#5068E4]">
						{corContent.corCards.map((elm, idx) => (
							<div
								key={elm}
								onMouseOver={() => setElementIndex(idx)}
								onFocus={() => setElementIndex(idx)}
								onMouseOut={() => setElementIndex(null)}
								onBlur={() => setElementIndex(null)}
								className={`bg-white w-1/4 text-md py-5 px-6 leading-5 border-x-1 border-x-[#9CAAF0] shadow-xl ${
									idx === elementIndex &&
									'scale-105 ease-out duration-75 delay-75 transform transition '
								}`}
							>
								{elm.subText}
							</div>
						))}
					</div>
					<div className="flex flex-row items-stretch gap-3 text-xs font-light text-black">
						{corContent.corCards.map((elm, idx) => (
							<ul
								// eslint-disable-next-line react/no-array-index-key
								key={idx}
								onMouseOver={() => setElementIndex(idx)}
								onFocus={() => setElementIndex(idx)}
								onMouseOut={() => setElementIndex(null)}
								onBlur={() => setElementIndex(null)}
								className={`bg-white w-1/4 list-disc px-8 text-left border-x-1 border-x-[#9CAAF0] shadow-xl ${
									idx === elementIndex &&
									'scale-105 ease-out duration-75 delay-75 transform transition '
								}`}
							>
								{elm.items?.map((item) => (
									<li
										key={item.text}
										className="lg:leading-5 text-md lg:text-[14px] 2xl:text-[15px] 2xl:leading-5 pb-2"
									>
										{item.text}
									</li>
								))}
							</ul>
						))}
					</div>
					<div className="flex flex-row items-stretch gap-3 text-xs font-light text-[#9CAAF0]">
						{corContent.corCards.map((elm, idx) => (
							<div
								key={elm.buttonCopy}
								onMouseOver={() => setElementIndex(idx)}
								onFocus={() => setElementIndex(idx)}
								onMouseOut={() => setElementIndex(null)}
								onBlur={() => setElementIndex(null)}
								className={`bg-white w-1/4 py-8 border-x-1 border-x-[#9CAAF0] shadow-xl border-b-1 border-b-[#9CAAF0] ${
									idx === elementIndex &&
									'scale-105 ease-out duration-75 delay-75 transform transition '
								}`}
							>
								<button
									className="inline-flex items-center px-6 py-4 mb-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-6 md:text-[16px] font-centra-medium focus:outline-0 focus:shadow-focus w-max"
									onClick={() => {
										setDrawerRef(drawerRef);
										setModalRef(modalRef);
										handleClickTracking({
											click_title: elm.buttonCopy,
											click_text: elm.buttonCopy,
										});
									}}
								>
									{elm.buttonCopy}
								</button>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* mobile view */}
			<div className="block lg:hidden flex flex-col items-stretch pt-10 px-3 sm:px-10 text-center gap-5 text-white font-centra-no1">
				{corContent.corCards.map((elm, idx) => (
					<div className="flex flex-col justify-start">
						<div
							className={`bg-white red text-[#1F2E7A] text-3xl font-black p-5 border-t-8 border-[${colors[idx]}] border-x-1 border-x-[#9CAAF0] shadow-xl`}
						>
							<span className="text-[#1F2E7A]">{elm.title}</span>
						</div>
						<div className="bg-white text-lg px-5 sm:px-10 sm:text-lg font-light text-[#5068E4] pb-5 border-x-1 border-x-[#9CAAF0] shadow-xl">
							{elm.subText}
						</div>
						<ul className="bg-white text-black text-left list-disc px-12 sm:px-5 pb-5 border-x-1 border-x-[#9CAAF0] shadow-xl">
							{elm.items?.map((item) => (
								<li className="text-lg sm:text-xl pb-2 leading-6 mx-6">
									{item.text}
								</li>
							))}
						</ul>
						<div className="bg-white pb-8 border-x-1 border-x-[#9CAAF0] shadow-xl border-b-1 border-b-[#9CAAF0]">
							<Button
								className="text-lg"
								onClick={() => {
									// eslint-disable-next-line no-unused-expressions
									drawerRef
										? setDrawerRef(drawerRef)
										: (window.location.href =
												'#adviceform');
									handleClickTracking({
										click_title: elm.buttonCopy,
										click_text: elm.buttonCopy,
									});
								}}
							>
								{elm.buttonCopy}
							</Button>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

CorCA.defaultProps = {
	id: 'hr-services',
	containerClassName: '',
};

CorCA.propTypes = {
	id: PropTypes.string,
	iconCardsContent: PropTypes.shape({
		title: PropTypes.oneOfType([titleProp, PropTypes.string]),
		subText: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	containerClassName: PropTypes.string,
};

export { CorCA };
