import { useEffect, useState } from 'react';

const useIsMobile = (breakpoint = 992) => {
	const [isMobile, setIsMobile] = useState();

	useEffect(() => {
		let windowWidth = window.innerWidth;

		const updateBreakpoint = () => {
			if (window.innerWidth !== windowWidth) {
				windowWidth = window.innerWidth;
			}
			setIsMobile(windowWidth <= breakpoint);
		};

		setIsMobile(windowWidth <= breakpoint);

		window.addEventListener('resize', updateBreakpoint);

		return () => window.removeEventListener('resize', updateBreakpoint);
	}, [breakpoint]);

	return isMobile;
};

export default useIsMobile;
