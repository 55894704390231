/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable compat/compat */

export const useMarketoFormSubmit = (data, formId, MktoForms2Instances) =>
	new Promise((res, rej) => {
		MktoForms2Instances.forEach((MktoForms2Library) => {
			if (!MktoForms2Library) {
				return rej(new Error('Marketo forms is not loaded'));
			}

			const errorTimer = setTimeout(() => rej(), 5000);

			const form = MktoForms2Library.getForm(formId);

			if (form) {
				form.addHiddenFields({
					...data,
				});
				// Prevent Marketo redirect
				form.onSuccess(() => {
					clearTimeout(errorTimer);
					res();

					return false;
				});
				form.submit();
			}
		});
	});
