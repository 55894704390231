import React from 'react';
import PropTypes from 'prop-types';

import FacebookIcon from '../../../assets/facebook.inline.svg';
import LinkedInIcon from '../../../assets/linkedin.inline.svg';
import EmailIcon from '../../../assets/email.inline.svg';
import TwitterIcon from '../../../assets/twitter.inline.svg';

const SocialShare = ({ title, url, className }) => {
	const fixedEncodeURIComponent = (string) =>
		encodeURIComponent(string).replace(
			/[!'()*]/g,
			(c) => `%${c.charCodeAt(0).toString(16)}`
		);

	const encodedUrl = fixedEncodeURIComponent(url);
	const encodedTitle = fixedEncodeURIComponent(title);

	const socials = [
		{
			outlet: 'Facebook',
			href: `https://www.facebook.com/sharer.php?u=${encodedUrl}`,
			label: 'Share on Facebook',
			icon: <FacebookIcon />,
		},
		{
			outlet: 'Twitter',
			href: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
			label: 'Share on Twitter',
			icon: <TwitterIcon />,
		},
		{
			outlet: 'LinkedIn',
			href: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
			label: 'Share on LinkedIn',
			icon: <LinkedInIcon />,
		},
		{
			outlet: 'Email',
			href: `mailto:?subject=${encodedTitle}&body=${encodedUrl}`,
			label: 'Share via Email',
			icon: <EmailIcon />,
		},
	];

	const socialLinks = socials.map((social) => (
		<li key={`${social.outlet}Share`}>
			<a
				href={social.href}
				target="_blank"
				aria-label={social.label}
				role="button"
				rel="noreferrer"
			>
				{social.icon}
			</a>
		</li>
	));

	return <ul className={className}>{socialLinks}</ul>;
};

SocialShare.defaultProps = {
	title: '',
	className: '',
};

SocialShare.propTypes = {
	url: PropTypes.string.isRequired,
	title: PropTypes.string,
	className: PropTypes.string,
};

export { SocialShare };
