import React from 'react';
import PropTypes from 'prop-types';
import { pricingCardProps, refProp, priceCalculatedProp } from '../../../types';
import { PricingCard } from '../../atoms/pricing-card';

const PricingCards = ({
	drawerRef,
	setFormTitle,
	content,
	priceCalculated,
	employeeCount,
}) => (
	<section className="grid grid-cols-12 gap-5 outline-none lg:container lg:mx-auto focus:shadow-focus">
		{content.map((item, index) => (
			<PricingCard
				priceCalculated={priceCalculated}
				key={`${item.id}-card`}
				drawerRef={drawerRef}
				setFormTitle={setFormTitle}
				content={item}
				index={index}
				employeeCount={employeeCount}
			/>
		))}
	</section>
);

PricingCards.defaultProps = {
	setFormTitle: () => null,
	employeeCount: 0,
	priceCalculated: {
		hr: '-',
		hs: '-',
		combined: '-',
	},
};

PricingCards.propTypes = {
	setFormTitle: PropTypes.func,
	content: PropTypes.arrayOf(pricingCardProps).isRequired,
	drawerRef: refProp.isRequired,
	employeeCount: PropTypes.number,
	priceCalculated: priceCalculatedProp,
};

export { PricingCards };
