import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderNodeRule, renderMarkRule, StructuredText } from 'react-datocms';
import {
	isHeading,
	isParagraph,
	isLink,
	isRoot,
	isList,
} from 'datocms-structured-text-utils';

import { Button } from '../button';
import { useModal } from '../../../contexts/modal-context';
import { PBSLink } from '../link';

import {
	datoGatsbyImageProp,
	structuredTextContent,
	titleProp,
	refProp,
} from '../../../types';
import * as textWithImageStyles from './index.module.css';
import { getLocale, buildLink } from '../../../utils/locale';

const TextWithImage = ({ textWithImageContent, flip, modalRef }) => {
	const {
		id,
		firsttitle,
		secondtitle,
		thirdtitle,
		content,
		as,
		titleProps,
		titleProps2,
		titleProps3,
		image,
		firstButtonCopy,
		firstDisplayCtaOnPage,
		secondButtonCopy,
		secondDisplayCtaOnPage,
		thirdButtonCopy,
		thirdDisplayCtaOnPage,
		className,
	} = textWithImageContent;
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);
	const Heading = as || 'h2';
	const Alt =
		image?.alt || firsttitle || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(image);

	const { setModalRef } = useModal();

	return (
		<div
			className={clsx(
				'lg:grid lg:grid-cols-[1fr_46%] lg:gap-[60px]',
				className
			)}
		>
			<div className={clsx(flip ? 'order-2' : null)}>
				{firsttitle || titleProps ? (
					<Heading
						id={id}
						className="mb-5 text-3xl lg:text-4xl font-castledown-regular"
						{...titleProps}
					>
						{firsttitle}
					</Heading>
				) : null}

				{secondtitle || titleProps2 ? (
					<Heading
						id={id}
						className="mb-5 text-3xl lg:text-4xl font-castledown-regular"
						{...titleProps2}
					>
						{secondtitle}
					</Heading>
				) : null}

				{thirdtitle || titleProps3 ? (
					<Heading
						id={id}
						className="mb-5 text-3xl lg:text-4xl font-castledown-regular"
						{...titleProps3}
					>
						{thirdtitle}
					</Heading>
				) : null}

				<StructuredText
					data={content}
					customNodeRules={[
						renderNodeRule(isHeading, ({ node, children, key }) => {
							const HeadingTag = `h${node.level}`;
							return (
								<HeadingTag
									className="mb-5 text-2xl lg:text-3xl font-castledown-regular"
									key={key}
								>
									{children}
								</HeadingTag>
							);
						}),
						renderNodeRule(isLink, ({ node, children, key }) => (
							<PBSLink
								to={buildLink(locale, node.url)}
								variant="Link"
								key={key}
							>
								{children}
							</PBSLink>
						)),
						renderNodeRule(isList, ({ node, children, key }) => {
							let ListTag;
							let listClassName;

							if (node.style === 'bulleted') {
								ListTag = 'ul';
								listClassName = 'list-disc';
							}
							if (node.style === 'numbered') {
								ListTag = 'ol';
								listClassName = 'list-decimal';
							}

							return (
								<ListTag
									className={clsx(
										'mb-4 text-lg font-centra-light md:text-xl list-inside',
										listClassName,
										textWithImageStyles.list
									)}
									key={
										key +
										node.style +
										textWithImageStyles.list
									}
								>
									{children}
								</ListTag>
							);
						}),
						renderNodeRule(
							isParagraph,
							({ children, key, ancestors }) => {
								if (isRoot(ancestors[0])) {
									return (
										<p
											className="mb-4 text-lg font-centra-light md:text-xl last-of-type:mb-0"
											key={key}
										>
											{children}
										</p>
									);
								}
								return children;
							}
						),
					]}
					customMarkRules={[
						renderMarkRule('strong', ({ children, key }) => (
							<span className="font-centra-book" key={key}>
								{children}
							</span>
						)),
					]}
				/>
				{firstButtonCopy && firstDisplayCtaOnPage ? (
					<p className="hidden mt-8 text-2xl leading-9 text-center lg:block lg:mt-10 lg:mb-0 lg:text-left">
						<Button onClick={() => setModalRef(modalRef)}>
							{firstButtonCopy}
						</Button>
					</p>
				) : null}
				{secondButtonCopy && secondDisplayCtaOnPage ? (
					<p className="hidden mt-8 text-2xl leading-9 text-center lg:block lg:mt-10 lg:mb-0 lg:text-left">
						<Button onClick={() => setModalRef(modalRef)}>
							{secondButtonCopy}
						</Button>
					</p>
				) : null}
				{thirdButtonCopy && thirdDisplayCtaOnPage ? (
					<p className="hidden mt-8 text-2xl leading-9 text-center lg:block lg:mt-10 lg:mb-0 lg:text-left">
						<Button onClick={() => setModalRef(modalRef)}>
							{thirdButtonCopy}
						</Button>
					</p>
				) : null}
			</div>
			<div
				className={clsx(
					'text-center mt-8 lg:mt-0 lg:text-text'
					// flip ? 'hidden lg:block order-1' : null
				)}
			>
				<GatsbyImage
					className={clsx(
						'border-8',
						'border-white',
						'drop-shadow-image',
						'self-start',
						'justify-self-center',
						'md:justify-self-start'
					)}
					image={imageData}
					alt={Alt}
				/>
				{firstButtonCopy && firstDisplayCtaOnPage ? (
					<p className="mt-8 text-2xl leading-9 text-center lg:hidden lg:mt-10 lg:mb-0 lg:text-left">
						<Button
							type="button"
							onClick={() => {
								window.location.href = '#adviceform';
							}}
						>
							{firstButtonCopy}
						</Button>
					</p>
				) : null}
				{secondButtonCopy && secondDisplayCtaOnPage ? (
					<p className="mt-8 text-2xl leading-9 text-center lg:hidden lg:mt-10 lg:mb-0 lg:text-left">
						<Button
							type="button"
							onClick={() => {
								window.location.href = '#adviceform';
							}}
						>
							{secondButtonCopy}
						</Button>
					</p>
				) : null}
				{thirdButtonCopy && thirdDisplayCtaOnPage ? (
					<p className="mt-8 text-2xl leading-9 text-center lg:hidden lg:mt-10 lg:mb-0 lg:text-left">
						<Button
							type="button"
							onClick={() => {
								window.location.href = '#adviceform';
							}}
						>
							{thirdButtonCopy}
						</Button>
					</p>
				) : null}
			</div>
		</div>
	);
};

TextWithImage.defaultProps = {
	textWithImageContent: {
		as: 'h2',
		titleProps: {},
		id: '',
	},
	flip: false,
};

TextWithImage.propTypes = {
	textWithImageContent: PropTypes.shape({
		id: PropTypes.string,
		firsttitle: PropTypes.string,
		secondtitle: PropTypes.string,
		thirdtitle: PropTypes.string,
		as: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
			PropTypes.func,
			titleProp,
		]),
		titleProps: PropTypes.shape({
			id: PropTypes.string,
			title: titleProp,
			headingClassNames: PropTypes.string,
		}),
		titleProps2: PropTypes.shape({
			id: PropTypes.string,
			title: titleProp,
			headingClassNames: PropTypes.string,
		}),
		titleProps3: PropTypes.shape({
			id: PropTypes.string,
			title: titleProp,
			headingClassNames: PropTypes.string,
		}),
		content: structuredTextContent.isRequired,
		image: datoGatsbyImageProp.isRequired,
		buttonLink: PropTypes.string,
		firstButtonCopy: PropTypes.string,
		secondButtonCopy: PropTypes.string,
		thirdButtonCopy: PropTypes.string,
		firstDisplayCtaOnPage: PropTypes.bool,
		secondDisplayCtaOnPage: PropTypes.bool,
		thirdDisplayCtaOnPage: PropTypes.bool,
		className: PropTypes.string,
	}),
	flip: PropTypes.bool,
	modalRef: refProp.isRequired,
};

export { TextWithImage };
