import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { TextInput } from '../../atoms/text-input';
import { Button } from '../../atoms/button';

const SearchBoxCA = React.forwardRef(
	({ placeholder, onChange, className }, ref) => {
		const winObj = typeof window !== 'undefined' && window;
		const params = React.useMemo(
			// eslint-disable-next-line compat/compat
			() => new URLSearchParams(winObj?.location?.search),
			[winObj?.location?.search]
		);
		const [submitting, setSubmitting] = React.useState(false);
		const [value, setValue] = React.useState('');

		const handleOnChange = (text) => {
			setValue(text);
		};

		const handleSubmit = (e) => {
			e.preventDefault();
			setSubmitting(true);

			// clear the page number
			params.delete('p');

			if (value.length === 0) {
				window.history.replaceState({ search: null }, '', '');
			} else {
				window.history.replaceState({ search: value }, '', '?p=1');
			}

			navigate(`?${params.toString()}`, {
				replace: true,
			});

			setTimeout(() => {
				setSubmitting(false);
				onChange(value); // Only trigger search on submit
			}, 1000);
		};

		return (
			<form
				className="relative flex flex-col grow "
				onSubmit={handleSubmit}
			>
				<div>
					<TextInput
						id="search"
						name="search"
						labelText="Search"
						showLabel={false}
						aria-required="true"
						className="w-full wrapper-small [&>input]:pr-[107px] md:[&>input]:pr-[140px]"
						validationMessage="Please enter a valid search"
						variant="RoundedThick"
						value={value}
						inputOnChange={handleOnChange}
						ref={ref}
						placeholder={placeholder}
						disabled={submitting}
					/>
					<Button
						type="submit"
						className={clsx(
							'absolute bottom-[1.5rem] leading-[1.46rem] md:leading-[1.61rem] right-[-2px]',
							submitting && 'justify-center loading',
							className
						)}
						disabled={submitting}
					>
						<span>Search</span>
						{submitting ? (
							<span className="sr-only" aria-live="assertive">
								Submitting your request, please wait...
							</span>
						) : null}
					</Button>
				</div>
			</form>
		);
	}
);

SearchBoxCA.defaultProps = {
	placeholder: 'Search',
	onChange: () => {},
	className: '',
};

SearchBoxCA.propTypes = {
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export { SearchBoxCA };
