import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { clsx } from 'clsx';

import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

import { titleProp, datoGatsbyImageProp, refProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { sendTrackingData } from '../../../utils';
import { buildLink, getLocale } from '../../../utils/locale';

import { useDrawer } from '../../../contexts/drawer-context';

import * as headingStyles from './index.module.css';
import { CallUs } from '../../atoms/call-us-cta-ppc';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const MainHeader = ({
	id,
	header,
	secondaryHeader,
	as,
	primaryImage,
	secondaryImage,
	primaryLink,
	primaryLinkCopy,
	primaryLinkOpenForm,
	secondaryLink,
	googleReview,
	showTrustPilot: showBusinessReview,
	headingClassNames,
	drawerRef,
	glu,
	logo,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});
	const primaryImageData = getImage(primaryImage);
	const secondaryImageData = getImage(secondaryImage);
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;
	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					handleClickTracking();
				}}
				// className="w-max"
				className="inline-flex items-center justify-center w-full px-6 py-4 mt-4 text-lg leading-6 !text-blue-400 transition-colors bg-white border-2 border-blue-400 rounded-lg hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus hover:!text-white focus:text-white focus:outline-focus sm:w-max sm:mt-0 sm:ml-4"
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	return (
		<section
			role="banner"
			className={clsx('px-4 pt-10 pb-6 lg:py-6 mx-auto lg:pr-0', {
				'bg-brand-pale-400': glu,
				'lg:max-w-m-screen': !glu,
			})}
			aria-labelledby={id}
		>
			<div
				className={clsx('flex mx-auto', {
					'lg:max-w-m-screen': glu,
				})}
			>
				<div
					className={clsx(
						'flex flex-col justify-center shrink lg:mr-8 lg:pl-8 xl:mr-12 lg:-mt-16',
						headingStyles.headingContainer
					)}
				>
					{logo && (
						<img
							src={logo.url}
							alt={logo.alt || ''}
							className="max-w-[275px]"
						/>
					)}
					<Component
						id={id}
						className={clsx(
							'!leading-[1.3] font-castledown-regular',
							!secondaryHeader ? headingStyles.heading : null,
							glu
								? 'text-2xl lg:text-4xl font-bold lg:min-h-[160px]'
								: 'text-4xl md:text-4xl xl:text-5xl md:w-8/12 xl:w-10/12',
							headingClassNames
						)}
					>
						{titleText}
					</Component>

					<span
						className={clsx(
							'mt-2 leading-[1.2] block',
							'md:mt-2',
							' xl:leading-[1.3]',

							glu
								? 'text-xl lg:text-2xl font-castledown-regular'
								: `text-2xl md:text-3xl ${headingStyles.heading} `
						)}
					>
						{secondaryHeader}
					</span>

					{primaryLink || secondaryLink ? (
						<p className="flex flex-col items-center justify-center mt-8 sm:flex-row sm:justify-start">
							{/* 1st CTA */}
							<CallUs
								// number={siteSettings.phoneNumber}
								className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
								// number={locale}
							/>

							{/* 2nd CTA */}
							{cta || null}
						</p>
					) : null}

					{showBusinessReview ? (
						<BusinessReviewHorizontal
							onlyGR
							googleReview={googleReview}
						/>
					) : null}
				</div>
				<div
					className={clsx(
						'hidden',
						'grid-cols-12',
						'lg:grid',
						'grow',
						'shrink-0',
						'w-[36%]',
						headingStyles.imageContainer
					)}
				>
					<GatsbyImage
						image={primaryImageData}
						alt={primaryImage.alt || ''}
						key={primaryImage.url}
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image',
							headingStyles.image1,
							'2xl:min-w-[400px]'
						)}
					/>
					<GatsbyImage
						image={secondaryImageData}
						alt={secondaryImage.alt || ''}
						key={secondaryImage.url}
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image',
							headingStyles.image2,
							'2xl:min-w-[330px]'
						)}
					/>
				</div>
			</div>
		</section>
	);
};

MainHeader.defaultProps = {
	id: 'main-site-header',
	as: 'h1',
	secondaryHeader: '',
	headingClassNames: '',
	showTrustPilot: true,
	glu: false,
	logo: {},
};

MainHeader.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	secondaryHeader: PropTypes.string,
	as: PropTypes.string,
	primaryImage: datoGatsbyImageProp.isRequired,
	secondaryImage: datoGatsbyImageProp.isRequired,
	headingClassNames: PropTypes.string,
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	secondaryLink: PropTypes.string.isRequired,
	showTrustPilot: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	drawerRef: refProp,
	glu: PropTypes.bool,
	logo: datoGatsbyImageProp,
	googleReview: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
};

export { MainHeader };
