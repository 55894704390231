import { useState, useEffect, useCallback, useRef } from 'react';
import useSWR from 'swr';
import haversine from 'haversine-distance';
import { formatDate } from '../../utils/date-formatter';
import { getLocale, parseDatoLocale } from '../../utils/locale';

const token = process.env.GATSBY_DATOAPIKEY;
const useDraft = process.env.GATSBY_DATO_DRAFT === true;
const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : '	'
);

const fetcher = (fetchQuery) =>
	// eslint-disable-next-line compat/compat
	fetch(`https://graphql.datocms.com/${useDraft ? 'preview/' : ''}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': `Bearer ${token}`,
		},
		body: JSON.stringify({
			query: fetchQuery,
		}),
	}).then((res) => {
		const responseBody = res.json();
		return responseBody;
	});

const currentDateTime = new Date().toISOString();
const generateEventQuery = (locationFilter) => {
	let filter = ` hideInEventLibrary: {eq: "no"}, dateTime: {gt: "${currentDateTime}"}`;
	if (locationFilter) {
		filter = `${filter}, ${locationFilter}`;
	}
	return `
	query AllEvents($locale: SiteLocale = ${parseDatoLocale[locale] || 'en'}) {
		allEventPages(filter: {${filter}},  locale: $locale, fallbackLocales: ${
		parseDatoLocale[locale] || 'en'
	}, first: 100, orderBy: dateTime_ASC) {
			id
			title
			slug
			eventTime
			dateTime
			hideInEventLibrary
			eventLocation {
				address {
					value
				}
				id
				venueName
			}
			shortDescription
		}
	}`;
};

const groupByDate = (array) =>
	array.reduce((group, event) => {
		const { dateTime } = event;
		// eslint-disable-next-line no-param-reassign
		group[dateTime] = group[dateTime] ?? [];
		group[dateTime].push(event);
		return group;
	}, {});

export const useFilters = ({ venuesData, distances }) => {
	const [venueDistanceLookup, setVenueDistanceLookup] = useState({});
	const [maxDistance, setMaxDistance] = useState(distances.at(-1));
	const [orderBy, setOrderBy] = useState('date'); // date or distance
	const [eventCards, setEventCards] = useState(null);
	const [resultData, setResultData] = useState(null);
	const validVenues = useRef(null);
	const venuesWithDistance = useRef(null);
	const query = useRef(generateEventQuery());
	if (venuesWithDistance?.current) {
		const maxDistanceInMeters = maxDistance * 1609;
		validVenues.current = venuesWithDistance.current
			.filter((venue) => venue.distance <= maxDistanceInMeters)
			.map((venue) => {
				const { id } = venue;
				if (id.includes('-')) {
					return `"${id}"`;
				}
				return id;
			});
		const distanceFilter = `eventLocation: {in: [${validVenues.current}]}`;
		query.current = generateEventQuery(distanceFilter, locale);
	}

	const key = JSON.stringify(query.current);
	const { data, isLoading } = useSWR(key, () => fetcher(query.current), {
		shouldRetryOnError: true,
		errorRetryInterval: 1000,
		errorRetryCount: 2,
		revalidateOnMount: true,
		revalidateOnFocus: false,
		revalidateIfStale: false,
	});
	const validVenuesAreSet =
		validVenues?.current && validVenues.current.length > 0;

	useEffect(() => {
		if (data?.data?.allEventPages) {
			setResultData(
				data.data.allEventPages.map((event) => ({
					...event,
					eventDate: formatDate(event.dateTime),
				}))
			);
		}
	}, [data]);

	const setEventsFilteredByDistance = useCallback(() => {
		// valid venues should be in distance order and the event pages should be in date order from the graph ql query
		// to get distance as primary order and date as secondary we'll looo through venues and add all events with the same venue id
		let temporaryEventCards = [];
		validVenues.current.forEach((venue) => {
			temporaryEventCards = [
				...temporaryEventCards,
				...resultData.filter(
					(eventCard) => eventCard.eventLocation.id === venue
				),
			];
		});
		setEventCards(temporaryEventCards);
	}, [resultData]);

	const setEventsFilteredByDate = useCallback(() => {
		// events should already be sorted by date using orderby within the graphql, we need to apply a secondary sort of distance.
		let temporaryEventCards = [];
		const groups = groupByDate(resultData);
		// loop through events rather than groups so that we keep the date ordering
		resultData.forEach((event) => {
			if (groups[event.dateTime]) {
				if (groups[event.dateTime].length === 1) {
					// if theres only one event with this datetime, just add it to array, theres no need to sort
					temporaryEventCards = [
						...temporaryEventCards,
						...groups[event.dateTime],
					];
				} else {
					// if there are multiple events with the same date, we need to sort them by distance then add them to array
					groups[event.dateTime] = groups[event.dateTime]
						.map((eventWithSameDate) => ({
							...eventWithSameDate,
							distance:
								venueDistanceLookup[
									eventWithSameDate.eventLocation.id
								],
						}))
						.sort((a, b) => a.distance - b.distance);
					temporaryEventCards = [
						...temporaryEventCards,
						...groups[event.dateTime],
					];
				}
				// delete group so that we don't process the same group multiple times
				delete groups[event.dateTime];
			}
		});
		setEventCards(temporaryEventCards);
	}, [resultData, venueDistanceLookup]);

	const doFilters = useCallback(() => {
		if (validVenuesAreSet) {
			if (orderBy === 'distance') {
				setEventsFilteredByDistance();
			} else if (orderBy === 'date') {
				setEventsFilteredByDate();
			}
		} else {
			setEventCards(resultData);
		}
	}, [
		validVenuesAreSet,
		setEventsFilteredByDistance,
		orderBy,
		setEventsFilteredByDate,
		resultData,
	]);

	useEffect(() => {
		if (!isLoading) {
			doFilters();
		}
	}, [isLoading, doFilters]);

	const setCurrentLocation = (currentLocation) => {
		if (currentLocation?.lat && currentLocation?.lng) {
			const venueDataWithDistance = venuesData
				.map((venue) => ({
					id: venue.originalId,
					distance: haversine(venue.venueLocation, {
						latitude: currentLocation.lat,
						longitude: currentLocation.lng,
					}),
				}))
				.sort((a, b) => a.distance - b.distance);
			venuesWithDistance.current = venueDataWithDistance;
			const distanceLookup = {};
			venueDataWithDistance.forEach(
				// eslint-disable-next-line no-return-assign
				(venue) => (distanceLookup[venue.id] = venue.distance)
			);
			setVenueDistanceLookup(distanceLookup);
		}
	};

	const loading = eventCards === null || isLoading;

	return {
		eventCards,
		setCurrentLocation,
		setOrderBy,
		setMaxDistance,
		loading,
		error: !!data?.errors,
	};
};
