import axios from 'axios';

const headers = {
	'x-ms-blob-type': 'BlockBlob',
};

export const useSalesforceAzureApi = () => {
	const fetchSalesforceAzure = async (SalesforceData) => {
		const getCurrentTimestamp = () => {
			const date = new Date();
			return date
				.toISOString()
				.replace(/[-:.]/g, '')
				.replace('T', '_')
				.split('.')[0];
		};

		const timestamp = getCurrentTimestamp();
		const fileName = `SalesforceFormData_${timestamp}.json`;

		await axios.put(
			`https://cacwebformlogs.blob.core.windows.net/webformlogs/logs/${fileName}?sv=2022-11-02&ss=bf&srt=o&sp=wactfx&se=2034-04-20T00:03:26Z&st=2024-04-19T16:03:26Z&spr=https&sig=RpXVohP%2BGWtYW8Dq4D6UXZUZOSbRcO5jNr0LXGxZx58%3D`,
			{ SalesforceData },
			{ headers }
		);
	};

	return { fetchSalesforceAzure };
};
