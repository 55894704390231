export const employeeData = {
	1: {
		lateness_absence: 2198,
		staff_turnover: 661,
		hr_docs: 8000,
		hs_docs: 10000,
		disciplinary: 1000,
		ohs_fines: 550,
		general_hr: 2418,
		cost_accidents: 3090,
		average_wrongful: 2205,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 30122,
		annual_cost: 6228,
		cost_per_employee: 519,
		annual_roi: 4.84,
		time_saved: 39,
	},
	2: {
		lateness_absence: 4396,
		staff_turnover: 1322,
		hr_docs: 8500,
		hs_docs: 10000,
		disciplinary: 2004,
		ohs_fines: 1100,
		general_hr: 2418,
		cost_accidents: 3090,
		average_wrongful: 4410,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 37240,
		annual_cost: 6228,
		cost_per_employee: 259.5,
		annual_roi: 5.98,
		time_saved: 60,
	},
	3: {
		lateness_absence: 6594,
		staff_turnover: 1983,
		hr_docs: 10666.6667,
		hs_docs: 10000,
		disciplinary: 4008,
		ohs_fines: 1650,
		general_hr: 2418,
		cost_accidents: 3090,
		average_wrongful: 6615,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 47024.6667,
		annual_cost: 6228,
		cost_per_employee: 173,
		annual_roi: 7.55,
		time_saved: 81,
	},
	4: {
		lateness_absence: 8792,
		staff_turnover: 2644,
		hr_docs: 13250,
		hs_docs: 10000,
		disciplinary: 6012,
		ohs_fines: 2200,
		general_hr: 2418,
		cost_accidents: 3090,
		average_wrongful: 8820,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 57226,
		annual_cost: 6228,
		cost_per_employee: 129.75,
		annual_roi: 9.19,
		time_saved: 103,
	},
	5: {
		lateness_absence: 10990,
		staff_turnover: 3305,
		hr_docs: 16000,
		hs_docs: 10000,
		disciplinary: 8060,
		ohs_fines: 2750,
		general_hr: 2418,
		cost_accidents: 3090,
		average_wrongful: 11025,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 67638,
		annual_cost: 6228,
		cost_per_employee: 103.8,
		annual_roi: 10.86,
		time_saved: 124,
	},
	6: {
		lateness_absence: 13188,
		staff_turnover: 3966,
		hr_docs: 18833.3333,
		hs_docs: 10000,
		disciplinary: 8060,
		ohs_fines: 3300,
		general_hr: 4836,
		cost_accidents: 3090,
		average_wrongful: 11025,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 76298.3333,
		annual_cost: 8148.24,
		cost_per_employee: 113.17,
		annual_roi: 9.36,
		time_saved: 146,
	},
	7: {
		lateness_absence: 15386,
		staff_turnover: 4627,
		hr_docs: 21714.2857,
		hs_docs: 10000,
		disciplinary: 8060,
		ohs_fines: 3850,
		general_hr: 4836,
		cost_accidents: 3090,
		average_wrongful: 11025,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 82588.2857,
		annual_cost: 8148,
		cost_per_employee: 97,
		annual_roi: 10.14,
		time_saved: 167,
	},
	8: {
		lateness_absence: 17584,
		staff_turnover: 5288,
		hr_docs: 24625,
		hs_docs: 10000,
		disciplinary: 8060,
		ohs_fines: 4400,
		general_hr: 4836,
		cost_accidents: 3090,
		average_wrongful: 11025,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 88908,
		annual_cost: 8148.48,
		cost_per_employee: 84.88,
		annual_roi: 10.91,
		time_saved: 188,
	},
	9: {
		lateness_absence: 19782,
		staff_turnover: 5949,
		hr_docs: 27555.5556,
		hs_docs: 10000,
		disciplinary: 8060,
		ohs_fines: 4950,
		general_hr: 4836,
		cost_accidents: 3090,
		average_wrongful: 11025,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 95247.5556,
		annual_cost: 8147.52,
		cost_per_employee: 75.44,
		annual_roi: 11.69,
		time_saved: 210,
	},
	10: {
		lateness_absence: 21980,
		staff_turnover: 6610,
		hr_docs: 30500,
		hs_docs: 10000,
		disciplinary: 8060,
		ohs_fines: 5500,
		general_hr: 4836,
		cost_accidents: 3090,
		average_wrongful: 11025,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 101601,
		annual_cost: 8148,
		cost_per_employee: 67.9,
		annual_roi: 12.47,
		time_saved: 231,
	},
	11: {
		lateness_absence: 24178,
		staff_turnover: 7271,
		hr_docs: 33454.5455,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 6050,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 128527.5455,
		annual_cost: 8556.24,
		cost_per_employee: 64.82,
		annual_roi: 15.02,
		time_saved: 253,
	},
	12: {
		lateness_absence: 26376,
		staff_turnover: 7932,
		hr_docs: 36416.6667,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 6600,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 134898.6667,
		annual_cost: 8964,
		cost_per_employee: 62.25,
		annual_roi: 15.05,
		time_saved: 274,
	},
	13: {
		lateness_absence: 28574,
		staff_turnover: 8593,
		hr_docs: 39384.6154,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 7150,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 141275.6154,
		annual_cost: 9372.48,
		cost_per_employee: 60.08,
		annual_roi: 15.07,
		time_saved: 296,
	},
	14: {
		lateness_absence: 30772,
		staff_turnover: 9254,
		hr_docs: 42357.1429,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 7700,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 147657.1429,
		annual_cost: 9779.28,
		cost_per_employee: 58.21,
		annual_roi: 15.1,
		time_saved: 317,
	},
	15: {
		lateness_absence: 32970,
		staff_turnover: 9915,
		hr_docs: 45333.3333,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 8250,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 154042.3333,
		annual_cost: 10188,
		cost_per_employee: 56.6,
		annual_roi: 15.12,
		time_saved: 338,
	},
	16: {
		lateness_absence: 35168,
		staff_turnover: 10576,
		hr_docs: 48312.5,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 8800,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 160430.5,
		annual_cost: 10571.52,
		cost_per_employee: 55.06,
		annual_roi: 15.18,
		time_saved: 360,
	},
	17: {
		lateness_absence: 37366,
		staff_turnover: 11237,
		hr_docs: 51294.1176,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 9350,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 166821.1176,
		annual_cost: 10956.84,
		cost_per_employee: 53.71,
		annual_roi: 15.23,
		time_saved: 381,
	},
	18: {
		lateness_absence: 39564,
		staff_turnover: 11898,
		hr_docs: 54277.7778,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 9900,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 173213.7778,
		annual_cost: 11340,
		cost_per_employee: 52.5,
		annual_roi: 15.27,
		time_saved: 402,
	},
	19: {
		lateness_absence: 41762,
		staff_turnover: 12559,
		hr_docs: 57263.1579,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 10450,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 179608.1579,
		annual_cost: 11723.76,
		cost_per_employee: 51.42,
		annual_roi: 15.32,
		time_saved: 424,
	},
	20: {
		lateness_absence: 43960,
		staff_turnover: 13220,
		hr_docs: 60250,
		hs_docs: 10000,
		disciplinary: 12090,
		ohs_fines: 11000,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 186004,
		annual_cost: 12108,
		cost_per_employee: 50.45,
		annual_roi: 15.36,
		time_saved: 445,
	},
	21: {
		lateness_absence: 46158,
		staff_turnover: 13881,
		hr_docs: 63238.0952,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 11550,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 196431.0952,
		annual_cost: 13429.08,
		cost_per_employee: 53.29,
		annual_roi: 14.63,
		time_saved: 467,
	},
	22: {
		lateness_absence: 48356,
		staff_turnover: 14542,
		hr_docs: 66227.2727,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 12100,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 202829.2727,
		annual_cost: 13427.04,
		cost_per_employee: 50.86,
		annual_roi: 15.11,
		time_saved: 488,
	},
	23: {
		lateness_absence: 50554,
		staff_turnover: 15203,
		hr_docs: 69217.3913,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 12650,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 209228.3913,
		annual_cost: 13427.4,
		cost_per_employee: 48.65,
		annual_roi: 15.58,
		time_saved: 510,
	},
	24: {
		lateness_absence: 52752,
		staff_turnover: 15864,
		hr_docs: 72208.3333,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 13200,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 215628.3333,
		annual_cost: 13429.44,
		cost_per_employee: 46.63,
		annual_roi: 16.06,
		time_saved: 531,
	},
	25: {
		lateness_absence: 54950,
		staff_turnover: 16525,
		hr_docs: 75200,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 13750,
		general_hr: 7254,
		cost_accidents: 6180,
		average_wrongful: 22050,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 222029,
		annual_cost: 13428,
		cost_per_employee: 44.76,
		annual_roi: 16.53,
		time_saved: 552,
	},
	26: {
		lateness_absence: 57148,
		staff_turnover: 17186,
		hr_docs: 78192.3077,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 14300,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 244963.3077,
		annual_cost: 14626.56,
		cost_per_employee: 46.88,
		annual_roi: 16.75,
		time_saved: 574,
	},
	27: {
		lateness_absence: 59346,
		staff_turnover: 17847,
		hr_docs: 81185.1852,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 14850,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 251365.1852,
		annual_cost: 14628.6,
		cost_per_employee: 45.15,
		annual_roi: 17.18,
		time_saved: 595,
	},
	28: {
		lateness_absence: 61544,
		staff_turnover: 18508,
		hr_docs: 84178.5714,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 15400,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 257767.5714,
		annual_cost: 14629.44,
		cost_per_employee: 43.54,
		annual_roi: 17.62,
		time_saved: 616,
	},
	29: {
		lateness_absence: 63742,
		staff_turnover: 19169,
		hr_docs: 87172.4138,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 15950,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 264170.4138,
		annual_cost: 14626.44,
		cost_per_employee: 42.03,
		annual_roi: 18.06,
		time_saved: 638,
	},
	30: {
		lateness_absence: 65940,
		staff_turnover: 19830,
		hr_docs: 90166.6667,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 16500,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 270573.6667,
		annual_cost: 14626.8,
		cost_per_employee: 40.63,
		annual_roi: 18.5,
		time_saved: 659,
	},
	31: {
		lateness_absence: 68138,
		staff_turnover: 20491,
		hr_docs: 93161.2903,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 17050,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 276977.2903,
		annual_cost: 15828.6,
		cost_per_employee: 42.55,
		annual_roi: 17.5,
		time_saved: 681,
	},
	32: {
		lateness_absence: 70336,
		staff_turnover: 21152,
		hr_docs: 96156.25,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 17600,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 283381.25,
		annual_cost: 15828.48,
		cost_per_employee: 41.22,
		annual_roi: 17.9,
		time_saved: 702,
	},
	33: {
		lateness_absence: 72534,
		staff_turnover: 21813,
		hr_docs: 99151.5152,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 18150,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 289785.5152,
		annual_cost: 15828.12,
		cost_per_employee: 39.97,
		annual_roi: 18.31,
		time_saved: 723,
	},
	34: {
		lateness_absence: 74732,
		staff_turnover: 22474,
		hr_docs: 102147.0588,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 18700,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 296190.0588,
		annual_cost: 15826.32,
		cost_per_employee: 38.79,
		annual_roi: 18.72,
		time_saved: 745,
	},
	35: {
		lateness_absence: 76930,
		staff_turnover: 23135,
		hr_docs: 105142.8571,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 19250,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 302594.8571,
		annual_cost: 15829.8,
		cost_per_employee: 37.69,
		annual_roi: 19.12,
		time_saved: 766,
	},
	36: {
		lateness_absence: 79128,
		staff_turnover: 23796,
		hr_docs: 108138.8889,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 19800,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 308999.8889,
		annual_cost: 16787.52,
		cost_per_employee: 38.86,
		annual_roi: 18.41,
		time_saved: 788,
	},
	37: {
		lateness_absence: 81326,
		staff_turnover: 24457,
		hr_docs: 111135.1351,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 20350,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 315405.1351,
		annual_cost: 16787.64,
		cost_per_employee: 37.81,
		annual_roi: 18.79,
		time_saved: 809,
	},
	38: {
		lateness_absence: 83524,
		staff_turnover: 25118,
		hr_docs: 114131.5789,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 20900,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 321810.5789,
		annual_cost: 16789.92,
		cost_per_employee: 36.82,
		annual_roi: 19.17,
		time_saved: 830,
	},
	39: {
		lateness_absence: 85722,
		staff_turnover: 25779,
		hr_docs: 117128.2051,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 21450,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 328216.2051,
		annual_cost: 16787.16,
		cost_per_employee: 35.87,
		annual_roi: 19.55,
		time_saved: 852,
	},
	40: {
		lateness_absence: 87920,
		staff_turnover: 26440,
		hr_docs: 120125,
		hs_docs: 10000,
		disciplinary: 16120,
		ohs_fines: 22000,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 334622,
		annual_cost: 16790.4,
		cost_per_employee: 34.98,
		annual_roi: 19.93,
		time_saved: 873,
	},
	41: {
		lateness_absence: 90118,
		staff_turnover: 27101,
		hr_docs: 123121.9512,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 22550,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 349087.9512,
		annual_cost: 17628.36,
		cost_per_employee: 35.83,
		annual_roi: 19.8,
		time_saved: 895,
	},
	42: {
		lateness_absence: 92316,
		staff_turnover: 27762,
		hr_docs: 126119.0476,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 23100,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 355494.0476,
		annual_cost: 17629.92,
		cost_per_employee: 34.98,
		annual_roi: 20.16,
		time_saved: 916,
	},
	43: {
		lateness_absence: 94514,
		staff_turnover: 28423,
		hr_docs: 129116.2791,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 23650,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 361900.2791,
		annual_cost: 17626.56,
		cost_per_employee: 34.16,
		annual_roi: 20.53,
		time_saved: 937,
	},
	44: {
		lateness_absence: 96712,
		staff_turnover: 29084,
		hr_docs: 132113.6364,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 24200,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 368306.6364,
		annual_cost: 17629.92,
		cost_per_employee: 33.39,
		annual_roi: 20.89,
		time_saved: 959,
	},
	45: {
		lateness_absence: 98910,
		staff_turnover: 29745,
		hr_docs: 135111.1111,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 24750,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 374713.1111,
		annual_cost: 17625.6,
		cost_per_employee: 32.64,
		annual_roi: 21.26,
		time_saved: 980,
	},
	46: {
		lateness_absence: 101108,
		staff_turnover: 30406,
		hr_docs: 138108.6957,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 25300,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 381119.6957,
		annual_cost: 18348.48,
		cost_per_employee: 33.24,
		annual_roi: 20.77,
		time_saved: 1002,
	},
	47: {
		lateness_absence: 103306,
		staff_turnover: 31067,
		hr_docs: 141106.383,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 25850,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 387526.383,
		annual_cost: 18346.92,
		cost_per_employee: 32.53,
		annual_roi: 21.12,
		time_saved: 1023,
	},
	48: {
		lateness_absence: 105504,
		staff_turnover: 31728,
		hr_docs: 144104.1667,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 26400,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 393933.1667,
		annual_cost: 18345.6,
		cost_per_employee: 31.85,
		annual_roi: 21.47,
		time_saved: 1044,
	},
	49: {
		lateness_absence: 107702,
		staff_turnover: 32389,
		hr_docs: 147102.0408,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 26950,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: '',
		hs_manager_cost: '',
		total_cost: 400340.0408,
		annual_cost: 18345.6,
		cost_per_employee: 31.2,
		annual_roi: 21.82,
		time_saved: 1066,
	},
	50: {
		lateness_absence: 109900,
		staff_turnover: 33050,
		hr_docs: 150100,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 27500,
		general_hr: 9672,
		cost_accidents: 9270,
		average_wrongful: 33075,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 626747,
		annual_cost: 18348,
		cost_per_employee: 30.58,
		annual_roi: 34.16,
		time_saved: 1087,
	},
	51: {
		lateness_absence: 112098,
		staff_turnover: 33711,
		hr_docs: 153098.0392,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 28050,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 649687.0392,
		annual_cost: 18825.12,
		cost_per_employee: 30.76,
		annual_roi: 34.51,
		time_saved: 1109,
	},
	52: {
		lateness_absence: 114296,
		staff_turnover: 34372,
		hr_docs: 156096.1538,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 28600,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 656094.1538,
		annual_cost: 18826.08,
		cost_per_employee: 30.17,
		annual_roi: 34.85,
		time_saved: 1130,
	},
	53: {
		lateness_absence: 116494,
		staff_turnover: 35033,
		hr_docs: 159094.3396,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 29150,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 662501.3396,
		annual_cost: 18825.6,
		cost_per_employee: 29.6,
		annual_roi: 35.19,
		time_saved: 1151,
	},
	54: {
		lateness_absence: 118692,
		staff_turnover: 35694,
		hr_docs: 162092.5926,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 29700,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 668908.5926,
		annual_cost: 18830.88,
		cost_per_employee: 29.06,
		annual_roi: 35.52,
		time_saved: 1173,
	},
	55: {
		lateness_absence: 120890,
		staff_turnover: 36355,
		hr_docs: 165090.9091,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 30250,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 675315.9091,
		annual_cost: 18829.8,
		cost_per_employee: 28.53,
		annual_roi: 35.86,
		time_saved: 1194,
	},
	56: {
		lateness_absence: 123088,
		staff_turnover: 37016,
		hr_docs: 168089.2857,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 30800,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 681723.2857,
		annual_cost: 18829.44,
		cost_per_employee: 28.02,
		annual_roi: 36.21,
		time_saved: 1216,
	},
	57: {
		lateness_absence: 125286,
		staff_turnover: 37677,
		hr_docs: 171087.7193,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 31350,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 688130.7193,
		annual_cost: 18830.52,
		cost_per_employee: 27.53,
		annual_roi: 36.54,
		time_saved: 1237,
	},
	58: {
		lateness_absence: 127484,
		staff_turnover: 38338,
		hr_docs: 174086.2069,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 31900,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 694538.2069,
		annual_cost: 18826.8,
		cost_per_employee: 27.05,
		annual_roi: 36.89,
		time_saved: 1258,
	},
	59: {
		lateness_absence: 129682,
		staff_turnover: 38999,
		hr_docs: 177084.7458,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 32450,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 700945.7458,
		annual_cost: 18825.72,
		cost_per_employee: 26.59,
		annual_roi: 37.23,
		time_saved: 1280,
	},
	60: {
		lateness_absence: 131880,
		staff_turnover: 39660,
		hr_docs: 180083.3333,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 33000,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 707353.3333,
		annual_cost: 18828,
		cost_per_employee: 26.15,
		annual_roi: 37.57,
		time_saved: 1301,
	},
	61: {
		lateness_absence: 134078,
		staff_turnover: 40321,
		hr_docs: 183081.9672,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 33550,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 713760.9672,
		annual_cost: 19310.16,
		cost_per_employee: 26.38,
		annual_roi: 36.96,
		time_saved: 1323,
	},
	62: {
		lateness_absence: 136276,
		staff_turnover: 40982,
		hr_docs: 186080.6452,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 34100,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 720168.6452,
		annual_cost: 19306.8,
		cost_per_employee: 25.95,
		annual_roi: 37.3,
		time_saved: 1344,
	},
	63: {
		lateness_absence: 138474,
		staff_turnover: 41643,
		hr_docs: 189079.3651,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 34650,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 726576.3651,
		annual_cost: 19308.24,
		cost_per_employee: 25.54,
		annual_roi: 37.63,
		time_saved: 1365,
	},
	64: {
		lateness_absence: 140672,
		staff_turnover: 42304,
		hr_docs: 192078.125,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 35200,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 732984.125,
		annual_cost: 19307.52,
		cost_per_employee: 25.14,
		annual_roi: 37.96,
		time_saved: 1387,
	},
	65: {
		lateness_absence: 142870,
		staff_turnover: 42965,
		hr_docs: 195076.9231,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 35750,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 739391.9231,
		annual_cost: 19305,
		cost_per_employee: 24.75,
		annual_roi: 38.3,
		time_saved: 1408,
	},
	66: {
		lateness_absence: 145068,
		staff_turnover: 43626,
		hr_docs: 198075.7576,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 36300,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 745799.7576,
		annual_cost: 19308.96,
		cost_per_employee: 24.38,
		annual_roi: 38.62,
		time_saved: 1430,
	},
	67: {
		lateness_absence: 147266,
		staff_turnover: 44287,
		hr_docs: 201074.6269,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 36850,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 752207.6269,
		annual_cost: 19304.04,
		cost_per_employee: 24.01,
		annual_roi: 38.97,
		time_saved: 1451,
	},
	68: {
		lateness_absence: 149464,
		staff_turnover: 44948,
		hr_docs: 204073.5294,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 37400,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 758615.5294,
		annual_cost: 19306.56,
		cost_per_employee: 23.66,
		annual_roi: 39.29,
		time_saved: 1472,
	},
	69: {
		lateness_absence: 151662,
		staff_turnover: 45609,
		hr_docs: 207072.4638,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 37950,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 765023.4638,
		annual_cost: 19308.96,
		cost_per_employee: 23.32,
		annual_roi: 39.62,
		time_saved: 1494,
	},
	70: {
		lateness_absence: 153860,
		staff_turnover: 46270,
		hr_docs: 210071.4286,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 38500,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 771431.4286,
		annual_cost: 19311.6,
		cost_per_employee: 22.99,
		annual_roi: 39.95,
		time_saved: 1515,
	},
	71: {
		lateness_absence: 156058,
		staff_turnover: 46931,
		hr_docs: 213070.4225,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 39050,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 777839.4225,
		annual_cost: 19911.24,
		cost_per_employee: 23.37,
		annual_roi: 39.07,
		time_saved: 1537,
	},
	72: {
		lateness_absence: 158256,
		staff_turnover: 47592,
		hr_docs: 216069.4444,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 39600,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 784247.4444,
		annual_cost: 19906.56,
		cost_per_employee: 23.04,
		annual_roi: 39.4,
		time_saved: 1558,
	},
	73: {
		lateness_absence: 160454,
		staff_turnover: 48253,
		hr_docs: 219068.4932,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 40150,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 790655.4932,
		annual_cost: 19911.48,
		cost_per_employee: 22.73,
		annual_roi: 39.71,
		time_saved: 1579,
	},
	74: {
		lateness_absence: 162652,
		staff_turnover: 48914,
		hr_docs: 222067.5676,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 40700,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 797063.5676,
		annual_cost: 19908.96,
		cost_per_employee: 22.42,
		annual_roi: 40.04,
		time_saved: 1601,
	},
	75: {
		lateness_absence: 164850,
		staff_turnover: 49575,
		hr_docs: 225066.6667,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 41250,
		general_hr: 12090,
		cost_accidents: 12360,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 803471.6667,
		annual_cost: 19908,
		cost_per_employee: 22.12,
		annual_roi: 40.36,
		time_saved: 1622,
	},
	76: {
		lateness_absence: 167048,
		staff_turnover: 50236,
		hr_docs: 228065.7895,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 41800,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 815477.7895,
		annual_cost: 19908.96,
		cost_per_employee: 21.83,
		annual_roi: 40.96,
		time_saved: 1644,
	},
	77: {
		lateness_absence: 169246,
		staff_turnover: 50897,
		hr_docs: 231064.9351,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 42350,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 821885.9351,
		annual_cost: 19912.2,
		cost_per_employee: 21.55,
		annual_roi: 41.28,
		time_saved: 1665,
	},
	78: {
		lateness_absence: 171444,
		staff_turnover: 51558,
		hr_docs: 234064.1026,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 42900,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 828294.1026,
		annual_cost: 19908.72,
		cost_per_employee: 21.27,
		annual_roi: 41.6,
		time_saved: 1686,
	},
	79: {
		lateness_absence: 173642,
		staff_turnover: 52219,
		hr_docs: 237063.2911,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 43450,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 834702.2911,
		annual_cost: 19908,
		cost_per_employee: 21,
		annual_roi: 41.93,
		time_saved: 1708,
	},
	80: {
		lateness_absence: 175840,
		staff_turnover: 52880,
		hr_docs: 240062.5,
		hs_docs: 10000,
		disciplinary: 24180,
		ohs_fines: 44000,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 841110.5,
		annual_cost: 19910.4,
		cost_per_employee: 20.74,
		annual_roi: 42.24,
		time_saved: 1729,
	},
	81: {
		lateness_absence: 178038,
		staff_turnover: 53541,
		hr_docs: 243061.7284,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 44550,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 855578.7284,
		annual_cost: 20392.56,
		cost_per_employee: 20.98,
		annual_roi: 41.96,
		time_saved: 1751,
	},
	82: {
		lateness_absence: 180236,
		staff_turnover: 54202,
		hr_docs: 246060.9756,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 45100,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 861986.9756,
		annual_cost: 20388.48,
		cost_per_employee: 20.72,
		annual_roi: 42.28,
		time_saved: 1772,
	},
	83: {
		lateness_absence: 182434,
		staff_turnover: 54863,
		hr_docs: 249060.241,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 45650,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 868395.241,
		annual_cost: 20388.12,
		cost_per_employee: 20.47,
		annual_roi: 42.59,
		time_saved: 1793,
	},
	84: {
		lateness_absence: 184632,
		staff_turnover: 55524,
		hr_docs: 252059.5238,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 46200,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 874803.5238,
		annual_cost: 20391.84,
		cost_per_employee: 20.23,
		annual_roi: 42.9,
		time_saved: 1815,
	},
	85: {
		lateness_absence: 186830,
		staff_turnover: 56185,
		hr_docs: 255058.8235,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 46750,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 881211.8235,
		annual_cost: 20389.8,
		cost_per_employee: 19.99,
		annual_roi: 43.22,
		time_saved: 1836,
	},
	86: {
		lateness_absence: 189028,
		staff_turnover: 56846,
		hr_docs: 258058.1395,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 47300,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 887620.1395,
		annual_cost: 20392.32,
		cost_per_employee: 19.76,
		annual_roi: 43.53,
		time_saved: 1858,
	},
	87: {
		lateness_absence: 191226,
		staff_turnover: 57507,
		hr_docs: 261057.4713,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 47850,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 894028.4713,
		annual_cost: 20389.32,
		cost_per_employee: 19.53,
		annual_roi: 43.85,
		time_saved: 1879,
	},
	88: {
		lateness_absence: 193424,
		staff_turnover: 58168,
		hr_docs: 264056.8182,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 48400,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 900436.8182,
		annual_cost: 20391.36,
		cost_per_employee: 19.31,
		annual_roi: 44.16,
		time_saved: 1900,
	},
	89: {
		lateness_absence: 195622,
		staff_turnover: 58829,
		hr_docs: 267056.1798,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 48950,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 906845.1798,
		annual_cost: 20388.12,
		cost_per_employee: 19.09,
		annual_roi: 44.48,
		time_saved: 1922,
	},
	90: {
		lateness_absence: 197820,
		staff_turnover: 59490,
		hr_docs: 270055.5556,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 49500,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 913253.5556,
		annual_cost: 20390.4,
		cost_per_employee: 18.88,
		annual_roi: 44.79,
		time_saved: 1943,
	},
	91: {
		lateness_absence: 200018,
		staff_turnover: 60151,
		hr_docs: 273054.9451,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 50050,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 919661.9451,
		annual_cost: 20868.12,
		cost_per_employee: 19.11,
		annual_roi: 44.07,
		time_saved: 1965,
	},
	92: {
		lateness_absence: 202216,
		staff_turnover: 60812,
		hr_docs: 276054.3478,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 50600,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 926070.3478,
		annual_cost: 20865.6,
		cost_per_employee: 18.9,
		annual_roi: 44.38,
		time_saved: 1986,
	},
	93: {
		lateness_absence: 204414,
		staff_turnover: 61473,
		hr_docs: 279053.7634,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 51150,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 932478.7634,
		annual_cost: 20869.2,
		cost_per_employee: 18.7,
		annual_roi: 44.68,
		time_saved: 2007,
	},
	94: {
		lateness_absence: 206612,
		staff_turnover: 62134,
		hr_docs: 282053.1915,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 51700,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 938887.1915,
		annual_cost: 20868,
		cost_per_employee: 18.5,
		annual_roi: 44.99,
		time_saved: 2029,
	},
	95: {
		lateness_absence: 208810,
		staff_turnover: 62795,
		hr_docs: 285052.6316,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 52250,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 945295.6316,
		annual_cost: 20873.4,
		cost_per_employee: 18.31,
		annual_roi: 45.29,
		time_saved: 2050,
	},
	96: {
		lateness_absence: 211008,
		staff_turnover: 63456,
		hr_docs: 288052.0833,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 52800,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 951704.0833,
		annual_cost: 20862.72,
		cost_per_employee: 18.11,
		annual_roi: 45.62,
		time_saved: 2072,
	},
	97: {
		lateness_absence: 213206,
		staff_turnover: 64117,
		hr_docs: 291051.5464,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 53350,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 958112.5464,
		annual_cost: 20870.52,
		cost_per_employee: 17.93,
		annual_roi: 45.91,
		time_saved: 2093,
	},
	98: {
		lateness_absence: 215404,
		staff_turnover: 64778,
		hr_docs: 294051.0204,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 53900,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 964521.0204,
		annual_cost: 20862.24,
		cost_per_employee: 17.74,
		annual_roi: 46.23,
		time_saved: 2114,
	},
	99: {
		lateness_absence: 217602,
		staff_turnover: 65439,
		hr_docs: 297050.5051,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 54450,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 970929.5051,
		annual_cost: 20873.16,
		cost_per_employee: 17.57,
		annual_roi: 46.52,
		time_saved: 2136,
	},
	100: {
		lateness_absence: 219800,
		staff_turnover: 66100,
		hr_docs: 300050,
		hs_docs: 10000,
		disciplinary: 32240,
		ohs_fines: 55000,
		general_hr: 14508,
		cost_accidents: 15540,
		average_wrongful: 44100,
		hr_manager_cost: 81000,
		hs_manager_cost: 139000,
		total_cost: 977338,
		annual_cost: 20868,
		cost_per_employee: 17.39,
		annual_roi: 46.83,
		time_saved: 2157,
	},
};
