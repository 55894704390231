/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import blueTick from './blue-tick.svg';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../../atoms/button';

const StyledDiv = styled.div`
	/* Media Query for screens 640px and wider */
	background: -moz-linear-gradient(
		235deg,
		rgba(237, 244, 255, 1) 0%,
		rgba(215, 221, 249, 1) 100%
	);
	background: -webkit-linear-gradient(
		235deg,
		rgba(237, 244, 255, 1) 0%,
		rgba(215, 221, 249, 1) 100%
	);
	background: linear-gradient(
		235deg,
		rgba(237, 244, 255, 1) 0%,
		rgba(215, 221, 249, 1) 100%
	);
	@media (min-width: 640px) and (max-width: 1023px) {
		background-image: url(${(props) => props.backgroundUrl}),
			linear-gradient(
				235deg,
				rgba(237, 244, 255, 1) 0%,
				rgba(215, 221, 249, 1) 100%
			);
		background-repeat: no-repeat;
		background-position: top right;
	}
	@media (min-width: 1280px) {
		background-image: url(${(props) => props.backgroundUrl}),
			linear-gradient(
				235deg,
				rgba(237, 244, 255, 1) 0%,
				rgba(215, 221, 249, 1) 100%
			);
		background-repeat: no-repeat;
		background-position: top right;
	}
`;

const WhyUsVideoCampaignCA = ({ whyUsSectionContent, modalRef, className }) => {
	const { setModalRef } = useModal();
	const { title, subText, content, primaryLinkCopy } = whyUsSectionContent;

	const lastContentItems = content[content.length - 1];

	const titleText = createHeadingHighlightCA({
		headings: title.value.document.children[0].children,
		variant: 'red',
	});

	const subTitle = createHeadingHighlightCA({
		headings: subText.value.document.children[0].children,
		variant: 'white',
		className: 'text-[20px] -mt-5',
	});

	return (
		<div
			className={`flex flex-col items-center gap-10 px-6 pt-7 font-centra-light text-center text-[20px] lg:px-12 lg:pb-32 lg:pt-24 xl:py-24 mx-auto lg:max-w-m-screen ${className}`}
		>
			<div className="">{titleText}</div>
			<div className="lg:px-10 xl:px-0">{subTitle}</div>
			<div className="flex flex-col lg:flex-row w-full gap-5 text-left font-centra-light font-semibold">
				<div className="w-full lg:w-1/2 flex flex-col gap-5">
					{/* Left div for all items except the last one */}
					{content.map((elm, idx) => (
						<>
							{content.length - 1 !== idx ? (
								<div className="flex flex-col flex-grow items-baseline p-10 lg:flex-row lg:gap-10 justify-start lg:items-center bg-[#FEF0F3] lg:p-5 border-2 border-[#FCD8E1] rounded-[.75rem] shadow-sm">
									<div className="mx-auto lg:mx-0">
										<img
											src={elm.primaryImage.url}
											alt=""
											className="hidden min-h-[156px] min-w-[156px] xl:block"
										/>
									</div>
									<div className="flex flex-col text-left">
										{/* content */}
										<div className="mb-1">
											{createHeadingHighlightCA({
												headings:
													elm.title.value.document
														.children[0].children,
												variant: '',
												className:
													'text-black text-[15px]',
											})}
										</div>
										<div className="mb-5">
											{createHeadingHighlightCA({
												headings:
													elm.subText.value.document
														.children[0].children,
												variant: '',
												className:
													'text-[27px] text-[#E30138] font-centra-light font-semi-bold leading-8',
											})}
										</div>
										{elm.list.map((item) => (
											<div className="flex flex-col leading-[1.35rem]">
												<div className="text-[16px]">
													{item.text}
												</div>
											</div>
										))}
									</div>
								</div>
							) : null}
						</>
					))}
				</div>
				{/* Right div for last content */}
				<StyledDiv
					backgroundUrl={lastContentItems.primaryImage.url}
					className="flex flex-grow w-full lg:w-1/2 bg-[#EDF4FF] p-10 items-center content-center border-2 border-[#E2E9F6] rounded-[.75rem] shadow-sm"
				>
					{/* Right div */}
					<div className="flex flex-col text-left">
						{/* content */}
						<div className="mb-1">
							{createHeadingHighlightCA({
								headings:
									lastContentItems.title.value.document
										.children[0].children,
								variant: '',
								className: 'text-black text-[15px]',
							})}
						</div>
						<div className="mb-5">
							{createHeadingHighlightCA({
								headings:
									lastContentItems.subText.value.document
										.children[0].children,
								variant: '',
								className:
									'text-[27px] text-[#3954E0] font-centra-light font-semi-bold leading-8',
							})}
						</div>
						{lastContentItems.list.map((item) => (
							<div className="flex flex-row gap-5 mb-2 leading-[1.35rem]">
								<div>
									<img
										src={blueTick}
										alt=""
										className="min-w-[20px] min-h-[17px] pt-1"
									/>
								</div>
								<div className="text-[16px]">{item.text}</div>
							</div>
						))}
					</div>
				</StyledDiv>
			</div>
			<div className="pb-0 md:pb-12 lg:pb-0">
				<Button
					onClick={() => setModalRef(modalRef)}
					className="hidden md:block transition delay-150 duration-1000 inline-flex items-center px-6 py-4 mt-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
				>
					{primaryLinkCopy}
				</Button>
				{/* for mobile */}
				<Button
					onClick={() => {
						window.location.href = '#adviceform';
					}}
					className="block md:hidden transition delay-150 duration-1000 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 max-w-fit break-words"
				>
					{primaryLinkCopy}
				</Button>
			</div>
		</div>
	);
};

export default WhyUsVideoCampaignCA;
