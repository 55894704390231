export const MktoForms2PerInstanceManager = (instanceBaseURLs) => {
	const instances = new Map();
	const isBrowser = typeof window !== 'undefined';

	const genStack = () => {
		const tempError = new Error();
		const { stack } = tempError;

		if (stack) {
			return stack;
		}
		try {
			throw tempError;
		} catch (error) {
			return error.stack;
		}
	};

	const getInstanceURLFromStack = (stack) =>
		instanceBaseURLs.filter((url) => stack.indexOf(url) !== -1)[0];

	if (isBrowser) {
		Object.defineProperty(window, 'MktoForms2', {
			configurable: true,
			get() {
				const stack = genStack();
				const instanceKey = getInstanceURLFromStack(stack);

				return instances.get(instanceKey);
			},
			set(library) {
				const stack = genStack();
				const instanceKey = getInstanceURLFromStack(stack);

				instances.set(instanceKey, library);
			},
		});
	}

	return instances;
};

export const validateString = (value) => {
	let error;
	if (!value) {
		error = true;
	} else {
		error = false;
	}
	return error;
};

export const validateJobTitle = (value) => {
	let error;
	const jobTitleRegex = /^[a-z\d]{2,}/i;

	if (!value) {
		error = 'Please enter your job title';
	} else if (!jobTitleRegex.test(value)) {
		error = 'Please enter at least 2 characters';
	} else {
		error = false;
	}

	return error;
};

export const validateEmail = (value) => {
	let error;
	const emailAddressRegex =
		/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/i;
	// /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

	if (!value) {
		error = 'Please enter your email address';
	} else if (!emailAddressRegex.test(value)) {
		error = true;
	} else {
		error = false;
	}
	return error;
};

export const validatePhoneNumber = (value) => {
	let error;
	const phoneRegex = /^(?:[+\d].*\d|\d)$/;

	if (!value) {
		error = true;
	} else if (!phoneRegex.test(value)) {
		error = true;
	} else {
		error = false;
	}

	return error;
};
