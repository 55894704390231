import React from 'react';
import { getCookie } from '../../utils';

function useFindCookie({ name }) {
	const [hasCookie, setHasCookie] = React.useState(false);
	const [findingCookie, setFindingCookie] = React.useState(true);
	React.useEffect(() => {
		const awaitCookie = setInterval(() => {
			if (getCookie(name)) {
				setHasCookie(true);
			} else {
				setHasCookie(false);
			}
			setFindingCookie(false);
		}, 100);

		return () => clearInterval(awaitCookie);
	}, [name]);
	return { hasCookie, findingCookie, setFindingCookie, setHasCookie };
}

export default useFindCookie;
