// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq';
import { IconCards } from '../components/organisms/icon-cards';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials';
import { pageTypes, resolveUrl } from '../utils/url-helpers';

import { FeefoTestimonials } from '../components/molecules/feefo';
import { MainHeader } from '../components/molecules/main-header';
import { OurSoftware } from '../components/molecules/our-software';
import { RelatedCards } from '../components/molecules/related-cards';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { TwoColText } from '../components/molecules/two-col-text';

import { Drawer } from '../components/atoms/drawer';
import { ReviewBanner } from '../components/atoms/review-banner';

import ModalWrapper from '../components/glu/molecules/modal-wrapper';
import { CardContainer } from '../components/organisms/card-container';
import { PeninsulaModalForm } from '../components/organisms/modal-peninsula-now';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import useModal from '../hooks/modal';
import usePeninsulaNow from '../hooks/peninsula-now';
import {
	getBreadcrumbsSeoSchema,
	getFAQSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';

// markup
const ChildServicePage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsChildServicePage.noIndex,
		noFollow: data.datoCmsChildServicePage.noFollow,
		metaInformation: data.datoCmsChildServicePage.metaInformation,
		fallbackTitle: data.datoCmsChildServicePage.title,
		fallbackDescription: data.datoCmsChildServicePage.longIntroduction,
		canonicalUrl:
			data.datoCmsChildServicePage?.canonicalUrl || location.href,
	};

	const adviceBannerContent = {
		...data.datoCmsChildServicePage.adviceBanner,
		title: data.datoCmsChildServicePage.adviceBanner.title.value.document
			.children,
	};

	const twoColumnText = {
		...data.datoCmsChildServicePage.twoColumnText,
		title: data.datoCmsChildServicePage.twoColumnText.title.value.document
			.children,
		content:
			data.datoCmsChildServicePage.twoColumnText.content.value.document
				.children,
	};

	const headerCTAContent = {
		...data.datoCmsChildServicePage.mainHeader,
		header: data.datoCmsChildServicePage.mainHeader.header.value.document
			.children,
	};

	const testimonialsContent = {
		...data.datoCmsChildServicePage.testimonials,
		title: data.datoCmsChildServicePage.testimonials.title.value.document
			.children,
		slides: data.datoCmsChildServicePage.testimonials.slides.map(
			(slide) => ({
				...slide,
				tags: slide.tags.split(','),
			})
		),
	};

	const faqContent = {
		title: data.datoCmsChildServicePage.faqContainer.title,
		subText: data.datoCmsChildServicePage.faqContainer.subText,
		questions: data.datoCmsChildServicePage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsChildServicePage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsChildServicePage?.customFormField[0]
				?.customFormSubtitle,
		customFormButton:
			data.datoCmsChildServicePage?.customFormField[0]?.customFormButton,
	};

	const uspBanner = data.datoCmsChildServicePage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsChildServicePage.uspCtaCardsBannerDisplayLinks;

	const textWithImageContainer = {
		...data.datoCmsChildServicePage.textWithImageContainer,
		title: data.datoCmsChildServicePage.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsChildServicePage.textWithImageContainer.firstContent,
		secondContent:
			data.datoCmsChildServicePage.textWithImageContainer.secondContent,
	};

	const textWithImageContainerOne = data.datoCmsChildServicePage
		.textWithImageContainerDisplayOnPageOne && {
		...data.datoCmsChildServicePage.textWithImageContainerOne,
		title: data.datoCmsChildServicePage.textWithImageContainerOne.title
			.value.document.children,
		firstContent:
			data.datoCmsChildServicePage.textWithImageContainerOne.firstContent,
		secondContent:
			data.datoCmsChildServicePage.textWithImageContainerOne
				.secondContent,
	};

	const textWithImageContainerTwo = data.datoCmsChildServicePage
		.textWithImageContainerDisplayOnPageTwo && {
		...data.datoCmsChildServicePage.textWithImageContainerTwo,
		title: data.datoCmsChildServicePage.textWithImageContainerTwo.title
			.value.document.children,
		firstContent:
			data.datoCmsChildServicePage.textWithImageContainerTwo.firstContent,
		secondContent:
			data.datoCmsChildServicePage.textWithImageContainerTwo
				.secondContent,
	};

	const textWithImage = {
		...data.datoCmsChildServicePage.textWithImage,
		content: data.datoCmsChildServicePage.textWithImage.content,
	};

	const reviewBannerContent = {
		title: data.datoCmsChildServicePage.reviewBannerTitle,
		subText: data.datoCmsChildServicePage.reviewBannerSubtext,
		reviewer: data.datoCmsChildServicePage.reviewBannerReviewer,
	};

	const relatedPages = data.datoCmsChildServicePage.relatedPages.map(
		(page) => ({
			title: page.title,
			intro: page.intro,
			to: `/${resolveUrl(page)}`,
		})
	);

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.chlidService,
			location,
			data: data.datoCmsChildServicePage,
		}),
		image: getImageSeoSchema(
			data.datoCmsChildServicePage?.mainHeader?.primaryImage
		),
		faq: getFAQSeoSchema(faqContent?.questions),
	};
	const { locale } = data.datoCmsChildServicePage;
	const ref = React.useRef();
	const { peninsulaNow } = data.datoCmsChildServicePage;
	const { isModalOpen, setModalOpen } = useModal(ref);
	const { isOnline, modalOnline } = usePeninsulaNow();
	const openModal = () => {
		if (peninsulaNow && modalOnline) {
			setModalOpen(true);
		}
	};
	const blogs = data.allDatoCmsBlogPage.nodes;
	const guides = data.allDatoCmsGuidePage.nodes;
	const mergedContent = [...blogs, ...guides].sort((a, b) =>
		new Date(a.publishedDate) > new Date(b.publishedDate) ? -1 : 1
	);
	const blogContainerContent = {
		...data.datoCmsChildServicePage.relatedBlogContainer,
		cards: mergedContent.slice(0, 3),
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsChildServicePage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{data.datoCmsChildServicePage.mainHeaderDisplayOnPage && (
				<MainHeader
					urlLocale={locale}
					drawerRef={adviceFormRef}
					pageName="childService"
					openModal={openModal}
					peninsulaNow={peninsulaNow && modalOnline}
					{...headerCTAContent}
					reference={reference}
					response={response}
					setReference={setReference}
					setResponse={setResponse}
				/>
			)}
			{data.datoCmsChildServicePage.uspCtaCardsBannerDisplayOnPage && (
				<IconCards
					iconCardsContent={{ uspCards: uspBanner }}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}
			{data.datoCmsChildServicePage
				.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					locale={locale}
					textWithImageContainerContent={textWithImageContainer}
					drawerRef={adviceFormRef}
				/>
			)}
			{data.datoCmsChildServicePage.adviceBannerDisplayOnPage &&
				locale !== 'en-CA' && (
					<AdviceBanner
						drawerRef={adviceFormRef}
						pageName="childService"
						openModal={openModal}
						peninsulaNow={peninsulaNow && modalOnline}
						adviceBannerContent={adviceBannerContent}
					/>
				)}

			{data.datoCmsChildServicePage
				.textWithImageContainerDisplayOnPageOne && (
				<TextWithImageContainer
					locale={locale}
					textWithImageContainerContent={textWithImageContainerOne}
					drawerRef={adviceFormRef}
				/>
			)}

			{data.datoCmsChildServicePage
				.textWithImageContainerDisplayOnPageTwo && (
				<TextWithImageContainer
					locale={locale}
					textWithImageContainerContent={textWithImageContainerTwo}
					drawerRef={adviceFormRef}
				/>
			)}
			{data.datoCmsChildServicePage.twoColumnTextDisplayOnPage && (
				<TwoColText
					drawerRef={adviceFormRef}
					pageName="childService"
					openModal={openModal}
					peninsulaNow={peninsulaNow && modalOnline}
					textColTextContent={twoColumnText}
				/>
			)}
			{data.datoCmsChildServicePage.trustPilotBannerDisplayOnPage && (
				<TrustpilotReviews locale={locale} />
			)}
			{data.datoCmsChildServicePage.textWithImageDisplayOnPage && (
				<TextWithImageContainer
					locale={locale}
					textWithImageContainerContent={textWithImage}
				/>
			)}
			{data.datoCmsChildServicePage.ourSoftwareDisplayOnPage && (
				<OurSoftware
					drawerRef={adviceFormRef}
					variant="Dark"
					ourSoftwareContent={
						data.datoCmsChildServicePage.ourSoftware
					}
				/>
			)}
			{locale === 'en' &&
				mergedContent.length > 0 &&
				data.datoCmsChildServicePage
					.relatedBlogContentDisplayOnPage && (
					<CardContainer
						cardContainerContent={{
							...blogContainerContent,
							to: '/resource-hub/blogs',
							toAll: '/resource-hub/blogs',
						}}
						cardVariant="Blog"
						variant="Center"
						location={location.pathname}
					/>
				)}
			{locale !== 'en-CA' &&
				data.datoCmsChildServicePage
					.feefoTestimonialsBannerDisplayOnPage && (
					<FeefoTestimonials locale={locale} />
				)}
			{data.datoCmsChildServicePage.faqContainerDisplayOnPage && (
				<FAQ faqContent={faqContent} />
			)}
			{data.datoCmsChildServicePage.reviewBannerDisplayOnPage && (
				<ReviewBanner reviewBannerContent={reviewBannerContent} />
			)}
			{data.datoCmsChildServicePage.testimonialsDisplayOnPage && (
				<Testimonials
					drawerRef={adviceFormRef}
					testimonialsContent={testimonialsContent}
				/>
			)}
			{data.datoCmsChildServicePage.relatedPagesDisplayOnPage && (
				<RelatedCards
					title={data.datoCmsChildServicePage.relatedPagesTitle}
					relatedCards={relatedPages}
				/>
			)}
			{data.datoCmsChildServicePage.adviceBannerDisplayOnPage &&
				locale === 'en-CA' && (
					<AdviceBanner
						drawerRef={adviceFormRef}
						pageName="childService"
						openModal={openModal}
						peninsulaNow={peninsulaNow && modalOnline}
						adviceBannerContent={adviceBannerContent}
					/>
				)}

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							pageName="childService"
							setReference={setReference}
							setResponse={setResponse}
							openModal={openModal}
							isOnline={modalOnline && peninsulaNow}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							pageName="childService"
							setReference={setReference}
							setResponse={setResponse}
							customFormFieldContent={customFormField}
							openModal={openModal}
							isOnline={modalOnline && peninsulaNow}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}

			{isModalOpen && (
				<ModalWrapper>
					<div
						className="relative 2xl:w-7/12 xl:w-[100%]  m-auto opacity-100 h-auto w-full 2xl:rounded-md shadow-xl bg-white overflow-auto"
						ref={ref}
					>
						<button
							title="Close Peninsula Now Modal"
							type="button"
							onClick={() => setModalOpen(false)}
							className="absolute flex top-[5px] text-2xl bg-tranparent font-bold right-[10px] opacity-100 border-2 border-brand-pale-200 h-9 w-9 items-center justify-center rounded-full"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="20"
								height="20"
								viewBox="0 0 50 50"
								className="font-bold fill-current text-brand-pale-200"
							>
								<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
							</svg>
						</button>
						<PeninsulaModalForm isOnline={isOnline} />
					</div>
				</ModalWrapper>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query childService($slug: String!, $locale: String!, $tag: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsChildServicePage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			adviceBannerDisplayOnPage
			mainHeaderDisplayOnPage
			ourSoftwareDisplayOnPage
			testimonialsDisplayOnPage
			peninsulaNow
			slug
			title
			faqContainerDisplayOnPage
			trustPilotBannerDisplayOnPage
			twoColumnTextDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			feefoTestimonialsBannerDisplayOnPage
			textWithImageDisplayOnPage
			textWithImageContainerDisplayOnPage
			textWithImageContainerDisplayOnPageOne
			textWithImageContainerDisplayOnPageTwo
			reviewBannerDisplayOnPage
			relatedPagesDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				showInlineForm
				formPosition
				note
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			twoColumnText {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
			relatedBlogContentDisplayOnPage
			relatedBlogContainer {
				id
				originalId
				subText
				title
				buttonCopy
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			ourSoftware {
				title
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				mobileImage {
					alt
					url
					gatsbyImageData(
						width: 230
						height: 277
						layout: CONSTRAINED
					)
				}
				desktopImage {
					alt
					url
					gatsbyImageData(
						width: 280
						height: 568
						layout: CONSTRAINED
					)
				}
				tickList {
					text
					subText
				}
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			uspCtaCardsBanner {
				title
				content
				icon
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
				showForm
				showFirstRow
				showSecondRow
			}
			textWithImageContainerOne {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
				showForm
				showFirstRow
				showSecondRow
			}
			textWithImageContainerTwo {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
				showForm
				showFirstRow
				showSecondRow
			}
			textWithImage {
				id
				title
				content {
					value
				}
				buttonCopy
				buttonLink
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			relatedPages {
				... on DatoCmsServicePage {
					internal {
						type
					}
					title
					intro
					slug
				}
				... on DatoCmsChildServicePage {
					internal {
						type
					}
					title
					intro
					slug
					parentPage {
						slug
					}
				}
			}
			relatedPagesTitle
		}
		allDatoCmsBlogPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: "en" }
				sectors: { elemMatch: { name: { eq: $tag } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
				externalLink
			}
		}
		allDatoCmsGuidePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: {
				slug: { ne: null }
				locale: { eq: "en" }
				sectors: { elemMatch: { name: { eq: $tag } } }
			}
		) {
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		datoCmsSiteSpecificSetting {
			trialServiceCopy
			trialServiceLink
			trialServiceOpenForm
			logo {
				url
			}
		}
	}
`;

const WrappedChildService = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<ChildServicePage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedChildService;
