export const usePostcodeLookupApi = () => {
	const fetchPostcode = async (postcode) => {
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.log('submission object', postcode);
			return {
				status: 200,
				body: { lat: '53.482366', lng: '-2.234581' },
			};
		}

		// eslint-disable-next-line compat/compat
		const response = await fetch('/api/postcode-lookup', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(postcode),
		});

		if (response.status === 200) {
			return { status: response.status, body: await response.json() };
		}
		return { status: response.status };
	};

	return { fetchPostcode };
};
