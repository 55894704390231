// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { Categories } from '../components/molecules/categories';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';
import { CardContainer } from '../components/organisms/card-container';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { Drawer } from '../components/atoms/drawer';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Title } from '../components/atoms/title';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';

const CategoryPage = ({ data, location, locale }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsCategory.noIndex,
		noFollow: data.datoCmsCategory.noFollow,
		metaInformation: data.datoCmsCategory.metaInformation,
		fallbackTitle: data.datoCmsCategory.title,
		fallbackDescription: data.datoCmsCategory.longIntroduction,
		canonicalUrl: data.datoCmsCategory?.canonicalUrl || location.href,
	};

	const guideContainerContent = {
		totalCount: data.allDatoCmsGuidePage.totalCount,
		cards: data.allDatoCmsGuidePage.nodes,
	};

	const downloadContainerContent = {
		totalCount: data.allDatoCmsDownloadPage.totalCount,
		cards: data.allDatoCmsDownloadPage.nodes,
	};

	const blogContainerContent = {
		totalCount: data.allDatoCmsBlogPage.totalCount,
		cards: data.allDatoCmsBlogPage.nodes,
	};

	const adviceBannerContent = {
		...data.datoCmsCategory.adviceBanner,
		title: data.datoCmsCategory.adviceBanner?.title?.value?.document
			?.children,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.category,
			location,
			data: data.datoCmsCategory,
		}),
		image: getImageSeoSchema(
			data.datoCmsCategory?.mainHeader?.primaryImage
		),
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsCategory.internal.type,
		data.datoCmsCategory
	);

	let pagePath = location.pathname;
	if (pagePath.endsWith('/')) {
		pagePath = location.pathname.slice(0, -1);
	}
	const lowerCaseName = data.datoCmsCategory.name.toLowerCase();

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsCategory.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<Title
				title={data.datoCmsCategory.name}
				className="!text-left px-3 mb-8 md:mb-14 mx-auto max-w-m-screen lg:px-8"
			/>
			{guideContainerContent.totalCount > 0 && (
				<CardContainer
					cardContainerContent={{
						...guideContainerContent,
						id: 'guides-container',
						title: 'Guides',
						to: pagePath,
						toAll: `${pagePath}/guides`,
						buttonCopy: `View all ${lowerCaseName} guides`,
						viewAllTextOverride: true,
					}}
					cardVariant="Guide"
				/>
			)}
			{blogContainerContent.totalCount > 0 && (
				<CardContainer
					cardContainerContent={{
						...blogContainerContent,
						id: 'blog-container',
						title: 'Blog articles',
						to: pagePath,
						toAll: `${pagePath}/blogs`,
						buttonCopy: `View all ${lowerCaseName} blogs`,
						viewAllTextOverride: true,
					}}
					cardVariant="Blog"
				/>
			)}
			{downloadContainerContent.totalCount > 0 && (
				<CardContainer
					cardContainerContent={{
						...downloadContainerContent,
						id: 'download-container',
						title: 'Free downloads',
						to: pagePath,
						toAll: `${pagePath}/downloads`,
						buttonCopy: `View all ${lowerCaseName} downloads`,
						viewAllTextOverride: true,
					}}
					cardVariant="Download"
				/>
			)}
			{data.datoCmsCategory?.categoryGroupTitle ? (
				<Categories
					variant="Footer"
					title={data.datoCmsCategory.categoryGroupTitle}
					categoryGroups={data.datoCmsCategory.categoryGroups}
					backButtonCopy="Back to resource hub"
					locale={locale}
				/>
			) : null}
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{data.datoCmsCategory?.adviceBanner ? (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			) : null}

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query category($slug: String!, $categoryId: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsCategory(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			id
			name
			title: name
			slug
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			categoryGroupTitle
			categoryGroups {
				originalId
				id
				internal {
					type
				}
				slug
				title
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				categories {
					originalId
					id
					name
					internal {
						type
					}
					slug
				}
			}
		}
		allDatoCmsGuidePage(
			filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
			sort: { order: DESC, fields: created }
			limit: 6
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		allDatoCmsBlogPage(
			filter: {
				categories: { elemMatch: { id: { eq: $categoryId } } }
				slug: { ne: $slug }
			}
			sort: { order: DESC, fields: created }
			limit: 6
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
		allDatoCmsDownloadPage(
			filter: {
				categories: { elemMatch: { id: { eq: $categoryId } } }
				slug: { ne: $slug }
			}
			sort: { order: DESC, fields: created }
			limit: 6
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				title
				slug
				intro
				downloadType
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				categories {
					id
					name
					slug
				}
				sectors {
					id
					name
				}
			}
		}
	}
`;

const WrappedCategory = ({ data, location, pageContext }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<CategoryPage
			data={data}
			location={location}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedCategory;
