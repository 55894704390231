import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ tag }) => (
	<li className="px-2 py-1 mr-2 text-blue-400 bg-brand-pale-300 text-s-f">
		{tag}
	</li>
);

export { Tag };

Tag.propTypes = {
	tag: PropTypes.string.isRequired,
};
