import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { getLocale } from '../../../utils/locale';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

export const CallUs = ({
	className,
	altMobile,
	onClickProp,
	number,
	email,
}) => {
	const [fallBackNumber, setFallBackNumber] = useState('');

	useEffect(() => {
		if (!number) {
			if (locale === 'ca') {
				setFallBackNumber('1 (833) 247-3652');
			} else {
				setFallBackNumber('0800 158 2313');
			}
		}
	}, [number]);

	return (
		<a
			href={`tel:${
				number?.replaceAll(' ', '') ||
				fallBackNumber?.replaceAll(' ', '')
			}`}
			onClick={() =>
				onClickProp({
					click_text: number,
				})
			}
			className={clsx(
				'text-lg',
				'sm:text-xl',
				'lg:text-4xl',
				'phone-number',
				'font-centra-medium',
				'text-brand-red-400',
				locale !== 'ca' ? 'rulerclick' : '',
				'focus:outline-0 focus:shadow-focus',
				altMobile
					? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
					: 'flex flex-col leading-none',
				className
			)}
		>
			<span className={locale !== 'ca' ? 'rulerclick' : ''}>
				{number || fallBackNumber}
			</span>
			<span
				className={clsx(
					'text-xs text-black md:text-xl font-centra-book',
					altMobile ? 'flex items-center' : 'inline-block'
				)}
			>
				<span className="inline-block ml-1 mr-2 bg-blue-400  shrink-0" />
				{email}
			</span>
		</a>
	);
};

CallUs.defaultProps = {
	className: '',
	altMobile: false,
	onClickProp: () => {},
};

CallUs.propTypes = {
	className: PropTypes.string,
	altMobile: PropTypes.bool,
	onClickProp: PropTypes.func,
	number: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
};
