import React from 'react';
import { parseDatoLocale } from '../../utils/locale';

function usePageQuery({
	pageName,
	categoriesId,
	searchTerms,
	locale,
	extraQuery,
}) {
	const [pageTotalQuery, setPageTotalQuery] = React.useState('');
	React.useEffect(() => {
		setPageTotalQuery(`_${pageName}Meta(filter: {categories: {anyIn: [${categoriesId}]}, ${extraQuery} OR: [{OR: {intro: {matches: {pattern: "^(?=.*${searchTerms}).*", caseSensitive: false}}}}, {OR: {title: {matches: {pattern: "^(?=.*${searchTerms}).*", caseSensitive: false}}}}] },   locale: ${
			parseDatoLocale[locale]
		}, fallbackLocales: ${parseDatoLocale[locale] || 'en'})  {
		count 
		}`);
	}, [pageName, categoriesId, searchTerms, locale, extraQuery]);

	return {
		pageTotalQuery,
	};
}

export default usePageQuery;
