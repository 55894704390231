import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MegaMenuLink } from '../../atoms/mega-menu-link-ca';

const MegaMenuTwoGroup = forwardRef(
	(
		{ links, className, listClassName, hidden, backdrop, ...navAttributes },
		ref
	) => {
		// Define custom orders for HR and Health & Safety services
		const hrServicesOrder = [
			'HR services',
			'Contracts & documentation',
			'Smart HR software',
			'Peninsula Protect Guarantee',
			'On-site HR support',
			'HR training',
		];

		const healthSafetyOrder = [
			'Health & safety services',
			'Health & safety software',
			'Risk assessment services',
			'On-site health & safety support',
			'Workplace health & safety training',
		];

		const sortedLinks = links.sort((a, b) => {
			// Check if the link belongs to HR services
			if (
				hrServicesOrder.includes(a.title) &&
				hrServicesOrder.includes(b.title)
			) {
				return (
					hrServicesOrder.indexOf(a.title) -
					hrServicesOrder.indexOf(b.title)
				);
			}
			// Check if the link belongs to Health & Safety services
			if (
				healthSafetyOrder.includes(a.title) &&
				healthSafetyOrder.includes(b.title)
			) {
				return (
					healthSafetyOrder.indexOf(a.title) -
					healthSafetyOrder.indexOf(b.title)
				);
			}
			// Fallback if the titles do not match either custom order
			return 0;
		});

		// Determine how many links are in each category
		const coreServicesLinks = sortedLinks.slice(0, sortedLinks.length - 2);
		const additionalServicesLinks = sortedLinks.slice(-2);

		return (
			<div
				className={clsx(
					'absolute left-0 right-0 z-10 top-full w-screen h-[calc(100vh-136px)]',
					className
				)}
				hidden={hidden}
			>
				{backdrop || null}
				<nav
					ref={ref}
					className={clsx(
						'w-screen bg-white block absolute left-0 pb-10 z-10'
					)}
					{...navAttributes}
				>
					<div className="flex-grow mb-12 border-b-2 border-blue-200" />
					<div
						className={clsx(
							'grid grid-cols-3 gap-[15px] mx-auto max-w-m-screen',
							listClassName
						)}
					>
						{/* Core services */}
						<div className="col-span-2">
							<div className="flex items-center mb-4">
								<p className="ml-4 text-sm text-blue-300">
									Core Services
								</p>
								<div className="flex-grow ml-4 mr-16 border-blue-300 border-b-1" />
							</div>
							<div className="grid grid-cols-2">
								{coreServicesLinks.map((link) => (
									<div
										key={link.title.replace(
											/[^A-Z0-9]/gi,
											''
										)}
									>
										<MegaMenuLink
											to={link.link}
											title={link.title}
											text={link.subText}
											icon={link.icon}
											parentTrackingId={navAttributes[
												'aria-label'
											]?.replace(' menu', '')}
										/>
									</div>
								))}
							</div>
						</div>
						{/* Additional services */}
						<div className="col-span-1">
							<div className="flex items-center mb-4">
								<p className="ml-4 text-sm text-blue-300">
									Additional Services
								</p>
								<div className="flex-grow ml-4 mr-8 border-blue-300 border-b-1" />
							</div>
							<div className="grid">
								{additionalServicesLinks.map((link) => (
									<div
										key={link.title.replace(
											/[^A-Z0-9]/gi,
											''
										)}
									>
										<MegaMenuLink
											to={link.link}
											title={link.title}
											text={link.subText}
											icon={link.icon}
											parentTrackingId={navAttributes[
												'aria-label'
											]?.replace(' menu', '')}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</nav>
			</div>
		);
	}
);

export { MegaMenuTwoGroup };

MegaMenuTwoGroup.defaultProps = {
	className: '',
	listClassName: '',
	hidden: true,
	backdrop: '',
};

MegaMenuTwoGroup.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subText: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
	listClassName: PropTypes.string,
	hidden: PropTypes.bool,
	backdrop: PropTypes.node,
};
