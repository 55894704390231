import React from 'react';
import { clsx } from 'clsx';

export const createHeadingHighlight = ({
	headings,
	headingStyles,
	headingHighlightClassNames,
}) =>
	headings.map((item) =>
		// Check to see if there are any highlighted words
		// eslint-disable-next-line no-nested-ternary
		item.marks ? (
			<span
				key={`heading-text-${item.value}-${Math.random()}`}
				className={clsx(
					'text-brand-red-400 pb-1',
					'bg-no-repeat bg-underline bg-bottom bg-[length:100%_6px]',
					'md:bg-[length:100%_8px]',
					headingStyles?.highlight,
					headingHighlightClassNames
				)}
			>
				{item.value}
			</span>
		) : item.value.includes('\n') ? (
			// Check for new lines and handle them
			item.value.split('\n').map((line, index) =>
				// We don't want the first one as it'll be part of the original text
				index > 0 ? (
					<span key={`heading-text-${line}-${Math.random()}`}>
						<br />
						{line}
					</span>
				) : (
					<span key={`heading-text-${line}`}>{line}</span>
				)
			)
		) : (
			<span key={`heading-text-${item.value}`}>{item.value}</span>
		)
	);
