import React from 'react';
import clsx from 'clsx';
import { render } from 'datocms-structured-text-to-plain-text';
import { isHeading } from 'datocms-structured-text-utils';

import { PBSLink } from '../../atoms/link';
import { structuredTextContent } from '../../../types';

const JumpTo = ({ content }) => {
	const data = content?.value?.document?.children || [];
	const hasHeading = data.some(isHeading);

	if (hasHeading) {
		const headings = data.filter(
			(e) => e.type === 'heading' && e.level === 2
		);

		const breakIndex = Math.ceil(headings.length / 2);

		const headingsData = headings.map((item) => `${render(item)}`);

		const columns = [[], []];
		headingsData.forEach((item, index) => {
			if (index < breakIndex) {
				return columns[0].push(item);
			}
			columns[1].push(item);
		});

		const renderItem = (item, itemIndex, colIndex) => {
			const goto = colIndex === 1 ? itemIndex + breakIndex : itemIndex;
			const to = `#t-${goto}`;

			return (
				<li
					className="w-full mb-3"
					key={`${item}Col${colIndex}In${itemIndex}`}
				>
					<PBSLink
						variant="Link"
						to={to}
						className="!font-centra-medium !inline"
					>
						{item}
					</PBSLink>
				</li>
			);
		};

		const renderColumn = (col, colIndex) => {
			if (col.length) {
				return (
					<ol
						className={clsx(
							'list-decimal list-inside basis-1/2 marker:font-centra-medium marker:text-lg marker:md:text-xl marker:text-blue-400 marker:inline'
						)}
						start={colIndex === 0 ? 1 : breakIndex + 1}
						key={`jumpToCol${colIndex}`}
					>
						{col.map((item, itemIndex) =>
							renderItem(item, itemIndex, colIndex)
						)}
					</ol>
				);
			}
			return null;
		};

		return (
			<section className="px-3 mx-auto max-w-content my-xl-f md:px-8 xl:px-0">
				<div
					aria-labelledby="jumpTo"
					className="border-2 border-blue-200 rounded-sm bg-brand-pale-400 p-s-f md:p-base-f"
				>
					<p className="pb-xs-f md:pb-5 text-base-f">
						Jump to section:
					</p>
					<div className="flex flex-col md:gap-20 md:flex-row md:-mb-2 -pb-xs-f">
						{columns.map(renderColumn)}
					</div>
				</div>
			</section>
		);
	}
	return null;
};

export { JumpTo };

JumpTo.defaultProps = {};

JumpTo.propTypes = {
	content: structuredTextContent.isRequired,
};
