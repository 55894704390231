/**
 * To use this somewhere and get the current value of the drawer context:
 * import { useDrawer } from '../../../contexts/drawer-context';
 * const { drawerRef, setDrawerRef } = useDrawer();
 */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const DrawerContext = React.createContext(undefined);

const DrawerProvider = ({ children, ref }) => {
	const [drawerRef, setDrawerRef] = useState(ref);

	return (
		<DrawerContext.Provider
			value={{
				drawerRef,
				setDrawerRef,
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
};

DrawerProvider.defaultProps = {
	ref: '',
};

DrawerProvider.propTypes = {
	children: PropTypes.node.isRequired,
	ref: PropTypes.string,
};

const useDrawer = () => {
	const context = useContext(DrawerContext);
	if (context === undefined) {
		throw new Error('useDrawer must be used within a DrawerProvider');
	}
	return context;
};

export { DrawerProvider, useDrawer };

export default DrawerContext;
