import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const LikeButton = ({ courseId }) => {
	const [liked, setLiked] = useState(false);
	const isBrowser = typeof window !== 'undefined';

	const getKey = isBrowser ? localStorage.getItem(courseId) : null;

	const updateLikes = () => {
		const dataz = new FormData();
		dataz.append('item', courseId);

		if (getKey === '1') {
			// eslint-disable-next-line no-unused-expressions
			isBrowser ? localStorage.removeItem(courseId) : null;
			setLiked('');

			dataz.append('unlike', courseId);
		} else {
			// eslint-disable-next-line no-unused-expressions
			isBrowser ? localStorage.setItem(courseId, '1') : null;
			setLiked(courseId);
		}
	};

	return (
		<button
			type="button"
			className={clsx(
				`relative z-10 flex items-center gap-2 px-3 py-2 text-black transition-all border-2 rounded-md w-max hover:text-brand-red-500`,
				{
					'bg-brand-red-200 text-brand-red-500 border-brand-red-200 [&>svg>path]:hover:fill-transparent [&>span]:hover:hidden':
						getKey === '1' || liked === courseId,
					'[&>svg>path]:hover:fill-brand-red-500':
						getKey !== '1' || liked !== courseId,
				}
			)}
			onClick={() => updateLikes()}
		>
			<svg
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5.33027 1.20077L5.625 1.41585L5.91973 1.20077C6.30971 0.916192 7.03881 0.5 7.96875 0.5C9.50344 0.5 10.7505 1.74713 10.7505 3.28125C10.7505 4.95552 9.89754 6.79522 8.18197 8.59033C6.99259 9.83226 5.81305 10.6183 5.62523 10.7414C5.43734 10.6183 4.25724 9.83214 3.06822 8.59003C1.35284 6.79502 0.5 4.95543 0.5 3.28125C0.5 1.74708 1.74708 0.5 3.28125 0.5C4.21119 0.5 4.94029 0.916192 5.33027 1.20077Z"
					fill="transparent"
					stroke="currentColor"
					className={`transition-all ${
						getKey === '1' || liked === courseId
							? 'fill-brand-red-500'
							: ''
					}`}
				/>
			</svg>
		</button>
	);
};

export { LikeButton };

LikeButton.propTypes = {
	courseId: PropTypes.string.isRequired,
};
