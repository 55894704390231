import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { useLocation } from '@reach/router';
import { SidebarHeader } from '../sidebar-header';
import { AddToCalendar } from '../add-to-calendar';
import { PBSLink } from '../link';

import { EventsCard } from '../../molecules/events-card';

import { iCalEvent } from '../../../types';
import { getMultiParagraphText } from '../../../utils';
import { buildLink, getLocale } from '../../../utils/locale';
import { formatAMPM } from '../../../utils/date-formatter';

const EventSuccess = ({
	id,
	location,
	startDateTime,
	description,
	time,
	dateOrdinal,
	title,
	confirmationText,
	eventResponse,
}) => {
	const dateNow = new Date();
	const start = format(new Date(startDateTime), 'MM/dd/yyyy');
	const dateStringStart = `${start} ${formatAMPM(new Date(startDateTime))}`;
	const startDateAndTime = new Date(
		dateNow.setTime(Date.parse(dateStringStart))
	)
		.toISOString()
		.replace('.', '');
	const containerRef = useRef();
	const urlLocation = useLocation();
	const locale = getLocale(urlLocation.pathname);
	const heading =
		'Thank you for registering for this event, you will receive a confirmation email shortly.';

	const contentArr = confirmationText.split('\n');
	const paragraphClassNames =
		'text-lg md:text-xl mb-3 last-of-type:mb-8 break-words';

	useEffect(() => {
		if (eventResponse) {
			containerRef.current.scrollIntoView({ behavior: 'instant' });
		}
	}, [eventResponse]);

	return (
		<div className="flex flex-col h-full max-h-screen" ref={containerRef}>
			<SidebarHeader heading={heading} ariaLive="assertive" />
			<div className="px-4 py-10">
				{confirmationText
					? getMultiParagraphText(contentArr, paragraphClassNames, id)
					: null}
				<EventsCard
					className="mb-10"
					grow={false}
					cardContent={{
						eventTime: time,
						eventDate: dateOrdinal,
						eventDateTime: startDateTime,
						eventLocation: location,
						shortDescription: description,
					}}
				/>
				<AddToCalendar
					className="mb-5 w-fit"
					event={{
						location: location.venueName,
						startDateTime: startDateAndTime,
						endDateTime: startDateAndTime,
						description,
						title,
					}}
				/>
				<PBSLink
					className="block w-fit"
					variant="White"
					to={buildLink(locale, '/events/')}
				>
					Return to events
				</PBSLink>
			</div>
		</div>
	);
};

export { EventSuccess };

EventSuccess.defaultProps = {
	confirmationText: '',
};

EventSuccess.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	confirmationText: PropTypes.string,
	eventResponse: PropTypes.bool.isRequired,
	...iCalEvent.event,
};
