// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { CaseStudiesText } from '../components/atoms/case-studies-text';

import { Drawer } from '../components/atoms/drawer';
import { IconBanner } from '../components/atoms/icon-banner';
import { Vimeo } from '../components/atoms/vimeo';

import { CaseStudyHeader } from '../components/molecules/case-study-header';
import { FeefoTestimonials } from '../components/molecules/feefo';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';

import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { CardContainer } from '../components/organisms/card-container';
import { IconCards } from '../components/organisms/icon-cards';
import { SuccessForm } from '../components/organisms/success-form';

import { useDrawer } from '../contexts/drawer-context';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { HrefLang } from '../components/templates/hrefLang';
import { getLocale } from '../utils/locale';

const CaseStudyPage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsCaseStudyPage.noIndex,
		noFollow: data.datoCmsCaseStudyPage.noFollow,
		metaInformation: data.datoCmsCaseStudyPage.metaInformation,
		fallbackTitle: data.datoCmsCaseStudyPage.title,
		fallbackDescription: data.datoCmsCaseStudyPage.longIntroduction,
		canonicalUrl: data.datoCmsCaseStudyPage?.canonicalUrl || location.href,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.caseStudy,
			location,
			data: data.datoCmsCaseStudyPage,
		}),
	};

	const caseStudyHeaderContent = {
		id: data.datoCmsCaseStudyPage.originalId,
		quote: data.datoCmsCaseStudyPage.quote,
		video: data.datoCmsCaseStudyPage.headerVideo?.url,
		image: data.datoCmsCaseStudyPage.thumbnail,
		logo: data.datoCmsCaseStudyPage.companyLogo,
		clientName: data.datoCmsCaseStudyPage.clientName,
	};

	const adviceBannerContent = {
		...data.datoCmsCaseStudyPage.adviceBanner,
		title: data.datoCmsCaseStudyPage.adviceBanner.title.value.document
			.children,
	};

	const uspBanner = data.datoCmsCaseStudyPage.uspBannerCards;
	const { uspBannerDisplayLinks, iconBanner, uspBannerSubTitle, uspTitle } =
		data.datoCmsCaseStudyPage;

	const relatedCaseStudiesContainerContent = {
		cards: data.allDatoCmsCaseStudyPage.nodes,
		subText: data.datoCmsSiteSpecificSetting.subText,
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsCaseStudyPage.internal.type,
		data.datoCmsCaseStudyPage
	);

	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);
	const { locale: urlLocale } = data.datoCmsCaseStudyPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsCaseStudyPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />

			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			<CaseStudyHeader content={caseStudyHeaderContent} />

			{iconBanner && <IconBanner data={iconBanner} />}

			<CaseStudiesText
				content={data.datoCmsCaseStudyPage.content}
				locale={urlLocale}
			/>

			{data.datoCmsCaseStudyPage.uspBannerDisplayOnPage && (
				<IconCards
					containerClassName="mt-xl-f md:mt-2xl-f"
					wrapperClassName="!pl-3 md:!pl-8"
					titleGroupClassName="pr-3 md:pr-0"
					borderBottom
					borderClassNames="border-0 md:my-2xl-f my-xl-f mx-3 lg:mx-8 h-[1px] bg-blue-200 max-w-[79.75rem] xl:mx-auto"
					iconCardsContent={{
						uspCards: uspBanner,
						title: uspTitle,
						subText: uspBannerSubTitle,
					}}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}

			{data.datoCmsCaseStudyPage.videoBannerDisplayOnPage && (
				<Vimeo
					containerClassName="mx-auto my-xl-f w-full md:my-2xl-f lg:max-w-[952px]"
					titleGroupClassNames="px-3 mx-auto lg:px-0"
					containerTitle={
						data.datoCmsCaseStudyPage.videoBannerHeading
					}
					bannerSubHeading={
						data.datoCmsCaseStudyPage.videoBannerSubHeading
					}
					video={data.datoCmsCaseStudyPage.bannerVideo?.url}
					responsive
				/>
			)}

			{urlLocale !== 'en-CA' &&
				data.datoCmsCaseStudyPage.feefoBannerDisplayOnPage && (
					<FeefoTestimonials locale={urlLocale} />
				)}

			{data.datoCmsCaseStudyPage.adviceBannerDisplayOnPage && (
				<AdviceBanner
					variant="Rte"
					className="max-w-[1058px] mx-3 lg:mx-auto lg:w-[90%]"
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			)}

			{relatedCaseStudiesContainerContent?.cards?.length > 0 && (
				<div className="mt-2xl-f md:mt-3xl-f">
					<CardContainer
						cardContainerContent={{
							...relatedCaseStudiesContainerContent,
							id: 'related-case-study-container',
							title: 'Similar case studies',
							to: '/case-studies',
							toAll:
								locale === 'ca'
									? '/ca/case-studies'
									: '/case-studies',
							buttonCopy: 'View all case studies',
							viewAllTextOverride: true,
						}}
						variant="ViewAllScrollable"
						cardVariant="CaseStudy"
					/>
				</div>
			)}

			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			{data.datoCmsCaseStudyPage.trustPilotBannerDisplayOnPage && (
				<TrustpilotReviews
					locale={urlLocale}
					className="!pb-0"
					wrapperClassName="border-b-1 border-blue-200 pb-xl-f md:pb-2xl-f"
				/>
			)}

			{urlLocale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query caseStudy(
		$slug: String!
		$categoryGroups: [String!]!
		$locale: String!
	) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsSiteSpecificSetting {
			subText: relatedCaseStudiesSubText
		}
		datoCmsCaseStudyPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			slug
			title
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			quote
			thumbnail {
				alt
				url
				gatsbyImageData(
					layout: CONSTRAINED
					aspectRatio: 1.3
					placeholder: DOMINANT_COLOR
					sizes: "(max-width: 640px) 640px, 800px"
				)
			}
			headerVideo {
				url
			}
			clientName
			companyLogo {
				alt
				url
				gatsbyImageData(height: 70, layout: CONSTRAINED)
			}
			iconBanner {
				id
				heading
				subHeading
				icon
			}
			content {
				__typename
				... on DatoCmsCaseStudyContentRow {
					id: originalId
					header
					contentRow: content {
						value
						blocks {
							__typename
							... on DatoCmsTickListContainer {
								id: originalId
								listItems {
									text
									subText
									id
								}
							}
							... on DatoCmsReviewBanner {
								id: originalId
								title
								subText
								reviewer
							}
						}
					}
				}
				... on DatoCmsTestimonial {
					id: originalId
					name
					job
					content
					profile {
						alt
						gatsbyImageData(
							aspectRatio: 53
							placeholder: DOMINANT_COLOR
							width: 500
							sizes: "(max-width: 640px) 400px, 800px"
							height: 300
						)
					}
				}
				... on DatoCmsVapCalculatorBlock {
					id: originalId
					headerTitle
					subHeader
					ctaText
					ctaSuccessLink
					ctaSuccessText
				}
			}
			uspBannerDisplayOnPage
			uspBannerDisplayLinks
			uspTitle
			uspBannerSubTitle
			uspBannerCards {
				title
				content
				icon
				link
				linkCopy
			}
			videoBannerHeading
			videoBannerSubHeading
			videoBannerDisplayOnPage
			bannerVideo {
				url
			}
			feefoBannerDisplayOnPage
			adviceBannerDisplayOnPage
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			trustPilotBannerDisplayOnPage
		}
		allDatoCmsCaseStudyPage(
			filter: {
				categories: { elemMatch: { group: { in: $categoryGroups } } }
				slug: { ne: $slug }
				locale: { eq: $locale }
			}
			sort: { order: DESC, fields: created }
			limit: 9
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				title
				clientName
				slug
				introduction
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				categories {
					name
					id
				}
			}
		}
	}
`;

const WrappedCaseStudy = ({ data, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<CaseStudyPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedCaseStudy;
