export const costOfLatnessAbsence = (count) =>
	(count * 1093).toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
export const staffTurnover = (11000).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const draftingPolicy = (1500).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const draftingContract = (1500).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const hsDocs = (1050).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const generalHr = (25000).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const costAccidents = (2089).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const averageTribunal = (11914).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const costHrManager = (50600).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const costHsManager = (46000).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const averageBackPain = (3165).toLocaleString(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const totalCost = (count) => {
	if (count > 49) {
		return (
			Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
			Number(staffTurnover.replace(/,/g, '')) +
			Number(draftingPolicy.replace(/,/g, '')) +
			Number(draftingContract.replace(/,/g, '')) +
			Number(hsDocs.replace(/,/g, '')) +
			Number(averageTribunal.replace(/,/g, '')) +
			Number(costHrManager.replace(/,/g, '')) +
			Number(costHsManager.replace(/,/g, '')) +
			Number(averageBackPain.replace(/,/g, ''))
		).toLocaleString(undefined, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	}
	return (
		Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
		Number(staffTurnover.replace(/,/g, '')) +
		Number(draftingPolicy.replace(/,/g, '')) +
		Number(draftingContract.replace(/,/g, '')) +
		Number(hsDocs.replace(/,/g, '')) +
		Number(generalHr.replace(/,/g, '')) +
		Number(averageTribunal.replace(/,/g, ''))
	).toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const totalCostHS = (count) => {
	if (count > 49) {
		return (
			Number(costHsManager.replace(/,/g, '')) +
			Number(hsDocs.replace(/,/g, '')) +
			Number(staffTurnover.replace(/,/g, '')) +
			Number(averageBackPain.replace(/,/g, ''))
		).toLocaleString(undefined, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	}
	return (
		Number(draftingContract.replace(/,/g, '')) +
		Number(draftingPolicy.replace(/,/g, '')) +
		Number(hsDocs.replace(/,/g, '')) +
		Number(staffTurnover.replace(/,/g, '')) +
		Number(generalHr.replace(/,/g, '')) +
		Number(costAccidents.replace(/,/g, ''))
	).toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const totalCostES = (count) => {
	if (count > 49) {
		return (
			Number(costHrManager.replace(/,/g, '')) +
			Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
			Number(staffTurnover.replace(/,/g, '')) +
			Number(averageTribunal.replace(/,/g, '')) +
			Number(hsDocs.replace(/,/g, '')) +
			Number(averageBackPain.replace(/,/g, ''))
		).toLocaleString(undefined, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	}
	return (
		Number(draftingContract.replace(/,/g, '')) +
		Number(draftingPolicy.replace(/,/g, '')) +
		Number(costOfLatnessAbsence(count).replace(/,/g, '')) +
		Number(staffTurnover.replace(/,/g, '')) +
		Number(generalHr.replace(/,/g, '')) +
		Number(averageTribunal.replace(/,/g, ''))
	).toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const annualCost = (count) => {
	let amount = 0;
	switch (true) {
		case count <= 5:
			amount = 284 * 12;
			break;
		case count <= 10:
			amount = 352 * 12;
			break;
		case count === '11':
			amount = 386 * 12;
			break;
		case count === '12':
			amount = 410 * 12;
			break;
		case count === '13':
			amount = 432 * 12;
			break;
		case count === '14':
			amount = 452 * 12;
			break;
		case count === '15':
			amount = 477 * 12;
			break;
		case count === '16':
			amount = 489 * 12;
			break;
		case count === '17':
			amount = 508 * 12;
			break;
		case count === '18':
			amount = 525 * 12;
			break;
		case count === '19':
			amount = 543 * 12;
			break;
		case count === '20':
			amount = 559 * 12;
			break;
		case count <= 24:
			amount = 572 * 12;
			break;
		case count <= 29:
			amount = 586 * 12;
			break;
		case count <= 34:
			amount = 593 * 12;
			break;
		case count <= 39:
			amount = 612 * 12;
			break;
		case count <= 44:
			amount = 654 * 12;
			break;
		case count <= 49:
			amount = 674 * 12;
			break;
		case count <= 59:
			amount = 707 * 12;
			break;
		case count <= 69:
			amount = 735 * 12;
			break;
		case count <= 79:
			amount = 770 * 12;
			break;
		case count <= 89:
			amount = 803 * 12;
			break;
		case count <= 99:
			amount = 860 * 12;
			break;
		case count <= 119:
			amount = 926 * 12;
			break;
		case count <= 139:
			amount = 1022 * 12;
			break;
		case count <= 159:
			amount = 1119 * 12;
			break;
		case count <= 179:
			amount = 1215 * 12;
			break;
		case count <= 199:
			amount = 1312 * 12;
			break;
		case count <= 224:
			amount = 1412 * 12;
			break;
		case count <= 249:
			amount = 1542 * 12;
			break;

		default:
			amount = 'POA';
			break;
	}
	if (count > 249) {
		return 'POA';
	}
	return amount.toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const annualCostHS = (count) => {
	let amount = 0;
	switch (true) {
		case count <= 5:
			amount = 150 * 12;
			break;
		case count <= 10:
			amount = 191 * 12;
			break;
		case count === '11':
			amount = 199 * 12;
			break;
		case count === '12':
			amount = 208 * 12;
			break;
		case count === '13':
			amount = 215 * 12;
			break;
		case count === '14':
			amount = 225 * 12;
			break;
		case count === '15':
			amount = 232 * 12;
			break;
		case count === '16':
			amount = 234 * 12;
			break;
		case count === '17':
			amount = 236 * 12;
			break;
		case count === '18':
			amount = 238 * 12;
			break;
		case count === '19':
			amount = 241 * 12;
			break;
		case count === '20':
			amount = 243 * 12;
			break;
		case count <= 24:
			amount = 244 * 12;
			break;
		case count <= 29:
			amount = 250 * 12;
			break;
		case count <= 34:
			amount = 261 * 12;
			break;
		case count <= 39:
			amount = 271 * 12;
			break;
		case count <= 44:
			amount = 276 * 12;
			break;
		case count <= 49:
			amount = 283 * 12;
			break;
		case count <= 59:
			amount = 299 * 12;
			break;
		case count <= 69:
			amount = 312 * 12;
			break;
		case count <= 79:
			amount = 327 * 12;
			break;
		case count <= 89:
			amount = 350 * 12;
			break;
		case count <= 99:
			amount = 373 * 12;
			break;
		case count <= 119:
			amount = 405 * 12;
			break;
		case count <= 139:
			amount = 450 * 12;
			break;
		case count <= 159:
			amount = 496 * 12;
			break;
		case count <= 179:
			amount = 540 * 12;
			break;
		case count <= 199:
			amount = 586 * 12;
			break;
		case count <= 224:
			amount = 636 * 12;
			break;
		case count <= 249:
			amount = 692 * 12;
			break;

		default:
			amount = 'POA';
			break;
	}
	if (count > 249) {
		return 'POA';
	}
	return amount.toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const annualCostES = (count) => {
	let amount = 0;
	switch (true) {
		case count <= 5:
			amount = 150 * 12;
			break;
		case count <= 10:
			amount = 197 * 12;
			break;
		case count === '11':
			amount = 229 * 12;
			break;
		case count === '12':
			amount = 246 * 12;
			break;
		case count === '13':
			amount = 263 * 12;
			break;
		case count === '14':
			amount = 280 * 12;
			break;
		case count === '15':
			amount = 297 * 12;
			break;
		case count === '16':
			amount = 310 * 12;
			break;
		case count === '17':
			amount = 326 * 12;
			break;
		case count === '18':
			amount = 342 * 12;
			break;
		case count === '19':
			amount = 360 * 12;
			break;
		case count === '20':
			amount = 374 * 12;
			break;
		case count <= 24:
			amount = 381 * 12;
			break;
		case count <= 29:
			amount = 395 * 12;
			break;
		case count <= 34:
			amount = 401 * 12;
			break;
		case count <= 39:
			amount = 421 * 12;
			break;
		case count <= 44:
			amount = 458 * 12;
			break;
		case count <= 49:
			amount = 479 * 12;
			break;
		case count <= 59:
			amount = 506 * 12;
			break;
		case count <= 69:
			amount = 531 * 12;
			break;
		case count <= 79:
			amount = 563 * 12;
			break;
		case count <= 89:
			amount = 592 * 12;
			break;
		case count <= 99:
			amount = 639 * 12;
			break;
		case count <= 119:
			amount = 700 * 12;
			break;
		case count <= 139:
			amount = 782 * 12;
			break;
		case count <= 159:
			amount = 864 * 12;
			break;
		case count <= 179:
			amount = 964 * 12;
			break;
		case count <= 199:
			amount = 1027 * 12;
			break;
		case count <= 224:
			amount = 1122 * 12;
			break;
		case count <= 249:
			amount = 1224 * 12;
			break;

		default:
			amount = 'POA';
			break;
	}
	if (count > 249) {
		return 'POA';
	}
	return amount.toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
};

export const costPerEmployee = (count) => {
	if (annualCost(count) === 'POA') {
		return 'POA';
	}
	const ac = Number(annualCost(count).replace(/,/g, ''));
	const c = Number(count);
	if (c === 0) {
		return 0;
	}
	const total = ac / c / 12;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const costPerEmployeeHS = (count) => {
	if (annualCostHS(count) === 'POA') {
		return 'POA';
	}
	const ac = Number(annualCostHS(count).replace(/,/g, ''));
	const c = Number(count);
	if (c === 0) {
		return 0;
	}
	const total = ac / c / 12;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const costPerEmployeeES = (count) => {
	if (annualCostES(count) === 'POA') {
		return 'POA';
	}
	const ac = Number(annualCostES(count).replace(/,/g, ''));
	const c = Number(count);
	if (c === 0) {
		return 0;
	}
	const total = ac / c / 12;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const annualRoiES = (count) => {
	if (annualCostES(count) === 'POA') {
		return 'POA';
	}
	const tc = Number(totalCostES(count).replace(/,/g, ''));
	const ac = Number(annualCostES(count).replace(/,/g, ''));
	if (ac === 0) {
		return 0;
	}
	const total = tc / ac;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const annualRoiHS = (count) => {
	if (annualCostHS(count) === 'POA') {
		return 'POA';
	}
	const tc = Number(totalCostHS(count).replace(/,/g, ''));
	const ac = Number(annualCostHS(count).replace(/,/g, ''));
	if (ac === 0) {
		return 0;
	}
	const total = tc / ac;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const annualRoi = (count) => {
	if (annualCost(count) === 'POA') {
		return 'POA';
	}
	const tc = Number(totalCost(count).replace(/,/g, ''));
	const ac = Number(annualCost(count).replace(/,/g, ''));
	if (ac === 0) {
		return 0;
	}
	const total = tc / ac;
	return total.toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};
