import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { IconCardListHrSoftware } from '../../molecules/icon-card-list-hr-software';
import { titleProp } from '../../../types';

const IconCardsHrSoftware = ({
	uspTitle,
	id,
	showContentLinks,
	iconCardsContent,
	isTimeline,
}) => {
	const { uspCards } = iconCardsContent;
	const subText = '';

	return (
		<section
			className="min-h-full bg-[#EDF3FF] flex justify-center xl:-mt-16"
			aria-labelledby={id}
		>
			<div className={clsx('mx-auto md:px-8 max-w-m-screen')}>
				{/* <h2 className='mb-4 text-3xl text-center lg:text-4xl'>{uspTitle}</h2> */}
				<div className="px-3 md:container lg:max-w-[1000px] mt-14 mb-6 lg:mt-20 lg:mb-14 md:mx-auto">
					<HeadingTagLine
						id={id}
						title={uspTitle}
						subText={subText}
					/>
				</div>
				<div>
					<IconCardListHrSoftware
						cards={uspCards}
						showContentLinks={showContentLinks}
						isTimeline={isTimeline}
					/>
				</div>
			</div>
		</section>
	);
};

IconCardsHrSoftware.defaultProps = {
	id: 'hr-services',
	showContentLinks: true,
	isTimeline: false,
};

IconCardsHrSoftware.propTypes = {
	id: PropTypes.string,
	uspTitle: titleProp.isRequired,
	isTimeline: PropTypes.bool,
	showContentLinks: PropTypes.bool,
	iconCardsContent: PropTypes.shape({
		uspTitle: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
};

export { IconCardsHrSoftware };
