import React from 'react';
import LogoIcon from '../../../assets/google-review.png';

const GoogleBusinessReviewPricing = () => (
	<img
		src={LogoIcon}
		alt="Google Business Review"
		className="lg:w-[550px] md:w-[275px] mb-5 pt-6 mx-auto"
	/>
);

export { GoogleBusinessReviewPricing };
