import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../button';

import { sendTrackingData } from '../../../utils';

import DownloadIcon from '../../../assets/download.inline.svg';
import { refProp } from '../../../types';

import { useDrawer } from '../../../contexts/drawer-context';

const DownloadPromo = ({ title, subText, drawerRef }) => {
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			click_type: 'primary',
			component_name: 'download-promo',
		});
	};

	return (
		<section className="px-3 mx-auto max-w-content mb-14 md:mb-20 md:px-8 xl:px-0">
			<article className="gradient px-4 py-5 lg:p-7 rounded-tr-[120px]">
				<hgroup className="max-w-[85%]">
					<h2 className="mb-4 text-2xl text-white md:text-3xl">
						{title}
					</h2>
					<p className="text-lg text-white md:text-2xl">{subText}</p>
				</hgroup>
				<Button
					variant="White"
					className="mt-7"
					onClick={() => {
						setDrawerRef(drawerRef);
						handleClickTracking();
					}}
				>
					<DownloadIcon
						width={25}
						height={25}
						aria-hidden="true"
						className="mr-2"
					/>{' '}
					Download for free
				</Button>
			</article>
		</section>
	);
};

export { DownloadPromo };

DownloadPromo.propTypes = {
	title: PropTypes.string.isRequired,
	subText: PropTypes.string.isRequired,
	drawerRef: refProp.isRequired,
};
