import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import {
	DEFAULT_LOCALE,
	LOCALE_PATHS,
	SITE_URL,
	buildLink,
} from '../../utils/locale';

// eslint-disable-next-line react/prop-types
function HrefLang({ locales, home }) {
	const url = typeof window !== 'undefined' ? window.location : '';
	let pathname;
	if (url) {
		pathname = url.pathname === '/' ? url.href : url.pathname;
	}
	// eslint-disable-next-line react/prop-types
	const altLocales = locales.map((l) => l.locale || l);
	// if (altLocales.length <= 1) {
	// 	return null;
	// }
	const xDefaultLocale = altLocales.includes('en')
		? DEFAULT_LOCALE
		: locales[0];

	const cleanUrl = (path) => {
		let withoutLocale;
		if (path === url.href) {
			withoutLocale = '/';
		} else {
			// Remove existing locale if it already exists in the URL
			withoutLocale = path && path?.replace(/\/(en|ca|ie)\//, '/');
		}
		return withoutLocale;
	};

	const buildXdefaultUrl = (path) => {
		const withoutLocale = cleanUrl(path);
		const sanitizedUrl = withoutLocale?.endsWith('/')
			? withoutLocale
			: `${withoutLocale}/`;
		// eslint-disable-next-line compat/compat
		return new URL(`${sanitizedUrl}`, `${SITE_URL}`).href;
	};

	const sanitizedUrl = (urlPath) => {
		// check if url ends with a /
		const originalUrl = urlPath?.endsWith('/');
		let trailingUrl = urlPath;
		if (!originalUrl) {
			trailingUrl = `${urlPath}/`;
		}
		// Ensure the URL ends with a slash
		const removeLocale = trailingUrl && trailingUrl?.split('/');
		const combineUrl =
			removeLocale.length === 2 ? removeLocale[1] : removeLocale[0];
		const finalUrl =
			trailingUrl && trailingUrl?.replace(`/${combineUrl}`, '/');
		return finalUrl.endsWith('/') ? finalUrl : `${finalUrl}/`;
	};
	function buildLocaleLink(path, locale) {
		const withoutLocale = cleanUrl(path);
		const prefix = '/';
		const newUrl = `${locale === 'en' ? '' : prefix + locale}`;
		const localeLink = `${newUrl}${sanitizedUrl(withoutLocale)}`;
		// eslint-disable-next-line compat/compat
		return new URL(`${localeLink}`, `${SITE_URL}`).href;
	}

	return (
		<Helmet>
			{locales &&
				locales
					// eslint-disable-next-line react/prop-types
					?.map((item, i) => (
						<link
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							rel="alternate"
							hrefLang={
								item.locale === 'en' ? 'en-GB' : item.locale
							}
							href={buildLocaleLink(
								pathname,
								LOCALE_PATHS[item.locale] || DEFAULT_LOCALE
							)}
						/>
					))}
			{home && (
				<link
					rel="alternate"
					hrefLang="en-AU"
					href="https://peninsulagrouplimited.com.au"
				/>
			)}
			{home && (
				<link
					rel="alternate"
					hrefLang="en-NZ"
					href="https://peninsulagrouplimited.co.nz"
				/>
			)}

			<link
				rel="alternate"
				hrefLang="x-default"
				href={buildLink(
					xDefaultLocale,
					// eslint-disable-next-line react/prop-types
					buildXdefaultUrl(pathname, locales[0].locale)
				)}
			/>
		</Helmet>
	);
}

export { HrefLang };

HrefLang.defaultProps = {
	locales: [],
	home: false,
};

HrefLang.propsTypes = {
	locales: PropTypes.arrayOf(PropTypes.node),
	home: PropTypes.bool,
};
