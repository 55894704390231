import React, { useRef, useState } from 'react';
import { clsx } from 'clsx';

import PropTypes from 'prop-types';
import { Button } from '../../atoms/button';
import { TextInput } from '../../atoms/text-input';

import { usePostcodeLookupApi } from '../../../hooks/postcode-lookup';
import { useFormValidation } from '../../../hooks/form-validation';
import { sleep } from '../../../utils';

const initialValidations = {
	postcode: { isValid: true },
};

const PostcodeLookupForm = ({ setCurrentLocation, setHasLocation }) => {
	const postcodeRef = useRef(null);
	const [submitting, setSubmitting] = useState(false);
	const [isError, setIsError] = useState(false);

	const { fetchPostcode } = usePostcodeLookupApi();
	const { validateForm } = useFormValidation(initialValidations);

	const submitButtonText = 'Search';

	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		event.preventDefault();
		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) return;

		setSubmitting(true);
		setHasLocation(false);
		setIsError(false);

		const payloadData = {
			postcode: formEntries?.postcode,
		};
		const response = await fetchPostcode(payloadData);
		await sleep(500);
		setSubmitting(false);
		switch (response.status) {
			case 200:
				setCurrentLocation(response.body);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	return (
		<form
			onSubmit={submitHandler}
			className="flex flex-col justify-between w-full max-w-[400px] mx-auto grow pb-7 px-3 sm:px-0"
		>
			<div
				className={clsx(
					isError &&
						'relative before:absolute before:top-0 before:-left-4 before:w-1 before:h-full before:transition-colors before:duration-200 before:motion-reduce:transition-none before:bg-alert-400'
				)}
			>
				<div className="flex flex-col grow relative">
					<TextInput
						id="postcode"
						name="postcode"
						labelText="Search by postcode"
						showLabel={false}
						aria-required="true"
						className="w-full wrapper-small [&>input]:pr-[107px] md:[&>input]:pr-[140px]"
						validationMessage="Please a valid postcode"
						variant="RoundedThick"
						ref={postcodeRef}
						placeholder="Search by postcode"
						disabled={submitting}
					/>
					<Button
						type="submit"
						className={clsx(
							'absolute bottom-[1.5rem] leading-[1.46rem] md:leading-[1.61rem] right-[-2px]',
							submitting && 'justify-center loading'
						)}
						disabled={submitting}
					>
						<span className={clsx(submitting ? 'invisible' : null)}>
							{submitButtonText}
						</span>
						{submitting ? (
							<span className="sr-only" aria-live="assertive">
								Submitting your postcode, please wait...
							</span>
						) : null}
					</Button>
				</div>
				{isError && (
					<p className="text-alert-500 mt-[-1rem]">
						Error with postcode lookup
					</p>
				)}
			</div>
		</form>
	);
};

export { PostcodeLookupForm };

PostcodeLookupForm.defaultProps = {
	setHasLocation: () => {},
};

PostcodeLookupForm.propTypes = {
	setCurrentLocation: PropTypes.func.isRequired,
	setHasLocation: PropTypes.func,
};
