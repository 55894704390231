import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Button } from '../button';

import { SpeakerLinks } from '../../molecules/speaker-links';

import ShowBio from '../../../assets/eye-show.inline.svg';
import HideBio from '../../../assets/eye-hide.inline.svg';
import FacebookIcon from '../../../assets/facebook.inline.svg';
import LinkedInIcon from '../../../assets/linkedin.inline.svg';
import EmailIcon from '../../../assets/email.inline.svg';
import TwitterIcon from '../../../assets/twitter.inline.svg';

import { getRteContent } from '../../../utils';

import { speakerProp } from '../../../types';

const buttonIcons = {
	ShowBio,
	HideBio,
};

const Speaker = ({ displayClassNames, layoutClassNames, bioRef, content }) => {
	const { id, speaker } = content;

	const [isBioVisible, setIsBioVisible] = useState(false);
	const Icon = buttonIcons[isBioVisible ? 'HideBio' : 'ShowBio'];

	const hasBio = !!speaker?.bio?.value?.document?.children;
	const speakerImageData = getImage(speaker.image);
	const speakerImageAlt =
		speaker.image.alt ||
		speaker.name ||
		'Peninsula HR & Health and Safty Support';

	const socials = [];

	if (speaker.facebookUrl) {
		socials.push({
			outlet: 'Facebook',
			href: speaker.facebookUrl,
			label: `View ${speaker.name} on Facebook`,
			icon: <FacebookIcon />,
		});
	}

	if (speaker.twitterUrl) {
		socials.push({
			outlet: 'Twitter',
			href: speaker.twitterUrl,
			label: `Follow ${speaker.name} on Twitter`,
			icon: <TwitterIcon />,
		});
	}

	if (speaker.linkedinUrl) {
		socials.push({
			outlet: 'LinkedIn',
			href: speaker.linkedinUrl,
			label: `View ${speaker.name} on Linkedin`,
			icon: <LinkedInIcon />,
		});
	}

	if (speaker.emailAddress) {
		socials.push({
			outlet: 'Email',
			href: `mailto:${speaker.emailAddress}`,
			label: `Contact ${speaker.name} via Email`,
			icon: <EmailIcon />,
		});
	}

	return (
		<>
			<div className={displayClassNames}>
				<div
					className={clsx(
						'mt-7 lg:items-center lg:flex-row lg:justify-between',
						layoutClassNames
					)}
				>
					<div className="flex">
						<GatsbyImage
							image={speakerImageData}
							alt={speakerImageAlt}
							className="w-12 h-12 rounded-[100%] mr-4 flex-shrink-0"
							imgClassName="rounded-[100%]"
						/>
						<div>
							<p className="text-sm font-centra-medium">
								{speaker.name}
							</p>
							<p className={clsx('block font-centra-light')}>
								{speaker.jobTitle}
							</p>
						</div>
					</div>
					<div className="flex items-center mt-6 lg-w-full lg:ml-2">
						{socials.length > 0 ? (
							<SpeakerLinks
								links={socials}
								speaker={speaker.name}
								className="flex items-center lg:mt-0 [&>li]:mr-4 [&>li:last-child]:mr-0"
							/>
						) : null}
						{hasBio ? (
							<Button
								variant="White"
								size="Tiny"
								id={`readBioBtn${bioRef}`}
								onClick={() => {
									setIsBioVisible(!isBioVisible);
								}}
								className="min-w-[8rem] ml-4"
							>
								<Icon className="w-5 mr-2" width="20" />{' '}
								{isBioVisible ? 'Hide bio' : 'Read bio'}
							</Button>
						) : null}
					</div>
				</div>
				{hasBio ? (
					<div
						role="dialog"
						aria-hidden={!isBioVisible}
						aria-labelledby={`readBioBtn${bioRef}`}
						className={clsx(
							'overflow-hidden max-h-0 duration-500 transition-all',
							isBioVisible && `max-h-[320px] md:max-h[228px]`
						)}
					>
						<div className="py-5 mt-8 bg-brand-pale-400 px-s-f text-sm border-l-[3px] border-l-sm border-blue-400 [&>p]:!text-sm md:[&>p]:!text-sm [&_a]:!text-sm md:[&_a]:!text-sm">
							{getRteContent(
								speaker.bio.value.document.children,
								id
							)}
						</div>
					</div>
				) : null}
			</div>
		</>
	);
};

Speaker.defaultProps = {
	displayClassNames: '',
	layoutClassNames: '',
	bioRef: '',
};

Speaker.propTypes = {
	content: PropTypes.shape({
		id: PropTypes.string,
		speaker: speakerProp,
	}).isRequired,
	displayClassNames: PropTypes.string,
	layoutClassNames: PropTypes.string,
	bioRef: PropTypes.string,
};

export { Speaker };
