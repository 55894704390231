// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { keyframes } from 'styled-components';
import { Button } from '../../atoms/button';
import { Select } from '../../atoms/select';
import { InputVariants } from '../../atoms/text-input';
import { sendTrackingData } from '../../../utils';
import { employeeData } from './data/employee-data';
import { industryData } from './data/industry-data';
import VapLink from '../vap-link';
import { useModal } from '../../../contexts/modal-context';

const VapCalculatorVideoCampaignCA = ({
	vapSectionPrimaryLinkCopy,
	setEmployeeCount,
	title,
	subtitle,
	modalRef,
	vapLinkShow,
	setVapLinkShow,
}) => {
	const elName = 'employeeCount';
	const buttonText = 'Calculate cost';

	const [inputValue, setInputValue] = useState('');
	const [industry, setIndustry] = useState('');
	const industryRef = useRef(null);
	const [province, setProvince] = useState('');
	const provinceRef = useRef(null);
	const [costPercentage, setCostPercentage] = useState();
	const { setModalRef } = useModal();
	const [isVAPClicked, setIsVAPClicked] = useState(false);
	const [progressBarWidth, setProgressBarWidth] = useState();
	const [priceCalculated, setPriceCalculated] = useState({
		lateness_absence: '-',
		staff_turnover: '-',
		hr_docs: '-',
		hs_docs: '-',
		disciplinary: '-',
		ohs_fines: '-',
		general_hr: '-',
		cost_accidents: '-',
		average_wrongful: '-',
		cost_hr_manager: '-',
		cost_hs_manager: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
		time_saved: '-',
	});

	const vapItemsInitialState = [
		{ name: 'lateness_absence', label: 'Cost of lateness & absence' },
		{ name: 'staff_turnover', label: 'Staff turnover' },
		{ name: 'hr_docs', label: 'HR documents' },
		{ name: 'hs_docs', label: 'Health & safety documents' },
		{ name: 'disciplinary', label: 'Disciplinary administrative work' },
		{ name: 'ohs_fines', label: 'OHS fines for incomplete training' },
		{
			name: 'general_hr',
			label: 'General HR, paperwork ‘keeping on top of things’',
		},
		{
			name: 'cost_accidents',
			label: 'Cost of accidents in your business',
		},
		{
			name: 'average_wrongful',
			label: 'Average wrongful dismissal claim (per year)',
		},
	];

	const dataArr = [];

	function vapHandleInput(employeeCountInLimit) {
		const selectedEmployeeData = employeeData[employeeCountInLimit];
		const selectedIndustryData = industryData[industry];
		const currency = '$';

		if (selectedEmployeeData) {
			let sum = 0;
			const annualCost = selectedEmployeeData.annual_cost;
			const timeSaved = selectedEmployeeData.time_saved.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}
			);

			// Calculate impact
			const impact = {};
			Object.entries(selectedEmployeeData).forEach(([key, value]) => {
				if (key in selectedIndustryData) {
					impact[key] = value * selectedIndustryData[key];
					sum += value * selectedIndustryData[key];
				}
			});

			Object.entries(impact).map(([key, value]) => {
				dataArr.push({
					[key]: `${currency}${value.toLocaleString(undefined, {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					})}`,
				});
				return null;
			});

			const totalCost = sum;
			const annualRoi = sum / annualCost;

			const costPercent =
				parseFloat(parseFloat(annualCost) / parseFloat(totalCost)) *
				100;
			setCostPercentage(costPercent);

			dataArr.push({
				total_cost: `${currency}${totalCost.toLocaleString(undefined, {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				})}`,
			});
			dataArr.push({
				annual_roi: `${currency}${annualRoi.toLocaleString(undefined, {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})}`,
			});
			dataArr.push({
				annual_cost: `${currency}${annualCost.toLocaleString(
					undefined,
					{
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}
				)}`,
			});
			dataArr.push({ time_saved: timeSaved });
			// also returns costPercent for progress bar width
			return new Promise((resolve) => {
				resolve(costPercent);
			});
		}
	}

	const handleOnChange = (e) => {
		setInputValue(e.target.value);
		return null;
	};

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return null;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-calculator',
			click_type: 'primary',
			click_action: 'change state',
			click_title: title,
			click_text: buttonText,
		});
		return null;
	};

	const submitHandler = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const employeeCount = formData.get('employeeCount');
		if (!employeeCount) {
			return null;
		}
		setEmployeeCount(parseInt(employeeCount, 10));
		const employeeCountInLimit = employeeCount > 100 ? 100 : employeeCount;
		const costPercent = await vapHandleInput(employeeCountInLimit);
		setPriceCalculated(Object.assign({}, ...dataArr));
		handleClickTracking();
		window.location.href = '#vapform';
		setIsVAPClicked(true);
		setProgressBarWidth(Math.round(costPercent));
	};

	const animateWidth = (endWidth) => keyframes`
  		0% {
    		width: 0%;
  		}
  		100% {
    		width: ${endWidth}%;
  		}
		`;

	const AnimatedProgressBar = styled.div`
		padding: 6px;
		animation: ${(props) => animateWidth(props.endWidth)} 4s linear;
		width: ${(props) => `${props.endWidth}%`};
		background-color: ${(props) => props.color};
		height: 1.25rem;
		border-radius: 0.75rem;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
			0 1px rgba(255, 255, 255, 0.08);
		background-image: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0.4),
			rgba(255, 255, 255, 0.01)
		);
		transition: 4s linear;
	`;

	const getAnimatedProgressBar = useCallback(
		(colorHEX, widthPercentage) => {
			const clampedWidth = Math.min(Math.max(widthPercentage, 0), 100);
			return (
				<AnimatedProgressBar color={colorHEX} endWidth={clampedWidth} />
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[progressBarWidth]
	);
	return (
		<>
			<div className="flex flex-col gap-5 scroll-mt-[79px] md:scroll-mt-[160px] scroll-mt-[140px]">
				<div className="">
					<form
						onSubmit={submitHandler}
						className="flex flex-col items-center "
					>
						<label
							htmlFor={elName}
							className="
				font-castledown-regular 
				text-center 
				xl:text-2xl
				xl:max-w-2xl
				sm:text-xl
				sm:max-w-xl
				xs: text-xl
				xs: max-w-xl
				"
						>
							{subtitle}
						</label>
						<div className="flex flex-col w-full text-center focus-within:outline-blue-400/[0.20] border-2 border-[#E5E4E4] pb-base-f rounded-[.75rem] shadow-sm w-5xl p-5">
							<div className="basis-1/3">
								<div className="flex flex-col lg:flex-row xl:flex-row">
									<div className="w-full lg:w-1/3">
										<div className="text-left font-castledown-regular mx-5 mt-5 mb-0 lg:m-5 lg:text-lg xl:text-2xl">
											Province
										</div>
										<div className="max-w-full mr-5 pr-5">
											<Select
												name="province"
												id="province"
												value={province}
												onChange={(e) =>
													setProvince(
														e.currentTarget.value
													)
												}
												className="mb-9 ml-5 w-full text-xl text-black"
												aria-required="true"
												placeholder="Please select"
												options={[
													{
														value: 'Alberta',
														label: 'Alberta',
													},
													{
														value: 'British Columbia',
														label: 'British Columbia',
													},
													{
														value: 'Manitoba',
														label: 'Manitoba',
													},
													{
														value: 'New Brunswick',
														label: 'New Brunswick',
													},
													{
														value: 'Newfoundland and Labrador',
														label: 'Newfoundland and Labrador',
													},
													{
														value: 'Nova Scotia',
														label: 'Nova Scotia',
													},
													{
														value: 'Ontario',
														label: 'Ontario',
													},
													{
														value: 'Prince Edward Island',
														label: 'Prince Edward Island',
													},
													{
														value: 'Quebec',
														label: 'Quebec',
													},
													{
														value: 'Saskatchewan',
														label: 'Saskatchewan',
													},
												]}
												validationMessage="Please select"
												ref={provinceRef}
												required
											/>
										</div>
									</div>
									<div className="w-full lg:w-1/3">
										<div className="text-left font-castledown-regular mx-5 -mt-3 mb-5 lg:m-5 lg:text-lg xl:text-2xl">
											No. of Employees
										</div>
										<div className="max-w-full mr-5 pr-5">
											<input
												className={clsx(
													InputVariants.Default,
													'text-xl h-auto mx-5 mb-5 w-full appearance-none transition-colors duration-200',
													'xs:mr-5',
													'hover:bg-brand-pale-400',
													'focus:border-blue-400 focus:placeholder:text-brand-blue-400',
													'motion-reduce:transition-none',
													inputValue.length > 0
														? 'border-[#E5E4E4] bg-brand-pale-300'
														: ''
												)}
												min={1}
												type="number"
												id={elName}
												name={elName}
												required
												aria-required="true"
												onChange={handleOnChange}
												value={inputValue}
												placeholder="e.g. 22"
											/>
										</div>
									</div>
									<div className="w-full lg:w-1/3 bg-gray-500">
										<div className="text-left font-castledown-regular mx-5 my-0 lg:m-5 lg:text-lg xl:text-2xl">
											Industry
										</div>
										<div className="max-w-full mr-5 pr-5">
											<Select
												name="industry"
												id="industry"
												value={industry}
												onChange={(e) =>
													setIndustry(
														e.currentTarget.value
													)
												}
												className="mb-9 m-5 w-full text-xl text-black"
												aria-required="true"
												placeholder="Please select"
												options={[
													{
														value: 'agriculture',
														label: 'Agriculture',
													},
													{
														value: 'automotive',
														label: 'Automotive',
													},
													{
														value: 'beauty_hair',
														label: 'Beauty & hair',
													},
													{
														value: 'childcare',
														label: 'Childcare',
													},
													{
														value: 'construction',
														label: 'Construction',
													},
													{
														value: 'dentists',
														label: 'Dentists',
													},
													{
														value: 'education',
														label: 'Education',
													},
													{
														value: 'finance_insurance',
														label: 'Finance & insurance',
													},
													{
														value: 'health_medical',
														label: 'Health & medical',
													},
													{
														value: 'hospitality',
														label: 'Hospitality',
													},
													{
														value: 'household_services',
														label: 'Household services',
													},
													{
														value: 'manufacturing',
														label: 'Manufacturing',
													},
													{
														value: 'professional_services',
														label: 'Professional Services',
													},
													{
														value: 'real_estate',
														label: 'Real estate',
													},
													{
														value: 'retail_wholesale',
														label: 'Retail & Wholesale',
													},
													{
														value: 'sports_recreation',
														label: 'Sports & recreation',
													},
													{
														value: 'transportation_storage',
														label: 'Transportation & storage',
													},
													{
														value: 'vets_pets',
														label: 'Vets & Pets',
													},
													{
														value: 'other',
														label: 'Other',
													},
												]}
												validationMessage="Please select"
												ref={industryRef}
												required
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="basis-1/3 text-white mb-5 lg:mt-4">
								<Button
									type="submit"
									className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 transition delay-150 duration-1000 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
								>
									{buttonText}
								</Button>
							</div>
						</div>
					</form>
				</div>
				<div
					id="vapform"
					className="flex flex-col shadow-sm rounded-[.75rem] border-2 border-[#E5E4E4] scroll-mt-[79px] md:scroll-mt-[160px] lg:scroll-mt-[88px]"
				>
					<div className="p-10 border-b-1 border-b-[#E5E4E4]">
						{/* top */}
						<div className="flex flex-col gap-5">
							<div className="flex flex-row w-full gap-2 overflow-auto justify-between text-2xl font-bold font-centra-no1 text-xl lg:text-2xl">
								<div className="">
									Annual cost to your business
								</div>
								<div className="text-[#E30138]">
									{priceCalculated.total_cost}
								</div>
							</div>
							<div className="bg-[#E5E4E4] min-w-full rounded-lg h-5 -mb-4">
								{isVAPClicked && (
									<div>
										{getAnimatedProgressBar('#E30138', 100)}
									</div>
								)}
							</div>
							<div className="flex flex-row">
								<div className="mx-auto text-black">
									{vapLinkShow && costPercentage !== 0 && (
										<>
											<div className="-mb-3 text-white">
												<VapLink
													vapLinkShow={vapLinkShow}
													setVapLinkShow={
														setVapLinkShow
													}
													text="Click to see your total cost breakdown"
													className=""
												/>
											</div>
										</>
									)}
								</div>
							</div>
							{!vapLinkShow && (
								<div className="col-1 focus-within:outline-blue-400/[0.20] border-1 border-blue-300 px-s-f py-base-f rounded-[.75rem] h-fit shadow-sm xs:mb-5 lg:mb-0 bg-[#edf4ff]">
									<div className="text-2xl my-3 font-black">
										Your total cost breakdown
									</div>
									{vapItemsInitialState.map(
										(elm, idx, arr) => (
											<>
												<div
													className={`${
														idx + 1 !== arr.length
															? 'border-b '
															: ''
													}flex flex-col justify-between py-8 lg:py-3 lg:flex-row border-[#1F2E7A]`}
												>
													<div className="flex flex-row items-center ">
														<div className="font-centra shrink-0 text-[#1D2951] text-[18px] md:text-[20px] lg:text-[20px] xl:text-[22px] leading-[26px] w-fit break-words">
															{elm.label}
														</div>
													</div>
													<div className="flex items-center pt-5 lg:pt-0 shrink-0 text-[#3954E0] font-normal text-[20px] lg:text-[20px] xl:text-[22px]">
														{
															priceCalculated[
																`${elm.name}`
															]
														}
													</div>
												</div>
											</>
										)
									)}
								</div>
							)}
						</div>
						{!vapLinkShow && (
							<div className="basis-1/3 text-white text-center">
								<VapLink
									vapLinkShow={vapLinkShow}
									setVapLinkShow={setVapLinkShow}
								/>
							</div>
						)}
					</div>
					<div className="p-10 border-b-1 border-b-[#E5E4E4]">
						<div className="flex flex-col gap-5">
							<div className="flex flex-row w-full gap-2 overflow-auto justify-between text-2xl font-bold font-centra-no1 text-xl lg:text-2xl">
								<div className="">
									Annual cost of Peninsula service
								</div>
								<div className="text-[#189864] ">
									{priceCalculated.annual_cost}
								</div>
							</div>
							<div className="bg-[#E5E4E4] w-full rounded-lg h-5 ">
								{isVAPClicked && (
									<div>
										{getAnimatedProgressBar(
											'#189864',
											progressBarWidth
										)}
									</div>
								)}
							</div>
							<div className="w-full flex flex-col gap-1">
								<div className="flex flex-row justify-between">
									<div className="text-xl">
										Return on Investment
										<div className="text-sm m-1 lg:mx-1 md:inline">
											(for every $1 spent)
										</div>
									</div>
									<div className="text-xl font-bold">
										{priceCalculated.annual_roi}
									</div>
								</div>
								<div className="flex flex-row justify-between">
									<div className="text-xl">Hours saved</div>
									<div className="text-xl font-bold">
										{priceCalculated.time_saved}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="p-10 mx-auto">
						{!vapLinkShow ? (
							<>
								<div className="basis-1/3 text-white mt-4">
									<Button
										onClick={() => setModalRef(modalRef)}
										className="hidden md:block mx-auto hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 transition delay-150 duration-1000 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
									>
										{vapSectionPrimaryLinkCopy}
									</Button>
									{/* mobile devices */}
									<Button
										onClick={() => {
											window.location.href =
												'#adviceform';
										}}
										className="block md:hidden hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 transition delay-150 duration-1000 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
									>
										{vapSectionPrimaryLinkCopy}
									</Button>
								</div>
							</>
						) : (
							<>
								<Button
									onClick={() => setModalRef(modalRef)}
									className="hidden md:block mx-auto hover:bg-brand-blue-400 hover:border-brand-blue-400 transition delay-150 duration-1000 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
								>
									{vapSectionPrimaryLinkCopy}
								</Button>
								{/* mobile devices */}
								<Button
									onClick={() => {
										window.location.href = '#adviceform';
									}}
									className="block md:hidden hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 transition delay-150 duration-1000 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
								>
									{vapSectionPrimaryLinkCopy}
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export { VapCalculatorVideoCampaignCA };

VapCalculatorVideoCampaignCA.defaultProps = {
	setEmployeeCount: () => {},
	title: '',
	subtitle: '',
};

VapCalculatorVideoCampaignCA.propTypes = {
	setEmployeeCount: PropTypes.func,
	title: PropTypes.string,
	subtitle: PropTypes.string,
};
