import React from 'react';
import PropTypes from 'prop-types';

import CalendarIcon from '../../../assets/calendar.inline.svg';

const EventDate = ({ date, time, theme }) => {
	const themeMap = {
		Light: {
			color: 'text-[#9CAAF0]',
		},
		Default: {
			color: 'text-blue-400',
		},
	}[theme];
	return (
		<p className={`mb-5 text-sm ${themeMap.color} md:mb-8 md:text-xl`}>
			<CalendarIcon
				className="inline w-5 mr-2 align-text-top md:align-baseline"
				aria-hidden="true"
			/>{' '}
			{date} <span className="mx-2">/</span> {time}
		</p>
	);
};

export { EventDate };

EventDate.defaultProps = {
	theme: 'Default',
};

EventDate.propTypes = {
	date: PropTypes.string.isRequired,
	time: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(['Default', 'Light']),
};
