/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/require-default-props */
/* eslint-disable compat/compat */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { useLocation } from '@reach/router';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

import { titleProp, datoGatsbyImageProp, refProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { sendTrackingData } from '../../../utils';
import { buildLink, getLocale } from '../../../utils/locale';

import { useModal } from '../../../contexts/modal-context';

import * as headingStyles from './index.module.css';

import tick from './blue_tick.svg';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const HeroPpcV2CA = ({
	id,
	header,
	secondaryHeaderRows,
	secondaryHeader,
	as,
	googleReview,
	primaryLink,
	primaryLinkCopy,
	primaryLinkOpenForm,
	primaryImage,
	showTrustPilot: showBusinessReview,
	headingClassNames,
	secondaryLinkCopy,
	secondaryLink,
	showSecondButton,
	modalRef,
	glu,
	logo,
	// ctaLabel,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});

	const { setModalRef } = useModal();
	const url = useLocation();
	const urlParams = new URLSearchParams(url.search);
	const locationParam = urlParams.get('h1');

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;

	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setModalRef(modalRef);
					handleClickTracking();
				}}
				className="inline-flex items-center px-6 py-4 mb-2 mr-2 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max"
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	let cta2;

	if (secondaryLink && secondaryLinkCopy && primaryLink) {
		cta2 = (
			<PBSLink
				className="justify-center px-6 text-blue-400 bg-white w-fit sm:w-max hover:text-white"
				to={secondaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{secondaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta2 = (
			<PBSLink
				className="justify-center px-6 text-blue-400 bg-white w-fit sm:w-max hover:text-white"
				to="/ca/pricing/"
				variant="White"
				onClick={handleClickTracking}
			>
				View services and pricing
			</PBSLink>
		);
	}

	return (
		<section
			role="banner"
			className={clsx(
				'px-4 xl:px-8 mx-auto lg:max-w-m-screen -mt-5 sm:mt-0 mb-12 md:mb-0 lg:mb-0 xl:mb-0 ',
				{
					'bg-brand-pale-400': glu,
					'lg:max-w-m-screen': !glu,
				}
			)}
			aria-labelledby={id}
		>
			<div className="flex flex-row items-center justify-center mt-12 lg:mt-16 xl:mt-10">
				<div className="w-full lg:mb-2 xl:m-0">
					<div
						className={clsx('flex mx-auto', {
							'lg:max-w-m-screen': glu,
						})}
					>
						<span
							className={clsx(
								'flex flex-col',
								headingStyles.headingContainer
							)}
						>
							{logo && (
								<img
									src={logo.url}
									alt={logo.alt || ''}
									className="max-w-[275px]"
								/>
							)}

							<div
								className={clsx(
									'inline !leading-[1.3] font-castledown-regular mx-5 xl:mx-0',
									!secondaryHeader
										? headingStyles.heading
										: null,
									glu
										? 'inline text-2xl lg:text-4xl font-bold lg:min-h-[160px]'
										: 'inline text-4xl md:text-4xl xl:text-5xl ',
									headingClassNames
								)}
							>
								<Component id={id} className="inline">
									{locationParam || titleText}
								</Component>
								<span
									id="dynamic-loc"
									className="inline dynamic-loc"
								/>
								{secondaryHeaderRows.length > 0 ? (
									<ul
										className={clsx(
											'mt-8 mx-2',
											'list-none',
											'mt-2 leading-[1.2] block',
											'md:mt-6',
											' xl:leading-[1.3]',
											glu
												? 'text-xl lg:text-2xl font-castledown-regular'
												: `text-2xl md:text-3xl ${headingStyles.heading} `
										)}
									>
										{secondaryHeaderRows.map(
											({ secondaryHeaderRow }) => (
												<div className="flex flex-row gap-4 my-1">
													<div className="mt-2 min-w-[20px] max-w-[20px]">
														<img
															src={tick}
															alt="Tick"
														/>
													</div>
													<div>
														<li className="max-w-full text-[21px] sm:text-[24px] lg:text-[20px] xl:text-[24px]">
															{secondaryHeaderRow}
														</li>
													</div>
												</div>
											)
										)}
									</ul>
								) : (
									<span
										className={clsx(
											'mt-2 leading-[1.2] block',
											'md:mt-2',
											' xl:leading-[1.3]',
											glu
												? 'text-xl lg:text-2xl font-castledown-regular'
												: `text-2xl md:text-3xl ${headingStyles.heading} `
										)}
									>
										{secondaryHeader}
									</span>
								)}
							</div>
							<span className="md:ml-5 xl:ml-0 xl:px-2">
								<p className="flex flex-col items-center justify-center gap-3 mt-8 sm:flex-row sm:justify-start">
									{/* 1st CTA */}
									{cta || null}
									{/* 2nd CTA */}
									{showSecondButton ? cta2 : null}
								</p>

								{showBusinessReview && locale === 'ca' ? (
									<BusinessReviewHorizontal
										googleReview={googleReview}
										onlyGR
									/>
								) : null}
							</span>
						</span>
					</div>
				</div>
				<div
					className={clsx(
						'w-full hidden lg:mr-1 xl:-m-6 lg:block scale-90 lg:self-start xl:self-start'
					)}
				>
					<img
						src={primaryImage.url}
						alt={primaryImage.alt}
						className={clsx('drop-shadow-image w-full ml-auto')}
					/>
				</div>
			</div>
		</section>
	);
};

HeroPpcV2CA.defaultProps = {
	id: 'main-site-header-v2-ca',
	as: 'h1',
	secondaryHeader: '',
	headingClassNames: '',
	showTrustPilot: true,
	showSecondButton: true,
	glu: false,
	logo: {},
	secondaryHeaderRows: [],
	secondaryLink: '',
	secondaryLinkCopy: '',
};

HeroPpcV2CA.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	secondaryHeader: PropTypes.string,
	as: PropTypes.string,
	primaryImage: datoGatsbyImageProp.isRequired,
	// secondaryImage: datoGatsbyImageProp.isRequired,
	headingClassNames: PropTypes.string,
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	secondaryLink: PropTypes.string,
	secondaryLinkCopy: PropTypes.string,
	showSecondButton: PropTypes.bool,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	showTrustPilot: PropTypes.bool,
	secondaryHeaderRows: PropTypes.arrayOf(PropTypes.string),
	modalRef: refProp,
	glu: PropTypes.bool,
	logo: datoGatsbyImageProp,
	googleReview: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
};

export { HeroPpcV2CA };
