/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { BusinessReviewHorizontal } from '../../atoms/business-reviews';

import { titleProp, datoGatsbyImageProp, refProp } from '../../../types';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import { sendTrackingData } from '../../../utils';
import { buildLink, getLocale } from '../../../utils/locale';

import { useDrawer } from '../../../contexts/drawer-context';

import * as headingStyles from './index.module.css';
import tick from './blue_tick.svg';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const MainHeaderCA = ({
	id,
	header,
	secondaryHeaderRows,
	as,
	primaryLink,
	primaryLinkCopy,
	secondaryLinkCopy,
	primaryLinkOpenForm,
	secondaryLink,
	headingClassNames,
	googleReview,
	drawerRef,
	glu,
	logo,
	media,
}) => {
	const Component = as;
	const titleText = createHeadingHighlight({
		headings: header[0].children,
		headingStyles,
	});

	const { setDrawerRef } = useDrawer();

	const primaryImage = {
		alt: '',
		url: `${media[0]?.media?.url}?auto=format`,
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: `${media[0]?.media?.url}&dpr=0.8&fit=max&w=500`,
					srcSet: `${media[0]?.media?.url}&dpr=0.2&fit=max&w=500 100w,\n${media[0]?.media?.url}&dpr=0.4&fit=max&w=500 200w,\n${media[0]?.media?.url}&dpr=0.8&fit=max&w=500 400w`,
					sizes: '(min-width: 400px) 400px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICBIXEBULDhoZFQ0NDhUOFg0NFxsZGBYVFhUmHysjGh0oHSEWJDUlKC0vMjIyGSU4PTcwPCsxMi8BCgsLDg0OHBANFS8cFhw1Ly8vLy8vLy8vLy8vLy8vLy8vLy81Ly8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIABEAGAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAABAUG/8QAHhAAAgICAgMAAAAAAAAAAAAAAAECAwQFBjQREjX/xAAVAQEBAAAAAAAAAAAAAAAAAAAEA//EABgRAAIDAAAAAAAAAAAAAAAAAAABAgMx/9oADAMBAAIRAxEAPwCzN5Gq871J47ZyynYjO2uJCWx8xJHY6rnFEVaykqYmlXyuynaOKBycq7pbVtAurGQdUTq8/vmZb3GACWjZ4S1/RAAlYFZ//9k=',
			},
			width: 400,
			height: 400,
		},
	};

	const secondaryImage = {
		alt: '',
		url: `${media[1]?.media?.url}?auto=format`,
		gatsbyImageData: {
			images: {
				sources: [],
				fallback: {
					src: `${media[1]?.media?.url}&dpr=0.76&fit=max&w=500`,
					srcSet: `${media[1]?.media?.url}&dpr=0.19&fit=max&w=500 95w,\n${media[1]?.media?.url}&dpr=0.38&fit=max&w=500 190w,\n${media[1]?.media?.url}&dpr=0.76&fit=max&w=500 380w`,
					sizes: '(min-width: 380px) 380px, 100vw',
				},
			},
			layout: 'constrained',
			placeholder: {
				fallback:
					'data:image/jpg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHBwgHBgoICAgXChQLDhYVFQ0WFSIVDRUNFx0dGBYfFhUdHysjGh0oHRUWJDUlKC0vMjIyGSI4PTcwPCsxMi8BCgsLDg0OHBANHS8cFh0vLy8vLy8vLy8vLy8vLy8vLy8vLy8vNS8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vLy8vL//AABEIABEAGAMBIgACEQEDEQH/xAAYAAEAAwEAAAAAAAAAAAAAAAAAAgQFBv/EACEQAAEEAQMFAAAAAAAAAAAAAAABAgMEBREScSEiMjM1/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIDBP/EABkRAAMAAwAAAAAAAAAAAAAAAAABAgMRMf/aAAwDAQACEQMRAD8AjRqOSboWEYqXNrjPw2QkfY7izYs639EJOkPh00aEOCr2rW5yghFJMyVFaoHVonUrZz+G95al+knIBnY2Dhsx+SAAKBXT/9k=',
			},
			width: 380,
			height: 330,
		},
	};

	const primaryImageData = getImage(primaryImage);
	const secondaryImageData = getImage(secondaryImage);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = header[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;

	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					handleClickTracking();
				}}
				className="inline-flex items-center px-6 py-4 text-lg leading-6 text-white transition-colors bg-blue-400 border-2 border-blue-400 rounded-lg md:mb-0 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 md:px-8 md:text-xl font-centra-medium focus:outline-0 focus:shadow-focus w-max lg:px-5"
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink && glu) {
		cta = (
			<PBSLink
				className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
				to={primaryLink}
				variant="White"
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}
	return (
		<div
			role="banner"
			className={clsx(
				'px-4 mx-auto lg:max-w-m-screen md:py-10 lg:py-0 lg:px-10 sm:mt-0 mb-12 md:mb-0 xl:px-12 2xl:pt-10 2xl:px-0',
				{
					'bg-brand-pale-400': glu,
					'lg:max-w-m-screen': !glu,
				}
			)}
			aria-labelledby={id}
		>
			<div className="flex flex-row items-center ">
				<div className="lg:w-3/5 xl:w-3/5 2xl:w-[54%]">
					<div
						className={clsx('flex mx-auto', {
							'lg:max-w-m-screen': glu,
						})}
					>
						<span
							className={clsx(
								'flex flex-col',
								headingStyles.headingContainer
							)}
						>
							{logo && (
								<img
									src={logo.url}
									alt={logo.alt || ''}
									className="max-w-[275px]"
								/>
							)}
							<div
								className={clsx(
									'inline !leading-[1.3] font-castledown-regular mx-5 xl:mx-0',
									headingStyles.heading,
									glu
										? 'inline text-2xl lg:text-4xl font-bold lg:min-h-[160px]'
										: 'inline text-4xl md:text-4xl xl:text-5xl ',
									headingClassNames
								)}
							>
								<Component
									id={id}
									className="!leading-[1.3] font-castledown-regular index-module--heading--48393 text-4xl md:text-5xl xl:text-5xl"
								>
									{titleText}
								</Component>
								<ul
									className={clsx(
										'mx-2',
										'list-none',
										'mt-2 leading-[1.2] block',
										'xl:leading-[1.3]',
										'pt-3',
										glu
											? 'text-xl lg:text-2xl font-castledown-regular'
											: `text-2xl md:text-3xl ${headingStyles.heading} `
									)}
								>
									{secondaryHeaderRows.map(
										({ secondaryHeaderRow }) => (
											<div className="flex flex-row gap-4 py-1 my-1 xl:py-1 2xl:py-2">
												<div className="mt-2 min-w-[20px]">
													<img src={tick} alt="" />
												</div>
												<div>
													<li className="max-w-full text-[21px] sm:text-[24px]">
														{secondaryHeaderRow}
													</li>
												</div>
											</div>
										)
									)}
								</ul>
							</div>
							{primaryLink || secondaryLink ? (
								<p className="flex flex-col items-center justify-center mt-8 sm:px-5 lg:pl-5 xl:pl-0 sm:flex-row sm:justify-start">
									{cta || null}
									{secondaryLink && secondaryLinkCopy ? (
										<PBSLink
											to={
												glu
													? secondaryLink
													: buildLink(
															locale,
															secondaryLink
													  )
											}
											variant="White"
											className="justify-center mt-4 sm:mt-0 sm:ml-4 lg:px-5"
										>
											{secondaryLinkCopy}
										</PBSLink>
									) : null}
								</p>
							) : null}
						</span>
					</div>
				</div>
				{/* right div for img */}
				<div
					className={`hidden lg:block grid-cols-12 lg:grid grow shrink-0 ${headingStyles.imageContainer} lg:w-2/5 lg:pl-12 xl:pt-5 2xl:w-[46%] 2xl:pl-10`}
				>
					<GatsbyImage
						image={primaryImageData}
						alt={primaryImage.alt || ''}
						key={primaryImage.url}
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image',
							'lg:-ml-8',
							'lg:h-[250px]',
							'lg:w-[250px]',
							'xl:ml-8',
							'xl:h-[280px]',
							'xl:w-[280px]',
							'2xl:-mb-10',
							'2xl:ml-20',
							'2xl:h-[334px]',
							'2xl:w-[334px]',
							headingStyles.image1
						)}
					/>
					<GatsbyImage
						image={secondaryImageData}
						alt={secondaryImage.alt || ''}
						key={secondaryImage.url}
						className={clsx(
							'border-8',
							'border-white',
							'drop-shadow-image',
							'lg:h-[250px]',
							'lg:w-[250px]',
							'xl:-mt-14',
							'xl:h-[280px]',
							'xl:w-[280px]',
							'2xl:-mt-24',
							'2xl:h-[334px]',
							'2xl:w-[334px]',
							headingStyles.image2
						)}
					/>
				</div>
			</div>
			<div className="sm:px-5 xl:px-0 2xl:px-5 xl:-mt-8">
				<BusinessReviewHorizontal googleReview={googleReview} />
			</div>
		</div>
	);
};

MainHeaderCA.defaultProps = {
	id: 'main-site-header-v2-ca',
	as: 'h1',
	headingClassNames: '',
	glu: false,
	logo: {},
	secondaryHeaderRows: [],
};

MainHeaderCA.propTypes = {
	id: PropTypes.string,
	header: titleProp.isRequired,
	as: PropTypes.string,
	headingClassNames: PropTypes.string,
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	secondaryLinkCopy: PropTypes.string.isRequired,
	primaryLinkOpenForm: PropTypes.bool.isRequired,
	secondaryLink: PropTypes.string.isRequired,
	secondaryHeaderRows: PropTypes.arrayOf(PropTypes.string),
	drawerRef: refProp,
	glu: PropTypes.bool,
	logo: datoGatsbyImageProp,
	googleReview: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
};

export { MainHeaderCA };
