import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { Controller, Manipulation, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useLocation } from '@reach/router';
import { Button } from '../../atoms/button';
import { CarouselNavigation } from '../../atoms/carousel-navigation';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { PBSLink } from '../../atoms/link';

import { QuoteCard } from '../../molecules/quote-card';

import { useDrawer } from '../../../contexts/drawer-context';
import { gatsbyImageProp, refProp, titleProp } from '../../../types';
import { sendTrackingData } from '../../../utils';

import * as testimonialStyles from './index.module.css';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { buildLink, getLocale } from '../../../utils/locale';

const Testimonials = ({
	testimonialsContent,
	drawerRef,
	className,
	hasBottomCtas,
}) => {
	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const paginationRef = useRef(null);
	const [activeTestimonial, setActiveTestimonial] = useState(0);
	const [firstSwiper, setFirstSwiper] = useState(null);
	const [secondSwiper, setSecondSwiper] = useState(null);
	const { setDrawerRef } = useDrawer();
	const [trackingIndex, setTrackingInddex] = useState(0);

	const { title, subText, slides, id } = testimonialsContent;
	const hasMoreThanOneSlide = slides.length > 1;
	const imagesHaveNoAlt = slides.every((slide) => slide.image.alt === null);
	const location = useLocation();
	const locale = getLocale(location.pathname);

	const renderImageSlide = (slide) => {
		const image = getImage(slide.image.gatsbyImageData);
		const imageAlt = slide.image.alt;

		return (
			<SwiperSlide key={`${slide.id}-image`} tag="li">
				<GatsbyImage
					image={image}
					alt={imageAlt || ''}
					className="border-8 border-white border-solid"
				/>
			</SwiperSlide>
		);
	};

	const showActiveTestimonial = (swiper) => {
		if (swiper.slides.length === 0) return;

		const activeSlide = swiper.slides[swiper.activeIndex];
		const index = activeSlide?.getAttribute('data-swiper-slide-index');

		setActiveTestimonial(parseInt(index, 10));
	};

	const handleOnInit = (swiper) => {
		if (swiper.slides.length === 0) return;

		const activeSlide = swiper.slides[swiper.activeIndex];
		const slideHtml = activeSlide?.outerHTML;
		const index = activeSlide?.getAttribute('data-swiper-slide-index');

		swiper.removeSlide(parseInt(index, 10));
		swiper.appendSlide(slideHtml);
	};

	const handleOnResize = (swiper) => {
		const isCorrectWindowSize = window.innerWidth >= 1280;
		const activeSlide = swiper.slides[swiper.activeIndex];
		const slideHtml = activeSlide?.outerHTML;
		const index = activeSlide?.getAttribute('data-swiper-slide-index');

		if (!isCorrectWindowSize || parseInt(index, 10) !== 0) return;

		swiper.removeSlide(parseInt(index, 10));
		swiper.appendSlide(slideHtml);
	};

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = title[0].children
			.map(({ value }) => value.replaceAll('\n', ' '))
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'testimonials',
			click_type: 'arrow',
			click_action: 'right',
			click_title: clickTitle,
			click_index: trackingIndex + 1,
		});
	};

	return (
		<section
			className={clsx('py-10 md:px-10 xl:pr-0 2xl:pr-10', className)}
			aria-roledescription="carousel"
			aria-labelledby={id}
		>
			<div className="px-3 md:container md:mx-auto">
				<HeadingTagLine id={id} title={title} subText={subText} />
			</div>

			<div className="relative flex flex-col items-center px-3 mt-9 sm:flex-row-reverse sm:justify-center sm:flex-wrap 2xl:container 2xl:mx-auto overflow-x-clip">
				<div className="contents xl:relative xl:grid xl:grid-cols-[auto_auto] xl:gap-5 xl:items-start xl:flex-1">
					<Swiper
						loop
						className="order-1 max-w-[365px] sm:[flex-basis:50%] !m-0 md:w-full md:max-w-[416px] lg:[flex-basis:auto] xl:order-none"
						modules={[Controller, Navigation, Pagination]}
						onActiveIndexChange={showActiveTestimonial}
						onSwiper={setSecondSwiper}
						controller={{ control: firstSwiper }}
						slidesPerView="auto"
						loopedSlides={slides.length}
						wrapperTag="ul"
						aria-hidden={imagesHaveNoAlt}
						navigation={
							hasMoreThanOneSlide && {
								nextEl: nextRef.current,
								prevEl: prevRef.current,
							}
						}
						pagination={{
							type: 'fraction',
							el: paginationRef.current,
							currentClass: testimonialStyles.paginationCurrent,
						}}
						onSlideChange={(e) => setTrackingInddex(e.realIndex)}
					>
						{slides.map(renderImageSlide)}
					</Swiper>

					<Swiper
						loop
						watchSlidesProgress
						modules={[Controller, Manipulation]}
						onInit={handleOnInit}
						onSwiper={setFirstSwiper}
						onResize={handleOnResize}
						controller={{ control: secondSwiper }}
						loopedSlides={slides.length}
						slidesPerView="auto"
						spaceBetween={20}
						wrapperTag="ul"
						className={clsx(
							'hidden xl:block',
							testimonialStyles.thumbsSwiper
						)}
					>
						{slides.map(renderImageSlide)}
					</Swiper>

					<CarouselNavigation
						className="order-3 w-full mt-6 xl:order-none xl:w-auto xl:absolute xl:bottom-[5%] xl:right-[5%] xl:mt-0 2xl:right-0"
						hasMoreThanOneSlide={hasMoreThanOneSlide}
						nextRef={nextRef}
						prevRef={prevRef}
						paginationRef={paginationRef}
						onClickNextProp={handleClickTracking}
					/>
				</div>

				<div className="order-2 relative z-10 xs:-ml-6 -mt-20 grid grid-cols-1 items-start max-w-[340px] sm:mt-0 sm:[flex-basis:50%] sm:!ml-0 md:max-w-[420px] md:flex-1 lg:[flex-basis:auto] xl:order-none">
					{slides.map((slide, index) => (
						<QuoteCard
							key={`${slide.id}-quote`}
							as="div"
							quote={slide.quote}
							nameAndJob={slide.nameAndJob}
							company={slide.company}
							tags={slide.tags}
							className={clsx(
								'col-start-1 row-start-1 transition-opacity max-w-[340px] sm:max-w-[none] md:max-w-[420px]',
								index === activeTestimonial ? 'block' : 'hidden'
							)}
						/>
					))}
				</div>
			</div>

			{hasBottomCtas && (
				<div className="flex flex-col items-center justify-center mt-9 sm:flex-row">
					<Button
						onClick={() => setDrawerRef(drawerRef)}
						className="mb-7 sm:mb-0 sm:mr-7"
					>
						{testimonialsContent.primaryLinkCopy}
					</Button>

					{testimonialsContent.secondaryLink ? (
						<PBSLink
							to={buildLink(
								locale,
								testimonialsContent.secondaryLink
							)}
							variant="Link"
						>
							{testimonialsContent.secondaryLinkCopy}
						</PBSLink>
					) : null}
				</div>
			)}
		</section>
	);
};

export { Testimonials };

Testimonials.defaultProps = {
	className: '',
	hasBottomCtas: true,
};

Testimonials.propTypes = {
	testimonialsContent: PropTypes.shape({
		id: PropTypes.string,
		title: titleProp.isRequired,
		subText: PropTypes.string.isRequired,
		slides: PropTypes.arrayOf(
			PropTypes.shape({
				quote: PropTypes.string.isRequired,
				nameAndJob: PropTypes.string.isRequired,
				company: PropTypes.string.isRequired,
				tags: PropTypes.arrayOf(PropTypes.string),
				image: PropTypes.shape({
					alt: PropTypes.string.isRequired,
					gatsbyImageData: gatsbyImageProp.isRequired,
				}),
			})
		).isRequired,
		primaryLinkCopy: PropTypes.string.isRequired,
		secondaryLinkCopy: PropTypes.string.isRequired,
		secondaryLink: PropTypes.string.isRequired,
	}).isRequired,
	drawerRef: refProp.isRequired,
	className: PropTypes.string,
	hasBottomCtas: PropTypes.bool,
};
