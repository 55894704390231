// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';
import { Head } from '../components/templates/head';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { PressBanner } from '../components/organisms/press-banner';
import { PressInquiry } from '../components/organisms/press-inquiry';
import { CardContainer } from '../components/organisms/card-container-press';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { Drawer } from '../components/atoms/drawer';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';
import { getBreadcrumbsSeoSchema } from '../utils/seo-schema';
import { useDrawer } from '../contexts/drawer-context';
import { HrefLang } from '../components/templates/hrefLang';
import { ClientLogos } from '../components/atoms/client-logos-press';

const ResourcesPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsPeninsulaPressPage.noIndex,
		noFollow: data.datoCmsPeninsulaPressPage.noFollow,
		metaInformation: data.datoCmsPeninsulaPressPage.metaInformation,
		fallbackTitle: data.datoCmsPeninsulaPressPage.title,
		fallbackDescription: data.datoCmsPeninsulaPressPage.longIntroduction,
		canonicalUrl:
			data.datoCmsPeninsulaPressPage?.canonicalUrl || location.href,
	};

	const pressBannerContent = {
		heading: data.datoCmsPeninsulaPressPage.heading,
		slug: data.datoCmsPeninsulaPressPage.pressBanner.slug,
		title: data.datoCmsPeninsulaPressPage.pressBanner.title,
		intro: data.datoCmsPeninsulaPressPage.pressBanner.intro,
		thumbnail: data.datoCmsPeninsulaPressPage.pressBanner.thumbnail,
	};

	const pressInquiryContent = {
		heading: data.datoCmsPeninsulaPressPage.inquiriesHeading,
		subHeading: data.datoCmsPeninsulaPressPage.subHeading,
		address1: data.datoCmsPeninsulaPressPage.address1,
		address2: data.datoCmsPeninsulaPressPage.address2,
		address3: data.datoCmsPeninsulaPressPage.address3,
		phone: data.datoCmsPeninsulaPressPage.phone,
		email: data.datoCmsPeninsulaPressPage.email,
		image: data.datoCmsPeninsulaPressPage.image,
	};

	const pressArticleContainerContent = {
		totalCount: data.allDatoCmsPressArticlePage.totalCount,
		cards: data.allDatoCmsPressArticlePage.nodes,
	};

	const pressRadioContainerContent = {
		totalCount: data.allDatoCmsPressRadioPage.totalCount,
		cards: data.allDatoCmsPressRadioPage.nodes,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.resourceHub,
			location,
			data: data.datoCmsPeninsulaPressPage,
		}),
	};

	let pagePath = location.pathname;
	if (pagePath.endsWith('/')) {
		pagePath = location.pathname.slice(0, -1);
	}

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsPeninsulaPressPage.internal.type
	);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPeninsulaPressPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			<PressBanner
				drawerRef={adviceFormRef}
				pressBannerContent={pressBannerContent}
			/>

			<ClientLogos pathname={location.pathname} />
			{pressArticleContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollableArticle"
					cardContainerContent={{
						...pressArticleContainerContent,
						id: 'pressarticle-container',
						title: 'Latest articles',
						to: pagePath,
						toAll: `${pagePath}/articles`,
					}}
					cardVariant="PressArticle"
				/>
			)}

			{pressRadioContainerContent.totalCount > 0 && (
				<CardContainer
					variant="ViewAllScrollablerRadio"
					cardContainerContent={{
						...pressRadioContainerContent,
						id: 'pressradio-container',
						title: 'Radio',
						to: pagePath,
						toAll: `${pagePath}/radios`,
					}}
					cardVariant="PressRadio"
				/>
			)}

			<PressInquiry
				drawerRef={adviceFormRef}
				pressInquiryContent={pressInquiryContent}
			/>
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />

			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceFormCA
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessFormCA reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query resourceHub($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPeninsulaPressPage(locale: { eq: $locale }) {
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			originalId
			id
			internal {
				type
			}
			noIndex
			noFollow
			canonicalUrl
			heading
			inquiriesHeading
			subHeading
			address1
			address2
			address3
			phone
			email
			image {
				alt
				gatsbyImageData(width: 577, height: 474, layout: CONSTRAINED)
			}
			pressBanner {
				slug
				title
				intro
				thumbnail {
					alt
					gatsbyImageData(
						width: 661
						height: 495
						layout: CONSTRAINED
					)
				}
			}
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}
		allDatoCmsPressArticlePage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: { slug: { ne: null }, locale: { eq: $locale } }
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 500
						sizes: "(max-width: 640px) 400px, 800px"
						height: 300
					)
				}
				author {
					id
					name
					jobTitle
					image {
						alt
						url
						gatsbyImageData(width: 44, height: 44, layout: FIXED)
					}
				}
				logo {
					alt
					gatsbyImageData(width: 372, height: 45)
				}
				sectors {
					id
					name
				}
			}
		}
		allDatoCmsPressRadioPage(
			sort: { order: DESC, fields: created }
			limit: 3
			filter: { slug: { ne: null }, locale: { eq: $locale } }
		) {
			totalCount
			nodes {
				id
				internal {
					type
				}
				publishedDateOrdinal: created(formatString: "MMMM Do YYYY")
				publishedDate: created
				title
				slug
				intro
				thumbnail {
					alt
					gatsbyImageData(
						aspectRatio: 53
						placeholder: DOMINANT_COLOR
						width: 400
						sizes: "(max-width: 640px) 400px, 800px"
						height: 200
					)
				}
				logo {
					alt
					gatsbyImageData(width: 99, height: 45)
				}
				sectors {
					id
					name
				}
			}
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedHome = ({ location, data, pageContext }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<ResourcesPage
			location={location}
			data={data}
			locale={pageContext.locale}
		/>
	</DefaultLayout>
);

export default WrappedHome;
