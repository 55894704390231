// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { Link } from 'gatsby';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { renderNodeRule, StructuredText } from 'react-datocms/structured-text';
import { isParagraph, isRoot } from 'datocms-structured-text-utils';
import * as compassStyles from './index.module.css';

const CTACompassCA = ({ ctaContent, className }) => {
	const { ctaTitle, ctaImages, ctaSubText, ctaLinks } = ctaContent;
	const customNodeRules = [
		renderNodeRule(
			isParagraph,
			({ adapter: { renderNode }, node, children, key, ancestors }) => {
				if (node.children[0].type === 'inlineItem') {
					return (
						<React.Fragment key={key}>{children}</React.Fragment>
					);
				}
				if (isRoot(ancestors[0])) {
					return renderNode(
						'p',
						{
							key,
							className: '',
						},
						children
					);
				}
				return <React.Fragment key={key}>{children}</React.Fragment>;
			}
		),
	];
	return (
		<div
			className={`w-screen -ml-5  text-center mx-auto py-12 -mb-5 sm:py-16 bg-[#1F2E7C] font-castledown-regular md:px-3 lg:px-8 lg:mx-0 xl:mx-0 xl:max-w-full xl:px-12 2xl:px-[14.5rem] ${compassStyles.macCtaCompass} ${className}`}
		>
			<div className="flex flex-col gap-5 mx-5 lg:mx-0 sm:gap-10 xl:gap-14">
				<div className="text-3xl leading-[3rem] xl:text-[42px]">
					<StructuredText
						data={ctaTitle}
						customNodeRules={customNodeRules}
					/>
				</div>
				<div className="hidden md:block md:inline-flex md:gap-5 xl:mx-auto 2xl:mx-auto 2xl:max-w-full 2xl:gap-10">
					{ctaImages.map((elm) => (
						<div>
							<img
								src={elm.image.url}
								alt=""
								className="md:rounded-[.75rem] object-fill inner-shadow 2xl:w-[600px] 2xl:h-[100%]"
							/>
						</div>
					))}
				</div>
				<div className="text-lg font-centra-light sm:mx-16 lg:mx-36 xl:text-2xl xl:mx-54 2xl:mx-64">
					<StructuredText
						data={ctaSubText}
						customNodeRules={customNodeRules}
					/>
				</div>
				<div className="flex flex-col gap-3 sm:gap-5 sm:flex-row py-4 mx-auto">
					{ctaLinks.map((elm) => (
						<div>
							<Link
								to={elm.link}
								className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-light focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-light focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 min-w-[200px] justify-center px-6 py-4 align-top text-base-f border-1 relative sm:w-auto sm:mt-0"
							>
								{elm.buttonCopy}
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CTACompassCA;
