import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import { Button } from '../../../../../atoms/button';
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header-short';
import { RadioButton } from '../../../../../atoms/radio-button';
import { TextInput } from '../../../../../atoms/text-input-v2-ca';
import { TimesRange } from '../../../../../molecules/times-range';
import { useFormValidation } from '../../../../../../hooks/form-validation-modal-short-ca';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';
import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

const initialValidations = {
	title: { isValid: true },
	fullName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
};

const AdviceHrSoftware = ({ setReference, setResponse, customFormField }) => {
	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const titleRef = useRef(null);
	const [chooseTime, setChooseTime] = useState(false);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [title, setTitle] = useState('');

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const sideBarHeaderText =
		customFormField?.customFormTitle || 'Schedule a FREE advice call';
	const sideBarSubHeaderText =
		customFormField?.customFormSubtitle ||
		'Need immediate help with an issue? Let us know how to get in touch with you.';
	const submitButtonText =
		customFormField?.customFormButton || sideBarHeaderText;

	let isSelectedTimes = false;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};

	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);

	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		const todaysDate = new Date().toISOString().split('T')[0];

		if (!isValid) return;

		setSubmitting(true);

		// Splitting full name into firstName and lastName
		const fullName = formEntries.fullName.trim();
		const nameParts = fullName.split(/\s+/);

		let firstNameData = '';
		let lastNameData = 'N/A';

		if (nameParts.length === 1) {
			[firstNameData] = nameParts;
		} else {
			lastNameData = nameParts.pop();
			firstNameData = nameParts.join(' ');
		}

		isSelectedTimes = formEntries.callFrom && formEntries.callTo;
		const selectedDate = isSelectedTimes ? formEntries.callDay : todaysDate;
		const selectedTime = isSelectedTimes
			? `${formEntries.callFrom}-${formEntries.callTo}`
			: 'asap';

		const payloadData = {
			jobTitle: title,
			firstName: firstNameData,
			lastName: lastNameData,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			selectedDate,
			selectedTime,
			leadSource: 'Website',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponse(
					`Brilliant, one of our advisors will call you ${
						isSelectedTimes
							? `between the hours of ${
									formEntries.callFrom
							  } and ${formEntries.callTo} ${
									todaysDate === formEntries.callDay
										? 'today'
										: 'tomorrow'
							  }.`
							: 'as soon as possible.'
					}`
				);
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.title?.isValid && titleRef.current) {
			return titleRef.current.focus();
		}
		if (!validations.fullName.isValid && fullNameRef.current) {
			return fullNameRef.current.focus();
		}
		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}
		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<div className="flex flex-col justify-between w-full max-w-sm mx-auto grow">
				<SidebarHeader
					heading="We've run into a technical error with your submission"
					text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
					ariaLive="assertive"
				/>
				<CallUs className="p-4" />
			</div>
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full max-w-sm mx-auto grow"
			>
				<SidebarHeader
					heading={sideBarHeaderText}
					text={sideBarSubHeaderText}
				/>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-3 bg-white grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col w-full mb-3 whitespace-nowrap text-md">
							<div className="mr-3 w-fit-content text-md font-centra-medium border-alert-400">
								I am:
							</div>
							<div className="mt-2 text-md font-centra-regular xl:mt-1">
								<div className="flex flex-row flex-wrap gap-4 xl:justify-start xl:gap-1">
									<div className="flex flex-row">
										<div className="mt-1">
											<input
												onChange={(e) => {
													setTitle(
														e.currentTarget.value
													);
												}}
												type="radio"
												value="an employer"
												name="Title"
												ref={titleRef}
												id="title-radio-group-1"
												className="w-4 h-4 border-2 border-blue-500 rounded-full"
											/>
										</div>
										<div className="xl:whitespace-normal w-[83px] text-sm ml-2 xl:leading-4">
											<label
												htmlFor="title-radio-group-1"
												className=""
											>
												an employer
											</label>
										</div>
									</div>
									<div className="flex flex-row">
										<div className="mt-1">
											<input
												onChange={(e) => {
													setTitle(
														e.currentTarget.value
													);
												}}
												type="radio"
												value="business representative"
												name="Title"
												ref={titleRef}
												id="title-radio-group-3"
												className="w-4 h-4 border-2 border-blue-500 rounded-full"
											/>
										</div>
										<div className="xl:whitespace-normal w-[83px] text-sm ml-2 xl:leading-4">
											<label
												htmlFor="title-radio-group-3"
												className=""
											>
												business representative
											</label>
										</div>
									</div>
									<div className="flex flex-row xl:ml-8">
										<div className="mt-1">
											<input
												onChange={(e) => {
													setTitle(
														e.currentTarget.value
													);
												}}
												type="radio"
												value="an employee"
												name="Title"
												ref={titleRef}
												id="title-radio-group-2"
												className="w-4 h-4 border-2 border-blue-500 rounded-full"
											/>
										</div>
										<div className="xl:whitespace-normal w-[83px] xl:w-[70px] text-sm ml-2 xl:leading-4">
											<label
												htmlFor="title-radio-group-2"
												className=""
											>
												an employee
											</label>
										</div>
									</div>
									{!validations.title?.isValid && (
										<p className="mt-4 text-left text-alert-500 status">
											Please select an option.
										</p>
									)}
								</div>
							</div>
						</div>

						<TextInput
							labelText="Full name"
							id="callbackFullName"
							name="fullName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your full name"
							showValidation={!validations.fullName.isValid}
							ref={fullNameRef}
						/>
						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>
						<TextInput
							labelText="Phone number"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>
						<div className="w-full wrapper-small">
							<fieldset
								className={clsx(chooseTime ? 'mb-4' : null)}
								disabled={submitting}
							>
								<legend className="mb-1 text-brand-blue-400 text-base-f font-centra-medium">
									When shall we call you?
								</legend>

								<p className="mb-4 text-s-f">
									Callbacks can be as quick as within 8
									minutes
								</p>

								<div className="flex flex-wrap gap-2">
									<RadioButton
										defaultChecked
										labelText="As soon as possible"
										id="callAsap"
										value="callAsap"
										name="callWhen"
										onChange={() => setChooseTime(false)}
									/>
									<RadioButton
										labelText="Choose a time"
										id="chooseTime"
										value="chooseTime"
										name="callWhen"
										onChange={() => setChooseTime(true)}
									/>
								</div>
							</fieldset>
							<div
								className="px-4 py-10 bg-brand-pale-300"
								hidden={!chooseTime}
							>
								<TimesRange
									callFromIsValid={
										chooseTime &&
										validations.callFrom &&
										validations.callFrom.isValid
									}
									callToIsValid={
										chooseTime &&
										validations.callTo &&
										validations.callTo.isValid
									}
									isDisabled={!chooseTime}
								/>
							</div>
						</div>
					</fieldset>
				</div>
				<div className="max-w-md p-4 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText || 'Talk to us today'}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
						<div className="mt-4 mb-0 text-xs">
							View our privacy policy{' '}
							<a
								target="_self"
								href="/ca/privacy-policy"
								className="inline-flex items-center text-xs text-center text-blue-400 transition-colors hover:bg-brand-blue-400 focus:bg-brand-blue-500 focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent decoration-1 hover:text-brand-blue-400 focus:outline-focus"
							>
								here
							</a>
							.
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

AdviceHrSoftware.defaultProps = {
	customFormField: '',
};

AdviceHrSoftware.propTypes = {
	setReference: PropTypes.func.isRequired,
	setResponse: PropTypes.func.isRequired,
	customFormField: PropTypes.node,
};

export { AdviceHrSoftware };
