/* eslint-disable react/prop-types */
import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { DefaultLayout } from '../../layouts/default';

const NotFoundPage = ({ data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<main className="max-w-screen-lg p-8 mx-auto">
			<title>Not found</title>
			<h1 className="mb-8 text-3xl">Page not found</h1>
			<p className="mb-8">
				Sorry we couldn’t find what you were looking for.
			</p>
			<p>
				<Link to="/ie" className="mt-8 underline text-brand-blue-300">
					Return to homepage.
				</Link>
			</p>
		</main>
	</DefaultLayout>
);

export default NotFoundPage;

export const query = graphql`
	query fourOhFour {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: "en-IE" }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: "en-IE" }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: "en-IE" }) {
			...footerItems
		}
	}
`;
/* eslint-enable react/prop-types */
