// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Parallax, Keyboard, Mousewheel } from 'swiper';

import { ParallaxSlide } from '../parallax-slide';
import { HeadingTagLine } from '../../atoms/heading-tag-line';

import { titleProp, parallaxSlideProp } from '../../../types';

import { useScreenResizer } from '../../../contexts/screen-resize-context';

import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';

import * as parallaxSlider from './index.module.css';

const ParallaxSlider = ({ parallaxSliderContent, pagination }) => {
	const { title, slides } = parallaxSliderContent;
	const { width } = useScreenResizer();

	const [parallaxOn, setParallaxOn] = useState(true);
	const [direction, setDirection] = useState('');
	const [active, setActive] = useState('');

	const sliderRef = useRef(null);
	const swiperRef = useRef(null);
	const activeRef = useRef(active);

	const id = 'ParallaxSlider';

	const paginationProps = {
		clickable: true,
		el: '.pagination-container',
		horizontalClass: parallaxSlider.paginationHorizontal,
		verticalClass: parallaxSlider.paginationVertical,
		bulletClass: parallaxSlider.paginationBullet,
		bulletActiveClass: parallaxSlider.paginationBulletActive,
	};

	const renderSlides = (slide, index) => (
		<SwiperSlide key={`tick-list-${index}`} tag="li" data-index={index}>
			<ParallaxSlide
				parallaxSlide={slide}
				index={index}
				parallaxOn={parallaxOn}
			/>
		</SwiperSlide>
	);

	const getKeyboardFocusableElements = (element) =>
		[
			...element.querySelectorAll(
				'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
			),
		].filter(
			(el) =>
				!el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
		);

	const handleHeight = (swiper) => {
		const currentSlide = swiper.slides[swiper.activeIndex];
		if (currentSlide) {
			const currentSlideItem = currentSlide.children[0];
			sliderRef.current.style.height = `${currentSlideItem.clientHeight}px`;
		}
	};

	const handleFocusIn = () => {
		setActive(document.activeElement);
	};

	const handleSlideFocus = useCallback(
		(e) => {
			setTimeout(() => {
				const swiperFocusableElements = getKeyboardFocusableElements(
					sliderRef.current
				);

				const parallaxEls = sliderRef.current.querySelectorAll(
					'[data-swiper-parallax]'
				);
				const parallexElsArr = [...parallaxEls];

				const parallaxElsVals = sliderRef.current.querySelectorAll(
					'[data-parallax-val]'
				);
				const parallexElsValsArr = [...parallaxElsVals];

				if (e.key === 'Tab') {
					if (swiperFocusableElements.includes(activeRef.current)) {
						setParallaxOn(false);
						parallexElsValsArr.forEach((el) => {
							el.removeAttribute('style');
							el.removeAttribute('data-swiper-parallax');
						});

						const activeSlide =
							activeRef.current.closest('.swiper-slide');
						const activeSlideIndex = Number(
							activeSlide.getAttribute('data-index')
						);
						if (
							swiperRef.current.activeIndex !== activeSlideIndex
						) {
							sliderRef.current.style.height = `${swiperRef.current.virtualSize}px`;
							swiperRef.current.slideTo(activeSlideIndex, 0);
						}
					} else if (parallexElsArr.length === 0) {
						setParallaxOn(true);
						parallexElsValsArr.forEach((el) => {
							const value = el.getAttribute('data-parallax-val');
							el.setAttribute('data-swiper-parallax', value);
						});
						swiperRef.current.update();
					}
				}
			}, 100);
		},
		[activeRef]
	);

	const handleResize = (swiper) => {
		handleHeight(swiper);
		swiper.changeDirection(direction);
	};

	useEffect(() => {
		if (width >= 768) {
			setDirection('vertical');
		} else {
			setDirection('horizontal');
		}
	}, [width]);

	useEffect(() => {
		setActive(document.activeElement);
		document.addEventListener('focusin', handleFocusIn);
		return () => {
			document.removeEventListener('focusin', handleFocusIn);
		};
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', handleSlideFocus);
		return () => {
			document.removeEventListener('keydown', handleSlideFocus);
		};
	}, [handleSlideFocus]);

	useEffect(() => {
		activeRef.current = active;
	}, [active]);

	return (
		<section
			className="mx-auto lg:container pt-xl-f pb-base-f xl:py-2xl-f px-xs-f"
			aria-labelledby={id}
		>
			<HeadingTagLine className="mb-4 md:mb-xl-f" id={id} title={title} />

			<div className={parallaxSlider.swiperContainer}>
				<Swiper
					ref={sliderRef}
					className="md:!pl-lg-f lg:!pl-xl-f"
					modules={[Pagination, Parallax, Keyboard, Mousewheel]}
					speed={600}
					wrapperTag="ul"
					pagination={pagination ? paginationProps : false}
					preloadImages
					updateOnImagesReady
					mousewheel={{
						releaseOnEdges: true,
						thresholdTime: 2,
					}}
					keyboard
					slidesPerView={1}
					parallax={parallaxOn}
					onImagesReady={handleHeight}
					onSlideChange={handleHeight}
					onResize={handleResize}
					direction={direction}
					onSwiper={(swiper) => {
						swiperRef.current = swiper;
					}}
				>
					{slides.map(renderSlides)}
				</Swiper>

				<div className="pagination-container" />
			</div>
		</section>
	);
};

ParallaxSlider.defaultProps = {
	parallaxSliderContent: {
		as: 'h3',
		secondaryLinkCopy: '',
		secondaryLink: '',
		tickList: [],
	},
	pagination: true,
};

ParallaxSlider.propTypes = {
	parallaxSliderContent: PropTypes.shape({
		title: titleProp.isRequired,
		slides: PropTypes.arrayOf(parallaxSlideProp.isRequired).isRequired,
	}),
	pagination: PropTypes.bool,
};

export { ParallaxSlider };
