import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms/button';
import { useModal } from '../../../contexts/modal-context';
import { sendTrackingData } from '../../../utils';
import { refProp } from '../../../types';
import { CheckIcon } from './check';
import { XIcon } from './xicon';

const PricingTable = ({
	content,
	modalRef,
	setFormTitle,
	index,
	quoteButtonCopy,
	employeeCount,
	table,
}) => {
	const buttonText = quoteButtonCopy;
	const { title } = content;

	const { setModalRef } = useModal();

	const handleClickTracking = (clickType) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			component_name: 'pricing-card',
			click_type: clickType,
			click_action: 'card click',
			click_state: `${employeeCount}_no_employees`,
			click_title: title,
			click_text: buttonText,
			click_index: index + 3,
		});
	};

	const handleClick = (formTitle) => {
		setModalRef(modalRef);
		setFormTitle(formTitle);
	};

	// Create a JSX table to display the pricing plan details using the data from 'table' prop. Use CheckIcon and XIcon components to render checkmarks and crosses. Apply tableOrders className to control column/row ordering.
	const tableOrders = {
		1: 'md:order-1',
		2: 'md:order-2',
		3: 'md:order-3',
		4: 'md:order-4',
		5: 'md:order-5',
		6: 'md:order-6',
		7: 'md:order-7',
		8: 'md:order-8',
		9: 'md:order-9',
		10: 'md:order-10',
		11: 'md:order-11',
		12: 'md:order-12',
	};

	return (
		<section className="relative flex overflow-hidden shadow-[10px_5px_10px_0px_rgba(0,0,15,0.08)]">
			<div className="w-full max-w-8xl mx-auto px-4 md:px-6">
				{/* Feature Comparison Pricing Table component */}
				<div className="bg-white max-w-sm mx-auto md:max-w-none grid md:grid-cols-[28%_24%_24%_24%] md:-mx-6 text-sm">
					{/* Column with labels */}
					<div className="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl">
						<div className="relative bg-white px-4 lg:px-6 flex flex-col mb-4">
							<div className="grow">
								<div className="mb-8">
									<span className="text-lg font-semibold text-slate-900">
										Services
									</span>
								</div>
							</div>
						</div>
						{table.map((item, idx) => (
							<div
								key={item.id}
								className={`bg-[#F8F9FF] mb-3 px-6 flex max-md:hidden border-r-2 border-blue-200 ${
									tableOrders[idx + 3]
								}`}
								aria-hidden="true"
							>
								<div className="py-4">{item.title}</div>
							</div>
						))}
						<div
							className="bg-white px-6 flex max-md:hidden border-r-2 border-blue-200 md:order-10"
							aria-hidden="true"
						/>
					</div>
					{/* End: Column with labels */}

					{/* Hr Services Only */}
					<section className="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl flex flex-col ">
						<div className="relative bg-[#9CAAF0] px-4 flex flex-col lg:px-6 mb-4">
							<div className="grow mx-auto">
								<div className="mb-8">
									<span className="text-lg font-medium text-white">
										{content[0].title}
									</span>
								</div>
							</div>
						</div>
						{table.map((item, idx) => (
							<div
								key={item.id}
								className={`bg-[#F8F9FF] mb-3 px-4 lg:px-6 flex md:border-r-2 border-r-0 border-blue-200 ${
									tableOrders[idx + 3]
								}`}
							>
								<div className="flex items-center h-full py-2 md:mx-auto">
									{item.humanResources ? (
										<CheckIcon />
									) : (
										<XIcon />
									)}
									<span className="ml-4">
										<span className="md:sr-only">
											{item.title}
										</span>
									</span>
								</div>
							</div>
						))}
						<div
							className={`bg-white px-4 lg:px-6 flex md:border-r-2 border-r-0 border-blue-200 ${
								tableOrders[table.length + 3]
							}`}
						>
							<div className="flex items-center h-full mt-6 mx-auto">
								<Button
									onClick={() => {
										handleClick(content[0].title);
										handleClickTracking();
									}}
								>
									Get started
								</Button>
							</div>
						</div>
					</section>
					{/* End: HR Services Only table */}

					{/* Health and Safety Only */}
					<section className="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl">
						<div className="relative bg-[#1F2E7A] px-4 lg:px-6 flex flex-col mb-4">
							<div className="grow mx-auto">
								<div className="mb-8">
									<span className="text-lg font-medium text-white">
										{content[1].title}
									</span>
								</div>
							</div>
						</div>
						{table.map((item, idx) => (
							<div
								key={item.id}
								className={`bg-[#F8F9FF] mb-3 px-4 lg:px-6 flex md:border-r-2 border-r-0 border-blue-200 ${
									tableOrders[idx + 3]
								}`}
							>
								<div className="flex items-center h-full py-2 md:mx-auto">
									{item.healthAndSafety ? (
										<CheckIcon />
									) : (
										<XIcon />
									)}
									<span className="ml-4">
										<span className="md:sr-only">
											{item.title}
										</span>
									</span>
								</div>
							</div>
						))}
						<div
							className={`bg-white px-4 lg:px-6 flex md:border-r-2 border-r-0 border-blue-200 ${
								tableOrders[table.length + 3]
							}`}
						>
							<div className="flex items-center h-full mt-6 mx-auto">
								<Button
									title="Health & Safety Only"
									onClick={() => {
										handleClick(content[1].title);
										handleClickTracking();
									}}
								>
									Get started
								</Button>
							</div>
						</div>
					</section>
					{/* End: Health and Safety Only */}

					{/* Full Compliance Protection */}
					<section className="md:contents [&>div:first-child]:pt-10 [&>div:first-child]:rounded-t-2xl [&>div:last-child]:pb-10 [&>div:last-child]:rounded-b-2xl">
						<div className="relative bg-[#E30138] px-4 flex flex-col mb-4">
							<div className="grow mx-auto">
								<div className="mb-8">
									<span className="text-lg font-medium text-white">
										{content[2].title}
									</span>
								</div>
							</div>
						</div>
						{table.map((item, idx) => (
							<div
								key={item.id}
								className={`bg-[#F8F9FF] mb-3 px-4 lg:px-6 flex ${
									tableOrders[idx + 3]
								}`}
							>
								<div className="flex items-center h-full py-2 md:mx-auto">
									{item.fullCompliance ? (
										<CheckIcon />
									) : (
										<XIcon />
									)}
									<span className="ml-4">
										<span className="md:sr-only">
											{item.title}
										</span>
									</span>
								</div>
							</div>
						))}
						<div
							className={`bg-white px-4 lg:px-6 flex border-blue-200 ${
								tableOrders[table.length + 3]
							}`}
						>
							<div className="flex items-center h-full mt-6 mx-auto">
								<Button
									onClick={() => {
										handleClick(content[2].title);
										handleClickTracking();
									}}
								>
									Get started
								</Button>
							</div>
						</div>
					</section>
					{/* End: Full Compliance Protection */}
				</div>
			</div>
		</section>
	);
};

PricingTable.propTypes = {
	content: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
		})
	).isRequired,
	modalRef: refProp.isRequired,
	setFormTitle: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	employeeCount: PropTypes.number.isRequired,
	quoteButtonCopy: PropTypes.string.isRequired,
	table: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			healthAndSafety: PropTypes.bool,
			humanResources: PropTypes.bool,
			fullCompliance: PropTypes.bool,
		})
	).isRequired,
};

export { PricingTable };
