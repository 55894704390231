import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useLocation } from '@reach/router';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';
import { CallUs } from '../../atoms/call-us';

import { titleProp } from '../../../types';

import { sendTrackingData } from '../../../utils';
import { getLocale, buildLink } from '../../../utils/locale';

import { Modal } from '../../atoms/modal-v2-ca';
import { AdviceModal } from '../ca/forms/salesforce/advice-modal';
import { SuccessModal } from '../ca/forms/salesforce/success-modal';
import { useModal } from '../../../contexts/modal-context';

const AdviceBannerPricingCA = ({ adviceBannerContent, variant, className }) => {
	const { title, content, ctaLabel, ctaLink, ctaOpenForm } =
		adviceBannerContent;
	const location = useLocation();
	const locale = getLocale(location.pathname);

	const { modalRef, setModalRef } = useModal();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');

	const customFormField = {
		customFormTitle: 'Book my free advice call',
		customFormSubtitle:
			'Tell us your details and one of our professionals will get back to you.',
		customFormButton: 'Speak to an expert',
	};

	const trackingTitle = (title || [{ children: [] }])[0].children
		.map(({ value }) => value)
		.join('');

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-banner',
			click_type: 'primary',
			...trackData,
		});
	};

	let cta;
	if (ctaLink) {
		cta = <PBSLink to={buildLink(locale, ctaLink)}>{ctaLabel}</PBSLink>;
	} else if (ctaOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setModalRef(adviceFormRef);
					handleClickTracking({
						click_title: trackingTitle,
						click_text: ctaLabel,
					});
				}}
			>
				{ctaLabel}
			</Button>
		);
	}

	return (
		<section
			aria-labelledby="adviceBanner"
			className={clsx(
				'bg-brand-pale-400',
				variant === 'Rte'
					? 'border-blue-200 border-2 rounded-sm my-14'
					: 'border-b-4 md:border-b-6 border-b-blue-400',
				className
			)}
		>
			<Modal
				label="Book my free advice call"
				ref={adviceFormRef}
				open={modalRef === adviceFormRef}
				hasClose
				className="!p-0 z-50"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceModal
						setReference={setReference}
						customFormField={customFormField}
					/>
				) : (
					<SuccessModal className="my-[15%]" />
				)}
			</Modal>
			<div
				className={clsx(
					'max-w-[850px] mx-auto',
					variant === 'Rte' ? 'p-8' : 'py-xl-f px-xs-f'
				)}
			>
				<HeadingTagLine
					id="adviceBanner"
					title={title}
					subText={content}
					headingClassNames={
						variant === 'Rte' ? '!text-2xl md:!text-3xl' : null
					}
					subTextClassNames={
						variant === 'Rte'
							? '!text-lg md:!text-xl !font-centra-book'
							: null
					}
				/>
				<div className="flex flex-col items-center justify-center md:flex-row mt-7">
					{cta}
					<hr
						aria-hidden="true"
						className="my-2 md:h-full md:min-h-[60px] md:mx-7 md:border-l-1 md:border-l-blue-300"
					/>
					<CallUs
						altMobile
						onClickProp={(trackData) =>
							handleClickTracking({
								click_type: 'tel',
								...trackData,
							})
						}
					/>
				</div>
			</div>
		</section>
	);
};

export { AdviceBannerPricingCA };

AdviceBannerPricingCA.defaultProps = {
	variant: '',
	className: '',
};

AdviceBannerPricingCA.propTypes = {
	adviceBannerContent: PropTypes.shape({
		title: titleProp.isRequired,
		content: PropTypes.string.isRequired,
		ctaLabel: PropTypes.string.isRequired,
		ctaLink: PropTypes.string,
		ctaOpenForm: PropTypes.bool,
	}).isRequired,
	variant: PropTypes.string,
	className: PropTypes.string,
};
