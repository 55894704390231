import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createHeadingHighlight } from '../../../utils/heading-highlight';
import * as headingStyles from '../safecheck-text-image-container/index.module.css';
import { refProp, titleProp } from '../../../types';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { useDrawer } from '../../../contexts/drawer-context';
import ModalWrapper from '../../glu/molecules/modal-wrapper';
import VideoPlayer from '../../glu/molecules/video-player';
import useModal from '../../../hooks/modal';
import VideoPlaceholder from '../../../assets/safecheck-placeholder.png';
import { sendTrackingData } from '../../../utils';

function TwoTextVideoColumn({
	id,
	cardTitle,
	content,
	ctaOpenForm,
	ctaText,
	videoLink,
	thumbnail,
	as,
	headingClassNames,
	drawerRef,
	locale,
}) {
	const { setDrawerRef } = useDrawer();
	const Component = as || 'h1';
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const titleText = createHeadingHighlight({
		headings: cardTitle[0].children,
		headingStyles,
	});
	const handleClickTracking = (text = null) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = cardTitle[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: text || ctaText,
		});
	};
	return (
		<section
			className="max-w-m-screen mx-auto lg:container bg-brand-pale-400 h-auto border-2 border-brand-pale-500 rounded-sm p-3  mt-5 md:mt-10"
			aria-labelledby={id}
		>
			<div className="grid md:grid-cols-2  grid-cols-1">
				<div className="flex px-3 md:px-5 xl:px-10 xl:py-7 mt-7 md:mt-12 xl:mt-20 flex-col gap-4 md:gap-5">
					<div
						className={clsx(
							'flex flex-col shrink mb-5',
							headingStyles.headingContainer
						)}
					>
						<Component
							id={id}
							className={clsx(
								'!leading-[1.3] font-castledown-regular xl:!ml-[-16px]',
								'text-3xl md:text-3xl xl:text-4xl',
								headingClassNames
							)}
						>
							{titleText}
						</Component>
					</div>
					<p className="mb-0 text-lg font-centra-light  md:text-xl last-of-type:mb-0">
						{content}
					</p>
					{ctaOpenForm ? (
						<Button
							className="w-max"
							variant="White"
							onClick={() => {
								setDrawerRef(drawerRef);
								handleClickTracking(ctaText);
							}}
						>
							{ctaText}
						</Button>
					) : (
						<PBSLink
							className="justify-center w-full text-blue-400 bg-white sm:w-max hover:text-white"
							to={videoLink}
							variant="White"
							onClick={handleClickTracking}
						>
							{ctaText}
						</PBSLink>
					)}
				</div>
				<div
					className={clsx('flex items-center justify-center h-auto')}
				>
					<div className="w-full flex items-center justify-center py-5 md:py-10">
						<button
							type="button"
							onClick={() => setModalOpen(true)}
							tabIndex={0}
							className="relative"
						>
							<img
								src={thumbnail.url || VideoPlaceholder}
								className="shadow-2xl w-full h-auto"
								alt="Video placeholder"
							/>
						</button>
					</div>
					{isModalOpen && (
						<ModalWrapper>
							<div
								className="relative w-7/12 m-auto opacity-100"
								ref={ref}
							>
								{videoLink.providerUid && (
									<VideoPlayer
										videoEmbed={videoLink}
										autoPlay
										locale={locale}
									/>
								)}
							</div>
						</ModalWrapper>
					)}
				</div>
			</div>
		</section>
	);
}

TwoTextVideoColumn.defaultProps = {
	as: 'h1',
	headingClassNames: '',
};

TwoTextVideoColumn.propTypes = {
	id: PropTypes.string.isRequired,
	cardTitle: titleProp.isRequired,
	content: PropTypes.string.isRequired,
	thumbnail: PropTypes.string.isRequired,
	ctaOpenForm: PropTypes.bool.isRequired,
	ctaText: PropTypes.string.isRequired,
	videoLink: PropTypes.string.isRequired,
	as: PropTypes.string,
	headingClassNames: PropTypes.string,
	drawerRef: refProp.isRequired,
	locale: PropTypes.string.isRequired,
};

export { TwoTextVideoColumn };
