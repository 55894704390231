import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { PBSLink } from '../link';

import { datoGatsbyImageProp, titleProp } from '../../../types';
import { getLocale, buildLink } from '../../../utils/locale';
import { TickList } from '../tick-list';

const TextWithImage = ({ textWithImageContent, flip }) => {
	const {
		id,
		title,
		tickList,
		as,
		titleProps,
		image,
		buttonLink,
		buttonCopy,
		className,
	} = textWithImageContent;
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);
	const Heading = as || 'h2';
	const Alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(image);

	return (
		<div
			className={clsx(
				'lg:grid lg:grid-cols-[1fr_46%] lg:gap-[60px]',
				className
			)}
		>
			<div className={clsx(flip ? 'order-2' : null)}>
				{title || titleProps ? (
					<Heading
						id={id}
						className="mb-5 text-3xl lg:text-4xl font-castledown-regular"
						{...titleProps}
					>
						{title}
					</Heading>
				) : null}

				{tickList.length > 0 ? (
					<TickList
						className="mt-4 lg:mt-8"
						listItemClassName="text-lg lg:text-xl"
						points={tickList}
						data-parallax-val="-100"
						data-swiper-parallax="-100"
					/>
				) : null}
				{buttonLink && buttonCopy ? (
					<p className="mt-8 text-2xl leading-9 text-center lg:mt-10 lg:mb-0 lg:text-left">
						<PBSLink to={buildLink(locale, buttonLink)}>
							{buttonCopy}
						</PBSLink>
					</p>
				) : null}
			</div>
			<div
				className={clsx(
					'text-center mt-8 lg:mt-0 lg:text-text',
					flip ? 'hidden lg:block order-1' : null
				)}
			>
				<GatsbyImage
					className={clsx(
						'border-8',
						'border-white',
						'drop-shadow-image',
						'self-start',
						'justify-self-center',
						'md:justify-self-start'
					)}
					image={imageData}
					alt={Alt}
				/>
			</div>
		</div>
	);
};

TextWithImage.defaultProps = {
	textWithImageContent: {
		as: 'h2',
		titleProps: {},
		id: '',
	},
	flip: false,
};

TextWithImage.propTypes = {
	textWithImageContent: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		as: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.string,
			PropTypes.func,
			titleProp,
		]),
		titleProps: PropTypes.shape({
			id: PropTypes.string,
			title: titleProp,
			headingClassNames: PropTypes.string,
		}),
		tickList: PropTypes.string,
		image: datoGatsbyImageProp.isRequired,
		buttonLink: PropTypes.string,
		buttonCopy: PropTypes.string,
		className: PropTypes.string,
	}),
	flip: PropTypes.bool,
};

export { TextWithImage };
