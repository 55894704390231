import React from 'react';
import PropTypes from 'prop-types';
import { TextWithImage } from '../../atoms/text-with-image-bdmbio';

const BdmBio = ({ bdmBio }) => {
	const { id } = bdmBio;
	let textWithImages = [bdmBio];

	if (!Object.prototype.hasOwnProperty.call(bdmBio, 'content')) {
		const { bioTitle, bioContent, bdmImage } = bdmBio;

		const elementOne = {
			titleProps: {
				bioTitle,
				headingClassNames:
					'text-left text-3xl md:!text-4xl md:!leading-normal lg:max-w-[470px]',
			},
			bioTitle,
			content: bioContent,
			image: bdmImage,
			className: 'items-center mb-8 lg:mb-2xl-f bg-red-200',
		};

		textWithImages = [elementOne];
	}

	return (
		<section className="">
			<div
				className="px-4 mx-auto md:px-8 max-w-m-screen "
				aria-labelledby={id}
			>
				{textWithImages.map((elContent) => (
					<TextWithImage textWithImageContent={elContent} />
				))}
			</div>
		</section>
	);
};

BdmBio.defaultProps = {
	bdmBio: '',
};

BdmBio.propTypes = {
	bdmBio: PropTypes.node,
};

export { BdmBio };
