/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default/ppc-v2';
import { Head } from '../components/templates/head';
// for modal
import { AdviceShortFormCA } from '../components/organisms/ca/forms/salesforce/advice-short';
import { SuccessModal } from '../components/organisms/ca/forms/salesforce/success-modal';
// for drawer
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';

import { HeroVideoCampaignCA } from '../components/molecules/hero-video-campaign-ca';
import { HeroMediaVideoCampaignCA } from '../components/molecules/hero-media-video-campaign-ca';
import { pageTypes } from '../utils/url-helpers';
import { Modal } from '../components/atoms/modal-v2-ca';
import { Drawer } from '../components/atoms/drawer';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { useModal } from '../contexts/modal-context';

import { HrefLang } from '../components/templates/hrefLang';
import { CallUs } from '../components/atoms/call-us-child-service-ca';

import CardsContentSectionVideoCampaignCA from '../components/organisms/cards-content-section-video-campaign-ca';
import CardsWithImageVideoCampaignCA from '../components/organisms/cards-with-image-video-campaign-ca';
import VAPSectionVideoCampaignCA from '../components/organisms/vap-section-video-campaign-ca';
import TestimonialsVideoCampaignCA from '../components/organisms/testimonials-video-campaign-ca';
import BottomContentVideoCampaignCA from '../components/organisms/bottom-content-video-campaign-ca';
import { useDrawer } from '../contexts/drawer-context';
import WhyUsVideoCampaignCA from '../components/organisms/why-us-video-campaign-ca';

const VideoCampaignCA = ({ data, location }) => {
	const { modalRef } = useModal();
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');

	const metaContent = {
		noIndex: data.datoCmsVideoCampaignPageCa.noIndex,
		noFollow: data.datoCmsVideoCampaignPageCa.noFollow,
		metaInformation: data.datoCmsVideoCampaignPageCa.metaInformation,
		fallbackTitle: data.datoCmsVideoCampaignPageCa.title,
		fallbackDescription: data.datoCmsVideoCampaignPageCa.longIntroduction,
		canonicalUrl:
			data.datoCmsVideoCampaignPageCa?.canonicalUrl || location.href,
	};

	const headerCTAContent = {
		...data.datoCmsVideoCampaignPageCa.mainHeader,
		header: data.datoCmsVideoCampaignPageCa.mainHeader.primaryHeaderText
			.value.document.children,
		googleReview: data.siteSettings.googleReview,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsVideoCampaignPageCa,
		}),
		image: getImageSeoSchema(
			data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryImage
		),
	};

	const useModalForm =
		data.datoCmsVideoCampaignPageCa?.mainHeader?.useModalForm;

	const heroVideoCampaignCAContent = {
		primaryHeaderText:
			data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryHeaderText,
		secondaryHeaderText:
			data.datoCmsVideoCampaignPageCa?.mainHeader?.secondaryHeaderText,
		primaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryLinkCopy,
		primaryLink: data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryLink,
		primaryLinkOpenForm:
			data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryLinkOpenForm,
		secondaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.mainHeader?.secondaryLinkCopy,
		secondaryLink:
			data.datoCmsVideoCampaignPageCa?.mainHeader?.secondaryLink,
		primaryImage: data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryImage,
		primaryVideo: data.datoCmsVideoCampaignPageCa?.mainHeader?.primaryVideo,
		clientLogosBannerDisplayOnPage:
			data.datoCmsVideoCampaignPageCa.clientLogosBannerDisplayOnPage,
	};

	const cardsContentSectionCAContent = {
		title: data.datoCmsVideoCampaignPageCa?.cardsContainer?.title,
		subText: data.datoCmsVideoCampaignPageCa?.cardsContainer?.subText,
		content: data.datoCmsVideoCampaignPageCa?.cardsContainer?.content,
		primaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.cardsContainer?.primaryLinkCopy,
		videoCampaignCards:
			data.datoCmsVideoCampaignPageCa?.cardsContainer?.videoCampaignCards,
	};

	const whyUsSectionContent = {
		title: data.datoCmsVideoCampaignPageCa?.whyUsTitle,
		subText: data.datoCmsVideoCampaignPageCa?.whyUsSubText,
		content: data.datoCmsVideoCampaignPageCa?.whyUsContent,
		primaryLinkCopy: data.datoCmsVideoCampaignPageCa?.whyUsPrimaryLinkCopy,
	};

	const cardsWithImageVideoCampaignCAContent = {
		title: data.datoCmsVideoCampaignPageCa?.textWithImageContainer[0]
			?.title,
		textWithImageSections:
			data.datoCmsVideoCampaignPageCa?.textWithImageContainer[0]
				?.textWithImageSections,
	};

	const vAPSectionVideoCampaignCAContent = {
		sectionTitle: data.datoCmsVideoCampaignPageCa?.vapSectionTitle,
		subText: data.datoCmsVideoCampaignPageCa?.vapSectionSubText,
		vapSectionPrimaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.vapSectionPrimaryLinkCopy,
		bannerImage: data.datoCmsVideoCampaignPageCa?.vapBannerImage,
		bannerTitle: data.datoCmsVideoCampaignPageCa?.vapBannerTitle,
		items: data.datoCmsVideoCampaignPageCa?.vapBannerItems,
		primaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.vapBannerPrimaryLinkCopy,
	};

	const testimonialsVideoCampaignCA = {
		title: data.datoCmsVideoCampaignPageCa?.testimonials?.title,
		subText: data.datoCmsVideoCampaignPageCa?.testimonials?.subText,
		primaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.testimonials?.primaryLinkCopy,
		testimonialCards:
			data.datoCmsVideoCampaignPageCa?.testimonials?.testimonialCards,
	};

	const bottomContentVideoCampaignCAContent = {
		bottomSectionPrimaryLinkCopy:
			data.datoCmsVideoCampaignPageCa?.bottomSectionPrimaryLinkCopy,
		bottomSectionTitle: data.datoCmsVideoCampaignPageCa?.bottomSectionTitle,
		bottonSectionImage: data.datoCmsVideoCampaignPageCa?.bottonSectionImage,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsVideoCampaignPageCa?.customFormField[0]
				?.customFormTitle,
		customFormSubtitle:
			data.datoCmsVideoCampaignPageCa?.customFormField[0]
				?.customFormSubtitle,
		customFormButton:
			data.datoCmsVideoCampaignPageCa?.customFormField[0]
				?.customFormButton,
	};

	const mediaType = String(
		data.datoCmsVideoCampaignPageCa?.mainHeader?.mediaType
	);

	const useHeroImage = mediaType === 'primary_image';
	const hasHeroImage =
		data.datoCmsVideoCampaignPageC?.mainHeader?.primaryImage?.url !== null;
	const useHeroVideo = mediaType === 'primary_video';
	const hasHeroVideo =
		data.datoCmsVideoCampaignPageC?.mainHeader?.primaryVideo?.url !== null;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<div className="block sm:hidden">
				<CallUs />
			</div>
			<HrefLang locales={data.datoCmsVideoCampaignPageCa.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />

			{data.datoCmsVideoCampaignPageCa.mainHeaderDisplayOnPage &&
				(useHeroImage && hasHeroImage ? (
					<HeroVideoCampaignCA
						{...headerCTAContent}
						heroVideoCampaignCAContent={heroVideoCampaignCAContent}
						location={location}
						useModalForm={useModalForm}
						modalRef={adviceFormRef}
						drawerRef={adviceFormRef}
					/>
				) : useHeroVideo && hasHeroVideo ? (
					<HeroMediaVideoCampaignCA
						{...headerCTAContent}
						heroVideoCampaignCAContent={heroVideoCampaignCAContent}
						location={location}
						useModalForm={useModalForm}
						modalRef={adviceFormRef}
						drawerRef={adviceFormRef}
					/>
				) : null)}

			{data.datoCmsVideoCampaignPageCa.cardsContainerDisplayOnPage && (
				<CardsContentSectionVideoCampaignCA
					cardsContentSectionCAContent={cardsContentSectionCAContent}
				/>
			)}

			{data.datoCmsVideoCampaignPageCa.whyUsDisplayOnPage && (
				<WhyUsVideoCampaignCA
					whyUsSectionContent={whyUsSectionContent}
					modalRef={adviceFormRef}
					className=""
				/>
			)}

			{/* For mobile devices */}
			<section
				id="adviceform"
				className="md:hidden lg:hidden xl:hidden border-blue-300 border-2 focus-within:outline-blue-400/[0.20] rounded-sm p-1 mx-4 my-10 scroll-mt-[79px] md:scroll-mt-[160px] scroll-mt-[140px]"
			>
				{reference === '' ? (
					<AdviceShortFormCA
						setReference={setReference}
						customFormField={customFormField}
						isMobile
					/>
				) : (
					<SuccessModal />
				)}
			</section>

			{data.datoCmsVideoCampaignPageCa
				.textWithImageContainerDisplayOnPage && (
				<CardsWithImageVideoCampaignCA
					cardsWithImageVideoCampaignCAContent={
						cardsWithImageVideoCampaignCAContent
					}
					modalRef={adviceFormRef}
				/>
			)}

			{data.datoCmsVideoCampaignPageCa.vapBannerDisplayOnPage && (
				<VAPSectionVideoCampaignCA
					vAPSectionVideoCampaignCAContent={
						vAPSectionVideoCampaignCAContent
					}
					modalRef={adviceFormRef}
				/>
			)}

			{data.datoCmsVideoCampaignPageCa.testimonialsDisplayOnPage && (
				<TestimonialsVideoCampaignCA
					testimonialsVideoCampaignCA={testimonialsVideoCampaignCA}
					modalRef={adviceFormRef}
				/>
			)}

			{data.datoCmsVideoCampaignPageCa.bottomSectionDisplayOnPage && (
				<BottomContentVideoCampaignCA
					bottomContentVideoCampaignCAContent={
						bottomContentVideoCampaignCAContent
					}
					modalRef={adviceFormRef}
				/>
			)}

			<Modal
				label="Book my free advice call"
				ref={adviceFormRef}
				open={modalRef === adviceFormRef}
				hasClose
				className="!p-0 z-50"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceShortFormCA
						setReference={setReference}
						customFormField={customFormField}
					/>
				) : (
					<SuccessModal className="my-[15%]" />
				)}
			</Modal>

			{!useModalForm && (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0 z-50"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							customFormField={customFormField}
						/>
					) : (
						<SuccessFormCA className="my-[15%]" />
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				alt
				url
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsVideoCampaignPageCa(
			slug: { eq: $slug }
			locale: { eq: $locale }
		) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			mainHeader {
				primaryHeaderText {
					value
				}
				secondaryHeaderText
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				useModalForm
				secondaryLink
				secondaryLinkCopy
				mediaType
				primaryImage {
					url
				}
				primaryVideo {
					url
				}
			}
			mainHeaderDisplayOnPage
			clientLogosBannerDisplayOnPage
			cardsContainer {
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				videoCampaignCards {
					structuredTextBlock {
						value
					}
				}
			}
			cardsContainerDisplayOnPage
			whyUsTitle {
				value
			}
			whyUsSubText {
				value
			}
			whyUsContent {
				title {
					value
				}
				subText {
					value
				}
				list {
					text
				}
				primaryImage {
					url
				}
			}
			whyUsPrimaryLinkCopy
			whyUsDisplayOnPage
			textWithImageContainer {
				title {
					value
				}
				textWithImageSections {
					title {
						value
					}
					primaryLinkCopy
					backgroundImage {
						url
					}
					textPerImageBlock {
						text
						image {
							url
						}
					}
				}
			}
			textWithImageContainerDisplayOnPage
			vapSectionTitle {
				value
			}
			vapSectionSubText
			vapSectionPrimaryLinkCopy
			vapBannerImage {
				url
			}
			vapBannerTitle
			vapBannerPrimaryLinkCopy
			vapBannerItems {
				structuredTextBlock {
					value
				}
			}
			vapBannerDisplayOnPage
			testimonials {
				title {
					value
				}
				subText
				testimonialCards {
					content
					name
					job
					profile {
						url
					}
				}
				primaryLinkCopy
			}
			testimonialsDisplayOnPage
			bottomSectionPrimaryLinkCopy
			bottomSectionTitle
			bottonSectionImage {
				url
			}
			bottomSectionDisplayOnPage
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
		}
		datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			logo {
				url
			}
			footerImageCa {
				url
				alt
			}
			footerImage1 {
				url
				alt
			}
			footerImage2 {
				url
				alt
			}
			footerImage3 {
				url
				alt
			}
			footerImage4 {
				url
				alt
			}
			footerImage5 {
				url
				alt
			}
		}
	}
`;

const WrappedVideoCampaignCA = ({ data, location }) => {
	const footerImages = {
		footerImageCA: data.datoCmsSiteSpecificSetting.footerImageCa,
		footerImage1: data.datoCmsSiteSpecificSetting.footerImage1,
		footerImage2: data.datoCmsSiteSpecificSetting.footerImage2,
		footerImage3: data.datoCmsSiteSpecificSetting.footerImage3,
		footerImage4: data.datoCmsSiteSpecificSetting.footerImage4,
		footerImage5: data.datoCmsSiteSpecificSetting.footerImage5,
	};
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			footerImages={footerImages}
		>
			<VideoCampaignCA data={data} location={location} />
		</DefaultLayout>
	);
};

export default WrappedVideoCampaignCA;
