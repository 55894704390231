// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import FeedbackGlu from '../../atoms/Feedbackgluvideos/feedbackglu';

const VideoPlayer = ({
	videoEmbed,
	autoPlay = false,
	setVideoFinished,
	scriptReady = false,
	videosWatchedList,
	privateVideoHash,
	locale,
	setIsGlu,
	setWarning,
	setVideoWatched,
}) => {
	const ref = useRef();
	const [showFeedbackBubble, setShowFeedbackBubble] = useState(false);
	const [response, setResponse] = useState('');
	const [showCommentForm, setShowCommentForm] = useState(false);
	const storageKey = `video-progress-${videoEmbed?.providerUid}`; // Key for localStorage

	useEffect(() => {
		if (
			scriptReady &&
			videoEmbed.provider === 'vimeo' &&
			setVideoFinished &&
			ref.current &&
			!videosWatchedList.includes(videoEmbed?.providerUid)
		) {
			// eslint-disable-next-line no-undef
			const player = new Vimeo.Player(ref.current);
			const videoList = [...videosWatchedList, videoEmbed.providerUid];

			if (setIsGlu) {
				// Retrieve the saved video time from localStorage on component mount
				const savedTime = localStorage.getItem(storageKey);
				player.ready().then(() => {
					if (savedTime) {
						player.setCurrentTime(parseFloat(savedTime));
					}
				});

				player.on('timeupdate', ({ duration, seconds }) => {
					if (duration - seconds > 0) {
						setVideoFinished(true);
						// setWarning(true);
					} else {
						setVideoFinished(false);
					}
					if (duration - seconds <= 30) {
						setVideoWatched(true);
						setShowFeedbackBubble(true);
						setWarning(false);
					} else {
						setVideoWatched(false);
						setShowFeedbackBubble(false);
					}

					// Save current playback time to localStorage
					localStorage.setItem(storageKey, seconds); // <-- Updated to store progress

					Cookies.set('videoPlayed', videoList);
				});
				let timeWatched = savedTime;
				player.on('timeupdate', (data) => {
					if (
						data.seconds - 1 < timeWatched &&
						data.seconds > timeWatched
					) {
						timeWatched = data.seconds;
					}
				});
				player.on('seeked', (data) => {
					if (timeWatched < data.seconds) {
						// if (
						// 	timeWatched < data.seconds &&
						// 	data.duration - data.seconds <= 30
						// ) {
						setVideoWatched(false);
						player.setCurrentTime(timeWatched);
					}
				});

				// Clear the saved time from localStorage when the video ends
				player.on('ended', () => {
					setVideoFinished(true);
					setShowFeedbackBubble(true);
					// setModalOpen(true);
					Cookies.set('videoPlayed', videoList);
					localStorage.removeItem(storageKey); // <-- Clear progress on video end
				});
			} else {
				player.on('ended', () => {
					setVideoFinished(true);
					setShowFeedbackBubble(true);
					Cookies.set('videoPlayed', videoList);
					localStorage.removeItem(storageKey); // <-- Clear progress on video end
				});
			}
		}
	}, [
		scriptReady,
		setVideoFinished,
		videoEmbed.provider,
		videoEmbed.providerUid,
		videosWatchedList,
		setIsGlu,
		storageKey,
		setVideoWatched,
		setWarning,
	]);

	const videoSrc =
		videoEmbed.provider === 'youtube'
			? `https://www.youtube.com/embed/${videoEmbed.providerUid}${
					autoPlay ? '?autoplay=1' : ''
			  }`
			: `https://player.vimeo.com/video/${videoEmbed.providerUid}${
					privateVideoHash && locale === 'en' ? '?h=9ace720501&' : '?'
			  }api=1&player_id=PLAYER1&portrait=0&byline=0${
					autoPlay ? '&autoplay=1' : ''
			  }`;

	return (
		<div className="relative mb-8 lg:b-0 lg:basis-1/2 aspect-video">
			<iframe
				title={videoEmbed.title || ''}
				src={videoSrc}
				allow="fullscreen; picture-in-picture"
				className="w-full h-full"
				width={videoEmbed.width}
				height={videoEmbed.height}
				id="vimeo-player"
				ref={ref}
			/>
			{locale === 'en' && setIsGlu && (
				<FeedbackGlu
					pageUrl={
						typeof window !== 'undefined'
							? window.location.href
							: ''
					}
					showFeedbackBubble={showFeedbackBubble}
					showCommentForm={showCommentForm}
					setIsGlu
					locale={locale}
					setShowCommentForm={setShowCommentForm}
					setShowFeedbackBubble={setShowFeedbackBubble}
					setResponse={setResponse}
					response={response}
				/>
			)}
		</div>
	);
};

export default VideoPlayer;
