import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { StructuredText } from 'react-datocms';
import CheckIcon from '../../../assets/check-icon.inline.svg';
import ChevronLeft from '../../../assets/chevron-left.inline.svg';
import ChevronRight from '../../../assets/chevron-right.inline.svg';
import Star from '../../../assets/rating-star.inline.svg';
import useIsMobile from '../../../hooks/mobile';

const fullSource = {
	trustpilot: 'Trustpilot',
	google: 'Google Reviews',
	facebook: 'Facebook Reviews',
	client: 'Client testimonials',
	feefo: 'Feefo Reviews',
};

function Cards({ reviews }) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const carouselRef = useRef(null);
	const isMobile = useIsMobile();

	const handleNext = () => {
		if (currentIndex < reviews.length - 1) {
			setCurrentIndex(currentIndex + 1);
			carouselRef.current.scrollBy({
				left: isMobile ? 300 : 1200,
				behavior: 'smooth',
			});
		}
	};

	const handlePrevious = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
			carouselRef.current.scrollBy({
				left: isMobile ? -300 : -1200,
				behavior: 'smooth',
			});
		}
	};
	const showButton = reviews.length > 4;
	return (
		<div>
			<div className="flex items-center space-x-2 mb-4">
				{showButton && (
					<button
						type="button"
						onClick={handlePrevious}
						className="bg-gray-400 p-2 rounded-full hover:bg-gray-400"
						disabled={currentIndex === 0}
					>
						<ChevronLeft
							className={clsx(
								'w-7 h-7 text-brand-pale-500 cursor-pointer',
								currentIndex === 0 &&
									'text-grey-400 cursor-not-allowed'
							)}
						/>
					</button>
				)}
				<div
					className="flex overflow-x-scroll snap-x carousel-hide-scrollbar"
					ref={carouselRef}
					style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
				>
					{reviews.map((review) => (
						<ItemCard review={review} key={review.id} />
					))}
				</div>
				{showButton && (
					<button
						type="button"
						onClick={handleNext}
						className="bg-gray-400 p-2 rounded-full hover:bg-gray-400"
						disabled={currentIndex === reviews.length - 1}
					>
						<ChevronRight
							className={clsx(
								'w-7 h-7 text-brand-pale-500 cursor-pointer',
								currentIndex === reviews.length - 1 &&
									'text-grey-400 cursor-not-allowed'
							)}
						/>
					</button>
				)}
			</div>
		</div>
	);
}

export default Cards;

Cards.defaultProps = {
	reviews: [],
};

Cards.propTypes = {
	reviews: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			time: PropTypes.string,
			title: PropTypes.string,
			review: PropTypes.string,
			rating: PropTypes.number,
		})
	),
};

const ItemCard = ({ review, isMobile }) => {
	const Component = isMobile ? 'div' : 'p';

	const [isOpend, setIsOpend] = useState(false);
	return (
		<div
			key={review.id}
			className="max-w-[290px] min-w-[290px] p-4 shadow-md m-2 bg-brand-pale-300 snap-start"
		>
			<div className="flex items-center">
				<div className="flex">
					{[...Array(Number(review.rating))].map((_, index) => (
						<>
							<Star
								keys={`Trustpilot-Review-star-${index}`}
								className={clsx(
									'h-3 w-3 ml-0.5',
									review.source === 'google' &&
										'text-warning',
									review.source === 'client' &&
										'text-brand-red-500',
									review.source === 'feefo' && 'text-warning',
									review.source === 'trustpilot' &&
										'text-success-500'
								)}
							/>
						</>
					))}
				</div>
				{review.isVerified && (
					<span className="flex items-center ml-2 text-gray-500 text-xs font-centra-light">
						<CheckIcon className="w-5 h-5 p-[2px] text-success-500 bg-brand-pale-500 rounded-full mr-1" />{' '}
						Verified
					</span>
				)}
			</div>

			<div>
				<h3 className="font-semibold text-sm text-gray-800 mt-2 font-castledown-bold capitalize line-clamp-1">
					{review.title}
				</h3>
				<Component
					onClick={() => setIsOpend((prev) => !prev)}
					className={clsx(
						'text-gray-500 text-xs font-centra-light mt-2 line-clamp-3 cursor-pointer',
						isOpend &&
							'line-clamp-none transition-all duration-500 ease-out'
					)}
				>
					<StructuredText data={review.review} />
				</Component>
				<p className="text-gray-400 text-xs mt-7 line-clamp-1 inline-flex">
					<span className="font-centra-light">{review.name}, </span>{' '}
					<span className="font-centra-light">{review.time}</span>
				</p>
				<p className="text-xs mt-1 font-centra-book capitalize">
					Source: {fullSource[review.source]}
				</p>
			</div>
		</div>
	);
};
ItemCard.defaultProps = {
	review: {},
	isMobile: false,
};

ItemCard.propTypes = {
	isMobile: PropTypes.bool,
	review: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
		time: PropTypes.string,
		title: PropTypes.string,
		review: PropTypes.string,
		rating: PropTypes.number,
		source: PropTypes.string,
		isVerified: PropTypes.bool,
	}),
};
