import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { PBSLink } from '../../../atoms/link';
import GreyBullet from '../../../../assets/grey-bullet.svg';
import { buildLink } from '../../../../utils/locale';
import { LikeButton } from '../../atoms/like-button';

const CardList = ({ cardContent, locale }) => {
	const fullSlug = cardContent.category?.slug
		? `${cardContent.category?.slug}/${cardContent.slug}`
		: cardContent.slug;

	return (
		<li
			key={`${cardContent.id}-${cardContent.title}${Math.random()}`}
			className="w-full pb-6 border-blue-200 border-b-1"
		>
			<div className="flex flex-col w-full h-full sm:items-center gap-y-4 gap-x-6 sm:flex-row">
				<div className="flex items-start w-full h-full lg:items-center">
					<div className="flex flex-col justify-between w-full h-full">
						<div>
							<h3 className="mb-2 text-xl font-bold">
								{cardContent.title}
							</h3>
							{cardContent.intro && <p>{cardContent.intro}</p>}
						</div>
						{cardContent.date ||
						cardContent.timeToWatchInMinutes ? (
							<ul className="flex flex-row gap-3 pt-3">
								{cardContent.date ? (
									<li className="flex flex-row items-center">
										<img
											src={GreyBullet}
											className="mr-3"
											alt="Peninsla HR & Health and Safety Support"
										/>
										{format(
											new Date(cardContent.date),
											'dd/MM/yyyy'
										)}
									</li>
								) : null}
								{cardContent.timeToWatchInMinutes ? (
									<li className="flex flex-row items-center">
										<img
											src={GreyBullet}
											className="mr-3"
											alt="Peninsla HR & Health and Safety Support"
										/>
										{cardContent.timeToWatchInMinutes} mins
									</li>
								) : null}
							</ul>
						) : null}
					</div>
				</div>
				<div className="flex gap-4">
					<LikeButton likes={132} courseId={cardContent.id} />
				</div>

				<div className="w-full my-4 sm:w-fit sm:my-0">
					<PBSLink
						size="Small"
						to={buildLink(locale, `/elearning/${fullSlug}`)}
						className="justify-center w-full ml-auto sm:w-fit"
					>
						Watch
					</PBSLink>
				</div>
			</div>
		</li>
	);
};

export { CardList };

CardList.defaultProps = {
	locale: 'en',
};

CardList.propTypes = {
	cardContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		timeToWatchInMinutes: PropTypes.string,
		intro: PropTypes.string,
		slug: PropTypes.string.isRequired,
		category: PropTypes.string,
		date: PropTypes.string,
	}).isRequired,
	locale: PropTypes.string,
};
