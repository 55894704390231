import { useState, useEffect } from 'react';
import { addMediaListener, removeMediaListener } from './eventListener';

export function useMediaQuery(query) {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		if (window.matchMedia) {
			const media = window?.matchMedia(query);
			if (media.matches !== matches) {
				setMatches(media.matches);
			}
			const listener = () => {
				setMatches(media.matches);
			};
			addMediaListener(media, 'change', listener);
			return () => removeMediaListener(media, 'change', listener);
		}
	}, [matches, query]);

	return matches;
}
