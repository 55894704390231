import { useLocation } from '@reach/router';
import { clsx } from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDrawer } from '../../../contexts/drawer-context';
import { useScreenResizer } from '../../../contexts/screen-resize-context';

import { CallUs } from '../../atoms/call-us';
import { Hamburger } from '../../atoms/hamburger';
import { Logo } from '../../atoms/logo';
import { SideNav } from '../../molecules/side-nav';
import { NavDesktop } from '../nav-desktop';

import useModal from '../../../hooks/modal';
import usePeninsulaNow from '../../../hooks/peninsula-now';
import { sendTrackingData } from '../../../utils';
import { getLocale } from '../../../utils/locale';
import ModalWrapper from '../../glu/molecules/modal-wrapper';
import { PeninsulaModalForm } from '../modal-peninsula-now';
import { PeninsulaNow } from '../peninsula-now';

const shortLocale = {
	// eslint-disable-next-line prettier/prettier
	en: 'en',
	'en-IE': 'ie',
	'en-CA': 'ca',
};
const Header = ({
	siteTitle,
	isStorybook,
	headerContent,
	siteSettings,
	mobileOnlyHamburger,
	homepageUrl,
	locale,
	gluLayout,
	pageName,
}) => {
	const { drawerRef, setDrawerRef } = useDrawer();
	const navRef = useRef(null);
	const { width } = useScreenResizer();
	const [sideLinks, setSideLinks] = useState([]);

	const primaryNavElementCheck = useStaticQuery(graphql`
		query primaryNavElementCheck {
			allDatoCmsGluWebinarsPage {
				nodes {
					locale
				}
			}
			allDatoCmsGluClientPage(sort: { fields: originalId, order: ASC }) {
				nodes {
					slug
					locale
					title
				}
			}
			allDatoCmsGluGossip {
				nodes {
					locale
				}
			}
		}
	`);

	const {
		allDatoCmsGluClientPage: clientPages,
		allDatoCmsGluWebinarsPage: webinarsPage,
		// allDatoCmsGluGossip: gossipPage,
	} = primaryNavElementCheck;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const primaryNavigationArray = [];
	const clientPagesForLocale = clientPages.nodes.filter(
		(node) => node.locale === locale
	);
	clientPagesForLocale.slice(0, 3).forEach((page) => {
		const primaryNavigationObject = {
			title: page.title,
			link:
				locale === 'en'
					? `/elearning/${page.slug}`
					: `/${shortLocale[locale]}/elearning/${page.slug}`,
			treeChildren: [],
		};
		primaryNavigationArray.push(primaryNavigationObject);
	});

	const secondNav = clientPagesForLocale
		.slice(3, clientPagesForLocale.length)
		.map((item, i) => ({
			title: item.title,
			link:
				locale === 'en'
					? `/elearning/${item.slug}`
					: `/${shortLocale[locale]}/elearning/${item.slug}`,
			subText: '',
			position: i,
		}));

	webinarsPage.nodes.forEach((page) => {
		if (page.locale !== locale) {
			return;
		}
		const primaryNavigationObject = {
			title: 'Webinars',
			link:
				locale === 'en'
					? '/elearning/webinars'
					: `/${shortLocale[locale]}/elearning/webinars`,
			treeChildren: [],
		};
		primaryNavigationArray.push(primaryNavigationObject);
	});
	if (secondNav.length > 0) {
		Array(1)
			.fill('menu')
			.forEach(() => {
				const primaryNavigationObject = {
					title: 'More GLU',
					link: null,
					treeChildren: secondNav,
				};
				primaryNavigationArray.push(primaryNavigationObject);
			});
	}

	// Bring back wehn ready to release via proxy
	// gossipPage.nodes.forEach((page) => {
	// 	if (page.locale !== locale) {
	// 		return;
	// 	}
	// 	const primaryNavigationObject = {
	// 		title: 'Gossip',
	// 		link:
	// 			locale === 'en'
	// 				? '/elearning/gossip'
	// 				: `/${shortLocale[locale]}/elearning/gossip`,
	// 		treeChildren: [],
	// 	};
	// 	primaryNavigationArray.push(primaryNavigationObject);
	// });

	const gluHeaderContent = useMemo(
		() => ({
			primaryNavigation: primaryNavigationArray,
			sideNavigationCloseCopy: 'Close',
			sideNavigationOpenCopy: 'Menu',
		}),
		[primaryNavigationArray]
	);
	const headerContentObj = gluLayout ? gluHeaderContent : headerContent;

	if (!isStorybook) {
		useEffect(() => {
			if (width > 1024) {
				setSideLinks(headerContentObj.sideNavigation);
			} else {
				setSideLinks([
					headerContentObj.primaryNavigation,
					headerContentObj.sideNavigation,
				]);
			}
		}, [width, headerContentObj]);
	}

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', trackData);
	};
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const { isOnline } = usePeninsulaNow();
	const openModal = () => {
		setModalOpen(true);
		handleClickTracking({
			event_name: 'navigation',
			click_action: 'open modal',
			click_type: 'icon',
			click_text: 'Menu',
		});
	};
	const location = useLocation();
	const localePath = getLocale(location.pathname);

	return (
		<header className="bg-white top-0 sticky z-50">
			<section className="relative flex items-center justify-between w-full gap-2 p-4 mx-auto max-w-m-screen">
				<div className="flex items-center lg:py-6 header-left">
					<Logo
						width={126}
						height={36}
						title={siteTitle}
						url={homepageUrl}
					/>
					<NavDesktop
						aria-label="Main menu"
						className="hidden lg:lg:ml-lg-f xl:ml-xl-f lg:block"
						links={headerContentObj?.primaryNavigation}
						drawerOpen={drawerRef === navRef}
					/>
				</div>
				<div className="flex items-center header-right ">
					{pageName && localePath === '' ? (
						// modal for peninsula now rewrite
						<div className={clsx(isOnline && 'card flip')}>
							<PeninsulaNow
								setModal={openModal}
								className={clsx(
									!isOnline && 'hidden',
									'front cursor-pointer'
								)}
							/>

							<div className={clsx(isOnline && 'hidden', 'back')}>
								<CallUs
									callFrom={
										gluLayout || pageName === 'PeninsulaNow'
											? 'page'
											: 'header'
									}
									pageName={pageName}
									number={siteSettings.phoneNumber}
									className={`pr-2 mr-2 text-right ${
										!mobileOnlyHamburger
											? ''
											: 'lg:border-none'
									} md:mr-6 md:pr-6 border-r border-brand-pale-500 focus:outline-0 focus:shadow-focus focus:rounded-xs`}
									onClickProp={() =>
										handleClickTracking({
											event_name: 'navigation',
											click_type: 'tel',
										})
									}
								/>
							</div>
						</div>
					) : (
						<div
							className={`${
								pageName === 'PeninsulaNow' ? 'card' : 'block'
							}`}
						>
							<CallUs
								callFrom={
									gluLayout || pageName === 'PeninsulaNow'
										? 'page'
										: 'header'
								}
								pageName={pageName}
								number={siteSettings.phoneNumber}
								className={`pr-2 mr-2 text-right ${
									!mobileOnlyHamburger ? '' : 'lg:border-none'
								} md:mr-6 md:pr-6 border-r border-brand-pale-500 focus:outline-0 focus:shadow-focus focus:rounded-xs`}
								onClickProp={() =>
									handleClickTracking({
										event_name: 'navigation',
										click_type: 'tel',
									})
								}
							/>
						</div>
					)}
					{isModalOpen && pageName && (
						<ModalWrapper>
							<div
								className="relative 2xl:w-7/12 xl:w-[100%]  m-auto opacity-100 h-auto w-full 2xl:rounded-md shadow-xl bg-white overflow-auto"
								ref={ref}
							>
								<button
									type="button"
									title="Close Peninsula Now Modal"
									onClick={() => setModalOpen(false)}
									className="absolute  flex top-[5px] text-2xl bg-tranparent font-bold right-[10px] opacity-100 border-2 border-brand-pale-200 h-9 w-9 flex items-center justify-center rounded-full"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										width="20"
										height="20"
										viewBox="0 0 50 50"
										className="fill-current text-brand-pale-200 font-bold"
									>
										<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
									</svg>
								</button>
								<PeninsulaModalForm isOnline={isOnline} />
							</div>
						</ModalWrapper>
					)}
					<div
						className={`${
							!mobileOnlyHamburger ? 'block' : 'lg:hidden'
						}`}
					>
						<Hamburger
							label={headerContentObj?.sideNavigationOpenCopy}
							activeLabel={
								headerContentObj?.sideNavigationCloseCopy
							}
							labelVisibleMobile={false}
							isOpen={drawerRef === navRef}
							onClick={() => {
								setDrawerRef(navRef);
								handleClickTracking({
									event_name: 'navigation',
									click_action: 'open menu',
									click_type: 'icon',
									click_text: 'Menu',
								});
							}}
							className="relative focus:outline-0 focus:shadow-focus focus:rounded-xs"
						/>
					</div>
				</div>
			</section>

			<SideNav
				navRef={navRef}
				links={
					isStorybook ? headerContentObj?.sideNavigation : sideLinks
				}
				signInCopy={headerContentObj?.signInCopy}
			/>
		</header>
	);
};

Header.defaultProps = {
	siteTitle: 'Peninsula Homepage',
	isStorybook: false,
	mobileOnlyHamburger: false,
	homepageUrl: '/',
	siteSettings: {},
	locale: 'en',
	gluLayout: false,
	headerContent: null,
	pageName: null,
};

Header.propTypes = {
	siteTitle: PropTypes.string,
	isStorybook: PropTypes.bool,
	headerContent: PropTypes.shape({
		primaryNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						subText: PropTypes.string.isRequired,
						link: PropTypes.string.isRequired,
						icon: PropTypes.string.isRequired,
					})
				),
			})
		).isRequired,
		sideNavigation: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			),
			PropTypes.arrayOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						link: PropTypes.string,
						treeChildren: PropTypes.arrayOf(
							PropTypes.shape({
								title: PropTypes.string.isRequired,
								subText: PropTypes.string.isRequired,
								link: PropTypes.string.isRequired,
								icon: PropTypes.string.isRequired,
							})
						),
					})
				),
				PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				)
			),
		]),
		sideNavigationOpenCopy: PropTypes.string.isRequired,
		sideNavigationCloseCopy: PropTypes.string.isRequired,
		signInCopy: PropTypes.string,
	}),
	mobileOnlyHamburger: PropTypes.bool,
	homepageUrl: PropTypes.string,
	siteSettings: PropTypes.objectOf(PropTypes.string),
	locale: PropTypes.string,
	gluLayout: PropTypes.bool,
	pageName: PropTypes.string,
};

export { Header };
