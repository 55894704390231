import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { RelatedCard } from '../../atoms/related-card';

import * as relatedCardsStyles from './index.module.css';
import { getLocale, buildLink } from '../../../utils/locale';

const RelatedCards = ({ title, relatedCards }) => {
	const id = 'relatedPagesTitle';
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	return (
		<section
			aria-labelledby={id}
			className="border-b-4 md:border-b-6 bg-brand-pale-400 border-b-blue-400 mt-xl-f lg:mt-2xl-f"
		>
			<div className="px-4 mx-auto md:px-8 max-w-m-screen py-xl-f xl:py-2xl-f">
				<h2
					className="text-3xl text-center md:leading-normal md:text-5xl font-castledown-regular mb-lg-f"
					id={id}
				>
					{title}
				</h2>
				<ul
					className={clsx(
						'grid w-full gap-5 grid-flow-col list-scrollbar pb-xs-f scroll-indicator',
						'overflow-x-scroll overflow-y-hidden',
						'lg:grid-cols-3 lg:grid-flow-row lg:pb-0 lg:bg-none',
						relatedCardsStyles.list
					)}
				>
					{relatedCards.map((relatedCard) => (
						<li key={`relatedCard${relatedCard.to}`}>
							<RelatedCard
								to={buildLink(locale, relatedCard.to)}
								title={relatedCard.title}
								intro={relatedCard.intro}
								className="w-[clamp(270px,80vw,340px)] lg:w-[auto]"
							/>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export { RelatedCards };

RelatedCards.propTypes = {
	title: PropTypes.string.isRequired,
	relatedCards: PropTypes.arrayOf(
		PropTypes.shape({
			headerLevel: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']),
			title: PropTypes.string.isRequired,
			intro: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
		})
	).isRequired,
};
