/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import useCountdown from '../../../../hooks/useCountdown';

const DateTimeDisplay = ({ value, type, className }) => (
	<div
		className={`${className} m-2 text-center border-2 rounded-sm xl:m-3 bg-brand-pale-400 py-2 px-4 min-w-[100px]`}
	>
		<p className="text-2xl font-bold md:text-3xl xl:text-4xl text-brand-red-400">
			{value}
		</p>
		<p className="text-sm text-brand-blue-400">{type}</p>
	</div>
);

const ClockDisplay = ({ days, hours, minutes, seconds }) => (
	<div className="flex flex-row items-center w-full justify-evenly">
		<DateTimeDisplay value={days} type="Days" />
		<span className="-mt-3 text-2xl font-bold md:text-3xl xl:text-4xl">
			:
		</span>
		<DateTimeDisplay value={hours} type="Hours" />
		<span className="hidden -mt-3 text-2xl font-bold md:text-3xl xl:text-4xl sm:block">
			:
		</span>
		<DateTimeDisplay
			value={minutes}
			type="Minutes"
			className="hidden sm:block"
		/>
		<span className="hidden -mt-3 text-2xl font-bold md:text-3xl xl:text-4xl lg:block">
			:
		</span>
		<DateTimeDisplay
			value={seconds}
			type="Seconds"
			className="hidden lg:block"
		/>
	</div>
);

const CountdownTimer = ({ targetDate }) => {
	const { days, hours, minutes, seconds, hasExpired } =
		useCountdown(targetDate);

	if (hasExpired) {
		return <p>Webinar has expired.</p>;
	}
	return (
		<ClockDisplay
			days={days}
			hours={hours}
			minutes={minutes}
			seconds={seconds}
		/>
	);
};

export default CountdownTimer;
