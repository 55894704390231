import React from 'react';
import PropTypes from 'prop-types';
import { StructuredText, renderNodeRule } from 'react-datocms';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { isLink, isParagraph, isRoot } from 'datocms-structured-text-utils';
import loadScript from 'load-script2';
import { datoGatsbyImageProp, refProp } from '../../../types';
import TickIcon from '../../../assets/tick.inline.svg';
import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';
import { getCookie, sendTrackingData } from '../../../utils';
import { useDrawer } from '../../../contexts/drawer-context';
import VideoPlayer from '../../glu/molecules/video-player';
import ModalWrapper from '../../glu/molecules/modal-wrapper';
import useModal from '../../../hooks/modal';
import playbutton from '../../../assets/play-button.png';
// import SVGComponent from './SvgIcon';

function TextWithImageBlock({ textWithImageContent, drawerRef, className }) {
	const {
		id,
		content,
		headerTitle,
		imagePosition,
		as,
		image,
		hasCta,
		ctaText,
		ctaOpenForm,
		ctaLink,
		showTitle,
	} = textWithImageContent;
	const { setDrawerRef } = useDrawer();
	const Component = as || 'h1';
	const Alt = (image && image.alt) || 'Peninsula Business support';
	const imageData = getImage(image);
	const ref = React.useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);

	// eslint-disable-next-line no-unused-vars
	const [videoFinished, setVideoFinished] = React.useState();
	const [scriptReady, setScriptReady] = React.useState(false);
	React.useEffect(() => {
		const loadVideoScript = () =>
			loadScript('https://player.vimeo.com/api/player.js')
				.then(() => {
					setScriptReady(true);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadVideoScript);
		} else {
			setTimeout(loadVideoScript);

			setTimeout(() => {
				loadVideoScript();
			}, 100);
		}
	}, []);

	const videosWatchedList = React.useMemo(() => {
		if (getCookie('videoPlayed')) {
			return getCookie('videoPlayed').includes('%')
				? getCookie('videoPlayed').split('%2C')
				: [getCookie('videoPlayed')];
		}
		return [];
	}, []);

	const handleClickTracking = (text = null) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = headerTitle;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: text || ctaText,
		});
	};
	return (
		<section
			className="max-w-m-screen mx-auto py-2 bg-white"
			aria-labelledby={id}
		>
			<div
				className={clsx(
					'bg-white grid md:grid-cols-2 gap-4 grid-cols-1',
					className
				)}
			>
				{textWithImageContent?.video ? (
					<div
						className={clsx(
							'mb-2 lg:mb-0 lg:basis-1/2 aspect-video w-full',
							imagePosition === 'Right'
								? 'md:order-last'
								: 'md:order-first'
						)}
					>
						<button
							type="button"
							onClick={() => setModalOpen(true)}
							tabIndex={0}
							className="relative"
						>
							{image ? (
								<GatsbyImage
									image={imageData}
									alt={Alt}
									className={clsx(
										'border-8 opacity-100',
										'border-white',
										'drop-shadow-image',
										'self-start',
										'justify-self-center',
										'md:justify-self-start'
									)}
								/>
							) : (
								<img
									src={
										textWithImageContent?.video.thumbnailUrl
									}
									alt={Alt}
									width="100%"
									height="100%"
								/>
							)}
							<img
								src={playbutton}
								alt="play-button"
								className="w-24 h-24 absolute block top-0 left-0 right-0 bottom-0 mx-auto my-auto opacity-80 hover:opacity-100 "
							/>
							{/* <SVGComponent
								// color="#3954e0"
								color="#cc0411"
								className="w-24 h-24 bg-white absolute block top-0 left-0 right-0 bottom-0 mx-auto my-auto opacity-70 hover:opacity-100 shadow-[rgba(0,_0,_0,_0.4)_0px_60px_40px_7px]"
							/> */}
						</button>
					</div>
				) : (
					<div
						className={clsx(
							'flex w-full px-5',
							imagePosition === 'Right'
								? 'md:order-last'
								: 'md:order-first'
						)}
					>
						<GatsbyImage
							image={imageData}
							alt={Alt}
							className={clsx(
								'border-8',
								'border-white',
								'drop-shadow-image',
								'self-start',
								'justify-self-center',
								'md:justify-self-start'
							)}
						/>
					</div>
				)}
				{isModalOpen && (
					<ModalWrapper>
						<div
							className="relative w-7/12 m-auto opacity-100"
							ref={ref}
						>
							{textWithImageContent?.video.providerUid && (
								<VideoPlayer
									videoEmbed={textWithImageContent?.video}
									setVideoFinished={setVideoFinished}
									scriptReady={scriptReady}
									videosWatchedList={videosWatchedList}
								/>
							)}
						</div>
					</ModalWrapper>
				)}
				<div className="flex flex-col md:px-5 px-4 items-start justify-start">
					{showTitle && (
						<div className={clsx('flex shrink ')}>
							<Component
								id={id}
								className={clsx(
									'!leading-[1.3] font-castledown-regular',
									'text-3xl md:text-4xl xl:text-4xl mb-10'
								)}
							>
								{headerTitle}
							</Component>
						</div>
					)}

					<StructuredText
						data={content}
						customNodeRules={[
							renderNodeRule(
								isParagraph,
								({ children, key, ancestors }) => {
									if (isRoot(ancestors[0])) {
										return (
											<p
												className="mb-3 text-lg font-centra-light md:text-xl last-of-type:mb-0"
												key={key}
											>
												{children}
											</p>
										);
									}
									return children;
								}
							),
						]}
						renderBlock={({ record }) => {
							// eslint-disable-next-line no-underscore-dangle
							switch (record?.__typename) {
								case 'DatoCmsTextWithLink':
									return (
										<div className="my-[6px] first-of-type:mb-1 last-of-type:mb-10">
											<div className="font-castledown-bold text-lg">
												<div className=" inline-flex items-center space-x-3">
													<TickIcon
														className={clsx(
															'flex items-center w-3 h-auto shrink-0 text-blue-400'
														)}
														aria-hidden="true"
													/>
													<span className="flex items-center">
														<StructuredText
															data={
																record.textContent
															}
															customNodeRules={[
																renderNodeRule(
																	isLink,
																	({
																		node,
																		children,
																		key,
																	}) => (
																		<PBSLink
																			to={
																				node.url
																			}
																			variant="Link"
																			key={
																				key
																			}
																		>
																			{
																				children
																			}
																		</PBSLink>
																	)
																),
															]}
														/>
													</span>
												</div>
											</div>
										</div>
									);

								default:
									return null;
							}
						}}
					/>
					{hasCta && ctaOpenForm && (
						<div className="my-5">
							<div
								className={clsx(
									'flex',
									'flex-wrap',
									'flex-col',
									'items-start',
									'justify-center',
									'md:justify-start',
									'md:items-start',
									'space-y-4',
									'lg:space-y-0',
									'lg:space-x-8',
									'lg:flex-row',
									'lg:items-center'
								)}
							>
								<Button
									onClick={() => {
										setDrawerRef(drawerRef);
										handleClickTracking(ctaText);
									}}
									variant="White"
									size="Small"
								>
									{ctaText}
								</Button>
							</div>
						</div>
					)}

					{hasCta && ctaLink && (
						<PBSLink to={ctaLink} variant="White" className="mt-4">
							{ctaText}
						</PBSLink>
					)}
				</div>
			</div>
		</section>
	);
}

TextWithImageBlock.defaultProps = {
	textWithImageContent: {
		as: 'h1',
		headingClassNames: '',
	},
	className: '',
};

TextWithImageBlock.propTypes = {
	textWithImageContent: PropTypes.shape({
		id: PropTypes.string.isRequired,
		headerTitle: PropTypes.string.isRequired,
		imagePosition: PropTypes.string.isRequired,
		image: datoGatsbyImageProp.isRequired,
		content: PropTypes.string.isRequired,
		ctaText: PropTypes.string.isRequired,
		ctaOpenForm: PropTypes.bool.isRequired,
		showTitle: PropTypes.bool.isRequired,
		ctaLink: PropTypes.string.isRequired,
		hasCta: PropTypes.bool.isRequired,
		checkListField: PropTypes.arrayOf(PropTypes.string).isRequired,
		as: PropTypes.string,
		headingClassNames: PropTypes.string,
		video: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
			url: PropTypes.string,
			provider: PropTypes.string,
			providerUid: PropTypes.string,
			title: PropTypes.string,
			thumbnailUrl: PropTypes.string,
		}),
	}),
	drawerRef: refProp.isRequired,
	className: PropTypes.string,
};

export { TextWithImageBlock };
