import React from 'react';

export const SuccessModal = () => (
	<>
		<div className="flex flex-col w-full justify-between mx-auto grow max-w-sm px-4 pt-6 pb-10 bg-brand-pale-300">
			<div className="flex text-[#E30138] font-centra text-xl p-4 ">
				Booking Confirmed
			</div>
			<div className="font-centra-medium px-4">
				Our HR experts will get in touch with you shortly. In the
				meantime, you can find out more about our services{' '}
				<a
					href="/ca/"
					className="hover:bg-brand-blue-400 focus:bg-brand-blue-500 transition-colors focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent text-blue-400 decoration-1 hover:text-brand-blue-400 focus:shadow-focus focus:outline-focus underline"
				>
					here
				</a>
				.
			</div>
		</div>
	</>
);
