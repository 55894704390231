import React from 'react';

const XIcon = () => (
	<svg
		className="shrink-0"
		width="22"
		height="23"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="10" cy="10.6865" r="10" fill="#FB5758" />
		<path
			d="M13.7144 7.68652L7.28578 14.1001"
			stroke="white"
			strokeLinecap="round"
		/>
		<path
			d="M13.7144 14.1001L7.28579 7.68651"
			stroke="white"
			strokeLinecap="round"
		/>
	</svg>
);

export { XIcon };
