import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TickIcon from '../../../assets/tick.inline.svg';

const TickList = ({
	points,
	className,
	iconClassName,
	listItemClassName,
	textClassName,
}) => (
	<ul className={className}>
		{points.map((point) => (
			<li
				key={`tick-list-points-${point.text.replace(
					/[^A-Z0-9]+/gi,
					'_'
				)}`}
				className={clsx(
					'flex items-start',
					point.subText ? 'mb-7' : 'mb-4',
					listItemClassName
				)}
			>
				<TickIcon
					className={clsx(
						'w-3 h-auto shrink-0 mt-[7px] lg:mt-[9px] mr-4 text-blue-400',
						iconClassName
					)}
					aria-hidden="true"
				/>
				<div>
					<p className={textClassName}>{point.text}</p>
					{point.subText ? (
						<p className="font-centra-light">{point.subText}</p>
					) : null}
				</div>
			</li>
		))}
	</ul>
);

TickList.defaultProps = {
	points: [],
	className: '',
	iconClassName: '',
	textClassName: '',
	listItemClassName: 'lg:text-lg',
};

TickList.propTypes = {
	points: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			subText: PropTypes.string,
		})
	),
	className: PropTypes.string,
	listItemClassName: PropTypes.string,
	iconClassName: PropTypes.string,
	textClassName: PropTypes.string,
};

export { TickList };
