export const data = {
	1: {
		combined: 267.52,
		hr: 140.8,
		hs: 139.76,
	},
	2: {
		combined: 133.76,
		hr: 70.4,
		hs: 69.88,
	},
	3: {
		combined: 89.17,
		hr: 46.93,
		hs: 46.59,
	},
	4: {
		combined: 66.88,
		hr: 35.2,
		hs: 34.94,
	},
	5: {
		combined: 53.5,
		hr: 28.16,
		hs: 27.95,
	},
	6: {
		combined: 55.39,
		hr: 30.98,
		hs: 28.68,
	},
	7: {
		combined: 47.47,
		hr: 26.56,
		hs: 24.59,
	},
	8: {
		combined: 41.54,
		hr: 23.24,
		hs: 21.51,
	},
	9: {
		combined: 36.92,
		hr: 20.66,
		hs: 19.12,
	},
	10: {
		combined: 33.23,
		hr: 18.59,
		hs: 17.21,
	},
	11: {
		combined: 34.09,
		hr: 20.2,
		hs: 16.47,
	},
	12: {
		combined: 33.14,
		hr: 19.89,
		hs: 15.74,
	},
	13: {
		combined: 32.22,
		hr: 19.63,
		hs: 15,
	},
	14: {
		combined: 31.55,
		hr: 19.41,
		hs: 14.49,
	},
	15: {
		combined: 30.86,
		hr: 19.21,
		hs: 13.93,
	},
	16: {
		combined: 29.94,
		hr: 18.98,
		hs: 13.16,
	},
	17: {
		combined: 29.32,
		hr: 18.83,
		hs: 12.64,
	},
	18: {
		combined: 28.61,
		hr: 18.7,
		hs: 11.98,
	},
	19: {
		combined: 28,
		hr: 18.58,
		hs: 11.44,
	},
	20: {
		combined: 27.13,
		hr: 18.14,
		hs: 10.9,
	},
	21: {
		combined: 26.41,
		hr: 17.61,
		hs: 10.43,
	},
	22: {
		combined: 25.21,
		hr: 16.81,
		hs: 9.95,
	},
	23: {
		combined: 24.11,
		hr: 16.08,
		hs: 9.52,
	},
	24: {
		combined: 23.11,
		hr: 15.41,
		hs: 9.13,
	},
	25: {
		combined: 23.42,
		hr: 15.8,
		hs: 8.8,
	},
	26: {
		combined: 22.52,
		hr: 15.19,
		hs: 8.46,
	},
	27: {
		combined: 21.69,
		hr: 14.63,
		hs: 8.15,
	},
	28: {
		combined: 20.91,
		hr: 14.1,
		hs: 7.86,
	},
	29: {
		combined: 20.19,
		hr: 13.62,
		hs: 7.59,
	},
	30: {
		combined: 19.77,
		hr: 13.36,
		hs: 7.38,
	},
	31: {
		combined: 19.14,
		hr: 12.93,
		hs: 7.14,
	},
	32: {
		combined: 18.54,
		hr: 12.52,
		hs: 6.92,
	},
	33: {
		combined: 17.98,
		hr: 12.14,
		hs: 6.71,
	},
	34: {
		combined: 17.45,
		hr: 11.79,
		hs: 6.51,
	},
	35: {
		combined: 17.48,
		hr: 12.03,
		hs: 6.33,
	},
	36: {
		combined: 16.99,
		hr: 11.7,
		hs: 6.16,
	},
	37: {
		combined: 16.53,
		hr: 11.38,
		hs: 5.99,
	},
	38: {
		combined: 16.1,
		hr: 11.08,
		hs: 5.83,
	},
	39: {
		combined: 15.69,
		hr: 10.8,
		hs: 5.68,
	},
	40: {
		combined: 16.36,
		hr: 11.46,
		hs: 5.6,
	},
	41: {
		combined: 15.96,
		hr: 11.18,
		hs: 5.47,
	},
	42: {
		combined: 15.58,
		hr: 10.91,
		hs: 5.34,
	},
	43: {
		combined: 15.22,
		hr: 10.66,
		hs: 5.21,
	},
	44: {
		combined: 14.87,
		hr: 10.42,
		hs: 5.09,
	},
	45: {
		combined: 14.98,
		hr: 10.64,
		hs: 5.11,
	},
	46: {
		combined: 14.65,
		hr: 10.41,
		hs: 5,
	},
	47: {
		combined: 14.34,
		hr: 10.19,
		hs: 4.89,
	},
	48: {
		combined: 14.04,
		hr: 9.98,
		hs: 4.79,
	},
	49: {
		combined: 13.76,
		hr: 9.78,
		hs: 4.69,
	},
	50: {
		combined: 14.13,
		hr: 10.13,
		hs: 4.74,
	},
	51: {
		combined: 13.86,
		hr: 9.93,
		hs: 4.65,
	},
	52: {
		combined: 13.59,
		hr: 9.74,
		hs: 4.56,
	},
	53: {
		combined: 13.33,
		hr: 9.56,
		hs: 4.47,
	},
	54: {
		combined: 13.09,
		hr: 9.38,
		hs: 4.39,
	},
	55: {
		combined: 12.85,
		hr: 9.21,
		hs: 4.31,
	},
	56: {
		combined: 12.62,
		hr: 9.04,
		hs: 4.23,
	},
	57: {
		combined: 12.4,
		hr: 8.89,
		hs: 4.16,
	},
	58: {
		combined: 12.18,
		hr: 8.73,
		hs: 4.09,
	},
	59: {
		combined: 11.98,
		hr: 8.58,
		hs: 4.02,
	},
	60: {
		combined: 11.9,
		hr: 8.51,
		hs: 3.99,
	},
	61: {
		combined: 11.7,
		hr: 8.37,
		hs: 3.92,
	},
	62: {
		combined: 11.52,
		hr: 8.24,
		hs: 3.86,
	},
	63: {
		combined: 11.33,
		hr: 8.11,
		hs: 3.8,
	},
	64: {
		combined: 11.16,
		hr: 7.98,
		hs: 3.74,
	},
	65: {
		combined: 10.98,
		hr: 7.86,
		hs: 3.68,
	},
	66: {
		combined: 10.82,
		hr: 7.74,
		hs: 3.62,
	},
	67: {
		combined: 10.66,
		hr: 7.62,
		hs: 3.57,
	},
	68: {
		combined: 10.5,
		hr: 7.51,
		hs: 3.52,
	},
	69: {
		combined: 10.35,
		hr: 7.4,
		hs: 3.47,
	},
	70: {
		combined: 10.79,
		hr: 7.88,
		hs: 3.47,
	},
	71: {
		combined: 10.63,
		hr: 7.77,
		hs: 3.42,
	},
	72: {
		combined: 10.49,
		hr: 7.66,
		hs: 3.37,
	},
	73: {
		combined: 10.34,
		hr: 7.55,
		hs: 3.33,
	},
	74: {
		combined: 10.2,
		hr: 7.45,
		hs: 3.28,
	},
	75: {
		combined: 10.07,
		hr: 7.35,
		hs: 3.24,
	},
	76: {
		combined: 9.93,
		hr: 7.26,
		hs: 3.2,
	},
	77: {
		combined: 9.81,
		hr: 7.16,
		hs: 3.16,
	},
	78: {
		combined: 9.68,
		hr: 7.07,
		hs: 3.11,
	},
	79: {
		combined: 9.56,
		hr: 6.98,
		hs: 3.08,
	},
	80: {
		combined: 10.04,
		hr: 7.4,
		hs: 3.18,
	},
	81: {
		combined: 9.92,
		hr: 7.31,
		hs: 3.14,
	},
	82: {
		combined: 9.8,
		hr: 7.22,
		hs: 3.1,
	},
	83: {
		combined: 9.68,
		hr: 7.14,
		hs: 3.06,
	},
	84: {
		combined: 9.56,
		hr: 7.05,
		hs: 3.03,
	},
	85: {
		combined: 9.45,
		hr: 6.97,
		hs: 2.99,
	},
	86: {
		combined: 9.34,
		hr: 6.89,
		hs: 2.96,
	},
	87: {
		combined: 9.23,
		hr: 6.81,
		hs: 2.92,
	},
	88: {
		combined: 9.13,
		hr: 6.73,
		hs: 2.89,
	},
	89: {
		combined: 9.03,
		hr: 6.66,
		hs: 2.86,
	},
	90: {
		combined: 9.46,
		hr: 7.04,
		hs: 2.95,
	},
	91: {
		combined: 9.36,
		hr: 6.96,
		hs: 2.92,
	},
	92: {
		combined: 9.26,
		hr: 6.88,
		hs: 2.89,
	},
	93: {
		combined: 9.16,
		hr: 6.81,
		hs: 2.86,
	},
	94: {
		combined: 9.06,
		hr: 6.74,
		hs: 2.83,
	},
	95: {
		combined: 8.96,
		hr: 6.67,
		hs: 2.8,
	},
	96: {
		combined: 8.87,
		hr: 6.6,
		hs: 2.77,
	},
	97: {
		combined: 8.78,
		hr: 6.53,
		hs: 2.74,
	},
	98: {
		combined: 8.69,
		hr: 6.46,
		hs: 2.71,
	},
	99: {
		combined: 8.6,
		hr: 6.4,
		hs: 2.68,
	},
	100: {
		combined: 9.26,
		hr: 7,
		hs: 2.81,
	},
};

export const dataCA = {
	1: {
		combined: 519.0,
		hs: 279.0,
		hr: 329.0,
	},
	2: {
		combined: 259.5,
		hs: 139.5,
		hr: 164.5,
	},
	3: {
		combined: 173.0,
		hs: 93.0,
		hr: 109.67,
	},
	4: {
		combined: 129.75,
		hs: 69.75,
		hr: 82.25,
	},
	5: {
		combined: 103.8,
		hs: 55.8,
		hr: 65.8,
	},
	6: {
		combined: 113.17,
		hs: 59.83,
		hr: 73.17,
	},
	7: {
		combined: 97.0,
		hs: 51.29,
		hr: 62.71,
	},
	8: {
		combined: 84.88,
		hs: 44.88,
		hr: 54.88,
	},
	9: {
		combined: 75.44,
		hs: 39.89,
		hr: 48.78,
	},
	10: {
		combined: 67.9,
		hs: 35.9,
		hr: 43.9,
	},
	11: {
		combined: 64.82,
		hs: 33.55,
		hr: 42.64,
	},
	12: {
		combined: 62.25,
		hs: 31.58,
		hr: 41.58,
	},
	13: {
		combined: 60.08,
		hs: 29.92,
		hr: 40.69,
	},
	14: {
		combined: 58.21,
		hs: 28.36,
		hr: 39.93,
	},
	15: {
		combined: 56.6,
		hs: 27.27,
		hr: 39.27,
	},
	16: {
		combined: 55.06,
		hs: 26.06,
		hr: 38.69,
	},
	17: {
		combined: 53.71,
		hs: 25.0,
		hr: 38.18,
	},
	18: {
		combined: 52.5,
		hs: 24.06,
		hr: 37.72,
	},
	19: {
		combined: 51.42,
		hs: 23.21,
		hr: 37.32,
	},
	20: {
		combined: 50.45,
		hs: 22.45,
		hr: 36.95,
	},
	21: {
		combined: 53.29,
		hs: 22.81,
		hr: 39.95,
	},
	22: {
		combined: 50.86,
		hs: 21.77,
		hr: 38.14,
	},
	23: {
		combined: 48.65,
		hs: 20.83,
		hr: 36.48,
	},
	24: {
		combined: 46.63,
		hs: 19.96,
		hr: 34.96,
	},
	25: {
		combined: 44.76,
		hs: 19.16,
		hr: 33.56,
	},
	26: {
		combined: 46.88,
		hs: 19.58,
		hr: 35.73,
	},
	27: {
		combined: 45.15,
		hs: 18.85,
		hr: 34.41,
	},
	28: {
		combined: 43.54,
		hs: 18.18,
		hr: 33.18,
	},
	29: {
		combined: 42.03,
		hs: 17.55,
		hr: 32.03,
	},
	30: {
		combined: 40.63,
		hs: 16.97,
		hr: 30.97,
	},
	31: {
		combined: 42.55,
		hs: 17.39,
		hr: 32.55,
	},
	32: {
		combined: 41.22,
		hs: 16.84,
		hr: 31.53,
	},
	33: {
		combined: 39.97,
		hs: 16.33,
		hr: 30.58,
	},
	34: {
		combined: 38.79,
		hs: 15.85,
		hr: 29.68,
	},
	35: {
		combined: 37.69,
		hs: 15.4,
		hr: 28.83,
	},
	36: {
		combined: 38.86,
		hs: 15.81,
		hr: 29.97,
	},
	37: {
		combined: 37.81,
		hs: 15.38,
		hr: 29.16,
	},
	38: {
		combined: 36.82,
		hs: 14.97,
		hr: 28.39,
	},
	39: {
		combined: 35.87,
		hs: 14.59,
		hr: 27.67,
	},
	40: {
		combined: 34.98,
		hs: 14.23,
		hr: 26.98,
	},
	41: {
		combined: 35.83,
		hs: 14.37,
		hr: 27.78,
	},
	42: {
		combined: 34.98,
		hs: 14.02,
		hr: 27.12,
	},
	43: {
		combined: 34.16,
		hs: 13.7,
		hr: 26.49,
	},
	44: {
		combined: 33.39,
		hs: 13.39,
		hr: 25.89,
	},
	45: {
		combined: 32.64,
		hs: 13.09,
		hr: 25.31,
	},
	46: {
		combined: 33.24,
		hs: 13.24,
		hr: 25.85,
	},
	47: {
		combined: 32.53,
		hs: 12.96,
		hr: 25.3,
	},
	48: {
		combined: 31.85,
		hs: 12.69,
		hr: 24.77,
	},
	49: {
		combined: 31.2,
		hs: 12.43,
		hr: 24.27,
	},
	50: {
		combined: 30.58,
		hs: 12.18,
		hr: 23.78,
	},
	51: {
		combined: 30.76,
		hs: 12.14,
		hr: 24.1,
	},
	52: {
		combined: 30.17,
		hs: 11.9,
		hr: 23.63,
	},
	53: {
		combined: 29.6,
		hs: 11.68,
		hr: 23.19,
	},
	54: {
		combined: 29.06,
		hs: 11.46,
		hr: 22.76,
	},
	55: {
		combined: 28.53,
		hs: 11.25,
		hr: 22.35,
	},
	56: {
		combined: 28.02,
		hs: 11.05,
		hr: 21.95,
	},
	57: {
		combined: 27.53,
		hs: 10.86,
		hr: 21.56,
	},
	58: {
		combined: 27.05,
		hs: 10.67,
		hr: 21.19,
	},
	59: {
		combined: 26.59,
		hs: 10.49,
		hr: 20.83,
	},
	60: {
		combined: 26.15,
		hs: 10.32,
		hr: 20.48,
	},
	61: {
		combined: 26.38,
		hs: 10.31,
		hr: 20.8,
	},
	62: {
		combined: 25.95,
		hs: 10.15,
		hr: 20.47,
	},
	63: {
		combined: 25.54,
		hs: 9.98,
		hr: 20.14,
	},
	64: {
		combined: 25.14,
		hs: 9.83,
		hr: 19.83,
	},
	65: {
		combined: 24.75,
		hs: 9.68,
		hr: 19.52,
	},
	66: {
		combined: 24.38,
		hs: 9.53,
		hr: 19.23,
	},
	67: {
		combined: 24.01,
		hs: 9.39,
		hr: 18.94,
	},
	68: {
		combined: 23.66,
		hs: 9.25,
		hr: 18.66,
	},
	69: {
		combined: 23.32,
		hs: 9.12,
		hr: 18.39,
	},
	70: {
		combined: 22.99,
		hs: 8.99,
		hr: 18.13,
	},
	71: {
		combined: 23.37,
		hs: 9.0,
		hr: 18.44,
	},
	72: {
		combined: 23.04,
		hs: 8.88,
		hr: 18.18,
	},
	73: {
		combined: 22.73,
		hs: 8.75,
		hr: 17.93,
	},
	74: {
		combined: 22.42,
		hs: 8.64,
		hr: 17.69,
	},
	75: {
		combined: 22.12,
		hs: 8.52,
		hr: 17.45,
	},
	76: {
		combined: 21.83,
		hs: 8.41,
		hr: 17.22,
	},
	77: {
		combined: 21.55,
		hs: 8.3,
		hr: 17.0,
	},
	78: {
		combined: 21.27,
		hs: 8.19,
		hr: 16.78,
	},
	79: {
		combined: 21.0,
		hs: 8.09,
		hr: 16.57,
	},
	80: {
		combined: 20.74,
		hs: 7.99,
		hr: 16.36,
	},
	81: {
		combined: 20.98,
		hs: 8.01,
		hr: 16.65,
	},
	82: {
		combined: 20.72,
		hs: 7.91,
		hr: 16.45,
	},
	83: {
		combined: 20.47,
		hs: 7.82,
		hr: 16.25,
	},
	84: {
		combined: 20.23,
		hs: 7.73,
		hr: 16.06,
	},
	85: {
		combined: 19.99,
		hs: 7.64,
		hr: 15.87,
	},
	86: {
		combined: 19.76,
		hs: 7.55,
		hr: 15.69,
	},
	87: {
		combined: 19.53,
		hs: 7.46,
		hr: 15.51,
	},
	88: {
		combined: 19.31,
		hs: 7.38,
		hr: 15.33,
	},
	89: {
		combined: 19.09,
		hs: 7.29,
		hr: 15.16,
	},
	90: {
		combined: 18.88,
		hs: 7.21,
		hr: 14.99,
	},
	91: {
		combined: 19.11,
		hs: 7.24,
		hr: 15.26,
	},
	92: {
		combined: 18.9,
		hs: 7.16,
		hr: 15.1,
	},
	93: {
		combined: 18.7,
		hs: 7.09,
		hr: 14.94,
	},
	94: {
		combined: 18.5,
		hs: 7.01,
		hr: 14.78,
	},
	95: {
		combined: 18.31,
		hs: 6.94,
		hr: 14.62,
	},
	96: {
		combined: 18.11,
		hs: 6.86,
		hr: 14.47,
	},
	97: {
		combined: 17.93,
		hs: 6.79,
		hr: 14.32,
	},
	98: {
		combined: 17.74,
		hs: 6.72,
		hr: 14.17,
	},
	99: {
		combined: 17.57,
		hs: 6.66,
		hr: 14.03,
	},
	100: {
		combined: 17.39,
		hs: 6.59,
		hr: 13.89,
	},
};

export const dataIE = {
	1: {
		combined: 366,
		hr: 219,
		hs: 188,
	},
	2: {
		combined: 183,
		hr: 109.5,
		hs: 94,
	},
	3: {
		combined: 122,
		hr: 73,
		hs: 62.67,
	},
	4: {
		combined: 91.5,
		hr: 54.75,
		hs: 47,
	},
	5: {
		combined: 73.2,
		hr: 43.8,
		hs: 37.6,
	},
	6: {
		combined: 76.83,
		hr: 47,
		hs: 38.33,
	},
	7: {
		combined: 65.86,
		hr: 40.29,
		hs: 32.86,
	},
	8: {
		combined: 57.63,
		hr: 35.25,
		hs: 28.75,
	},
	9: {
		combined: 51.22,
		hr: 31.33,
		hs: 25.56,
	},
	10: {
		combined: 46.1,
		hr: 28.2,
		hs: 23,
	},
	11: {
		combined: 43.55,
		hr: 27.18,
		hs: 21.27,
	},
	12: {
		combined: 41.58,
		hr: 26.58,
		hs: 19.92,
	},
	13: {
		combined: 41.46,
		hr: 26.85,
		hs: 18.77,
	},
	14: {
		combined: 40.64,
		hr: 27.07,
		hs: 17.79,
	},
	15: {
		combined: 40.6,
		hr: 27.27,
		hs: 16.93,
	},
	16: {
		combined: 39.94,
		hr: 27.13,
		hs: 16.19,
	},
	17: {
		combined: 38.76,
		hr: 27,
		hs: 15.53,
	},
	18: {
		combined: 38.28,
		hr: 26.89,
		hs: 14.94,
	},
	19: {
		combined: 37.84,
		hr: 26.79,
		hs: 14.42,
	},
	20: {
		combined: 37.45,
		hr: 26.7,
		hs: 13.95,
	},
	21: {
		combined: 36.14,
		hr: 27.33,
		hs: 13.76,
	},
	22: {
		combined: 34.5,
		hr: 26.09,
		hs: 13.14,
	},
	23: {
		combined: 33,
		hr: 24.96,
		hs: 12.57,
	},
	24: {
		combined: 31.63,
		hr: 23.92,
		hs: 12.04,
	},
	25: {
		combined: 31.96,
		hr: 24.56,
		hs: 11.96,
	},
	26: {
		combined: 30.73,
		hr: 23.62,
		hs: 11.5,
	},
	27: {
		combined: 29.59,
		hr: 22.74,
		hs: 11.07,
	},
	28: {
		combined: 28.54,
		hr: 21.93,
		hs: 10.68,
	},
	29: {
		combined: 27.55,
		hr: 21.17,
		hs: 10.31,
	},
	30: {
		combined: 27.97,
		hr: 21.63,
		hs: 10.3,
	},
	31: {
		combined: 27.06,
		hr: 20.94,
		hs: 9.97,
	},
	32: {
		combined: 26.22,
		hr: 20.28,
		hs: 9.66,
	},
	33: {
		combined: 25.42,
		hr: 19.67,
		hs: 9.36,
	},
	34: {
		combined: 24.68,
		hr: 19.09,
		hs: 9.09,
	},
	35: {
		combined: 24.83,
		hr: 19.54,
		hs: 9.11,
	},
	36: {
		combined: 24.14,
		hr: 19,
		hs: 8.86,
	},
	37: {
		combined: 23.49,
		hr: 18.49,
		hs: 8.62,
	},
	38: {
		combined: 22.87,
		hr: 18,
		hs: 8.39,
	},
	39: {
		combined: 22.28,
		hr: 17.54,
		hs: 8.18,
	},
	40: {
		combined: 22.48,
		hr: 17.85,
		hs: 8.23,
	},
	41: {
		combined: 21.93,
		hr: 17.41,
		hs: 8.02,
	},
	42: {
		combined: 21.4,
		hr: 17,
		hs: 7.83,
	},
	43: {
		combined: 20.91,
		hr: 16.6,
		hs: 7.65,
	},
	44: {
		combined: 20.43,
		hr: 16.23,
		hs: 7.48,
	},
	45: {
		combined: 20.64,
		hr: 16.53,
		hs: 7.53,
	},
	46: {
		combined: 20.2,
		hr: 16.17,
		hs: 7.37,
	},
	47: {
		combined: 19.77,
		hr: 15.83,
		hs: 7.21,
	},
	48: {
		combined: 19.35,
		hr: 15.5,
		hs: 7.06,
	},
	49: {
		combined: 18.96,
		hr: 15.18,
		hs: 6.92,
	},
	50: {
		combined: 19.58,
		hr: 15.88,
		hs: 6.98,
	},
	51: {
		combined: 19.2,
		hr: 15.57,
		hs: 6.84,
	},
	52: {
		combined: 18.83,
		hr: 15.27,
		hs: 6.71,
	},
	53: {
		combined: 18.47,
		hr: 14.98,
		hs: 6.58,
	},
	54: {
		combined: 18.13,
		hr: 14.7,
		hs: 6.46,
	},
	55: {
		combined: 17.8,
		hr: 14.44,
		hs: 6.35,
	},
	56: {
		combined: 17.48,
		hr: 14.18,
		hs: 6.23,
	},
	57: {
		combined: 17.18,
		hr: 13.93,
		hs: 6.12,
	},
	58: {
		combined: 16.88,
		hr: 13.69,
		hs: 6.02,
	},
	59: {
		combined: 16.59,
		hr: 13.46,
		hs: 5.92,
	},
	60: {
		combined: 17.15,
		hr: 14.07,
		hs: 5.98,
	},
	61: {
		combined: 16.87,
		hr: 13.84,
		hs: 5.89,
	},
	62: {
		combined: 16.6,
		hr: 13.61,
		hs: 5.79,
	},
	63: {
		combined: 16.33,
		hr: 13.4,
		hs: 5.7,
	},
	64: {
		combined: 16.08,
		hr: 13.19,
		hs: 5.61,
	},
	65: {
		combined: 15.83,
		hr: 12.98,
		hs: 5.52,
	},
	66: {
		combined: 15.59,
		hr: 12.79,
		hs: 5.44,
	},
	67: {
		combined: 15.36,
		hr: 12.6,
		hs: 5.36,
	},
	68: {
		combined: 15.13,
		hr: 12.41,
		hs: 5.28,
	},
	69: {
		combined: 14.91,
		hr: 12.23,
		hs: 5.2,
	},
	70: {
		combined: 15.41,
		hr: 12.7,
		hs: 5.27,
	},
	71: {
		combined: 15.2,
		hr: 12.52,
		hs: 5.2,
	},
	72: {
		combined: 14.99,
		hr: 12.35,
		hs: 5.13,
	},
	73: {
		combined: 14.78,
		hr: 12.18,
		hs: 5.05,
	},
	74: {
		combined: 14.58,
		hr: 12.01,
		hs: 4.99,
	},
	75: {
		combined: 14.39,
		hr: 11.85,
		hs: 4.92,
	},
	76: {
		combined: 14.2,
		hr: 11.7,
		hs: 4.86,
	},
	77: {
		combined: 14.01,
		hr: 11.55,
		hs: 4.79,
	},
	78: {
		combined: 13.83,
		hr: 11.4,
		hs: 4.73,
	},
	79: {
		combined: 13.66,
		hr: 11.25,
		hs: 4.67,
	},
	80: {
		combined: 14.11,
		hr: 11.68,
		hs: 4.74,
	},
	81: {
		combined: 13.94,
		hr: 11.53,
		hs: 4.68,
	},
	82: {
		combined: 13.77,
		hr: 11.39,
		hs: 4.62,
	},
	83: {
		combined: 13.6,
		hr: 11.25,
		hs: 4.57,
	},
	84: {
		combined: 13.44,
		hr: 11.12,
		hs: 4.51,
	},
	85: {
		combined: 13.28,
		hr: 10.99,
		hs: 4.46,
	},
	86: {
		combined: 13.13,
		hr: 10.86,
		hs: 4.41,
	},
	87: {
		combined: 12.98,
		hr: 10.74,
		hs: 4.36,
	},
	88: {
		combined: 12.83,
		hr: 10.61,
		hs: 4.31,
	},
	89: {
		combined: 12.69,
		hr: 10.49,
		hs: 4.26,
	},
	90: {
		combined: 12.99,
		hr: 10.82,
		hs: 4.32,
	},
	91: {
		combined: 12.85,
		hr: 10.7,
		hs: 4.27,
	},
	92: {
		combined: 12.71,
		hr: 10.59,
		hs: 4.23,
	},
	93: {
		combined: 12.57,
		hr: 10.47,
		hs: 4.18,
	},
	94: {
		combined: 12.44,
		hr: 10.36,
		hs: 4.14,
	},
	95: {
		combined: 12.31,
		hr: 10.25,
		hs: 4.09,
	},
	96: {
		combined: 12.18,
		hr: 10.15,
		hs: 4.05,
	},
	97: {
		combined: 12.05,
		hr: 10.04,
		hs: 4.01,
	},
	98: {
		combined: 11.93,
		hr: 9.94,
		hs: 3.97,
	},
	99: {
		combined: 11.81,
		hr: 9.84,
		hs: 3.93,
	},
};
