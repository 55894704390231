import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// eslint-disable-next-line arrow-body-style
const TabItem = ({ item, tabId }) => {
	return (
		<>
			<input
				type="radio"
				id={`contacts-${tabId}`}
				name="contact-tabs"
				defaultChecked={tabId === 'canada-tab' ? 'checked' : ''}
			/>

			<label
				htmlFor={`contacts-${tabId}`}
				className={clsx(
					'w-full flex items-center justify-center md:flex-col',
					'relative py-2 text-sm border-1 border-blue-300',
					'transition-colors motion-reduce:transition-none',
					'outline-none'
				)}
			>
				<span className="flex flex-row items-center text-xl font-centra-medium">
					{item.tabIcon && item.tabIcon}
					{item.tabTitle}
				</span>
				<small className="block ml-4 text-sm md:ml-0">
					{item.tabSubTitle}
				</small>
			</label>
		</>
	);
};

export { TabItem };

TabItem.propTypes = {
	item: PropTypes.shape({
		tabTitle: PropTypes.string.isRequired,
		tabSubTitle: PropTypes.string,
		tabIcon: PropTypes.node,
	}).isRequired,
	tabId: PropTypes.string.isRequired,
};
