/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { DrawerProvider } from '../../../contexts/drawer-context';
import { ScreenResizerProvider } from '../../../contexts/screen-resize-context';
import { MarketoProvider } from '../../../contexts/marketo-context';

import { MasterLayout } from '../../master';

import { Header } from '../../../components/organisms/header-faq';
import { HeaderCA } from '../../../components/organisms/header-ca';
import { Footer } from '../../../components/organisms/footer';

import { getLocale } from '../../../utils/locale';

import { Newsletter } from '../../../components/molecules/newsletter';

const DefaultLayout = ({
	children,
	className,
	siteSettings,
	navigation,
	footerData,
	pageName,
	defaultLocale,
}) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			datoCmsSiteSpecificSetting {
				sideNavigationOpenCopy
				sideNavigationCloseCopy
				signInCopy
				footerAddressTitle
				footerSocialTitle
				footerInternationalSitesCopy
			}
		}
	`);

	const globalSiteSettings = {
		...siteSettings,
	};

	const headerContent = {
		...data.datoCmsSiteSpecificSetting,
		...navigation,
	};

	const footerContent = {
		...data.datoCmsSiteSpecificSetting,
		...footerData,
	};

	const location = useLocation();
	const { pathname } = location;

	const [localeCA, setLocaleCA] = useState(defaultLocale);

	useEffect(() => {
		setLocaleCA(getLocale(pathname));
	}, [pathname]);

	return (
		<ScreenResizerProvider>
			<DrawerProvider>
				<MarketoProvider>
					<MasterLayout>
						{localeCA === 'ca' ? (
							<HeaderCA
								siteTitle={data.site.siteMetadata.title}
								headerContent={headerContent}
								siteSettings={globalSiteSettings}
								pageName={pageName}
							/>
						) : (
							<Header
								siteTitle={data.site.siteMetadata.title}
								headerContent={headerContent}
								siteSettings={globalSiteSettings}
								pageName={pageName}
							/>
						)}
						<main id="main" className={className}>
							{children}
						</main>
						<Newsletter />
						<Footer
							pageName={pageName}
							footerContent={footerContent}
							siteTitle={data.site.siteMetadata.title}
						/>
					</MasterLayout>
				</MarketoProvider>
			</DrawerProvider>
		</ScreenResizerProvider>
	);
};

export const query = graphql`
	fragment siteSettings on DatoCmsSiteSpecificSetting {
		phoneNumber
	}
	fragment navItems on DatoCmsSiteSpecificSetting {
		phoneNumber
		primaryNavigation {
			title
			link
			treeChildren {
				title
				subText
				link
				icon
				position
				locale
			}
		}
		sideNavigation {
			title
			link
		}
	}
	fragment footerItems on DatoCmsSiteSpecificSetting {
		footerAddress
		footerNavigation {
			treeChildren {
				title
				link
				position
			}
			title
		}

		footerInternationalSites {
			title
			link
		}
		footerCopyright
	}
`;

DefaultLayout.defaultProps = {
	className: '',
	pageName: '',
	siteSettings: '',
	navigation: '',
	footerData: '',
	defaultLocale: 'en',
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	pageName: PropTypes.string,
	siteSettings: PropTypes.string,
	navigation: PropTypes.string,
	footerData: PropTypes.string,
	defaultLocale: PropTypes.string,
};

export { DefaultLayout };
