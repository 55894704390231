import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { MegaMenu } from '../../molecules/mega-menu';
import { NavLink } from '../../atoms/nav-link';
import { Backdrop } from '../../atoms/backdrop';

import { sendTrackingData } from '../../../utils/index';

import * as navStyles from './index.module.css';

export const NavDesktopVariants = {
	Default: navStyles.megalist,
	Unset: '',
};

const NavDesktop = ({
	links,
	drawerOpen,
	megaMenuVariant,
	...navAttributes
}) => {
	const linkRefs = useRef({});
	const megaRefs = useRef({});
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [hiddenMega, setHiddenMega] = useState(true);

	const toggleOpen = (index) => {
		setSelectedIndex(index);

		const megaRef = megaRefs.current[index];
		const linkRef = linkRefs.current[index];
		const siblingLinkRef = linkRefs.current[index + 1];

		if (selectedIndex === index) {
			setSelectedIndex(null);
			setHiddenMega(true);
			document.body.classList.remove('overflow-hidden');
		} else {
			setHiddenMega(false);
			document.body.classList.add('overflow-hidden');
			const firstFocusableElement =
				megaRef.querySelectorAll('button, a')[0];
			const focusableContent = megaRef.querySelectorAll('button, a');
			const lastFocusableElement =
				focusableContent[focusableContent.length - 1];

			document.addEventListener('keydown', (e) => {
				if (e.key === 'Escape') {
					setSelectedIndex(null);
					setHiddenMega(true);
					linkRef.focus();
				}

				if (e.shiftKey) {
					if (document.activeElement === firstFocusableElement) {
						if (e.key !== 'Enter') {
							linkRef.focus();
							e.preventDefault();
						}
					}
				} else if (document.activeElement === lastFocusableElement) {
					if (e.key !== 'Enter') {
						siblingLinkRef.focus();
						e.preventDefault();
					}
				}
			});

			firstFocusableElement.focus();
		}
	};

	useEffect(() => {
		if (drawerOpen) {
			setSelectedIndex(null);
			setHiddenMega(true);
			document.body.classList.remove('overflow-hidden');
		}
	}, [drawerOpen]);

	const handleClickTracking = (clickTitle) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'open section',
			click_type: 'link',
			click_text: clickTitle,
			click_title: clickTitle,
		});
	};

	return (
		<nav {...navAttributes}>
			<ul className={clsx('flex', navStyles.navdesktop)}>
				{links.map((link, index) => (
					<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
						{link.treeChildren && link.treeChildren.length > 0 ? (
							<>
								<NavLink
									className="relative"
									as="button"
									// eslint-disable-next-line no-return-assign
									ref={(ref) =>
										(linkRefs.current[index] = ref)
									}
									onClick={() => {
										toggleOpen(index);
										handleClickTracking(link.title);
									}}
									aria-expanded={selectedIndex === index}
									hasMegaMenu
								>
									{link.title}
								</NavLink>
								<MegaMenu
									aria-label={`${link.title} menu`}
									hidden={
										selectedIndex !== index || hiddenMega
									}
									// eslint-disable-next-line no-return-assign
									ref={(ref) =>
										(megaRefs.current[index] = ref)
									}
									className={navStyles.megamenu}
									listClassName={clsx(
										megaMenuVariant === 'Unset'
											? null
											: NavDesktopVariants.Default
									)}
									links={link.treeChildren}
									backdrop={
										<Backdrop
											onClick={() => {
												setSelectedIndex(null);
												setHiddenMega(true);
												document.body.classList.remove(
													'overflow-hidden'
												);
											}}
										/>
									}
								/>
							</>
						) : (
							<NavLink
								// eslint-disable-next-line no-return-assign
								ref={(ref) => (linkRefs.current[index] = ref)}
								to={link.link}
							>
								{link.title}
							</NavLink>
						)}
					</li>
				))}
			</ul>
		</nav>
	);
};

export { NavDesktop };

NavDesktop.defaultProps = {
	megaMenuVariant: 'Default',
};

NavDesktop.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			link: PropTypes.string,
			treeChildren: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subText: PropTypes.string.isRequired,
					link: PropTypes.string.isRequired,
					icon: PropTypes.string.isRequired,
				})
			),
		})
	).isRequired,
	drawerOpen: PropTypes.bool.isRequired,
	megaMenuVariant: PropTypes.oneOf(['Default', 'Unset']),
};
