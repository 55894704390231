import React from 'react';
import PropTypes from 'prop-types';
import { vapCalculatedProp, refProp } from '../../../types';
import FullCoverIE from './FullCoverIE';

const VapCardIE = ({ priceCalculated, drawerRef, showLargeBusiness }) => (
	<section className="lg:px-4 lg:pr-7">
		<FullCoverIE
			priceCalculated={priceCalculated}
			drawerRef={drawerRef}
			showLargeBusiness={showLargeBusiness}
		/>
	</section>
);

VapCardIE.defaultProps = {
	priceCalculated: {
		drafting_contract: '-',
		drafting_policy: '-',
		hs_docs: '-',
		fine_contract: '-',
		lateness_absence: '-',
		staff_turnover: '-',
		general_hr: '-',
		hs_fines: '-',
		average_tribunal: '-',
		total_cost: '-',
		annual_cost: '-',
		cost_per_employee: '-',
		annual_roi: '-',
	},
};

VapCardIE.propTypes = {
	priceCalculated: vapCalculatedProp,
	drawerRef: refProp.isRequired,
	showLargeBusiness: PropTypes.bool.isRequired,
};

export { VapCardIE };
