import clsx from 'clsx';
import { Field, FormikProvider, useFormik } from 'formik';
import loadScript from 'load-script2';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from '@reach/router';
import { Button } from '../../../atoms/button';
import { CallUs } from '../../../atoms/call-us';
import { PBSLink } from '../../../atoms/link';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { TextInput } from '../../../atoms/text-input';

import { getCookie, sendTrackingData, sleep } from '../../../../utils';
import { validateString } from '../../../../utils/marketo';

import { useMarketo } from '../../../../contexts/marketo-context';
import { useFormValidation } from '../../../../hooks/form-validation';
import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import useCountdown from '../../../../hooks/useCountdown';
import usePrivacyLink from '../../../../hooks/usePrivacyLink';
import { getLocale } from '../../../../utils/locale';

const WebinarMoreInfoForm = ({ pageUrl, dateAndTime, setFormResponse }) => {
	const MktoForms2Instances = useMarketo();
	const location = useLocation();
	const locale = getLocale(location.pathname);
	const { privacyLink } = usePrivacyLink(locale);
	const initialValidations = {
		reasonforregistering: { isValid: true },
		Number_Of_Employees__c: { isValid: true },
		Questionsontopic: { isValid: true },
	};
	if (locale === 'ie') {
		setFormResponse(true);
		return null;
	}
	const formId = 6650;

	const [technicalError, setTechnicalError] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);

	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [msclkidCookie, setMsclkidCookie] = useState('');
	const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [gclidLastCookie, setGclidLastCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');

	const title = 'Submit';
	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const reasonforregistering = useRef(null);
	const numberofEmployees = useRef(null);
	const Questionsontopic = useRef(null);

	const initialValues = {
		reasonforregistering: '',
		Number_Of_Employees__c: '',
		Questionsontopic: '',
		PersonSource:
			locale === 'ie'
				? 'Website - ROI Confirm Webinar Web Lead'
				: 'Website - Confirm Webinar Web Lead',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		MSCLKID__c: msclkidCookie,
		MSCLKID_last: msclkidLastCookie,
		GCLID__c: gclidCookie,
		gCLID_last: gclidLastCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const submitHandler = async (event) => {
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) return;

		const payloadData = {
			...initialValues,
			reasonforregistering: formEntries.reasonforregistering,
			Number_of_Employees__c: formEntries.Number_of_Employees__c,
			questionsontopic: formEntries.Questionsontopic,
		};

		await sleep(500);
		handleClickTracking();
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setFormResponse(true);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
			});
	};

	const { hasExpired } = useCountdown(dateAndTime);

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-lon04.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-lon04.marketo.com'
					).loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setMsclkidCookie(getCookie('msclkid'));
		setMsclkidLastCookie(getCookie('msclkid_last'));
		setGclidCookie(getCookie('gclid'));
		setGclidLastCookie(getCookie('gclid_last'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);

	useEffect(() => {
		if (
			!validations.reasonforregistering.isValid &&
			reasonforregistering.current
		) {
			return reasonforregistering.current.focus();
		}
		if (!validations.Questionsontopic.isValid && Questionsontopic.current) {
			return Questionsontopic.current.focus();
		}
		if (
			!validations.Number_Of_Employees__c.isValid &&
			numberofEmployees.current
		) {
			return numberofEmployees.current.focus();
		}
	}, [validations]);

	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto overflow-hidden grow"
			>
				<div className="flex flex-col grow">
					<fieldset className="flex flex-col bg-white grow">
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<Field
							name="reasonforregistering"
							validate={validateString}
						>
							{({ field }) => (
								<div>
									<label
										htmlFor="reasonforregistering"
										className="text-brand-blue-400 font-centra-medium"
									>
										Why did you decide to join this
										webcast&#42;
									</label>
									<TextInput
										id="reasonforregistering"
										name="reasonforregistering"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={reasonforregistering}
										showValidation={
											!validations.reasonforregistering
												.isValid &&
											!reasonforregistering.current.value
										}
										validationMessage={
											!validations.reasonforregistering
												.isValid
												? 'Please enter your reason'
												: null
										}
										{...field}
										space={false}
										placeholder="Type your reason here..."
										validateOnBlur
									/>
								</div>
							)}
						</Field>

						<Field
							name="Number_Of_Employees__c"
							validate={validateString}
						>
							{({ field }) => (
								<div>
									<label
										htmlFor="Number_Of_Employees__c"
										className="text-brand-blue-400 font-centra-medium"
									>
										How many employees do you have?&#42;
									</label>
									<TextInput
										id="Number_Of_Employees__c"
										name="Number_Of_Employees__c"
										type="number"
										min="1"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={numberofEmployees}
										showValidation={
											!validations.Number_Of_Employees__c
												.isValid &&
											!numberofEmployees.current.value
										}
										validationMessage="Please enter a number"
										{...field}
										space={false}
										validateOnBlur
									/>
								</div>
							)}
						</Field>

						<Field
							name="Questionsontopic"
							validate={validateString}
						>
							{({ field }) => (
								<div>
									<label
										htmlFor="Questionsontopic"
										className="text-brand-blue-400 font-centra-medium"
									>
										Is there a question you want to ask the
										host if there is time?&#42;
									</label>
									<TextInput
										id="Questionsontopic"
										name="Questionsontopic"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={Questionsontopic}
										showValidation={
											!validations.Questionsontopic
												.isValid &&
											!Questionsontopic.current.value
										}
										validationMessage="Please enter a question"
										{...field}
										space={false}
										placeholder="Type your question here..."
										validateOnBlur
									/>
								</div>
							)}
						</Field>
					</fieldset>
				</div>

				<div className="flex flex-col items-center pt-3 pb-4 bg-white lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
							disabled={hasExpired}
						>
							<span
								className={clsx(
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{title}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
					<p className="px-4 pt-4 text-center bg-white lg:text-base-f lg:pt-0">
						View our{' '}
						<PBSLink to={privacyLink} variant="Link" size="Small">
							privacy notice
						</PBSLink>
						.
					</p>
				</div>
			</form>
		</FormikProvider>
	);
};

WebinarMoreInfoForm.defaultProps = {
	setFormResponse: undefined,
	setConsentResponse: undefined,
};

WebinarMoreInfoForm.propTypes = {
	setConsentResponse: PropTypes.func,
	pageUrl: PropTypes.string.isRequired,
	dateAndTime: PropTypes.string.isRequired,
	setFormResponse: PropTypes.func,
};

export { WebinarMoreInfoForm };
