import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTabs } from '../../../contexts/tabs-context';

const TabPanel = ({ children, tabId, tabPanelId, tabPanelIndex }) => {
	const tabPanelRef = useRef(null);
	const { activeIndex, setActiveTabPanel } = useTabs();
	const isActive = activeIndex === tabPanelIndex;

	useEffect(() => {
		if (tabPanelRef.current && isActive) {
			setActiveTabPanel(tabPanelRef.current);
		}
	}, [isActive, setActiveTabPanel]);

	return (
		<section
			ref={tabPanelRef}
			id={tabPanelId}
			role="tabpanel"
			aria-labelledby={tabId}
			tabIndex="-1"
			className={clsx(
				'grid-cols-12 gap-5 outline-none',
				'lg:container lg:mx-auto focus:shadow-focus',
				isActive ? 'grid' : 'hidden'
			)}
		>
			{children}
		</section>
	);
};

export { TabPanel };

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	tabId: PropTypes.string.isRequired,
	tabPanelId: PropTypes.string.isRequired,
	tabPanelIndex: PropTypes.number.isRequired,
};
