import React, { useEffect, useState, useRef, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { useDrawer } from '../../../contexts/drawer-context';
import { useScreenResizer } from '../../../contexts/screen-resize-context';

import { CallUs } from '../../atoms/call-us';
import { Hamburger } from '../../atoms/hamburger';
import { Logo } from '../../atoms/logo';
import { SideNav } from '../../molecules/side-nav-v2';

import { sendTrackingData } from '../../../utils';

const shortLocale = {
	en: 'en',
	'en-IE': 'ie',
	'en-CA': 'ca',
};

const Header = ({
	siteTitle,
	isStorybook,
	headerContent,
	siteSettings,
	mobileOnlyHamburger,
	homepageUrl,
	locale,
	gluLayout,
}) => {
	const { drawerRef, setDrawerRef } = useDrawer();
	const navRef = useRef(null);
	const { width } = useScreenResizer();
	const [sideLinks, setSideLinks] = useState([]);

	const primaryNavElementCheck = useStaticQuery(graphql`
		query primaryNavElementCheck {
			allDatoCmsGluWebinarsPage {
				nodes {
					locale
				}
			}
			allDatoCmsGluClientPage(sort: { fields: originalId, order: ASC }) {
				nodes {
					slug
					locale
					title
				}
			}
			allDatoCmsGluGossip {
				nodes {
					locale
				}
			}
		}
	`);

	const {
		allDatoCmsGluClientPage: clientPages,
		allDatoCmsGluWebinarsPage: webinarsPage,
		// allDatoCmsGluGossip: gossipPage,
	} = primaryNavElementCheck;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const primaryNavigationArray = [];

	const clientPagesForLocale = clientPages.nodes.filter(
		(node) => node.locale === locale
	);

	clientPagesForLocale.forEach((page) => {
		const primaryNavigationObject = {
			title: page.title,
			link:
				locale === 'en'
					? `/elearning/${page.slug}`
					: `/${shortLocale[locale]}/elearning/${page.slug}`,
			treeChildren: [],
		};
		primaryNavigationArray.push(primaryNavigationObject);
	});

	webinarsPage.nodes.forEach((page) => {
		if (page.locale !== locale) {
			return;
		}
		const primaryNavigationObject = {
			title: 'Webinars',
			link:
				locale === 'en'
					? '/elearning/webinars'
					: `/${shortLocale[locale]}/elearning/webinars`,
			treeChildren: [],
		};
		primaryNavigationArray.push(primaryNavigationObject);
	});
	// Bring back wehn ready to release via proxy
	// gossipPage.nodes.forEach((page) => {
	// 	if (page.locale !== locale) {
	// 		return;
	// 	}
	// 	const primaryNavigationObject = {
	// 		title: 'Gossip',
	// 		link:
	// 			locale === 'en'
	// 				? '/elearning/gossip'
	// 				: `/${shortLocale[locale]}/elearning/gossip`,
	// 		treeChildren: [],
	// 	};
	// 	primaryNavigationArray.push(primaryNavigationObject);
	// });

	const gluHeaderContent = useMemo(
		() => ({
			primaryNavigation: primaryNavigationArray,
			sideNavigationCloseCopy: 'Close',
			sideNavigationOpenCopy: 'Menu',
		}),
		[primaryNavigationArray]
	);
	const headerContentObj = gluLayout ? gluHeaderContent : headerContent;

	if (!isStorybook) {
		useEffect(() => {
			if (width > 1024) {
				setSideLinks(headerContentObj.sideNavigation);
			} else {
				setSideLinks([
					headerContentObj.primaryNavigation,
					headerContentObj.sideNavigation,
				]);
			}
		}, [width, headerContentObj]);
	}

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', trackData);
	};

	return (
		<header className="bg-white">
			<section className="relative flex items-center justify-between w-full gap-2 p-4 mx-auto max-w-m-screen">
				<div className="flex items-center lg:py-6 header-left">
					<Logo
						width={126}
						height={36}
						title={siteTitle}
						url={homepageUrl}
					/>
				</div>
				<div className="flex items-center header-right">
					<CallUs
						number={siteSettings.phoneNumber}
						className={`pr-2 mr-2 text-right ${
							!mobileOnlyHamburger ? '' : 'lg:border-none'
						} md:mr-6 md:pr-6 border-r border-brand-pale-500 focus:outline-0 focus:shadow-focus focus:rounded-xs`}
						onClickProp={() =>
							handleClickTracking({
								event_name: 'navigation',
								click_type: 'tel',
							})
						}
					/>
					<div
						className={`${
							!mobileOnlyHamburger ? 'block' : 'lg:hidden'
						}`}
					>
						<Hamburger
							label={headerContentObj?.sideNavigationOpenCopy}
							activeLabel={
								headerContentObj?.sideNavigationCloseCopy
							}
							labelVisibleMobile={false}
							isOpen={drawerRef === navRef}
							onClick={() => {
								setDrawerRef(navRef);
								handleClickTracking({
									event_name: 'navigation',
									click_action: 'open menu',
									click_type: 'icon',
									click_text: 'Menu',
								});
							}}
							className="relative focus:outline-0 focus:shadow-focus focus:rounded-xs"
						/>
					</div>
				</div>
			</section>
			<SideNav
				navRef={navRef}
				links={
					isStorybook ? headerContentObj?.sideNavigation : sideLinks
				}
				signInCopy={headerContentObj?.signInCopy}
			/>
		</header>
	);
};

Header.defaultProps = {
	siteTitle: 'Peninsula Homepage',
	isStorybook: false,
	mobileOnlyHamburger: false,
	homepageUrl: '/',
	siteSettings: {},
	locale: 'en',
	gluLayout: false,
	headerContent: null,
};

Header.propTypes = {
	siteTitle: PropTypes.string,
	isStorybook: PropTypes.bool,
	headerContent: PropTypes.shape({
		primaryNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						subText: PropTypes.string.isRequired,
						link: PropTypes.string.isRequired,
						icon: PropTypes.string.isRequired,
					})
				),
			})
		).isRequired,
		sideNavigation: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			),
			PropTypes.arrayOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						link: PropTypes.string,
						treeChildren: PropTypes.arrayOf(
							PropTypes.shape({
								title: PropTypes.string.isRequired,
								subText: PropTypes.string.isRequired,
								link: PropTypes.string.isRequired,
								icon: PropTypes.string.isRequired,
							})
						),
					})
				),
				PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				)
			),
		]),
		sideNavigationOpenCopy: PropTypes.string.isRequired,
		sideNavigationCloseCopy: PropTypes.string.isRequired,
		signInCopy: PropTypes.string,
	}),
	mobileOnlyHamburger: PropTypes.bool,
	homepageUrl: PropTypes.string,
	siteSettings: PropTypes.objectOf(PropTypes.string),
	locale: PropTypes.string,
	gluLayout: PropTypes.bool,
};

export { Header };
