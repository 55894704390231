// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { pricingCardProps, refProp } from '../../../types';
import { Title } from '../../atoms/title';
import { VapCalculatorUK } from '../../molecules/vap-calculator-uk';
import { VapCardUK } from '../../molecules/vap-card-uk';
import { TabList, TabPanel, Tabs } from '../tabs';

const VapSectionUK = ({ pricingContent, drawerRef, locale }) => {
	const [priceCalculated, setPriceCalculated] = useState({
		lateness_absence: '-',
		staff_turnover: '-',
		drafting_policy: '-',
		drafting_contract: '-',
		hs_docs: '-',
		general_hr: '-',
		cost_accidents: '-',
		average_tribunal: '-',
		cost_hr_manager: '-',
		cost_hs_manager: '-',
		average_back_pain: '-',
		total_cost: '-',
		total_cost_hs: '-',
		total_cost_es: '-',
		annual_cost: '-',
		annual_cost_hs: '-',
		annual_cost_es: '-',
		cost_per_employee: '-',
		cost_per_employee_hs: '-',
		cost_per_employee_es: '-',
		annual_roi: '-',
		annual_roi_hs: '-',
		annual_roi_es: '-',
	});
	const [employeeCount, setEmployeeCount] = useState(0);
	const [showLargeBusiness, setShowLargeBusiness] = useState(false);

	const { title, subtitle } = pricingContent;
	const [show, setShow] = useState(false);
	const [parent] = useAutoAnimate({
		animate: true,
		duration: 300,
		easing: 'ease-in-out',
	});
	const hrTabs = [
		{
			name: 'Fully Peninsula Covered',
			short: 'fully-covered',
		},
		{
			name: 'Health & Safety Services Covered',
			short: 'health-safety',
		},
		{
			name: 'Employment Services Covered',
			short: 'employment-services',
		},
	];
	const refinedCountryTabs = hrTabs.map((tab) => ({
		...tab,
		tabTitle: tab.name,
	}));
	return (
		<section className="relative w-full">
			<div ref={parent} className="px-3 py-16 mx-auto max-w-m-screen">
				<Title title={title} id="vap-calc" />
				<VapCalculatorUK
					title={title}
					subtitle={subtitle}
					setPriceCalculated={setPriceCalculated}
					setEmployeeCount={setEmployeeCount}
					setShowLargeBusiness={setShowLargeBusiness}
					setShow={setShow}
				/>

				{show && (
					<Tabs tabsTitle="List" className="mb-10 lg:mb-0">
						<div className="mb-16 lg:container lg:mx-auto">
							<TabList
								options={refinedCountryTabs}
								className="rounded-t-sm rounded-b-sm shadow-slight md:rounded-l-lg md:rounded-r-lg"
							/>
						</div>
						{refinedCountryTabs.map((panel, index) => {
							const tabId = panel.tabTitle
								.replaceAll(' ', '-')
								.toLowerCase();

							return (
								<TabPanel
									key={tabId}
									tabId={`${tabId}-tab`}
									tabPanelId={`${tabId}-panel`}
									tabPanelIndex={index}
								>
									<section className="col-span-12 lg:col-span-12">
										<VapCardUK
											panel={panel.short}
											priceCalculated={priceCalculated}
											employeeCount={employeeCount}
											drawerRef={drawerRef}
											showLargeBusiness={
												showLargeBusiness
											}
											locale={locale}
										/>
									</section>
								</TabPanel>
							);
						})}
					</Tabs>
				)}
			</div>
		</section>
	);
};

VapSectionUK.propTypes = {
	pricingContent: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		cards: PropTypes.arrayOf(pricingCardProps),
	}).isRequired,
	drawerRef: refProp.isRequired,
	locale: PropTypes.string.isRequired,
};

export { VapSectionUK };
