import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// import { useLocation } from '@reach/router';
import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { useDrawer } from '../../../contexts/drawer-context';

import { Drawer } from '../../atoms/drawer';
import { NavLink } from '../../atoms/nav-link-ca';

import { NavMobile } from '../../organisms/nav-mobile';

import { refProp } from '../../../types';

const SideNav = ({ links, navRef }) => {
	const { drawerRef } = useDrawer();
	const [isDesktop, setIsDesktop] = useState(null);
	const [secondaryLinks, setSecondaryLinks] = useState([]);
	const { width } = useScreenResizer();

	useEffect(() => {
		if (width > 1024) {
			setIsDesktop(true);
		} else {
			setIsDesktop(false);
		}
	}, [width]);

	useEffect(() => {
		if (isDesktop === false) {
			setSecondaryLinks(links[1]);
		} else {
			setSecondaryLinks(links);
		}
	}, [isDesktop, links]);

	const renderSecondaryLinks = () => {
		if (!secondaryLinks || secondaryLinks.length === 0) return null;

		const resourcesGroup = secondaryLinks.slice(0, 7);
		const companyGroup = secondaryLinks.slice(7);

		return (
			<nav aria-label="Secondary menu">
				<ul>
					{/* Resources group: first 6 items */}
					<div className="flex items-center pb-2 ml-4">
						<p className="text-xs text-blue-300">Resources</p>
						<div className="flex-grow ml-4 border-blue-300 border-b-1" />
					</div>
					{resourcesGroup.map((link) => (
						<li key={link.link} className="mb-3">
							<NavLink
								to={link.link}
								className={clsx(
									'!block font-centra-book',
									isDesktop
										? 'text-xl'
										: 'text-lg rounded-none px-8'
								)}
							>
								{link.title}
							</NavLink>
						</li>
					))}

					{/* Company group: Remaining items */}
					<div className="flex items-center pb-2 ml-4">
						<p className="text-xs text-blue-300">Company</p>
						<div className="flex-grow ml-4 border-blue-300 border-b-1" />
					</div>
					{companyGroup.map((link) => (
						<li key={link.link} className="mb-3">
							<NavLink
								to={link.link}
								className={clsx(
									'!block font-centra-book',
									isDesktop
										? 'text-xl'
										: 'text-lg rounded-none px-8'
								)}
							>
								{link.title}
							</NavLink>
						</li>
					))}
				</ul>
			</nav>
		);
	};

	return links ? (
		<Drawer
			label="More Menu"
			ref={navRef}
			open={drawerRef === navRef}
			elevation={20}
			hasClose
		>
			<div className={clsx(isDesktop ? 'px-10' : null)}>
				{isDesktop === false && links[0] && links[0].length > 0 ? (
					<>
						<NavMobile aria-label="Main menu" links={links[0]} />
						<hr className="mx-4 mb-6 border-brand-pale-400" />
					</>
				) : null}

				{/* Render the secondary links in 2 groups: Resources and Company */}
				{renderSecondaryLinks()}
			</div>
		</Drawer>
	) : null;
};

SideNav.defaultProps = {
	links: [],
	// signInCopy: '',
};

SideNav.propTypes = {
	links: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
			})
		),
		PropTypes.arrayOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					link: PropTypes.string,
					treeChildren: PropTypes.arrayOf(
						PropTypes.shape({
							title: PropTypes.string.isRequired,
							subText: PropTypes.string.isRequired,
							link: PropTypes.string.isRequired,
							icon: PropTypes.string.isRequired,
						})
					),
				})
			),
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			)
		),
		PropTypes.array,
	]),
	// signInCopy: PropTypes.string,
	navRef: refProp.isRequired,
};

export { SideNav };
