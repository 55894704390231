import React from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ModalWrapper = ({ children, fullBlur }) => (
	<GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
		<div
			className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center w-full h-full overflow-hidden"
			style={{ background: fullBlur ? 'white' : 'rgba(29, 41, 81, 0.8)' }}
		>
			{children}
		</div>
	</GoogleReCaptchaProvider>
);
export default ModalWrapper;
ModalWrapper.defaultProps = {
	fullBlur: false,
};

ModalWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	fullBlur: PropTypes.bool,
};
