import React from 'react';
import PropTypes from 'prop-types';

const MasterLayout = ({ children }) => <>{children}</>;

export { MasterLayout };

MasterLayout.propTypes = {
	children: PropTypes.node.isRequired,
};
