import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useLocation } from '@reach/router';
import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { useDrawer } from '../../../contexts/drawer-context';

import { Drawer } from '../../atoms/drawer';
import { NavLink } from '../../atoms/nav-link';

import { NavMobile } from '../../organisms/nav-mobile';

import { refProp } from '../../../types';
import { getLocale } from '../../../utils/locale';

const SideNav = ({ links, navRef }) => {
	const { drawerRef } = useDrawer();
	const [isDesktop, setIsDesktop] = useState(null);
	const [secondaryLinks, setSecondaryLinks] = useState([]);
	const { width } = useScreenResizer();
	const location = useLocation();
	const locale = getLocale(location.pathname);

	useEffect(() => {
		if (width > 1024) {
			setIsDesktop(true);
		} else {
			setIsDesktop(false);
		}
	}, [width]);

	useEffect(() => {
		if (isDesktop === false) {
			setSecondaryLinks(links[1]);
		} else {
			setSecondaryLinks(links);
		}
	}, [isDesktop, links]);
	// const location = useLocation();
	// const locale = getLocale(location.pathname);
	return links ? (
		<Drawer
			label="More Menu"
			ref={navRef}
			open={drawerRef === navRef}
			elevation={20}
			hasClose
		>
			<div className={clsx(isDesktop ? 'px-10' : null)}>
				{isDesktop === false && links[0] && links[0].length > 0 ? (
					<>
						<NavMobile aria-label="Main menu" links={links[0]} />
						<hr className="mx-4 mb-6 border-brand-pale-400" />
					</>
				) : null}
				{locale !== 'ca' ? (
					<NavMobile
						aria-label="Main menu"
						links={secondaryLinks}
						listClassName="!text-5xl !block !font-centra-medium"
					/>
				) : (
					<nav aria-label="Secondary menu">
						<ul>
							{secondaryLinks && secondaryLinks.length > 0
								? secondaryLinks.map((link) => (
										<li key={link.link} className="mb-2">
											<NavLink
												to={link.link}
												className={clsx(
													'!block font-centra-book',
													isDesktop
														? 'text-2xl'
														: 'text-lg rounded-none px-8'
												)}
											>
												{link.title}
											</NavLink>
										</li>
								  ))
								: null}
						</ul>
					</nav>
				)}
				{/* <hr
					className={clsx(
						'mt-4 border-brand-pale-400',
						!isDesktop ? 'mx-4' : null
					)}
				/>
				<PBSLink
					variant="White"
					className={clsx(
						'!block mt-8 mb-10 text-center',
						!isDesktop ? 'mx-4' : null
					)}
				>
					{signInCopy}
				</PBSLink> */}
			</div>
		</Drawer>
	) : null;
};

SideNav.defaultProps = {
	links: [],
	signInCopy: '',
};

SideNav.propTypes = {
	links: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
			})
		),
		PropTypes.arrayOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					link: PropTypes.string,
					treeChildren: PropTypes.arrayOf(
						PropTypes.shape({
							title: PropTypes.string.isRequired,
							subText: PropTypes.string.isRequired,
							link: PropTypes.string.isRequired,
							icon: PropTypes.string.isRequired,
						})
					),
				})
			),
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			)
		),
		PropTypes.array,
	]),
	// eslint-disable-next-line react/no-unused-prop-types
	signInCopy: PropTypes.string,
	navRef: refProp.isRequired,
};

export { SideNav };
