import PropTypes from 'prop-types';

export const useRefProp = PropTypes.oneOfType([
	PropTypes.object,
	PropTypes.func,
	PropTypes.shape({ current: PropTypes.instanceOf(PropTypes.element) }),
]);

export const titleProp = PropTypes.arrayOf(
	PropTypes.shape({
		children: PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
				marks: PropTypes.arrayOf(PropTypes.string),
			})
		),
		type: PropTypes.string.isRequired,
	})
);

export const gatsbyImageProp = PropTypes.shape({
	images: PropTypes.shape({
		sources: PropTypes.arrayOf(PropTypes.string),
		fallback: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
	}),
	layout: PropTypes.string,
	backgroundColor: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
});

export const trustpilotReviewsProp = PropTypes.shape({
	id: PropTypes.string,
	reviews: PropTypes.arrayOf(
		PropTypes.shape({
			consumer: PropTypes.shape({
				displayName: PropTypes.string,
			}),
			createdAt: PropTypes.string,
			id: PropTypes.string,
			stars: PropTypes.number,
			text: PropTypes.string,
			title: PropTypes.string,
		})
	),
});

export const datoGatsbyImageProp = PropTypes.shape({
	url: PropTypes.string,
	gatsbyImageData: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
		placeholder: PropTypes.shape({
			fallback: PropTypes.string,
		}),
		images: PropTypes.shape({
			fallback: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
			sources: PropTypes.arrayOf(
				PropTypes.shape({
					srcSet: PropTypes.string,
					sizes: PropTypes.string,
					type: PropTypes.string,
				})
			),
		}),
		layout: PropTypes.string,
	}),
	alt: PropTypes.string,
});

export const pricingCardProps = PropTypes.shape({
	title: PropTypes.string.isRequired,
	prePriceCopy: PropTypes.string.isRequired,
	postPriceCopy: PropTypes.string.isRequired,
	tickListHeader: PropTypes.string.isRequired,
	tickList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
		})
	).isRequired,
});

export const priceCalculatedProp = PropTypes.shape({
	hr: PropTypes.string,
	hs: PropTypes.string,
	combined: PropTypes.string,
});

export const vapCalculatedProp = PropTypes.shape({
	lateness_absence: PropTypes.string,
	staff_turnover: PropTypes.string,
	hr_docs: PropTypes.string,
	hs_docs: PropTypes.string,
	disciplinary: PropTypes.string,
	ohs_fines: PropTypes.string,
	general_hr: PropTypes.string,
	cost_accidents: PropTypes.string,
	average_wrongful: PropTypes.string,
	cost_hr_manager: PropTypes.string,
	cost_hs_manager: PropTypes.string,
	total_cost: PropTypes.string,
	annual_cost: PropTypes.string,
	cost_per_employee: PropTypes.string,
	annual_roi: PropTypes.string,
	time_saved: PropTypes.string,
});

export const categoryCardProp = {
	title: PropTypes.string.isRequired,
	thumbnail: datoGatsbyImageProp.isRequired,
	index: PropTypes.number,
	onClickProp: PropTypes.func,
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		})
	).isRequired,
};

export const eventLocationProp = PropTypes.shape({
	address: PropTypes.shape({
		value: PropTypes.shape({
			schema: PropTypes.string,
			document: PropTypes.shape({
				type: PropTypes.string.isRequired,
				children: PropTypes.arrayOf(
					PropTypes.shape({
						type: PropTypes.string.isRequired,
						children: PropTypes.arrayOf(
							PropTypes.shape({
								type: PropTypes.string.isRequired,
								value: PropTypes.string,
							})
						),
					})
				),
			}),
		}),
	}),
	venueName: PropTypes.string.isRequired,
});

export const eventsCardProp = {
	className: PropTypes.string,
	as: PropTypes.string,
	to: PropTypes.string,
	onClickProp: PropTypes.func,
	location: PropTypes.string,
	loading: PropTypes.bool,
	grow: PropTypes.bool,
	cardContent: PropTypes.shape({
		index: PropTypes.number,
		id: PropTypes.string,
		slug: PropTypes.string,
		eventDateTime: PropTypes.string,
		eventTime: PropTypes.string.isRequired,
		eventDate: PropTypes.string,
		eventLocation: eventLocationProp,
		shortDescription: PropTypes.string,
	}),
};

export const sectorCardProp = {
	className: PropTypes.string,
	onClickProp: PropTypes.func,
	cardContent: PropTypes.shape({
		id: PropTypes.string,
		slug: PropTypes.string,
		title: PropTypes.string,
		intro: PropTypes.string,
		alt: PropTypes.string,
		buttonText: PropTypes.string,
	}),
};

export const refProp = PropTypes.oneOfType([
	PropTypes.func,
	PropTypes.shape({
		current: PropTypes.instanceOf(
			typeof Element === 'undefined' ? PropTypes.func : Element
		),
	}),
]);

export const parallaxSlideProp = PropTypes.shape({
	title: PropTypes.string.isRequired,
	as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	subText: PropTypes.string.isRequired,
	image: datoGatsbyImageProp.isRequired,
	tickList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			subText: PropTypes.string,
		})
	),
	primaryLink: PropTypes.string.isRequired,
	primaryLinkCopy: PropTypes.string.isRequired,
	secondaryLink: PropTypes.string,
	secondaryLinkCopy: PropTypes.string,
});

export const parallaxSlideProp2 = PropTypes.shape({
	title: PropTypes.string.isRequired,
	as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	subText: PropTypes.string.isRequired,
	image: datoGatsbyImageProp.isRequired,
});

export const rteContent = PropTypes.arrayOf(
	PropTypes.shape({
		children: PropTypes.arrayOf(
			PropTypes.shape({
				type: PropTypes.string.isRequired,
				value: PropTypes.string,
				url: PropTypes.string,
				marks: PropTypes.arrayOf(PropTypes.string),
				children: PropTypes.arrayOf(
					PropTypes.shape({
						type: PropTypes.string.isRequired,
						value: PropTypes.string.isRequired,
					})
				),
			})
		).isRequired,
		type: PropTypes.string.isRequired,
	})
);

export const cardVariants = PropTypes.oneOf([
	'Blog',
	'Guide',
	'Download',
	'CaseStudy',
	'Events',
]);

export const structuredTextContent = PropTypes.shape({
	value: PropTypes.shape({
		document: PropTypes.shape({
			children: PropTypes.arrayOf(
				PropTypes.shape({
					type: PropTypes.string.isRequired,
					value: PropTypes.string,
					url: PropTypes.string,
					marks: PropTypes.arrayOf(PropTypes.string),
					children: PropTypes.arrayOf(
						PropTypes.shape({
							type: PropTypes.string,
							value: PropTypes.string,
						})
					),
				})
			).isRequired,
			type: PropTypes.string.isRequired,
		}),
	}),
	schema: PropTypes.string,
});

export const cardContents = PropTypes.shape({
	id: PropTypes.string.isRequired,
	downloadType: PropTypes.string,
	publishedDateOrdinal: PropTypes.string,
	publishedDate: PropTypes.string,
	thumbnail: PropTypes.shape({
		alt: PropTypes.string,
		gatsbyImageData: gatsbyImageProp.isRequired,
	}),
	title: PropTypes.string.isRequired,
	slug: PropTypes.string,
	intro: PropTypes.string,
	author: PropTypes.shape({
		image: PropTypes.shape({
			alt: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}),
		name: PropTypes.string,
		jobTitle: PropTypes.string,
	}),
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
		})
	),
	sectors: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
		})
	),
});

export const breadCrumbItems = PropTypes.arrayOf(
	PropTypes.shape({
		title: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		hierarchyPosition: PropTypes.number.isRequired,
	})
);
export const iCalEvent = {
	event: PropTypes.shape({
		location: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		startDateTime: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
	}).isRequired,
};

export const eventFormStepOneValues = PropTypes.shape({
	FirstName: PropTypes.string,
	LastName: PropTypes.string,
	Company: PropTypes.string,
	Title: PropTypes.string,
	Email: PropTypes.string,
	MobilePhone: PropTypes.string,
	Address: PropTypes.string,
	PostalCode: PropTypes.string,
});

export const eventFormStepOneErrors = PropTypes.shape({
	FirstName: PropTypes.bool,
	LastName: PropTypes.bool,
	Company: PropTypes.bool,
	Title: PropTypes.bool,
	Email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	MobilePhone: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	Address: PropTypes.bool,
	PostalCode: PropTypes.bool,
});

export const inlineRecordProp = PropTypes.shape({
	__typename: PropTypes.string,
	title: PropTypes.shape({
		value: PropTypes.shape({
			document: PropTypes.shape({
				titleProp,
			}),
		}),
	}),
	promoTitle: PropTypes.shape({
		value: PropTypes.shape({
			document: PropTypes.shape({
				titleProp,
			}),
		}),
	}),
	adviceFormTitle: PropTypes.string,
	newsletterSignUpTitle: PropTypes.string,
	ctaBannerTitle: PropTypes.string,
	adviceBannerContent: PropTypes.shape({
		content: PropTypes.string,
		ctaLabel: PropTypes.string,
		ctaLink: PropTypes.string,
		ctaOpenForm: PropTypes.bool,
	}),
	id: PropTypes.string,
	link: PropTypes.string,
	subText: PropTypes.string,
	linkCopy: PropTypes.string,
	buttonCopy: PropTypes.string,
	ctaOpenForm: PropTypes.bool,
	content: PropTypes.string,
	onClickProp: PropTypes.func,
	image: gatsbyImageProp,
});

export const speakerProp = PropTypes.shape({
	name: PropTypes.string,
	jobTitle: PropTypes.string,
	image: PropTypes.shape({
		url: PropTypes.string,
		alt: PropTypes.string,
	}),
	bio: PropTypes.shape({
		value: PropTypes.shape({
			document: PropTypes.shape({
				children: PropTypes.arrayOf(
					PropTypes.shape({
						type: PropTypes.string,
						value: PropTypes.string,
					})
				),
			}),
		}),
	}),
	facebookUrl: PropTypes.string,
	linkedinUrl: PropTypes.string,
	twitterUrl: PropTypes.string,
	emailAddress: PropTypes.string,
});

export const authorProp = PropTypes.shape({
	name: PropTypes.string,
	jobTitle: PropTypes.string,
	image: PropTypes.shape({
		url: PropTypes.string,
		alt: PropTypes.string,
	}),
});
