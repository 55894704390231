/**
 * Format a Date-compatible string or timestamp into a date string
 *
 * @param {string | number} datetime - A Date-compatible string or timestamp
 */
export const formatDate = (
	datetime,
	{ displayYear = true, displayWeekday = false } = {}
) =>
	new Date(datetime).toLocaleDateString('en-US', {
		month: 'short',
		day: 'numeric',
		...(displayYear && { year: 'numeric' }),
		...(displayWeekday && { weekday: 'short' }),
	});

export const formatAMPM = (date) => {
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	const strTime = `${hours}:${minutes} ${ampm}`;
	return strTime;
};

export const convertTo12HourFormat = (timeString) => {
	const match = timeString.match(/^(\d+)(:)?(\d+)?(\s)?(am|pm)?$/i);
	if (!match) {
		return 'Invalid time format';
	}

	let hour = parseInt(match[1], 10);
	const minute = match[3] ? parseInt(match[3], 10) : 0;
	const period = match[5] ? match[5].toLowerCase() : '';

	if (period === 'pm' && hour < 12) {
		hour += 12;
	} else if (period === 'am' && hour === 12) {
		hour = 0;
	}

	return `${hour.toString().padStart(2, '0')}:${minute
		.toString()
		.padStart(2, '0')}`;
};
