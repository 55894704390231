import React from 'react';
import PropTypes from 'prop-types';

function NoResultFound({ message, longMessage }) {
	return (
		<div className="flex flex-col items-center justify-center m-auto h-72">
			<h3 className="text-xl font-bold">{message}</h3>
			<p>{longMessage}</p>
		</div>
	);
}

export { NoResultFound };
NoResultFound.defaultProps = {
	message: 'No search results',
	longMessage:
		'No results matched this search. Try editing your search or look for something else.',
};
NoResultFound.propTypes = {
	message: PropTypes.string,
	longMessage: PropTypes.string,
};
