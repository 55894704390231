import React from 'react';

import { Button } from '../../atoms/button';
import { BhaPromoBanner } from '../../atoms/bha-promo-banner';
import { CtaBanner } from '../../atoms/cta-banner';
import { PBSLink } from '../../atoms/link';

import { Newsletter } from '../../molecules/newsletter';

import { AdviceBanner } from '../advice-banner';
import { AnyQuestions } from '../any-questions';

import { sendTrackingData } from '../../../utils';
import { refProp, inlineRecordProp } from '../../../types';

import { useDrawer } from '../../../contexts/drawer-context';

const InlineRecord = ({ record, drawerRef, buttonFormRef }) => {
	const { setDrawerRef } = useDrawer();

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			click_type: 'primary',
			...trackData,
		});
	};

	// eslint-disable-next-line no-underscore-dangle
	switch (record.__typename) {
		case 'DatoCmsAdviceBanner':
			return (
				<AdviceBanner
					adviceBannerContent={{
						...record,
						title: record.title.value.document.children,
					}}
					drawerRef={drawerRef}
					variant="Rte"
				/>
			);
		case 'DatoCmsBusinessHealthAssistantPromoBanner':
			return (
				<BhaPromoBanner
					title={record.promoTitle.value.document.children}
					linkCopy={record.linkCopy}
					id={record.id}
					content={record.content}
					onClickProp={handleClickTracking}
				/>
			);
		case 'DatoCmsNewsletterSignUp':
			return (
				<Newsletter
					title={record.newsletterSignUpTitle}
					subText={record.subText}
					variant="Rte"
				/>
			);
		case 'DatoCmsCtaBanner':
			return (
				<CtaBanner
					id={record.id}
					title={record.ctaBannerTitle}
					content={record.content}
					link={record.link}
					linkCopy={record.linkCopy}
					image={record.image}
					ctaOpenForm={record.ctaOpenForm}
					drawerRef={drawerRef}
					onClickProp={handleClickTracking}
				/>
			);
		case 'DatoCmsAdviceForm':
			return (
				<AnyQuestions
					title={record.adviceFormTitle}
					subText={record.subText}
					id={record.id}
				/>
			);
		case 'DatoCmsButton': {
			const buttonClick = () => {
				handleClickTracking({
					component_name: 'rte-content',
					click_text: record.buttonCopy,
				});
			};
			return record.link ? (
				<PBSLink
					className="!block !w-fit my-base-f md:my-lg-f"
					to={record.link}
					onClick={buttonClick}
				>
					{record.buttonCopy}
				</PBSLink>
			) : (
				<Button
					className="!block !w-fit my-base-f md:my-lg-f"
					onClick={() => {
						buttonClick();
						if (buttonFormRef) {
							setDrawerRef(buttonFormRef);
						}
					}}
				>
					{record.buttonCopy}
				</Button>
			);
		}
		default:
			return null;
	}
};

InlineRecord.defaultProps = {
	drawerRef: () => {},
	buttonFormRef: () => {},
};

InlineRecord.propTypes = {
	record: inlineRecordProp.isRequired,
	drawerRef: refProp,
	buttonFormRef: refProp,
};

export { InlineRecord };
