import React from 'react';
import PropTypes from 'prop-types';
import { shareContent } from '../../../../utils/share-content';

function BannerNav({ title }) {
	const hasShareContentSetup =
		typeof window !== 'undefined' ? !!navigator.share : '';
	const shareSocialLink =
		typeof window !== 'undefined' ? window.location : '';
	const content = {
		title: `From Peninsula Elearning`,
		text: `Check out this awesome content.`,
		url: shareSocialLink,
	};
	return (
		<div className="flex article-nav">
			<div className="flex mx-auto overflow-hidden max-w-m-screen whitespace-nowrap">
				<div className="py-4 overflow-hidden">
					{hasShareContentSetup && (
						<button
							type="button"
							onClick={() =>
								shareContent(hasShareContentSetup, content)
							}
							className="flex flex-row gap-3 hover:underline cursor-pointer overflow-visible text-white whitespace-nowrap inline-flex items-center"
						>
							<p className="text-white ">{title}</p>
							<svg
								width="27"
								height="26"
								viewBox="0 0 22 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.0687 19.6885C10.8219 19.6885 10.5751 19.6075 10.3669 19.4494C10.0738 19.2258 3.12555 13.8892 1.32485 10.5732C0.0770744 8.68399 -1.23044 4.4539 2.16929 1.66224C5.42365 -1.00989 8.71428 -0.0830148 11.0934 1.79866C12.8169 0.371979 15.6064 -0.932814 19.2354 1.01445C21.9408 2.46606 22.443 6.41157 21.6723 8.93829C20.7315 12.023 16.6944 15.4509 16.2355 15.8326C15.7459 16.2413 15.0171 16.1758 14.6084 15.6861C14.1996 15.1964 14.2652 14.4676 14.7549 14.055C15.85 13.1412 18.727 10.28 19.3555 8.2248C19.711 6.35391 19.7375 4.11652 18.0015 2.96938C14.8243 0.964326 12.1232 3.96664 12.0114 4.09388C11.8031 4.3368 11.4134 4.54133 11.0933 4.55289C10.7733 4.56446 10.4028 4.32138 10.1753 4.09388C7.19857 1.11714 4.88768 2.41799 3.63452 3.44751C2.83091 4.09388 1.59416 6.21632 3.3569 9.46655C4.51752 11.6066 8.81683 15.2735 11.0687 17.0626L12.129 16.2413C12.6342 15.848 13.3591 15.9406 13.7524 16.4457C14.1457 16.9508 14.0531 17.6757 13.548 18.069L11.7743 19.4456C11.5661 19.6075 11.3154 19.6885 11.0648 19.6885H11.0687Z"
									fill="white"
									className="hover:fill-brand-red-400 font-extrabold text-white"
								/>
							</svg>
						</button>
					)}
					{!hasShareContentSetup && (
						<a
							href={`mailto:?subject=${content.text} - ${content.title}&body=${shareSocialLink}`}
							target="_blank"
							className="inline-flex items-center text-[15px] font-extrabold gap-5 hover:underline"
							rel="noreferrer"
						>
							<p className="text-white ">{title}</p>
							<svg
								width="27"
								height="26"
								viewBox="0 0 22 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.0687 19.6885C10.8219 19.6885 10.5751 19.6075 10.3669 19.4494C10.0738 19.2258 3.12555 13.8892 1.32485 10.5732C0.0770744 8.68399 -1.23044 4.4539 2.16929 1.66224C5.42365 -1.00989 8.71428 -0.0830148 11.0934 1.79866C12.8169 0.371979 15.6064 -0.932814 19.2354 1.01445C21.9408 2.46606 22.443 6.41157 21.6723 8.93829C20.7315 12.023 16.6944 15.4509 16.2355 15.8326C15.7459 16.2413 15.0171 16.1758 14.6084 15.6861C14.1996 15.1964 14.2652 14.4676 14.7549 14.055C15.85 13.1412 18.727 10.28 19.3555 8.2248C19.711 6.35391 19.7375 4.11652 18.0015 2.96938C14.8243 0.964326 12.1232 3.96664 12.0114 4.09388C11.8031 4.3368 11.4134 4.54133 11.0933 4.55289C10.7733 4.56446 10.4028 4.32138 10.1753 4.09388C7.19857 1.11714 4.88768 2.41799 3.63452 3.44751C2.83091 4.09388 1.59416 6.21632 3.3569 9.46655C4.51752 11.6066 8.81683 15.2735 11.0687 17.0626L12.129 16.2413C12.6342 15.848 13.3591 15.9406 13.7524 16.4457C14.1457 16.9508 14.0531 17.6757 13.548 18.069L11.7743 19.4456C11.5661 19.6075 11.3154 19.6885 11.0648 19.6885H11.0687Z"
									fill="white"
									className="hover:fill-brand-red-400 font-extrabold text-white"
								/>
							</svg>
						</a>
					)}
				</div>
			</div>
		</div>
	);
}

export { BannerNav };

BannerNav.defaultProps = {
	title: '',
};

BannerNav.propTypes = {
	title: PropTypes.string,
};
