import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFormValidation } from '../../../hooks/form-validation';
import usePeninsulaNow from '../../../hooks/peninsula-now';
import { useSalesforceApi } from '../../../hooks/salesforce';
import { getCookie, sendTrackingData, sleep } from '../../../utils';
import { getLocale } from '../../../utils/locale';
import { Button } from '../../atoms/button';
import { RadioButton } from '../../atoms/radio-button';
import { TextInput } from '../../atoms/text-input';

const initialValidations = {
	isEmployer: { isValid: true },
	isClient: { isValid: true },
	emailAddress: { isValid: true },
	phoneNumber: { isValid: true },
	companyName: { isValid: true },
	noEmployees: { isValid: true },
	industryName: { isValid: true },
	issue: { isValid: true },
};
const PeninsulaModalForm = () => {
	const data = useStaticQuery(graphql`
		query PeninsulaNowQuery {
			datoCmsPeninsulaNow {
				id
				headerTitle
				subHeading
				title
				inTime
				notAnEmployer
				isAClient
				footerText
				checkListField {
					id
					originalId
					text
				}
				formTitle
			}
		}
	`);
	// const [parent] = useAutoAnimate({
	// 	animate: true,
	// 	duration: 300,
	// 	easing: 'linear',
	// });
	const { GISAPISAMPLES, setLog, checkClerkAvailability } = usePeninsulaNow();
	const { fetchSalesforce } = useSalesforceApi();
	const { executeRecaptcha } = useGoogleReCaptcha();
	// const [industry, setIndustry] = React.useState('');
	// const [isEmployer, setIsEmployer] = React.useState(null);
	const [isClient, setIsClient] = React.useState(null);
	const cmsData = data.datoCmsPeninsulaNow;
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const formRef = React.useRef(null);
	const emailAddressRef = React.useRef(null);
	const phoneNumberRef = React.useRef(null);
	// const isEmployerRef = React.useRef(null);
	const isClientRef = React.useRef(null);
	const companyNameRef = React.useRef(null);
	// const noEmployeesRef = React.useRef(null);
	// const industryNameRef = React.useRef(null);
	// const issueRef = React.useRef(null);
	const { validations, validateFormPeninsulaNow } =
		useFormValidation(initialValidations);

	// eslint-disable-next-line no-unused-vars
	const callEndedHandler = (_data) => {
		setLog('Emplifi: Call ended.');
		setTimeout(checkClerkAvailability, 3000);
	};
	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form-peninsula-now',
			click_type: 'primary',
			click_title: 'Peninsula Now - Qualified advisors online now',
			click_text: 'Connect to an advisor',
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: 'Peninsula Now - Qualified advisors online now',
		});
	};
	const pageUrl = useLocation().href;
	const locale = getLocale(useLocation().pathname);
	const startEmplifiCall = async () => {
		setLog('Emplifi: starting a call...');
		if (GISAPISAMPLES.BasicCallSample.onConnectClick()) {
			setLog('Emplifi: call started successfully');
		} else {
			setLog('Error : video api not enabled');
		}
	};
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();
		const { formEntries, isValid } = validateFormPeninsulaNow(
			event.currentTarget
		);
		if (!isValid) return;

		setSubmitting(true);
		const payloadData = {
			businessOwner: formEntries.isEmployer === 'yes',
			email: formEntries.emailAddress,
			phoneNumber: formEntries.phoneNumber,
			firstName: '',
			lastName: '',
			company: formEntries.companyName,
			numberOfEmployees: formEntries.noEmployees,
			industry: formEntries.industryName,
			description: formEntries.issue,
			leadSource: 'Website - Peninsula Now',
			locale: locale === '' ? 'en' : locale,
			pageUrl,
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
		};

		if (locale === '' && (locale !== 'ca' || locale !== 'ie')) {
			payloadData.utmCampaign = getCookie('utm_campaign');
			payloadData.utmMedium = getCookie('utm_medium');
			payloadData.utmSource = getCookie('utm_source');
			payloadData.utmTerm = getCookie('utm_term');
		}
		const response = await fetchSalesforce(
			executeRecaptcha,
			payloadData,
			'POST'
		);
		await sleep(500);
		startEmplifiCall();
		handleClickTracking();
		switch (response.status) {
			case 200:
				setLog('Success : Form submitted successfully');
				handleCallbackTracking();
				setIsSubmitted(true);
				break;
			case 403:
				setLog('Error : Forbidden');
				break;
			default:
				setLog('Error : Something went wrong');
				break;
		}

		setSubmitting(false);
	};
	React.useEffect(() => {
		if (!validations?.isClient?.isValid && isClientRef?.current) {
			return isClientRef.current.focus();
		}
		// if (!validations?.isEmployer?.isValid && isEmployerRef?.current) {
		// 	return isEmployerRef.current.focus();
		// }
		if (!validations.emailAddress.isValid && emailAddressRef.current) {
			return emailAddressRef.current.focus();
		}
		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
		if (!validations.companyName.isValid && companyNameRef.current) {
			return companyNameRef.current.focus();
		}

		// if (!validations.noEmployees.isValid && noEmployeesRef.current) {
		// 	return noEmployeesRef.current.focus();
		// }

		// if (!validations.industryName.isValid && industryNameRef.current) {
		// 	return industryNameRef.current.focus();
		// }

		// if (!validations.issue.isValid && issueRef.current) {
		// 	return issueRef.current.focus();
		// }
	}, [validations]);

	return isSubmitted ? null : (
		<div className="grid md:grid-cols-5 grid-cols-1 gap-4 w-full pt-7 md:pt-0 h-auto bg-brand-pale-400">
			<div className="flex md:order-first order-last col-span-2 bg-brand-pale-400 h-full flex-1 md:p-8 p-4">
				<div className="flex flex-col md:gap-12 gap-4">
					<div className="flex flex-col">
						<div className="md:text-4xl text-2xl text-black font-centra-medium">
							{cmsData.headerTitle}
						</div>
						<div className="md:text-lg text-sm text-black font-centra-medium font-bold flex inline-flex">
							{cmsData.subHeading}
							<span className="text-red md:pl-2 pl-1 underline underline-offset-2">
								{cmsData.inTime}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-7">
						<div className="text-black md:text-xl text-lg font-bold">
							{cmsData.title}
						</div>
						<div className="flex items-center gap-4">
							<div className="flex flex-col">
								{cmsData?.checkListField.map((item) => (
									<div
										key={item.id}
										className="font-castledown-bold text-lg last:pb-0 pb-5 flex items-center justify-start gap-2"
									>
										<div className=" inline-flex items-center space-x-3 justify-start">
											<svg
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 15 14"
												className={clsx(
													'flex items-center w-3 h-auto shrink-0 text-blue-400'
												)}
											>
												<path
													d="m1 7.8164 2.6542 3.6999c.2042.2997.5433.484.9104.4948.367.0107.7167-.1534.9388-.4406L14 1.0115"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>

											<span className="flex items-center">
												{item.text}
											</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col md:gap-4 gap-2 items-start col-span-3 w-full bg-white h-full flex-1 md:p-8 p-4 mb-10 md:-mt-0 -mt-6">
				<div className="flex items-center mt-10">
					<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
					<p className="text-black xl:text-2xl lg:text-xl text-lg font-bold">
						{cmsData.formTitle}
					</p>
				</div>
				<form ref={formRef} onSubmit={submitHandler} className="w-full">
					<div className="flex flex-col w-full">
						<fieldset
							className={clsx(
								'flex flex-col gap-5 grow my-3 space-y-3',
								!validations?.isClient?.isValid &&
									'border-alert-400 border-l-4 pl-4 ml-[-16px]'
							)}
						>
							<legend className=" text-brand-blue-400 xl:text-base-f text-lg font-centra-medium">
								Are you a Peninsula customer?
							</legend>

							<div className="flex flex-wrap gap-2 space-x-2 ">
								<>
									<RadioButton
										defaultChecked={isClient === 'yes'}
										labelText="Yes"
										id="isClient"
										name="isClient"
										ref={(node) => {
											isClientRef.current = node;
										}}
										value={
											isClient === 'yes' ? 'yes' : 'no'
										}
										onChange={() => {
											setIsClient('yes');
										}}
										showValidation={
											!validations?.isClient?.isValid
										}
									/>
								</>
								<>
									<RadioButton
										defaultChecked={isClient === 'no'}
										labelText="No"
										id="isClient"
										name="isClient"
										ref={(node) => {
											isClientRef.current = node;
										}}
										value={isClient === 'no' ? 'no' : 'yes'}
										onChange={() => {
											setIsClient('no');
										}}
										showValidation={
											!validations?.isClient?.isValid
										}
									/>
								</>
							</div>
							{!validations?.isClient?.isValid && (
								<p className="text-brand-red-500 pt-5">
									Please select an option
								</p>
							)}
						</fieldset>
						{isClient === 'no' && (
							<>
								{/* <fieldset
									className={clsx(
										'flex flex-col gap-5 grow my-3 space-y-3',
										!validations?.isEmployer?.isValid &&
											'border-alert-400 border-l-4 pl-4 ml-[-16px]'
									)}
								>
									<legend className=" text-brand-blue-400 xl:text-base-f text-lg font-centra-medium">
										Are you a business owner or employer?
									</legend>

									<div className="flex flex-wrap gap-2 space-x-2 ">
										<>
											<RadioButton
												defaultChecked={
													isEmployer === 'yes'
												}
												labelText="Yes"
												id="isEmployer"
												name="isEmployer"
												ref={(node) => {
													isEmployerRef.current =
														node;
												}}
												value={
													isEmployer === 'yes'
														? 'yes'
														: 'no'
												}
												onChange={() => {
													setIsEmployer('yes');
												}}
												showValidation={
													!validations?.isEmployer
														?.isValid
												}
											/>
										</>
										<>
											<RadioButton
												defaultChecked={
													isEmployer === 'no'
												}
												labelText="No"
												id="isEmployer"
												name="isEmployer"
												ref={(node) => {
													isEmployerRef.current =
														node;
												}}
												value={
													isClient === 'no'
														? 'no'
														: 'yes'
												}
												onChange={() => {
													setIsEmployer('no');
												}}
												showValidation={
													!validations?.isEmployer
														?.isValid
												}
											/>
										</>
									</div>
									{!validations?.isEmployer?.isValid && (
										<p className="text-brand-red-500 pt-5">
											Please select an option
										</p>
									)}
								</fieldset>
								{isEmployer !== 'no' && (
									<> */}
								<div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-0 w-full">
									<TextInput
										labelText="Your email address"
										id="emailAddress"
										name="emailAddress"
										aria-required="true"
										type="email"
										// disabled={!isOnline}
										className={clsx(
											'w-full wrapper-small lg:text-lg text-sm'
											// !isOnline && 'blur-sm'
										)}
										validationMessage="Please enter your email address"
										showValidation={
											!validations.emailAddress.isValid
										}
										ref={emailAddressRef}
									/>

									<TextInput
										labelText="Your phone number"
										id="phoneNumber"
										name="phoneNumber"
										aria-required="true"
										type="tel"
										className={clsx(
											'w-full wrapper-small lg:text-lg text-sm'
											// !isOnline && 'blur-sm'
										)}
										validationMessage="Please enter your phone number"
										showValidation={
											!validations.phoneNumber.isValid
										}
										ref={phoneNumberRef}
									/>
								</div>
								<TextInput
									labelText="Your company name"
									id="companyName"
									name="companyName"
									aria-required="true"
									// disabled={!isOnline}
									className={clsx(
										'w-full wrapper-small lg:text-lg pt-[-20px] text-sm'
										// !isOnline && 'blur-sm'
									)}
									validationMessage="Please enter your company name"
									showValidation={
										!validations.companyName.isValid
									}
									ref={companyNameRef}
								/>
								{/* <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-5 gap-0 justify-center">
							<div className="lg:col-span-1 col-span-3">
								<TextInput
									labelText="Employees"
									id="noEmployees"
									name="noEmployees"
									aria-required="true"
									className={clsx(
										'w-full wrapper-small lg:text-lg text-sm'
										// !isOnline && 'blur-sm'
									)}
									// disabled={!isOnline}
									validationMessage="Please no of employees"
									showValidation={
										!validations.noEmployees.isValid
									}
									ref={noEmployeesRef}
									type="number"
									min="1"
								/>
							</div>
							<div className="lg:col-span-2 col-span-3">
								<Select
									labelText="Industry"
									name="industryName"
									id="industryName"
									value={industry}
									onChange={(e) =>
										setIndustry(e.currentTarget.value)
									}
									className={clsx(
										'w-full wrapper-small lg:text-lg text-sm'
										// !isOnline && 'blur-sm'
									)}
									aria-required="true"
									// disabled={!isOnline}
									placeholder="Please make a selection"
									options={[
										{
											value: 'agriculture',
											label: 'Agriculture',
										},
										{
											value: 'automotive',
											label: 'Automotive',
										},
										{
											value: 'beauty_hair',
											label: 'Beauty & hair',
										},
										{
											value: 'childcare',
											label: 'Childcare',
										},
										{
											value: 'construction',
											label: 'Construction',
										},
										{
											value: 'dentists',
											label: 'Dentists',
										},
										{
											value: 'education',
											label: 'Education',
										},
										{
											value: 'finance_insurance',
											label: 'Finance & insurance',
										},
										{
											value: 'health_medical',
											label: 'Health & medical',
										},
										{
											value: 'hospitality',
											label: 'Hospitality',
										},
										{
											value: 'household_services',
											label: 'Household services',
										},
										{
											value: 'manufacturing',
											label: 'Manufacturing',
										},
										{
											value: 'professional_services',
											label: 'Professional Services',
										},
										{
											value: 'real_estate',
											label: 'Real estate',
										},
										{
											value: 'retail_wholesale',
											label: 'Retail & Wholesale',
										},
										{
											value: 'sports_recreation',
											label: 'Sports & recreation',
										},
										{
											value: 'transportation_storage',
											label: 'Transportation & storage',
										},
										{
											value: 'vets_pets',
											label: 'Vets & Pets',
										},
										{
											value: 'other',
											label: 'Other',
										},
									]}
									showValidation={
										!validations.industryName.isValid
									}
									validationMessage="Please make a selection"
									ref={industryNameRef}
									// required
								/>
							</div>
						</div>
						<Textarea
							labelText="Describe your issue"
							id="issue"
							name="issue"
							// disabled={!isOnline}
							aria-required="true"
							validationMessage="Please enter your issue"
							showValidation={!validations.issue.isValid}
							ref={issueRef}
							rows={2}
							maxLength={500}
							// className={clsx(!isOnline && 'blur-sm')}
						/> */}
							</>
						)}
						{/* </>
						)} */}

						<div className={clsx('py-4')}>
							{/* {isEmployer === 'no' && isClient !== 'yes' && (
								<div className="flex flex-col space-y-3">
									<p className="text-2xl font-bold flex-wrap flex ">
										{cmsData.notAnEmployer}
									</p>
								</div>
							)}
*/}
							{isClient === 'yes' && (
								<div className="flex flex-col space-y-3">
									<p className="text-2xl font-bold flex-wrap flex ">
										{cmsData.isAClient}
									</p>
								</div>
							)}

							{isClient === 'no' && (
								<>
									<div className="wrapper-small">
										<Button
											id="call-button"
											disabled={submitting}
											type="submit"
											className={clsx(
												submitting &&
													'justify-center loading'
											)}
										>
											<span
												className={clsx(
													submitting
														? 'invisible'
														: null
												)}
											>
												Connect to an advisor
											</span>

											{submitting ? (
												<span
													className="sr-only"
													aria-live="assertive"
												>
													Submitting your enquiry,
													please wait...
												</span>
											) : null}
										</Button>
									</div>
									<p className=" font-castledown-bold mt-10">
										{cmsData.footerText}
									</p>
								</>
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export { PeninsulaModalForm };
