// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Spinner from '../../assets/spinner.inline.svg';
import ArticleNav from '../../components/glu/organisms/article-nav';
import DoubleTextBlock from '../../components/glu/organisms/double-text-block';
import HeroWithVideo from '../../components/glu/organisms/hero-with-video';
import PageSection from '../../components/glu/organisms/page-section';
import ServiceCardContainer from '../../components/glu/organisms/service-card-container';
import SessionCardContainer from '../../components/glu/organisms/session-card-container';
import TiltedCardContainer from '../../components/glu/organisms/tilted-card';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import { Head } from '../../components/templates/head';
import useFindCookie from '../../hooks/useCookie';
import { GLULayout } from '../../layouts/glu';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../../utils/url-helpers';
import WebinarModal from '../../components/glu/atoms/GLU-home-pop-up';

const Homepage = ({ data, location }) => {
	const {
		eLearningSessionCards,
		hero: heroContent,
		intro,
		eLearningTitle,
		liveSessionTitle,
		liveWebinarSessions,
		otherServicesTitle,
		otherServices,
		locale,
	} = data.datoCmsGluHomepage;
	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { nodes: gluBanner } = data.allDatoCmsGluBanner;
	const { nodes: liveWebinars } = data.allDatoCmsGluCourse;
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];
	const metaContent = {
		noIndex: data.datoCmsGluHomepage.noIndex,
		noFollow: data.datoCmsGluHomepage.noFollow,
		metaInformation: data.datoCmsGluHomepage.metaInformation,
		fallbackTitle: data.datoCmsGluHomepage.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl: data.datoCmsGluHomepage?.canonicalUrl || location?.href,
	};
	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.gluHome,
			location,
		}),
		image: getImageSeoSchema(mainLogo?.url),
	};

	const sortedELearningSessionCards = eLearningSessionCards.sort(
		(a, b) => a.originalId - b.originalId
	);

	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			{locale === 'en' && <WebinarModal />}
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{locale === 'en' && articles.length > 0 && (
				<ArticleNav
					articleData={articles}
					locale={locale}
					bannerData={gluBanner}
				/>
			)}

			{locale === 'en-IE' && liveWebinars.length > 0 && (
				<ArticleNav articleData={liveWebinars} locale={locale} />
			)}
			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							<HeroWithVideo
								hero={heroContent}
								logo={mainLogo}
								privateVideoHash="9ace720501"
								locale={locale}
							/>
							<DoubleTextBlock
								textBlocks={intro.doubleTextBlock[0]}
							/>
							<PageSection
								titleBlock={eLearningTitle.value}
								logo={mainLogo}
							>
								<TiltedCardContainer
									cards={sortedELearningSessionCards}
									locale={locale}
								/>
							</PageSection>
							<PageSection
								theme="White"
								titleBlock={liveSessionTitle.value}
								logo={mainLogo}
							>
								<SessionCardContainer
									liveWebinarSessions={liveWebinarSessions}
									locale={locale}
								/>
							</PageSection>
							<PageSection
								theme="Dark"
								titleBlock={otherServicesTitle.value}
							>
								<ServiceCardContainer cards={otherServices} />
							</PageSection>
						</>
					) : (
						<div className="flex flex-row justify-center py-10  items-center w-full">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
								/>
							</div>
						</div>
					)}
				</>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedHomepage = ({ data, location }) => (
	<GLULayout locale={data.datoCmsGluHomepage.locale}>
		<Homepage data={data} location={location} />
	</GLULayout>
);
export default WrappedHomepage;

export const query = graphql`
	query gluHomepage($locale: String!) {
		datoCmsGluHomepage(locale: { eq: $locale }) {
			locale
			internal {
				type
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			intro {
				doubleTextBlock {
					firstBlock {
						value
					}
					secondBlock {
						value
					}
				}
			}
			eLearningTitle {
				value
			}
			liveWebinarSessions {
				highlightedLiveWebinar {
					title
					dateAndTime
					slug
				}
				image {
					gatsbyImageData(placeholder: DOMINANT_COLOR)
					alt
					url
				}
				upcomingLiveWebinars {
					title
					dateAndTime
					slug
				}
			}
			liveSessionTitle {
				value
			}
			eLearningSessionCards {
				id
				originalId
				title
				image {
					gatsbyImageData
					alt
				}
				description {
					value
				}
				ctaLink
				ctaText
			}
			hero {
				vimeoLink {
					thumbnailUrl
					width
					height
					providerUid
					provider
					title
				}
				videoOverlayText
				trustpilotReview
				mainTitle {
					value
				}
				mainSubheading {
					value
				}
			}
			otherServices {
				title
				image {
					gatsbyImageData
					alt
				}
				ctaLink
				ctaText
				content {
					value
				}
				videoLinks {
					id
					title
					vimeoLink {
						thumbnailUrl
						width
						height
						providerUid
						provider
						title
					}
				}
			}
			otherServicesTitle {
				value
			}
		}
		allDatoCmsGluBanner(filter: { locale: { eq: $locale } }) {
			nodes {
				link
				id
				title
				viewTitle
				enableFlashing
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
		allDatoCmsGluCourse(
			filter: {
				locale: { eq: $locale }
				category: { slug: { ne: null } }
			}
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
		) {
			nodes {
				title
				slug
				category {
					slug
				}
				id
			}
		}
		allDatoCmsGluLogo {
			nodes {
				mainLogo {
					url
					alt
				}
			}
		}
	}
`;
