import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import { HeadingTagLine } from '../heading-tag-line';
import { PBSLink } from '../link';

import { titleProp } from '../../../types';
import { getMultiParagraphText } from '../../../utils';

const BhaPromoBanner = ({ id, title, linkCopy, content, onClickProp }) => {
	const contentArr = content.split('\n');
	const to = 'https://businesshealthassistant.peninsulagrouplimited.com/';
	const paragraphClassNames =
		'text-lg md:text-xl text-white mb-3 last-of-type:mb-0';

	const trackingTitle = (title || [{ children: [] }])[0].children
		.map(({ value }) => value)
		.join('');

	return (
		<article className="sm:grid sm:gap-10 lg:gap-20 sm:grid-cols-[1fr_fit-content(35%)] px-4 md:px-7 lg:px-10 rounded-sm bg-brand-blue-400 my-14">
			<div className="flex flex-col items-center justify-center py-8 text-center sm:items-start sm:text-left">
				<HeadingTagLine
					id={id}
					title={title}
					headingClassNames="text-white sm:text-left !text-2xl md:!text-3xl mb-6"
					headingHighlightClassNames="text-white brightness-0 invert"
				/>
				{getMultiParagraphText(contentArr, paragraphClassNames, id)}
				<PBSLink
					className="mt-7 w-fit hover:!bg-brand-blue-500"
					to={to}
					onClick={() =>
						onClickProp({
							click_text: linkCopy,
							click_title: trackingTitle,
							component_name: 'bha-promo-banner',
						})
					}
				>
					{linkCopy}
				</PBSLink>
			</div>

			<div className="flex flex-col items-center justify-end lg:px-4 lg:pt-7">
				<StaticImage
					src="../../../assets/triage.png"
					alt="Peninsula Business Health Assistant"
					objectFit="contain"
					className="w-[200px] lg:w-[254px]"
					quality={100}
				/>
			</div>
		</article>
	);
};

export { BhaPromoBanner };

BhaPromoBanner.defaultProps = {
	onClickProp: () => {},
};

BhaPromoBanner.propTypes = {
	title: titleProp.isRequired,
	id: PropTypes.string.isRequired,
	linkCopy: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	onClickProp: PropTypes.func,
};
