import React from 'react';
import PropTypes from 'prop-types';
import { PBSLink } from '../link';

import CalendarIcon from '../../../assets/calendar.inline.svg';

import { iCalEvent } from '../../../types';
import { makeIcsFile } from '../../../utils/make-ics';

const AddToCalendar = ({ event, variant, className, shortDescription }) => {
	const { startDateTime, description, title, location, endDateTime } = event;

	const url = makeIcsFile({
		location,
		startDateTime,
		endDateTime,
		description,
		title,
		shortDescription,
	});

	return (
		<PBSLink
			download="event.ics"
			to={url}
			variant={variant}
			className={className}
		>
			<CalendarIcon className="w-[19px] md:w-[22px] mr-3" />
			Add to calendar
		</PBSLink>
	);
};

AddToCalendar.defaultProps = {
	className: undefined,
	variant: undefined,
	shortDescription: undefined,
};

AddToCalendar.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
	shortDescription: PropTypes.string,
	...iCalEvent,
};

export { AddToCalendar };
