import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useLocation } from '@reach/router';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { PBSLink } from '../../atoms/link';
import { Button } from '../../atoms/button';
import { CallUs } from '../../atoms/call-us';

import { titleProp, refProp } from '../../../types';

import { useDrawer } from '../../../contexts/drawer-context';

import { sendTrackingData } from '../../../utils';
import { getLocale, buildLink } from '../../../utils/locale';

const AdviceBanner = ({
	adviceBannerContent,
	drawerRef,
	variant,
	className,
	openModal,
	peninsulaNow,
	pageName,
}) => {
	const { title, content, ctaLabel, ctaLink, ctaOpenForm } =
		adviceBannerContent;
	const { setDrawerRef } = useDrawer();
	const location = useLocation();
	const locale = getLocale(location.pathname);

	const trackingTitle = (title || [{ children: [] }])[0].children
		.map(({ value }) => value)
		.join('');

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-banner',
			click_type: 'primary',
			...trackData,
		});
	};

	let cta;
	if (ctaLink) {
		cta = <PBSLink to={buildLink(locale, ctaLink)}>{ctaLabel}</PBSLink>;
	} else if (ctaOpenForm) {
		cta = (
			<Button
				onClick={() => {
					if (
						locale === '' &&
						peninsulaNow &&
						pageName === 'childService'
					) {
						openModal();
					} else {
						setDrawerRef(drawerRef);
					}
					handleClickTracking({
						click_title: trackingTitle,
						click_text: ctaLabel,
					});
				}}
			>
				{ctaLabel}
			</Button>
		);
	}

	return (
		<section
			aria-labelledby="adviceBanner"
			className={clsx(
				'bg-brand-pale-400',
				variant === 'Rte'
					? 'border-blue-200 border-2 rounded-sm my-14'
					: 'border-b-4 md:border-b-6 border-b-blue-400',
				className
			)}
		>
			<div
				className={clsx(
					'max-w-[850px] mx-auto',
					variant === 'Rte' ? 'p-8' : 'py-xl-f px-xs-f'
				)}
			>
				<HeadingTagLine
					id="adviceBanner"
					title={title}
					subText={content}
					headingClassNames={
						variant === 'Rte' ? '!text-2xl md:!text-3xl' : null
					}
					subTextClassNames={
						variant === 'Rte'
							? '!text-lg md:!text-xl !font-centra-book'
							: null
					}
				/>
				<div className="flex flex-col items-center justify-center md:flex-row mt-7">
					{cta}
					<hr
						aria-hidden="true"
						className="my-2 md:h-full md:min-h-[60px] md:mx-7 md:border-l-1 md:border-l-blue-300"
					/>
					<CallUs
						altMobile
						onClickProp={(trackData) =>
							handleClickTracking({
								click_type: 'tel',
								...trackData,
							})
						}
					/>
				</div>
			</div>
		</section>
	);
};

export { AdviceBanner };

AdviceBanner.defaultProps = {
	variant: '',
	className: '',
	openModal: false,
	peninsulaNow: false,
	pageName: '',
};

AdviceBanner.propTypes = {
	adviceBannerContent: PropTypes.shape({
		title: titleProp.isRequired,
		content: PropTypes.string.isRequired,
		ctaLabel: PropTypes.string.isRequired,
		ctaLink: PropTypes.string,
		ctaOpenForm: PropTypes.bool,
	}).isRequired,
	drawerRef: refProp.isRequired,
	variant: PropTypes.string,
	className: PropTypes.string,
	openModal: PropTypes.bool,
	peninsulaNow: PropTypes.bool,
	pageName: PropTypes.string,
};
