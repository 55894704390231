import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import * as radioButtonStyles from './index.module.css';

export const ButtonVariants = {
	Pill: 'rounded-md',
	Rect: 'rounded-xs',
};

const RadioButton = forwardRef(
	(
		{
			labelText,
			onChange,
			id,
			name,
			className,
			variant,
			...inputAttributes
		},
		ref
	) => (
		<div className={clsx('relative inline-block', className)}>
			<input
				className={clsx(
					'absolute top-0 left-0 w-full h-full bg-transparent cursor-pointer appearance-none outline-none peer',
					radioButtonStyles.radio
				)}
				type="radio"
				onChange={onChange}
				id={id}
				name={name}
				ref={ref}
				{...inputAttributes}
			/>
			<label
				className={clsx(
					'block px-6 py-4 text-sm transition-colors duration-200 bg-white border-1 border-brand-pale-500 rounded-md shadow-answer text-brand-blue-400 motion-reduce:transition-none',
					ButtonVariants[variant]
				)}
				htmlFor={id}
			>
				{labelText}
			</label>
		</div>
	)
);

RadioButton.defaultProps = {
	onChange: () => {},
	className: '',
	variant: 'Pill',
};

RadioButton.propTypes = {
	labelText: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	variant: PropTypes.string,
};

export { RadioButton };
