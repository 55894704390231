import React from 'react';

// eslint-disable-next-line react/prop-types
export const VapCheckbox = ({ label, onChange, idx, isChecked }) => {
	const toggleCheckbox = () => {
		onChange(idx);
	};

	return (
		<div className="flex items-center">
			<svg
				viewBox="0 0 50 60"
				className="w-[50px] h-[50px] ml-5 cursor-pointer"
				onClick={(e) => toggleCheckbox(e)}
			>
				<rect
					x="0.5"
					y="0.5"
					width="39"
					height="41"
					rx="3.5"
					fill="white"
					stroke="#9CAAF0"
				/>
				{isChecked && (
					<path
						transform="translate(8,9)"
						d="M20 6L9 17L4 12"
						stroke="#3954E0"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						fill="none"
					/>
				)}
			</svg>
			<label
				htmlFor={label}
				className="mb-5 font-centra-regular max-w-[240px] sm:max-w-xl md:max-w-md lg:max-w-md lg:text-[17px] xl:max-w-md text-lg text-black text-left"
			>
				{label}
			</label>
		</div>
	);
};
