import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik, Field, FormikProvider } from 'formik';
import clsx from 'clsx';
import loadScript from 'load-script2';
import { DateTime } from 'luxon';

import { Button } from '../../../atoms/button';
import { TextInput } from '../../../atoms/text-input';
import { CallUs } from '../../../atoms/call-us';
import { SidebarHeader } from '../../../atoms/sidebar-header';
import { PBSLink } from '../../../atoms/link';

import { sleep, sendTrackingData, getCookie } from '../../../../utils';
import {
	validateString,
	validateEmail,
	validatePhoneNumber,
} from '../../../../utils/marketo';

import { useMarketoFormSubmit } from '../../../../hooks/marketo';
import { useMarketo } from '../../../../contexts/marketo-context';
import CountdownTimer from '../../molecules/countdown-timer';
import useCountdown from '../../../../hooks/useCountdown';
import { useFormValidation } from '../../../../hooks/form-validation';

const WebinarSignupFormCA = ({
	pageUrl,
	dateAndTime,
	setFormResponse,
	timeZone,
	formId,
}) => {
	const MktoForms2Instances = useMarketo();

	const initialValidations = {
		FirstName: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
	};

	const timeZonedDateAndTime = DateTime.fromISO(dateAndTime, {
		zone: timeZone,
	});

	const [technicalError, setTechnicalError] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);

	const [referralCookie, setReferralCookie] = useState('');
	const [keywordCookie, setKeywordCookie] = useState('');
	const [mediumCookie, setMediumCookie] = useState('');
	const [campaignCookie, setCampaignCookie] = useState('');
	const [gclidCookie, setGclidCookie] = useState('');
	const [campaignLastCookie, setCampaignLastCookie] = useState('');
	const [contentLastCookie, setContentLastCookie] = useState('');
	const [mediumLastCookie, setMediumLastCookie] = useState('');
	const [sourceLastCookie, setSourceLastCookie] = useState('');
	const [termLastCookie, setTermLastCookie] = useState('');
	const [googleCookie, setGoogleCookie] = useState('');
	const [socialId, setSocialId] = useState('');

	const title = 'Reserve your spot';

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const urlObj = new URL(pageUrl);
		setSocialId(urlObj.searchParams.get('social_id'));
	}, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: title,
		});
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
		PersonSource: 'Website - Confirm Webinar Web Lead',
		referalSource: referralCookie,
		keyword: keywordCookie,
		utm_medium: mediumCookie,
		utm_campaign: campaignCookie,
		GCLID__c: gclidCookie,
		utm_campaign_last__c: campaignLastCookie,
		utm_content_last__c: contentLastCookie,
		utm_medium_last__c: mediumLastCookie,
		utm_source_last__c: sourceLastCookie,
		utm_term_last__c: termLastCookie,
		Google_Cookie_ID__c: googleCookie,
		socialCampaignID: socialId,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const submitHandler = async (event) => {
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		const payloadData = {
			...initialValues,
			FirstName: formEntries.FirstName,
			LastName: formEntries.LastName,
			Phone: formEntries.Phone,
			Email: formEntries.Email,
			Company: formEntries.Company,
			PersonSource: 'Website - Confirm Webinar Web Lead',
		};

		await sleep(500);
		handleClickTracking();
		useMarketoFormSubmit(payloadData, formId, MktoForms2Instances)
			.then(() => {
				formik.setStatus('submitted');
				setFormResponse(true);
			})
			.catch(() => {
				formik.setStatus('error');
				setTechnicalError(true);
			})
			.finally(() => {
				handleClickTracking();
			});
	};

	const { hasExpired } = useCountdown(timeZonedDateAndTime);

	useEffect(() => {
		const loadForm = () =>
			loadScript(
				'https://app-ab31.marketo.com/js/forms2/js/forms2.min.js'
			)
				.then(() => {
					MktoForms2Instances.get(
						'https://app-ab31.marketo.com'
					).loadForm(
						'https://app-ab31.marketo.com',
						'544-LUT-177',
						formId
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}

		setReferralCookie(getCookie('utm_source'));
		setKeywordCookie(getCookie('utm_term'));
		setMediumCookie(getCookie('utm_medium'));
		setCampaignCookie(getCookie('utm_campaign'));
		setGclidCookie(getCookie('gclid'));
		setCampaignLastCookie(getCookie('utm_campaign_last'));
		setContentLastCookie(getCookie('utm_content_last'));
		setMediumLastCookie(getCookie('utm_medium_last'));
		setSourceLastCookie(getCookie('utm_source_last'));
		setTermLastCookie(getCookie('utm_term_last'));
		setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	}, [MktoForms2Instances, formId]);

	useEffect(() => {
		if (!validations.FirstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.LastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.Email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.Phone.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.Company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}
	}, [validations]);

	return technicalError ? (
		<div className="px-4 pb-10 bg-white">
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</div>
	) : (
		<FormikProvider value={formik}>
			<form
				data-formid={formId}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto overflow-hidden border rounded-sm shadow-lg grow border-brand-pale-500"
			>
				<div className="flex flex-col p-4 lg:p-8 lg:flex-row bg-brand-blue-400">
					<div className="w-full text-white">
						<CountdownTimer targetDate={timeZonedDateAndTime} />
					</div>
				</div>
				<div className="flex flex-col grow">
					<fieldset className="flex flex-col px-4 pt-5 bg-white lg:pt-10 lg:px-8 grow">
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<div className="flex flex-col lg:flex-row lg:gap-6">
							<Field name="FirstName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="FirstName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											First name&#42;
										</label>
										<TextInput
											id="FirstName"
											name="FirstName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={firstNameRef}
											showValidation={
												!validations.FirstName
													.isValid &&
												!firstNameRef.current.value
											}
											validationMessage={
												!validations.FirstName.isValid
													? 'Please enter your first name'
													: null
											}
											{...field}
											space={false}
											placeholder="Jane"
											validateOnBlur
										/>
									</div>
								)}
							</Field>

							<Field name="LastName" validate={validateString}>
								{({ field }) => (
									<div className="w-full lg:w-1/2">
										<label
											htmlFor="LastName"
											className="text-brand-blue-400 lg:text-base-f font-centra-medium"
										>
											Last name&#42;
										</label>
										<TextInput
											id="LastName"
											name="LastName"
											aria-required="true"
											className="w-full mb-3 wrapper-small"
											ref={lastNameRef}
											showValidation={
												!validations.LastName.isValid &&
												!lastNameRef.current.value
											}
											validationMessage={
												!validations.LastName.isValid
													? 'Please enter your last name'
													: null
											}
											{...field}
											space={false}
											placeholder="Smith"
											validateOnBlur
										/>
									</div>
								)}
							</Field>
						</div>
						<Field name="Company" validate={validateString}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Company"
										className="text-brand-blue-400 lg:text-base-f font-centra-medium"
									>
										Company&#42;
									</label>
									<TextInput
										id="Company"
										name="Company"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={companyRef}
										showValidation={
											!validations.Company.isValid &&
											!companyRef.current.value
										}
										validationMessage={
											!validations.Company.isValid
												? 'Please enter your company'
												: null
										}
										{...field}
										space={false}
										placeholder="Company LTD"
										validateOnBlur
									/>
								</div>
							)}
						</Field>

						<Field name="Email" validate={validateEmail}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Email"
										className="text-brand-blue-400 lg:text-base-f font-centra-medium"
									>
										Email&#42;
									</label>
									<TextInput
										id="Email"
										type="email"
										name="Email"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={emailRef}
										showValidation={
											!validations.Email.isValid &&
											!emailRef.current.value
										}
										validationMessage="Please enter your email address"
										{...field}
										space={false}
										placeholder="jane.smith@gmail.com"
										validateOnBlur
									/>
								</div>
							)}
						</Field>

						<Field name="Phone" validate={validatePhoneNumber}>
							{({ field }) => (
								<div>
									<label
										htmlFor="Phone"
										className="text-brand-blue-400 lg:text-base-f font-centra-medium"
									>
										Phone&#42;
									</label>
									<TextInput
										id="Phone"
										name="Phone"
										aria-required="true"
										className="w-full mb-3 wrapper-small"
										ref={phoneNumberRef}
										showValidation={
											!validations.Phone.isValid &&
											!phoneNumberRef.current.value
										}
										validationMessage="Please enter a phone number"
										{...field}
										space={false}
										type="tel"
										validateOnBlur
									/>
								</div>
							)}
						</Field>
					</fieldset>
				</div>

				<div className="flex flex-col items-center px-8 pt-3 pb-4 bg-white lg:pb-12 lg:flex-row lg:justify-between">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
							disabled={hasExpired}
						>
							<span
								className={clsx(
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{title}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
					<p className="px-4 pt-4 text-center bg-white lg:text-base-f lg:pt-0">
						View our{' '}
						<PBSLink
							to="https://www.peninsulagrouplimited.com/ca/privacy-policy/"
							variant="Link"
							size="Small"
						>
							privacy notice
						</PBSLink>
						.
					</p>
				</div>
			</form>
		</FormikProvider>
	);
};

WebinarSignupFormCA.defaultProps = {
	setFormResponse: undefined,
	setConsentResponse: undefined,
	formId: 0,
};

WebinarSignupFormCA.propTypes = {
	setConsentResponse: PropTypes.func,
	pageUrl: PropTypes.string.isRequired,
	dateAndTime: PropTypes.string.isRequired,
	timeZone: PropTypes.string.isRequired,
	setFormResponse: PropTypes.func,
	formId: PropTypes.number,
};

export { WebinarSignupFormCA };
