import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '../../atoms/button';
import { PBSLink } from '../../atoms/link';
import { useDrawer } from '../../../contexts/drawer-context';
import { buildLink, getLocale } from '../../../utils/locale';
import { HeadingTagLine } from '../../atoms/heading-tag-line';
import { IconCardListBusinessSize } from '../../molecules/icon-card-list-business-size';
import { titleProp, refProp } from '../../../types';
import { sendTrackingData } from '../../../utils';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const IconCardsBusinessSize = ({
	uspTitle,
	id,
	showContentLinks,
	iconCardsContent,
	isTimeline,
	links,
	drawerRef,
}) => {
	const { primaryLink, primaryLinkCopy, primaryLinkOpenForm } = links;
	const { uspCards } = iconCardsContent;
	const subText = '';

	const { setDrawerRef } = useDrawer();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		const clickTitle = uspTitle[0].children
			.map(({ value }) => value)
			.join('');

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'main_header',
			click_type: 'primary',
			click_title: clickTitle,
			click_text: primaryLinkCopy,
		});
	};

	let cta;

	if (primaryLinkOpenForm) {
		cta = (
			<Button
				onClick={() => {
					setDrawerRef(drawerRef);
					handleClickTracking();
				}}
			>
				{primaryLinkCopy}
			</Button>
		);
	} else if (primaryLink) {
		cta = (
			<PBSLink
				className="w-max"
				to={buildLink(locale, primaryLink)}
				onClick={handleClickTracking}
			>
				{primaryLinkCopy}
			</PBSLink>
		);
	}

	return (
		<section
			className="bg-white flex items-center justify-center -mt-24 pt-24 sm:pt-8 md:pt-24 lg:pt-12 pb-6"
			aria-labelledby={id}
		>
			<div className={clsx('mx-auto md:px-8 max-w-xl-screen')}>
				{/* <h2 className='mb-4 text-3xl text-center lg:text-4xl'>{uspTitle}</h2> */}
				<div className="px-3 md:container mb-6 lg:mt-20 lg:mb-6 md:mx-auto">
					<HeadingTagLine
						id={id}
						title={uspTitle}
						subText={subText}
					/>
				</div>
				<div>
					<IconCardListBusinessSize
						cards={uspCards}
						showContentLinks={showContentLinks}
						isTimeline={isTimeline}
					/>
					<p className="flex flex-col items-center justify-center  sm:flex-row">
						{cta || null}
					</p>
				</div>
			</div>
		</section>
	);
};

IconCardsBusinessSize.defaultProps = {
	id: 'hr-services',
	showContentLinks: true,
	isTimeline: false,
};

IconCardsBusinessSize.propTypes = {
	id: PropTypes.string,
	uspTitle: titleProp.isRequired,
	isTimeline: PropTypes.bool,
	showContentLinks: PropTypes.bool,
	links: PropTypes.shape({
		primaryLink: PropTypes.string.isRequired,
		primaryLinkCopy: PropTypes.string.isRequired,
		primaryLinkOpenForm: PropTypes.bool.isRequired,
	}).isRequired,
	iconCardsContent: PropTypes.shape({
		uspTitle: PropTypes.string,
		uspCards: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				content: PropTypes.string.isRequired,
				linkCopy: PropTypes.string,
				link: PropTypes.string,
				icon: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	// eslint-disable-next-line react/require-default-props
	drawerRef: refProp,
};

export { IconCardsBusinessSize };
