// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '../../atoms/button';
import { Select } from '../../atoms/select';
import { InputVariants } from '../../atoms/text-input';
import { sendTrackingData } from '../../../utils';
import { getLocale } from '../../../utils/locale';
import { employeeData } from './data/employee-data';
import { industryData } from './data/industry-data';
import VapLink from '../vap-link';
import { VapCheckbox } from '../vap-checkbox';

const locale = getLocale(
	typeof window !== 'undefined' ? window.location.pathname : ''
);

const getMultiplier = () => {
	const multiplier = 1;
	let currency = '£';

	switch (locale) {
		case 'ie':
			currency = '€';
			break;
		case 'ca':
			currency = '$';
			break;
		default:
			currency = '£';
			break;
	}

	return { multiplier, currency };
};

const VapCalculator = ({
	setPriceCalculated,
	setEmployeeCount,
	checkboxItems,
	setCheckboxItems,
	setSelectedVapItems,
	setUnSelectedVapItems,
	unSelectedVapItemsInitialState,
	title,
	subtitle,
}) => {
	const elName = 'employeeCount';
	const buttonText = 'Calculate cost';

	const [inputValue, setInputValue] = useState('');
	const [industry, setIndustry] = useState('');
	const industryRef = useRef(null);
	const [province, setProvince] = useState('');
	const provinceRef = useRef(null);
	const [vapLinkShow, setVapLinkShow] = useState(true);

	const dataArr = [];

	const handleCheckboxChange = (idx) => {
		const updatedCheckboxItems = [...checkboxItems];
		updatedCheckboxItems[idx].isChecked =
			!updatedCheckboxItems[idx].isChecked;
		setCheckboxItems([...updatedCheckboxItems]);
	};

	function vapHandleInput(employeeCountInLimit) {
		const selectedEmployeeData = employeeData[employeeCountInLimit];
		const selectedIndustryData = industryData[industry];
		const { currency } = getMultiplier();

		if (selectedEmployeeData) {
			let sum = 0;
			const annualCost = selectedEmployeeData.annual_cost;
			const timeSaved = selectedEmployeeData.time_saved.toLocaleString(
				undefined,
				{
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}
			);

			// Calculate impact
			const impact = {};
			Object.entries(selectedEmployeeData).forEach(([key, value]) => {
				if (key in selectedIndustryData) {
					impact[key] = value * selectedIndustryData[key];
					sum += value * selectedIndustryData[key];
				}
			});

			Object.entries(impact).map(([key, value]) => {
				dataArr.push({
					[key]: `${currency}${value.toLocaleString(undefined, {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					})}`,
				});
				return null;
			});

			const totalCost = sum;
			const annualRoi = sum / annualCost;
			dataArr.push({
				total_cost: `${currency}${totalCost.toLocaleString(undefined, {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				})}`,
			});
			dataArr.push({
				annual_roi: `${currency}${annualRoi.toLocaleString(undefined, {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})}`,
			});
			dataArr.push({
				annual_cost: `${currency}${annualCost.toLocaleString(
					undefined,
					{
						minimumFractionDigits: 0,
						maximumFractionDigits: 2,
					}
				)}`,
			});
			dataArr.push({ time_saved: timeSaved });
		}
	}

	const handleOnChange = (e) => {
		setInputValue(e.target.value);
		return null;
	};

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return null;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-calculator',
			click_type: 'primary',
			click_action: 'change state',
			click_title: title,
			click_text: buttonText,
		});
		return null;
	};

	const submitHandler = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const employeeCount = formData.get('employeeCount');
		if (!employeeCount) {
			return null;
		}
		setEmployeeCount(parseInt(employeeCount, 10));
		const employeeCountInLimit = employeeCount > 100 ? 100 : employeeCount;
		vapHandleInput(employeeCountInLimit);
		setPriceCalculated(Object.assign({}, ...dataArr));
		handleClickTracking();

		const selectedItemsArr = unSelectedVapItemsInitialState.filter(
			(item1) =>
				checkboxItems.find(
					(item2) =>
						item2.name.toString() === item1.name.toString() &&
						item2.isChecked
				)
		);

		const unSelectedItemsArr = unSelectedVapItemsInitialState.filter(
			(item1) =>
				!selectedItemsArr.some(
					(item2) => item2.name.toString() === item1.name.toString()
				)
		);

		setSelectedVapItems(selectedItemsArr);
		setUnSelectedVapItems(unSelectedItemsArr);
		window.location.href = '#vapcard';
	};
	return (
		<section id="vapform">
			<form
				onSubmit={submitHandler}
				className="flex flex-col items-center pb-xl-f"
			>
				<label
					htmlFor={elName}
					className="
				mb-10
				pb-5
				font-castledown-regular 
				text-center 
				xl:text-2xl
				xl:max-w-2xl
				
				sm:text-xl
				sm:max-w-xl
				xs: text-xl
				xs: max-w-xl
				"
				>
					{subtitle}
				</label>

				<div className="flex flex-col bg-brand-pale-400 p-5 m-5 w-full text-center focus-within:outline-blue-400/[0.20] border-2 border-blue-300 px-s-f py-base-f rounded-sm w-5xl">
					<div className="basis-1/3">
						<div className="flex flex-col lg:flex-row xl:flex-row">
							<div className="w-full lg:w-1/3">
								<div className="text-left font-castledown-regular m-5 text-3xl">
									Province
								</div>
								<div className="max-w-full mr-5 pr-5">
									<Select
										name="province"
										id="province"
										value={province}
										onChange={(e) =>
											setProvince(e.currentTarget.value)
										}
										className="mb-9 ml-5 w-full text-xl text-black"
										aria-required="true"
										placeholder="Please make a selection"
										options={[
											{
												value: 'Alberta',
												label: 'Alberta',
											},
											{
												value: 'British Columbia',
												label: 'British Columbia',
											},
											{
												value: 'Manitoba',
												label: 'Manitoba',
											},
											{
												value: 'New Brunswick',
												label: 'New Brunswick',
											},
											{
												value: 'Newfoundland and Labrador',
												label: 'Newfoundland and Labrador',
											},
											{
												value: 'Nova Scotia',
												label: 'Nova Scotia',
											},
											{
												value: 'Ontario',
												label: 'Ontario',
											},
											{
												value: 'Prince Edward Island',
												label: 'Prince Edward Island',
											},
											{
												value: 'Quebec',
												label: 'Quebec',
											},
											{
												value: 'Saskatchewan',
												label: 'Saskatchewan',
											},
										]}
										validationMessage="Please make a selection"
										ref={provinceRef}
										required
									/>
								</div>
							</div>
							<div className="w-full lg:w-1/3">
								<div className="text-left font-castledown-regular m-5 text-3xl">
									No. of Employees
								</div>
								<div className="max-w-full mr-5 pr-5">
									<input
										className={clsx(
											InputVariants.Default,
											'text-xl h-auto mx-5 mb-5 w-full appearance-none transition-colors duration-200',
											'xs:mr-5',
											'hover:bg-brand-pale-400',
											'focus:border-blue-400 focus:placeholder:text-brand-blue-400',
											'motion-reduce:transition-none',
											inputValue.length > 0
												? 'border-brand-blue-400 bg-brand-pale-300'
												: ''
										)}
										min={1}
										type="number"
										id={elName}
										name={elName}
										required
										aria-required="true"
										onChange={handleOnChange}
										value={inputValue}
										placeholder="e.g. 22"
									/>
								</div>
							</div>
							<div className="w-full lg:w-1/3 bg-gray-500">
								<div className="text-left font-castledown-regular m-5 text-3xl">
									Industry
								</div>
								<div className="max-w-full mr-5 pr-5">
									<Select
										name="industry"
										id="industry"
										value={industry}
										onChange={(e) =>
											setIndustry(e.currentTarget.value)
										}
										className="mb-9 m-5 w-full text-xl text-black"
										aria-required="true"
										placeholder="Please make a selection"
										options={[
											{
												value: 'agriculture',
												label: 'Agriculture',
											},
											{
												value: 'automotive',
												label: 'Automotive',
											},
											{
												value: 'beauty_hair',
												label: 'Beauty & hair',
											},
											{
												value: 'childcare',
												label: 'Childcare',
											},
											{
												value: 'construction',
												label: 'Construction',
											},
											{
												value: 'dentists',
												label: 'Dentists',
											},
											{
												value: 'education',
												label: 'Education',
											},
											{
												value: 'finance_insurance',
												label: 'Finance & insurance',
											},
											{
												value: 'health_medical',
												label: 'Health & medical',
											},
											{
												value: 'hospitality',
												label: 'Hospitality',
											},
											{
												value: 'household_services',
												label: 'Household services',
											},
											{
												value: 'manufacturing',
												label: 'Manufacturing',
											},
											{
												value: 'professional_services',
												label: 'Professional Services',
											},
											{
												value: 'real_estate',
												label: 'Real estate',
											},
											{
												value: 'retail_wholesale',
												label: 'Retail & Wholesale',
											},
											{
												value: 'sports_recreation',
												label: 'Sports & recreation',
											},
											{
												value: 'transportation_storage',
												label: 'Transportation & storage',
											},
											{
												value: 'vets_pets',
												label: 'Vets & Pets',
											},
											{
												value: 'other',
												label: 'Other',
											},
										]}
										validationMessage="Please make a selection"
										ref={industryRef}
										required
									/>
								</div>
							</div>
						</div>
					</div>
					{vapLinkShow ? (
						<>
							<div className="basis-1/3 text-white mt-4">
								<Button
									type="submit"
									className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
								>
									{buttonText}
								</Button>
							</div>
							<div className="basis-1/3 text-white">
								<VapLink
									vapLinkShow={vapLinkShow}
									setVapLinkShow={setVapLinkShow}
									text="Personalize your calculation"
								/>
							</div>
						</>
					) : (
						<>
							<div className="basis-1/3">
								<div className="mt-3 text-left font-castledown-regular mx-5 text-3xl">
									What are your current business concerns?
								</div>
								<div className="my-1 text-left font-castledown-regular m-5 text-lg text-[#727272] pb-5">
									Select all that apply
								</div>
							</div>
							<div className="block columns-1 sm:block sm:columns-1 md:block md:columns-1 lg:block lg:columns-2 xl:block xl:columns-2 bg-red-900">
								{checkboxItems.map((item, idx) => (
									<div
										key={item.label}
										className="flex items-center mb-2"
									>
										<VapCheckbox
											idx={idx}
											label={item.label}
											isChecked={item.isChecked}
											onChange={() =>
												handleCheckboxChange(idx)
											}
										/>
									</div>
								))}
							</div>
							<div className="basis-1/3 text-white my-5 pt-5">
								<Button
									type="submit"
									className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 whitespace-nowrap"
								>
									{buttonText}
								</Button>
							</div>
							<div className="basis-1/3 text-white mb-5">
								<VapLink
									vapLinkShow={vapLinkShow}
									setVapLinkShow={setVapLinkShow}
									text=""
								/>
							</div>
						</>
					)}
				</div>
			</form>
		</section>
	);
};

export { VapCalculator };

VapCalculator.defaultProps = {
	setEmployeeCount: () => {},
	setPriceCalculated: () => {},
	title: '',
	subtitle: '',
};

VapCalculator.propTypes = {
	setPriceCalculated: PropTypes.func,
	setEmployeeCount: PropTypes.func,
	title: PropTypes.string,
	subtitle: PropTypes.string,
};
