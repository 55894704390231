export const addMediaListener = (element, type, cb) => {
	try {
		// Chrome & Firefox
		element.addEventListener(type, cb);
	} catch {
		try {
			// Safari
			element.addListener((e) => {
				cb(e);
			});
		} catch {
			// eslint-disable-next-line no-console
			console.error('ad listener not supported');
		}
	}
};

export const removeMediaListener = (element, type, cb) => {
	try {
		// Chrome & Firefox
		element.removeEventListener(type, cb);
	} catch {
		try {
			// Safari
			element.removeListener((e) => {
				cb(e);
			});
		} catch {
			// eslint-disable-next-line no-console
			console.error('ad listener not supported');
		}
	}
};
