import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { parallaxSlideProp, refProp } from '../../../types';

import { TickList } from '../../atoms/tick-list';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';

const PartnerInfoSection = ({ partnerInfoSection, index, drawerRef }) => {
	const { title, as, image, tickList } = partnerInfoSection;

	const Heading = as || 'h3';
	const Alt = image.alt || title || 'Peninsula HR & Health and Safty Support';
	const imageData = getImage(image);

	const { setDrawerRef } = useDrawer();

	return (
		<div
			className={clsx(
				'mx-auto lg:container lg:max-w-m-screen lg:px-8 lg:py-20 md:grid px-4 md:gap-4 md:grid-cols-[1fr_1fr] md:py-1 md:px-8 pt-28 items-center',
				'lg:gap-6',
				index !== 0
					? 'lg:grid-cols-[1fr_fit-content(45%)]'
					: 'lg:grid-cols-[fit-content(45%)_1fr]',
				'xl:gap-[60px]'
			)}
		>
			<div
				className={clsx('md:order-1')}
				data-parallax-val="-300"
				data-swiper-parallax="-300"
			>
				<GatsbyImage
					className="w-full border-8 border-white drop-shadow-image"
					image={imageData}
					alt={Alt}
				/>
			</div>
			<div
				className={clsx(
					'md:p-3',
					index !== 0 ? 'md:order-2' : 'md:order-1'
				)}
			>
				<Heading
					className="text-3xl mt-lg-f md:mt-0 lg:text-5xl font-castledown-regular"
					data-parallax-val="-300"
					data-swiper-parallax="-300"
				>
					{title}
				</Heading>
				{tickList.length > 0 ? (
					<TickList
						className="mt-6 lg:mt-8"
						listItemClassName="text-lg lg:text-xl"
						points={tickList}
						data-parallax-val="-100"
						data-swiper-parallax="-100"
					/>
				) : null}
				<div
					className={clsx(
						'flex flex-col items-center mt-8',
						'md:mt-6 md:items-start',
						'lg:mt-8',
						'xl:items-center xl:flex-row'
					)}
					data-parallax-val="-100"
					data-swiper-parallax="-100"
				>
					<Button onClick={() => setDrawerRef(drawerRef)}>
						Speak to an advisor
					</Button>
				</div>
			</div>
		</div>
	);
};

PartnerInfoSection.defaultProps = {
	index: 1,
	drawerRef: '',
};

PartnerInfoSection.propTypes = {
	partnerInfoSection: parallaxSlideProp.isRequired,
	index: PropTypes.number,
	drawerRef: refProp,
};

export { PartnerInfoSection };
