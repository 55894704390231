// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import { DateTime } from 'luxon';
// import { format } from 'date-fns';
import {
	StructuredText,
	renderNodeRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading, isListItem } from 'datocms-structured-text-utils';
import { PBSLink } from '../../../atoms/link';
import ClockIcon from '../../../../assets/clock.inline.svg';
import CalendarIcon from '../../../../assets/calendar.inline.svg';
import TickIcon from '../../../../assets/tick.inline.svg';
import { buildLink } from '../../../../utils/locale';
import { Button } from '../../../atoms/button';

const FeaturedSessionCard = ({ featuredCard, pbsWebinar, locale }) => {
	const parsedStartDate = DateTime.fromISO(featuredCard.dateAndTime, {
		zone: featuredCard.timeZone,
	});
	const formatDateString = 'MMM d, y';
	const startDateAsString = parsedStartDate.toFormat(formatDateString);

	const date = `${startDateAsString}`;

	const parsedStartDateTime = DateTime.fromISO(featuredCard.dateAndTime, {
		zone: featuredCard.timeZone,
	});
	const formatString = 'h:mm a';

	const timeZoneMap = {
		'America/Toronto': 'ET',
		'America/Vancouver': 'PT',
	};

	const startDateTimeAsString = parsedStartDateTime.toFormat(formatString);

	const transformTime = (timeToCut) => {
		if (timeToCut.startsWith('0')) {
			return timeToCut.slice(1);
		}
		return timeToCut;
	};

	const time = `${startDateTimeAsString} - ${transformTime(
		featuredCard.timeEnd
	)} ${timeZoneMap[featuredCard.timeZone]}`;

	// If not achievable try to create live webinar in DATO with closest date and don't publish. Only for local testing
	let btnDescription = '';
	// If current date and time is greater than the webinar time then it is expired
	const expiredTime = false; // Need to put condition and work on this variable
	// If current date and time is greater than the webinar time
	// AND 5pm at that day[I believe to put in one variable and compare or double if condtion] then show access recording
	const acccesRecording = '5spm'; // Need to put condition and work on this variable

	if (acccesRecording === '5pm') {
		btnDescription = () => (
			<div>
				<PBSLink
					to="https://pages.peninsula-ca.com/webinars-on-demand.html"
					className="text-center !text-sm"
					size="Small"
					target="_blank"
				>
					Access recording
				</PBSLink>
			</div>
		);
	} else if (expiredTime) {
		btnDescription = () => (
			<div className="wrapper-small">
				<Button type="submit" disabled>
					Expired
				</Button>
			</div>
		);
	} else {
		btnDescription = () => (
			<div>
				<PBSLink
					to={`${
						pbsWebinar
							? buildLink(locale, '/webinars/')
							: buildLink(locale, '/elearning/webinar/')
					}${featuredCard.slug}`}
					className="text-center !text-sm"
					size="Small"
					target="_blank"
				>
					Save your spot
				</PBSLink>
			</div>
		);
	}

	return (
		<div className="flex flex-col justify-between w-full p-10 text-white rounded-sm rounded-t-none lg:rounded-t-sm md:w-1/2 bg-brand-blue-400">
			<div>
				<p className="mb-6 text-2xl lg:text-3xl font-semibold">
					{featuredCard.title}
				</p>
				<p className="mb-6 text-xl">
					<CalendarIcon
						className="w-[22px] text-blue-300 inline-block mr-4"
						aria-hidden
					/>
					{date}
				</p>
				<p className="mb-6 text-xl">
					<ClockIcon
						className="w-[22px] text-blue-300 inline-block mr-4"
						aria-hidden
					/>
					{time}
				</p>
				{featuredCard.shortenedContent ? (
					<div className="mb-5">
						<StructuredText
							data={featuredCard.shortenedContent.value}
							customNodeRules={[
								renderNodeRule(
									isHeading,
									({ node, children, key }) => {
										const HeadingTag = `h${node.level}`;
										return (
											<HeadingTag
												className="mb-5 text-2xl font-bold lg:text-3xl font-castledown-regular"
												key={key}
											>
												{children}
											</HeadingTag>
										);
									}
								),
								renderNodeRule(
									isListItem,
									({ children, key }) => (
										<li
											className="flex items-center mt-5 mb-4 text-sm last-of-type:mb-0 font-centra-light"
											key={key}
										>
											<TickIcon
												className="w-3 h-auto mr-4 text-blue-400 shrink-0"
												aria-hidden="true"
											/>

											{children}
										</li>
									)
								),
							]}
						/>
					</div>
				) : null}
			</div>

			<div className="">{btnDescription()}</div>
		</div>
	);
};

export default FeaturedSessionCard;
