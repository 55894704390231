import React from 'react';
import { clsx } from 'clsx';
import Proptypes from 'prop-types';

function PeninsulaNow({ className, setModal, pageName }) {
	const Component = 'div';
	return (
		<Component
			onClick={() => setModal()}
			className={clsx(
				!pageName && 'md:mr-4 md:pr-0 border-r border-brand-pale-500',
				'font-centra-light  flex flex-col p-[1px] ',
				className
			)}
		>
			<div
				className={clsx(
					'w-fit',
					'lg:w-fit',
					'text-[1.5rem] ',
					'lg:text-[1.5rem] leading-[1.9rem]',
					'font-centra-light bg-black text-3xl text-white flex items-center px-[5px] rounded-sm '
				)}
			>
				<span className="inline-block ml-1 mr-2 bg-brand-red-400 red-dot shrink-0" />
				NOW
			</div>
			<span
				className={clsx(
					pageName
						? 'md:text-[11px] text-[9px] text-right text-black font-centra-book'
						: 'md:text-[15px] text-[9px] text-black font-centra-book',
					'inline-block',
					'sm: mr-2'
				)}
			>
				Live video call with an expert!
			</span>
		</Component>
	);
}

PeninsulaNow.defaultProps = {
	className: '',
	setModal: () => {},
	pageName: '',
};

PeninsulaNow.propTypes = {
	className: Proptypes.string,
	setModal: Proptypes.func,
	pageName: Proptypes.string,
};
export { PeninsulaNow };
