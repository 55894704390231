/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { createHeadingHighlightCA } from '../../../utils/heading-highlight-ca';
import openQuote from './quotes/open_quote.svg';
import closeQuote from './quotes/close_quote.svg';
import { useModal } from '../../../contexts/modal-context';
import { Button } from '../../atoms/button';
import { useDrawer } from '../../../contexts/drawer-context';

const TestimonialsVideoCampaignCA = ({
	testimonialsVideoCampaignCA = {},
	modalRef,
	drawerRef,
	className = '',
	firstChildClassName = '',
}) => {
	const {
		title = {},
		subText = '',
		testimonialCards = [],
		primaryLinkCopy = '',
	} = testimonialsVideoCampaignCA;
	const { setModalRef } = useModal();
	const { setDrawerRef } = useDrawer();

	// State to manage visibility of cards
	const [showAll, setShowAll] = useState(false);

	// Determine the number of cards to show
	const cardsToShow =
		showAll && Array.isArray(testimonialCards)
			? testimonialCards
			: Array.isArray(testimonialCards)
			? testimonialCards?.slice(0, 3)
			: [];

	return (
		<div className={`w-full bg-[#EDF4FF] ${className}`}>
			<div
				className={`flex flex-col items-center px-6 pb-0 pt-8 lg:px-10 lg:pt-20 lg:pb-16 mx-auto lg:max-w-m-screen bg-[#EDF4FF] ${firstChildClassName}`}
			>
				{/* title */}
				<div className="pb-5">
					{createHeadingHighlightCA({
						headings: title?.value?.document?.children[0]?.children,
						variant: 'red',
						className: 'text-center',
					})}
				</div>
				{/* subText */}
				<div className="text-center text-[20px] font-centra-light pb-10 lg:pb-16">
					{subText || ''}
				</div>
				{/* cards */}
				<div className="grid grid-flow-row lg:grid-flow-row lg:grid-cols-3 px-2 gap-5 font-castledown-regular">
					{/* for desktop */}
					{testimonialCards.length > 0 &&
						testimonialCards?.map((elm) => (
							<div
								key={elm?.id} // Ensure each element has a unique key
								className="col-span-1 h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-centra-no1 relative shadow-sm hidden sm:block"
							>
								<div className="font-centra-book">
									<span className="inline">
										<img
											src={openQuote || ''}
											className="inline pb-1 pr-2 -mt-2"
											alt=""
										/>
										{elm.content || ''}
										<img
											src={closeQuote}
											className="inline pl-2 pb-1"
											alt=""
										/>
									</span>
								</div>
								<div className="flex flex-row justify-between items-center mt-5">
									<div className="flex flex-col font-centra-no1 font-bold">
										<div className="font-castledown-regular text-[#3954E0]">
											{elm?.name || ''},
										</div>
										<div className="text-[#1f237a] max-w-[250px]">
											{elm?.job || ''}
										</div>
									</div>
									<div>
										<img
											src={elm.profile?.url || ''}
											alt=""
											className="min-h-[74px] min-w-[74px]"
										/>
									</div>
								</div>
							</div>
						))}
					{/* for mobile */}
					{cardsToShow.length > 0 &&
						cardsToShow?.map((elm) => (
							<div
								key={elm.id} // Ensure each element has a unique key
								className="col-span-1 h-fit bg-white rounded-[.75rem] border-1 border-[#dddddd] p-5 text-3xl-f text-[16px] leading-[1.35rem] font-centra-no1 relative shadow-sm block sm:hidden"
							>
								<div className="font-centra-book">
									<span className="inline">
										<img
											src={openQuote}
											className="inline pb-1 pr-2 -mt-2"
											alt=""
										/>
										{elm?.content || ''}
										<img
											src={closeQuote}
											className="inline pl-2 pb-1"
											alt=""
										/>
									</span>
								</div>
								<div className="flex flex-row justify-between items-center mt-5">
									<div className="flex flex-col font-centra-no1 font-bold">
										<div className="font-castledown-regular text-[#3954E0]">
											{elm?.name || ''},
										</div>
										<div className="text-[#1f237a] max-w-[250px]">
											{elm?.job || ''}
										</div>
									</div>
									<div>
										<img
											src={elm.profile?.url || ''}
											alt=""
											className="min-h-[74px] min-w-[74px]"
										/>
									</div>
								</div>
							</div>
						))}
				</div>
				{/* Read More Button for mobile */}
				{!showAll && testimonialCards?.length > 3 && (
					<div className="flex justify-center pt-5 lg:pt-16 block sm:hidden">
						<button
							onClick={() => setShowAll(true)}
							className="hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-transparent hover:bg-transparent focus:bg-transparent text-blue-400 !border-0 !p-0 underline decoration-1 hover:text-brand-blue-400 focus:shadow-focus focus:outline-focus"
						>
							Read More
						</button>
					</div>
				)}
				{/* CTA */}
				<div className="flex justify-center pb-10 pt-10 lg:pt-16">
					<Button
						onClick={() => {
							if (modalRef !== null) setModalRef(modalRef);
							if (drawerRef !== null) setDrawerRef(drawerRef);
						}}
						className="hidden md:block transition delay-150 duration-1000 mx-auto hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white whitespace-nowrap"
					>
						{primaryLinkCopy}
					</Button>
					{/* for mobile devices */}
					<Button
						onClick={() => {
							if (modalRef !== null)
								window.location.href = '#adviceform';
							if (drawerRef !== null) setDrawerRef(drawerRef);
						}}
						className="block md:hidden transition delay-150 duration-1000 hover:bg-brand-blue-400 hover:border-brand-blue-400 focus:bg-brand-blue-500 inline-flex items-center rounded-lg py-4 px-6 md:px-8 text-lg md:text-xl transition-colors font-centra-medium focus:outline-0 focus:shadow-focus leading-6 border-2 border-blue-400 bg-blue-400 text-white whitespace-nowrap mx-auto"
					>
						{primaryLinkCopy || 'Speak to an expert'}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TestimonialsVideoCampaignCA;
