// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { DefaultLayout } from '../layouts/default/partnership';

import { Head } from '../components/templates/head';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import { Drawer } from '../components/atoms/drawer';
import { useDrawer } from '../contexts/drawer-context';

import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../components/organisms/success-form';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { AdviceBanner } from '../components/organisms/advice-banner';

import { MainHeader } from '../components/molecules/main-header-partner';
import { ClientLogosPartner } from '../components/atoms/client-logos-partner';
import { ParallaxSlider } from '../components/molecules/parallax-slider-partner';
import { TextWithOneImageContainer } from '../components/molecules/text-with-one-image-container';

import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { HrefLang } from '../components/templates/hrefLang';

const PartnershipPage = ({ location, data }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const { locale } = data.datoCmsPartnerLandingPage;
	const metaContent = {
		noIndex: data.datoCmsPartnerLandingPage.noIndex,
		noFollow: data.datoCmsPartnerLandingPage.noFollow,
		metaInformation: data.datoCmsPartnerLandingPage.metaInformation,
		fallbackTitle: data.datoCmsPartnerLandingPage.title,
		fallbackDescription: data.datoCmsPartnerLandingPage.longIntroduction,
		canonicalUrl:
			data.datoCmsPartnerLandingPage?.canonicalUrl || location.href,
	};

	const logoContent = data.datoCmsPartnerLandingPage.partnerLogos;

	const headerCTAContent = {
		...data.datoCmsPartnerLandingPage.partnerLandingHeader,
		header: data.datoCmsPartnerLandingPage.partnerLandingHeader.header.value
			.document.children,
	};

	const textWithOneImageContainer = {
		...data.datoCmsPartnerLandingPage.textWithImage,
		title: data.datoCmsPartnerLandingPage.textWithImage.title.value.document
			.children,
		firstContent: data.datoCmsPartnerLandingPage.textWithImage.firstContent,
	};

	const partnerLandingPageSlider = {
		...data.datoCmsPartnerLandingPage.partnerPageSlider,
		title: data.datoCmsPartnerLandingPage.partnerPageSlider.title.value
			.document.children,
	};

	const adviceBannerContent = {
		...data.datoCmsPartnerLandingPage.adviceBanner,
		title: data.datoCmsPartnerLandingPage.adviceBanner.title.value.document
			.children,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(headerCTAContent?.primaryImage),
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPartnerLandingPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<MainHeader
				urlLocale={locale}
				drawerRef={adviceFormRef}
				{...headerCTAContent}
			/>
			<ClientLogosPartner
				pathname={location.pathname}
				logoContent={logoContent}
			/>
			<ClientLogosPartner
				pathname={location.pathname}
				logoContent={logoContent}
				isMobile
			/>
			<TextWithOneImageContainer
				textWithOneImageContainerContent={textWithOneImageContainer}
			/>
			<ParallaxSlider
				parallaxSliderContent={partnerLandingPageSlider}
				className="mb-20"
			/>
			<AdviceBanner
				drawerRef={adviceFormRef}
				adviceBannerContent={adviceBannerContent}
			/>
			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedPartnership = ({ location, data }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<PartnershipPage location={location} data={data} />
	</DefaultLayout>
);

export default WrappedPartnership;

export const query = graphql`
	query partnership($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPartnerLandingPage(locale: { eq: $locale }) {
			id
			locale
			title
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			partnerLandingHeader {
				header {
					value
				}
				id
				primaryLink
				primaryLinkCopy
				primaryLinkOpenForm
				secondaryHeader
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
			}
			partnerLogos {
				title
				logoOne {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoTwo {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoThree {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoFour {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoFive {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoSix {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				logoNumber
			}
			textWithImage {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}
			partnerPageSlider {
				title {
					value
				}
				slides {
					title
					subText
					image {
						alt
						url
						gatsbyImageData(
							width: 600
							height: 460
							layout: CONSTRAINED
						)
					}
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
		}

		siteLogo: datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;
