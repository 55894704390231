import React from 'react';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';
import { truncateWithEllipsis } from '../../../utils/truncateWithEllipsis';
import { sectorCardProp } from '../../../types';
import { sendTrackingData } from '../../../utils';

const SectorCard = ({ cardContent }) => {
	const { title, slug, intro, mainHeader, alt } = cardContent;
	const { gatsbyImageData } = mainHeader.primaryImage;
	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: `sectors-card`,
			click_type: 'block',
			click_title: title,
			...trackData,
		});
	};
	const Component = Link;
	return (
		<Component
			to={slug}
			onClick={handleClickTracking}
			className={clsx(
				'flex flex-col group relative bg-white h-full w-full',
				'border-2 border-blue-200 border-solid rounded-sm shadow-card',
				'transition-shadow duration-300 motion-reduce:transition-none',
				'hover:shadow-default-hover focus-within:shadow-default-hover hover:cursor-pointer',
				'focus-within:outline focus-within:outline-offset-[-2px] focus-within:outline-2'
			)}
		>
			<GatsbyImage
				image={gatsbyImageData}
				alt={alt || title}
				className="aspect-[400/200]"
			/>

			<div className="flex flex-col grow">
				<div className="px-s-f">
					<h3 className="leading-tight py-s-f text-lg-f font-centra-medium">
						{title}
					</h3>
				</div>
				<div className="mb-8 px-s-f">
					<p className="text-sm md:text-base-f">
						{truncateWithEllipsis(intro, 120)}
					</p>
				</div>
			</div>
		</Component>
	);
};
SectorCard.defaultProps = {
	onClickProp: () => {},
};

SectorCard.propTypes = sectorCardProp;

export { SectorCard };
